<div *ngIf="loaded">
  <div style="position: relative; height: 500px">
    <canvas
      baseChart
      [datasets]="lineChartData"
      [labels]="lineChartLabels"
      [options]="lineChartOptions"
      [legend]="lineChartLegend"
      [type]="lineChartType"
    ></canvas>
  </div>
</div>

<div class="text-center dashboard-loading-spinner" *ngIf="!loaded">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
