{
  "READ_MORE_AND_ORDER": "Läs mer & Beställ",
  "SUB_CATEGORY_1": "Filtrera 1",
  "SUB_CATEGORY_2": "Filtrera 2",
  "SUB_CATEGORY_3": "Filtrera 3",
  "SUB_CATEGORY_4": "Filtrera 4",
  "AR_ITEMTYPECD1": "Artikelfilter 1",
  "AR_ITEMTYPECD2": "Artikelfilter 2",
  "AR_ITEMTYPECD3": "Artikelfilter 3",
  "AR_ITEMTYPECD4": "Artikelfilter 4",
  "AR_ITEMTYPECD5": "Artikelfilter 5",
  "AR_ITEMTYPECD6": "Artikelfilter 6",
  "SEND_QUOTE": "Skicka offert",
  "SUPPLIER_STOCK": "Ej i lager (0)",
  "SJOBORG_FREIGHT_COST": "Fraktkostnad på <strong>{{value}} {{currencyCode}}</strong> läggs till",
  "SPAREPARTS": "Reservdelar",
  "ENTER_COMPANY": "Ange företag",
  "EMAIL_ORDER_CONFIRMATION": "Orderbekräftelse e-post",
  "ENTER_EMAIL_ORDER_CONFIRMATION": "Ange orderbekräftelse e-post",
  "EMAIL_INVOICE": "Faktura e-post",
  "ENTER_EMAIL_INVOICE": "Ange Faktura e-post",
  "EXTRA_MESSAGE": "Extra meddelande",
  "ENTER_EXTRA_MESSAGE": "Ange extra meddelande",
  "QUANTITY_ADJUSTED_UP_CLOSET_PACKAGE_SIZE": "Antal justerat upp till närmaste förpackningsstorlek",
  "CART_ARTICLE_SKU_TOTAL": "{{article}} artiklar och {{sku}} st",
  "MODIFY_DATE": "Datum redigerad",
  "OUR_RECOMMENDATION": "Vår rekommendation"
}
