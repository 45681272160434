<div *ngIf="!loading && !noProducts" class="row">
  <div
    class="p-2"
    [ngClass]="{
      'col-6': itemsPerRow == 2 || innerWidth < 768,
      'col-4': itemsPerRow == 3 && innerWidth >= 768,
      'col-3': itemsPerRow == 4 && innerWidth >= 768,
      'col-2': itemsPerRow >= 5 && innerWidth >= 768
    }"
    *ngFor="let data of mappedData; let index = index"
  >
    <div
      class="card product-card d-flex flex-column"
      [ngClass]="{ 'mr-0': (index + 1) % currentItemsPerRow === 0, 'ml-0': index % currentItemsPerRow === 0 }"
    >
      <div class="card-body">
        <div class="ribbon" *ngIf="data.product?.extra?.pim?.banner">
          <div
            class="ribbon-child"
            [ngStyle]="{
              'background-color': data?.product?.extra?.pim?.banner?.properties?.HexCode
                ? '#' + data.product.extra.pim.banner.properties.HexCode
                : 'black'
            }"
            translate
          >
            <div class="arrow-ribbon"></div>
            <span
              *ngIf="
                data?.product?.extra?.pim?.banner?.translateProperties[currentLang]?.Name ||
                data?.product?.extra?.pim?.banner?.description
              "
              >{{
                data.product.extra.pim.banner.translateProperties[currentLang]?.Name ||
                  data.product.extra.pim.banner.description
              }}</span
            >
          </div>
        </div>
        <span class="favourite-icon-wrapper">
          <lib-favourite-product-icon [id]="data.product.id"></lib-favourite-product-icon>
        </span>
        <div class="image-wrapper" (click)="goToDetail(data.product)">
          <img
            class="img-fluid"
            *ngIf="data.product.extra && data.product.extra.images && data.product.extra.images[0]"
            [src]="
              data.product.extra.images[0].s3Uri
                | gungImageUrl: 'type:thumbnail' : 'etag:' + data.product.extra.images[0].s3ETag
            "
            alt=""
          />
        </div>
        <div class="product-text text-center">
          <div
            *ngIf="data?.product?.extra?.gungItems?.itemCategoryCode"
            class="text-truncate font-weight-bold long-text-hide"
            translate
          >
            {{ data.product.extra.gungItems.itemCategoryCode }} {{ data.product.extra.gungItems.description2 }}
          </div>

          <div *ngIf="data?.product?.extra?.gungItems?.displayName" class="long-text-hide">
            {{ data.product.extra.gungItems.displayName }}
          </div>
          <div *ngIf="data?.product?.name && !data?.product?.extra?.gungItems?.displayName" class="long-text-hide">
            {{ data.product.name }}
          </div>
          <div class="xsmall long-text-hide">{{ data.product.id }}</div>

          <lib-availability
            class="long-text-hide"
            [availability]="data.availability"
            [isSales]="false"
            *ngIf="data.availability"
          ></lib-availability>

          <div class="font-weight-bold row justify-content-between mt-2 long-text-hide" *ngIf="!isAnonymous">
            <div *ngIf="data.price" class="col mr-auto">
              <lib-price
                [price]="data.price"
                type="NET"
                [currency]="data.price.customerNetPrice.currencyCode"
              ></lib-price>
            </div>
            <!--
              <div class="col text-muted">
                <span *ngIf="data.price">
                  {{ 'RRP' | translate }}
                  <lib-price
                    *ngIf="data.price.recommendedRetailPrice; else rrpPrice"
                    [price]="data.price"
                    type="RRP"
                    [currency]="data.price.recommendedRetailPrice.currencyCode"
                  ></lib-price>
                  <ng-template #rrpPrice>
                    <lib-price [price]="'1999'" [currency]="data.price.customerNetPrice.currencyCode"></lib-price>
                  </ng-template>
                </span>
              </div>
              -->
          </div>
        </div>
        <div>
          <container-element [ngSwitch]="data.product?.productType">
            <button
              *ngSwitchCase="'concept'"
              class="btn btn-sm btn-primary w-100"
              (click)="goToDetail(data.product)"
              translate
            >
              SHOW_PRODUCTS
            </button>
            <lib-buy-button-wrapper
              *ngSwitchDefault
              [id]="data.product.id"
              [product]="data.product"
              [availability]="data.availability"
            ></lib-buy-button-wrapper>
          </container-element>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loading || noProducts" class="d-flex flex-row justify-content-center align-items-center h-100">
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</div>
