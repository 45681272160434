import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, ReplaySubject } from 'rxjs';
import { CustomerGungContact } from '../../models/customerGungContact';

@Injectable({
  providedIn: 'root'
})
export class CustomerGungContactsService {
  private contactsSubjects: { [customerId: string]: Subject<CustomerGungContact[]> } = {};

  constructor(protected http: HttpClient) {}

  updateCustomerContactsSubject(customerId: string, nocache?: boolean): void {
    this.getContactsByCustomerIdInternal(customerId, nocache).subscribe((cc: CustomerGungContact[]) =>
      this.contactsSubjects[customerId].next(cc)
    );
  }

  protected internalGetCustomerContactsFromUrl(customerId: string): Observable<CustomerGungContact[]> {
    const url = `json/customer/${customerId}/gung-contacts`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<CustomerGungContact[]>(url, { headers });
  }

  getAllContacts(nocache?: boolean): Observable<CustomerGungContact[]> {
    const url = `json/customer/gung-contacts`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<CustomerGungContact[]>(url, { headers });
  }

  private getContactsByCustomerIdInternal(customerId: string, nocache?: boolean): Observable<CustomerGungContact[]> {
    const url = `json/customer/${customerId}/gung-contacts`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<CustomerGungContact[]>(url, { headers });
  }

  getContactsByCustomerId(customerId: string, nocache?: boolean): Observable<CustomerGungContact[]> {
    if (!this.contactsSubjects[customerId]) {
      this.contactsSubjects[customerId] = new ReplaySubject<CustomerGungContact[]>();
      this.updateCustomerContactsSubject(customerId, nocache);
    }
    return this.contactsSubjects[customerId].asObservable();
  }

  getContactById(customerId: string, contactId: string): Observable<CustomerGungContact[]> {
    const url = `/json/customer/${customerId}/gung-contacts/${contactId}`;
    return this.http.get<CustomerGungContact[]>(url);
  }

  putContact(customerId: string, contact: CustomerGungContact): Observable<CustomerGungContact[]> {
    const url = `json/customer/${customerId}/gung-contacts`;
    return this.http.put<CustomerGungContact[]>(url, contact);
  }

  postContact(customerId: string, contact: CustomerGungContact): Observable<CustomerGungContact[]> {
    const url = `json/customer/${customerId}/gung-contacts`;
    return this.http.post<CustomerGungContact[]>(url, contact);
  }

  deleteContact(customerId: string, contactId: string): Observable<CustomerGungContact[]> {
    const url = `json/customer/${customerId}/gung-contacts/${contactId}`;
    return this.http.delete<CustomerGungContact[]>(url);
  }
}
