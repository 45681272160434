import { Injectable } from '@angular/core';
import { ActivityInternalCustomerResponsibleService } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class JeevesActivityInternalCustomerResponsibleService extends ActivityInternalCustomerResponsibleService {
  public metadataTable: string = 'salj';
  public metadataId: string = 'saljare';
  public metadataName: string = 'saljarenamn';
  userCustomerResponsiblePath: string = '';
  useCustomerSalesperson: boolean = true;
  customerCustomerResponsiblePath: string = 'extra.kus.saljare';
}
