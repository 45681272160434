import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { OrderPortalEntry } from '../models/order-portal';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OrderPortalService } from './order-portal.service';
import { OrderPortalListViewComponent } from '../components/order-portal-list-view/order-portal-list-view.component';
import { CRS } from 'leaflet';
import Simple = CRS.Simple;
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class OrderPortalListConfigService implements ConfigService<OrderPortalEntry> {
  topFilter = false;

  fromDateYearMonthDay: string;

  setFromDateYearMonthDay(fromDateYearMonthDate: string) {
    this.fromDateYearMonthDay = fromDateYearMonthDate;
  }

  constructor(protected orderPortalService: OrderPortalService) {}

  getItems(): Observable<OrderPortalEntry[]> {
    if (!this.fromDateYearMonthDay) {
      return this.orderPortalService.getEntries();
    } else {
      return this.orderPortalService.getEntries(this.fromDateYearMonthDay);
    }
  }

  getFilters(): ConfigBaseFilter<OrderPortalEntry>[] {
    return [
      new OrderChannelFilter(),
      new OrderSubChannelFilter(),
      new OrderPortalDateFilter(),
      new CreatedByFilter(),
      new HasErrorsFilter()
    ];
  }

  getSearchTerms(item: OrderPortalEntry): string[] {
    return [...(item.createdOrderIds || []), ...item.messages.map(m => m.message), item.orderContextId];
  }

  getLayouts(): ListLayout<OrderPortalEntry>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OrderPortalListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order portal list'
      }
    ];
  }

  getSortOptions(): ListSortOption<OrderPortalEntry>[] {
    return [];
  }

  getBatchSizes(): number[] | undefined {
    return [24];
  }

  getItemId(item: OrderPortalEntry): string {
    return item.id;
  }
}

class CreatedByFilter extends SimpleConfigBaseFilter<OrderPortalEntry> {
  getName(): string {
    return 'CREATED_BY';
  }

  getOptionIds(item: OrderPortalEntry): string[] {
    return [item.createdBy];
  }

  getOptionName(key: string): string {
    return key;
  }
}

class OrderChannelFilter extends SimpleConfigBaseFilter<OrderPortalEntry> {
  getName(): string {
    return 'ORDER_CHANNEL';
  }

  getOptionIds(item: OrderPortalEntry): string[] {
    return [item.orderChannel];
  }

  getOptionName(key: string): string {
    return key;
  }
}

class OrderSubChannelFilter extends SimpleConfigBaseFilter<OrderPortalEntry> {
  getName(): string {
    return 'ORDER_SUB_CHANNEL';
  }

  getOptionIds(item: OrderPortalEntry): string[] {
    if (item.orderSubChannel) {
      return [item.orderSubChannel];
    }
    return [];
  }

  getOptionName(key: string): string {
    return key;
  }
}

class HasErrorsFilter extends SimpleConfigBaseFilter<OrderPortalEntry> {
  getName(): string {
    return 'HAS_ERRORS';
  }

  getOptionIds(item: OrderPortalEntry): string[] {
    if (item.finishedSuccessfully) {
      return ['FALSE'];
    }
    return ['TRUE'];
  }

  getOptionName(key: string): string {
    return key;
  }
}

class OrderPortalDateFilter extends SimpleConfigBaseFilter<OrderPortalEntry> {
  type = 'dateRangeFilter';

  getName(): string {
    return 'DATE';
  }

  getOptionIds(item: OrderPortalEntry): string[] {
    return [format(new Date(item.timestamp || Date()), 'yyMMdd')];
  }

  getOptionName(key: string): string {
    return key;
  }
}
