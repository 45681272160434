<div class="table-responsive gung-responsive">
  <table class="table">
    <thead>
      <tr>
        <th translate>ID</th>
        <th translate>NAME</th>
        <th translate *ngIf="includeAvailability">AVAILABILITY</th>
        <th translate>PRICE</th>
        <th style="min-width: 200px"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of mappedData">
        <td [attr.data-label]="'ID' | translate" *ngIf="item.replacementId == null">
          <a (click)="goToDetail(item)" class="text-dark">
            {{ item.id }}
          </a>
        </td>
        <td [attr.data-label]="'ID' | translate" *ngIf="item.replacementId != null">
          <span [ngbPopover]="popContent2" triggers="mouseenter" [autoClose]="'outside'">
            <a (click)="goToDetail(item)" class="text-red">
              {{ item.id }} <i class="fas fa-exclamation-triangle"></i>
            </a>
          </span>
          <ng-template #popContent2>
            <div class="row">
              <div class="col text-center">
                <span translate>PRODUCT_REPLACED_BY</span> <br />
                <a (click)="goToDetail(item)" class="text-dark">
                  {{ item.replacementId }}
                </a>
              </div>
            </div>
          </ng-template>
        </td>
        <td [attr.data-label]="'NAME' | translate">
          <a (click)="goToDetail(item)" class="text-dark">
            {{ item.name }}
          </a>
        </td>
        <td [attr.data-label]="'AVAILABILITY' | translate" *ngIf="includeAvailability">
          <lib-availability [availability]="item.availability" [isSales]="isSales"></lib-availability>
        </td>
        <td [attr.data-label]="'PRICE' | translate">
          <span *ngIf="item.price">
            <span *ngIf="item.price.customerDiscountPercent === 0" translate>NET_PRICE</span>
            <span>
              {{ item.price.customerNetPrice.value | gungCurrency: item.price.customerNetPrice.currencyCode }}
            </span>
            <span *ngIf="item.price.customerDiscountPercent !== 0">(-{{ item.price.customerDiscountPercent }}%)</span>
          </span>
        </td>
        <td [attr.data-label]="'BUY' | translate" style="width: 200px">
          <lib-buy-btn [id]="item.id" [product]="item.product"> </lib-buy-btn>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="findError" class="mb-2 text-center">
    {{ errorMessage }}
  </div>
</div>
