import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CompactType, DisplayGrid, GridType, GridsterConfig } from 'angular-gridster2';
import { PlanogramAddItemsService } from '../../services/planogram-add-items.service';

@Component({
  selector: 'lib-planogram-drag-helper',
  templateUrl: './planogram-drag-helper.component.html',
  styleUrls: ['./planogram-drag-helper.component.scss']
})
export class PlanogramDragHelperComponent implements OnInit, OnChanges{

  landscape = false;

  @Input()
  droppedProduct;

  lastDragProduct;
  
  data = [];
  
  constructor(protected planogramAddItemsService: PlanogramAddItemsService, private el: ElementRef){

  }


  ngOnChanges(changes: SimpleChanges): void {
    if(this.droppedProduct && this.lastDragProduct.id === this.droppedProduct.itemId ){
      const index = this.data.findIndex(d => d.id === this.droppedProduct.itemId);
      this.data.splice(index, 1);
      this.lastDragProduct = null;
      this.droppedProduct = null;
    }
  }

  

  ngOnInit(): void {
    this.removeStyles();
    this.planogramAddItemsService.getDragHelper().subscribe(item =>{
      this.data.push(item);
    })
  }

  removeStyles() {
    const divElement = this.el.nativeElement.querySelector('.draggable-div');
    if (divElement) {
      divElement.removeAttribute('style');
    }
  }

  onDragEnded(event: CdkDragDrop<any>): void {
    // Handle drag end event if needed
    console.log('Drag ended:', event);
  }


  onDrop(event) {
    event.preventDefault();
    if (this.planogramAddItemsService.draggedItem) {
      this.planogramAddItemsService.draggedItem.articles = this.planogramAddItemsService.draggedItem.extra?.SpaceManagementModuleProducts?.map(p=> p.itemId);
      this.data.push(this.planogramAddItemsService.draggedItem);
      this.planogramAddItemsService.draggedItem = null;
    }
  }

  onDragStart(event, product) {
    this.planogramAddItemsService.draggedItem = structuredClone(product);
    this.lastDragProduct = structuredClone(product);
    
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    //console.log('over here');
  }

  changeOrientation(){
    this.landscape = !this.landscape;
    this.removeStyles();
  }

  deleteItem(i){
    this.data.splice(i, 1);
  }
}
