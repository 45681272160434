import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchRequest } from 'gung-list';
import { forkJoin, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, mergeMap, switchMap, takeUntil } from 'rxjs';
import { Product } from '../../models';
import { Availability } from '../../models/availability';
import { CartRowPrice } from '../../models/price';
import { Assortment, AssortmentService } from '../../services/assortment.service';
import { CartService } from '../../services/cart/cart.service';
import { ProductInputQuantityConfigService } from '../../services/product-input-quantity-config/product-input-quantity-config.service';
import { ProductService } from '../../services/products/product.service';
import { CartRow } from '../../state/cart/types';
import { SelectedCustomerService } from "../../services/selected-customer/selected-customer.service";

@Component({
  selector: 'lib-quickorder',
  templateUrl: './quickorder.component.html',
  styleUrls: ['./quickorder.component.css']
})
export class QuickorderComponent implements OnInit, OnDestroy {
  @ViewChild('quickorderSummarize', { static: false }) private quickorderSummarize: ElementRef<HTMLDivElement>;
  isQuickorderSummarizeScrolledIntoView = true;

  cachedData: { [id: string]: { product: Product; price: CartRowPrice; availability: Availability } } = {};
  public product: Product;
  public quantity: number;
  cart: CartRow[] = [];
  protected unsubscribeRouter: Subject<void> = new Subject<void>();
  assortment: Assortment;
  minQty = 1;
  step = 1;
  allowManualQtyInputOutsideStepSizes = false;
  customerId: string;

  formatter = (product: Product) => product.id + ' - ' + product.name;

  search: OperatorFunction<string, readonly { id; name }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(term => term.length >= 2),
      switchMap(term => {
        const searchRequest: SearchRequest = {
          terms: [...term.split(' ')],
          skip: 0,
          limit: 10,
          customerId: this.customerId,
          assortment: this.assortment?.id || 's0'
        };
        return forkJoin([of(term), this.productService.getPagedProducts(searchRequest)]);
      }),
      map(([term, products]) =>
        products.items./* filter(product => new RegExp(term, 'mi').test(product.name)). */ slice(0, 10)
      )
    );

  constructor(
    protected productService: ProductService,
    protected cartService: CartService,
    protected assortmentService: AssortmentService,
    protected productInputQuantityConfigService: ProductInputQuantityConfigService,
    protected selectedCustomerService: SelectedCustomerService
  ) {}

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.quickorderSummarize) {
      const rect = this.quickorderSummarize.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      const show = rect.top <= window.innerHeight;
      // this.isTestDivScrolledIntoView = topShown && bottomShown;
      this.isQuickorderSummarizeScrolledIntoView = show;
    }
  }

  ngOnInit(): void {
    this.selectedCustomerService.getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => {
        this.customerId = customer.id;
      });
    this.assortmentService
      .getUserAssortment()
      .pipe(first())
      .subscribe(assortment => {
        this.assortment = assortment;
      });
    this.cartService
      .getCurrentCart()
      .pipe(
        takeUntil(this.unsubscribeRouter),
        mergeMap(cart => {
          const ids = cart.map(c => c.productId);
          const cachedIds = Object.keys(this.cachedData);
          const toGetIds = ids.filter(id => !cachedIds.includes(id));
          return forkJoin([of(cart), this.productService.getProductsByIds(toGetIds).pipe(first())]);
        }),
        switchMap(([cart, products]) => {
          this.cachedData = {
            ...this.cachedData,
            ...products
              .map(p => p.id)
              .map(id => ({
                product: products.find(p => p.id === id)
                // price: resp.prices.find(p => p.productId === id),
                // availability: resp.availabilities.find(av => av.productId === id)
              }))
              .reduce((acc, curr) => ({ ...acc, [curr.product.id]: curr }), {})
          };
          return of(cart);
        })
      )
      .subscribe(cart => {
        this.cart = cart.reverse();
      });
  }

  ngOnDestroy() {
    this.unsubscribeRouter.next();
    this.unsubscribeRouter.complete();
  }

  selectProduct({ item, preventDefault }) {
    const row = this.cart.find(c => c.productId === item.id);
    this.minQty = this.productInputQuantityConfigService.getMinimumOrderQuantity(item.id, item);
    this.step = this.productInputQuantityConfigService.getStepAmount(item.id, item);
    this.allowManualQtyInputOutsideStepSizes =
      this.productInputQuantityConfigService.getAllowManualQtyInputOutsideStepSizes(item.id, item);
    if (row) {
      this.quantity = row.qty;
    } else {
      this.quantity = Math.max(this.minQty, this.step);
    }
    setTimeout(() => this.isScrolledIntoView(), 0);
  }

  selectProductById(productId: string) {
    this.productService
      .getProduct(productId)
      .pipe(first())
      .subscribe(product => {
        this.product = product;
        this.selectProduct({ item: product, preventDefault: null });
      });
  }

  addProductToCart(quantity, productId?: string) {
    quantity = Number(quantity);
    if (!this.product || !quantity) {
      return;
    }
    if (this.allowManualQtyInputOutsideStepSizes) {
      this.quantity = quantity;
    } else if (quantity >= this.minQty && (this.step === 1 || quantity % this.step === 0)) {
      this.quantity = quantity;
    } else if (quantity >= 0 && quantity % this.step !== 0) {
      quantity += this.step - (quantity % this.step);
      this.quantity = quantity;
    }
    if (this.cart.map(c => c.productId).includes(productId || this.product.id)) {
      this.cartService.setProductQuantity(productId || this.product.id, Number(this.quantity));
    } else {
      this.cartService.addToCart(productId || this.product.id, Number(this.quantity), undefined, undefined, undefined, undefined, undefined, this.product?.name);
    }
    this.clearFields();
  }

  clearFields() {
    this.product = undefined;
    this.quantity = undefined;
    setTimeout(() => this.isScrolledIntoView(), 0);
  }

  focusElement(ele, select: boolean) {
    ele.focus();
    if (select) {
      setTimeout(() => {
        ele.select();
      }, 10);
    }
  }
}
