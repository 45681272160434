import { Injectable } from '@angular/core';
import { Order } from '../../../models/order';
import { Observable, first, firstValueFrom, of, tap } from 'rxjs';
import { ProductionManagementOrderExportService } from './production-management-order-export.service';
import { printDocument } from '../../../utils/print-document';

@Injectable({
  providedIn: 'root'
})
export class ProductionManagementModalService {
  // This flag is only used temporarily while we implement the modal customer specific.
  // If we create a "standard" modal, we could remove this flag entirely.
  public printShippingModalEnabled = false;
  public printPlocksedelModalEnabled = false;
  public printFoljesedelModalEnabled = false;

  constructor(
    protected orderExportService: ProductionManagementOrderExportService
  ) {}

  public openCreateShipmentModal(order: Order): Promise<any> {
    console.log('ProductManagementModalService.openCreateShipmentModal - No implementation');
    return of(null).toPromise();
  }

  public openCreatePlocksedelModal(order: Order): Promise<any> {
    return firstValueFrom(this.orderExportService
      .exportOrders([order], 'PICKING_LIST')
      .pipe(
        first(),
        tap(data => printDocument(data.body, 'picking-list.pdf'))
      ));
  }

  public openCreateFoljesedelModal(order: Order): Promise<any> {
    return firstValueFrom(this.orderExportService
      .exportOrders([order], 'DELIVERY_NOTE_PROFORMA_COMBINED')
      .pipe(
        first(),
        tap(data => printDocument(data.body, 'deliverynote_proforma.pdf'))
      ));
  }
}
