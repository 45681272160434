import { Injectable, Type } from '@angular/core';
import { GungSettingsBaseViewComponent } from '../components/gung-settings-base-view/gung-settings-base-view.component';
import { Observable, of } from 'rxjs';
import { GungSettingsUsersViewComponent } from '../components/gung-settings/gung-settings-users-view/gung-settings-users-view.component';

export interface GungSettingsMenuItem {
  translationLabel: string,
  componentView: Type<GungSettingsBaseViewComponent> | undefined,
  prependIcon?: string,
  appendIcon?: string,
  isSeparator?: boolean,
  disabled?: boolean,
  hide?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class GungSettingsConfigService {
  public getGungSettingsMenu(): Observable<GungSettingsMenuItem[]> {
    const menu: GungSettingsMenuItem[] = [];

    menu.push({
      translationLabel: 'GENERAL',
      prependIcon: 'fa-light fa-gear',
      componentView: GungSettingsBaseViewComponent,
      disabled: true
    });

    menu.push({
      translationLabel: 'USERS',
      prependIcon: 'fa-light fa-user',
      componentView: GungSettingsUsersViewComponent
    });

    menu.push({
      translationLabel: 'ORDERS',
      prependIcon: 'fa-light fa-clipboard-list-check',
      componentView: GungSettingsBaseViewComponent,
      disabled: true
    });

    menu.push({
      translationLabel: '',
      componentView: undefined,
      isSeparator: true
    });

    return of(menu);
  }
}
