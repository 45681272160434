import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { format, isDate, isValid, parse, parseISO } from 'date-fns';
import {
  AuthService,
  Availability,
  AvailabilityService,
  BarcodeQuickCheckoutComponent,
  CartRow,
  CartService,
  CheckoutConfigService,
  Customer,
  CustomerContact,
  CustomerContactService,
  CustomerService,
  GungFlow,
  GungFlowService,
  GungModalService,
  mergeDeep,
  OrderSubmitService,
  ProductService,
  SelectedCustomerService
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { SystemSettings } from 'gung-standard/lib/models/system-setting';
import { User } from 'gung-standard/lib/state/auth/types';
import { forkJoin, merge, of, throwError } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs';
import {
  JeevesDeliveryAddressesService,
  JeevesOrderDeliveryAddress
} from '../../../services/jeeves-delivery-addresses.service';

@Component({
  selector: 'gung-jeeves-jeeves-barcode-quick-checkout',
  templateUrl: './jeeves-barcode-quick-checkout.component.html',
  styleUrls: ['./jeeves-barcode-quick-checkout.component.css']
})
export class JeevesBarcodeQuickCheckoutComponent extends BarcodeQuickCheckoutComponent implements OnInit {
  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected gungFlowService: GungFlowService,
    protected cartService: CartService,
    protected authService: AuthService,
    protected availabilityService: AvailabilityService,
    protected customerContactService: CustomerContactService,
    protected dateUtilService: DateUtilService,
    protected customerService: CustomerService,
    protected checkoutConfig: CheckoutConfigService,
    protected orderSubmitService: OrderSubmitService,
    public activeModal: NgbActiveModal,
    protected datePipe: DatePipe,
    protected gungModalService: GungModalService,
    protected translateService: TranslateService,
    protected productService: ProductService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService
  ) {
    super(
      selectedCustomerService,
      gungFlowService,
      cartService,
      authService,
      availabilityService,
      customerContactService,
      dateUtilService,
      customerService,
      checkoutConfig,
      orderSubmitService,
      activeModal,
      datePipe,
      gungModalService,
      translateService,
      productService
    );
  }

  protected setValues(
    customer: Customer,
    flow: GungFlow,
    user: User,
    customerContacts: CustomerContact[],
    billingCustomer: Customer,
    cartRows: CartRow[],
    availabilities: Availability[],
    systemSettings: SystemSettings,
    blockedProducts: { [productId: string]: boolean }
  ) {
    super.setValues(
      customer,
      flow,
      user,
      customerContacts,
      billingCustomer,
      cartRows,
      availabilities,
      systemSettings,
      blockedProducts
    );
    this.jeevesDeliveryAddressesService
      .getOrderedDeliveryAddressesForCustomer(customer.id)
      .pipe()
      .subscribe(deliveryAddresses => {
        this.assignJeevesDefaultsComponent(
          customer,
          flow,
          user,
          customerContacts,
          billingCustomer,
          cartRows,
          deliveryAddresses
        );
        this.loading = false;
      });
  }

  // InitCheckoutObjectComponent
  initCheckout(customer: Customer) {
    if (customer == null) {
      throwError('Could not find a selected customer');
      return;
    }
    this.checkout = {
      deliveryCustomerId: customer.id,
      extra: {},
      rows: []
    };
  }

  // AssignSystemSettingComponent
  assignSystemSetting(systemSettings: SystemSettings) {
    if (!!systemSettings && !!systemSettings.orderParams) {
      this.checkout = {
        ...this.checkout,
        extra: {
          ...this.checkout.extra,
          ...systemSettings.orderParams
        }
      };
    }
  }

  // AssignFlowSettingComponent
  assignFlowSetting(flow: GungFlow) {
    if (flow.orderParams) {
      const merged = mergeDeep(this.checkout.extra, flow.orderParams);
      this.checkout = {
        ...this.checkout,
        extra: {
          ...merged
        }
      };
    }
  }

  // RemoveBlockedProductsStepComponent
  removeBlockedProductsStepComponent(cartRows: CartRow[], blockedProducts: { [productId: string]: boolean }) {
    const blockedProductsIds: string[] = [];
    const rowsToRemove: any[] = [];
    for (const productId of Object.keys(blockedProducts)) {
      // in case product is blocked then remove it from the cart
      if (blockedProducts[productId]) {
        const cartRow = cartRows.find(cr => cr.productId === productId);
        if (!!cartRow) {
          rowsToRemove.push(cartRow);
          blockedProductsIds.push(productId);
        }
      }
    }
    this.cartService.bulkRemoveRows(rowsToRemove);

    if (blockedProductsIds.length > 0) {
      this.gungModalService
        .openConfirmYesNoModal(
          this.translateService.instant('BLOCKED_PRODUCTS'),
          this.translateService.instant('BLOCKED_PRODUCTS_MESSAGE', { products: blockedProductsIds.join(', ') }),
          null,
          'OK',
          null
        )
        .then(_ => {});
    }
  }

  // AssignOrderRowsComponent
  assignOrderRowsComponent(rows: CartRow[]) {
    this.checkout = {
      ...this.checkout,
      rows: rows.map(r => ({
        id: r.productId,
        partialId: r.productPartialId,
        targetStockId: r.targetStockId,
        quantity: r.qty,
        extra: r.extra || {},
        rownr: r.rownr
      }))
    };
  }

  // AssignJeevesDefaultsComponent
  assignJeevesDefaultsComponent(
    customer: Customer,
    flow: GungFlow,
    user: User,
    customerContacts: CustomerContact[],
    billingCustomer: Customer,
    cartRows: CartRow[],
    deliveryAddress: JeevesOrderDeliveryAddress[]
  ) {
    if (customer == null) {
      throwError('Could not find a selected customer');
      return;
    }
    if (!this.checkout.extra.oh) {
      this.checkout.extra.oh = {};
    }

    if (!this.checkout.extra.procargs) {
      this.checkout.extra.procargs = {};
    }

    this.checkout.extra._flow = flow;

    this.checkout.extra.confirmationEmails = this.checkout.extra.confirmationEmails || [];

    if (this.checkout.extra.confirmationEmails.indexOf(user.email) === -1) {
      this.checkout.extra.confirmationEmails.push(user.email);
    }

    this.checkout.extra.procargs.levsattkod = customer.extra.kus.levsattkod;
    this.checkout.extra.procargs.levvillkkod = customer.extra.kus.levvillkkod;
    this.checkout.extra.oh.betkod = customer.extra.kus.betkod;
    this.checkout.extra.procargs.saljare = customer.extra.kus.saljare;

    /* this.checkout.extra.procargs.ordlevadr2 = customer.extra.fr.ftgpostadr1;
    this.checkout.extra.procargs.ordlevadr3 = customer.extra.fr.ftgpostadr2;
    this.checkout.extra.procargs.ordlevadr4 = customer.extra.fr.ftgpostadr3;
    this.checkout.extra.procargs.ftgpostnr = customer.extra.fr.ftgpostnr;
    this.checkout.extra.procargs.ordlevadrlandskod = customer.extra.fr.landskod;
    this.checkout.extra.procargs.ordlevadrprovincecode = customer.extra.fr.provincecode; */

    if (!customer.extra.kus.kundbetalarenr) {
      this.checkout.billingCustomerId = customer.extra.kus.id;
    } else {
      this.checkout.billingCustomerId = customer.extra.kus.kundbetalarenr;
    }

    /*
      2021-03-29 - This should be done in all checkouts, but unsure of consequences right now
    */
    if (user.extra.orderParams) {
      mergeMap(this.checkout.extra, user.extra.orderParams);
    }

    // setDeliveryAddress
    if (!this.checkout.extra.procargs) {
      this.checkout.extra.procargs = {};
    }
    if (deliveryAddress?.length > 0) {
      this.checkout.extra.procargs = { ...this.checkout.extra.procargs, ...deliveryAddress[0].addressValues };
    }
  }
}
