import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { InvoiceStatistic } from '../../types';

@Injectable({
  providedIn: 'root'
})
export class InvoiceCustomerFilterService extends SimpleConfigBaseFilter<InvoiceStatistic> {
  constructor() {
    super();
  }

  getName(): string {
    return 'CUSTOMER';
  }
  getOptionIds(item: InvoiceStatistic): string[] {
    return [item.customerName];
  }
  getOptionName(key: string): string {
    return key;
  }
}
