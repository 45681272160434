import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { parse } from 'date-fns';
import {
  CheckoutStepComponent,
  AuthService,
  AvailabilityService,
  Availability,
  CartService,
  DeliveryDateService,
  SelectedCustomerService,
  CartRow,
  Customer,
  CalendarDate,
  SalesCartConfigService,
  CartConfigService,
  TableRecord,
  ProductService,
  Product
} from 'gung-standard';
import { distinctUntilChanged, forkJoin, of, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs';

interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'gung-jeeves-order-terms-with-cart-list-jeeves',
  templateUrl: './order-terms-with-cart-list-jeeves.component.html',
  styleUrls: ['./order-terms-with-cart-list-jeeves.component.css']
})
export class OrderTermsWithCartListJeevesComponent extends CheckoutStepComponent implements OnInit, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();
  loadingComponent = true;
  form: FormGroup;

  minDate: Date;

  isUser: boolean;
  cartRows: CartRow[];
  currentCustomer: Customer;
  dates: CalendarDate[];

  partDeliveryEntries = [
    { id: '0', name: 'OH_DELLEVTILLATEN_0' },
    { id: '10', name: 'OH_DELLEVTILLATEN_10' }
  ];

  orderWeight = 0;
  orderVolume = 0;

  alertMessageFreightCost: Alert;
  alertMessageAdminFee: Alert;

  firstLoad = true;

  // NOTE: CAN CHANGE FOR DIFERENT CLIENTS
  protected cartRowExtraDeliveryPlan = '_deliveryPlan';

  availabilities: { [productId: string]: Availability };

  constructor(
    protected authService: AuthService,
    protected formBuilder: FormBuilder,
    protected datePipe: DatePipe,
    protected availabilityService: AvailabilityService,
    protected cartService: CartService,
    protected deliveryDateService: DeliveryDateService,
    protected selectedCustomerService: SelectedCustomerService,
    public salesCartConfigService: SalesCartConfigService,
    public cartConfigService: CartConfigService,
    protected productService: ProductService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked());
    this.initForm();
    this.getData();
    // Calculate delivery date when delivery method changes
    this.form
      .get('levsattkod')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => {
        this.handlePartDeliveryChange();
        this.onDeliveryMethodChanged(value);
      });
    this.loadingComponent = false;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected initForm() {
    this.form = this.formBuilder.group({
      levsattkod: this.formBuilder.control(this.checkout.extra.procargs.levsattkod || ''),
      levvillkkod: this.formBuilder.control(this.checkout.extra.procargs.levvillkkod || ''),
      betkod: this.formBuilder.control(this.checkout.extra.oh.betkod || ''),
      saljare: this.formBuilder.control(this.checkout.extra.procargs.saljare || ''),
      dellevtillaten: this.formBuilder.control(this.checkout.extra.oh.dellevtillaten || '0')
      // ordstat: this.formBuilder.control(this.checkout.extra.oh.ordstat || 10),
    });
  }

  getData() {
    this.cartService
      .getCurrentCart()
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(cartRows =>
          forkJoin({
            cartRows: of(cartRows),
            avs: this.checkAllProductsInAvailabilities(
              cartRows.map(r => r.productId),
              this.availabilities
            )
              ? of(Object.values(this.availabilities))
              : this.availabilityService
                  .getAvailabilities(
                    cartRows.map(r => r.productId),
                    undefined,
                    true
                  )
                  .pipe(first()),
            dates: this.deliveryDateService.getDeliveryDates(this.form.get('levsattkod').value).pipe(first()),
            customer: this.selectedCustomerService.getSelectedCustomer().pipe(first()),
            roles: this.authService.getRoles().pipe(first()),
            products: this.productService.getFullProductsByIds(cartRows.map(row => row.productId)).pipe(first())
          })
        )
      )
      .subscribe(({ cartRows, avs, dates, customer, roles, products }) => {
        this.availabilities = avs.reduce((a, v) => ({ ...a, [v.productId]: v }), {});
        this.cartRows = JSON.parse(JSON.stringify(cartRows));
        this.currentCustomer = customer;
        this.dates = dates;
        this.isUser = roles.length === 1 && roles.includes('USER');

        this.calulcateOrderWeight(products);
        this.calulcateOrderVolume(products);
        this.calculateFreightCost();
        this.calculateAdminFee();

        // // Calculate delivery date when delivery method changes
        // this.form.get('levsattkod').valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
        //   this.handlePartDeliveryChange();
        //   this.onDeliveryMethodChanged(value);
        // });
        if (this.firstLoad && this.checkout.extra.oh.dellevtillaten !== '10') {
          this.firstLoad = !this.firstLoad;
          this.assignRowDates();
        }

        avs = avs.map(av => ({ ...av, availabilities: av.extra.availabilities }));
        const nowDate = new Date();
        let minDate = nowDate;
        for (const row of this.cartRows) {
          // if (row.extra.orp.ordberlevdat) {
          //   const tempDate = new Date(row.extra.orp.ordberlevdat);
          //   if (minDate < tempDate) {
          //     minDate = tempDate;
          //   }
          // } else {
          const av = avs.find(a => a.productId === row.productId);
          if (av) {
            const tempDate = this.getMinDate(av);
            if (minDate < tempDate) {
              minDate = tempDate;
            }
          }
        }
        this.minDate = minDate;
        this.loadingComponent = false;
      });
  }

  handlePartDeliveryChange(): void {
    this.loadingComponent = true;
    this.checkout.extra.oh.dellevtillaten = this.form.get('dellevtillaten')?.value || '';
    if (this.checkout.extra.oh.dellevtillaten === '0') {
      delete this.checkout.extra.oh.ordberlevdat;
      this.assignRowDates();
    } else {
      delete this.minDate;
      const requestAvs = this.checkAllProductsInAvailabilities(
        this.cartRows.map(r => r.productId),
        this.availabilities
      )
        ? of(Object.values(this.availabilities))
        : this.availabilityService.getAvailabilities(
            this.cartRows.map(r => r.productId),
            undefined,
            true
          );

      requestAvs
        .pipe(map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities }))))
        .subscribe(avs => {
          const nowDate = new Date();
          let minDateFromAvailability = nowDate;

          // let maxAssignedDate = '1970-01-01';
          for (const row of this.cartRows) {
            const av = avs.find(a => a.productId === row.productId);
            if (av) {
              const tempDate = this.getMinDate(av, row.qty);
              if (minDateFromAvailability < tempDate) {
                minDateFromAvailability = tempDate;
              }
            }
            // if (row.extra._user_selected_ordberlevdat && row.extra._user_selected_ordberlevdat > maxAssignedDate) {
            //   maxAssignedDate = row.extra._user_selected_ordberlevdat;
            // }
          }
          this.minDate = minDateFromAvailability;

          for (const testDate of this.dates) {
            if (testDate.date >= this.minDate) {
              if (testDate.valid) {
                this.minDate = testDate.date;
                break;
              }
            }
          }

          const dString: string = this.datePipe.transform(this.minDate, 'yyyy-MM-dd');

          this.checkout.extra.oh.ordberlevdat = this.checkout.extra.oh.ordberlevdat || dString;

          for (const row of this.cartRows) {
            // this.cartService.removeExtraField(row.productId, 'orp.ordberlevdat', row.targetStockId);
          }

          // if (dString > maxAssignedDate) {
          //   this.checkout.extra.oh.ordberlevdat = dString;
          // } else {
          //   this.checkout.extra.oh.ordberlevdat = maxAssignedDate;
          //   this.minDate = parse(maxAssignedDate, 'yyyy-MM-dd', this.minDate);
          // }
          this.loadingComponent = false;
          this.createFullDeliveryPlan();
        });
    }
  }

  assignRowDates(): void {
    if (!this.cartRows?.length) {
      return;
    }
    const requestAvs = this.checkAllProductsInAvailabilities(
      this.cartRows.map(r => r.productId),
      this.availabilities
    )
      ? of(Object.values(this.availabilities))
      : this.availabilityService.getAvailabilities(
          this.cartRows.map(r => r.productId),
          undefined,
          true
        );
    requestAvs.pipe(map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities })))).subscribe(avs => {
      const bulkExtra = [];
      for (const row of this.cartRows) {
        const av = avs.find(a => a.productId === row.productId);
        row.extra._availability_reference = av;
        let mindate = null;
        if (av) {
          const tempDate = this.getMinDate(av, row.qty);
          const nowDate = new Date();
          let minDate = nowDate;
          if (minDate < tempDate) {
            minDate = tempDate;
          }

          for (const testDate of this.dates) {
            const dString1: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
            const dString2: string = this.datePipe.transform(testDate.date, 'yyyy-MM-dd');

            // We cant include time when comparing
            if (dString2 >= dString1) {
              if (testDate.valid) {
                minDate = testDate.date;
                break;
              }
            }
          }

          const dString: string = this.datePipe.transform(minDate, 'yyyy-MM-dd');
          mindate = dString;
        }

        row.extra._calc_min_date = mindate;
        row.extra.orp = row.extra.orp || {};
        row.extra.orp.ordberlevdat = row.extra._calc_min_date;
        if (row.extra._user_selected_ordberlevdat) {
          if (row.extra._user_selected_ordberlevdat > row.extra._calc_min_date) {
            row.extra.orp.ordberlevdat = row.extra._user_selected_ordberlevdat;
          }
          if (new Date(row.extra._user_selected_ordberlevdat) > new Date(row.extra._calc_min_date)) {
            // row.extra.orp.ordberlevdat = row.extra._user_selected_ordberlevdat;
            // TODO: Check if the selected date is in the available dates in this delivery method
            // this.deliveryDateService.checkIfDateInDeliveryDates(new Date(row.extra._user_selected_ordberlevdat));
          }
        }

        const deliveryPlan = {};
        const dates = Object.keys(av.availabilities);
        let quantityRemaining = row.qty;
        for (const date of dates) {
          const qty = av.availabilities[date];
          if (qty > 0 && quantityRemaining > 0) {
            const parsedDate = parse(date, 'yyMMdd', new Date());
            const formattedDate = this.datePipe.transform(parsedDate, 'yyyy-MM-dd');

            let assignedDate = formattedDate;
            if (row.extra._user_selected_ordberlevdat > assignedDate) {
              assignedDate = row.extra._user_selected_ordberlevdat;
            }
            const currentOnDate = deliveryPlan[assignedDate] || 0;
            const currentAllocation = Math.min(qty, quantityRemaining);

            deliveryPlan[assignedDate] = currentOnDate + currentAllocation;

            quantityRemaining = quantityRemaining - currentAllocation;
          }
        }
        if (quantityRemaining > 0) {
          // Handle edge case of last quantities of expiring product
          deliveryPlan['2099-12-31'] = quantityRemaining;
        }
        row.extra[this.cartRowExtraDeliveryPlan] = deliveryPlan;
        row.extra._dellevtillaten = this.checkout.extra.oh.dellevtillaten;
        row.extra._levsattkod = this.form.get('levsattkod').value;
        bulkExtra.push({
          productId: row.productId,
          extra: row.extra,
          targetStockId: row.targetStockId,
          productPartialId: row.productPartialId
        });
      }
      this.cartService.bulkSetExtra(bulkExtra);
      this.loadingComponent = false;
    });
  }

  optionsFilterDeliveryMethod(customer: Customer, option: { key: string; value: string | TableRecord }): boolean {
    const allowedDeliveryMethod = [];
    if (this.checkout.extra.customerVATCode === 7) {
      allowedDeliveryMethod.push(...['80', '83', '81', '75']);
    } else if (this.checkout.extra.customerVATCode === 9) {
      allowedDeliveryMethod.push(...['85', '88', '86', '75']);
    } else {
      if (this.orderWeight >= 10) {
        allowedDeliveryMethod.push(...['1', '5']);
      } else {
        allowedDeliveryMethod.push(...['1', '5', '66', '67']);
      }
    }

    if (
      customer.extra.kus.levsattkod &&
      allowedDeliveryMethod.findIndex(d => d.id === customer.extra.kus.levsattkod + '') === -1
    ) {
      allowedDeliveryMethod.push(customer.extra.kus.levsattkod + '');
    }
    return allowedDeliveryMethod.includes(option.key);
  }

  onDeliveryMethodChanged(levsattkod: string) {
    this.calculateFreightCost();
  }

  calulcateOrderWeight(products: Product[]): void {
    this.orderWeight = 0;
    // artbtotvikt
    products
      .filter(product => !!product.extra.ar?.artbtotvikt)
      .forEach(product => {
        const quantity = this.cartRows.find(row => row.productId === product.id).qty;
        this.orderWeight += quantity * parseFloat(product.extra.ar.artbtotvikt);
      });
  }

  calulcateOrderVolume(products: Product[]): void {
    this.orderVolume = 0;
    // artvolym
    products
      .filter(product => !!product.extra.ar?.artvolym)
      .forEach(product => {
        const quantity = this.cartRows.find(row => row.productId === product.id).qty;
        this.orderVolume += quantity * parseFloat(product.extra.ar.artvolym);
      });

    this.orderVolume *= 280; // One cubic metre = 280 kg in freight costs. This is standard for freight costs around the world. // Adam
  }

  calculateFreightCost(): void {
    this.alertMessageFreightCost = null;

    const selectedLevsattkodValue = this.form.get('levsattkod').value || '';
    this.checkout.extra.additionalCosts = {
      ...this.checkout.extra.additionalCosts,
      freight: null
    };

    return;
  }

  /**
   * calculateAdminFee - Check min value and add admin charge
   */
  calculateAdminFee() {
    this.alertMessageAdminFee = null;
    this.checkout.extra.additionalCosts = {
      ...this.checkout.extra.additionalCosts,
      adminFee: null
    };
    return;
  }

  handleNextButtonClicked(): void {
    if (this.form.invalid) {
      return;
    }

    this.assignFormValues(this.form);
    this.stepDone.emit(this.checkout);
  }

  protected assignFormValues(formGroup: FormGroup) {
    const rawValues = formGroup.getRawValue();
    this.checkout.extra = {
      ...this.checkout.extra,
      procargs: {
        ...this.checkout.extra.procargs,
        levsattkod: rawValues.levsattkod,
        levvillkkod: rawValues.levvillkkod,
        saljare: rawValues.saljare
      },
      oh: {
        ...this.checkout.extra.oh,
        betkod: rawValues.betkod
      }
    };
  }

  dateSelected(event) {
    const date = event.date;
    const dString: string = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.checkout.extra.oh.ordberlevdat = dString;
    this.createFullDeliveryPlan();
    // this.handlePartDeliveryChange();
  }

  createFullDeliveryPlan() {
    const sDate = this.checkout.extra.oh.ordberlevdat;
    const bulkExtra = [];
    for (const row of this.cartRows) {
      // row.deliveryDate = sDate;
      row.extra.orp = row.extra.orp || {};
      row.extra.orp.ordberlevdat = sDate;
      const deliveryPlan = {};
      deliveryPlan[sDate] = row.qty;
      // This is important because used by backend to split lines
      row.extra[this.cartRowExtraDeliveryPlan] = deliveryPlan;
      row.extra._dellevtillaten = this.checkout.extra.oh.dellevtillaten;
      row.extra._levsattkod = this.form.get('levsattkod').value;
      bulkExtra.push({
        productId: row.productId,
        extra: row.extra,
        targetStockId: row.targetStockId,
        productPartialId: row.productPartialId
      });
    }
    this.cartService.bulkSetExtra(bulkExtra);
  }

  getMinDate(av: Availability, quantity = 1): Date {
    if (av.currentAvailability > 0) {
      return new Date();
    }
    if (av.maxFutureStock === 0) {
      return new Date();
    }
    const valids = Object.keys(av.availabilities).filter(entry => av.availabilities[entry] >= quantity);
    const dateFormat = 'yyMMdd';
    return valids.length > 0 ? parse(valids[0], dateFormat, new Date()) : new Date();
  }

  getLabel(): string {
    return 'CART' || 'TERMS_AND_CONDITIONS';
  }

  checkAllProductsInAvailabilities(productIds: string[], availabilities = this.availabilities): boolean {
    if (!(productIds?.length > 0)) {
      return false;
    }
    for (const id of productIds) {
      if (!availabilities?.[id]) {
        return false;
      }
    }
    return true;
  }
}
