import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { GungDashboardComponent } from './components/gung-dashboard/gung-dashboard.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { DashboardCardCreatorComponent } from './components/dashboard-card-creator/dashboard-card-creator.component';
import { InvoiceStatisticsChartComponent } from './components/charts/invoice-statistics-chart/invoice-statistics-chart.component';
import { MonthlySalesSummaryCardComponent } from './components/cards/monthly-sales-summary-card/monthly-sales-summary-card.component';
import { MonthlySalesAmountSummaryCardComponent } from './components/cards/monthly-sales-amount-summary-card/monthly-sales-amount-summary-card.component';
import { SalesSummaryCardComponent } from './components/cards/sales-summary-card/sales-summary-card.component';
import { FormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { FilterCardLargeComponent } from './components/cards/filter-card-large/filter-card-large.component';
import { GungListModule } from 'gung-list';
import { RouterModule } from '@angular/router';
import { InvoiceFilteredStatisticsComponent } from './components/invoice-filtered-statistics/invoice-filtered-statistics.component';
import { CustomersWhoPurchasedCardComponent } from './components/cards/customers-who-purchased-card/customers-who-purchased-card.component';
import { GungStandardModule } from 'gung-standard';
import { ActiveProductsCardComponent } from './components/cards/active-products-card/active-products-card.component';
import { InactiveCustomersCardComponent } from './components/cards/inactive-customers-card/inactive-customers-card.component';
import { InvoiceStatisticsFieldChartComponent } from './components/charts/invoice-statistics-field-chart/invoice-statistics-field-chart.component';
import { InvoiceStatisticsChartCardComponent } from './components/cards/invoice-statistics-chart-card/invoice-statistics-chart-card.component';
import { LastVsThisYearSalesAmountSummaryCardComponent } from './components/cards/last-vs-this-year-sales-amount-summary-card/last-vs-this-year-sales-amount-summary-card.component';
import { LastVsThisMonthSalesAmountSummaryCardComponent } from './components/cards/last-vs-this-month-sales-amount-summary-card/last-vs-this-month-sales-amount-summary-card.component';
import { LastVsThisWeekSalesAmountSummaryCardComponent } from './components/cards/last-vs-this-week-sales-amount-summary-card/last-vs-this-week-sales-amount-summary-card.component';
import { LastSevenDaysOrdersSummaryCardComponent } from './components/cards/last-seven-days-orders-summary-card/last-seven-days-orders-summary-card.component';
import { ImportBudgetComponent } from './components/import/import-budget/import-budget.component';
import { UpcomingActivitiesCardComponent } from './components/cards/upcoming-activities-card/upcoming-activities-card.component';
import { UpcomingActivitiesTableCardComponent } from './components/cards/upcoming-activities-table-card/upcoming-activities-table-card.component';
import { OpenOrdersTableCardComponent } from './components/cards/open-orders-table-card/open-orders-table-card.component';
import { LastVsThisYearOfferAmountSummaryCardComponent } from './components/cards/last-vs-this-year-offer-amount-summary-card/last-vs-this-year-offer-amount-summary-card.component';
import { LastVsThisMonthOffersAmountSummaryCardComponent } from './components/cards/last-vs-this-month-offers-amount-summary-card/last-vs-this-month-offers-amount-summary-card.component';
import { LastVsThisWeekOffersAmountSummaryCardComponent } from './components/cards/last-vs-this-week-offers-amount-summary-card/last-vs-this-week-offers-amount-summary-card.component';
import { OfferStatisticsChartCardComponent } from './components/cards/offer-statistics-chart-card/offer-statistics-chart-card.component';
import { DashboardTotalsCardComponent } from './components/cards/dashboard-totals-card/dashboard-totals-card.component';
import { OfferTypesCardComponent } from './components/cards/offer-types-card/offer-types-card.component';
import { StatisticsExcelExportCardComponent } from './components/cards/statistics-excel-export-card/statistics-excel-export-card.component';
import { GungCommonModule } from 'gung-common';

@NgModule({
  declarations: [
    GungDashboardComponent,
    DashboardCardCreatorComponent,
    InvoiceStatisticsChartComponent,
    MonthlySalesSummaryCardComponent,
    MonthlySalesAmountSummaryCardComponent,
    SalesSummaryCardComponent,
    FilterCardLargeComponent,
    InvoiceFilteredStatisticsComponent,
    CustomersWhoPurchasedCardComponent,
    ActiveProductsCardComponent,
    InactiveCustomersCardComponent,
    InvoiceStatisticsFieldChartComponent,
    InvoiceStatisticsChartCardComponent,
    LastVsThisYearSalesAmountSummaryCardComponent,
    LastVsThisMonthSalesAmountSummaryCardComponent,
    LastVsThisWeekSalesAmountSummaryCardComponent,
    LastSevenDaysOrdersSummaryCardComponent,
    ImportBudgetComponent,
    UpcomingActivitiesCardComponent,
    UpcomingActivitiesTableCardComponent,
    OpenOrdersTableCardComponent,
    LastVsThisYearOfferAmountSummaryCardComponent,
    LastVsThisMonthOffersAmountSummaryCardComponent,
    LastVsThisWeekOffersAmountSummaryCardComponent,
    OfferStatisticsChartCardComponent,
    DashboardTotalsCardComponent,
    OfferTypesCardComponent,
    StatisticsExcelExportCardComponent
  ],
  imports: [
    NgbModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    NgChartsModule,
    GungListModule,
    GungStandardModule,
    GungCommonModule,
    FormsModule,
    RouterModule,
    BrowserModule,
    TranslateModule
  ],
  exports: [GungDashboardComponent, InvoiceStatisticsChartComponent, DashboardCardCreatorComponent, LastVsThisMonthSalesAmountSummaryCardComponent]
})
export class GungDashboardModule { }
