<div class="d-flex image-carousel-minimal">
    <ngb-carousel
        #carousel
        [interval]="interval"
        [showNavigationArrows]="false" 
        [showNavigationIndicators]="false"
        (slide)="onSlide($event)"
    >
        <ng-template ngbSlide *ngFor="let image of images; let i = index" [id]="(i + '')">
            <div [class]="imageClass">
                <img [src]="image.s3Uri | gungImageUrl
                    :'type:list'
                    :'etag:'+image.s3ETag
                    :'size:'+imageSize
                    :'orientation:'+orientation
                "
                width="{{width}}"
                height="{{height}}"
                alt="{{image?.description || ''}}"
                class="img-fluid"
                loading=lazy
                [ngStyle]="{width: width, height: height}"
            />
            </div>
        </ng-template>
    </ngb-carousel>
    <button *ngIf="images?.length > 1 && !disableProductListImageCarousel" type="button" class="btn btn-link arrow arrow-left" (click)="$event.preventDefault(); $event.stopPropagation(); carousel.prev()">
        <i class="fa-solid fa-angle-left"></i>
    </button>
    <button *ngIf="images?.length > 1 && !disableProductListImageCarousel" type="button" class="btn btn-link arrow arrow-right" (click)="$event.preventDefault(); $event.stopPropagation(); carousel.next()">
        <i class="fa-solid fa-angle-right"></i>
    </button>
    <div class="indicators" *ngIf="images?.length > 1 && !disableProductListImageCarousel">
        <div *ngFor="let image of images; let i = index" class="indicator"
            [class.selected]="(i + '') === currentSlide"
            (click)="$event.preventDefault(); $event.stopPropagation(); carousel.select(i + '')"></div>
    </div>
</div>