import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ButtonsComponent,
  NavbarCartService,
  GungModalService,
  SaveCartModalConfigService,
  CartService,
  AuthService,
  CheckoutObject
} from 'gung-standard';

@Component({
  selector: 'sjoborg-buttons',
  templateUrl: './sjoborg-buttons.component.html',
  styleUrls: ['./sjoborg-buttons.component.scss']
})
export class SjoborgButtonsComponent extends ButtonsComponent {
  public isLastStep: boolean;

  public nextAlternativeBtnText: string;
  @Output()
  nextAlternativeClicked = new EventEmitter<void>();

  updateData() {
    super.updateData();
    this.isLastStep = this.allSteps.indexOf(this.currentStep) === this.allSteps.length - 1;
  }

  updateBtnTexts(currentCheckout: CheckoutObject): void {
    super.updateBtnTexts(currentCheckout);
    this.nextAlternativeBtnText = 'NEXT_ALTERNATIVE';
    const btnTexts = this.currentStep.prototype.getBtnTexts();
    if (!btnTexts) {
      return;
    }
    this.nextAlternativeBtnText = btnTexts.nextAlternative || this.nextAlternativeBtnText;
  }
}
