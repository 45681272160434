import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs';
import {EdiOrder, EdiOrderItem, EdiOrderMessageRelations, EdiPurchaseOrder, GungEdiError} from '../../../models/edi';
import { EdiDispatchAdvicesActionConfigService } from '../../../services/edi-dispatch-advices-action-config/edi-dispatch-advices-action-config.service';
import { EdiInvoicesActionConfigService } from '../../../services/edi-invoices-action-config/edi-invoices-action-config.service';
import { EdiOrderActionConfigService } from '../../../services/edi-order-action-config/edi-order-action-config.service';
import { EdiRespondeOrderResponseActionConfigService } from '../../../services/edi-order-response-action-config/edi-order-response-action-config.service';

import { EdiService } from '../../../services/edi/edi.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { EdiModalCancelLineComponent } from '../edi-modal/edi-modal-cancel-line/edi-modal-cancel-line.component';
import { EdiModalChangeLineComponent } from '../edi-modal/edi-modal-change-line/edi-modal-change-line.component';
import { EdiRowTable, EdiRowTableType } from '../edi-row-table/edi-row-table.component';
import { EdiModalChangeQuantityComponent } from '../edi-modal/edi-modal-change-quantity/edi-modal-change-quantity.component';
import {
  EdiPurchasedOrderActionConfigService
} from "../../../services/edi-purchased-order-action-config/edi-purchased-order-action-config.service";

@Component({
  selector: 'lib-edi-order-detail',
  templateUrl: './edi-purchased-order-detail.component.html',
  styleUrls: ['./edi-purchased-order-detail.component.css']
})
export class EdiPurchasedOrderDetailComponent implements OnInit {
  orderId: string;
  orderData;

  relations: EdiOrderMessageRelations;
  invoiceMap: any;
  ordrspMap: any;
  desadvMap: any;

  invoicesToGenerate = [];
  ordrspToGenerate = [];
  despachtsToGenerate = [];

  invoiceId: string;

  itemsColumns: EdiRowTable[] = [
    {
      header: 'IMAGE',
      type: EdiRowTableType.IMAGE,
      getValue: (item: EdiPurchaseOrder, index: number) => item?.extra?.images
    },
    {
      header: 'ITEMID',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiPurchaseOrder, index: number) => {
        let str = '';
        if (item.name) {
          str += this.translateService.instant('NAME', {}) + ': ' + item.name;
        }
        if (item.ids.gungId) {
          if (!!str) {
            str += '\n';
          }
          str += this.translateService.instant('ID', {}) + ': ' + item.ids.gungId;
        }
        if (item.ids.ean13) {
          if (!!str) {
            str += '\n';
          }
          str += this.translateService.instant('EAN', {}) + ': ' + item.ids.ean13;
        }
        if (item.ids.upca) {
          if (!!str) {
            str += '\n';
          }
          str += this.translateService.instant('UPCA', {}) + ': ' + item.ids.upca;
        }
        if (item.ids.buyerId) {
          if (!!str) {
            str += '\n';
          }
          str += this.translateService.instant('BUYER_ID', {}) + ': ' + item.ids.buyerId;
        }
        return str;
      },
      customClass: 'formatted'
    },
    {
      header: 'QUANTITY',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiPurchaseOrder, index: number) => {
        if (item.quantities.expectedQuantity) {
          return 'EXP: ' + item.quantities.expectedQuantity;
        }
        return '';
      }
    },
    {
      header: 'PRICE',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiPurchaseOrder, index: number) => {
        if (item.prices.expectedNetPrice) {
          return 'EXP: ' + item.prices.expectedNetPrice.value;
        }
        return '';
      }
    },
    {
      header: 'DELIVERY_DATE',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiPurchaseOrder, index: number) => '?'
    },
    {
      header: 'STATUS',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiPurchaseOrder, index: number) => '?'
    },
    {
      header: 'SECONDARY_TABLE',
      type: EdiRowTableType.TABLE,
      getValue: (item: EdiPurchaseOrder, index: number) => item.errors,
      secondaryColumn: [
        {
          header: 'Type',
          type: EdiRowTableType.IMAGE,
          getValue: (item: EdiPurchaseOrder, index: number) => item.type
        },
        {
          header: 'Message',
          type: EdiRowTableType.TEXT,
          getValue: (item: EdiPurchaseOrder, index: number) => item.error
        },
        {
          header: 'Source',
          type: EdiRowTableType.TEXT,
          getValue: (item: EdiPurchaseOrder, index: number) => item.validator
        },
        {
          header: 'Action',
          type: EdiRowTableType.ACTION,
          getValue: (item: EdiOrderItem, index: number) => [
            {
              header: 'CANCEL_LINE',
              getValue: (subItem: GungEdiError) => this.cancelLineAndRevalidate(item, index),
              customClass: 'btn btn-sm btn-block btn-primary'
            },
            {
              header: 'CHANGE_ITEM_ID',
              getValue: (subItem: EdiOrder) => this.changeLineAndRevalidate(item, index),
              customClass: 'btn btn-sm btn-block btn-secondary'
            }
          ]
        }
      ]
    }
  ];

  constructor(
    protected route: ActivatedRoute,
    protected ediService: EdiService,
    protected http: HttpClient,
    protected modalService: NgbModal,
    protected gungModalService: GungModalService,
    public orderActionConfigService: EdiPurchasedOrderActionConfigService,
    public orderResponseActionConfigService: EdiRespondeOrderResponseActionConfigService,
    public dispatchActionConfigService: EdiDispatchAdvicesActionConfigService,
    public invoicesActionConfigService: EdiInvoicesActionConfigService,
    protected translateService: TranslateService
  ) {
    this.invoiceMap = {};
    this.ordrspMap = {};
    this.desadvMap = {};
  }

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      this.orderId = data.id;
      this.ediService.getPurchasedOrder(this.orderId).subscribe(orderData => {
        this.orderData = orderData.data || orderData;
      });

      //this.updateRelations();
    });
  }

  updateRelations(): void {
    this.ediService
      .getOrderMessageRelations(this.orderId)
      .pipe(first())
      .subscribe(relations => {
        this.relations = relations;
        if (this.relations.erpInvoiceIds) {
          this.relations.erpInvoiceIds.forEach(invoiceId => {
            this.getInvoicesMessagesByOrder(invoiceId)
              .pipe(first())
              .subscribe(ediInvoices => {
                if (ediInvoices.length > 0) {
                  this.invoiceMap[invoiceId] = ediInvoices;
                } else {
                  this.invoicesToGenerate.push(invoiceId);
                }
              });
          });
        }

        if (this.relations.erpDesadvIds) {
          this.relations.erpDesadvIds.forEach(despatchId => {
            this.getDespatchsMessagesByOrder(despatchId)
              .pipe(first())
              .subscribe(ediDespatches => {
                if (ediDespatches.length > 0) {
                  this.desadvMap[despatchId] = ediDespatches;
                } else {
                  this.despachtsToGenerate.push(despatchId);
                }
              });
          });
        }

        if (this.relations.erpOrderIds) {
          this.relations.erpOrdrspIds.forEach(orderId => {
            this.getOrdersMessagesByOrder(orderId)
              .pipe(first())
              .subscribe(ediOrdrsps => {
                if (ediOrdrsps.length > 0) {
                  this.ordrspMap[orderId] = ediOrdrsps;
                } else {
                  this.ordrspToGenerate.push(orderId);
                }
              });
          });
        }
      });
  }

  getInvoicesMessagesByOrder(invoiceId): Observable<any> {
    return this.http.get('json/edi/v2/invoice/messages/by-invoice-id?invoiceId=' + invoiceId);
  }

  getDespatchsMessagesByOrder(despatchId): Observable<any> {
    return this.http.get('json/edi/v2/despatch/messages/by-despatch-id?despatchId=' + despatchId);
  }

  getOrdersMessagesByOrder(orderId): Observable<any> {
    return this.http.get('json/edi/v2/order-response/messages/by-order-id?orderId=' + orderId);
  }

  sendToErp() {
    const data = { data: this.orderData };
    this.ediService.sendToErp(this.orderId, data).subscribe(postdata => {
      console.log(postdata);
    });
  }

  cancelLineAndRevalidate(line: any, index: number) {
    // line.changes = line.changes || {};
    // line.changes.cancelled = true;
    const modalRef = this.modalService.open(EdiModalCancelLineComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static'
    });
    modalRef.componentInstance.ediOrderId = this.orderData.id;
    modalRef.componentInstance.ediOrderItem = line;
    modalRef.componentInstance.index = index;
    modalRef.result.then(
      result => {
        if (result) {
          if (result.validate) {
            this.validateAgain();
          }
          this.gungModalService
            .openConfirmYesNoModal(undefined, 'Sucessfully cancelled line', { size: 'sm' }, 'OK', null)
            .then(_ => {});
        }
      },
      reason => {}
    );
  }

  changeLineAndRevalidate(line: EdiOrderItem, index: number) {
    const modalRef = this.modalService.open(EdiModalChangeLineComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static'
    });
    modalRef.componentInstance.ediOrderId = this.orderData.id;
    modalRef.componentInstance.ediOrderItem = line;
    modalRef.componentInstance.index = index;
    modalRef.result.then(
      result => {
        if (result && result.response) {
          if (result.validate) {
            this.validateAgain();
          }
          this.gungModalService
            .openConfirmYesNoModal(undefined, 'Sucessfully changed line', { size: 'sm' }, 'OK', null)
            .then(_ => {});
        }
      },
      reason => {}
    );
  }

  changeQuantityAndRevalidate(line: EdiOrderItem, index: number): void {
    const modalRef = this.modalService.open(EdiModalChangeQuantityComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static'
    });
    modalRef.componentInstance.ediOrderId = this.orderData.id;
    modalRef.componentInstance.ediOrderItem = line;
    modalRef.componentInstance.index = index;
    modalRef.result.then(
      result => {
        if (result && result.response) {
          if (result.validate) {
            this.validateAgain();
          }
          this.gungModalService
            .openConfirmYesNoModal(undefined, 'Sucessfully changed line', { size: 'sm' }, 'OK', null)
            .then(_ => {});
        }
      },
      reason => {}
    );
  }

  validateAgain(): any {
    this.http
      .get('json/edi/v2/order/messages/revalidate-by-message-id?messageId=' + this.orderId)
      .pipe(first())
      .subscribe(newOrderData => {
        this.orderData = newOrderData;
        this.updateRelations();
      });
  }

  sendOrderResponseByOrderId(orderId: string): any {
    this.http
      .get('json/edi/v2/actuator/ordrsp/generate-by-order-id-get?orderId=' + orderId)
      .pipe(first())
      .subscribe(response => {});
  }
  sendInvoiceByInvoiceId(invoiceId: string): any {
    this.http
      .get('json/edi/v2/actuator/invoice/generate-by-invoice-id-get?invoiceId=' + invoiceId)
      .pipe(first())
      .subscribe(response => {});
  }
  sendDesadvByDesadvId(despatchId: string): any {
    this.http
      .get('json/edi/v2/actuator/desadv/generate-by-despatch-system-id-get?despatchSystemId=' + despatchId)
      .pipe(first())
      .subscribe(response => {});
  }

  sendOrderResponse() {
    const data = { data: this.orderData };
    this.ediService.sendOrderResponse(this.orderId, data).subscribe(postdata => {
      console.log(postdata);
    });
  }

  sendInvoices() {}
}
