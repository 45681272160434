<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" *ngIf="form">
  <form class="form" [formGroup]="form">
    <fieldset [disabled]="disabled">
      <div class="form-row">
        <div *ngIf="deliveryLocation.id" class="col-12">
          <lib-gung-text-input-horizontal
            renderStatic="true"
            gungTranslate="FR_FTGNR"
            [formControlName]="'ftgnr'"
          ></lib-gung-text-input-horizontal>
        </div>
        <div class="col-12">
          <lib-gung-text-input-horizontal
            gungTranslate="FR_FTGNAMN"
            [formControlName]="'ftgnamn'"
          ></lib-gung-text-input-horizontal>
        </div>
        <div class="col-12">
          <lib-gung-text-input-horizontal
            gungTranslate="FR_FTGPOSTADR1"
            [formControlName]="'ftgpostadr1'"
          ></lib-gung-text-input-horizontal>
        </div>
        <div class="col-12">
          <lib-gung-text-input-horizontal
            gungTranslate="FR_FTGPOSTADR2"
            [formControlName]="'ftgpostadr2'"
          ></lib-gung-text-input-horizontal>
        </div>
        <div class="col-12">
          <lib-gung-text-input-horizontal
            gungTranslate="FR_FTGPOSTNR"
            [formControlName]="'ftgpostnr'"
          ></lib-gung-text-input-horizontal>
        </div>
        <div class="col-12">
          <lib-gung-text-input-horizontal
            gungTranslate="FR_FTGPOSTADR3"
            [formControlName]="'ftgpostadr3'"
          ></lib-gung-text-input-horizontal>
        </div>
        <div class="col-12">
          <lib-gung-text-input-horizontal
            gungTranslate="FR_LANDSKOD"
            [formControlName]="'landskod'"
          ></lib-gung-text-input-horizontal>
        </div>
      </div>
    </fieldset>
  </form>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-light mr-auto"
    aria-label="Close"
    (click)="activeModal.close()"
    [disabled]="disabled"
    translate
  >
    CANCEL
  </button>
  <button type="button" class="btn btn-primary ml-auto" (click)="submitForm()" [disabled]="disabled" translate>
    SAVE_CHANGES
  </button>
</div>
