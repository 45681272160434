import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthService,
  CartService,
  FavouriteProductsService,
  GungFlowService,
  NavbarComponent,
  NavbarConfigService,
  SelectedCustomerService,
  GlobalSearchConfigService
} from 'gung-standard';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'sjoborg-navbar',
  templateUrl: './sjoborg-navbar.component.html',
  styleUrls: ['./sjoborg-navbar.component.scss']
})
export class SjoborgNavbarComponent extends NavbarComponent implements OnInit {
  favouritesCount = 0;
  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected authService: AuthService,
    protected cartService: CartService,
    protected elementRef: ElementRef,
    protected navbarConfigService: NavbarConfigService,
    protected gungFlowService: GungFlowService,
    protected router: Router,
    protected favouriteProductsService: FavouriteProductsService,
    protected globalSearchConfig: GlobalSearchConfigService
  ) {
    super(selectedCustomerService, authService, cartService, elementRef, navbarConfigService, gungFlowService, router, globalSearchConfig);
  }

  ngOnInit() {
    super.ngOnInit();
    this.favouriteProductsService
      .getFavourites()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (favourites: any[]) => (this.favouritesCount = Object.keys(favourites).filter(p => favourites[p]).length)
      );
  }
}
