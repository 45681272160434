import { Component, OnInit } from '@angular/core';
import { AuthService, AvailabilityService, CartListComponent, CartService, GungModalService, PriceService, ProductService } from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { first } from 'rxjs';

@Component({
  selector: 'sjoborg-cart-list',
  templateUrl: './sjoborg-cart-list.component.html',
  styleUrls: ['./sjoborg-cart-list.component.scss']
})
export class SjoborgCartListComponent extends CartListComponent implements OnInit {
  isSalesOrAdmin: boolean = false;

  constructor(
    protected productService: ProductService,
    protected cartService: CartService,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    protected dateUtilService: DateUtilService,
    protected gungModalService: GungModalService,
    protected authService: AuthService
  ) {
    super(productService, cartService, priceService, availabilityService, dateUtilService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.authService.getRoles().pipe(first()).subscribe(roles => {
      this.isSalesOrAdmin = roles.findIndex(r => r ==='ADMIN') > -1 || roles.findIndex(r => r ==='SALES') > -1;
    });
  }

  getPackageSize(item: any): number {
    if (!!item.extra.ar.artfsgforp) {
      return parseInt(item.extra.ar.artfsgforp, 10);
    }

    return 0;
  }

  addNewOrderLine(): void {
    this.gungModalService.addRowCheckoutModal().then(
      result => {
        if (result) {
        }
      },
      reason => {}
    );
  }
}
