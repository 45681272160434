<div class="dashboard-card card" *ngIf="loaded">
  <div class="card-body dashboard-card-content">
    <div class="title" translate>SALES_AMOUNT_PER_MONTH</div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>THIS_MONTH</span>
      </div>
      <span class="current-statistic text-primary">{{ salesAmountThisMonth | number: '1.0-0' }}</span>
    </div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>LAST_MONTH</span>
      </div>
      <span class="current-statistic text-primary">{{ salesAmountLastMonth | number: '1.0-0' }}</span>
    </div>

    <div class="progress-container mt-2">
      <ngb-progressbar [animated]="true" [value]="(salesAmountThisMonth / salesAmountLastMonth) * 100">
      </ngb-progressbar>
      <div class="d-flex justify-content-between">
        <span class="sub-title">{{ ((salesAmountThisMonth / salesAmountLastMonth) * 100 || 0).toFixed() }}%</span>
        <span class="sub-title" translate>COMPARED_LAST_MONTH</span>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-card card" *ngIf="!loaded">
  <div class="card-body dashboard-card-content">
    <div class="text-center dashboard-loading-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
