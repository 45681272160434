import { Component } from '@angular/core';
import { JeevesProductTableComponent } from 'gung-standard-jeeves';
import {
  PriceService,
  GungFlowService,
  ProductService,
  MetadataService,
  AuthService,
  LocationConfigService
} from 'gung-standard';
import { Router } from '@angular/router';
import { first } from 'rxjs';

@Component({
  selector: 'product-pagination-list-table', 
  templateUrl: './product-pagination-list-table.component.html',
  styleUrls: ['./product-pagination-list-table.component.scss']
})
export class ProductPaginationListTableComponent extends JeevesProductTableComponent {
  isSales: boolean;
  hasArticleClass: boolean = false;
  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected productService: ProductService,
    protected metadataService: MetadataService,
    authService: AuthService,
    protected router: Router,
    protected locationConfigService: LocationConfigService
  ) {
    super(priceService, gungFlowService, authService, router, locationConfigService);
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(
        roles =>
          (this.isSales =
            roles.filter(role => role.toUpperCase() === 'ADMIN' || role.toUpperCase() === 'SALES').length > 0)
      );
    }

    protected subscribeToProducts() {
      super.subscribeToProducts();
      if(this.data.length > 0) {
          this.hasArticleClass = this.data.some(item => item.extra && item.extra.hasOwnProperty('articleClass'))
      }
    }
    
    getPackageSize(item: any): number {
      if (!!item.extra.ar.artfsgforp) {
      return parseInt(item.extra.ar.artfsgforp, 10);
    }

    return 0;
  }
}
