import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, first, map } from 'rxjs';
import { HubspotCompany } from '../../models/hubspot-company';
import { Customer } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class HubspotCompanyService {
  constructor(protected http: HttpClient) {}

  public createCompanyAndContact(customerId: string, userId?: string): Observable<any> {
    const url = `json/hubspot/companies?erpCustomerId=${customerId}${!!userId ? '&userId=' + userId : ''}`;
    return this.http.post<any>(url, {});
  }

  public findCompanyByErpId(erpId: string): Observable<HubspotCompany> {
    const url = `json/hubspot/companies/${erpId}?isErpId=true`;
    return this.http.get<HubspotCompany>(url, {});
  }

  public updateCompany(hubspotCompany: HubspotCompany, customer: Customer): Observable<HubspotCompany> {
    const url = `json/hubspot/companies/update/${hubspotCompany.id}`;
    return this.http.patch<HubspotCompany>(url, customer);
  }
}
