<div class="row">
  <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3 planogram-entry" [routerLink]="['/planogram/new']">
    <div class="new-planogram-entry">
      <i class="fas fa-plus"></i>
      <div class="new-planogram-text" translate>NEW</div>
    </div>
  </div>

  <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3 planogram-entry" *ngFor="let planogram of data">
    <div class="card planogram-card">
      <div class="d-flex flex-row align-items-center">
        <div class="d-flex flex-column mb-3">
          <span class="name">{{ planogram.name }}</span>
        </div>
      </div>
      <div class="align-items-center">
        <lib-action-btn [item]="planogram" [config]="actionButtonConfig" [blockBtns]="blockBtns"></lib-action-btn>
      </div>
    </div>
  </div>
</div>
