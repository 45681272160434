import { Injectable } from '@angular/core';
import { Scheduler, SchedulersService } from 'gung-common';
import { TranslateService } from '@ngx-translate/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { first, forkJoin, map, Observable, switchMap, timer } from 'rxjs';
import { SchedulersMonitorListViewComponent } from '../../components/schedulers-monitor-list-view/schedulers-monitor-list-view.component';

export interface SchedulerV2 extends Scheduler {
  hasDescription: boolean;
  descriptionEn?: string
}

export class SchedulerLastStatusFilter extends SimpleConfigBaseFilter<SchedulerV2> {
  constructor() {
    super();
  }

  getName(): string {
    return 'LAST_STATUS';
  }

  getOptionIds(item: SchedulerV2): string[] {
    return [item.jobdatamap.lastStatus];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class SchedulerCategoryFilter extends SimpleConfigBaseFilter<SchedulerV2> {
  constructor() {
    super();
  }

  isActiveFromStart = () => true;

  getName(): string {
    return 'CATEGORY';
  }

  getOptionIds(item: SchedulerV2): string[] {
    // Ensure list exists
    item.jobdatamap.jobCategories = item.jobdatamap.jobCategories || [];
    return [...item.jobdatamap.jobCategories];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class SchedulerExecutingFilter extends SimpleConfigBaseFilter<SchedulerV2> {
  constructor() {
    super();
  }

  isActiveFromStart = () => true;

  getName(): string {
    return 'EXECUTING';
  }

  getOptionIds(item: SchedulerV2): string[] {
    // Ensure list exists
    if(item.jobdatamap.running){
      return ['TRUE'];
    }
    return ['FALSE'];
  }

  getOptionName(key: string): string {
    return key;
  }
}



@Injectable({
  providedIn: 'root'
})
export class SchedulersListConfigService implements ConfigService<SchedulerV2> {
  topFilter = false;
  keepFilterOpen = true;

  lastUpdated = new Date();

  constructor(protected schedulersService: SchedulersService, protected translateService: TranslateService) {}

  getFilters(): ConfigBaseFilter<SchedulerV2>[] {
    return [new SchedulerCategoryFilter(), new SchedulerLastStatusFilter(), new SchedulerExecutingFilter()];
  }

  getSortOptions(): ListSortOption<SchedulerV2>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<SchedulerV2>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => SchedulersMonitorListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'SchedulerList'
      }
    ];
  }

  getItems(): Observable<SchedulerV2[]> {
    return timer(0, this.schedulersService.intervaleSeconds * 1000).pipe(
      switchMap(() =>
      forkJoin({
        schedules: this.schedulersService.getSchedulers(true).pipe(first()),
        translation: this.translateService.getTranslation('en').pipe(first())
      }).pipe(
          map(({schedules, translation}) => {
            this.lastUpdated = new Date();
            for (const schedule of schedules as SchedulerV2[]) {
              if (
                this.translateService.instant(`${schedule.jobName}_DESCRIPTION`) !== `${schedule.jobName}_DESCRIPTION`
              ) {
                schedule.hasDescription = true;
              } else if (translation[`${schedule.jobName}_DESCRIPTION`]) {
                schedule.hasDescription = true;
                schedule.descriptionEn = translation[`${schedule.jobName}_DESCRIPTION`];
              } else {
                schedule.hasDescription = false;
              }
            }
            return schedules as SchedulerV2[];
          })
        )
      )
    );
  }

  getItemId(item: SchedulerV2) {
    return item.jobKey.name;
  }

  getSearchTerms(item: SchedulerV2) {
    return [
      item.jobName,
      this.translateService.instant(`${item.jobName}_DESCRIPTION`) !== `${item.jobName}_DESCRIPTION`
        ? this.translateService.instant(`${item.jobName}_DESCRIPTION`)
        : ''
    ];
  }

  getNothingFoundTranslateTag(){
    return 'NOTHING_FOUND';
  }
}
