import { Injectable } from '@angular/core';
import { ConfigService, ConfigBaseFilter, ListSortOption, ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { Subject, BehaviorSubject, Observable, mergeMap, forkJoin, of, first, switchMap, filter, combineLatest, map, tap, distinctUntilChanged } from 'rxjs';
import { SupplierPoListTableViewComponent } from '../../components/supplier/supplier-po-list-table-view/supplier-po-list-table-view.component';
import { SupplierPurchaseOrder } from '../../models';
import { SelectedSupplierService } from '../selected-supplier/selected-supplier.service';
import { SupplierService } from '../suppliers/supplier.service';
import { ColumnSortSelectionService } from '../column-sort-selection/column-sort-selection.service';
import { SupplierSortColumnService } from '../../components/supplier/supplier-sort-column/supplier-sort-column.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierPoListConfigService implements ConfigService<SupplierPurchaseOrder> {
  public purchaseOrderType: Subject<string> = new BehaviorSubject<string>(undefined);
  lastGetType;
  cachedDocuments;
  noCache = true;

  constructor(
    protected supplierService: SupplierService, 
    protected selectedSupplierService: SelectedSupplierService,
    protected columnSortSelectionService: SupplierSortColumnService
  ) {
  
  }

  getItems(): Observable<SupplierPurchaseOrder[]> {
    
    return combineLatest([
      this.purchaseOrderType,
      this.selectedSupplierService.getSelectedSupplier(),
      this.columnSortSelectionService.getSelectedColumnSort()
    ]).pipe(
      mergeMap(([type, selectedSupplier, selectedColumnSort]) => {
        if (this.lastGetType === type) {
          this.lastGetType = type;
          this.noCache = false;
        } else {
          this.noCache = true;
        }
        if (selectedSupplier) {
          return this.supplierService.getPurchaseOrder(type, selectedSupplier?.id, this.noCache).pipe(
            map(purchaseOrders => ({ purchaseOrders, selectedColumnSort }))
          );
        } else {
          return of({ purchaseOrders: null, selectedColumnSort });
        }
      }),
      switchMap(({ purchaseOrders, selectedColumnSort }) => {
        if (purchaseOrders === null) {
          return of({ documents: [], purchaseOrders: [] });
        }
        let rowIds = purchaseOrders.map(d => d.id);
        purchaseOrders = this.columnSortSelectionService.sortItemsByColumn(purchaseOrders, selectedColumnSort);
        if (!this.cachedDocuments || this.noCache) {
          return this.supplierService.getDocumentByRowIds(rowIds).pipe(
            first(), // take the first emitted value and complete
            tap(documents => this.cachedDocuments = documents),
            map(documents => ({ documents, purchaseOrders }))
          );
        } else {
          return of({ documents: this.cachedDocuments, purchaseOrders });
        }
      }),
      switchMap(({ documents, purchaseOrders }) => {
        for (const po of purchaseOrders) {
          po.extra._hasDocuments = documents[po.id]?.length > 0;
          po.extra._haveNewDocuments = documents[po.id].filter(d => !d.read).length > 0;
          po.extra._hasComments = po.comments?.length > 0;
          po.extra._haveNewComments = po.comments.filter(c => !c.read).length > 0;
        }
    
        return of(purchaseOrders);
      })
    );
    /* return this.purchaseOrderType.pipe(
      mergeMap(type => forkJoin([of(type), this.selectedSupplierService.getSelectedSupplier().pipe(filter(supplier => !!supplier),first())])),
      switchMap(([type, selectedSupplier]) => (!selectedSupplier)?null:this.supplierService.getPurchaseOrder(type, selectedSupplier?.id, true)),
    ); */
  }

  getFilters(): ConfigBaseFilter<SupplierPurchaseOrder>[] {
    return [];
  }

  getSortOptions(): ListSortOption<SupplierPurchaseOrder>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<SupplierPurchaseOrder>[] {
    return [
      {
        getIconClass: () => 'fas fa-th-list',
        getListItemComponent: () => SupplierPoListTableViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'SupplierPurchaseOrderTable'
      }
    ];
  }

  getSearchTerms(item: SupplierPurchaseOrder): string[] {
    return [item.orderId, item.productId];
  }

  getItemId(item: SupplierPurchaseOrder): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }

}
