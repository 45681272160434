import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { map } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { DashboardCard } from '../../types';
import { DashboardCardService } from '../../services/dashboard-card.service';
import { BreakpointObserverService } from '../../services/breakpoint-observer.service';

@Component({
  selector: 'lib-gung-dashboard',
  templateUrl: './gung-dashboard.component.html',
  styleUrls: ['./gung-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GungDashboardComponent implements OnInit {
  cards: DashboardCard[] = [];
  currentMediaQuery: string;

  constructor(
    protected dashboardCardService: DashboardCardService,
    protected breakpointObserverService: BreakpointObserverService
  ) {
    this.dashboardCardService.getCards().subscribe(cards => {
      this.cards = cards;
    });

    this.breakpointObserverService.getMediaQuery().subscribe(mq => {
      this.currentMediaQuery = mq;
    });
  }

  ngOnInit(): void {
    this.createCards();
  }

  createCards(): void {
    /* this.dashboardCardService.addCard(
      new DashboardCard(
        {name: {key: DashboardCard.metadata.NAME, value: 'users'},
         routerLink: {key: DashboardCard.metadata.ROUTERLINK,
                      value: '/dashboard/users'},
         iconClass: {key: DashboardCard.metadata.ICONCLASS,
                     value: 'fa-users'},
         color: {key: DashboardCard.metadata.COLOR, value: 'blue'}
        }, DashboardUsersComponent)
   ); */
  }

  /*
    cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
        map(({ matches }) => {
          if (matches) {
            return [
              { title: 'Card 1', cols: 1, rows: 1 },
              { title: 'Card 2', cols: 1, rows: 1 },
              { title: 'Card 3', cols: 1, rows: 1 },
              { title: 'Card 4', cols: 1, rows: 1 }
            ];
          }

          return [
            { title: 'Card 1', cols: 2, rows: 1 },
            { title: 'Card 2', cols: 1, rows: 1 },
            { title: 'Card 3', cols: 1, rows: 2 },
            { title: 'Card 4', cols: 1, rows: 1 }
          ];
        })
      ); */
}
