import { Injectable } from '@angular/core';
import { MatrixDiscount } from '../../models/matrix-discount';
import { MatrixDiscountModalComponent } from '../../components/matrix-discount-modal/matrix-discount-modal.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class MatrixDiscountModalService {
  private modalRef: NgbModalRef;

  private modalOptions: NgbModalOptions = {
    backdrop: true || 'static',
    size: 'sm',
    keyboard: true
  };

  constructor(protected modalService: NgbModal) {}

  openModal(matrixDiscount?: MatrixDiscount) {
    this.modalRef = this.modalService.open(this.getModalClass(), this.modalOptions);

    if (!!matrixDiscount) {
      this.modalRef.componentInstance.matrixDiscount = matrixDiscount;
    }

    this.modalRef.result.then(
      result => {},
      reason => {}
    );
  }

  protected getModalClass(): any {
    return MatrixDiscountModalComponent;
  }
}
