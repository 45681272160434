<div class="table-responsive gung-responsive">
  <table class="table">
    <thead>
    <tr>
      <th class="p-0 pr-2"></th>
      <th translate></th>
      <th translate>ID</th>
      <th translate>REFERENCE</th>
      <th translate>TRANSACTION_AMOUNT</th>
      <th translate>CREATED_DATE</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let bankgiro of data">
      <td class="p-0 border-0" [ngClass]="{
          'has-been-exported': bankgiro.usedInReport,
          'has-not-been-exported': !bankgiro.usedInReport
        }"></td>
      <td>
        <lib-item-selection-button [item]="bankgiro" [selectionText]="''"></lib-item-selection-button>
      </td>
      <td scope="row">
        {{bankgiro.id}}
      </td>
      <td scope="row">
        {{bankgiro.ocrReference}}
      </td>
      <td>
        {{gungCurrencyPipe.transform(bankgiro.paymentAmount)}}
      </td>
      <td>
        <span>{{bankgiro.createdDate | date: 'yyyy-MM-dd HH:mm:ss'}}</span> <br/>
        <span class="small font-italic">({{bankgiro.createdBy}})</span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
