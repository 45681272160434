import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JeevesKuarUpdate } from '../../models/jeeves-kuar-update';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JeevesKuarUpdateService {
  constructor(protected http: HttpClient) {}

  public updateKuar(kuar: JeevesKuarUpdate): Observable<any> {
    const url = 'json/jeeves/add-update-kuar';
    return this.http.post(url, kuar);
  }
}
