<div class="row">
  <div class="col-12 col-lg-2 d-flex flex-column">
    <div class="input-group">
      <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="minDate"
        ngbDatepicker
        #minD="ngbDatepicker"
        readonly
        container="body"
        [value]="minDateValue"
        [minDate]="minDate"
        (dateSelect)="updateInitDate($event)"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="minD.toggle()" type="button">
          <i class="fas fa-calendar"></i>
        </button>
      </div>
    </div>
    <div class="input-group mt-2">
      <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dp"
        ngbDatepicker
        #d="ngbDatepicker"
        readonly
        container="body"
        [value]="maxDateValue"
        [minDate]="minDateToMaxDate"
        (dateSelect)="updateEndDate($event)"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
          <i class="fas fa-calendar"></i>
        </button>
      </div>
    </div>
    <button class="btn btn-outline-danger w-100 mt-2" (click)="createCalendar()" translate>CLEAR_ALL_DELIVERIES</button>
  </div>
  <div class="col-12 col-lg-10">
    <div class="row font-weight-bold text-center">
      <div class="col col-cal-table weekabr" translate></div>
      <div *ngFor="let weekday of gungCalendarWeekdayOrder" class="col col-cal-table text-capitalize" translate>{{ weekdayTranslationMap?.[weekday]?.abr | translate }}</div>
      <div class="col col-cal-table actionscol" translate></div>
    </div>     
    <div class="row  weeks-line" *ngFor="let row of calendarRows;let i = index">
      <div class="col col-cal-table weekabr pt-2"> {{ ('WEEK_ABREVIATION' | translate) + (row?.[0]?.date | date: 'w') }}</div>
      <div class="col col-cal-table pt-2 cal-day" *ngFor="let cell of row">
        <div *ngIf="cell?.show" class="cal-day-string">{{ cell?.day }} {{cell?.getMonthName}}</div>
        <div class="call-day-carts text-center" *ngIf="cell?.show">
          <div class="mb-1 cart-selected text-left p-2 clearfix" *ngFor="let cart of cell.carts">
            {{cart.name}} <i class="fa-regular fa-trash red float-right" (click)="removeSavedCart(cell, cart)"></i>
          </div>
          <button *ngIf="!cell?.disabled" class="btn btn-sm btn-outline-primary mb-2 " translate (click)="addSavedCart(cell)">ADD</button>
        </div>
      </div>
      <div class="col col-cal-table actionscol cal-day pt-2">
        <div ngbDropdown placement="bottom-right" class="d-inline-block dropup" *ngIf="i < calendarRows.length - 1">
          <button class="btn btn-primary export-area-buttons" id="dropdownSelection" ngbDropdownToggle translate>
            ACTIONS
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownSelection" class="dropdown-container dropdown-container-max-height">
            <button class="schedule-calendar-actions" ngbDropdownItem (click)="copyToNextLine(i)" translate>
              COPY_TO_NEXT_WEEK
            </button>
            <button class="schedule-calendar-actions" ngbDropdownItem (click)="copyToAllNextLines(i)" translate>
              COPY_TO_ALL_COMING_WEEKS
            </button>
            <button class="schedule-calendar-actions" ngbDropdownItem (click)="copyToEveryOtherLine(i)" translate>
              COPY_TO_EVERY_SECOND_WEEK
            </button>
          </div>
        </div> 
      </div>
    </div>
  </div>
</div>
