import { Component, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Order, CustomerService } from 'gung-standard';
import { of } from 'rxjs';

@Component({
  selector: 'gung-jeeves-jeeves-open-order-table',
  templateUrl: './jeeves-open-order-table.component.html',
  styleUrls: ['./jeeves-open-order-table.component.css']
})
export class JeevesOpenOrderTableComponent extends ListItemRendererComponent<Order[]> implements AfterViewInit {
  constructor(public customerService: CustomerService) {
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
