<div class="row view-more-root">
  <div *ngIf="!disableViewMoreSearch" class="col-12">
    <input
      #searchInput
      type="text"
      class="form-control search-form"
      [attr.placeholder]="'SEARCH' | translate"
      [formControl]="searchTermForm"
    />
  </div>

  <div class="col-12">
    <lib-simple-list *ngIf="!loading; else loadingCircle" [configService]="navbarSearchViewMoreListConfigService"></lib-simple-list>
    <ng-template #loadingCircle>
      <div class="w-100 text-center mt-5">
        <i class="fas fa-spinner fa-spin fa-5x"></i>
      </div>
    </ng-template>
  </div>

  <div class="col-12">
    <button *ngIf="(batch + skip) < totalItems" class="btn btn-primary w-100" (click)="showMore()">
      <span translate [translateParams]="{ limit: batch + skip, total: totalItems, batchSize: batch }">GUNG_ITEM_LIST_LOAD_MORE</span>
    </button>
  </div>
</div>