import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { BankgiroDynamicComponent } from '../components/bankgiro-dynamic/bankgiro-dynamic.component';
import { Bankgiro } from '../models/bankgiro';

@Injectable({
  providedIn: 'root'
})
export class BankgiroModalService extends GungModalService {

    openBankgiroDynamicModal(modalOptions?: NgbModalOptions): Promise<any> {
        const option: NgbModalOptions = {
            backdrop: 'static',
            keyboard: true,
            centered: true,
            size: 'xl',
            windowClass: 'modal-lg-custom',
            ...modalOptions
          };
    
        const id = BankgiroDynamicComponent.name;
        const ref = this.ngbModal.open(BankgiroDynamicComponent, option);
        // ref.componentInstance.customer = customer;
    
        this.modalRef.set(id, ref);
        return ref.result;
    }
}
