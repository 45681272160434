<div class="d-flex flex-column justify-content-center align-items-center" *ngIf="selections.length == 0">
  <span class="mt-3 font-italic" translate>NO_SELECTIONS_HAVE_BEEN_MADE</span>
</div>

<div class="row" *ngIf="selections.length > 0">
  <div class="col-lg-6 col-md-12">
    <h5 translate>CONFIGURED_PRODUCT</h5>
    <hr/>

    <ng-container>
      <span><b translate>Base</b></span> <br/>
      <span class="d-flex justify-content-between">
        <span>
          <span>{{configuration.name}} </span>
          </span>
          <span>
            {{currency}}{{configurationPrice}}
            <br/>
          </span>
        </span>
      <hr/>
    </ng-container>
    <ng-container *ngFor="let group of groupedSelection | keyvalue">
      <span><b>{{group.key}}</b></span> <br/>
      <span *ngFor="let selection of group.value">
        <span class="d-flex justify-content-between">
          <span>
          <span>{{selection.feature.name}}: </span> <span>{{selection.option.name}}</span>
          </span>
          <span>
            {{(optionPriceService.getPrice(selection.option) | async).currency}}{{(optionPriceService.getPrice(selection.option) | async).price}}
            <br/>
          </span>
        </span>
      </span>
      <hr/>
    </ng-container>
    <span class="d-flex justify-content-between">
      <span></span>
      <span><span class="mr-3 font-weight-bold" translate>TOTAL</span>{{currency}}{{total}}</span>
    </span>
  </div>

  <div class="col-lg-6 col-md-12">
    <h5 translate>BILL_OF_MATERIAL</h5>
    <hr/>

    <div class="row">
      <div class="col-4">
        <span class="font-weight-bold" translate>MATERIAL</span> <br/>
        <ng-container *ngFor="let selection of selections">
          <ng-container *ngFor="let material of selection.option.materials">
          <span *ngIf="products && products.has(material.productId)">
            <span>{{products.get(material.productId)?.name}}</span> <span
            class="font-italic ml-1">({{material.productId}})</span>
          </span>
            <br/>
          </ng-container>
        </ng-container>
      </div>

      <div class="col-6">
        <span class="font-weight-bold" translate>OPTION</span> <br/>
        <ng-container *ngFor="let selection of selections">
        <span *ngFor="let material of selection.option.materials"
              style="overflow-x: hidden; text-overflow: ellipsis; white-space: nowrap;">
          {{selection.feature.name}}: {{selection.option.name}} <br/>
        </span>
        </ng-container>
      </div>

      <div class="col-2 text-right">
        <span class="font-weight-bold" translate>Quantity</span> <br/>
        <ng-container *ngFor="let selection of selections">
        <span *ngFor="let material of selection.option.materials">{{material.quantity * getSelectionQuantity(selection)}} <span
          *ngIf="products && products.has(material.productId)">{{products.get(material.productId)?.extra?.items?.baseUnitOfMeasure?.displayName}}</span> <br/></span>
        </ng-container>
      </div>
    </div>
  </div>
</div>
