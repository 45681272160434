import { Component, OnInit } from '@angular/core';
import { ReplenishmentProductListConfigService } from '../../services/replenishment-product-list-config.service';

@Component({
  selector: 'lib-replenishment-product-list',
  template: '<lib-filter-list #list [configService]="configService"></lib-filter-list>',
  styleUrls: ['./replenishment-product-list.component.css']
})
export class ReplenishmentProductListComponent {
  constructor(public configService: ReplenishmentProductListConfigService) {}
}
