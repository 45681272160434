<div class="d-flex justify-content-end">
  <div class="mb-2">
    <span style="font-size: 12px" class="mr-2" translate>ADD_CUSTOMER</span>
    <label class="expandableAddCustomer">
      <input
        id="customerid"
        type="text"
        class="form-control"
        [resultFormatter]="formatterCustomer"
        [ngbTypeahead]="searchCustomers"
        (selectItem)="onSelectCustomer($event)"
      />
      <i class="far fa-plus"></i>
    </label>
  </div>
</div>

<div *ngIf="data && loaded; else loading" class="table-responsive gung-responsive">
  <table class="table">
    <thead>
      <tr>
        <th translate>CUSTOMER</th>
        <th style="width: 150px" class="text-right" translate>ACTIVE</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data">
        <td>
          <a [routerLink]="['/replenishment/', item.customerId]">
            <span class="customer-id">{{ item.customerId }}</span> <br />
            <span class="customer-name" *ngIf="keyedCustomers[item.customerId]">{{
              keyedCustomers[item.customerId].name
            }}</span>
          </a>
        </td>

        <td class="text-right toggle-switch-column">
          <div class="gung-toggle-switch">
            <label class="switch">
              <input
                id="activeState"
                name="activeState"
                type="checkbox"
                #activeStateInput
                [checked]="item.active"
                (change)="changeActiveState(item, $event.target.checked)"
              />
              <span class="slider round bg-primary"></span>
            </label>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
  LOADING...
</ng-template>
