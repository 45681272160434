import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CartRow, ProductMultiDimension, Product, ProductMatrixModalService, CartService, ProductService, PriceService, isMultiDimensionProduct, mergeDeep } from 'gung-standard';
import { Subject, first, mergeMap, takeUntil, map, forkJoin, switchMap, of } from 'rxjs';

interface CartRowEdit {
  cartRow: CartRow;
  editExtra: any;
  product: ProductMultiDimension;
  isMultiDimensional: boolean;
}

@Component({
  selector: 'gung-jeeves-cart-discount-matrix',
  templateUrl: './cart-discount-matrix.component.html',
  styleUrls: ['./cart-discount-matrix.component.css']
})
export class CartDiscountMatrixComponent implements OnInit {

  unsubscribe: Subject<boolean> = new Subject<boolean>();

  productId: string;
  productName: string;

  cartRows: CartRowEdit[];

  inicialPrices = [];
  initialDiscount = [];

  constructor(
    protected cartService: CartService,
    protected productService: ProductService,
    protected translateService: TranslateService,
    protected priceService: PriceService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.cartService.getCurrentCart().pipe(
      takeUntil(this.unsubscribe),
      map(cartRows => {
        return cartRows.filter(row => row.productId.indexOf(this.productId) === 0);
      }),
      switchMap(cartRows => {
        return forkJoin([
          of(cartRows),
          forkJoin(cartRows.map(r => this.productService.getProduct(r.productId).pipe(first()))),
          this.priceService.getCurrentCustomerPrices(cartRows.map(r => r.productId)).pipe(first()),
        ])
      }),
      map(([cartRows, products, prices]) => {
        this.productName = products?.length && products[0].name;
        products.forEach(p => {
          p.extra._gung = {
            oemPrice: prices.find(pr => pr.productId === p.id)?.customerGrossPrice?.value,
            oemDiscount: prices.find(pr => pr.productId === p.id)?.customerDiscountPercent
          }
        })
        return ({cartRows, products, prices});
      }),
    ).subscribe(({cartRows, products, prices}) => {
      this.cartRows = cartRows
      .map((row, i) => {
        // row.extra.m3 = row.extra.m3 || {};
        return this.createRowEdit(row, products.find(p => p.id === row.productId));
      })
      .sort((v1, v2) => {
        const v1Id = v1.product.id;
        const v2Id = v2.product.id;
        if (v1Id > v2Id) {
          return 1;
        } else if (v1Id < v2Id) {
          return -1;
        } else {
          return 0;
        }
      });
    });
  }

  protected createRowEdit(row: CartRow, product: Product): CartRowEdit {
    return {
      cartRow: row,
      editExtra: {
        orp: {},
        ...JSON.parse(JSON.stringify(row.extra))
      },
      product: product as ProductMultiDimension,
      isMultiDimensional: isMultiDimensionProduct(product)
    };
  }

  getTranslation(key: string): string {
    return this.translateService.instant(key);
  }

  copyDown(operation: string, currentRowIndex: number, rows: CartRowEdit[] = this.cartRows): void {
    if (operation === 'once') {
      const editRow = rows[currentRowIndex + 1];
      editRow.editExtra = JSON.parse(JSON.stringify(rows[currentRowIndex].editExtra));
    } else if (operation === 'all') {
      for (let i = currentRowIndex + 1; i < rows.length; i++) {
        const editRow = rows[i];
        editRow.editExtra = JSON.parse(JSON.stringify(rows[currentRowIndex].editExtra));
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  closeMatrix() {
    this.activeModal.close('ok');
  }

  applyAndCloseMatrix() {
    this.cartRows.forEach(row => {
      row.cartRow.extra = mergeDeep(JSON.parse(JSON.stringify(row.cartRow.extra)), row.editExtra);
      this.cartService.setExtra(row.cartRow.productId, row.cartRow.extra);
    });
    this.closeMatrix();
  }

}
