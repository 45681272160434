import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Assortment, AssortmentRecursive, AssortmentService, Product } from 'gung-standard';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sjoborg-concept-table-view',
  templateUrl: './sjoborg-concept-table-view.component.html',
  styleUrls: ['./sjoborg-concept-table-view.component.scss']
})
export class SjoborgConceptTableViewComponent
  extends ListItemRendererComponent<AssortmentRecursive[]>
  implements OnInit, OnDestroy, OnChanges
{
  private subscriptions: Subscription[] = [];
  currentLang = this.translateService.currentLang || 'se';
  itemProperties: any[];

  constructor(
    protected translateService: TranslateService,
    protected activatedRoute: ActivatedRoute,
    protected assortmentService: AssortmentService
  ) {
    super();
  }

  public ngOnInit() {
    const url = this.activatedRoute.snapshot.url;

    this.assortmentService.getAssortment(url[url.length - 1].path).subscribe(assortment => {
      if ((assortment as any).extra.itemProperties) {
        const itemProperties: any[] = (assortment as any).extra.itemProperties;
        this.itemProperties = itemProperties.filter(prop => prop.isDisplay);
      } else {
        this.itemProperties = [];
      }
    });
  }

  public ngOnChanges() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getAssortmentImg(child: AssortmentRecursive): string {
    if (child.extra.images && child.extra.images.length > 0) {
      return `https://d27ahaa1qqlr90.cloudfront.net/fit-in/100x100/filters:fill(white):background_color(white):etag(${child.extra.images[0].s3ETag}):format(jpeg)/${child.extra.images[0].s3Uri}`;
    }
    return 'https://d27ahaa1qqlr90.cloudfront.net/fit-in/100x100/filters:fill(white):background_color(white):format(jpeg)/images/no-image.jpg';
  }

  getDescription(assortment): string {
    let description = '';
    if (assortment.extra.i18n && assortment.extra.i18n[this.currentLang]) {
      description = assortment.extra.i18n[this.currentLang].description || '';
    }
    if (!description && assortment.extra.pim) {
      description = assortment.extra.pim.description || '';
    }
    return description;
  }
  getPropertyValue(item: any, itemProperty: any): any {
    const path: string = itemProperty.path;
    const splitPath = path.split('.');

    let currentValue = item.extra;
    while (splitPath.length > 0) {
      const currentPath = splitPath[0];
      splitPath.splice(0, 1);
      if (itemProperty.type === 'metadata' && splitPath.length === 0) {
        if (currentValue && currentValue.hasOwnProperty(currentPath)) {
          currentValue = currentValue[currentPath].description;
        } else {
          currentValue = undefined;
        }
      } else {
        currentValue = currentValue[currentPath];
      }
    }
    if (Array.isArray(currentValue)) {
      return Array.from(new Set(currentValue));
    }
    return currentValue;
  }

  getPackageSize(item: any): number {
    if (!!item.extra.ar.artfsgforp) {
      return parseInt(item.extra.ar.artfsgforp, 10);
    }

    return 0;
  }
}
