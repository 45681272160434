import { Component, ViewChild, forwardRef } from '@angular/core';
import { ProductionManagementOrdersToPickListConfigService } from '../../services/production-management-orders-to-pick-list-config.service';
import { AbstractSelectionService, FilterListComponent } from 'gung-list';
import { ProductSelectionService } from '../../../../services/product-selection-service/product-selection.service';
import { Order } from '../../../../models/order';

@Component({
  selector: 'lib-production-management-orders-to-pick',
  template: `
  <div class="container-main">
    <div class="row">
      <div class="col-12 text-center">
        <h3 translate class="page-title text-center">ORDERS_TO_PICK</h3>
      </div>
      <div class="col-auto position-absolute">
        <a type="button" class="btn btn-info" routerLink=".."
          ><i class="fa-solid fa-arrow-left-long mr-1"></i><span translate>GO_BACK</span></a
        >
      </div>
    </div>
    <lib-filter-list #orderExportList [configService]="config"></lib-filter-list>
  </div>
  `,
  styles: [],
  providers: [
    {
      provide: AbstractSelectionService,
      useExisting: forwardRef(() => ProductSelectionService) // TODO refactor - new selectionService?
    }
  ]
})
export class ProductionManagementOrdersToPickComponent {
  constructor(public config: ProductionManagementOrdersToPickListConfigService) {
  }
  @ViewChild('orderExportList') orderExportList: FilterListComponent<Order>;
}
