import { Injectable } from '@angular/core';
import {
  CustomerListConfigService,
  Customer,
  MetadataService,
  CustomerService,
  UsersService,
  AuthService,
  CustomerSelectionActionConfigService
} from 'gung-standard';
import {
  ConfigBaseFilter,
  ListLayout,
  ListLayoutSingleComponent,
  FilterListComponent,
  SimpleConfigBaseFilter
} from 'gung-list';
import { JeevesCustomerListCardComponent } from '../../components/jeeves-customer-list-card/jeeves-customer-list-card.component';
import { TranslateService } from '@ngx-translate/core';

// MARK: CustomerSalespersonFilter
export class CustomerSalespersonFilter extends SimpleConfigBaseFilter<Customer> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'SALESPERSON';
  }
  getOptionIds(item: Customer): string[] {
    const metadataValue = this.metadataService.getMetadataValue('salj', 'saljarenamn', item.extra.kus.saljare);
    if (metadataValue == null) {
      return [];
    } else {
      return [metadataValue];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class JeevesCustomerListConfigService extends CustomerListConfigService {
  constructor(
    public customerService: CustomerService,
    protected usersService: UsersService,
    protected authService: AuthService,
    protected customerSelectionActionConfigService: CustomerSelectionActionConfigService,
    protected translateService: TranslateService,
    protected metadataService: MetadataService
  ) {
    super(customerService, usersService, authService, customerSelectionActionConfigService, translateService);
  }

  getFilters(): ConfigBaseFilter<Customer>[] {
    const filters = super.getFilters();

    filters.push(new CustomerSalespersonFilter(this.metadataService));

    return filters;
  }

  getSearchTerms(item: Customer): string[] {
    const superTerms = super.getSearchTerms(item);
    if (item.extra.fr && item.extra.fr.ftgpostadr3) {
      return superTerms.concat([item.extra.fr.ftgpostadr3]);
    }
    return superTerms;
  }

  getLayouts(): ListLayout<Customer>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => JeevesCustomerListCardComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Customer list'
      }
    ];
  }
}
