import { Component, OnInit, OnChanges, OnDestroy, SimpleChanges, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import {
  CartRow,
  CartService,
  PriceService,
  ProductService,
  Product,
  AvailabilityService,
  Availability,
  CartRowPrice,
  SelectedCustomerService,
  Customer,
  GungFlowService,
  GungFlow
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { Subscription, forkJoin, of } from 'rxjs';
import { first, map, tap } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { format, parse } from 'date-fns';

enum DiscountType {
  PERCENTAGE,
  OVERRIDE
}
export interface JeevesSalesCartListRow {
  originalPrice: number;
  name: string;
  productId: string;
  productPartialId?: string;
  targetStockId?: string;
  discountPercent?: number;
  discountPercent2?: number;
  description?: string;
  overridePrice?: number;
  deliveryDate?: string;
  minDate: NgbDate;
  quantity: number;
  currency: string;
  cartRowPriceElement: CartRowPrice;
  deliveryMethod: string;
  cartRow: CartRow;
  currentAvailability?: number;
  product: Product;
  price: CartRowPrice;
}

@Component({
  selector: 'gung-jeeves-jeeves-sales-cart-list',
  templateUrl: './jeeves-sales-cart-list.component.html',
  styleUrls: ['./jeeves-sales-cart-list.component.css']
})
export class JeevesSalesCartListComponent
  extends ListItemRendererComponent<CartRow[]>
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  public cachedData: { [id: string]: { product: Product; price: CartRowPrice; availability: Availability } } = {};
  public subscriptions: Subscription[] = [];
  currentFlowRequireAvailability: boolean
  public mappedData: JeevesSalesCartListRow[];

  deliveryDate = new Date();

  constructor(
    protected productService: ProductService,
    protected cartService: CartService,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    protected selectedCustomerService: SelectedCustomerService,
    protected dateUtilService: DateUtilService,
    protected flowService: GungFlowService
  ) {
    super();
  }

  ngOnInit() {
    this.flowService.getSelectedFlow().pipe(first()).subscribe(flow => {
      this.currentFlowRequireAvailability = (flow.requireAvailability || flow.requireCurrentAvailability) ;
      this.updateData();
    })
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.subscriptions.forEach(s => s.unsubscribe());

    this.updateData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  updateData() {
    const ids = this.data.map(c => c.productId);
    const cachedIds = Object.keys(this.cachedData);
    const toGetIds = ids.filter(id => !cachedIds.includes(id));

    const sub = forkJoin({
      products: this.productService.getProductsByIds(toGetIds).pipe(first()),
      prices: this.priceService.getCartRowPricesObservable(this.data).pipe(first()),
      availabilities: this.currentFlowRequireAvailability ? this.availabilityService.getAvailabilities(toGetIds, undefined, true).pipe(
        first(),
        map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities })))
      ) : of([]),
      cartRows: of(this.data),
      customer: this.selectedCustomerService.getSelectedCustomer().pipe(first())
    })
      .pipe(
        first(),
        tap(resp => {
          this.cachedData = {
            ...this.cachedData,
            ...toGetIds
              .map(id => ({
                product: resp.products.find(p => p.id === id),
                price: resp.prices.find(p => p.productId === id),
                availability: this.currentFlowRequireAvailability ? resp.availabilities.find(av => av.productId === id) : null
              }))
              .reduce((acc, curr) => ({ ...acc, [curr.product.id]: curr }), {})
          };
        }),
        map(data => {
          const cachedProducts = Object.values(this.cachedData).map(d => d.product);
          const cachedPrices = data.prices;
          const cachedAvs = Object.values(this.cachedData).map(d => d.availability);
          return this.mapData(cachedProducts, cachedPrices, cachedAvs, data.cartRows, data.customer);
        })
      )
      .subscribe(d => this.updateMappedData(d));

    this.subscriptions.push(sub);
  }

  updateMappedData(d: JeevesSalesCartListRow[]) {
    this.mappedData = JSON.parse(JSON.stringify(d));
  }

  public trackByFn(index: number, item: JeevesSalesCartListRow) {
    return index + '_' + item.productId + '_' + item.productPartialId;
  }

  public removeRow(row: CartRow) {
    const index = this.mappedData.findIndex(
      mappedDataRow =>
        mappedDataRow.cartRow.productId === row.productId &&
        (!mappedDataRow.cartRow.productPartialId || mappedDataRow.cartRow.productPartialId === row.productPartialId) &&
        (!mappedDataRow.cartRow.targetStockId || mappedDataRow.cartRow.targetStockId === row.targetStockId)
    );
    if (index > -1) {
      this.mappedData.splice(index, 1);
      this.cartService.removeRow(row);
    }
  }

  public updateQty(row: JeevesSalesCartListRow, value: any) {
    const newQty = Number.parseInt(value, 10);
    // check qty
    const oldQty = row.quantity;
    if (oldQty === newQty) {
      return;
    }
    // value has changed, propagate throught the cart
    this.cartService.setProductQuantity(row.productId, newQty, row.targetStockId, row.productPartialId);
  }

  public updateOverridePrice(row: JeevesSalesCartListRow, value: any) {
    const oldVal = row.overridePrice || row.originalPrice;

    if (oldVal === value) {
      return;
    }

    if (!value || isNaN(value)) {
      this.cartService.removeExtraField(row.productId, 'orp.vb_pris', row.targetStockId, row.productPartialId);
      return;
    }

    this.cartService.setExtra(
      row.productId,
      {
        orp: {
          vb_pris: parseFloat(value).toFixed(2)
        }
      },
      row.targetStockId,
      row.productPartialId
    );
  }

  updateDeliveryDate(row: JeevesSalesCartListRow, selectedDeliveryDate) {
    const oldVal = row.deliveryDate || row.minDate;

    let deliveryDate;

    if (selectedDeliveryDate instanceof NgbDate) {
      deliveryDate = format(
        new Date(selectedDeliveryDate.year, selectedDeliveryDate.month - 1, selectedDeliveryDate.day),
        'yyyy-MM-dd'
      );
    } else {
      deliveryDate = format(selectedDeliveryDate.date, 'yyyy-MM-dd');
    }

    if (oldVal === deliveryDate) {
      return;
    }

    if (!deliveryDate) {
      this.cartService.removeExtraField(
        row.productId,
        this.getDeliveryDateFieldName(),
        row.targetStockId,
        row.productPartialId
      );
      return;
    }

    const extra = this.getExtraWithDeliveryDate(deliveryDate);
    this.cartService.setExtra(row.productId, extra, row.targetStockId, row.productPartialId);
  }

  public updateDiscountPercentage(row: JeevesSalesCartListRow, value: any, totalDiscount?: boolean) {
    const oldVal = row.discountPercent;
    if (oldVal === value) {
      return;
    }
    // eslint-disable-next-line eqeqeq
    if (!value && value != 0) {
      this.cartService.removeExtraField(row.productId, 'orp.rabatt1', row.targetStockId, row.productPartialId);
      if (totalDiscount) {
        this.cartService.removeExtraField(row.productId, 'orp.rabatt2', row.targetStockId, row.productPartialId);
        this.cartService.removeExtraField(row.productId, 'orp.rabatt3', row.targetStockId, row.productPartialId);
        this.cartService.removeExtraField(row.productId, 'orp.kundrabatt', row.targetStockId, row.productPartialId);
        this.cartService.removeExtraField(row.productId, 'orp.volymrabatt', row.targetStockId, row.productPartialId);
      }
      return;
    }

    const extra: any = {
      orp: {
        rabatt1: value
      }
    };

    if (totalDiscount) {
      extra.orp.rabatt2 = '0';
      extra.orp.rabatt3 = '0';
      extra.orp.kundrabatt = '0';
      extra.orp.volymrabatt = '0';
    }

    this.cartService.setExtra(row.productId, extra, row.targetStockId, row.productPartialId);
  }

  public mapData(
    products: Product[],
    prices: CartRowPrice[],
    availabilities: Availability[],
    cartRows: CartRow[],
    customer: Customer
  ): JeevesSalesCartListRow[] {
    return cartRows.map(cr => {
      const product = products.find(p => p.id === cr.productId);
      const price = prices.find(
        p => p.productId === cr.productId && (!cr.productPartialId || p.productPartialId === cr.productPartialId)
      );
      const av = this.currentFlowRequireAvailability ? availabilities.find(a => a.productId === cr.productId) : null;
      const minDateJs: Date = this.getMinDate(av);
      const toReturn: JeevesSalesCartListRow = {
        productId: cr.productId,
        productPartialId: cr.productPartialId,
        name: product.name,
        quantity: cr.qty,
        originalPrice: price.customerGrossPrice.value,
        currency: price.customerNetPrice.currencyCode,
        minDate: new NgbDate(minDateJs.getFullYear(), minDateJs.getMonth() + 1, minDateJs.getDate()),
        targetStockId: cr.targetStockId,
        cartRowPriceElement: price,
        deliveryMethod: customer.extra.kus.levsattkod,
        cartRow: cr,
        currentAvailability: this.currentFlowRequireAvailability ? av.currentAvailability : 0,
        product,
        price
      };
      if (cr.extra && cr.extra.orp && cr.extra.orp.rabatt1) {
        toReturn.discountPercent = cr.extra.orp.rabatt1;
      }
      if (cr.extra && cr.extra.orp && cr.extra.orp.rabatt2) {
        toReturn.discountPercent2 = cr.extra.orp.rabatt2;
      }
      if (cr.extra && cr.extra.orp && cr.extra.orp.vb_pris) {
        toReturn.overridePrice = cr.extra.orp.vb_pris;
      }
      if (cr.extra && cr.extra.orp && cr.extra.orp.artbeskr) {
        toReturn.description = cr.extra.orp.artbeskr;
      }
      if (this.getDeliveryDateFromRow(cr)) {
        const setDate = new Date(this.getDeliveryDateFromRow(cr));
        toReturn.deliveryDate = format(setDate, 'yyyy-MM-dd');
      }
      return toReturn;
    });
  }

  getMinDate(av: Availability): Date {
    if (!av) {
      return new Date();
    }
    if (av.currentAvailability > 0) {
      return new Date();
    }
    if (av.maxFutureStock === 0) {
      return new Date();
    }
    const valids = Object.keys(av.availabilities).filter(entry => av.availabilities[entry] > 0);
    const dateFormat = 'yyMMdd';
    return valids.length > 0 ? parse(valids[0], dateFormat, new Date()) : new Date();
  }

  formatNgbDate(date: NgbDate) {
    const dObj = this.dateUtilService.createDateFromNgbDate(date);
    return format(dObj, 'yyyy-MM-dd');
  }

  public calculateTotal(row: JeevesSalesCartListRow): number {
    const discountPercent = row.discountPercent || 0;
    const price = row.overridePrice || row.originalPrice;
    return price * row.quantity * (1.0 - discountPercent / 100);
  }

  calculateTotalValue(): number {
    return this.mappedData.map(r => this.calculateTotal(r)).reduce((acc, curr) => acc + curr, 0);
  }

  parseFloatFixed(value, fixed = 2) {
    if (!isNaN(value)) {
      return parseFloat(value).toFixed(fixed);
    }
    return value;
  }

  dateSelected(event) {
    const date = event.date;

    for (const row of this.mappedData) {
      let rowDate = this.getDeliveryDateFromRow(row.cartRow) || row.deliveryDate || row.minDate;
      if (typeof rowDate === 'string') {
        rowDate = parse(rowDate, 'yyyy-MM-dd', new Date());
      } else if (rowDate instanceof NgbDate) {
        rowDate = new Date(rowDate.year, rowDate.month - 1, rowDate.day);
      }

      if (rowDate < date) {
        this.cartService.removeExtraField(
          row.productId,
          this.getDeliveryDateFieldName(),
          row.targetStockId,
          row.productPartialId
        );
        const dateFortmated = format(date, 'yyyy-MM-dd');
        row.deliveryDate = dateFortmated;
        const extra = this.getExtraWithDeliveryDate(dateFortmated);
        this.cartService.setExtra(row.productId, extra, row.targetStockId, row.productPartialId);
      }
    }
  }

  restoreMinDate() {
    for (const row of this.mappedData) {
      const rowDate = new Date(row.minDate.year, row.minDate.month - 1, row.minDate.day);

      this.cartService.removeExtraField(
        row.productId,
        this.getDeliveryDateFieldName(),
        row.targetStockId,
        row.productPartialId
      );
      const dateFortmated = format(rowDate, 'yyyy-MM-dd');
      row.deliveryDate = dateFortmated;
      const extra = this.getExtraWithDeliveryDate(dateFortmated);
      this.cartService.setExtra(row.productId, extra, row.targetStockId, row.productPartialId);
    }
    this.deliveryDate = new Date();
  }

  getDeliveryDateFieldName(): string {
    return 'orp.ordberlevdat';
  }

  getExtraWithDeliveryDate(deliveryDate: string) {
    const fields = this.getDeliveryDateFieldName().split('.');
    const data = {};
    let tmp = data;
    for (const field of fields.slice(0, -1)) {
      tmp[field] = {};
      tmp = tmp[field];
    }
    tmp[fields[fields.length - 1]] = deliveryDate;
    return data;
  }

  getDeliveryDateFromRow(row: CartRow) {
    const field = this.getDeliveryDateFieldName();
    const keys = field.split('.');
    return keys.reduce((acc, curr) => acc?.[curr], row.extra);
  }
}
