import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { EdiRespondeOrder } from '../../models/edi';
import { gungComparatorHelper } from '../../utils/gung-utils';
import { EdiOrderResponseListConfigService } from '../edi-order-response-list-config/edi-order-response-list-config.service';

@Injectable({
  providedIn: 'root'
})
export class EdiIncomingOrderResponseListConfigService extends EdiOrderResponseListConfigService {
  getItems(): Observable<EdiRespondeOrder[]> {
    return this.ediService
      .getIncomingOrderResponses()
      .pipe(map(items => items.sort((a, b) => gungComparatorHelper([a.timestamp, a.id], [b.timestamp, b.id], -1))));
  }
}
