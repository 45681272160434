import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Order } from 'gung-standard';
import { JeevesOrderService } from '../../services/jeeves-order.service';

@Component({
  selector: 'gung-jeeves-jeeves-edit-order-modal',
  templateUrl: './jeeves-edit-order-modal.component.html',
  styleUrls: ['./jeeves-edit-order-modal.component.css']
})
export class JeevesEditOrderModalComponent implements OnChanges {
  @Input()
  protected order: Order;

  @Output()
  public orderChange = new EventEmitter();

  public editedOrder: Order;
  private allowedEditableFields = [
    'betkod',
    'levvillkkod',
    'levsattkod',
    'ordlevadr2',
    'ordlevadr3',
    'ordlevadr4',
    'ftgpostnr',
    'ordlevadrlandskod',
    'kundbestnr',
    'editext'
  ];

  constructor(private modalService: NgbModal, private orderService: JeevesOrderService) {}

  open(content) {
    const modal = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static'
    });

    modal.result.then(
      result => {
        this.saveEditedOrder();
      },
      reason => {
        console.log(reason);
      }
    );
  }

  protected saveEditedOrder() {
    this.orderService.updateOrderHeader(this.editedOrder).subscribe((order: Order) => {
      this.orderChange.emit(order);
    });
  }

  ngOnChanges() {
    // Clone the order, do not modify it directly.
    this.editedOrder = JSON.parse(JSON.stringify(this.order));
    const orderHead = {};
    this.allowedEditableFields.forEach(field => {
      orderHead[field] = this.order.extra.oh[field];
    });
    this.editedOrder.extra = {
      oh: orderHead
    };
  }
}
