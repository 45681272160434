import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtilService } from 'gung-common';
import { SupplierPoSplitModalComponent } from 'gung-standard';

@Component({
  selector: 'gung-jeeves-jeeves-supplier-po-split-modal',
  templateUrl: './jeeves-supplier-po-split-modal.component.html',
  styleUrls: ['./jeeves-supplier-po-split-modal.component.css']
})
export class JeevesSupplierPoSplitModalComponent extends SupplierPoSplitModalComponent implements OnInit {

  today = new Date();
  
  constructor(
    public activeModal: NgbActiveModal,
    public dateUtilService: DateUtilService
  ) {
    super(activeModal);
  }

  ngOnInit(): void {
    this.originalSupplierPurchaseOrder = JSON.parse(JSON.stringify(this.supplierPurchaseOrder));
    this.newSupplierPurchaseOrder = JSON.parse(JSON.stringify(this.supplierPurchaseOrder));

    this.originalQty = Number(this.supplierPurchaseOrder.extra.bp.bestant);
    this.originalSupplierPurchaseOrder.extra.bp.bestant = (this.originalQty - 1);
    this.newSupplierPurchaseOrder.extra.bp.bestant = 1;
    this.newInputQty = 1;
  }

  inputChanged(qty: number) {
    if (qty >= this.originalQty) {
      this.newInputQty = qty = (this.originalQty - 1);
    }
    this.originalSupplierPurchaseOrder.extra.bp.bestant = (this.originalQty - qty);
    this.newSupplierPurchaseOrder.extra.bp.bestant = (qty);
  }

}
