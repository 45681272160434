import { Component } from '@angular/core';
import { HubspotContactListConfigService } from '../../services/hubspot-contact-list-config/hubspot-contact-list-config.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-hubspot-contact-list',
  templateUrl: './hubspot-contact-list.component.html',
  styleUrls: ['./hubspot-contact-list.component.css']
})
export class HubspotContactListComponent {

  constructor(
    public hubspotContactListConfigService: HubspotContactListConfigService,
    route: ActivatedRoute
    ){
      route.params.subscribe((params) => {
        hubspotContactListConfigService.setCustomerId(params.customerId);
      });
      
  }
}
