<span *ngIf="productConfigurationCartRowService.isProductConfiguration(row)"
      [popoverTitle]="title"
      [ngbPopover]="content"
      [autoClose]="'outside'"
      triggers="mouseenter"
      container="body">
  <i class="fa-regular fa-lightbulb-gear"></i>
</span>

<ng-template #title><span translate>CONFIGURATION_DETAILS</span></ng-template>
<ng-template #content>
  <span *ngFor="let featureOptions of selectionsGroupedByFeature | keyvalue">
    <span style="font-weight: bold;">{{keyedFeatures.get(featureOptions.key).name}}: </span>
    <span *ngFor="let option of featureOptions.value">{{option.name}}. </span>
    <br/>
  </span>
</ng-template>
