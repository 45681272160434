import { Injectable } from '@angular/core';
import { Availability } from '../../../models/availability';
import { ReplenishmentProduct, ReplenishmentProductStatus } from '../models/replenishment';

@Injectable({
  providedIn: 'root'
})
export class ReplenishmentProductStatusService {
  constructor() {}

  getStatus(product: ReplenishmentProduct, availability: Availability): ReplenishmentProductStatus {
    if (this.isShortage(product, availability)) {
      return ReplenishmentProductStatus.SHORTAGE;
    }

    if (!product.currentStockUpdateTime) {
      return ReplenishmentProductStatus.NEVER_SYNCED;
    }

    if (!product.minimumQuantity && !product.maximumQuantity) {
      return ReplenishmentProductStatus.NO_PRESSURE;
    }

    if (this.isNoPressure(product)) {
      return ReplenishmentProductStatus.NO_PRESSURE;
    }

    return ReplenishmentProductStatus.OK_FOR_ORDER;
  }

  protected isNoPressure(product: ReplenishmentProduct): boolean {
    return this.getProductCurrentStock(product) >= product.minimumQuantity;
  }

  protected isShortage(product: ReplenishmentProduct, availability: Availability): boolean {
    if (!product || !availability) {
      return false;
    }

    return (
      this.getProductCurrentStock(product) < product.minimumQuantity &&
      availability.currentAvailability < product.minimumQuantity
    );
  }

  protected getProductCurrentStock(product: ReplenishmentProduct): number {
    return product.currentStock + (product.inTransitQuantity || 0);
  }
}
