import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart } from '@angular/router';
import { first } from 'rxjs';
import { GungTabChangeEvent, GungTabsetComponent } from 'gung-common';
import {
  AuthService,
  CustomerDetailsComponent,
  CustomerService,
  GungBase,
  LocationConfigService,
  NavbarConfigService,
  NavigationConfig,
  RouterEventsService
} from 'gung-standard';
import { JeevesCustomerActionConfigService } from '../../services/jeeves-customer-action-config/jeeves-customer-action-config.service';
import { JeevesCustomerDeliveryLocationListConfigService } from '../../services/jeeves-customer-delivery-location-list-config/jeeves-customer-delivery-location-list-config.service';

@Component({
  selector: 'gung-jeeves-jeeves-customer-details',
  templateUrl: './jeeves-customer-details.component.html',
  styleUrls: ['./jeeves-customer-details.component.css']
})
export class JeevesCustomerDetailsComponent extends CustomerDetailsComponent implements OnInit {
  _ngbTab: GungTabsetComponent;
  @ViewChild('ngbTab')
  set ngbTab(ngbTab: GungTabsetComponent) {
    if (!ngbTab) {
      return;
    }
    this._ngbTab = ngbTab;
    if (this.activeIdString) {
      this.ngbTab.select(this.activeIdString);
    }
  }
  get ngbTab(): GungTabsetComponent {
    return this._ngbTab;
  }
  activeIdString: string;

  isActuator = false;
  public enableContacts = this.navbarConfigService.enableContacts;
  public enableWarrantyClaims = this.navbarConfigService.enableWarrantyClaims;
  public enableJeevesClaims = this.navbarConfigService.enableJeevesClaims;

  constructor(
    route: ActivatedRoute,
    customerService: CustomerService,
    public configService: JeevesCustomerActionConfigService,
    public jeevesCustomerDeliveryLocationListConfigService: JeevesCustomerDeliveryLocationListConfigService,
    protected authService: AuthService,
    protected routerEventsService: RouterEventsService,
    protected locationConfigService: LocationConfigService,
    protected navbarConfigService: NavbarConfigService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig
  ) {
    super(route, customerService, authService, navbarConfigService, environment);
    this.routerEventsService
      .getRouterNavigationStart()
      .pipe(first())
      .subscribe((data: NavigationStart) => {
        if (data.navigationTrigger === 'popstate') {
          this.navigationTrigger(data);
        } else {
          // Remove previous selected tab
          const customerDetails = this.locationConfigService.get('customer-details');
          if (customerDetails) {
            this.locationConfigService.remove(customerDetails);
          }
        }
      });
  }

  ngOnInit() {
    const routeParams = this.route.snapshot.params;
    if (routeParams.customerId != null) {
      this.customerService
        .getCustomer(routeParams.customerId)
        .pipe(first())
        .subscribe(customer => {
          this.jeevesCustomerDeliveryLocationListConfigService.customerId = customer && customer.id;
          this.customer = customer;
          if (!this.customer.extra._billing) {
            console.error('Customer missing extra _billing information');
            this.customer = JSON.parse(JSON.stringify(customer));
            this.customer.extra = {
              _billing: {},
              ...this.customer.extra
            };
          }
        });
    }
    this.authService.getCurrentUser().subscribe(user => (this.isActuator = user.roles.indexOf('ACTUATOR') > -1));
  }

  tabChanged(event: GungTabChangeEvent) {
    // const tabId = Number(event.nextId.replace('lib-gung-tab-', ''));
    const tabs: string[] = (this.ngbTab.tabs as any)._results.map(t => t.id);
    const tabId = +tabs
      .find(t => t === event.nextId)
      .split('-')
      .pop();
    this.locationConfigService.add({
      path: 'customer-details',
      scrollX: tabId,
      scrollY: tabId
    });
  }

  protected navigationTrigger(data: NavigationStart) {
    const customerDetails = this.locationConfigService.get('customer-details');
    if (customerDetails) {
      const activeIdString = customerDetails.scrollX;
      const tabId = 'lib-gung-tab-' + activeIdString;
      this.activeIdString = tabId;
    }
  }
}
