import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { format, subYears } from 'date-fns';
import { InvoiceStatistic, DashboardCard, InvoiceStatisticSubset } from '../../../types';
import { Observable, of, Subject } from 'rxjs';
import { InvoiceStatisticsService } from '../../../services/invoice-statistics.service';
import { DashboardKpiService } from '../../../services/dashboard-kpi.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-monthly-sales-summary-card',
  templateUrl: './monthly-sales-summary-card.component.html',
  styleUrls: ['./monthly-sales-summary-card.component.scss']
})
export class MonthlySalesSummaryCardComponent implements OnInit, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();
  @Input()
  preConditions: any;

  @Input()
  invoices: InvoiceStatisticSubset[];

  salesThisMonth = 0;
  salesLastYearThisMonth = 0;
  monthString = format(new Date(), 'MMMM yyyy');
  monthStringLastYear = format(subYears(new Date(), 1), 'MMMM yyyy');
  loaded = false;

  constructor(protected dashboardKpiService: DashboardKpiService) {}

  ngOnInit() {
    this.dashboardKpiService
      .getCurrentMonthSalesComparison(this.preConditions)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.salesThisMonth = res.thisYear;
        this.salesLastYearThisMonth = res.lastYear;
        this.loaded = true;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
