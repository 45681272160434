<div *ngIf="loginRequired || (!isLoading && !errorOccured); else noApp">
  <mat-sidenav-container [ngStyle]="containerStyles" [hasBackdrop]="true">
    <mat-sidenav
      #sidenav
      mode="over"
      [fixedInViewport]="true"
      [fixedTopGap]="marginTop"
      position="end"
      (opened)="sideMenuChange(true)"
      (closed)="sideMenuChange(false)"
    >
      <ul *ngIf="mainMenu" class="no-style p-2 main-menu">
        <li class="col-12 link-block" *ngFor="let linkGroup of mainMenu.links">
          <div *ngIf="linkGroup.link || linkGroup.action; else noLink">
            <a
              [routerLink]="linkGroup.link ? linkGroup.link : undefined"
              [routerLinkActive]="linkGroup.link ? 'active' : ''"
              (click)="linkGroup.action ? linkGroup.action() : undefined"
            >
              <i *ngIf="linkGroup.icon" class="fa {{ linkGroup.icon }} mr-2"></i>
              <span translate>{{ linkGroup.heading }}</span></a
            >
          </div>

          <ng-template #noLink>
            <h6 class="text-uppercase font-weight-bold">
              <i *ngIf="linkGroup.icon" class="fa {{ linkGroup.icon }} mr-2"></i>
              <span translate>{{ linkGroup.heading }}</span>
            </h6>
          </ng-template>

          <ul class="pl-0 no-style">
            <li *ngFor="let childLink of linkGroup.links">
              <a
                [routerLink]="childLink.link ? childLink.link : undefined"
                [routerLinkActive]="childLink.link ? 'active' : ''"
                (click)="childLink.action ? childLink.action() : undefined"
              >
                <i *ngIf="childLink.icon" class="fa {{ childLink.icon }} mr-2"></i>
                <span translate>{{ childLink.heading }}</span>
              </a>
              <ul>
                <li *ngFor="let subLink of childLink.links">
                  <a
                    [routerLink]="subLink.link ? subLink.link : undefined"
                    [routerLinkActive]="subLink.link ? 'active' : ''"
                    (click)="subLink.action ? subLink.action() : undefined"
                  >
                    <i *ngIf="subLink.icon" class="fa {{ subLink.icon }} mr-2"></i>
                    <span translate>{{ subLink.heading }}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </mat-sidenav>

    <mat-sidenav-content>
      <sjoborg-navbar
        #navbar
        *ngIf="!hideNavbar"
        [class.d-none]="hideNavbar"
        (navbarHeight)="setContainerMarginTop($event)"
        (sidenavToggle)="sidenav.toggle()"
        (topMenuExpanded)="topMenuChange($event)"
      ></sjoborg-navbar>
      <div id="app-wrapper-content-container" class="wrapper-content-container" (click)="collapseTopMenu()">
        <div class="container main">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <lib-footer></lib-footer>
</div>
<ng-template #noApp>
  <div class="wrapper d-flex align-items-center justify-content-center m-5">
    <div *ngIf="isLoading && !errorOccured">
      <i class="fa fa-spinner fa-spin fa-7x"></i>
    </div>
    <div *ngIf="errorOccured">
      <h1 class="text-center">An error occured, please log out and contact your supplier</h1>
      <div class="d-flex justify-content-center">
        <button class="btn btn-primary" (click)="logout()" translate>LOGOUT</button>
      </div>
    </div>
  </div>
</ng-template>
