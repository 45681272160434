import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CommonModalService,
  GungTabsetComponent,
  JsonViewModal,
  PasswordRequirement,
  getValidEmailExpression as getValidEmailExpressionCommon,
  gungAddRemoveSpinner
} from 'gung-common';
import { catchError, first, forkJoin, Observable, of, switchMap, throwError, Subject, takeUntil } from 'rxjs';
import { Customer } from '../../models/customer';
import { AuthService } from '../../services/auth/auth.service';
import { BackendFeatureService } from '../../services/backend-feature.service';
import { CustomerService } from '../../services/customers/customer.service';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { PasswordPolicyService } from '../../services/password-policy.service';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { UsersService } from '../../services/users/users.service';
import { User } from '../../state/auth/types';
import { CompanyTemplatesService } from '../../services/company-templates.service';

export function getValidEmailExpression(): string {
  return getValidEmailExpressionCommon();
}

@Component({
  selector: 'lib-create-customer-modal',
  templateUrl: './create-customer-modal.component.html',
  styleUrls: ['./create-customer-modal.component.css']
})
export class CreateCustomerModalComponent implements OnInit, OnDestroy {
  protected extraKey = 'extra';
  protected customerKey: string; // ERP based

  customerForm: FormGroup;
  customer: Customer;
  savedCustomer: Customer;
  currentUser: User;

  hasTemplates = false;
  hasTemplateSelection = false;
  companyTemplates = [];

  customerCreated = false;

  userForm: FormGroup;
  password: string;
  userCreated = false;
  savedUser: User;
  sendEmail = false;
  currentTemplate: string;
  templateUserGroups;

  passwordRequirements: PasswordRequirement;

  protected unsubscribe: Subject<void> = new Subject();
  protected templateHasExtra: boolean = false;

  public nextBtn: string = 'NEXT';
  public activeId;

  _ngbTab: GungTabsetComponent;
  @ViewChild('ngbTab')
  set ngbTab(ngbTab: GungTabsetComponent) {
    if (!ngbTab) {
      return;
    }
    this._ngbTab = ngbTab;
    if (this.activeId) {
      this.ngbTab.select(this.activeId);
    }
  }
  get ngbTab(): GungTabsetComponent {
    return this._ngbTab;
  }

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected gungFlow: GungFlowService,
    protected authService: AuthService,
    protected customerService: CustomerService,
    protected usersService: UsersService,
    public selectCustomerService: SelectedCustomerService,
    protected router: Router,
    protected modalService: CommonModalService,
    protected backendFeatureService: BackendFeatureService,
    protected passwordPolicyService: PasswordPolicyService,
    protected companyTemplatesService: CompanyTemplatesService
  ) { }

  ngOnInit(): void {
    this.initCustomer();

    forkJoin({
      systemSettings: this.gungFlow.getSystemGung().pipe(first()),
      currentUser: this.authService.getCurrentUser().pipe(first()),
      passwordRequirements: this.backendFeatureService.isActivated('passwordPolicies').pipe(
        first(),
        switchMap(isActivated => {
          if (isActivated) {
            return this.passwordPolicyService.getPasswordRequirements();
          }
          return of(undefined as PasswordRequirement);
        })
      ),
      isTemplateFeatureActive: this.backendFeatureService.isActivated('gung-company-templates').pipe(first())
    }).subscribe(({ systemSettings, currentUser, passwordRequirements, isTemplateFeatureActive }) => {
      this.currentUser = currentUser;
      this.companyTemplates = systemSettings.extra.companyTemplates || [];
      this.passwordRequirements = passwordRequirements;

      if (this.extraKey && !!currentUser[this.extraKey]) {
        if (currentUser[this.extraKey].companyTemplateIds) {
          this.companyTemplates = this.companyTemplates.filter(
            template => !template.id || currentUser[this.extraKey].companyTemplateIds.indexOf(template.id) >= 0
          );
        }

        if (this.companyTemplates.length === 0) {
          if (!!currentUser[this.extraKey].companyTemplate) {
            this.companyTemplates.push(currentUser[this.extraKey].companyTemplate);
          }
        }
      }

      this.hasTemplates = this.companyTemplates.length > 0;

      this.initFormControl();
      this.initUserFormControl();

      if (this.hasTemplates && this.companyTemplates.length === 1) {
        this.applyTemplate(this.companyTemplates[0]);
      }

      if (isTemplateFeatureActive && this.companyTemplates.length === 0) {
        this.loadCompanyTemplates();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getFormControl(value): AbstractControl {
    return this.customerForm.get(value);
  }

  initCustomer() {
    // init customer, if defined this is an edit customer
    if (!this.customer) {
      this.customer = {
        id: '',
        name: '',
        extra: {}
      };
      this.customer.extra[this.customerKey] = {};
    } else {
      this.customer = structuredClone(this.customer);
    }
  }

  protected initFormControl(): void {
    // Customize based on ERP
    this.customerForm = this.formBuilder.group({
      Name: this.formBuilder.control(this.customer.extra[this.customerKey].Name || '', [Validators.required]),
      Email: this.formBuilder.control(this.customer.extra[this.customerKey].Email || '', [
        Validators.pattern(new RegExp(getValidEmailExpression()))
      ])
    });
  }

  protected initUserFormControl() {
    this.userForm = this.formBuilder.group({
      name: this.formBuilder.control(this.customerForm.get('Name').value || ''),
      email: this.formBuilder.control(this.customerForm.get('Email').value || '', [Validators.email]),
      password: this.formBuilder.control('')
    });

    this.customerForm.get('Name').valueChanges.subscribe(value => {
      if (!!value && !this.customerForm.disabled) {
        this.userForm.get('name').setValue(value);
      }
    });
    this.customerForm.get('Email').valueChanges.subscribe(value => {
      if (!!value && !this.customerForm.disabled) {
        this.userForm.get('email').setValue(value);
      }
    });
  }

  applyTemplate(template): void {
    this.hasTemplateSelection = true;
    this.currentTemplate = template.name;

    this.customer.extra = {
      ...this.customer.extra,
      // In order to get customer values that are NOT in the customer key, such as Gung owned fields.
      ...(this.templateHasExtra ? template?.extra : {})
    };

    this.customer.extra[this.customerKey] = {};

    // merge template into customer
    this.customer.extra[this.customerKey] = {
      ...this.customer.extra[this.customerKey],
      ...(this.templateHasExtra ? template?.extra?.[this.customerKey] : template[this.customerKey])
    };

    if (this.extraKey && !!this.currentUser[this.extraKey] && !!this.currentUser[this.extraKey].companyTemplate) {
      const mergedCustomer = {
        ...this.customer.extra[this.customerKey],
        ...this.currentUser[this.extraKey].companyTemplate[this.customerKey]
      };

      this.customer.extra = {
        ...this.customer.extra,
        ...this.currentUser[this.extraKey].companyTemplate,
        [this.customerKey]: mergedCustomer
      };
    }
    this.setTemplateUserGroups(template);
    this.customerForm.patchValue(this.customer.extra[this.customerKey]);
  }

  setTemplateUserGroups(template) {
    if (template.userGroups) {
      this.templateUserGroups = {};
      for (const group of template.userGroups) {
        this.templateUserGroups[group] = true;
      }
    } else {
      this.templateUserGroups = null;
    }
  }

  copyInvoiceAddressIntoDeliveryAddress(): void {
    this.customer.extra[this.customerKey] = {
      ...this.customer.extra[this.customerKey],
      ...this.customerForm.getRawValue()
    };

    this.customerForm.get('DeliveryName').setValue(this.customer.extra[this.customerKey].Name);
    this.customerForm.get('DeliveryAddress1').setValue(this.customer.extra[this.customerKey].Address1);
    this.customerForm.get('DeliveryAddress2').setValue(this.customer.extra[this.customerKey].Address2);
    this.customerForm.get('DeliveryCity').setValue(this.customer.extra[this.customerKey].City);
    this.customerForm.get('DeliveryZipCode').setValue(this.customer.extra[this.customerKey].ZipCode);
    this.customerForm.get('DeliveryPhone1').setValue(this.customer.extra[this.customerKey].Phone1);
    this.customerForm.get('DeliveryCountryCode').setValue(this.customer.extra[this.customerKey].CountryCode);
  }

  close() {
    this.activeModal.close('Closed');
  }

  save(event?: any): void {
    this.customerCreated = false;
    if (this.customerForm.invalid) {
      this.customerForm.markAllAsTouched();
      return;
    }

    gungAddRemoveSpinner(event?.['target']);

    this.customer.extra[this.customerKey] = {
      ...this.customer.extra[this.customerKey],
      ...this.customerForm.getRawValue()
    };

    // We dont want to send an empty string (example VATNumber since this will fail in the fortnox validation)
    this.customer.extra[this.customerKey] = Object.fromEntries(
      Object.entries(this.customer.extra[this.customerKey]).filter(([_, v]) => v !== '')
    );

    this.customerForm.disable();

    const user: User = this.userForm.getRawValue();
    if (this.templateUserGroups) {
      user.activeGroups = this.templateUserGroups;
    }
    this.customerService
      .createNewCustomer(this.customer)
      .pipe(
        switchMap(customer => {
          let requestUser: Observable<User> = of(null);
          if (!!user.password && !!user.email && !!user.name) {
            user.managedCompanyIds = [customer.id];
            // user.assortment =
            user.username = user.email;
            requestUser = this.usersService.createUser(user).pipe(first());
          }
          return forkJoin({
            customer: of(customer),
            userNew: requestUser
          });
        }),
        catchError((error: HttpErrorResponse, caught) => {
          const data: JsonViewModal = {
            title: 'ERROR',
            subTitle: error.error.status,
            json: error.error.gungMessage || error.error.message,
            typeString: true
          };

          this.modalService.openJSONModal(data);
          return throwError(() => error);
        })
      )
      .subscribe({
        next: ({ customer, userNew }) => {
          // Success
          this.savedCustomer = customer;
          this.customerCreated = true;
          this.addMore();

          this.userCreated = !!userNew;
          if (this.userCreated) {
            this.savedUser = userNew;
            if (!!this.sendEmail) {
              const domain = location.origin;
              this.usersService.sendWelcomeUserMessage(userNew.username, domain).subscribe();
            }
          }
          gungAddRemoveSpinner(event?.['target']);
          // this.selectCustomer(customer);
        },
        error: error => {
          gungAddRemoveSpinner(event?.['target']);
          this.customerForm.enable();
        }
      });
  }

  addMore() {
    this.customer = {
      id: '',
      name: '',
      extra: {}
    };
    this.customer.extra[this.customerKey] = {};
    this.resetForm(this.customerForm);
    this.resetForm(this.userForm);
    this.password = '';
    this.savedUser = undefined;
    this.customerForm.enable();
  }

  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setValue('');
      form.get(key).markAsPristine();
      form.get(key).markAsUntouched();
    });
  }

  setPassword(validPassword) {
    if (!!this.password && validPassword) {
      this.userForm.get('password').setValue(this.password);
    }
  }

  selectCustomer(customer: Customer) {
    this.router.navigate(['customers', customer.id]);
    this.selectCustomerService.selectCustomer(customer.id);
    this.close();
  }

  loadCompanyTemplates(): void {
    this.companyTemplatesService
      .getAllCompanyTemplates()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(templates => {
        if (templates.length > 0) {
          this.companyTemplates = templates;
          this.hasTemplates = true;
          this.templateHasExtra = true;
        }
      });
  }

  onNextClick(event) {
    if (!this.ngbTab) {
      return;
    }
    const tabs = this.ngbTab.tabs.toArray();
    const idx = tabs.findIndex(t => t.id === this.activeId);
    if (tabs.length > 0 && idx < tabs.length - 1) {
      this.ngbTab.select(tabs[idx + 1].id);
    } else {
      this.save(event);
    }
  }

  tabChanged({ activeId, nextId }) {
    const tabs = this.ngbTab.tabs.toArray();
    const idx = tabs.findIndex(t => t.id === nextId);
    if (tabs.length > 0 && idx < tabs.length - 1) {
      this.nextBtn = 'NEXT';
    } else {
      this.nextBtn = 'SUBMIT';
    }
  }
}
