import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {
  ActivityInternalService,
  GungModalService,
  ActivityInternalViewModalComponent,
  ActivityInternal
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isAfter, isSameDay, parseISO, subDays } from 'date-fns';

@Component({
  selector: 'lib-upcoming-activities-table-card',
  templateUrl: './upcoming-activities-table-card.component.html',
  styleUrls: ['./upcoming-activities-table-card.component.scss']
})
export class UpcomingActivitiesTableCardComponent implements OnInit, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();

  activities: ActivityInternal[] = [];
  loaded = false;

  constructor(
    protected activityInternalService: ActivityInternalService,
    public dateUtilService: DateUtilService,
    protected gungModalService: GungModalService,
    protected modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.activityInternalService
      .getAll()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        const today = new Date();

        for (const a of res) {
          const activityDate = parseISO(a.activityDate);
          const isTodayOrFuture = isSameDay(today, activityDate) || isAfter(activityDate, today);
          if (isTodayOrFuture && this.activities.length < 10) {
            this.activities.push(a);
          } else {
            break;
          }
        }

        this.loaded = true;
      });
  }

  openViewModal(item: ActivityInternal) {
    const modalRef = this.modalService.open(ActivityInternalViewModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static'
    });
    modalRef.componentInstance.data = item;
    modalRef.result.then(
      result => {},
      reason => {
        console.log(`Dismissed ${reason}`);
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
