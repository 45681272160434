import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map, Observable, ReplaySubject, tap } from 'rxjs';
import { MatrixDiscount } from '../../models/matrix-discount';

@Injectable({
  providedIn: 'root'
})
export class MatrixDiscountsService {

  public customerDiscountMetadata = {
    table: 'CUSTOMERDISCOUNTGROUP',
    id: 'id',
    name: 'name',
  };

  public itemDiscountMetadata = {
    table: 'ITEMDISCOUNTGROUP',
    id: 'id',
    name: 'name',
  };


  private matrixDiscountSubject: ReplaySubject<MatrixDiscount[]>;
  protected baseUrl = 'json/matrix-discounts';

  constructor(protected http: HttpClient) {}

  deleteMatrixDiscount(id: string): Observable<boolean> {
    const url = `${this.baseUrl}/` + id;
    return this.http.delete<boolean>(url).pipe(
      first(),
      tap(result => {
        this.matrixDiscountSubject.pipe(first()).subscribe(value => {
          const index = value.findIndex(item => item.id === id);
          if (index > -1) {
            value.splice(index, 1);
            this.matrixDiscountSubject.next(value);
          }
        });
      })
    );
  }

  protected updateMatrixDiscountSubject(matrixDiscount: MatrixDiscount): void {
    if (!this.matrixDiscountSubject) {
      return;
    } else {
      this.matrixDiscountSubject.pipe(first()).subscribe(value => {
        const index = value.findIndex(item => item.id === matrixDiscount.id);
        if (index > -1) {
          value.splice(index, 1, matrixDiscount);
        } else {
          value.unshift(matrixDiscount);
        }

        this.matrixDiscountSubject.next(value);
      });
    }
  }

  protected internalGetMatrixDiscounts(nocache?: boolean): Observable<MatrixDiscount[]> {
    const url = `${this.baseUrl}`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };

    return this.http.get<MatrixDiscount[]>(url, { headers }).pipe(first());
  }

  public postMatrixDiscount(matrixDiscount: MatrixDiscount): Observable<MatrixDiscount> {
    const url = `${this.baseUrl}`;
    return this.http.post<any>(url, matrixDiscount).pipe(tap(() => this.updateMatrixDiscountSubject(matrixDiscount)));
  }

  getAllMatrixDiscount(nocache?: boolean): Observable<MatrixDiscount[]> {
    if (!this.matrixDiscountSubject) {
      this.matrixDiscountSubject = new ReplaySubject<MatrixDiscount[]>();
      this.internalGetMatrixDiscounts(nocache)
        .pipe(first())
        .subscribe(news => this.matrixDiscountSubject.next(news));
    }

    return this.matrixDiscountSubject.asObservable();
  }

  getMatrixDiscountById(newsId: string): Observable<MatrixDiscount> {
    return this.getAllMatrixDiscount().pipe(
      first(),
      map(news => news.find(n => n.id === newsId))
    );
  }
}
