import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { HubspotOwner } from '../../models/hubspot-owner';

@Injectable({
  providedIn: 'root'
})
export class HubspotOwnerService {
  constructor(protected http: HttpClient) {}

  public getOwners(): Observable<HubspotOwner[]> {
    const url = `json/hubspot/owners`;
    return this.http.get<HubspotOwner[]>(url, {});
  }

  public getOwnersByTeam(teamId: string): Observable<HubspotOwner[]> {
    const url = `json/hubspot/owners`;
    return this.http.get<HubspotOwner[]>(url, {}).pipe(
      map(owners => {
        return owners.filter(owner => {
          if (!owner.extra || !owner.extra.hubspotOwner || !owner.extra.hubspotOwner.teams) {
            return false;
          }
          return owner.extra.hubspotOwner.teams.some(team => {
            return team.id === teamId;
          });
        });
      })
    );
  }
}
