import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Customer, Product, SelectedCustomerService } from 'gung-standard';
import { JeevesKuarUpdate } from '../../models/jeeves-kuar-update';
import { JeevesKuarUpdateService } from '../../services/jeeves-kuar-update/jeeves-kuar-update.service';
import { first } from 'rxjs/operators';

interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'gung-jeeves-jeeves-update-kuar-modal',
  templateUrl: './jeeves-update-kuar-modal.component.html',
  styleUrls: ['./jeeves-update-kuar-modal.component.css']
})
export class JeevesUpdateKuarModalComponent implements OnInit {
  product: Product;
  selectedCustomer: Customer;

  alertMessage: Alert;
  submited = false;
  kuarForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected jeevesKuarUpdateService: JeevesKuarUpdateService,
    protected selectedCustomerService: SelectedCustomerService
  ) {}

  ngOnInit(): void {
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => {
        this.selectedCustomer = customer;

        this.kuarForm = this.formBuilder.group({
          productId: this.formBuilder.control(this.product.id, []),
          productName: this.formBuilder.control(this.product.name, []),
          customerProductId: this.formBuilder.control(
            (this.product.extra._customerCrossReferences &&
              this.product.extra._customerCrossReferences[customer.id]?.referenceId) ||
              '',
            []
          ),
          customerProductName: this.formBuilder.control(
            (this.product.extra._customerCrossReferences &&
              this.product.extra._customerCrossReferences[customer.id]?.name) ||
              '',
            []
          )
          // customerProductDescription: this.formBuilder.control(this.product.extra._customerCrossReferences && this.product.extra._customerCrossReferences[customer.id]?.description || '', []),
        });
      });
  }

  onSave() {
    if (!this.kuarForm.valid) {
      this.kuarForm.markAllAsTouched();
      return;
    }
    const kuarRaw = this.kuarForm.getRawValue();
    const update = {
      ftgnr: this.selectedCustomer.id,
      artnr: this.product.id,
      artnrkund: kuarRaw.customerProductId,
      artbeskr: kuarRaw.customerProductName
      // artbeskr2: kuarRaw.customerProductDescription
    } as JeevesKuarUpdate;

    this.jeevesKuarUpdateService.updateKuar(update).subscribe(
      res => {
        this.alertMessage = { type: 'success', message: 'INFORMATION_SAVED' };
        this.submited = true;
      },
      () => {
        this.alertMessage = { type: 'danger', message: 'AN_ERROR_HAS_OCCURED' };
        this.submited = true;
      }
    );
  }
}
