import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { OrderPortalEntry, OrderPortalStatistic } from '../models/order-portal';

@Injectable({
  providedIn: 'root'
})
export class OrderPortalService {
  private baseUrl = 'json/order-portal';

  constructor(protected http: HttpClient) {}

  public getEntries(fromDateYearMonthDay?: string): Observable<OrderPortalEntry[]> {
    let baseUrl = this.baseUrl + '/entries';
    if (fromDateYearMonthDay) {
      baseUrl = `${baseUrl}?fromDateYearMonthDay=${fromDateYearMonthDay}`;
    }

    return this.http.get<OrderPortalEntry[]>(baseUrl);
  }

  public getStatistics(): Observable<OrderPortalStatistic[]> {
    return this.http.get<OrderPortalStatistic[]>(this.baseUrl + '/statistics');
  }
}
