import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface OrderRowPickStatus {
  id: string;
  rowId: string;
  orderId: string;
  picked: boolean;
  updatedBy: string;
  updatedAt: Date;
}

@Injectable({
  providedIn: 'root'
})
export class ProductionManagementOrderPickingService {
  // Define the API endpoint
  private apiUrl = '/json/production/order-picking';

  constructor(private http: HttpClient) {}

  getOrderPicking(orderId: string): Observable<OrderRowPickStatus[]> {
    return this.http.get<OrderRowPickStatus[]>(`${this.apiUrl}/${orderId}`);
  }

  postToggleRowPickStatus(orderId: string, rowId: string): Observable<OrderRowPickStatus> {
    return this.http.post<OrderRowPickStatus>(`${this.apiUrl}/${orderId}/toggle-row-pick-status?rowId=${rowId}`, null);
  }
}
