<div *ngIf="!!customer">
  <div class="row mb-4">
    <div class="col d-flex flex-direction-row justify-content-between">
      <h3>{{ customer.name }}</h3>
      <lib-action-btn [item]="customer" [config]="configService"></lib-action-btn>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <lib-gung-tabset
        #ngbTab="gungTabset"
        (tabChange)="tabChanged($event)"
        [activeId]="activeIdString"
        justify="fill"
        *ngIf="!!customer"
      >
        <lib-gung-tab [title]="'ADDRESS' | translate" id="lib-gung-tab-0">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="control-label" for="nameInput" translate>CUSTOMER_NAME</label>
                  <input class="form-control" name="nameInput" type="text" [(ngModel)]="customer.name" disabled />
                </div>

                <div class="form-group">
                  <label class="control-label" for="address" translate>FR_FTGPOSTADR1</label>
                  <input
                    class="form-control"
                    name="address"
                    type="text"
                    [(ngModel)]="customer.extra.fr.ftgpostadr1"
                    disabled
                  />
                </div>

                <div class="form-group">
                  <label class="control-label" for="city" translate>FR_FTGPOSTADR2</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra.fr.ftgpostadr2"
                    disabled
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="control-label" for="postalCode" translate>FR_FTGPOSTNR</label>
                  <input
                    class="form-control"
                    name="postalCode"
                    type="text"
                    [(ngModel)]="customer.extra.fr.ftgpostnr"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label class="control-label" for="city" translate>FR_FTGPOSTADR3</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra._billing.ftgpostadr3"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label class="control-label" for="city" translate>FR_LANDSKOD</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra.fr.landskod"
                    disabled
                  />
                </div>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'DELIVERY_ADDRESS' | translate" id="lib-gung-tab-1">
          <ng-template libGungTabContent>
            <div class="pt-3">
              <lib-filter-list [configService]="jeevesCustomerDeliveryLocationListConfigService"></lib-filter-list>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'BILLING_INFORMATION' | translate" id="lib-gung-tab-2">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="control-label" for="city" translate>NAME</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra._billing.ftgnamn"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label class="control-label" for="address" translate>FR_FTGPOSTADR1</label>
                  <input
                    class="form-control"
                    name="address"
                    type="text"
                    [(ngModel)]="customer.extra._billing.ftgpostadr1"
                    disabled
                  />
                </div>

                <div class="form-group">
                  <label class="control-label" for="city" translate>FR_FTGPOSTADR2</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra._billing.ftgpostadr2"
                    disabled
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="control-label" for="city" translate>FR_FTGPOSTNR</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra._billing.ftgpostnr"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label class="control-label" for="city" translate>FR_FTGPOSTADR3</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra._billing.ftgpostadr3"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label class="control-label" for="city" translate>FR_LANDSKOD</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra._billing.landskod"
                    disabled
                  />
                </div>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'CONTACTS' | translate" id="lib-gung-tab-3">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <lib-customer-contact-list></lib-customer-contact-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'CONTACTS' | translate" *ngIf="enableContacts" id="lib-gung-tab-5">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <lib-customer-contact-list></lib-customer-contact-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab *ngIf="isActuator" [title]="'ACTIVITIES' | translate" id="lib-gung-tab-4">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <lib-activity-internal-list [customerId]="customer.id"></lib-activity-internal-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'ORDERS' | translate" id="lib-gung-tab-5">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <lib-order-list></lib-order-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'INVOICES' | translate" id="lib-gung-tab-6">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <gung-jeeves-jeeves-invoice-list></gung-jeeves-jeeves-invoice-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab *ngIf="enableWarrantyClaims" [title]="'RETURN_WARRANTY' | translate" id="lib-gung-tab-7">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <lib-return-warranty-list [customerId]="customer.id"></lib-return-warranty-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab *ngIf="enableJeevesClaims" [title]="'CLAIMS' | translate" id="lib-gung-tab-8">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <gung-jeeves-jeeves-claim-list [customerId]="customer.id"></gung-jeeves-jeeves-claim-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'USERS' | translate" id="lib-gung-tab-9">
          <ng-template libGungTabContent>
            <div class="row pt-4 users-style">
              <div class="col">
                <lib-customer-user-list></lib-customer-user-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
      </lib-gung-tabset>
    </div>
  </div>
</div>
