<!-- INITIAL IMPLEMENTATION OF BOTTOM BUTTONS
   <div class="d-flex justify-content-between flex-row-reverse">
  <button class="btn btn-primary" type="button" (click)="nextClicked.emit()">
    <span translate>{{nextBtnText}}</span>
  </button>
  <button class="btn btn-light" type="button" (click)="prevClicked.emit()" *ngIf="!isFirstStep">
      <span translate>{{prevBtnText}}</span>
  </button>
</div> -->

<div class="row pt-4">
  <div class="col-3 text-truncate">
    <h6 class="font-weight-bold" translate>CART_SUBTOTAL</h6>
  </div>
  <div class="col-4 text-center">
    {{ 'CART_ARTICLE_SKU_TOTAL' | translate: { article: cartTotalRows, sku: cartTotalItems } }}
  </div>
  <div class="col-5 font-weight-bold item-info text-primary text-right">
    {{ cartTotalValue | gungCurrency: cartCurrency:'code' }}
  </div>
</div>

<div class="row align-items-end pt-2">
  <div class="col-6 col-lg-8">
    <button type="button" class="btn btn-outline-primary checkout-btn-previous"
      (click)="isFirstStep ? emptyCart() : prevClicked.emit()" translate>
      {{ isFirstStep ? 'EMPTY_CART' : prevBtnText }}
    </button>
  </div>

  <div class="col-6 col-lg-4">
    <div class="row align-items-end">
      <div class="col-12 col-md-6">
        <button type="button" class="btn btn-outline-primary w-100" (click)="saveCart()" *ngIf="isFirstStep">
          {{ 'SAVE_CART' | translate }}
        </button>

        <!-- <button type="button" class="btn btn-outline-primary w-100 mt-1" (click)="createQuotation()"
          *ngIf="isFirstStep && isSales">{{'CREATE_QUOTATION' | translate}}</button> -->
        <button *ngIf="isLastStep" class="btn btn-outline-primary w-100 mt-1" type="button"
          (click)="nextAlternativeClicked.emit()">
          <span translate>{{ nextAlternativeBtnText }}</span>
        </button>
      </div>

      <div class="col-12 col-md-6">
        <button class="btn btn-primary w-100 mt-1 checkout-btn-next" type="button" (click)="nextClicked.emit()">
          <span translate>{{ nextBtnText }}</span>
        </button>
      </div>
    </div>
  </div>
</div>