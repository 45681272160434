import { Injectable } from '@angular/core';
import { AbstractSelectionService } from 'gung-list';
import { Bankgiro } from '../models/bankgiro';

@Injectable({
  providedIn: 'root'
})
export class BankgiroSelectionService extends AbstractSelectionService<Bankgiro> {
  constructor() {
    super();
  }

  getItemId(item: Bankgiro): string {
    return item.id;
  }
}
