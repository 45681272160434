import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GungTableCustomBodyComponent } from 'gung-common';
import { CustomerActionConfigService } from '../../../services/customer-action-config.service';
import { Customer } from '../../../models/customer';
import { SelectedCustomerService } from '../../../services/selected-customer/selected-customer.service';

@Component({
  selector: 'lib-customer-custom-table-body',
  templateUrl: './customer-custom-table-body.component.html',
  styleUrl: './customer-custom-table-body.component.scss'
})
export class CustomerCustomTableBodyComponent extends GungTableCustomBodyComponent<Customer> implements OnInit{

  originalData = {};
  constructor(
    protected selectCustomerService: SelectedCustomerService,
    public actionButtonsConfig?: CustomerActionConfigService
  ) {
    super()
  }

  ngOnInit(): void {
    this.data.map(c =>{
      this.originalData[c.id]=c;
    })
  }

  selectCustomer(id) {
    this.selectCustomerService.selectCustomer(id);
  }
}
