import { Component, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import {
  CartRowFeatureOptionSelection,
  OptionMaterial,
  ProductConfiguration,
  ProductConfigurationFeature
} from '../../models/product-configuration-types';
import { debounceTime, first, forkJoin } from 'rxjs';
import {
  OptionPrice,
  ProductConfigurationOptionPriceService
} from '../../services/product-configuration-option-price.service';
import { Product } from '../../../../models';
import { ProductService } from '../../../../services/products/product.service';
import { PriceService } from '../../../../services/price/price.service';
import { CustomerProductPrice } from '../../../../models/price';

@Component({
  selector: 'lib-cpq-configuration-summary',
  templateUrl: './cpq-configuration-summary.component.html',
  styleUrls: ['./cpq-configuration-summary.component.scss']
})
export class CpqConfigurationSummaryComponent implements OnChanges {
  @Input()
  configuration: ProductConfiguration;

  @Input()
  selections: CartRowFeatureOptionSelection[];

  groupedSelection: Map<string, CartRowFeatureOptionSelection[]>;
  products: Map<string, Product>;
  private fetchingProducts = false;

  configurationPrice = 0;
  optionPrices: Map<string, OptionPrice>;
  currency: string;
  public total: number = 0;

  constructor(
    protected optionPriceService: ProductConfigurationOptionPriceService,
    protected productService: ProductService,
    protected priceService: PriceService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.configuration || !this.selections) {
      return;
    }

    this.groupedSelection = this.groupByFeature(this.selections);
    this.calculateTotal();

    if (changes['selections'] && this.hasNewMaterialIds(changes['selections'])) {
      this.getProductsFromOptionMaterials(this.selections);
    }
  }

  private hasNewMaterialIds(change: SimpleChange): boolean {
    // If we are currently fetching products, don't attempt to fetch again until it has finalized
    if (this.fetchingProducts) {
      return false;
    }

    const currentMaterialIds: Set<String> = new Set<string>(this.products?.keys() || []);
    const newMaterialIds: Set<String> = new Set<String>(
      (change.currentValue || [])
        .flatMap((s: CartRowFeatureOptionSelection) => s.option.materials)
        .map((m: OptionMaterial) => m.productId)
    );

    // Important that both of these are of Set type to avoid false detection when we have multiple materials that have
    // the same id. Before only the currentMaterialIds was a Set, but then when adding multiple options with the same
    // material id we got an infinity loop with HTTP requests to the backend.
    if (newMaterialIds.size > currentMaterialIds.size) {
      // If we have more ids than before we can guarantee that we have new materials we need to fetch.
      return true;
    }

    const unseenMaterialIds = [...newMaterialIds].filter(id => !currentMaterialIds.has(id));
    return unseenMaterialIds.length > 0;
  }

  private groupByFeature(selections: CartRowFeatureOptionSelection[]): Map<string, CartRowFeatureOptionSelection[]> {
    const result = new Map<string, CartRowFeatureOptionSelection[]>();
    selections.forEach(s => {
      if (!result.has(this.getFeatureGroup(s.feature))) {
        result.set(this.getFeatureGroup(s.feature), []);
      }
      result.get(this.getFeatureGroup(s.feature)).push(s);
    });
    return result;
  }

  private getFeatureGroup(feature: ProductConfigurationFeature): string {
    return '' + feature.properties?.featureGroup || 'General';
  }

  private getProductsFromOptionMaterials(selections: CartRowFeatureOptionSelection[]) {
    const ids = selections.flatMap(s => s.option.materials).map(m => m.productId);
    if (ids.length == 0 || this.fetchingProducts) {
      return;
    }
    // This is a failsafe to avoid us DOS:ing our own servers when the change detection is faster than the product fetch
    this.fetchingProducts = true;

    this.productService
      .getProductsByIdsUnfiltered(ids)
      .pipe(debounceTime(200), first())
      .subscribe(productResults => {
        const products = new Map<string, Product>();
        productResults.forEach(p => products.set(p.id, p));
        this.products = products;
        this.fetchingProducts = false;
      });
  }

  calculateTotal(): void {
    const priceQuestions = [];

    for (const selection of this.selections) {
      priceQuestions.push(this.optionPriceService.getPrice(selection.option).pipe(first()));
    }

    forkJoin([
      this.priceService.getCurrentCustomerPrice(this.configuration.id).pipe(first()),
      ...priceQuestions
    ]).subscribe(prices => {
      const configurationPrice: CustomerProductPrice = prices.splice(0, 1)[0];
      this.configurationPrice = configurationPrice.customerNetPrice.value;

      this.total = prices.reduce((sum, price) => (sum += price.price), 0);
      this.total += this.configurationPrice;

      const priceMap = new Map<string, OptionPrice>();
      prices.forEach(price => {
        priceMap.set(price.id, price);
      });
      this.optionPrices = priceMap;
      this.currency = prices[0].currency;
    });
  }

  getSelectionQuantity(selection: CartRowFeatureOptionSelection): number {
    return Number(selection.option?.properties?.quantityAdded) || 1;
  }
}
