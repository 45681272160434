import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderPortalStatusModalComponent } from '../components/order-portal-status-modal/order-portal-status-modal.component';
import { OrderPortalEntry } from '../models/order-portal';

@Injectable({
  providedIn: 'root'
})
export class OrderPortalModalService {
  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();

  constructor(protected ngbModal: NgbModal) {}

  openOrderPortalStatusModal(entry: OrderPortalEntry): Promise<any> {
    const id = OrderPortalStatusModalComponent.name;

    const ref = this.ngbModal.open(OrderPortalStatusModalComponent, {
      size: 'lg',
      keyboard: true
    });

    ref.componentInstance.delegate = this;

    const modalComponent = ref.componentInstance as OrderPortalStatusModalComponent;
    modalComponent.setEntry(entry);

    this.modalRef.set(id, ref);
    return ref.result;
  }
}
