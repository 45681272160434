import { Injectable } from '@angular/core';
import { OrderPortalEntry } from '../models/order-portal';

@Injectable({
  providedIn: 'root'
})
export class OrderPortalChannelService {
  constructor() {}

  getCreatedEntityName(entry: OrderPortalEntry): string {
    if (entry.orderChannel === 'GUNG_WEB_OFFER') {
      return 'OFFER';
    } else if (entry.orderChannel === 'GUNG_WEB_RETURN') {
      return 'RETURN';
    } else if (this.isEdiToGungChannel(entry.orderChannel)) {
      return 'EDI_ORDER_MESSAGE';
    }

    return 'ORDER';
  }

  getOriginalInputRoute(entry: OrderPortalEntry, id: string): string {
    if (entry.orderChannel === 'EDI_ORDER_REGISTRATION') {
      return '/admin/edi/orders/' + id;
    }
    return undefined;
  }

  getCreatedEntityRoute(entry: OrderPortalEntry, id: string): EntityRoute {
    const entityName = this.getCreatedEntityName(entry);
    if (entityName === 'ORDER') {
      return {
        link: '/orders/' + id,
        params: {}
      };
    } else if (entityName === 'OFFER') {
      return {
        link: '/offers/' + id,
        params: {}
      };
    } else if (entityName === 'RETURN') {
      return {
        link: '/return-warranty',
        params: {
          search: 'DEFAULT__:__' + id
        }
      };
    } else if (entityName === 'EDI_ORDER_MESSAGE') {
      return {
        link: '/admin/edi/orders/' + id,
        params: {}
      };
    }

    return undefined;
  }

  // This variable contains a list of all EDI channels that are incoming messages to GUNG.
  private ediToGungChannels: { [s: string]: boolean } = {
    EDIXML_B24: true,
    B24_V2: true,
    EDIFACT: true,
    EDI_GUNG_FORMAT: true,
    EDI_EMAIL_SCANNER: true
  };

  private isEdiToGungChannel(orderChannel: string): boolean {
    return this.ediToGungChannels[orderChannel];
  }
}

export interface EntityRoute {
  link: string;
  params: { [key: string]: string };
}
