import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ReplenishmentCustomer } from '../models/replenishment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReplenishmentCustomerService {
  protected replenishmentCustomerSubject: BehaviorSubject<ReplenishmentCustomer[]>;

  constructor(protected http: HttpClient) {}

  private baseUrl = 'json/replenishments/customers';

  public getAllReplenishmentCustomers(): Observable<ReplenishmentCustomer[]> {
    if (!this.replenishmentCustomerSubject) {
      this.updateSubject();
    }

    return this.replenishmentCustomerSubject.asObservable();
  }

  private updateSubject(): void {
    if (!this.replenishmentCustomerSubject) {
      this.replenishmentCustomerSubject = new BehaviorSubject<ReplenishmentCustomer[]>([]);
    }

    this.getAllReplenishmentCustomersInternal().subscribe(rc => {
      this.replenishmentCustomerSubject.next(rc);
    });
  }

  private getAllReplenishmentCustomersInternal(): Observable<ReplenishmentCustomer[]> {
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<ReplenishmentCustomer[]>(this.baseUrl, { headers });
  }

  public getReplenishmentCustomer(id: string): Observable<ReplenishmentCustomer> {
    const url = `${this.baseUrl}/${id}`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<ReplenishmentCustomer>(url, { headers });
  }

  saveReplenishmentCustomer(replenishmentCustomer: ReplenishmentCustomer): Observable<ReplenishmentCustomer> {
    const url = `${this.baseUrl}/${replenishmentCustomer.customerId}`;
    return this.http.put<ReplenishmentCustomer>(url, replenishmentCustomer).pipe(tap(_ => this.updateSubject()));
  }
}
