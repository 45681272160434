import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Order, OrderService, LocationConfigService } from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-open-orders-table-card',
  templateUrl: './open-orders-table-card.component.html',
  styleUrls: ['./open-orders-table-card.component.scss']
})
export class OpenOrdersTableCardComponent implements OnInit, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();

  orders: Order[] = [];
  loaded = false;

  constructor(
    protected orderService: OrderService,
    protected locationConfigService: LocationConfigService,
    protected router: Router,
    public dateUtilService: DateUtilService
  ) {}

  ngOnInit(): void {
    this.orderService
      .getOpenOrders(false, 10)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.orders = res;
        this.loaded = true;
      });
  }

  goToDetail(order: Order): void {
    const url = `/orders/${order.id}`;
    this.router.navigate([url]);
  }

  getStatusTranslationKey(key: string): string {
    if (!!key) {
      return ('ORDER_STATUS_' + key.toUpperCase()).replace(' ', '_');
    }
    return 'UNDEFINED';
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
