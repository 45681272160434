import { Component } from '@angular/core';

@Component({
  selector: 'lib-production-management-menu',
  templateUrl: './production-management-menu.component.html',
  styleUrls: ['./production-management-menu.component.css']
})
export class ProductionManagementMenuComponent {

}
