import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, switchMap } from 'rxjs';
import { DateUtilService } from 'gung-common';
import { AvailabilityHelperService, AuthService, Availability, AvailabilityDisplay, MetadataService, ProductService, GungAnonymousConfigService } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class SjoborgAvailabilityHelperService extends AvailabilityHelperService {
  constructor(
    protected authService: AuthService,
    protected translateService: TranslateService,
    protected productService: ProductService,
    protected dateUtilService: DateUtilService,
    protected gungAnonymousConfigService: GungAnonymousConfigService,
    protected metadataService: MetadataService
  ) {
    super(authService, translateService, dateUtilService, gungAnonymousConfigService, metadataService);
  }

  public getAvailabilityDisplay(availability: Availability): Observable<AvailabilityDisplay> {
    return this.productService.getProduct(availability.productId).pipe(
      switchMap(product => {
        if ('' + product.extra.ar.anskaffningssatt === '2') {
          const res: AvailabilityDisplay = {
            class: {},
            value: this.translateService.instant('SUPPLIER_STOCK')
          };
          return of(res);
        } else if (availability && availability.currentAvailability === 0) {
          const res: AvailabilityDisplay = {
            class: {},
            value: this.translateService.instant('SUPPLIER_STOCK')
          };
          return of(res);
        } else {
          return super.getAvailabilityDisplay(availability);
        }
      })
    );
  }
}
