<div class="dashboard-card card" *ngIf="loaded">
  <div class="card-body dashboard-card-content">
    <div class="title" translate>Upcoming activities</div>
    <div class="table-responsive gung-responsive">
      <table class="table">
        <thead>
          <tr>
            <th translate>STATUS</th>
            <th translate>ID</th>
            <th translate>DATE_CREATED</th>
            <th translate>ACTIVITY_DATE</th>
            <th translate>CUSTOMER</th>
            <th translate>CUSTOMER_RESPONSIBLE</th>
            <th translate>CUSTOMER_PREVIEW</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of activities">
            <td [attr.data-label]="'STATUS' | translate">
              <span
                class="badge rounded-pill"
                [ngClass]="{
                  'badge-danger': item.status === 'Registered',
                  'badge-success': item.status === 'Closed',
                  'badge-warning': item.status === 'Started'
                }"
                translate
                >{{ item.status }}</span
              >
            </td>
            <td [attr.data-label]="'ID' | translate">
              <button type="button" class="btn btn-link" (click)="openViewModal(item)">{{ item.id }}</button>
            </td>
            <td [attr.data-label]="'DATE_CREATED' | translate">
              {{ item.date | date: dateUtilService.dateFormat }}
            </td>
            <td [attr.data-label]="'ACTIVITY_DATE' | translate">
              {{ item.activityDate | date: dateUtilService.dateFormat }}
            </td>
            <td [attr.data-label]="'CUSTOMER' | translate">{{ item.customerId }} - {{ item.customerName }}</td>
            <td [attr.data-label]="'CUSTOMER_RESPONSIBLE' | translate">
              {{ item.customerResponsible }}
            </td>
            <td [attr.data-label]="'CUSTOMER_PREVIEW' | translate" class="text-truncate">
              {{ item.comments[item.comments.length - 1].comment }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="dashboard-card card" *ngIf="!loaded">
  <div class="card-body dashboard-card-content">
    <div class="text-center dashboard-loading-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
