import { Injectable } from '@angular/core';
import { ComparePlanogram, GeneratePdfPlanogram, Planogram } from '../models/planogram';
import { Observable, ReplaySubject, Subject, of, retry, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductConcept } from '../../../components/concept-detail-v2/concept-detail-v2.component';

@Injectable({
  providedIn: 'root'
})
export class PlanogramService {
  baseUrl = 'json/isadora/planogram';

  protected planogramListInitialized = false;
  protected planogramListSubject: Subject<Planogram[]> = new ReplaySubject<Planogram[]>();
  protected planogramListObservable: Observable<Planogram[]> = this.planogramListSubject.asObservable();

  constructor(protected http: HttpClient) {}

  

  savePlanogram(documentBody: Planogram): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}`, documentBody).pipe(tap(() => this.updatePlanogramListSubject()));
  }

  updatePlanogram(documentBody: Planogram): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}`, documentBody).pipe(tap(() => this.updatePlanogramListSubject()));
  }

  getPlanograms(): Observable<Planogram[]> {
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');
    return this.http.get<any>(`${this.baseUrl}`, { headers });
  }

  getPlanogram(id): Observable<Planogram> {
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');
    return this.http.get<any>(`${this.baseUrl}/${id}`, { headers });
  }

  deletePlanogram(id): Observable<Planogram> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`).pipe(tap(() => this.updatePlanogramListSubject()));
  }

  getAllPlanograms(): Observable<Planogram[]> {
    if (!this.planogramListInitialized) {
      this.updatePlanogramListSubject();
    }

    return this.planogramListObservable;
  }

  protected updatePlanogramListSubject() {
    const headers: { [s: string]: string | string[] } = {};
    headers.maxAge = '-1';
    this.http.get<any>(`${this.baseUrl}`, { headers }).subscribe(planograms => {
      this.planogramListSubject.next(planograms);
      this.planogramListInitialized = true;
    }); 
  }

  getModules(): Observable<ProductConcept[]>{
    return this.http.get<ProductConcept[]>(`json/isadora/spaceManagementModules`);
  }

  generatePdf(generatePdfPlanogram: GeneratePdfPlanogram): Observable<any>{
    return this.http.post<any>(`json/planogram/exports/dynamic-pdf/generate`, generatePdfPlanogram);
  }

  comparePlanogram(payload: ComparePlanogram): Observable<any>{
    return this.http.post<any>(`json/isadora/planogram-compare-export`, payload);
  }

  exportPlanogram(payload): Observable<any>{
    return this.http.post<any>(`json/isadora/planogram-assortment-export`, payload);
  }

  exportPlanogramWithPrices(payload): Observable<any>{
    return this.http.post<any>(`json/isadora/planogram-assortment-with-price-export`, payload);
  }

  exportPlanogramNewModules(payload): Observable<any>{
    return this.http.post<any>(`json/isadora/planogram-assortment-only-new-modules-export`, payload);
  }
}
