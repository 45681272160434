import { EventEmitter, Injectable, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionButtonConfigService, ActionButton, DateUtilService } from 'gung-common';
import { Observable, of, tap, first, switchMap } from 'rxjs';
import { SupplierPurchaseOrder, SupplierTableColumns, ColumnRowTableType } from '../../models';
import { User } from '../../state/auth/types';
import { gungAddRemoveSpinner, gungComparatorHelper } from '../../utils/gung-utils';
import { AuthService } from '../auth/auth.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { getValuesDynamic, setValueDynamic } from '../supplier-dynamic-columns/supplier-dynamic-columns.service';
import { SupplierService, GungPatchValue } from '../suppliers/supplier.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierPoActionConfigService implements ActionButtonConfigService<SupplierPurchaseOrder> {

  actions: ActionButton<SupplierPurchaseOrder>[] = [];
  currentUser: User;

  @Input()
  currentStatus: number;

  @Input()
  nextStatusName: string;

  @Input()
  nextStatus: number;

  @Input()
  rejectStatusName: string;

  @Input()
  rejectStatus: number;

  @Input()
  tableColumn: SupplierTableColumns[];

  @Input()
  detailsColumn: SupplierTableColumns[];

  public actionMoveNextStatusPurchaseLine: ActionButton<SupplierPurchaseOrder> = {
    title: 'NEXT_STATUS',
    icon: 'fas fa-check mr-1',
    action: (item, $event) => {
      this.modalService.openConfirmYesNoModal(
        undefined,
        // The message can contain which order, row and article + which status it was moved to.
        this.translationService.instant('SUPPLIER_PORTAL_MOVE_ORDER_ROW_NEXT_STATUS', { orderId: item.orderId, rowNumber: item.rowNumber, nextStatus: this.nextStatusName }) + '?',
        // 'SUPPLIER_PORTAL_MOVE_ORDER_ROW_NEXT_STATUS':'Move the row {{rowNumber}} of order {{orderId}} to status {{nextStatus}}?'
        { size: 'sm' }
      )
      .then(result => {
        if (result) {
          console.log(item);
          if(item.status === 40){
            this.setValuesToShipStep(item).then(itemChanged =>{
              console.log('save', itemChanged);
              if(itemChanged){
                this.confirmPurchaseLine(itemChanged, $event || { target: null });
              }else{
                this.confirmPurchaseLine(item, $event || { target: null });
              }
            }) 
          }else{
            this.confirmPurchaseLine(item, $event || { target: null });
          }        
        }
      });
    }
  };

  public actionMoveRejectStatusPurchaseLine: ActionButton<SupplierPurchaseOrder> = {
    title: 'REJECT_STATUS',
    icon: 'fas fa-check mr-1',
    action: (item, $event) => {
      this.modalService.openConfirmYesNoModal(
        undefined,
        this.translationService.instant('SUPPLIER_PORTAL_MOVE_ORDER_ROW_NEXT_STATUS', { orderId: item.orderId, rowNumber: item.rowNumber, nextStatus: this.rejectStatusName }) + '?',
        { size: 'sm' }
      )
      .then(result => {
        if (result) {
          this.rejectPurchaseLine(item, $event || { target: null });
        }
      });
    }
  };

  public actionSplitPurchaseLine: ActionButton<SupplierPurchaseOrder> = {
    title: 'SPLIT',
    icon: 'fa-solid fa-split mr-1',
    action: (item, $event) => { this.splitPurchaseLine(item, $event || { target: null }) }
  };

  public actionHistoryPurchaseLine: ActionButton<SupplierPurchaseOrder> = {
    title: 'HISTORY',
    icon: 'fa-solid fa-clock-rotate-left mr-1',
    action: (item, $event) => { this.historyPurchaseLine(item, $event || { target: null }) }
  };

  constructor(
    protected supplierService: SupplierService,
    protected modalService: GungModalService,
    protected authService: AuthService,
    protected dateUtilService: DateUtilService,
    protected translationService: TranslateService
  ) { }

  getButtons(item: SupplierPurchaseOrder, eventEmitter?: EventEmitter<SupplierPurchaseOrder>): Observable<ActionButton<SupplierPurchaseOrder>[]> {
    return this.authService.getCurrentUser().pipe(
      first(),
      switchMap(currentUser => {
        this.currentUser = currentUser;
        const roles = currentUser.roles;
        if (!roles.includes('BUYER')) {
          this.actions = this.actions.filter(a => a.title !== 'HISTORY');
        }
        return of(this.actions);
      })
    );
  }

  setActions(actionsList: string | string[] | (string | string[])[]) {
    const actions = this.getActions(actionsList);
    if (Array.isArray(actions)) {
      this.actions = actions;
    } else {
      this.actions = [actions];
    }
  }

  getActions(action: string | string[] | (string | string[])[]): ActionButton<SupplierPurchaseOrder>[] | ActionButton<SupplierPurchaseOrder> {
    if (Array.isArray(action)) {
      const actions = [];
      for (const a of action) {
        actions.push(this.getActions(a));
      }
      return actions;
    } else {
      switch (action) {
        case 'btnMoveNextStatus':
          this.actionMoveNextStatusPurchaseLine.title = this.nextStatusName;
          return this.actionMoveNextStatusPurchaseLine;
        case 'btnMoveRejectStatus':
          this.actionMoveRejectStatusPurchaseLine.title = this.rejectStatusName;
          return this.actionMoveRejectStatusPurchaseLine;
        case 'btnSplit':
          return this.actionSplitPurchaseLine;
        case 'btnHistory':
          return this.actionHistoryPurchaseLine;
        default:
          break;
      }
    }
  }

  getNextStatusName() {
    return this.nextStatusName;
  }

  /**
   * ACTIONS
   */
  confirmPurchaseLine(item: SupplierPurchaseOrder, { target }) {
    gungAddRemoveSpinner(target);
    this.confirmPurchaseLineRequest(item).pipe(
      tap(result => {
        if (result?.id) {
          // force update current status
          this.supplierService.getPurchaseOrder(this.currentStatus.toString(), result.supplierId, true).pipe(first()).subscribe( (_) => {})
        }
      })
    ).pipe(first()).subscribe({
      next: (result) => {
        if (result?.id) {

        }
        gungAddRemoveSpinner(target);
      },
      error: (e) => {
        gungAddRemoveSpinner(target);
      },
      // complete: () => console.info('complete')
    });
  }

  rejectPurchaseLine(item: SupplierPurchaseOrder, { target }) {
    gungAddRemoveSpinner(target);
    this.modalService.openInputSaveModal('REASON', 'REJECT', '', true).then(
      (result) => {
        if (result?.value) {
          item.comments.push({
            documents: null,
            comment: result.value,
            date: (new Date()).toISOString(),
            userId: this.currentUser.username,
          });
          this.confirmPurchaseLineRequest(item, true).pipe(
            tap(result => {
              if (result?.id) {
                // force update current status
                this.supplierService.getPurchaseOrder(this.currentStatus.toString(), result.supplierId, true).pipe(first()).subscribe( (_) => {})
              }
            })
          ).pipe(first()).subscribe({
            next: (result) => {
              if (result?.id) {

              }
              gungAddRemoveSpinner(target);
            },
            error: (e) => {
              gungAddRemoveSpinner(target);
            },
            // complete: () => console.info('complete')
          });
        } else {
          gungAddRemoveSpinner(target);
        }
      }
    )
  }

  confirmPurchaseLineRequest(item: SupplierPurchaseOrder, rejectAction = false): Observable<SupplierPurchaseOrder> {
    let previousRequest: Observable<any> = of(item);
    let patchValues: GungPatchValue[] = [];
    if (!rejectAction) {
      // Check all required
      for (const column of this.tableColumn.filter(c => c.extra?.required)) {
        item.extra.errors = [];
        if (Array.isArray(column.path)) {
          for (const path of column.path) {
            if (!this.getValues(item, path)) {
              item.extra.errors.push(path);
              return of(null);
            }
          }
        } else {
          if (!this.getValues(item, column.path)) {
            item.extra.errors.push(column.path);
            return of(null);
          }
          // Should remove this - ONLY FOR INPUTDATE
          if (column.type === ColumnRowTableType.INPUTDATE && this.getValues(item, column.path) === '0001-01-01') {
            item.extra.errors.push(column.path);
            return of(null);
          }
        }
      }
      delete item.extra.errors;
      // Check dynamic conditions
      for (const column of this.tableColumn.filter(c => c.extra?.conditions?.length > 0)) {
        for (const condition of column.extra.conditions) {
          const path1Value = this.getValues(item, condition.path1);
          const path2Value = condition.value2 || this.getValues(item, condition.path2);
          const compare = gungComparatorHelper(path1Value, path2Value, 1);
          switch (condition.comparator) {
            case '===':
              if (compare === 0) {
                previousRequest = condition.result(item);
              }
              break;
            case '!==':
              if (compare !== 0) {
                previousRequest = condition.result(item);
              }
              break;
            case '>==':
              if (compare === 1 || compare === 0) {
                previousRequest = condition.result(item);
              }
              break;
            case '<==':
              if (compare === -1 || compare === 0) {
                previousRequest = condition.result(item);
              }
              break;
            case '>':
              if (compare === 1) {
                previousRequest = condition.result(item);
              }
              break;
            case '<':
              if (compare === -1) {
                previousRequest = condition.result(item);
              }
              break;
            default:
              break;
          }
          if (previousRequest === null || previousRequest === undefined) {
            console.log('Error in the conditions', item);
            return of(null)
          }
        }
      }
      // Copy all copyTo
      for (const column of this.tableColumn.filter(c => c.extra?.copyTo)) {
        if (Array.isArray(column.path)) {
          for (let index = 0; index < column.path.length; index++) {
            const path = column.path[index];
            // NOTE: if column.path is array and column.extra?.copyTo is not array
            const pathCopyTo = (Array.isArray(column.extra?.copyTo) && (column.extra?.copyTo[index] || column.extra?.copyTo[0])) || column.extra?.copyTo as string;
            this.setValue(item, pathCopyTo, this.getValues(item, path));
          }
        } else {
          if (this.getValues(item, column.path)) {
            if (Array.isArray(column.extra?.copyTo)) {
              for (const pathCopyTo of column.extra.copyTo) {
                this.setValue(item, pathCopyTo, this.getValues(item, column.path));
              }
            } else {
              const pathCopyTo = column.extra?.copyTo as string;
              this.setValue(item, pathCopyTo, this.getValues(item, column.path));
            }
          }
        }
      }
      // Delete all fields on delete prop
      for (const column of this.tableColumn.filter(c => c.extra?.delete)) {
        this.deleteValue(item, column.path);
        // if (Array.isArray(column.path)) {
        //   for (let index = 0; index < column.path.length; index++) {
        //     const path = column.path[index];
        //     this.setValue(item, path, undefined, true);
        //   }
        // } else {
        //   if (this.getValues(item, column.path)) {
        //     this.setValue(item, column.path, undefined, true);
        //   }
        // }
      }
      // Patch Value for the request
      const patchValueList = this.tableColumn.filter(c => c.extra?.patchValue);
      for (const column of patchValueList) {
        if (column.extra.copyTo) {
          if (Array.isArray(column.extra.copyTo)) {
            for (let index = 0; index < column.extra.copyTo.length; index++) {
              const copyTo = column.extra.copyTo[index];
              patchValues.push({
                op: column.extra.patchValue,
                path: copyTo,
                value: this.getValues(item, copyTo)
              });
            }
          } else {
            patchValues.push({
              op: column.extra.patchValue,
              path: column.extra.copyTo,
              value: this.getValues(item, column.extra.copyTo)
            });
          }

        }
      }
    } else {
      // Reject comment
      previousRequest = this.supplierService.postPurchaseOrderComment(item.id, item.comments[item.comments.length-1]);
    }
    // Start creating the request change
    const request = this.createRequest(item, rejectAction, patchValues);
    if (request) {
      return previousRequest.pipe(
        switchMap(po => {
          if (po?.id) {
            return this.supplierService.patchPurchaseOrder(po.id, request)
          } else {
            console.error('ERROR when patchPurchaseOrder - missing ID');
          }
        })
      );
    }
    return of(null)
  }

  deleteValue(item: SupplierPurchaseOrder, path: string | string[] | (string | string[])[]) {
    if (Array.isArray(path)) {
      for (let index = 0; index < path.length; index++) {
        const pathChild = path[index];
        this.deleteValue(item, pathChild);
        // this.setValue(item, path, undefined, true);
      }
    } else {
      if (this.getValues(item, path)) {
        this.setValue(item, path, undefined, true);
      }
    }
  }

  createRequest(itemInput: SupplierPurchaseOrder, rejectAction: boolean, patchValues: GungPatchValue[]): GungPatchValue[] {
    const item = itemInput;
    const newStatus = rejectAction ? this.rejectStatus : this.nextStatus;
    if (!newStatus) {
      console.error('createRequest() missing new status');
      return;
    }
    if (patchValues) {
      // Dynamic patchValues from supplier-dynamic-columns
      patchValues.unshift({
        op: 'replace',
        path: 'status',
        value: Number( newStatus)
      });
    }
    return patchValues;
  }

  public splitPurchaseLine(item: SupplierPurchaseOrder, { target }) {
    gungAddRemoveSpinner(target);
    this.modalService.openSupplierPurchaseOrderSplitModal(item).then(
      (value: SupplierPurchaseOrder[]) => {
        const oldRowWithNewQuantity = value[0];
        const newRow = value[1];
        this.supplierService.postPurchaseOrderSplit(item.id, newRow.extra.gungPurchaseLine.quantity).pipe(first()).subscribe(
          (result: SupplierPurchaseOrder) => {
            if (result?.id) {
            }
          }
        )
      }
    ).finally(() => gungAddRemoveSpinner(target));
  }

  public downloadDocuments(item: SupplierPurchaseOrder, { target }) {
    gungAddRemoveSpinner(target);
    /*
    const pdmId = item.pdmId;
    const pdmIdSplitted = pdmId.split(';');
    pdmIdSplitted.forEach(pdmSegment => {

      this.http
        .get('download/jeeves/brigo/pdm-document-from-jsb?pdmId=' + pdmSegment, {
          responseType: 'blob'
        })
        .subscribe(response => {
          let blob: any = new Blob([response], { type: response.type });
          let targetName = pdmSegment;
          const url = window.URL.createObjectURL(blob);
          saveAs(blob, targetName);
        });
    });
    */
    gungAddRemoveSpinner(target);
  }

  public historyPurchaseLine(item: SupplierPurchaseOrder, { target }) {
    gungAddRemoveSpinner(target);
    if(Array.isArray(item)){
      const ids= item.map(i=>i.id)
      this.supplierService.getPurchaseOrderHistoryMap(ids).pipe(first()).subscribe(history => {
        this.modalService.openSupplierPurchaseOrderHistoryModal(history,true).finally(() => gungAddRemoveSpinner(target));
      });

    }else{
      this.supplierService.getPurchaseOrderHistory(item.id).pipe(first()).subscribe(history => {
        this.modalService.openSupplierPurchaseOrderHistoryModal(history).finally(() => gungAddRemoveSpinner(target));
      });
    }
  }

  public detailsPurchaseLine(item: SupplierPurchaseOrder, { target }) {
    gungAddRemoveSpinner(target);
    this.modalService.openSupplierPoDetailsModal(item, this.detailsColumn).then(
      (data) => {
        const patchValues: GungPatchValue[] = this.saveChanges(item, this.detailsColumn);
        if (patchValues?.length > 0) {
          this.supplierService.patchPurchaseOrder(item.id, patchValues).pipe(first()).subscribe((result) => item = result);
        }
      }
    ).finally(() => gungAddRemoveSpinner(target));
  }

  setValuesToShipStep(item): Promise<any>{
    return this.modalService.setValuesToShipStepModal(item);
  }

  saveChanges(item: SupplierPurchaseOrder, detailsColumn: SupplierTableColumns[]): GungPatchValue[] {
    const patchValues: GungPatchValue[] = [];
    for (const colum of detailsColumn) {
      if ([
        ColumnRowTableType.INPUTDATE,
        ColumnRowTableType.INPUTNUMBER,
        ColumnRowTableType.INPUTTEXT,
        ColumnRowTableType.INPUTTEXTAREA,
        ColumnRowTableType.INPUTCHECKBOX
      ].includes(colum.type)) {
        patchValues.push({
          op: 'replace',
          path: this.getElementFromArray(colum.path),
          value: this.getValues(item, colum.path)
        })
      }
    }
    return patchValues;
  }

  getElementFromArray(ele: any | any[]): any {
    if (Array.isArray(ele)) {
      if (ele.length > 0) {
        return this.getElementFromArray(ele[0]);
      }
      return null;
    }
    return ele;
  }

  getValues(row: SupplierPurchaseOrder, path: string | string[] | (string | string[])[]) {
    return getValuesDynamic(row, path);
    // if (Array.isArray(path)) {
    //   return path.map(p => this.getValues(row, p)).join(', ');
    // }
    // return gungGetPropertyOfObject(path, row);
  }

  setValue(row: SupplierPurchaseOrder, path: string, value: any, forceDelete?: boolean) {
    return setValueDynamic(row, path, value, forceDelete);
    // const result = gungSetPropertyOfObject(row, path, value, forceDelete);
    // if (!result) {
    //   console.log('NOT SAVED - result', result);
    // }
  }
}
