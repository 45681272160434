import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomerContact, Customer, GungBase } from 'gung-standard';
import { CartDiscountMatrixComponent } from '../../components/cart-discount-matrix/cart-discount-matrix.component';
import { JeevesAddContactModalComponent } from '../../components/jeeves-add-contact-modal/jeeves-add-contact-modal.component';
import { JeevesCustomerDeliveryLocationModalComponent } from '../../components/jeeves-customer-delivery-location-modal/jeeves-customer-delivery-location-modal.component';
import { JeevesEditContactModalComponent } from '../../components/jeeves-edit-contact-modal/jeeves-edit-contact-modal.component';
import { JeevesCheckoutAddRowModalComponent } from '../../components/jeeves-checkout-add-row-modal/jeeves-checkout-add-row-modal.component';
import { Claim } from '../../models/claim';
import { JeevesCreateClaimModalComponent } from '../../components/jeeves-create-claim-modal/jeeves-create-claim-modal.component';

@Injectable({
  providedIn: 'root'
})
export class JeevesModalService {
  public modalRef: NgbModalRef;
  public multiModalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();

  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    size: 'lg',
    keyboard: true
  };

  constructor(protected modalService: NgbModal) {}

  openAddContactModal(contact: Customer) {
    this.modalRef = this.modalService.open(JeevesAddContactModalComponent, this.modalOptions);
    this.modalRef.componentInstance.contact = contact;
    this.modalRef.result.then(
      result => {},
      reason => {}
    );
  }

  openAddContactModalAsPromise(customer: Customer): Promise<any> {
    this.modalRef = this.modalService.open(JeevesAddContactModalComponent, this.modalOptions);
    this.modalRef.componentInstance.contact = customer;
    return this.modalRef.result;
  }

  openEditContactModal(customerContact: CustomerContact) {
    this.modalRef = this.modalService.open(JeevesEditContactModalComponent, this.modalOptions);
    this.modalRef.componentInstance.customerContact = customerContact;
    this.modalRef.result.then(
      result => {},
      reason => {}
    );
  }

  openCustomerDeliveryLocationModal(deliveryLocation: GungBase) {
    this.modalRef = this.modalService.open(JeevesCustomerDeliveryLocationModalComponent, this.modalOptions);
    this.modalRef.componentInstance.deliveryLocation = deliveryLocation;
    this.modalRef.result.then(
      result => {},
      reason => {}
    );
  }

  openCartDiscountModal(productId: string) {
    const id = CartDiscountMatrixComponent.name;

    const ref = this.modalService.open(CartDiscountMatrixComponent, {
      keyboard: true,
      size: 'lg'
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.productId = productId;
    ref.result.then(
      result => {},
      reason => {}
    );

    // this.modalRef.set(id, ref);
  }

  openAddRowCheckoutModal() {
    const id = JeevesCheckoutAddRowModalComponent.name;
    const ref = this.modalService.open(JeevesCheckoutAddRowModalComponent, { size: 'lg', keyboard: true, backdrop: 'static' });
    ref.componentInstance.delegate = this;
    this.multiModalRef.set(id, ref);
   
    return ref.result;
  }

  openClaimModal(customer: Customer, claim?: Claim, modalOption?: NgbModalOptions): Promise<any> {
    const id = JeevesCreateClaimModalComponent.name;
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      size: 'lg',
      // windowClass: 'modal-lg-custom',
      ...modalOption
    };
    const modalRef = this.modalService.open(JeevesCreateClaimModalComponent, option);
    modalRef.componentInstance.delegate = this;
    modalRef.componentInstance.customer = customer;
    modalRef.componentInstance.claim = claim;
    this.multiModalRef.set(id, modalRef);
    return modalRef.result;
  }

}
