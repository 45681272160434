<div *ngIf="summaryTable">
  <div class="text-center alert-warning p-2 mb-2">
    <span translate>NOTE</span>!<br />
    <span translate>THIS_WILL_CREATE</span> {{summaryTable.length}} <span translate>ORDERS</span>
  </div>
  <div class="row" *ngFor="let item of summaryTable">
    <div class="col-12 col-lg-2">
      <div class="date bg-primary">{{item.year}}-{{item.month}}-{{item.day}}</div>
    </div>
    <div class="col-12 col-lg-10">
      <div class="table-responsive overflow-hidden " *ngFor="let cart of item.carts">
        <table class="table table-sm table-borderless table-striped">
          <thead>
            <tr>
              <th class="td-id" style="width: 20%"><span translate>PRODUCT_ID</span></th>
              <th class="td-name" style="width: 25%"><span translate>NAME</span></th>
              <th class="td-qty" style="width: 15%"><span translate>QTY</span></th>
              <th class="td-price" style="width: 25%"><span translate>PRICE</span></th>
              <th class="td-total" style="width:  20%"><span translate>TOTAL</span></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of cart.data.rows" class="dragable-item">
              <td class="td-id">
                {{ row.productId }}
              </td>
              <td class="td-name">
                <span *ngIf="productData[row.productId]">{{ productData[row.productId].product.name }}</span>
              </td>
              <td class="td-qty">
                {{ row.qty }}
              </td>
              <td class="td-price">
                <lib-price [price]="row.extra.price" [currentQty]="row.qty" [priceLevelsDisplayType]="'COMPACT_LIST'" type="STAFFED"></lib-price>
              </td>
              <td class="td-total">
                <lib-price [price]="row.extra.totalPrice.value" [currency]="row.extra.totalPrice.currencyCode"></lib-price>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td translate>SUMMARY</td>
              <td></td>
              <td>{{cart.extra.totalQty}}</td>
              <td></td>
              <td>
                <lib-price [price]="cart.extra.totalCart" [currency]="cart.extra.totalCurrency"></lib-price>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>