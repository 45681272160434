import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MetaDataSelectInputComponent } from '../../../../components/meta-data-select-input/meta-data-select-input.component';

@Component({
  selector: 'lib-production-management-meta-data-select-input',
  templateUrl: './production-management-meta-data-select-input.component.html',
  styleUrls: ['./production-management-meta-data-select-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductionManagementMetaDataSelectInputComponent),
      multi: true
    }
  ]
})
export class ProductionManagementMetaDataSelectInputComponent extends MetaDataSelectInputComponent {

}
