<div class="container">
  <div class="row" *ngIf="!inputParams">
    <div class="col-12 text-center">
      <h2 class="mt-5" translate>STOCK_COUNTING</h2>
    </div>
    <div class="col-auto position-absolute">
      <a type="button" class="btn btn-info" routerLink=".."
        ><i class="fa-solid fa-arrow-left-long mr-1"></i><span translate>GO_BACK</span></a
      >
    </div>
  </div>
  <div class="row" *ngIf="form">
    <div class="col-12 col-md-3">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <lib-production-management-product-select-dropdown
                #productEle
                gungTranslate="ITEM_NO"
                [formatterFunction]="formatterFunction"
                [formatterInputFunction]="formatterInputFunction"
                (productSelected)="selectProduct($event)"
                [disabled]="productSearchDisabled"
                [preselectedProductId]="this.form.get('itemNo').value"
              ></lib-production-management-product-select-dropdown>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="location" translate>STOCK_ID</label>
              <lib-production-management-meta-data-select-input
                suppressLabel="true"
                formControlName="location"
                label="TO_LOCATION"
                table="StockPoints"
                metaId="code"
                metaDisplay="name"
                [hideEmptyOption]="false"
              ></lib-production-management-meta-data-select-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="stockLocation" translate>STOCK_LOCATION</label>
              <lib-gung-select-option-input
                suppressLabel="true"
                formControlName="stockLocation"
                [optionsList]="stockLocationList"
              ></lib-gung-select-option-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <lib-gung-text-input gungTranslate="NEW_QTY" formControlName="newQty"></lib-gung-text-input>
            </div>
          </div>
        </div>

        <button *ngIf="!inputParams" type="button" class="btn btn-primary mb-2 w-100" translate [disabled]="disabledSave" (click)="save($event)">
          SAVE
        </button>
        <br />
        <button *ngIf="!inputParams" type="button" class="btn btn-secondary w-100" translate (click)="newCount(productEle)">NEW_COUNT</button>
      </form>
    </div>
    <div class="col-12 col-md-9 mt-4 mt-md-0">
      <div class="row">
        <div class="col-12">
          <lib-gung-text-input
            gungTranslate="NAME"
            renderStatic="true"
            [ngModel]="product?.name ?? ''"
          ></lib-gung-text-input>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-auto">
          <lib-gung-text-input
            gungTranslate="INVENTORY"
            renderStatic="true"
            [ngModel]="productStock?.inventory ?? ''"
          ></lib-gung-text-input>
        </div>
        <div class="col">
          <lib-gung-text-input
            gungTranslate="AVAILABLE"
            renderStatic="true"
            [ngModel]="productStock?.available ?? ''"
          ></lib-gung-text-input>
        </div>
      </div>
    </div>
  </div>
</div>
