<div class="row pb-2 d-none d-md-flex">
  <div class="col-4 col-lg-1"></div>
  <div class="d-none d-xl-block col-16 col-md-9 col-lg-6 col-xl-4"></div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-2">
    <b translate>QUANTITY</b>
  </div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-2">
    <b translate>PRICE</b>
  </div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-1">
    <b translate>DISCOUNT_PERCENT</b>
  </div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-1">
    <b translate>TOTAL</b>
  </div>
  <div class="col-1"></div>
</div>
<span *ngFor="let row of mappedData">
  <div class="row pb-2 align-items-center">
    <div
      class="col-3 col-lg-1 d-flex align-items-center navigate-to-product-details"
      [routerLink]="['/product', row.product.id]"
    >
      <img
        class="img-fluid"
        src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/64x64/filters:fill(white):background_color(white):format(jpeg)/{{
          row.product.extra.images[0].s3Uri
        }}"
        alt=""
      />
    </div>

    <div
      class="col-12 col-lg-9 col-lg-6 col-xl-4 text-truncate navigate-to-product-details"
      [routerLink]="['/product', row.product.id]"
    >
      <div>
        <b>{{ row.product.id }}</b> - {{ row.product.name }}
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-2 col-request-quantity">
      <div class="row pt-1">
        <div class="col-4 d-flex d-xl-none">
          <b translate>QUANTITY</b>
        </div>
        <div class="col-8 col-xl-12">
          <sjoborg-buy-btn
            [id]="row.product.id"
            [checkoutCart]="true"
            [minimumOrderQuantity]="getPackageSize(row.product)"
            [stepAmount]="getPackageSize(row.product)"
          >
          </sjoborg-buy-btn>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-2">
      <div class="row pt-1">
        <div class="col-4 d-flex d-xl-none">
          <b translate>PRICE</b>
        </div>
        <div class="col-8 col-xl-12 d-flex">
          <input
            type="number"
            class="form-control"
            [value]="parseFloatFixed(row.overridePrice, 2)"
            [placeholder]="row.originalPrice | number: '1.2-2'"
            (blur)="updateOverridePrice(row, parseFloatFixed($event.target.value, 2))"
          />
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-1">
      <div class="row pt-1">
        <div class="col-4 d-flex d-xl-none">
          <b translate>DISCOUNT_PERCENT</b>
        </div>
        <div class="col-8 col-xl-12 d-flex">
          <input
            type="number"
            class="form-control discount-control"
            [value]="row.discountPercent || row.cartRowPriceElement.cartRowDiscountPercent || 0"
            (blur)="updateDiscountPercentage(row, $event.target.value)"
            placeholder="%"
          />
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-1">
      <div class="row pt-1">
        <div class="col-4 d-flex d-xl-none">
          <b translate>TOTAL</b>
        </div>
        <div class="col-8 col-xl-12 d-flex">
          <lib-price [price]="row.cartRowPriceElement" type="cartRowTotalPriceInclRowDiscount"></lib-price>
        </div>
      </div>
    </div>

    <div class="col-2 col-md-1 order-first order-xl-0 d-flex align-items-center col-delete-button">
      <button class="btn btn-light delete-button" (click)="removeRow(row)">
        <i class="fal fa-minus-circle"></i>
      </button>
    </div>
  </div>
  <div class="row hr"></div>
</span>

<div class="pt-3">
  <button class="btn btn-primary btn-circle" type="button" (click)="addNewOrderLine()">
    <i class="fas fa-plus"></i>
  </button>
</div>
