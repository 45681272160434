import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-invoice-statistics-chart-card',
  templateUrl: './invoice-statistics-chart-card.component.html',
  styleUrls: ['./invoice-statistics-chart-card.component.css']
})
export class InvoiceStatisticsChartCardComponent implements OnInit {
  @Input()
  preConditions: any;

  constructor() {}

  ngOnInit() {}
}
