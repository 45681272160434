import { Injectable } from '@angular/core';
import { AbstractSelectionService } from 'gung-list';
import { SupplierPurchaseOrder } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class SupplierPoSelectionService extends AbstractSelectionService<SupplierPurchaseOrder> {
  constructor() {
    super();
  }

  getItemId(item: SupplierPurchaseOrder): string {
    return item.id;
  }
}
