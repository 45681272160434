import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  ProductRequirements,
  RequirementsPlanningOrder,
  RequirementsPlanningOrderType,
  RequirementsPlanningProductData
} from '../../models/product-requirements';
import { ListItemRendererComponent } from 'gung-list';
import { GungNotificationService } from 'gung-common';
import { ProductionOrderService } from '../../services/production-order.service';
import { first } from 'rxjs';
import { ProductionManagementSuggestedOrderToRequestBodyMapperService } from '../../services/production-management-suggested-order-to-request-body-mapper.service';
import { OrderSubmitService } from '../../../../services/order-submit.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductionManagementCreatePurchaseOrderModalComponent } from '../production-management-create-purchase-order-modal/production-management-create-purchase-order-modal.component';

@Component({
  selector: 'lib-production-management-mrp',
  templateUrl: './production-management-mrp.component.html',
  styleUrls: ['./production-management-mrp.component.scss']
})
export class ProductionManagementMrpComponent
  extends ListItemRendererComponent<ProductRequirements[]>
  implements OnInit, AfterViewInit
{
  isLoading = true;
  show: { [s: string]: boolean } = {};
  createdOrders: { [s: string]: boolean } = {};

  constructor(
    protected gungNotificationService: GungNotificationService,
    protected productionOrderService: ProductionOrderService,
    protected suggestedOrderToRequestBodyMapperService: ProductionManagementSuggestedOrderToRequestBodyMapperService,
    protected orderSubmitService: OrderSubmitService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = false;
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  showDetails(productId: string) {
    if (this.show[productId]) {
      this.show[productId] = false;
    } else {
      this.show[productId] = true;
    }
  }

  createSuggestion(
    order: RequirementsPlanningOrder,
    orderIndex: number,
    productData: RequirementsPlanningProductData,
    suggestedOrders?: RequirementsPlanningOrder[]
  ): void {
    const mappedOrder = this.suggestedOrderToRequestBodyMapperService.map([order], productData);
    if (order.orderType === RequirementsPlanningOrderType.PRODUCTION_ORDER) {
      this.productionOrderService
        .create(mappedOrder)
        .pipe(first())
        .subscribe(createdOrder => {
          this.createdOrders[this.getSuggestedOrderId(order, orderIndex)] = true;
          this.gungNotificationService.notify(
            'Created: ' + createdOrder.id,
            'Created a new production order with id: ' + createdOrder.id,
            'success'
          );
        });
    }else{
      const modalReference = this.modalService.open(ProductionManagementCreatePurchaseOrderModalComponent, {
        backdrop: 'static',
        size: 'lg',
        keyboard: true
      });
  
      modalReference.componentInstance.order = order;
      modalReference.componentInstance.orderIndex = orderIndex;
      modalReference.componentInstance.productData = productData;
      modalReference.componentInstance.suggestedOrders = suggestedOrders;
      modalReference.result.then(
        () => {
          
        },
        error => {}
      )
    }
    
  }

  public getSuggestedOrderId(order: RequirementsPlanningOrder, orderIndex: number): string {
    return order.productId + '_' + orderIndex;
  }

  protected readonly RequirementsPlanningOrderType = RequirementsPlanningOrderType;
}
