import { Component, Input, OnInit } from '@angular/core';
import { first, forkJoin } from 'rxjs';
import { DashboardKpiService } from '../../../services/dashboard-kpi.service';
import { SalesSummaryEntity, Timespan } from '../../../types';

@Component({
  selector: 'lib-last-vs-this-month-offers-amount-summary-card',
  templateUrl: './last-vs-this-month-offers-amount-summary-card.component.html',
  styleUrls: ['./last-vs-this-month-offers-amount-summary-card.component.css']
})
export class LastVsThisMonthOffersAmountSummaryCardComponent implements OnInit {

  numberOffersThisMonth = 0;
  numberOffersLastMonth = 0;
  loaded = false;

  @Input()
  preConditions: any;

  constructor(protected dashboardKpiService: DashboardKpiService) {}

  ngOnInit() {
    const thisMonthSpan = this.dashboardKpiService.getDateFromTimespan(Timespan.Month);
    const lastMonthSpan = this.dashboardKpiService.getDateFromTimespan(Timespan.LastMonth);

    forkJoin({
      offersSummaryGroupsThisMoth: this.dashboardKpiService
        .getOfferSummary(SalesSummaryEntity.MAIN_ENTITY, thisMonthSpan.fromDate, null, this.preConditions)
        .pipe(first()),
      offersSummaryGroupsLastMoth: this.dashboardKpiService
        .getOfferSummary(SalesSummaryEntity.MAIN_ENTITY, lastMonthSpan.fromDate, lastMonthSpan.toDate, this.preConditions)
        .pipe(first())
    }).subscribe(({ offersSummaryGroupsThisMoth, offersSummaryGroupsLastMoth}) => {
      this.numberOffersThisMonth = offersSummaryGroupsThisMoth.length;
      this.numberOffersLastMonth = offersSummaryGroupsLastMoth.length;
      this.loaded = true;
    });
  }
}
