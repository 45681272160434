import { Component, OnInit } from '@angular/core';
import { EdiIncomingDespatchAdvicesListConfigService } from '../../../services/edi-incoming-despatch-advices-list-config/edi-incoming-despatch-advices-list-config.service';

@Component({
  selector: 'lib-edi-incoming-despatch-advices',
  template: `<h3 class="d-block page-title text-center" translate>EDI - Incoming Despatch advice</h3>
    <lib-filter-list [configService]="ediIncomingDespatchAdvicesListConfigService"></lib-filter-list>`,
  styleUrls: ['./edi-incoming-despatch-advices.component.css']
})
export class EdiIncomingDespatchAdvicesComponent implements OnInit {
  constructor(public ediIncomingDespatchAdvicesListConfigService: EdiIncomingDespatchAdvicesListConfigService) {}

  ngOnInit(): void {}
}
