import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { gungGetPropertyOfObject } from 'gung-common';
import { Subject, takeUntil } from 'rxjs';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';

@Injectable({providedIn: 'root'})
export class GungTitleGuard {
  protected unsubscribe: Subject<void> = new Subject();
  gungTitleVar: {[s: string]: any} = {};

  constructor(private titleService: Title, private router: Router, private selectedCustomerService: SelectedCustomerService, private translateService: TranslateService) {
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selectedCustomer => {
        this.gungTitleVar['selectedCustomer'] = selectedCustomer;
        this.setGungTitle(this.gungTitleVar['gungTitle']);
      });
  }

  setGungTitle(gungTitle: string) {
    if (!gungTitle) { return; }
    let title = gungTitle || 'Otto Olsen B2B';
    const tags = title.match(/<GUNG>.*?<\/GUNG>/g) || [];

    // Replace dynamic tags
    tags.forEach((tag: string) => {
      const dynamicKey = tag.slice('<GUNG>'.length, -'</GUNG>'.length); // Remove tags
      const dynamicValue = gungGetPropertyOfObject(dynamicKey, this.gungTitleVar);
      if (dynamicValue !== undefined) {
        title = title.replace(new RegExp(tag, 'g'), dynamicValue);
      }
    });
    // Set tile
    this.titleService.setTitle(this.translateService.instant(title));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const gungTitle = route?.data?.gungTitle;
    this.gungTitleVar['gungTitle'] = gungTitle;
    this.setGungTitle(gungTitle);
    return true;
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): boolean {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    const title = currentRoute?.data?.gungTitleOut;
    if (title) {
      this.titleService.setTitle(this.translateService.instant(title));
    }
    return true;
  }
}
