import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GungBase } from '../models';


export interface PimTemplateColumn {
  additionalTargetPaths: string[],
  blankType: any,
  columnName: string,
  path: string,
  required: boolean,
  targetType: string
}

export interface PimTemplate extends GungBase {
  active: boolean,
  columns: PimTemplateColumn[],
  expandMasterId: any,
  order: number
}

@Injectable({
  providedIn: 'root'
})
export class PimTemplateService {
  private templatesList: BehaviorSubject<PimTemplate[]>;

  enableCreateTemplate = true;

  constructor(private http: HttpClient) {}

  getTemplates(noCache?: boolean): Observable<PimTemplate[]> {
    const url = 'json/pim-data-templates';

    const headers: { [key: string]: string | string[] } = {};
    if (noCache) {
      headers.maxAge = '-1';
    }
    return this.http.get<PimTemplate[]>(url, { headers });
  }

  updateTemplatesListSubject(): void {
    this.getTemplates(true).subscribe(templates => this.templatesList.next(templates));
  }

  getAllTemplatesSubject(): Observable<PimTemplate[]> {
    if (!this.templatesList) {
      this.templatesList = new BehaviorSubject<PimTemplate[]>([]);
      this.updateTemplatesListSubject();
    }
    return this.templatesList.asObservable();
  }

  createTemplate(template: {id: string, name: string, order: number, columns: PimTemplateColumn[]}): Observable<PimTemplate[]> {
    const url = 'json/pim-data-templates';
    return this.http.post<PimTemplate[]>(url, template).pipe(tap(() => this.updateTemplatesListSubject()));
  }

  updateTemplate(template: PimTemplate): Observable<PimTemplate[]> {
    const url = 'json/pim-data-templates';
    return this.http.post<PimTemplate[]>(url, template).pipe(tap(() => this.updateTemplatesListSubject()));
  }
}
