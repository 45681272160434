import { Injectable } from '@angular/core';
import { ExportDragConfigService, ExportDragProductService } from 'gung-dynamic-pdf';
import { ConfigBaseFilter } from 'gung-list';
import { ProductService } from '../products/product.service';
import { Product } from '../../models/product';
import { ProductListConfigService } from '../product-list-config/product-list-config.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicPdfProductListConfigService extends ExportDragConfigService {
  constructor(
    protected productService: ProductService,
    protected exportDragProductService: ExportDragProductService,
    protected productListConfigService: ProductListConfigService
  ) {
    super(productService, exportDragProductService);
  }

  getFilters(): ConfigBaseFilter<Product>[] {
    return this.productListConfigService.getFilters();
  }
}
