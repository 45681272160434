export class OrderStatistic {
  productId: string;
  productModelId: string;
  productName: string;
  productPrimaryDimensionId: string;
  productPrimaryDimensionName: string;
  productSecondaryDimensionId: string;
  productSecondaryDimensionName: string;
  productSeasonCode: string;
  productSeasonDescription: string;
  productCategoryCode: string;
  productCategoryDescription: string;
  id: string;
  customerId: string;
  customerName: string;
  orderRowNumber: number;
  orderRowTotalQuantity: number;
  orderRowTotalValue: number;
  orderRowTotalValueCurrency: number;
  orderCreationDateYearMonth: string;
  orderCreationDateYearWeek: string;
  orderCreationDateYearQuarter: string;
  orderTypeCode: string;
  orderTypeDescription: string;
  orderSeasonCode: string;
  orderSeasonDescription: string;
  orderId: string;
  orderCreationDate: Date;
  orderDeliveryDate: Date;
  orderDeliveryDateYearQuarter: string;
  orderDeliveryDateYearMonth: string;
  orderDeliveryDateYearWeek: string;
  orderRowDeliveryStatusCode: string;
  orderRowDeliveryStatusDescription: string;
}

export class TopProductsStatistics {
  productId: string;
  productName: string;
  qty: number;
  orderCount: number;
  number?: number;
}
