import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Claim } from '../../models/claim';

@Injectable({
  providedIn: 'root'
})
export class JeevesClaimService {
  baseUrl = 'json/jeeves/claims';
  

  constructor(protected http: HttpClient) {}

  getAllClaims(): Observable<Claim[]> {
    const url = `${this.baseUrl}/list`;
    return this.http.get<Claim[]>(url);
  }

  getClaims(customerId: string): Observable<Claim[]> {
    const url = `${this.baseUrl}/list-by-customer-id/${customerId}`;
    return this.http.get<Claim[]>(url);
  }

  postClaim(customerId: string, claim: Claim): Observable<any> {
    const url = `${this.baseUrl}/create-claim/${customerId}`;
    return this.http.post<any>(url, claim);
  }
}
