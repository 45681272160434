<div class="row mt-4" *ngIf="!!this.data">
  <div class="col-12">
    <lib-invoice-statistics-chart [invoices]="this.data"></lib-invoice-statistics-chart>
  </div>
</div>

<div class="row mt-4" *ngIf="!!this.data">
  <div class="col-12">
    <lib-invoice-statistics-field-chart
      [invoices]="this.data"
      invoiceField="productCategoryCode"
    ></lib-invoice-statistics-field-chart>
  </div>
</div>
