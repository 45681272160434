import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService } from 'gung-common';
import { SupplierPoActionConfigService, SupplierService, GungModalService, GungPatchValue, SupplierPurchaseOrder, AuthService, gungAddRemoveSpinner } from 'gung-standard';
import { first, Observable, of, switchMap } from 'rxjs';
import { JeevesGungModalService } from '../jeeves-gung-modal/jeeves-gung-modal.service';

@Injectable({
  providedIn: 'root'
})
export class JeevesSupplierPoActionConfigService extends SupplierPoActionConfigService {

  constructor(
    protected supplierService: SupplierService,
    protected modalService: JeevesGungModalService,
    protected authService: AuthService,
    protected dateUtilService: DateUtilService,
    protected translationService: TranslateService
  ) {
    super(supplierService, modalService, authService, dateUtilService, translationService);
  }

  /**
   * ACTIONS
   */
  confirmPurchaseLineRequest(item: SupplierPurchaseOrder, rejectAction = false): Observable<SupplierPurchaseOrder> {
    let previousRequest: Observable<any> = of(item);
    let patchValues: GungPatchValue[] = [];
    if (!rejectAction) {
      // Check all required
      item.extra.errors = [];
      if (this.currentStatus === 20) { // New
        if (!this.getValues(item, 'extra.bp_bestant')) {
          item.extra.errors.push('extra.bp_bestant');
          // return of(null);
        }
        if (!this.getValues(item, 'extra.bp_bestlovlevdat')
          || this.getValues(item, 'extra.bp_bestlovlevdat') === '0001-01-01') {
          item.extra.errors.push('extra.bp_bestlovlevdat');
          // return of(null);
        }
        if (item.extra.errors.length > 0) {
          return of(null);
        }
      }
      delete item.extra.errors;
      // Check dynamic conditions
      if (this.currentStatus === 20) { // New
        if (this.getValues(item, 'extra.bp_bestant') < this.getValues(item, 'extra.bp.bestant') ) {
          // If they don't confirm all quantities requested, need to split the row first
          previousRequest = this.supplierService.postPurchaseOrderSplit(
            item.id,
            item.extra.bp_bestant
          ).pipe(first());
        }
      }
      // Copy all copyTo
      if (this.currentStatus === 20) { // New
        this.setValue(item, 'extra.bp.bestant', this.getValues(item, 'extra.bp_bestant'));
        this.setValue(item, 'extra.bp.bestlovlevdat', this.getValues(item, 'extra.bp_bestlovlevdat'));
      }
      // Delete all fields on delete prop
      if (this.currentStatus === 20) { // New
        this.deleteValue(item, 'extra.bp_bestant');
        this.deleteValue(item, 'extra.bp_bestlovlevdat');
      }
      // Patch Value for the request
      // ...
    } else {
      // Reject comment
      previousRequest = this.supplierService.postPurchaseOrderComment(item.id, item.comments[item.comments.length-1]);
    }
    // Start creating the request change
    const request = this.createRequest(item, rejectAction, patchValues);
    if (request) {
      return previousRequest.pipe(
        switchMap(po => {
          if (po?.id) {
            return this.supplierService.patchPurchaseOrder(po.id, request)
          } else {
            console.error('ERROR when patchPurchaseOrder - missing ID');
          }
        })
      );
    }
    return of(null)
  }

  createRequest(itemInput: SupplierPurchaseOrder, rejectAction: boolean, patchValues: GungPatchValue[]): GungPatchValue[] {
    const item = itemInput;
    const newStatus = rejectAction ? this.rejectStatus : this.nextStatus;
    if (!newStatus) {
      console.error('createRequest() missing new status');
      return;
    }
    patchValues = patchValues || [];
    // Status Change
    patchValues.push({
      op: 'replace',
      path: 'status',
      value: Number(newStatus)
    });
    if (rejectAction) {
      // Comment
      patchValues.push({
        op: 'replace',
        path: 'extra.bp.editext',
        value: itemInput.comments[itemInput.comments.length-1].comment
      });
    }
    if (this.currentStatus === 20) { // New
      patchValues.push({
        op: 'replace',
        path: 'extra.bp.bestant',
        value: this.getValues(item, 'extra.bp.bestant')
      });
      patchValues.push({
        op: 'replace',
        path: 'extra.bp.bestlovlevdat',
        value: this.getValues(item, 'extra.bp.bestlovlevdat')
      });
    }
    
    return patchValues;
  }

  public splitPurchaseLine(item: SupplierPurchaseOrder, { target }) {
    gungAddRemoveSpinner(target);
    this.modalService.openSupplierPurchaseOrderSplitModal(item).then(
      (value: SupplierPurchaseOrder[]) => {
        const oldRowWithNewQuantity = value[0];
        const newRow = value[1];
        this.supplierService.postPurchaseOrderSplit(item.id, newRow.extra.bp.bestant).pipe(first()).subscribe(
          (result: SupplierPurchaseOrder) => {
            if (result?.id) {
            }
          }
        )
      }
    ).finally(() => gungAddRemoveSpinner(target));
  }
}
