import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Claim } from '../../models/claim';
import { gungAddRemoveSpinner } from 'gung-standard';
import { JeevesModalService } from '../../services/jeeves-modal-service/jeeves-modal.service';

@Component({
  selector: 'gung-jeeves-jeeves-claim-table-view',
  templateUrl: './jeeves-claim-table-view.component.html',
  styleUrls: ['./jeeves-claim-table-view.component.css']
})
export class JeevesClaimTableViewComponent extends ListItemRendererComponent<Claim[]> implements OnInit, AfterViewInit {

  constructor(protected jeevesModalService: JeevesModalService) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  viewClaim(claim: Claim, element) {
    const target = element.target;
    gungAddRemoveSpinner(target);
    this.jeevesModalService
      .openClaimModal(null, claim)
      .then(
        answer => {
          console.log('answer', answer);
          if (answer) {
            gungAddRemoveSpinner(target);
          } else {
            gungAddRemoveSpinner(target);
            // this.gungModalService.openConfirmYesNoModal(undefined, 'ERROR', { size: 'sm' }, 'OK', null).then(_ => { });
          }
        },
        error => {
          gungAddRemoveSpinner(target);
          // this.gungModalService.openConfirmYesNoModal(undefined, 'ERROR', { size: 'sm' }, 'OK', null).then(_ => { });
        }
      )
      .catch(res => gungAddRemoveSpinner(target));
  }
}
