import { Injectable } from '@angular/core';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { AssortmentRecursive, AssortmentService, AssortmentTreeListConfigService } from 'gung-standard';
import { SjoborgAssortmentTreeListViewComponent } from '../components/sjoborg-assortment-tree-list-view/sjoborg-assortment-tree-list-view.component';

@Injectable({
  providedIn: 'root'
})
export class SjoborgAssortmentTreeListConfigService extends AssortmentTreeListConfigService {
  constructor(protected assortmentService: AssortmentService) {
    super(assortmentService);
  }

  getLayouts(): ListLayout<AssortmentRecursive>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => SjoborgAssortmentTreeListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'AssortmentGrid'
      }
    ];
  }
}
