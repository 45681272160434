<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit order</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div ngbAccordion>
      <div ngbAccordionItem [collapsed]="false">
        <div ngbAccordionHeader>
          <button ngbAccordionButton translate class="btn btn-link container-fluid text-left pl-0">            
              <span translate>CONDITIONS</span>
            <!--   <i class="fas ml-2" [ngClass]="!opened ? 'fa-chevron-down' : 'fa-chevron-up'"></i> -->
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="form-group row">
                <label for="oh.betkod" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4" translate
                  >PAYMENT_TERMS</label
                >
                <div class="col">
                  <lib-meta-select-input
                    id="oh.betkod"
                    table="x2"
                    metaId="betkod"
                    metaDisplay="betvillbeskr"
                    [(ngModel)]="editedOrder.extra.oh.betkod"
                  ></lib-meta-select-input>
                </div>
              </div>
              <div class="form-group row">
                <label for="oh.levvillkkod" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4" translate
                  >DELIVERY_TERMS</label
                >
                <div class="col">
                  <lib-meta-select-input
                    id="oh.levvillkkod"
                    table="x2e"
                    metaId="levvillkkod"
                    metaDisplay="levvillkbeskr"
                    [(ngModel)]="editedOrder.extra.oh.levvillkkod"
                  ></lib-meta-select-input>
                </div>
              </div>
              <div class="form-group row">
                <label for="oh.levsattkod" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4" translate
                  >DELIVERY_METHOD</label
                >
                <div class="col">
                  <lib-meta-select-input
                    id="oh.levsattkod"
                    table="x2f"
                    metaId="levsattkod"
                    metaDisplay="levsattbeskr"
                    [(ngModel)]="editedOrder.extra.oh.levsattkod"
                  ></lib-meta-select-input>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div ngbAccordionItem>
        <div ngbAccordionHeader>
          <button ngbAccordionButton class="btn btn-link container-fluid text-left pl-0">
            <span translate>SHIPPING_INFORMATION</span>
           <!--  <i class="fas ml-2" [ngClass]="!opened ? 'fa-chevron-down' : 'fa-chevron-up'"></i> -->
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <lib-gung-text-input-horizontal
                gungTranslate="ADDRESS1"
                [(ngModel)]="editedOrder.extra.oh.ordlevadr2"
              ></lib-gung-text-input-horizontal>
              <lib-gung-text-input-horizontal
                gungTranslate="ADDRESS2"
                [(ngModel)]="editedOrder.extra.oh.ordlevadr3"
              ></lib-gung-text-input-horizontal>
              <lib-gung-text-input-horizontal
                gungTranslate="CITY"
                [(ngModel)]="editedOrder.extra.oh.ordlevadr4"
              ></lib-gung-text-input-horizontal>
              <lib-gung-text-input-horizontal
                gungTranslate="ZIP"
                [(ngModel)]="editedOrder.extra.oh.ftgpostnr"
              ></lib-gung-text-input-horizontal>
              <div class="form-group row">
                <label for="oh.landskod" class="col-form-label text-muted col-xs-12 col-sm-4" translate>COUNTRY</label>
                <div class="col">
                  <lib-meta-select-input
                    class="hide-meta-label"
                    id="oh.landskod"
                    table="xr"
                    metaId="landskod"
                    metaDisplay="land"
                    [(ngModel)]="editedOrder.extra.oh.ordlevadrlandskod"
                  ></lib-meta-select-input>
                </div>
              </div>
    
              <lib-gung-text-input-horizontal
                gungTranslate="OH_KUNDBESTNR"
                [(ngModel)]="editedOrder.extra.oh.kundbestnr"
              ></lib-gung-text-input-horizontal>
              <div class="form-group row">
                <label for="oh.editext" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4" translate
                  >MESSAGE</label
                >
                <div class="col">
                  <textarea
                    class="form-control"
                    type="text"
                    name="editext"
                    id="oh.editext"
                    [(ngModel)]="editedOrder.extra.oh.editext"
                  ></textarea>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>

<button ngbDropdownItem (click)="open(content)">Edit order</button>
