<div class="row" *ngFor="let deliveryLocation of data; let first = first">
  <div class="col-10">
    <h4>{{ deliveryLocation.name }}</h4>
  </div>

  <div class="col-12 col-md-4">
    <lib-gung-text-input
      gungTranslate="FR_FTGPOSTADR1"
      [renderStatic]="true"
      [ngModel]="deliveryLocation.extra.fr.ftgpostadr5"
    ></lib-gung-text-input>
  </div>
  <div class="col-12 col-md-2">
    <lib-gung-text-input
      gungTranslate="FR_FTGPOSTNR"
      [renderStatic]="true"
      [ngModel]="deliveryLocation.extra.fr.ftglevpostnr"
    ></lib-gung-text-input>
  </div>
  <div class="col-12 col-md-4">
    <lib-gung-text-input
      gungTranslate="FR_FTGPOSTADR3"
      [renderStatic]="true"
      [ngModel]="deliveryLocation.extra.fr.ftgpostadr3"
    ></lib-gung-text-input>
  </div>
  <div class="col-12 col-md-2">
    <div class="form-group">
      <lib-gung-meta-output
        [value]="deliveryLocation.extra.fr.landskod"
        gungTranslate="FR_LANDSKOD"
        metadataTable="xr"
        metaOptionName="land"
      ></lib-gung-meta-output>
    </div>
  </div>
</div>
