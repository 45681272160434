import { Injectable } from '@angular/core';
import { JeevesSalesCartConfigService } from 'gung-standard-jeeves';
import { ListLayoutMultipleComponent, ListLayout } from 'gung-list';
import { BaseViewConfigService, CartRow, CartService, ProductAssortmentParentService } from 'gung-standard';
import { SjoborgSalesCartListComponent } from '../components/checkout/sjoborg-sales-cart-list/sjoborg-sales-cart-list.component';

@Injectable({
  providedIn: 'root'
})
export class SjoborgSalesCartConfigService extends JeevesSalesCartConfigService {
  constructor(
    protected cartService: CartService,     
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService
    ) {
    super(cartService, baseViewConfigService, productAssortmentParentService);
  }

  getLayouts(): ListLayout<CartRow>[] {
    return [
      {
        getIconClass: () => '',
        getName: () => 'Cart table',
        getListItemComponent: () => SjoborgSalesCartListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent
      }
    ];
  }
}
