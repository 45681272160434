import { Component } from '@angular/core';
import { JeevesOrderCardListComponent } from 'gung-standard-jeeves';

@Component({
  selector: 'sjoborg-order-card-list',
  templateUrl: './sjoborg-order-card-list.component.html',
  styleUrls: ['./sjoborg-order-card-list.component.scss']
})
export class SjoborgOrderCardListComponent extends JeevesOrderCardListComponent {
  public trackingUrl =
    // 'https://www.postnord.se/vara-verktyg/spara-brev-paket-och-pall2?gclid=Cj0KCQjwn8_mBRCLARIsAKxi0GLJFrcKR1EQ2ci74ecb1XKIV8V35a5v_717vckXQe-OYCVVZ8fO1I4aAnpPEALw_wcB&gclsrc=aw.ds#dynamicloading=true&shipmentid=';
    'https://www.postnord.se/vara-verktyg/spara-brev-paket-och-pall/?shipmentId=';
  // public trackingUrlDHL = 'https://www.dhl.com/se-sv/home/tracking/tracking-freight.html?submit=1&tracking-id=';
  // public trackingUrlDHLExpress = 'https://www.dhl.com/se-sv/home/tracking/tracking-express.html?submit=1&tracking-id=';
  public trackingUrlDHL = 'https://www.dhl.com/se-sv/home/tracking/tracking-express.html?submit=1&tracking-id=';

  public isDHLTracking(packageLev: string): boolean {
    return packageLev === 'DHL';
  }
  public isDHLExpressTracking(packageLev: string): boolean {
    return false;
  }

  public isPostNordTracking(packageLev: string): boolean {
    return packageLev === 'PostNord';
  }
}
