<div class="row">
    <div *ngFor="let product of data" class="col-12 col-md-6">
        <div class="card product-card"  draggable="true"
        (dragstart)="onDragStart($event, product)">
            <div class="card-body">
              <div class="image-wrapper" >
                <img
                  class="img-fluid"
                  *ngIf="product.extra && product.extra.images && product.extra.images[0]"
                  [src]="product.extra.images[0].s3Uri | gungImageUrl
                    :'type:thumbnail'
                    :'etag:'+product.extra.images[0].s3ETag
                  "
                  alt=""
                />
              </div>
              <div class="product-text pb-1">
                <div>{{ product.id }}</div>
                <div class="text-truncate" translate>{{ product.name }}</div>
                
                
                <div class="product-card-button">
                
                </div>
              </div>
            </div>
          </div>
    </div>
</div>

