import { Component, Input, OnInit } from '@angular/core';
import { first, forkJoin } from 'rxjs';
import { DashboardKpiService } from '../../../services/dashboard-kpi.service';
import { SalesSummaryEntity, Timespan } from '../../../types';

@Component({
  selector: 'lib-last-vs-this-week-offers-amount-summary-card',
  templateUrl: './last-vs-this-week-offers-amount-summary-card.component.html',
  styleUrls: ['./last-vs-this-week-offers-amount-summary-card.component.css']
})
export class LastVsThisWeekOffersAmountSummaryCardComponent implements OnInit {

  @Input()
  preConditions: any;

  numberOffersThisWeek = 0;
  numberOffersLastWeek = 0;
  loaded = false;

  constructor(protected dashboardKpiService: DashboardKpiService) {}

  ngOnInit() {
    const thisWeekSpan = this.dashboardKpiService.getDateFromTimespan(Timespan.Week);
    const lastWeekSpan = this.dashboardKpiService.getDateFromTimespan(Timespan.LastWeek);

    forkJoin({
      offersSummaryGroupsThisWeek: this.dashboardKpiService
      .getOfferSummary(SalesSummaryEntity.MAIN_ENTITY, thisWeekSpan.fromDate, null, this.preConditions)
      .pipe(first()),
      offersSummaryGroupsLastWeek: this.dashboardKpiService
      .getOfferSummary(SalesSummaryEntity.MAIN_ENTITY, lastWeekSpan.fromDate, lastWeekSpan.toDate, this.preConditions)
      .pipe(first())
    }).subscribe(({ offersSummaryGroupsThisWeek, offersSummaryGroupsLastWeek}) => {
      this.numberOffersThisWeek = offersSummaryGroupsThisWeek.length;
      this.numberOffersLastWeek = offersSummaryGroupsLastWeek.length;
      this.loaded = true;
    });
  }
}
