import { Injectable } from '@angular/core';
import {
  JeevesProductListConfigService,
  JeevesProductTableComponent,
  ProductItemGroupFilterService
} from 'gung-standard-jeeves';
import {
  AuthService,
  CloudPimFiltersService,
  CurrentAssortmentService,
  GungFlowService,
  LocationConfigService,
  Product,
  ProductArticleClassFilterService,
  ProductService,
  ProductSortService
} from 'gung-standard';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { SjoborgProductGridViewComponent } from '../components/sjoborg-product-grid-view/sjoborg-product-grid-view.component';

@Injectable({
  providedIn: 'root'
})
export class SjoborgProductListConfigService extends JeevesProductListConfigService {
  constructor(
    protected authService: AuthService,
    protected productService: ProductService,
    protected productItemGroupFilterService: ProductItemGroupFilterService,
    protected productArticleClassFilterService: ProductArticleClassFilterService,
    protected productSortService: ProductSortService,
    protected gungFlowService: GungFlowService,
    protected locationConfigService: LocationConfigService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected currentAssortmentService: CurrentAssortmentService
  ) {
    super(
      authService,
      productService,
      productItemGroupFilterService,
      productArticleClassFilterService,
      productSortService,
      gungFlowService,
      locationConfigService,
      cloudPimFiltersService,
      currentAssortmentService
    );
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => 'fa fa-th',
        getListItemComponent: () => SjoborgProductGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      },
      {
        getIconClass: () => 'fa fa-list',
        getListItemComponent: () => JeevesProductTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductList'
      }
    ];
  }
}
