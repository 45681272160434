import { Component, Input, OnChanges } from '@angular/core';
import {
  CartRowFeatureOptionSelection,
  ProductConfigurationFeature,
  ProductConfigurationOption
} from '../../models/product-configuration-types';
import { ProductConfigurationCartRowService } from '../../services/product-configuration-cart-row.service';
import { CheckoutRow } from '../../../../models/checkout';
import { CartRow } from '../../../../state/cart/types';

@Component({
  selector: 'lib-cpq-cart-row-summary',
  templateUrl: './cpq-cart-row-summary.component.html',
  styleUrls: ['./cpq-cart-row-summary.component.css']
})
export class CpqCartRowSummaryComponent implements OnChanges {
  @Input()
  row: CartRow | CheckoutRow;

  selectionsGroupedByFeature: Map<string, ProductConfigurationOption[]>;
  keyedFeatures: Map<string, ProductConfigurationFeature>;

  constructor(protected productConfigurationCartRowService: ProductConfigurationCartRowService) {}

  ngOnChanges(): void {
    if (!this.row || !this.productConfigurationCartRowService.isProductConfiguration(this.row)) {
      return;
    }
    this.selectionsGroupedByFeature = this.getSelectionGroupedByFeature(this.row);
    this.keyedFeatures = this.keyFeatures(this.row);
  }

  private getSelectionGroupedByFeature(row: CartRow | CheckoutRow): Map<string, ProductConfigurationOption[]> {
    const result = new Map<string, ProductConfigurationOption[]>();
    row.extra.productConfigurationSelectedOptions.forEach(selection => {
      if (!result.has(selection.feature.id)) {
        result.set(selection.feature.id, []);
      }
      result.get(selection.feature.id).push(selection.option);
    });

    return result;
  }

  private keyFeatures(row: CartRow | CheckoutRow): Map<string, ProductConfigurationFeature> {
    const result = new Map<string, ProductConfigurationFeature>();
    row.extra.productConfigurationSelectedOptions.forEach((selection: CartRowFeatureOptionSelection) => {
      result.set(selection.feature.id, selection.feature);
    });
    return result;
  }
}
