import { Component, Input, OnInit } from '@angular/core';
import { ReplenishmentProductExpanded, ReplenishmentProductStatus } from '../../models/replenishment';

import { first, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../services/auth/auth.service';
import { CartService } from '../../../../services/cart/cart.service';
import { SelectedCustomerService } from '../../../../services/selected-customer/selected-customer.service';
import { ReplenishmentProductListConfigService } from '../../services/replenishment-product-list-config.service';
import { PackSizeHelperService } from '../../../../services/pack-size-helper/pack-size-helper.service';

@Component({
  selector: 'lib-create-replenishment-button',
  templateUrl: './create-replenishment-button.component.html',
  styleUrls: ['./create-replenishment-button.component.scss']
})
export class CreateReplenishmentButtonComponent implements OnInit {
  @Input()
  protected replenishmentCustomerId: string;

  private unsubscribe: Subject<void> = new Subject();

  protected replenishmentProducts: ReplenishmentProductExpanded[] = [];

  constructor(
    protected authService: AuthService,
    protected cartService: CartService,
    protected selectedCustomerService: SelectedCustomerService,
    protected router: Router,
    protected replenishmentProductListConfigService: ReplenishmentProductListConfigService,
    protected route: ActivatedRoute,
    protected packSizeHelperService: PackSizeHelperService
  ) {}

  ngOnInit(): void {
    this.replenishmentProductListConfigService
      .getItems(this.route.snapshot)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(products => (this.replenishmentProducts = products));
  }

  createReplenishment(): void {
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => {
        const stockId = user.managedMultistockIds[0];
        const cartRows = this.getProductsRequiringReplenishment().map(p => {
          return {
            productId: p.productId,
            targetStockId: stockId,
            qty: this.calculateQuantityIncludingPackSize(p)
          };
        });
        this.cartService.clearCart();
        // This set-timout is for the clearCart event dispatch to have a bigger chance of actually finishing before adding
        // more values to the cart. Otherwise, there is a chance that we finish our addition to the cart before the
        // clear and will get the previous cart + the current addition.
        setTimeout(() => {
          this.selectedCustomerService.selectCustomer(this.replenishmentCustomerId);
          setTimeout(() => {
            this.cartService.bulkAddToCart(cartRows);
            this.router.navigate(['checkout']);
          }, 100);
        }, 1000);
      });
  }

  protected calculateQuantityIncludingPackSize(product: ReplenishmentProductExpanded): number {
    const purchaseQuantity = product.maximumQuantity - product.currentStock;
    const packSize = this.packSizeHelperService.getPackageSize(product.productData);

    // Round to the NEXT multiple of packSize
    return Math.ceil(purchaseQuantity / packSize) * packSize;
  }

  getProductsRequiringReplenishment(): ReplenishmentProductExpanded[] {
    return this.replenishmentProducts.filter(p => {
      return (p.status === ReplenishmentProductStatus.OK_FOR_ORDER || p.status === ReplenishmentProductStatus.NEVER_SYNCED) && p.active === true;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
