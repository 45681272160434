import {EventEmitter, Injectable} from '@angular/core';
import {first, Observable, of, switchMap} from 'rxjs';
import {
  EdiMessageBase,
  EdiOrder,
  EdiPurchaseOrder,
  GungEdiMessageSendStatus,
  GungEdimessageType
} from '../../models/edi';
import {gungAddRemoveSpinner} from '../../utils/gung-utils';
import {
  EdiMessageBaseActionConfigService
} from '../edi-message-base-action-config/edi-message-base-action-config.service';
import {ActionButton, ActionButtonConfigService} from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class EdiPurchasedOrderActionConfigService
  extends EdiMessageBaseActionConfigService
  implements ActionButtonConfigService<EdiPurchaseOrder> {

  protected getMessageType(): GungEdimessageType {
    return GungEdimessageType.PURCHASE_ORDER;
  }
}
