import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListLayoutMultipleComponent, ListLayout } from 'gung-list';
import {
  ProductService,
  AuthService,
  ProductSortService,
  ConceptDetailProductListConfigService,
  Product,
  MetadataService,
  BaseViewConfigService,
  ColumnSortSelectionService,
  DynamicColumnsService
} from 'gung-standard';
import { SjoborgConceptDetailProductListViewComponent } from '../components/sjoborg-concept-detail-product-list-view/sjoborg-concept-detail-product-list-view.component';

@Injectable({
  providedIn: 'root'
})
export class SjoborgConceptDetailProductListConfigService extends ConceptDetailProductListConfigService {
  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productSortService: ProductSortService,
    protected translationService: TranslateService,
    protected metadataService: MetadataService,
    protected baseViewConfigService: BaseViewConfigService,
    protected columnSortSelectionService: ColumnSortSelectionService,
    protected dynamicColumnsService: DynamicColumnsService
  ) {
    super(productService, authService, productSortService, translationService, metadataService, baseViewConfigService, columnSortSelectionService, dynamicColumnsService);
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => SjoborgConceptDetailProductListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }
}
