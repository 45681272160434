import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  OnInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FastSearchLayout, InlineSearchNavbarComponent } from 'gung-list';
import { forkJoin, interval, Observable, of } from 'rxjs';
import { debounce, distinctUntilChanged, filter, first, mergeMap, startWith, switchMap, takeUntil } from 'rxjs';
import { Customer } from '../../models';
import { AssortmentService } from '../../services/assortment.service';
import { AuthService } from '../../services/auth/auth.service';
import { ProductAssortmentParentService } from '../../services/product-assortment-parent/product-assortment-parent.service';
import { ProductService } from '../../services/products/product.service';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { User } from '../../state/auth/types';
import { ProductConcept } from '../concept-detail-v2/concept-detail-v2.component';

@Component({
  selector: 'lib-inline-search-product-concept-navbar',
  templateUrl: './inline-search-product-concept-navbar.component.html',
  styleUrls: ['./inline-search-product-concept-navbar.component.scss']
})
export class InlineSearchProductConceptNavbarComponent<T> extends InlineSearchNavbarComponent<T> implements OnInit {
  protected globalSearch; // = 'sINTERNAL_SEARCH';
  isScannerSearch = false;
  currentUser: User;
  currentCustomer: Customer;

  assortments = [];
  products = [];
  resultsLimit = 15;

  constructor(
    protected resolver: ComponentFactoryResolver,
    protected detector: ChangeDetectorRef,
    protected eRef: ElementRef,
    protected router: Router,
    route: ActivatedRoute,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected productService: ProductService,
    protected productAssortmentParentService: ProductAssortmentParentService,
    protected assortmentService: AssortmentService
  ) {
    super(resolver, detector, eRef, router, route);
  }

  // @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   if (event.path && event.path.findIndex(t => t.dataset && !!t.dataset.inlineSearchProducts) > -1) {
  //     // Click on a product, close results
  //     this.resultsShow = false;
  //     return;
  //   }
  //   const insideClick = this.eRef.nativeElement.contains(event.target);
  //   if (!this.resultsShow && insideClick && !this.isSmallScreen) {
  //    /*  this.resultsShow = true; */
  //     return;
  //   } else if (this.resultsShow && !insideClick) {
  //     this.resultsShow = false;
  //   }
  // }

  ngOnInit() {
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => (this.currentUser = user));
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => (this.currentCustomer = customer));

    // super.ngOnInit();
    this.searchTermForm = new FormControl(this.searchTerm);
    this.searchTermFormTrigger()
      .pipe(switchMap(valueChanged => this.search(valueChanged)))
      .subscribe(response => {
        this.searching = false;
        this.resultsShow = true;
        this.totalItems.next(response.totalItems);
        this.renderItems.next(response.items as any);
      });

    const mediaQuery = matchMedia('screen and (max-width: 767px)');
    this.isSmallScreen = mediaQuery.matches;

    this.totalItems.pipe(takeUntil(this.unsubscribeRouter)).subscribe(total => (this.totalCount = total));
    if(this.router.url === '/search-scanner'){
      this.isScannerSearch = true;
    }
  }

  searchTermFormTrigger(): Observable<string> {
    return this.searchTermForm.valueChanges.pipe(
      takeUntil(this.unsubscribeRouter),
      filter(term => !!term),
      // startWith(''),
      debounce(() => interval(400)),
      distinctUntilChanged()
    );
  }

  renderLayout(layout: FastSearchLayout<T>) {
    return;
  }

  public openProduct(path) {
    this.resultsShow = false;
    /* this.searchTermForm.patchValue(''); */
    if(this.isScannerSearch){
      path[0]="/mobile-product";
    }
    this.router.navigate(path);
  }

  public showMoreAssortments({ value }) {
    this.resultsShow = false;
    /* this.searchTermForm.patchValue(''); */
    this.router.navigate(['/products-search'], { queryParams: { search: value } });
  }

  public showMoreProducts({ value }) {
    // localStorage.setItem('searchTerm', value);
    this.resultsShow = false;
    /* this.searchTermForm.patchValue(''); */
    this.router.navigate(['/quick-products'], { queryParams: { products: 0, limit: 24, terms: value } });
  }

  search(term: string) {
    if (term.length === 0) {
      this.resultsShow = false;
      return;
    }

    this.searching = true;
    const request = {
      terms: [term],
      limit: this.resultsLimit,
      skip: 0,
      assortment: this.globalSearch || this.currentUser.assortment,
      customerId: this.currentCustomer.id,
      additionalAssortments: this.currentCustomer.extra._customerSpecAssortment
        ? [this.currentCustomer.extra._customerSpecAssortment]
        : undefined
    };
    return forkJoin([
      this.productAssortmentParentService.getSearchAssortments(term).pipe(first()),
      this.productService.getPagedProducts(request)
    ]).pipe(
      switchMap(([searchAssortments, pagedProducts]) => {
        const ids = pagedProducts.items.map(p => p.id);
        return forkJoin([
          of(searchAssortments),
          of(pagedProducts),
          this.productAssortmentParentService.postProductAssortmentParentByProductIds(ids).pipe(
            filter(assortmentParent => !!assortmentParent),
            first()
          )
        ]);
      }),
      switchMap(([searchAssortments, pagedProducts, productAssortmentParent]) => {
        const assortments = searchAssortments.assortments
          .filter(assortment => assortment.path.length > 2) // Remove first level assortment
          .map(a => {
            const _path = a.path.map(p => p.id);
            _path.splice(0, 1, '/articles');
            return { ...a.assortment, _path };
          });
        const concepts = searchAssortments.concepts.map(c => {
          const _path = c.path.map(p => p.id);
          _path.splice(0, 1, '/articles');
          return { ...c.concept, _path };
        });
        const products = pagedProducts.items.map(p => {
          const tempPath = productAssortmentParent && productAssortmentParent[p.id];
          const _path = (tempPath && tempPath.map(t => t.id)) || [''];
          if (_path.length <= 1) {
            _path.splice(0, 1, '/product');
          } else {
            _path.splice(0, 1, '/articles');
          }
          if (!_path.includes(p.id)) {
            _path.push(p.id);
          }
          return { ...p, _path };
        });
        products.map(product => {
          if (
            product.extra.images &&
            product.extra.images.length > 0 &&
            product.extra.images[0].s3Uri === 'images/no-image.jpg'
          ) {
            if (product._path?.length > 1 && product._path[product._path.length - 2].startsWith('s')) {
              this.assortmentService
                .getAssortment(product._path[product._path.length - 2])
                .subscribe(assortment => (product.extra.images = assortment.extra.images));
            }
          }
          return product;
        });
        if (products.length === 1 && assortments.length === 0 && concepts.length === 0) {
          const product = products[0];
          const path = product._path?.length > 1 && product._path[product._path.length - 2];
          if (path && path.startsWith('s')) {
            this.assortmentService.getAssortment(path).subscribe(assortment => {
              const tempPath = productAssortmentParent && productAssortmentParent[product.id];
              const _path: string[] = (tempPath && tempPath.map(t => t.id)) || [''];
              _path.splice(0, 1, '/articles');
              _path.pop();
              assortments.push({ ...assortment, _path });
              this.assortments = assortments;
            });
          } else if (path) {
            this.productService.getProduct(path).subscribe((concept: ProductConcept) => {
              const tempPath = productAssortmentParent && productAssortmentParent[product.id];
              const _path: string[] = (tempPath && tempPath.map(t => t.id)) || [''];
              _path.splice(0, 1, '/articles');
              _path.pop();
              concepts.push({ ...concept, _path });
              this.assortments = concepts;
            });
          }
        }
        // Add assortments/concepts from product to list
        const extraList = pagedProducts.items
          .map(p => {
            const id = p.id;
            const productAssortments = productAssortmentParent && productAssortmentParent[id];
            const _path = (productAssortments && productAssortments.map(t => t.id)) || [''];
            if (_path.length <= 1) {
              _path.splice(0, 1, '/product');
            } else {
              _path.splice(0, 1, '/articles');
            }
            if (_path.includes(p.id)) {
              _path.pop();
            }
            if (productAssortments.length > 0) {
              return {
                ...productAssortments[productAssortments.length - 1],
                _path
              };
            }
            return;
          })
          .filter(
            (value, index, self) =>
              index === self.findIndex(t => t.id === value.id) &&
              ![...assortments, ...concepts].find(a => a.id === value.id) // remove duplicate
          )
          .map(extra => {
            const conceptIndex = concepts.findIndex(c => c.id === extra.id);
            if (conceptIndex !== -1) {
              return concepts[conceptIndex];
            }
            const assortmentIndex = assortments.findIndex(a => a.id === extra.id);
            if (assortmentIndex !== -1) {
              return assortments[assortmentIndex];
            }
            return extra;
          });
        extraList.map(item => {
          if (
            !item.extra.images ||
            item.extra.images.length === 0 ||
            item.extra.images[0].s3Uri === 'images/no-image.jpg'
          ) {
            this.productService.getProduct(item.id).subscribe(product => {
              // get image from the product/concept
              item.extra = product.extra;
              if (
                !item.extra.images ||
                item.extra.images.length === 0 ||
                item.extra.images[0].s3Uri === 'images/no-image.jpg'
              ) {
                if (item._path?.length > 1 && item._path[item._path.length - 2].startsWith('s')) {
                  this.assortmentService
                    .getAssortment(item._path[item._path.length - 2])
                    .subscribe(assortment => (item.extra.images = assortment.extra.images));
                }
              }
            });
          }
          return item;
        });
        this.assortments = [...assortments, ...concepts];
        this.assortments.splice(15 - extraList.length, 0, ...extraList);
        this.products = [...products];
        const items = [...assortments, ...concepts, ...products];
        const searchResultProducts = {
          items,
          totalItems: pagedProducts.totalItems,
          skipped: null
        };
        return of(searchResultProducts);
      })
    );
  }
}