import { Component } from '@angular/core';
import {
  OrderDeliveryRequest,
  OrderDeliveryRequestRow,
  ProductionManagementShipOrdersService
} from '../../services/production-management-ship-orders.service';
import { TranslateService } from '@ngx-translate/core';
import { GungNotificationService } from 'gung-common';
import { OperatorFunction, Observable, debounceTime, distinctUntilChanged, map, first, from, catchError, of } from 'rxjs';
import { AvailabilityService } from '../../../../services/availability/availability.service';
import { MetadataService } from '../../../../services/metadata/metadata.service';
import { Order } from '../../../../models/order';
import { TableRecord } from '../../../../state/metadata/types';
import { OrderService } from '../../../../services/orders/order.service';
import { SelectedCustomerService } from '../../../../services/selected-customer/selected-customer.service';
import { FreightProductHelperService } from '../../../../services/freight-product-helper/freight-product-helper.service';
import { OrderRow } from '../../../../models/orderRow';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductionManagementModalService } from '../../services/production-management-modal.service';
import { ProductService } from '../../../../services/products/product.service';
import { ProductionManagementOrderPickingService } from '../../services/production-management-order-picking.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ProductionManagementStockCountingModalComponent, } from '../production-management-stock-counting-modal/production-management-stock-counting-modal.component';

@Component({
  selector: 'lib-production-management-ship-orders',
  templateUrl: './production-management-ship-orders.component.html',
  styleUrls: ['./production-management-ship-orders.component.css']
})
export class ProductionManagementShipOrdersComponent {
  public orderId: string;
  order: Order;

  resultFormatter = (item: Order) => item.id + ' - ' + item.extra.deliveryCustomer.name;
  searchTypeahead: OperatorFunction<string, readonly Order[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.ordersList.filter(s => new RegExp(term, 'mi')?.test(s.id)).slice(0, 10))
    );
  ordersList: Order[];
  locations: [TableRecord];
  products = {};

  pickStatus: any[] = [];
  loading: boolean = true;

  detailsView: boolean = false;

  freightProducts: string[];

  constructor(
    protected shipOrdersService: ProductionManagementShipOrdersService,
    protected orderService: OrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected availabilityService: AvailabilityService,
    protected metadataService: MetadataService,
    protected gungNotificationService: GungNotificationService,
    protected translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    protected productionManagementModalService: ProductionManagementModalService,
    protected productService: ProductService,
    protected orderPickingService: ProductionManagementOrderPickingService,
    protected freightProductHelperService: FreightProductHelperService,
    protected ngbModal: NgbModal
  ) {
    this.locations = this.metadataService.getMetadataTableList('StockPoints');
    this.freightProductHelperService.getFreightProductsAsObservable().subscribe(freightProducts => {
      this.freightProducts = freightProducts;
    });

    const id = this.route.snapshot?.params?.id;
    if (id) {
      this.detailsView = true;
      this.orderId = id;
      this.getOrderById(this.orderId);
    } else {
      this.orderService
        .getOpenOrders()
        .pipe(first())
        .subscribe(orders => {
          this.ordersList = orders;
        });
    }
  }

  getOrderById(orderId) {
    if (orderId) {
      this.orderService
        .getOrder(orderId || this.orderId, true)
        .pipe(first())
        .subscribe(order => {
          this.order = order;
          this.order.rows.forEach(
            row =>
            (row.extra._qtyToDeliver =
              row.extra?.OrderRows?.OrderedQuantity - row.extra?.OrderRows?.DeliveredQuantity)
          );
          this.getAvailability(this.order);
          this.getStockPoints(this.order);
          this.getProducts(this.order);
          this.getOrderPicking(this.order.id);
        });
    }
  }

  getOrderPicking(orderId: string) {
    this.orderPickingService.getOrderPicking(orderId).subscribe(data => {
      this.pickStatus = data;
      this.order.rows.forEach(row => {
        row.extra._pickStatus = data.find(status => status.rowId === row.id);
      });
    });
  }

  toggleRow(row: OrderRow) {
    this.orderPickingService.postToggleRowPickStatus(this.order.id, row.id).subscribe(data => {
      this.pickStatus.push(data);
      row.extra._pickStatus = data;
    });
  }

  getProducts(order: Order) {
    const ids = order.rows.map(r => r.productId);
    this.productService
      .getFullProductsByIds(ids)
      .pipe(first())
      .subscribe(products => {
        for (const p of products) {
          this.products[p.id] = p;
        }
        this.sortOrderRows();
      });
  }

  sortOrderRows() {
    this.order.rows.sort((a, b) => {
      return this.products[a.productId].extra?.Article?.DefaultStockLocation?.localeCompare(
        this.products[b.productId].extra?.Article?.DefaultStockLocation,
        'sv'
      );
    });
  }

  getAvailability(order: Order) {
    const stockPointToRows: { [stockPointId: string]: OrderRow[] } = {};

    order.rows.forEach(row => {
      const stockPoint = this.getStockPointFromId(row.extra.OrderRows.StockPointId)?.code;
      if (!stockPointToRows[stockPoint]) {
        stockPointToRows[stockPoint] = [];
      }
      stockPointToRows[stockPoint].push(row);
    });

    for (const stockPointId in stockPointToRows) {
      this.availabilityService
        .getAvailabilities(
          stockPointToRows[stockPointId].map(r => r.productId),
          stockPointId,
          true
        )
        .pipe(first())
        .subscribe(av => {
          const keyedAv = av.reduce((acc, a) => {
            acc[a.productId] = a;
            return acc;
          }, {});

          for (const row of stockPointToRows[stockPointId]) {
            const availability = keyedAv[row.productId];
            row.extra._availability = availability;
            if (availability.currentStock < row.quantity) {
              row.extra._qtyToDeliver = availability.currentStock;
            }
          }
          this.loading = false;
        });
    }
  }

  isDelivered(order: Order) {
    return order.extra?.Order?.WarehouseReady === true;
  }

  getStockPoints(order: Order) {
    order.rows.forEach(row => {
      row.extra._StockPoint = this.getStockPointFromId(row.extra.OrderRows.StockPointId)?.name;
    });
  }

  getStockPointFromId(id: string): TableRecord {
    if (!id) {
      return;
    }
    return this.locations.find(s => s.id === id);
  }

  selectItem({ item }) {
    if (item) {
      this.getOrderById(item.id);
    }
  }

  deliverOrder(rows: OrderRow[]) {
    const o: OrderDeliveryRequest = {
      orderId: this.order.id,
      rows: rows.map(this.mapRowToDeliveryRow)
    };
    this.postUpdate(o, true);
  }

  mapRowToDeliveryRow(row: OrderRow): OrderDeliveryRequestRow {
    return {
      rowId: '' + row.extra.OrderRows.RowId,
      // For service articles we will get full delivered quantity automatically, so we don't want to reset them to 0
      deliveryQuantity: row.extra._qtyToDeliver || row.extra?.OrderRows?.DeliveredQuantity
    };
  }

  cancelRow(rowCancelIndex: number) {
    const o: OrderDeliveryRequest = {
      orderId: this.order.id,
      rows: this.order.rows.map(this.mapRowToDeliveryRow)
    };

    o.rows[rowCancelIndex].deliveryQuantity = 0;
    this.postUpdate(o, false, 'CANCELLED');
  }

  sendEmailRowNoStock(rowCancelIndex: number) {
    const row = this.order.rows[rowCancelIndex];
    const product = this.products[row.productId];
    this.shipOrdersService.sendNoStockEmail(row, product).pipe(
      catchError(err => {
        console.error(err);
        return of(false);
      })
    ).subscribe(result => {
      this.gungNotificationService.notify(this.translateService.instant('EMAIL'), this.translateService.instant(result ? 'SENT' : 'ERRORWHILESENDING'), result ? 'success' : 'error')
    });
  }

  sendEmailRowLastBox(rowCancelIndex: number) {
    const row = this.order.rows[rowCancelIndex];
    const product = this.products[row.productId];
    this.shipOrdersService.sendLastBoxStockEmail(row, product).pipe(
      catchError(err => {
        console.error(err);
        return of(false);
      })
    ).subscribe(result => {
      this.gungNotificationService.notify(this.translateService.instant('EMAIL'), this.translateService.instant(result ? 'SENT' : 'ERRORWHILESENDING'), result ? 'success' : 'error')
    });
  }

  stockCountRow(rowCancelIndex: number) {
    const row = this.order.rows[rowCancelIndex];
    const params = {
      productId: row.productId,
      StockPointCode: row.extra.OrderRows.StockPointCode,
      StockPointId: row.extra.OrderRows.StockPointId
    };

    // this.router.navigate(['warehouse-management','stock-counting'], {queryParams: params})
    this.openStockCountModal(params.productId, params.StockPointCode, params.StockPointId).then(qty => {
      row.extra._availability.currentStock = qty ?? row.extra._availability.currentStock;
      this.getAvailability(this.order);
    }).catch(_ => { });
  }

  openStockCountModal(productId: string, StockPointCode: string, StockPointId: string, modalOption?: NgbModalOptions): Promise<any> {
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      size: 'xl',
      ...modalOption
    };
    const ref = this.ngbModal.open(ProductionManagementStockCountingModalComponent, option);
    const componentInstance = ref.componentInstance as ProductionManagementStockCountingModalComponent;
    componentInstance.inputParams = {
      productId,
      StockPointCode,
      StockPointId
    };

    return ref.result;
  }

  postUpdate(request: OrderDeliveryRequest, finished: boolean, successMessage?: string) {
    var postMethod = finished
      ? this.shipOrdersService.deliverOrderAndMarkAsFinished
      : this.shipOrdersService.deliverOrder;
    postMethod.call(this.shipOrdersService, request).subscribe({
      next: res => {
        this.getOrderById(this.order.id);
      },
      error: err => {
        console.log('err', err);
        this.gungNotificationService.notify(
          this.translateService.instant('ERROR'),
          this.translateService.instant('ORDER_DELIVERY_ERROR'),
          'error'
        );
        this.getOrderById(this.order.id);
      }
    });
  }

  openPrintShippingModal() {
    from(this.productionManagementModalService.openCreateShipmentModal(this.order))
      .pipe(first())
      .subscribe(() => {
        this.getOrderById(this.order.id);
      });
  }

  openPrintPlocksedelModal() {
    from(this.productionManagementModalService.openCreatePlocksedelModal(this.order))
      .pipe(first())
      .subscribe(() => {
        this.getOrderById(this.order.id);
      });
  }

  openPrintFoljesedelModal() {
    from(this.productionManagementModalService.openCreateFoljesedelModal(this.order))
      .pipe(first())
      .subscribe(() => {
        this.getOrderById(this.order.id);
      });
  }
}
