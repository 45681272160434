import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GungTableActiveFilter } from '../../../models/common-table';

@Component({
  selector: 'gung-table-active-filter-number',
  templateUrl: './gung-table-active-filter-number.component.html',
  styleUrl: './gung-table-active-filter-number.component.scss'
})
export class GungTableActiveFilterNumberComponent{
  
  activeNumber = 0;

  private _activeFilters: GungTableActiveFilter[];

  @Input()
  set activeFilters(value: GungTableActiveFilter[]) {
    this._activeFilters = value;
    this.onActiveFiltersChanged();
  }

  get activeFilters(): GungTableActiveFilter[] {
    return this._activeFilters;
  }

  @Input() index: number;

  onActiveFiltersChanged(): void {
    const thisFilter = this.activeFilters.find(f=> f.headerIndex === this.index);
    if(thisFilter){
      this.activeNumber = thisFilter.activeValues.length;
    }else{
      this.activeNumber = 0;
    }
  }

}
