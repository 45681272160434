import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService } from 'gung-common';
import { SupplierPoListTableViewComponent, SupplierService, GungModalService, MetadataService, SupplierDynamicColumnsService, SupplierPoActionConfigService, SupplierSortColumnService } from 'gung-standard';

@Component({
  selector: 'gung-jeeves-jeeves-supplier-po-list-table-view',
  templateUrl: './jeeves-supplier-po-list-table-view.component.html',
  styleUrls: ['./jeeves-supplier-po-list-table-view.component.css']
})
export class JeevesSupplierPoListTableViewComponent extends SupplierPoListTableViewComponent implements OnInit {

  constructor(
    public dateUtilService: DateUtilService,
    protected supplierService: SupplierService,
    protected http: HttpClient,
    protected modalService: GungModalService,
    protected metadataService: MetadataService,
    protected activatedRoute: ActivatedRoute,
    public supplierPoActionConfigService: SupplierPoActionConfigService,
    public supplierDynamicColumnsService: SupplierDynamicColumnsService,
    protected translationService: TranslateService,
    protected columnSortService: SupplierSortColumnService
  ) {
    super(
      dateUtilService,
      supplierService,
      http,
      modalService,
      metadataService,
      activatedRoute,
      supplierPoActionConfigService,
      supplierDynamicColumnsService,
      translationService,
      columnSortService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initData() {
    this.currentStatus = Number(this.activatedRoute.snapshot.params?.purchaseOrderType);
    // Reject status
    const rejectStatus = this.postatus.find(p => p.name.toLocaleUpperCase() === 'Rejected'.toLocaleUpperCase());
    if (rejectStatus !== undefined && rejectStatus !== null && this.currentStatus.toString() !== rejectStatus.id) {
      this.rejectStatus = Number(rejectStatus.id);
      this.rejectStatusName = rejectStatus.name;
    }
    // Next status
    this.nextStatus = this.currentStatus !== undefined && this.currentStatus !== null && Number(this.getNextStatus(this.currentStatus.toString()));
    this.nextStatusName = this.postatus.find(p => p.id === this.nextStatus.toString())?.name;

    // Actions

    // this.detailsColumn = this.supplierDynamicColumnsService.getPoSupplierTableColumnsDetails(this.currentStatus);
    this.supplierPoActionConfigService.currentStatus = this.currentStatus;
    this.supplierPoActionConfigService.nextStatus = this.nextStatus;
    this.supplierPoActionConfigService.nextStatusName = this.nextStatusName;
    this.supplierPoActionConfigService.rejectStatus = this.rejectStatus;
    this.supplierPoActionConfigService.rejectStatusName = this.rejectStatusName;
    // this.supplierPoActionConfigService.tableColumn = this.tableColumn;
    this.supplierPoActionConfigService.actions = [];
    if (this.nextStatusName) {
      this.supplierPoActionConfigService.actions.push(
        {
          ...this.supplierPoActionConfigService.actionMoveNextStatusPurchaseLine,
          title: this.nextStatusName || this.supplierPoActionConfigService.actionMoveNextStatusPurchaseLine.title
        }
      );
    }
    if (this.rejectStatusName) {
      this.supplierPoActionConfigService.actions.push(
        {
          ...this.supplierPoActionConfigService.actionMoveRejectStatusPurchaseLine,
          title: this.rejectStatusName || this.supplierPoActionConfigService.actionMoveRejectStatusPurchaseLine.title
        }
      );
    }
    this.supplierPoActionConfigService.actions.push(
      this.supplierPoActionConfigService.actionHistoryPurchaseLine,
      // this.supplierPoActionConfigService.actionUploadDocumentPurchaseLine
    );
  }

}
