import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../../guards/authguard/auth-guard';
import { PlanogramMainPageComponent } from './components/planogram-main-page/planogram-main-page.component';
import { RouterModule, Routes } from '@angular/router';
import { featureActivatedGuard } from '../../guards/feature-activated/feature-activated.guard';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PlanogramGridViewComponent } from './components/planogram-grid-view/planogram-grid-view.component';
import { PlanogramComponent } from './components/planogram/planogram.component';
import { GridsterModule } from 'angular-gridster2';
import { PlanogramCreateConfigModalComponent } from './components/planogram-create-config-modal/planogram-create-config-modal.component';
import { PlanogramProductGridComponent } from './components/planogram-product-grid/planogram-product-grid.component';
import { PlanogramModuleGridComponent } from './components/planogram-module-grid/planogram-module-grid.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PlanogramDragHelperComponent } from './components/planogram-drag-helper/planogram-drag-helper.component';
import { PlanogramEmailPdfExportComponent } from './components/planogram-email-pdf-export/planogram-email-pdf-export.component';
import { PlanogramCompareModalComponent } from './components/planogram-compare-modal/planogram-compare-modal.component';
import { PlanogramPrintPreviewComponent } from './components/planogram-print-preview/planogram-print-preview.component';

const routes: Routes = [
  {
    path: '',
    component: PlanogramMainPageComponent,
    canActivate: [AuthGuard/* , featureActivatedGuard */],
    data: {
      featureId: 'planogram',
      minimumGungBaseVersion: '3.0.18'
    }
  },
  {
    path: 'new',
    component: PlanogramComponent,
    canActivate: [AuthGuard/* , featureActivatedGuard */],
    data: {
      featureId: 'planogram',
      minimumGungBaseVersion: '3.0.18'
    }
  },
  {
    path: ':id',
    component: PlanogramComponent,
    canActivate: [AuthGuard/* , featureActivatedGuard */],
    data: {
      featureId: 'planogram',
      minimumGungBaseVersion: '3.0.18'
    }
  },
  {
    path: 'preview/:id',
    component: PlanogramPrintPreviewComponent,
    canActivate: [AuthGuard/* , featureActivatedGuard */],
    data: {
      featureId: 'planogram',
      minimumGungBaseVersion: '3.0.18'
    }
  }
];

@NgModule({
  declarations: [
    PlanogramMainPageComponent,
    PlanogramGridViewComponent,
    PlanogramComponent,
    PlanogramCreateConfigModalComponent,
    PlanogramProductGridComponent,
    PlanogramModuleGridComponent,
    PlanogramDragHelperComponent,
    PlanogramEmailPdfExportComponent,
    PlanogramCompareModalComponent,
    PlanogramPrintPreviewComponent,
  ],
  imports: [
    SharedModule,
    GridsterModule,
    DragDropModule,
    TranslateModule.forChild({
      extend: true
    }),
    RouterModule.forChild(routes),
  ]
})
export class PlanogramModule { }
