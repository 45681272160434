import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first, catchError, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExportDataService {
  constructor(private http: HttpClient) {}

  exportProductsAsExcel(body: any): Observable<{ status: boolean; message: string }> {
    return this.http.post<{ status: string }>('download/generate-product-excel-from-products-ids', body).pipe(
      first(),
      map(resp => {
        return {
          status: resp.status === 'OK',
          message: resp.status
        };
      })
    );
  }

  exportProductsAsCsv(body: any): Observable<{ status: boolean; message: string }> {
    return this.http.post<{ status: string }>('download/generate-product-csv-from-products-ids', body).pipe(
      first(),
      map(resp => {
        return {
          status: resp.status === 'OK',
          message: resp.status
        };
      })
    );
  }

  exportProductsAsLinesheet(body: any): Observable<{ status: boolean; message: string }> {
    return this.http.post<{ status: string }>('download/generate-linesheet-excel-from-products-ids', body).pipe(
      first(),
      map(resp => {
        return {
          status: resp.status === 'OK',
          message: resp.status
        };
      })
    );
  }

  exportProductsAsImageZip(body: any): Observable<{ status: boolean; message: string }> {
    return this.http.post<{ status: string }>('download/generate-product-image-zip-from-products-ids', body).pipe(
      first(),
      map(resp => {
        return {
          status: resp.status === 'OK',
          message: resp.status
        };
      })
    );
  }

  exportProductsAsPdf(body: any): Observable<{ status: boolean; message: string }> {
    return this.http.post<{ status: string }>('download/generate-product-pdf-from-products-ids', body).pipe(
      first(),
      map(resp => {
        return {
          status: resp.status === 'OK',
          message: resp.status
        };
      })
    );
  }
}
