<div class="dashboard-card card" *ngIf="loaded">
  <div class="card-body dashboard-card-content">
    <div class="title" translate>ORDERS_LAST_7_DAYS</div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>NUMBER_OF_ORDERS</span>
      </div>
      <span class="current-statistic text-primary">{{ orderLastXDaysCount | number: '1.0-0' }}</span>
    </div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>AVERAGE_ORDER_VALUE</span>
      </div>
      <span class="current-statistic text-primary">{{ orderLastXDaysAvgValue | number: '1.0-0' }}</span>
    </div>

    <div class="progress-container mt-2">
      <ngb-progressbar [animated]="true" [value]="(orderLastXDaysCount / orderXDaysBeforeLastXDaysCount) * 100">
      </ngb-progressbar>
      <div class="d-flex justify-content-between">
        <span class="sub-title"
          >{{ ((orderLastXDaysCount / orderXDaysBeforeLastXDaysCount) * 100 || 0).toFixed() }}%</span
        >
        <span class="sub-title" translate>NUMBER_OF_ORDERS_COMPARED_LAST_7_DAYS</span>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-card card" *ngIf="!loaded">
  <div class="card-body dashboard-card-content">
    <div class="text-center dashboard-loading-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
