import { Inject, Injectable, Optional } from "@angular/core";
import { switchMap, first, map, tap, forkJoin, of } from "rxjs";
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CartService } from '../cart/cart.service';
import { CartRow } from '../../state/cart/types';
import { Cart } from '../../models/cart';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { Customer } from '../../models/customer';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { GungFlow } from '../../state/flow/types';
import { NavigationConfig } from "../../models/navigation-config";

const version = 'V2.0-20211015';
@Injectable({
  providedIn: 'root'
})
export class CartsService {
  private carts: BehaviorSubject<Cart[]>;
  private quotes: BehaviorSubject<Cart[]>;

  constructor(
    protected http: HttpClient,
    protected cartService: CartService,
    protected selectedCustomerService: SelectedCustomerService,
    protected gungFlowService: GungFlowService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig,
  ) { }

  getCarts(): Observable<Cart[]> {
    const url = 'json/carts?frontendVersion=ANGULAR2';
    const headers = {
      maxAge: '-1'
    };

    if (this.environment.savedCartsMode === 'customer') {
      return this.getCartsBelongingToSelectedCustomer(url, headers);
    } else if (this.environment.savedCartsMode === 'userAndCustomer') {
      return forkJoin({
        userCarts: this.getCartsBelongingToUser(url, headers),
        customerCarts: this.getCartsBelongingToSelectedCustomer(url, headers)
      }).pipe(
        switchMap(({ userCarts, customerCarts }) => {
          // Remove duplicates (based on id)
          const uniqueCarts = new Map<string, Cart>();
          userCarts.concat(customerCarts).forEach(cart => uniqueCarts.set(cart.id, cart));
          return of(Array.from(uniqueCarts.values()));
        })
      );
    } else {
      return this.getCartsBelongingToUser(url, headers);
    }
  }

  private getCartsBelongingToUser(url: string, headers: { maxAge: string }) {
    return this.http.get<Cart[]>(url, { headers }).pipe(
      first(),
      map(carts => this.mapCarts(carts))
    );
  }

  private getCartsBelongingToSelectedCustomer(url: string, headers: { maxAge: string }) {
    return this.selectedCustomerService.getSelectedCustomer().pipe(
      first(),
      switchMap(selectedCustomer =>
        this.http.get<Cart[]>(url + "&selectedCustomerId=" + selectedCustomer.id, { headers }).pipe(first())
      ),
      map(carts => this.mapCarts(carts))
    );
  }

  private mapCarts(carts: Cart[]): Cart[] {
    return carts
      .filter(cart => cart.extra.tag !== "QUOTE")
      .map(cart => {
        if (cart.version !== version) {
          cart = this.potentiallyMapFromOldStructure(cart);
        }
        if (!cart.data.rows && !!cart.extra.rows) {
          cart.data.rows = cart.extra.rows;
          delete cart.extra.rows;
        }
        return cart;
      });
  }

  getQuotes(): Observable<Cart[]> {
    const url = 'json/carts';
    const headers = {
      maxAge: '-1'
    };

    return this.http.get<Cart[]>(url, { headers }).pipe(
      map(quotes =>
        quotes
          .filter(quote => quote.extra.tag === 'QUOTE')
          .map(cart => {
            if (cart.version !== version) {
              cart = this.potentiallyMapFromOldStructure(cart);
            }
            if (!cart.data.rows && !!cart.extra.rows) {
              cart.data.rows = cart.extra.rows;
              delete cart.extra.rows;
            }
            return cart;
          })
      )
    );
  }

  saveCart(name: string, extra?: any): Observable<Cart> {
    const url = 'json/carts';
    return forkJoin({
      customer: this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      rows: this.cartService.getCurrentCart().pipe(first()),
      currentFlow: this.gungFlowService.getSelectedFlow().pipe(first())
    }).pipe(
      switchMap(({ customer, rows, currentFlow }: { customer: Customer, rows: CartRow[], currentFlow: GungFlow }) => this.http.post<Cart>(url, { name, version, customerId: customer.id, data: { rows }, extra, flowId: currentFlow.id }))
    );
  }

  saveQuotation(name: string, description: string): Observable<Cart> {
    let extra: any = {
      tag: 'QUOTE'
    };

    if (!!description) {
      extra = {
        ...extra,
        description
      };
    }

    const url = 'json/carts';
    return forkJoin({
      customer: this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      rows: this.cartService.getCurrentCart().pipe(first())
    }).pipe(
      switchMap(({ customer, rows }: { customer: Customer, rows: CartRow[] }) => this.http.post<Cart>(url, { name, version, customerId: customer.id, extra, data: { rows } }))
    );
  }

  updateCart(name?: string) {
    const url = 'json/carts';
    return this.cartService.getCurrentCart().pipe(
      first(),
      switchMap((rows: CartRow[]) => this.http.put<Cart>(url, { name, version, data: { rows } }))
    );
  }

  updateSavedCart(cart: Cart) {
    const url = 'json/carts';
    return this.http.post<Cart>(url, cart).pipe(tap(data => this.updateSavedCartsSubject()));
  }

  deleteCart(id: string): Observable<boolean> {
    const url = 'json/carts/' + id;
    return this.http.delete<boolean>(url);
  }

  getCart(id: string): Observable<Cart> {
    const url = 'json/carts/' + id;
    return this.http.get<Cart>(url);
  }


  updateSavedCartsSubject(): void {
    this.getCarts().subscribe((carts: Cart[]) => {
      if (this.carts) {
        this.carts.next(carts);
      }
    });
  }

  getSavedCartsFromSubject(): Observable<Cart[]> {
    return this.getCarts().pipe(
      switchMap((carts: Cart[]) => {
        this.carts = new BehaviorSubject<Cart[]>(carts);
        return this.carts.asObservable();
      })
    );
  }

  updateSavedQuotesSubject(): void {
    this.getQuotes().subscribe((quotes: Cart[]) => {
      if (this.quotes) {
        this.quotes.next(quotes);
      }
    });
  }

  getSavedQuotesFromSubject(): Observable<Cart[]> {
    return this.getQuotes().pipe(
      switchMap((quotes: Cart[]) => {
        this.quotes = new BehaviorSubject<Cart[]>(quotes);
        return this.quotes.asObservable();
      })
    );
  }

  setLoadedCardId(cartId) {
    sessionStorage.setItem('savedCard', cartId);
  }

  getLoadedCardId(): string {
    const cartId = sessionStorage.getItem('savedCard');
    return cartId;
  }

  removeLoadedCardId() {
    if (this.getLoadedCardId()) {
      sessionStorage.removeItem('savedCard');
    }
  }

  potentiallyMapFromOldStructure(cart: Cart): Cart {
    if (cart.data && Object.keys(cart.data).length > 0 && !cart.data.rows) {
      const cartRows = [];
      Object.values(cart.data).forEach(value => {
        if (value instanceof Object) {
          cartRows.push(...Object.values(value));
        }
      });
      cart.data = { rows: cartRows };
    }
    return cart;
  }
}
