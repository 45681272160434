import { Component } from '@angular/core';
import { CustomerGungAddressConfigService } from '../../services/customer-gung-address-config.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-customer-gung-address',
  templateUrl: './customer-gung-address.component.html',
  styleUrls: ['./customer-gung-address.component.css']
})
export class CustomerGungAddressComponent {
  constructor(
    public customerGungAddressConfigService: CustomerGungAddressConfigService,
    protected route: ActivatedRoute
  ) {
    customerGungAddressConfigService.setCustomerId(route.snapshot.params.customerId);
  }
}
