<div class="inline-search-container" [ngClass]="{ 'inline-search-container-mobile': isSmallScreen && resultsShow }">
    <div class="row">
      <div class="col">
        <div class="input-group inline-search d-none d-md-flex" [ngClass]="{ 'd-flex': resultsShow }">
          <input
            #searchInput
            type="text"
            class="form-control"
            [attr.placeholder]="'SEARCH' | translate"
            [formControl]="searchTermForm"
          />
          <div class="input-group-append">
            <span class="input-group-text d-none d-md-flex" (click)="search(searchInput.value)"
              ><i class="fas fa-search"></i
            ></span>
            <span
              class="input-group-text d-md-none"
              (click)="$event.stopPropagation(); $event.preventDefault(); searchInput.value = ''; resultsShow = false"
              ><i class="fas fa-times"></i
            ></span>
          </div>
        </div>
        <div class="inline-search-mobile d-md-none" [class.d-none]="isSmallScreen && resultsShow">
          <div class="input-group-append">
            <span class="input-group-text" (click)="resultsShow = true"><i class="fas fa-search"></i></span>
          </div>
        </div>
      </div>
    </div>
    <div class="inline-search-products" [class.d-none]="!resultsShow">
      <div class="row">
        <div class="col-6 border-divide">
          <!-- PRODUCTS -->
          <div class="result-title" translate>PRODUCTS</div>
          <div class="row">
            <div class="col-12 items-listing pr-0">
              <a
                *ngFor="let item of products"
                fastsearchSelect
                [routerLink]="item._path"
                (click)="$event.stopPropagation(); $event.preventDefault(); openProduct(item._path)"
              >
                <button type="button" class="btn btn-light btn-block btn-sm text-left">
                  <div class="row">
                    <div class="col-3 pr-0">
                      <img
                        alt="{{ item.name }}"
                        class="img-fluid"
                        *ngIf="item.extra.images && item.extra.images[0]; else noimage"
                        [src]="item.extra.images[0].s3Uri | gungImageUrl
                          :'type:list'
                          :'etag:'+item.extra.images[0].s3ETag
                        "
                      />
                      <ng-template #noimage>
                        <img
                          class="card-img-top img-fluid w-100"
                          [src]="'images/no-image.jpg' | gungImageUrl"
                          alt="NO IMAGE"
                          class="img-fluid mx-auto d-block"
                        />
                      </ng-template>
                    </div>
                    <div class="col-9 text-truncate">
                      <small class="d-block font-weight-bold">{{ item.id }}</small>
                      <span
                        *ngIf="
                          currentCustomer &&
                          item.extra._customerCrossReferences &&
                          item.extra._customerCrossReferences[currentCustomer.id]
                        "
                        class="d-block small"
                      >
                        {{ item.extra._customerCrossReferences[currentCustomer.id].referenceId }}
                      </span>
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </button>
              </a>
            </div>
            <div class="col-12" *ngIf="totalCount > resultsLimit">
              <button
                type="button"
                class="btn btn-light btn-block btn-sm btn-outline-secondary mt-1"
                (click)="showMoreProducts(searchInput)"
              >
                <small>{{ 'GUNG_PRODUCT_LIST_LOAD_MORE' | translate: { limit: resultsLimit, total: totalCount } }}</small>
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="result-title" translate><span translate>CATEGORIES</span></div>
          <!-- ASSORTMENTS / CONCEPTS -->
          <div class="row">
            <div class="col-12 items-listing px-0">
              <a
                *ngFor="let item of assortments | slice: 0:resultsLimit"
                fastsearchSelect
                [routerLink]="item._path"
                (click)="$event.stopPropagation(); $event.preventDefault(); openProduct(item._path)"
              >
                <button type="button" class="btn btn-light btn-block btn-sm text-left">
                  <div class="row">
                    <div class="col-3 pr-0">
                      <img
                        alt="{{ item.name }}"
                        class="img-fluid"
                        *ngIf="item.extra.images && item.extra.images[0]; else noimage"
                        [src]="item.extra.images[0].s3Uri | gungImageUrl
                          :'type:list'
                          :'etag:'+item.extra.images[0].s3ETag
                        "
                      />
                      <ng-template #noimage>
                        <img
                          class="card-img-top img-fluid w-100"
                          [src]="'images/no-image.jpg' | gungImageUrl"
                          alt="NO IMAGE"
                          class="img-fluid mx-auto d-block"
                        />
                      </ng-template>
                    </div>
                    <div class="col-9 text-truncate">
                      <small class="d-block font-weight-bold">{{ item.id }}</small>
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </button>
              </a>
            </div>
            <div class="col-12 mt-1" *ngIf="assortments.length > resultsLimit">
              <button
                type="button"
                class="btn btn-light btn-block btn-sm btn-outline-secondary"
                (click)="showMoreAssortments(searchInput)"
              >
                <small>{{
                  'GUNG_PRODUCT_LIST_LOAD_MORE' | translate: { limit: resultsLimit, total: assortments.length }
                }}</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  