import { Component } from '@angular/core';

@Component({
  selector: 'lib-adyen-payment-failed',
  templateUrl: './adyen-payment-failed.component.html',
  styleUrls: ['./adyen-payment-failed.component.css']
})
export class AdyenPaymentFailedComponent {

}
