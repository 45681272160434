<div class="planogram-wrapper clearfix" *ngIf="planogram">
  <div class="planogram-options no-print" [ngClass]="{ 'open-option': optionOpen }">
    <div class="clearfix">
      <div class="menu-options">
        <div class="option-menu mb-3" [routerLink]="['/planogram']">
          <div class="icon"><i class="fa-solid fa-arrow-left"></i></div>
          <div class="option-name" translate>BACK</div>
        </div>
        <div class="option-menu mb-3" (click)="selectMenu('modules')" [class.o-selected]="listSelected === 'modules'">
          <div class="icon"><i class="fa-solid fa-boxes-stacked"></i></div>
          <div class="option-name">
            <span *ngIf="listSelected !== 'modules'" translate>SHOW</span>
            <span *ngIf="listSelected == 'modules'" translate>HIDE</span>
            Modules
          </div>
        </div>
        <div
          class="option-menu mb-3"
          (click)="selectMenu('products')"
          [class.o-selected]="listSelected === 'products'"
          *ngIf="planogramConfig.showProductList"
        >
          <div class="icon"><i class="fa-solid fa-shop"></i></div>
          <div class="option-name">Products</div>
        </div>
      </div>
      <div class="option-open" *ngIf="optionOpen">
        <div [ngSwitch]="listSelected">
          <div *ngSwitchCase="'products'" class="drag-list">
            <lib-filter-list [configService]="productListConfig"></lib-filter-list>
          </div>
          <div *ngSwitchCase="'modules'" class="drag-list">
            <lib-filter-list [configService]="modulesListConfig"></lib-filter-list>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="planogram-grid" [ngClass]="{ 'reduce-grid': optionOpen }">
    <h1 class="planogram-name">
      {{ planogram.name }}
      <span class="cursor-pointer no-print" (click)="editPlanogram()"><i class="fa-solid fa-gear"></i></span>
    </h1>
    <div class="zoom-wrapper">
      <div *ngIf="!loadGrid" class="zoom-grid" [style.width.%]="zoom">
        <div class="head"></div>
        <div class="ruler text-center">
          <div class="mb-2">{{ width }} mm</div>
          <div class="ruler-points d-flex justify-content-between">
            <div class="ruler-point" *ngFor="let i of [].constructor(options.maxCols + 1); let index = index">
              <div class="ruler-value">{{ (width / options.maxCols) * index }}</div>
            </div>
          </div>
        </div>

        <gridster [options]="options">
          <div class="shelfs bg-primary"><span class="shelf-text">Shelfs</span></div>
          <gridster-item [item]="item" *ngFor="let item of data">
            <div class="item-otpions no-print">
              <i class="fa-solid fa-chevron-down"></i>
              <div class="show-item-options">
                <div class="show-item-option" (click)="removeItem($event, item)">Delete</div>
                <div class="show-item-option text-truncate" (click)="markAsNew(item)" *ngIf="!item.newModule" translate>
                  New
                </div>
                <div
                  class="show-item-option text-truncate"
                  (click)="markAsNotNew(item)"
                  *ngIf="item.newModule"
                  translate
                >
                  Remove New
                </div>
                <div
                  class="show-item-option text-truncate"
                  (click)="markAsExpired(item)"
                  *ngIf="!item.expired"
                  translate
                >
                  Expired
                </div>
                <div
                  class="show-item-option text-truncate"
                  (click)="removeAsExpired(item)"
                  *ngIf="item.expired"
                  translate
                >
                  Remove Expired
                </div>
                <div
                  class="show-item-option text-truncate"
                  (click)="expiredRemove(item)"
                  translate
                >
                  Expired and Remove
                </div>
                <div class="show-item-option text-truncate" (click)="toDragHelper(item)" *ngIf="dragHelper" translate>
                  To Drag Helper
                </div>
                <div
                  class="show-item-option text-truncate"
                  (click)="toLineToDragHelper(item)"
                  *ngIf="dragHelper"
                  translate
                >
                  All line to drag Helper
                </div>
              </div>
            </div>
            <div
              class="grid-item-content"
              [class.module-new]="item.newModule"
              [class.details-separated]="displayImageText"
              [class.module-expired]="item.expired"
            >
              <div class="grid-item-image" [class.image-botton]="!showText">
                <img
                  *ngIf="item.cols === 1"
                  [src]="item.data?.extra?.images[0].s3Uri | gungImageUrl
                    :'size:500x900'
                  "
                />
                <img
                  *ngIf="item.cols !== 1"
                  [src]="item.data?.extra?.images[0].s3Uri | gungImageUrl"
                />
              </div>
              <div class="grid-item-details" *ngIf="showText" #gridItemDetails>
                <div class="grid-item-name">
                  {{ item.data.name }}
                </div>
                <div [innerHTML]="item.data?.extra?.pim?.spaceManagementInformation"></div>
                <div *ngIf="item.data?.quantityInDisplay">{{ item.data?.quantityInDisplay }}</div>
                <div class="articles">
                  <div>ID:</div>
                  <div>[{{ item.data?.artNo }}]</div>
                  <div *ngIf="item.data?.floorOne && item.data?.floorOne.length > 0">
                    Col: <span *ngFor="let floorOne of item.data?.floorOne">{{ floorOne.id }} </span>
                  </div>
                  <div *ngIf="item.data?.floorTwo && item.data?.floorTwo.length > 0">
                    Col: <span *ngFor="let floorTwo of item.data?.floorTwo">{{ floorTwo.id }} </span>
                  </div>
                  <div *ngIf="item.data?.floorThree && item.data?.floorThree.length > 0">
                    Col: <span *ngFor="let floorThree of item.data?.floorThree">{{ floorThree.id }} </span>
                  </div>
                </div>
              </div>
            </div>
          </gridster-item>
        </gridster>
        <div class="adicional-products mt-3" *ngIf="planogram.additionalProducts">
          <span *ngFor="let additionalP of planogram.additionalProducts | keyvalue; let last = last">
            art no: {{ additionalP.key }} x {{ additionalP.value }}<span *ngIf="!last">,</span>
          </span>
        </div>
      </div>
    </div>
    <lib-planogram-drag-helper *ngIf="dragHelper" [droppedProduct]="droppedProduct"></lib-planogram-drag-helper>
  </div>

  <div class="plano-options justify-content-between p-2 no-print" [ngClass]="{ 'reduce-options': optionOpen }">
    <div>
      <button class="btn btn-sm btn-outline-primary mr-1" (click)="tootleDargHelper()" translate>
        <span translate>DRAG_HELPER</span>:<span *ngIf="dragHelper" translate>ON</span>
        <span *ngIf="!dragHelper" translate>OFF</span>
      </button>
      <div class="layouts">
        <button class="btn btn-sm btn-outline-primary" translate>
          <span translate>Layout</span>
        </button>
        <div class="layouts-list btn-outline-primary">
          <div (click)="pickLayout('text-over-image')">Text over Image</div>
          <div (click)="pickLayout('image')">Image</div>
          <div (click)="pickLayout('image-text')">Image and Text</div>
        </div>
      </div>
    </div>
    <div class="zoom">
      <button class="btn btn-sm btn-outline-secondary" (click)="zoomOut()">
        <i class="fa-solid fa-magnifying-glass-minus"></i>
      </button>
      <span class="p-2">{{ zoom }}</span>
      <button class="btn btn-sm btn-outline-secondary" (click)="zoomIn()">
        <i class="fa-solid fa-magnifying-glass-plus"></i>
      </button>
    </div>
    <div>
      <button class="btn btn-sm btn-outline-primary mr-1" (click)="printPage(planogram.id)" translate>
        <span translate>PRINT_PAGE</span>
      </button>
      <button class="btn btn-sm btn-primary" (click)="savePlanogram()" translate>
        <span translate>SAVE</span>
      </button>
    </div>
  </div>
</div>
