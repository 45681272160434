<div class="container">
    <div class="row">
        <div class="col-12">
            <h2 (click)="edit ? openModal('title') : undefined" [class.cursor-pointer]="edit">{{getText(data.elements.title.text)}}</h2>
            <button *ngIf="edit" (click)="addCategory()" type="button" class="btn btn-secondary btn-sm h-100 add-category"><i class="fa-solid fa-circle-plus"></i></button>
        </div>
    </div>
    <div class="row">
        <div class="col-6" *ngFor="let category of categories; let i = index; let isLast = last">
            <div class="m-1">
                <div class="row mb-2">
                    <div class="col-12 image-container" [ngStyle]="{ 'background-image': 'url(\'' + getImage(data.elements[category].image) + '\')' }">
                        <div class="d-flex align-items-end category-header">
                            <span class="h4" (click)="edit ? openModal(category) : undefined" [class.cursor-pointer]="edit" [style.color]="'' + getTextColor(data.elements[category].text) + ' !important'">
                                <span *ngIf="edit" (click)="$event.preventDefault(); $event.stopPropagation(); removeCategory(category)" class="mr-2">
                                    <i class="fa-solid fa-trash-can"></i>
                                </span>
                                {{ getText(data.elements[category].text) }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row p-3 border mb-1 cursor-pointer" *ngFor="let element of elements[category]; let idx = index" (click)="edit ? openModal(element) : navigateTo(data.elements[element].link)">
                    <div style="width: 200px;">
                        <div class="product-image-container image-container" [ngStyle]="{ 'background-image': 'url(\'' + getImage(data.elements[element].image) + '\')' }">
                        </div>
                    </div>
                    <div class="col d-flex align-items-center pl-4" style="font-size: 22px;">
                        {{getText(data.elements[element].text) }}
                    </div>
                    <div class="col-3 d-flex align-items-end justify-content-end">
                        <div>
                            <span translate>VIEW_MORE</span>
                            <i class="fa-solid fa-arrow-right-long ml-2"></i>
                        </div>
                    </div>
                    <div class="col-auto" *ngIf="edit">
                        <button type="button" class="btn btn-sm btn-danger h-100" (click)="$event.preventDefault(); $event.stopPropagation(); removeElement(element, category)">
                            <i class="fa-solid fa-trash-can"></i>
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="edit">
                    <div class="col-12">
                        <button type="button" class="btn btn-secondary w-100 mt-2" (click)="addElement(category)">
                            <i class="fa-solid fa-circle-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
