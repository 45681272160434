import { Component, OnInit } from '@angular/core';
import { CommonModalService } from 'gung-common';

@Component({
  selector: 'lib-planogram-email-pdf-export',
  templateUrl: './planogram-email-pdf-export.component.html',
  styleUrls: ['./planogram-email-pdf-export.component.css']
})
export class PlanogramEmailPdfExportComponent implements OnInit {

  email = '';
  delegate: CommonModalService;
  componentId: string;
  showText = true;

  ngOnInit() {
    this.componentId = PlanogramEmailPdfExportComponent.name;
  }

  confirm() {
    this.delegate.modalRef.get(this.componentId).close({ email: this.email, showText: this.showText });
  }

  cancel() {
    this.delegate.modalRef.get(this.componentId).dismiss();
  }
}
