<!-- <div [ngClass]="{'mt-4 mb-4' : products && products.length > 0}"> -->
  <owl-carousel-o [options]="customOptions">
  <ng-container  *ngFor="let data of products">
      <ng-template carouselSlide [id]="data.product.id">
          <div class="card product-card">
          <div class="card-body">
              <div>
                  <span class="favourite-icon-wrapper">
                    <lib-favourite-product-icon [id]="data.product.id"></lib-favourite-product-icon>
                  </span>
                </div>
              <div class="image-wrapper" [routerLink]="['/product/', data.product.id]">
                  <img class="img-fluid"
                      *ngIf="data.product.extra && data.product.extra.images && data.product.extra.images[0]"
                      [src]="data.product.extra.images[0].s3Uri | gungImageUrl
                        :'type:list'
                        :'etag:'+data.product.extra.images[0].s3ETag
                      "
                      alt="">
                  <div class="product-colors" *ngIf="data.product.extra.colors">
                      <div class="p-color" *ngFor="let color of data.product.extra.colors"
                          [ngStyle]="{'background-color': color}"></div>
                  </div>
              </div>
              <div class="product-text pb-1">
                  <div>{{ data.product.id }}</div>
                  <div class="text-truncate" translate>{{ data.product.name }}</div>
                  <div translate *ngIf="data.price" class="font-weight-bold">
                      {{ data.price.customerNetPrice.value | gungCurrency: data.price.customerNetPrice.currencyCode}}
                  </div>
                  <lib-availability [availability]="data.availability" [isSales]="isSales"
                      *ngIf="includeAvailability">
                  </lib-availability>
                  <div class="product-card-button">
                     <button *ngIf="data.product.extra?.pim?.viewOnly; else buyBtn"
                       class="btn btn-primary viewonly-btn" tabindex="-1" [routerLink]="['/product/', data.product.id]" translate>
                        VIEW_INFO
                     </button>
                     <ng-template #buyBtn>
                       <lib-buy-btn [id]="data.product.id"></lib-buy-btn>
                     </ng-template>
                  </div>
              </div>
          </div>
      </div>
      </ng-template>
  </ng-container>
</owl-carousel-o>
<!-- </div> -->