import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SalesSummaryEntity, SalesSummaryGroup, Timespan } from '../../../types';
import { startOfMonth, startOfWeek, startOfYear, subMonths, subWeeks, subYears } from 'date-fns';
import { Subject, forkJoin, of } from 'rxjs';
import { first } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardKpiService } from '../../../services/dashboard-kpi.service';
import { isMultiDimensionProduct, Product, ProductMultiDimension, ProductService } from 'gung-standard';

@Component({
  selector: 'lib-sales-summary-card',
  templateUrl: './sales-summary-card.component.html',
  styleUrls: ['./sales-summary-card.component.scss']
})
export class SalesSummaryCardComponent implements OnInit, AfterViewInit, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();

  @Input()
  preConditions: any;

  salesSummaryEntity = SalesSummaryEntity;
  timespan = Timespan;
  currentGroupOn: SalesSummaryEntity;
  currentTimespan: Timespan;
  showPreviousTimeSpan = false;

  salesSummaryGroups: SalesSummaryGroup[];
  previousTimespanSalesSummaryGroups: { [id: string]: SalesSummaryGroup };
  allowProductDetails = false;
  showDetails = [];
  details = [];

  public displayedColumns = ['name', 'totalQuantity', 'totalAmount'];
  public dataSource = new MatTableDataSource<SalesSummaryGroup>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected dashboardKpiService: DashboardKpiService,
    protected translateService: TranslateService,
    protected productService: ProductService
  ) {}

  ngOnInit() {
    this.currentGroupOn = SalesSummaryEntity.PRODUCT;
    this.currentTimespan = this.timespan.Year;

    this.fetchSalesSummaries();
  }

  protected fetchSalesSummaries() {
    const isToShowPreviousTimeSpan = this.getIsShowPreviousTimeSpan();

    const dateSpan = this.dashboardKpiService.getDateFromTimespan(this.currentTimespan);
    const previousDateSpan = this.dashboardKpiService.getDateFromTimespan(this.getPreviousTimeSpan());

    forkJoin({
      salesSummaryGroups: this.dashboardKpiService
        .getSalesSummary(this.currentGroupOn, dateSpan.fromDate, dateSpan.toDate, this.preConditions)
        .pipe(first()),
      previousSalesSummaryGroups: isToShowPreviousTimeSpan
        ? this.dashboardKpiService
            .getSalesSummary(
              this.currentGroupOn,
              previousDateSpan.fromDate,
              previousDateSpan.toDate,
              this.preConditions
            )
            .pipe(first())
        : of(null)
    }).subscribe(({ salesSummaryGroups, previousSalesSummaryGroups }) => {
      this.salesSummaryGroups = salesSummaryGroups;
      this.dataSource.data = this.salesSummaryGroups;

      // If is to show the previous values
      // create the data map for the previous values
      // the map will contains the data for the ids in the salesSummaryGroups (which is the dataSource of the table)
      // and the goal is to get the previous values against each table row (current time span)
      if (isToShowPreviousTimeSpan) {
        this.previousTimespanSalesSummaryGroups = {};

        for (const salesSummaryGroup of salesSummaryGroups) {
          const previousValue = previousSalesSummaryGroups.find(previous => previous.id === salesSummaryGroup.id);
          this.showDetails[salesSummaryGroup.id] = false;
          this.details[salesSummaryGroup.id] = { color: 'UNDEFINED', size: 'UNDEFINED', img: 'UNDEFINED' };
          /*  this.details[salesSummaryGroup.id] = {color: '', size: '', img: ''}; */
          if (!!previousValue) {
            this.previousTimespanSalesSummaryGroups[salesSummaryGroup.id] = previousValue;
          } else {
            this.previousTimespanSalesSummaryGroups[salesSummaryGroup.id] = {
              ...salesSummaryGroup,
              totalAmount: 0,
              totalQuantity: 0
            };
          }
        }
      }

      this.showPreviousTimeSpan = isToShowPreviousTimeSpan;
    });
  }

  seeDetails(product: SalesSummaryGroup) {
    this.showDetails[product.id] = true;
    this.productService
      .getProduct(product.id)
      .pipe(first())
      .subscribe((data: ProductMultiDimension) => {
        let img = '';
        if (data.extra.images && data.extra.images[0]) {
          img = data.extra.images[0].s3Uri;
        }
        if (isMultiDimensionProduct(data)) {
          this.details[data.id] = { color: data.primaryDimension[0].name, size: data.secondaryDimension[0].name, img };
        } else {
          this.details[data.id] = { color: 'UNDEFINED', size: 'UNDEFINED', img };
        }
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.sort.sort({ id: 'totalAmount', start: 'desc', disableClear: true });
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  chooseGroup(entity: SalesSummaryEntity) {
    this.currentGroupOn = entity;
    this.fetchSalesSummaries();
  }

  changeTimespan(newTimespan: Timespan) {
    this.currentTimespan = newTimespan;
    this.fetchSalesSummaries();
  }

  getPreviousTimeSpanLabel(): string {
    switch (this.currentTimespan) {
      case Timespan.Year:
        return this.translateService.instant('LAST_YEAR');

      case Timespan.Month:
        return this.translateService.instant('LAST_MONTH');

      case Timespan.Week:
        return this.translateService.instant('LAST_WEEK');
    }

    return null;
  }

  getPreviousTimeSpan(): Timespan {
    switch (this.currentTimespan) {
      case Timespan.Year:
        return Timespan.LastYear;

      case Timespan.Month:
        return Timespan.LastMonth;

      case Timespan.Week:
        return Timespan.LastWeek;
    }

    return null;
  }

  protected getIsShowPreviousTimeSpan(): boolean {
    return (
      this.currentTimespan === Timespan.Year ||
      this.currentTimespan === Timespan.Week ||
      this.currentTimespan === Timespan.Month
    );
  }
}
