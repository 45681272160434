import { Component, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { GungCustomerAddress, GungCustomerAddressService } from '../../services/gung-customer-address.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerAddGungAddressModalComponent } from '../customer-add-gung-address-modal/customer-add-gung-address-modal.component';

@Component({
  selector: 'lib-customer-gung-address-card-list',
  templateUrl: './customer-gung-address-card-list.component.html',
  styleUrls: ['./customer-gung-address-card-list.component.css']
})
export class CustomerGungAddressCardListComponent extends ListItemRendererComponent<GungCustomerAddress[]> implements AfterViewInit {

  public modalRef: NgbModalRef;

  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    size: 'lg',
    keyboard: true
  };

  constructor(
    protected ngModalService: NgbModal,
    protected gungCustomerAddressService: GungCustomerAddressService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  editDeliveryLocation(data: GungCustomerAddress) {
    this.modalRef = this.ngModalService.open(CustomerAddGungAddressModalComponent, this.modalOptions);
    this.modalRef.componentInstance.customerId = data.customerId;
    this.modalRef.componentInstance.gungAddress = data;
    this.modalRef.result.then(
      result => {},
      reason => {}
    );
  }

  deleteDeliveryLocation(data: GungCustomerAddress) {
    this.gungModalService
      .openConfirmYesNoModal(undefined, this.translationService.instant('REMOVE') + ' ' + data.name + '?', {
        size: 'sm'
      })
      .then(result => {
        if (result) {
          this.gungCustomerAddressService.deleteGungAddresses(data.customerId, data.id).subscribe();
        }
      });
  }
}
