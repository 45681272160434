import { Component, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { PlanogramAddItemsService } from '../../services/planogram-add-items.service';
import { ProductConcept } from '../../../../components/concept-detail-v2/concept-detail-v2.component';

@Component({
  selector: 'lib-planogram-module-grid',
  templateUrl: './planogram-module-grid.component.html',
  styleUrls: ['./planogram-module-grid.component.css']
})
export class PlanogramModuleGridComponent extends ListItemRendererComponent<ProductConcept[]> implements AfterViewInit {

  constructor(protected planogramAddItemsService: PlanogramAddItemsService){
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
  
  onDragStart(event, product) {
    this.planogramAddItemsService.draggedItem = product;
  }
}
