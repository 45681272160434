<div class="dashboard-card card" *ngIf="loaded">
  <div class="card-body dashboard-card-content">
    <div class="title" translate>SHORTCUTS</div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>CUSTOMERS</span>
      </div>
      <a [routerLink]="['/customers']" ><span class="current-statistic text-primary">{{totals.customers}}</span></a>
    </div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>OFFER</span>
      </div>
      <a [routerLink]="['/offers']" ><span class="current-statistic text-primary">{{totals.offers}}</span></a>
    </div>
    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>ORDER</span>
      </div>
      <a [routerLink]="['/open-orders']" ><span class="current-statistic text-primary">{{totals.orders}}</span></a>
    </div>
  </div>
</div>

<div class="dashboard-card card" *ngIf="!loaded">
  <div class="card-body dashboard-card-content">
    <div class="text-center dashboard-loading-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
