import { Component, forwardRef } from '@angular/core';
import { MetaSelectInputComponent } from '../meta-select-input/meta-select-input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lib-meta-select-input-horizontal',
  templateUrl: './meta-select-input-horizontal.component.html',
  styleUrls: ['./meta-select-input-horizontal.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaSelectInputHorizontalComponent),
      multi: true
    }
  ]
})
export class MetaSelectInputHorizontalComponent extends MetaSelectInputComponent { }
