import { Component, OnInit } from '@angular/core';
import { FavouriteProductsService, FavouriteProductIconComponent } from 'gung-standard';

@Component({
  selector: 'sjoborg-favourite-product',
  templateUrl: './sjoborg-favourite-product.component.html',
  styleUrls: ['./sjoborg-favourite-product.component.scss']
})
export class SjoborgFavouriteProductComponent extends FavouriteProductIconComponent implements OnInit {
  constructor(protected favouritesService: FavouriteProductsService) {
    super(favouritesService);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
