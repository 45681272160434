import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, throwError } from 'rxjs';

export interface CreditCheckAmout {
  creditLeft?: number;
}

@Injectable({
  providedIn: 'root'
})
export class JeevesCheckCreditService {
  constructor(protected http: HttpClient) {}

  public getAmountOfCredit(billingCustomerId: string): Observable<CreditCheckAmout> {
    const url = `json/jeeves/credit-check/${billingCustomerId}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '1800');
    return this.http.get<CreditCheckAmout>(url, { headers }).pipe(catchError(err => {
      if (Number(err.status) === 404) {
        return of({});
      } else {
        throwError(() => new Error(err));
      }
    }));
  }
}
