import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  SimpleConfigBaseFilter
} from 'gung-list';
import { Observable, first, forkJoin, map, of, switchMap } from 'rxjs';
import { Supplier, SupplierPurchaseOrder } from '../../models';
import { SelectedSupplierService } from '../selected-supplier/selected-supplier.service';
import { SupplierPoListConfigService } from '../supplier-po-list-config/supplier-po-list-config.service';
import { SupplierService } from '../suppliers/supplier.service';
import { MetadataService } from '../metadata/metadata.service';
import { SupplierPoAllListTableViewComponent } from '../../components/supplier/supplier-po-all-list-table-view/supplier-po-all-list-table-view.component';
import { ColumnSortSelectionService } from '../column-sort-selection/column-sort-selection.service';
import { SupplierSortColumnService } from '../../components/supplier/supplier-sort-column/supplier-sort-column.service';

export interface GroupedSupplierPurchaseOrder {
  [orderId: string]: SupplierPurchaseOrder[];
}

@Injectable({
  providedIn: 'root'
})
export class SupplierPoAllListConfigService extends SupplierPoListConfigService {
  topFilter = true;
  suppliers: Supplier[];

  constructor(
    protected supplierService: SupplierService,
    protected selectedSupplierService: SelectedSupplierService,
    protected metadataService: MetadataService,
    protected columnSortSelectionService: SupplierSortColumnService
  ) {
    super(supplierService, selectedSupplierService, columnSortSelectionService);
  }

  getItems(): Observable<SupplierPurchaseOrder[][] | any> {
    return this.supplierService.getBuyerPurchaseOrder().pipe(
      switchMap(dataAll => forkJoin([of(dataAll), this.supplierService.getSuppliers().pipe(first())])),
      map(([dataAll, suppliers]) => {
        this.suppliers = suppliers;
        const groupData: GroupedSupplierPurchaseOrder = {};
        for (const data of dataAll) {
          if (groupData[data.orderId]) {
            groupData[data.orderId].push(data);
          } else {
            groupData[data.orderId] = [data];
          }
        }
        return Object.values(groupData);
      })
    );
  }

  getFilters(): ConfigBaseFilter<SupplierPurchaseOrder>[] {
    return [
      new BuyerStatusFilter(this.metadataService),
      new BuyerSupplierFilter(this.suppliers),
      new BuyerLocationCodeFilter()
    ];
  }

  getSearchTerms(item: SupplierPurchaseOrder): string[] {
    let itemObj = item;
    if (Array.isArray(item) && item.length > 0) {
      const orderId = item.map(o => o.orderId || '');
      const productId = item.map(o => o.productId || '');
      const status = item.map(o => o.status || '');
      const description = item.map(o => o.extra.gungPurchaseLine?.description || '');
      const itemNo = item.map(
        o => o.extra.gungPurchaseLine?.itemNo + '-' + o.extra.gungPurchaseLine?.variantCode || ''
      );
      const locationCode = item.map(o => o.extra.gungPurchaseOrder?.locationCode || '');
      const noteofGoods = item.map(o => o.extra.gungPurchaseOrder?.noteofGoods || '');
      const dates = item.map(o => o.extra.gungPurchaseLine.requestedDeliveryDate);
      return [
        ...new Set(orderId),
        ...new Set(productId),
        ...[...new Set(status)].map(s =>
          s !== '' && s !== null && s !== undefined ? this.metadataService.getMetadataValue('POSTATUS', 'name', s) : s
        ),
        ...new Set(description),
        ...new Set(itemNo),
        ...new Set(locationCode),
        ...new Set(noteofGoods),
        ...new Set(dates)
      ];
    }
    return [
      itemObj?.orderId,
      itemObj?.productId,
      itemObj?.status,
      itemObj?.extra.gungPurchaseLine?.description,
      itemObj?.extra.gungPurchaseLine?.itemNo + '-' + itemObj?.extra.gungPurchaseLine?.variantCode,
      itemObj?.extra.gungPurchaseOrder?.locationCode,
      itemObj?.extra.gungPurchaseOrder?.noteofGoods
    ];
  }

  getItemId(item: SupplierPurchaseOrder): string {
    let itemObj = item;
    if (Array.isArray(item) && item.length > 0) {
      itemObj = item[0];
    }
    return itemObj?.id;
  }

  getLayouts(): ListLayout<SupplierPurchaseOrder>[] {
    return [
      {
        getIconClass: () => 'fas fa-th-list',
        getListItemComponent: () => SupplierPoAllListTableViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'SupplierPurchaseOrderTable'
      }
    ];
  }
}

export class BuyerStatusFilter extends SimpleConfigBaseFilter<SupplierPurchaseOrder> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getOptionIds(item: SupplierPurchaseOrder): string[] {
    return [item[0].status.toString()];
  }
  getOptionName(key: string): string {
    return this.metadataService.getMetadataValue('POSTATUS', 'name', key);
  }
  getName(): string {
    return 'STATUS';
  }
}

export class BuyerSupplierFilter extends SimpleConfigBaseFilter<SupplierPurchaseOrder> {
  constructor(private suppliers: Supplier[]) {
    super();
  }

  getOptionIds(item: SupplierPurchaseOrder): string[] {
    return [item[0].supplierId];
  }
  getOptionName(key: string): string {
    let name = key;
    const supIdx = this.suppliers?.findIndex(s => s.id === key);
    if (supIdx > -1) {
      return this.suppliers[supIdx].name;
    }
    return name;
  }
  getName(): string {
    return 'SUPPLIER';
  }
}

export class BuyerLocationCodeFilter extends SimpleConfigBaseFilter<SupplierPurchaseOrder> {
  constructor() {
    super();
  }

  getOptionIds(item: SupplierPurchaseOrder): string[] {
    if (Array.isArray(item) && item.length > 0) {
      const locationCode = item.map(o => o.extra.gungPurchaseOrder?.locationCode);
      return [...new Set(locationCode)];
    }
    return [item[0].extra.gungPurchaseOrder?.locationCode];
  }
  getOptionName(key: string): string {
    return key;
  }
  getName(): string {
    return 'LOCATION_CODE';
  }
}
