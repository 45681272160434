import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceStatisticsService<T> {
  initialized = false;
  invoices$: ReplaySubject<T[]> = new ReplaySubject<T[]>();

  constructor(protected http: HttpClient) {
    this.initializeService();
  }

  private initializeService(): void {
    if (this.initialized) {
      return;
    }

    this.initialized = true;

    const url = 'json/invoice-statistics';
    this.http.get<T[]>(url).subscribe(result => {
      this.invoices$.next(result);
    });
  }

  public getInvoices(): Observable<T[]> {
    return this.invoices$.asObservable();
  }
}
