import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ButtonsComponent } from '../../checkout/buttons/buttons.component';

@Component({
  selector: 'lib-bulk-checkout-buttons',
  templateUrl: './bulk-checkout-buttons.component.html',
  styleUrls: ['./bulk-checkout-buttons.component.css']
})
export class BulkCheckoutButtonsComponent extends ButtonsComponent implements OnChanges, OnInit, OnDestroy {
  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}