import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  CustomPageDisplayComponent,
  CustomPageResolverService,
  AuthGuard,
  LoginWrapperComponent,
  ProductPaginationListComponent,
  AssortmentTreeNavigationComponent
} from 'gung-standard';
import { SjoborgCustomPageDisplayComponent } from './components/sjoborg-custom-page-display/sjoborg-custom-page-display.component';
import { SjoborgJeevesOrderDetailsComponent } from './components/sjoborg-jeeves-order-details/sjoborg-jeeves-order-details.component';
import localeSv from '@angular/common/locales/sv';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SjoborgCheckoutWrapperComponent } from './components/checkout/sjoborg-checkout-wrapper/sjoborg-checkout-wrapper.component';
import { SjoborgProductDetailsComponent } from './components/sjoborg-product-details/sjoborg-product-details.component';
import { SjoborgHomeAssortmentComponent } from './components/sjoborg-home-assortment/sjoborg-home-assortment.component';
import { SjoborgRequestAccountComponent } from './components/sjoborg-request-account/sjoborg-request-account.component';
import { SjoborgLoginWrapperComponent } from './components/sjoborg-login-wrapper/sjoborg-login-wrapper.component';
import { SjoborgCustomerDetailsComponent } from './components/sjoborg-customer-details/sjoborg-customer-details.component';
import { SjoborgAssortmentTreeNavigationComponent } from './components/sjoborg-assortment-tree-navigation/sjoborg-assortment-tree-navigation.component';

@Component({
  selector: 'sjoborg-root',
  template: `<sjoborg-app-wrapper></sjoborg-app-wrapper>`,
  styles: []
})
export class SjoborgAppComponent {
  constructor(private router: Router, translate: TranslateService) {
    const lang = localStorage.getItem('selectedLanguage');
    translate.use(lang || 'se');
    registerLocaleData(localeSv);
    registerLocaleData(localeEn);
    this.setupRoutes();

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem('selectedLanguage', event.lang);
    });
  }

  private setupRoutes() {
    const routerConfig = this.router.config;
    this.router.resetConfig([
      // {
      //   path: '',
      //   component: SjoborgCustomPageDisplayComponent,
      //   resolve: {
      //     pageComponent: CustomPageResolverService,
      //   },
      //   data: {
      //     pageName: 'home',
      //     // pageComponent: HomeDefaultTemplateComponent
      //   },
      //   canActivate: [AuthGuard]
      // },
      {
        path: '',
        // component: SjoborgHomeAssortmentComponent,
        // canActivate: [AuthGuard]
        redirectTo: '/articles',
        pathMatch: 'full'
      },
      {
        path: 'request-account',
        component: SjoborgRequestAccountComponent
      },
      {
        path: 'forgot-password',
        component: LoginWrapperComponent,
        data: {
          login: 'FashionLogin' // NONE, 'FashionLogin', 'NavbarLogin'
        }
      },
      {
        path: 'login',
        component: SjoborgLoginWrapperComponent,
        data: {
          login: 'FashionLogin' // NONE, 'FashionLogin', 'NavbarLogin'
        }
      },
      {
        path: 'articles',
        canActivate: [AuthGuard],
        children: [
          {
            path: '**',
            component: SjoborgAssortmentTreeNavigationComponent
          }
        ]
      },
      {
        path: 'quick-products',
        canActivate: [AuthGuard],
        component: ProductPaginationListComponent
      },
      {
        path: 'orders/:orderId',
        component: SjoborgJeevesOrderDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'checkout',
        canActivate: [AuthGuard],
        component: SjoborgCheckoutWrapperComponent
      },
      {
        path: 'products',
        redirectTo: 'articles'
      },
      {
        path: 'product/:productId',
        component: SjoborgProductDetailsComponent,
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: 'customers/:customerId',
        component: SjoborgCustomerDetailsComponent,
        canActivate: [AuthGuard]
      },
      ...routerConfig
    ]);
  }
}
