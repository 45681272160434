import { Injectable } from '@angular/core';
import { Observable, first } from 'rxjs';
import { Pim } from '../../models/pim';
import { AuthService } from '../auth/auth.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { PimListConfigService } from '../pim-list-config/pim-list-config.service';
import { PimService } from '../pim/pim.service';

@Injectable({
  providedIn: 'root'
})
export class PimVariantsListConfigService extends PimListConfigService {
  constructor(
    protected pimService: PimService,
    protected gungModalService: GungModalService,
    protected authService: AuthService
  ) {
    super(pimService, gungModalService, authService);
  }

  getItems(): Observable<Pim[]> {
    return this.pimService.getPimProductsVariants().pipe(first());
  }
}
