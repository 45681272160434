import { Component } from '@angular/core';
import { ReplenishmentCustomerListConfigService } from '../../services/replenishment-customer-list-config.service';

@Component({
  selector: 'lib-replenishment-customer-list',
  template: '<lib-filter-list #list [configService]="configService"></lib-filter-list>',
  styleUrls: ['./replenishment-customer-list.component.css']
})
export class ReplenishmentCustomerListComponent {
  constructor(public configService: ReplenishmentCustomerListConfigService) {}
}
