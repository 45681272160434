import { Component, Inject, OnInit, Optional, Type } from '@angular/core';
import { RouterEventsService } from '../../../services/router-events/router-events.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GungLanguageConfigService } from 'gung-common';
import { GungListRouterEventsService } from 'gung-list';
import { TranslateService } from '@ngx-translate/core';
import { SelectedCustomerService } from '../../../services/selected-customer/selected-customer.service';
import { AuthService } from '../../../services/auth/auth.service';
import { NavigationConfig } from '../../../models/navigation-config';
import { first, takeUntil } from 'rxjs';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../state/state.module';
import { LoadCart } from '../../../state/cart/actions';
import { BaseViewConfigService } from '../../../services/base-view-config/base-view-config.service';
import { FooterComponent } from '../../footer/footer.component';

export enum GungLoadingState {
  LOADING,
  VALID,
  INVALID
}

@Component({
  selector: 'lib-gung-app',
  templateUrl: './gung-app.component.html',
  styleUrls: ['./gung-app.component.scss']
})
export class GungAppComponent implements OnInit {

  cookiesEnabled: boolean = this.environment.enableCookies;
  isLoading = true;
  errorOccured = false;
  loginRequired = true;
  protected states: { [identifier: string]: GungLoadingState } = {};
  public footerComponent: Type<FooterComponent>;

  cartListener = (e: StorageEvent) => {
    if (e.key.startsWith('cart_') && e.oldValue !== e.newValue) {
      this.store.dispatch(new LoadCart({ cartKey: e.key }));
    }
  };

  constructor(
    protected router: Router,
    protected store: Store<AppState>,
    protected route: ActivatedRoute,
    protected routerEventsService: RouterEventsService,
    protected gungLanguageConfigService: GungLanguageConfigService,
    protected gungListRouterEventsService: GungListRouterEventsService,
    protected translate: TranslateService,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig,
    protected baseViewConfigServicei: BaseViewConfigService
  ) {
    this.routerEventsService.setRouter(this.router);
    this.gungListRouterEventsService.setRouter(this.router);
    this.gungLanguageConfigService.selectLanguage();

    this.route.queryParams.subscribe(value => {
      let language = value['language'];
      if (!!language) translate.use(language);
    });
    this.footerComponent = this.baseViewConfigServicei.getFooterComponent();
  }

  ngOnInit(): void {
    this.authService.getIsAuthenticated().subscribe(authenticated => {
      this.loginRequired = !authenticated;

      if (!!authenticated) {
        this.subscribeToState();
        window.addEventListener('storage', this.cartListener, false);
      }

      this.updateAppState();
    });
  }

  protected subscribeToState() {
    this.subscribeCustomer();
    this.subscribeMetadata();
    this.subscribeFlow();
  }

  protected subscribeMetadata() {
    this.states.metadataLoaded = GungLoadingState.LOADING;
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe({
        next: roles => {

          if (roles.indexOf('ANONYMOUS') >= 0) {
            this.states.metadataLoaded = GungLoadingState.VALID;
            this.updateAppState();
          } else {
            this.metadataService.getMetadataCompleted().subscribe(_ => {
              this.states.metadataLoaded = GungLoadingState.VALID;
              this.updateAppState();
            });
          }
        },
        error: _ => {
          this.states.metadataLoaded = GungLoadingState.INVALID;
          this.updateAppState();
        }
      });
  }

  protected subscribeCustomer() {
    this.states.selectedCustomer = GungLoadingState.LOADING;
    this.store
      .select(state => state.customer)
      .subscribe({
        next: customerState => {
          if (customerState.doneLoading) {
            if (!!customerState.selectedCustomer) {
              this.states.selectedCustomer = GungLoadingState.VALID;
            } else {
              this.states.selectedCustomer = GungLoadingState.INVALID;
            }
          }

          this.updateAppState();
        },
        error: _ => {
          this.states.selectedCustomer = GungLoadingState.INVALID;
          this.updateAppState();
        }
      });
  }

  protected subscribeFlow() {
    this.states.selectedFlow = GungLoadingState.LOADING;
    this.store
      .select(state => state.gungFlow)
      .subscribe({
        next: gungFlowState => {
          if (gungFlowState.doneLoading) {
            if (!!gungFlowState.selectedFlow) {
              this.states.selectedFlow = GungLoadingState.VALID;
            } else {
              this.states.selectedFlow = GungLoadingState.INVALID;
            }
          }

          this.updateAppState();
        },
        error: _ => {
          this.states.selectedFlow = GungLoadingState.INVALID;
          this.updateAppState();
        }
      });
  }

  updateAppState() {
    const keys = Object.keys(this.states);

    const filteredKeys = keys.filter(key => {
      return this.states[key] === GungLoadingState.LOADING;
    });

    this.isLoading = filteredKeys.length !== 0;

    const invalidKeys = keys.filter(key => {
      return this.states[key] === GungLoadingState.INVALID;
    });
    const error = invalidKeys.length > 0;

    if (error) {
      console.error(`Error in the following keys: ${invalidKeys}`);
    }
  }

  logout() {
    this.authService.logout();
  }
}
