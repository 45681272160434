<div class="card mb-2">
  <div class="card-body">
    <div class="card-title row">
      <div class="col">
        <h5>
          {{ data.name }} - <small>{{ data.id }}</small> &nbsp;
          <!-- <small *ngIf="data.extra.kus.kreditsparr == '1'" class="text-warning" translate>CREDIT_BLOCKED</small> -->
        </h5>
      </div>
      <div class="col-auto">
        <lib-action-btn [item]="data" [config]="actionConfig"></lib-action-btn>
      </div>
    </div>
    <div class="card-text">
      <div class="row">
        <div class="col col-sm-6 col-md-4">
          <lib-gung-text-input
            [gungTranslate]="'ADDRESS'"
            [renderStatic]="true"
            [ngModel]="data.extra.fr.ftgpostadr2 || ''"
          ></lib-gung-text-input>
          <lib-gung-text-input [gungTranslate]="'ZIP'" [renderStatic]="true" [ngModel]="data.extra.fr.ftgpostnr || ''">
          </lib-gung-text-input>
        </div>
        <div class="col col-sm-6 col-md-4">
          <lib-gung-text-input
            [gungTranslate]="'CITY'"
            [renderStatic]="true"
            [ngModel]="data.extra.fr.ftgpostadr3 || ''"
          ></lib-gung-text-input>
          <lib-gung-text-input
            [gungTranslate]="'COUNTRY'"
            [renderStatic]="true"
            [ngModel]="data.extra.fr.landskod || ''"
          ></lib-gung-text-input>
        </div>
        <div class="col col-sm-6 col-md-4">
          <div *ngIf="data.extra.kus.saljare">
            <lib-gung-text-input
              [gungTranslate]="'SALESPERSON'"
              [renderStatic]="true"
              [ngModel]="metadataService.getMetadataValue('salj', 'saljarenamn', data.extra.kus.saljare)"
            >
            </lib-gung-text-input>
          </div>
          <div *ngIf="data.extra.fr.distrkod">
            <lib-gung-text-input
              [gungTranslate]="'DISTRICT'"
              [renderStatic]="true"
              [ngModel]="metadataService.getMetadataValue('salj', 'saljarenamn', data.extra.fr.distrkod)"
            >
            </lib-gung-text-input>
          </div>
          <lib-gung-text-input [gungTranslate]="'ORG_NR'" [renderStatic]="true" [ngModel]="data.extra.fr.orgnr || ''">
          </lib-gung-text-input>
        </div>
      </div>
    </div>
  </div>
</div>
