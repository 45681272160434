import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CustomerService, MetadataService } from 'gung-standard';
import { first } from 'rxjs';
import { DashboardDataService } from '../../../services/dashboard/dashboard-data.service';

@Component({
  selector: 'sjoborg-salesperson-select',
  templateUrl: './sjoborg-salesperson-select.component.html',
  styleUrls: ['./sjoborg-salesperson-select.component.scss']
})
export class SjoborgSalespersonSelectComponent implements OnInit {
  myFormGroup: any;
  saljare: string;
  saljareToCustomerIds: any;
  constructor(
    protected dashboardDataService: DashboardDataService,
    protected customerService: CustomerService,
    protected formBuilder: FormBuilder
  ) {
    this.saljare = dashboardDataService.selectedSalesPerson;
    this.saljareToCustomerIds = {};

    this.customerService
      .getCustomers()
      .pipe(first())
      .subscribe(customers => {
        customers.forEach(e => {
          const saljare = e.extra.kus.saljare;
          this.saljareToCustomerIds[saljare] = this.saljareToCustomerIds[saljare] || [];
          this.saljareToCustomerIds[saljare].push(e.id);
        });
        this.saljareToCustomerIds.ANÅH = ['1543'];
      });
  }

  ngOnInit(): void {
    this.myFormGroup = this.formBuilder.group({
      saljare: [{ disabled: false, value: this.saljare || '' }]
    });
  }

  salesPersonSelected(event) {
    let filter = null;
    const chosenSalesPersonCode = event.split(": ").length > 1 ? event.split(": ")[1] : event;
    this.dashboardDataService.selectedSalesPerson = chosenSalesPersonCode;
    if (event) {
      const customerIds = this.saljareToCustomerIds[chosenSalesPersonCode];
      if (customerIds) {
        filter = { required: { customerId: customerIds } };
      } else {
        filter = { required: { customerId: [] } };
      }
    }

    this.dashboardDataService.setFilter(filter);
  }
}
