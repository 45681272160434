import { Injectable } from '@angular/core';
import { Order, OrderService, SelectedCustomerService, MetadataService } from 'gung-standard';
import { ConfigService, ListLayout, ConfigBaseFilter, ListLayoutMultipleComponent } from 'gung-list';
import { Observable } from 'rxjs';
import { mergeMap, map, filter } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { JeevesOrderListConfigService } from '../jeeves-order-list-config/jeeves-order-list-config.service';
import { JeevesOpenOrderTableComponent } from '../../components/jeeves-open-order-table/jeeves-open-order-table.component';
import { CustomerService } from 'gung-standard';
import { DateUtilService } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class JeevesQuotesListConfigService extends JeevesOrderListConfigService {
  constructor(
    protected translationService: TranslateService,
    protected orderService: OrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService,
    protected dateUtilService: DateUtilService
  ) {
    super(translationService, orderService, selectedCustomerService, metadataService, customerService, dateUtilService);
  }

  getItems(): Observable<Order[]> {
    return super.getItems().pipe(map(orders => orders.filter((order: Order) => order.extra.oh.ordstat === 0)));
  }

  getFilters(): ConfigBaseFilter<Order>[] {
    return [];
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => JeevesOpenOrderTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Product grid'
      }
    ];
  }
}
