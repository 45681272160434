<div class="dashboard-card card" *ngIf="loaded">
  <div class="card-body dashboard-card-content">
    <div class="card-title">
      <div class="d-flex justify-content-between">
        <div>
          <span class="title" translate>ACTIVE_PRODUCTS</span> <br />
          <span class="sub-title" translate>PURCHASED_THIS_YEAR</span>
        </div>

        <span class="current-statistic text-primary">{{ amountOfUniqueOrderedProducts | number: '1.0-0' }}</span>
      </div>
    </div>
    <div class="progress-container">
      <ngb-progressbar [animated]="true" [value]="(amountOfUniqueOrderedProducts / amountOfUniqueProducts) * 100 || 0">
      </ngb-progressbar>
      <div class="d-flex justify-content-between">
        <span class="sub-title"
          >{{ ((amountOfUniqueOrderedProducts / amountOfUniqueProducts) * 100 || 0).toFixed() }}%</span
        >
        <span class="sub-title">
          {{ amountOfUniqueProducts | number: '1.0-0' }}
          <span style="text-transform: lowercase" translate>PRODUCTS_TOTAL</span></span
        >
      </div>
    </div>
  </div>
</div>

<div class="dashboard-card card" *ngIf="!loaded">
  <div class="card-body dashboard-card-content">
    <div class="text-center dashboard-loading-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
