import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CartLogoApplicatorModalComponent } from '../../components/cart-logo-applicator-modal/cart-logo-applicator-modal.component';
import { LogoApplication } from '../../models/logo-application';
import { CartRow } from '../../state/cart/types';

@Injectable({
  providedIn: 'root'
})
export class CartLogoApplicatorModalService {
  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();

  constructor(protected ngbModal: NgbModal) {}

  openLogoApplicatorModal(cartRow: CartRow, logoApplications: {}) {
    const id = CartLogoApplicatorModalComponent.name;

    const ref = this.ngbModal.open(CartLogoApplicatorModalComponent, {
      size: 'lg'
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.cartRow = cartRow;
    ref.componentInstance.logoApplications = logoApplications;
    ref.result.then(
      result => {},
      reason => {}
    );

    this.modalRef.set(id, ref);
  }
}
