<div class="table-responsive gung-responsive">
  <table class="table table-borderless table-striped">
    <thead>
      <tr>
        <th translate>INVOICE</th>
        <th translate>FH_FAKTSTKOD</th>
        <th translate>CUSTOMER_NAME</th>
        <th translate>INVOICE_DATE</th>
        <th translate>DUE_DATE</th>
        <th translate>VALUE</th>
        <th translate>RESIDUAL_AMOUNT</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let invoice of mappedInvoices" [ngClass]="classSelector(invoice.extra.fh.faktstkod)">
        <td [attr.data-label]="'INVOICE' | translate">
          {{ invoice.id }}
        </td>
        <td [attr.data-label]="'FH_FAKTSTKOD' | translate" translate>
          {{ getStatus('FH_FAKTSTKOD_', invoice.extra.fh.faktstkod) }}
        </td>
        <td [attr.data-label]="'CUSTOMER_NAME' | translate">
          {{ invoice.customer.name }}
        </td>
        <td [attr.data-label]="'INVOICE_DATE' | translate">
          {{ invoice.extra.fh.faktdat | date: dateUtilService.dateFormat }}
        </td>
        <td [attr.data-label]="'DUE_DATE' | translate">
          {{ invoice.extra.fh.faktffdat | date: dateUtilService.dateFormat }}
        </td>
        <td [attr.data-label]="'VALUE' | translate">
          {{ invoice.extra.fh.vb_faktsum | currency: invoice.extra.fh.valkod:'symbol-narrow' }}
        </td>
        <td [attr.data-label]="'RESIDUAL_AMOUNT' | translate">
          {{ invoice.extra.fh.vbfaktrest | currency: invoice.extra.fh.valkod:'symbol-narrow' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
