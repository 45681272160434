import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { Observable } from 'rxjs';
import { MatrixDiscount } from '../../models/matrix-discount';
import { MatrixDiscountsCardListComponent } from '../../components/matrix-discounts-card-list/matrix-discounts-card-list.component';
import { MatrixDiscountsService } from '../matrix-discounts/matrix-discounts.service';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
  providedIn: 'root'
})
export class MatrixDiscountListConfigService implements ConfigService<MatrixDiscount> {
  topFilter = true;
  constructor(protected matrixDiscountService: MatrixDiscountsService, protected metadataService: MetadataService) {}

  getItems(route?: ActivatedRouteSnapshot): Observable<MatrixDiscount[]> {
    return this.matrixDiscountService.getAllMatrixDiscount();
  }
  getFilters(route?: ActivatedRouteSnapshot): ConfigBaseFilter<MatrixDiscount>[] {
    return [
      new CustomerDiscountGroupFilter(this.metadataService, this.matrixDiscountService),
      new ProductDiscountGroupFilter(this.metadataService, this.matrixDiscountService)
    ];
  }
  getSortOptions(route?: ActivatedRouteSnapshot): ListSortOption<MatrixDiscount>[] {
    return [];
  }
  getBatchSizes(route?: ActivatedRouteSnapshot): number[] {
    return [24];
  }
  getLayouts(route?: ActivatedRouteSnapshot): ListLayout<MatrixDiscount>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => MatrixDiscountsCardListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'MatrixDiscount'
      }
    ];
    return [];
  }

  getSearchTerms(item: MatrixDiscount): string[] {
    return [item.id, item.customerDiscountGroup];
  }

  getItemId(item: MatrixDiscount): string {
    return item.id;
  }
}

export class CustomerDiscountGroupFilter extends SimpleConfigBaseFilter<MatrixDiscount> {
  constructor(protected metadataService: MetadataService, protected matrixDiscountService: MatrixDiscountsService) {
    super();
  }

  getName(): string {
    return 'CUSTOMERDISCOUNTGROUP';
  }

  getOptionIds(item: MatrixDiscount): string[] {
    return [item.customerDiscountGroup];
  }

  getOptionName(key: string): string {
    const metadataValue = this.metadataService.getMetadataValue(
      this.matrixDiscountService.customerDiscountMetadata.table,
      this.matrixDiscountService.customerDiscountMetadata.name,
      key
    );
    if (metadataValue) {
      return metadataValue;
    } else {
      return key;
    }
  }
}

export class ProductDiscountGroupFilter extends SimpleConfigBaseFilter<MatrixDiscount> {
  constructor(protected metadataService: MetadataService, protected matrixDiscountService: MatrixDiscountsService) {
    super();
  }

  getName(): string {
    return 'ITEMDISCOUNTGROUP';
  }

  getOptionIds(item: MatrixDiscount): string[] {
    return [item.productDiscountGroup];
  }

  getOptionName(key: string): string {
    const metadataValue = this.metadataService.getMetadataValue(
      this.matrixDiscountService.itemDiscountMetadata.table,
      this.matrixDiscountService.itemDiscountMetadata.name,
      key
    );
    if (metadataValue) {
      return metadataValue;
    } else {
      return key;
    }
  }
}
