<div *ngIf="!isLoading; else loading" class="h-100 d-flex flex-column">
  <div class="modal-header">
    <!-- <div>
      <h3>
        {{ product.name }} <span class="small">{{ product.id }}</span>
      </h3>
    </div> -->
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row mb-3 h-100">
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col" translate>PO_NUMBER</th>
                      <th scope="col" translate>ROW_INDEX</th>
                      <th scope="col" translate>PLANNED_DATE</th>
                      <th scope="col" translate>ITEM</th>
                      <th scope="col" translate>QUANTITY_ORDERED</th>
                      <th scope="col" translate>PRICE</th>
                      <th scope="col" translate>TOTAL_PRICE</th>
                      <th scope="col" translate>QUANTITY_CONFIRM</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" translate>ORIGINAL</th>
                      <td>{{originalSupplierPurchaseOrder.extra.bh.bestnr}}</td>
                      <td>{{originalSupplierPurchaseOrder.extra.bp.bestradnr}}</td>
                      <td>{{originalSupplierPurchaseOrder.extra.bp.bestlevdatfranlevskaj | date:dateUtilService.dateFormat}}</td>
                      <td>
                        {{originalSupplierPurchaseOrder.extra.bp.artnr}},
                        {{originalSupplierPurchaseOrder.extra.bp.artnrlev}},
                        {{originalSupplierPurchaseOrder.extra.bp.levsartbeskr}}
                      </td>
                      <td>{{(originalQty || originalSupplierPurchaseOrder.extra.bp.bestant) | number: '1.0-2'}}</td>
                      <td>
                        <lib-price [price]="originalSupplierPurchaseOrder.extra.bp.vb_inpris" [currency]="originalSupplierPurchaseOrder.extra.bh.valkod"></lib-price>
                      </td>
                      <td>
                        <lib-price [price]="originalSupplierPurchaseOrder.extra.bp.vbbestvalue" [currency]="originalSupplierPurchaseOrder.extra.bh.valkod"></lib-price>
                      </td>
                      <td>{{originalSupplierPurchaseOrder.extra.bp.bestant | number: '1.0-2'}}</td>
                    </tr>
                    <tr>
                        <th scope="row" translate>NEW</th>
                        <td>{{newSupplierPurchaseOrder.extra.bh.bestnr}}</td>
                        <td>{{newSupplierPurchaseOrder.extra.bp.bestradnr}}</td>
                        <td>
                          <lib-gung-date-picker
                              #buttonEl
                              [minInitialDate]="today"
                              [currentselectedDate]="newSupplierPurchaseOrder.extra.bp.bestlevdatfranlevskaj"
                              (selectedDate)="newSupplierPurchaseOrder.extra.bp.bestlevdatfranlevskaj = dateUtilService.getFormattedDateString($event)"
                              suppressLabel="true"
                              extraInputClass="form-control-sm"
                              extraBtnClass="btn-sm">
                          </lib-gung-date-picker>
                        </td>
                        <td>
                          {{newSupplierPurchaseOrder.extra.bp.artnr}},
                          {{newSupplierPurchaseOrder.extra.bp.artnrlev}},
                          {{newSupplierPurchaseOrder.extra.bp.levsartbeskr}}
                        </td>
                        <td>{{(originalQty || newSupplierPurchaseOrder.extra.bp.bestant) | number: '1.0-2'}}</td>
                        <td>
                          <lib-price [price]="newSupplierPurchaseOrder.extra.bp.vb_inpris" [currency]="newSupplierPurchaseOrder.extra.bh.valkod"></lib-price>
                        </td>
                        <td>
                          <lib-price [price]="newSupplierPurchaseOrder.extra.bp.vbbestvalue" [currency]="newSupplierPurchaseOrder.extra.bh.valkod"></lib-price>
                        </td>
                        <td>
                            <lib-gung-number-input 
                                [suppressLabel]="true"
                                [(ngModel)]="newInputQty"
                                (ngModelChange)="inputChanged($event)"
                                placeholder="0"
                                maxValue="{{originalQty - 1}}"
                                minValue="1"
                                extraInputClass="form-control-sm"></lib-gung-number-input>
                        </td>
                      </tr>
                  </tbody>
            </table>
          </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn btn-outline-primary text-uppercase" (click)="activeModal.dismiss('Cancel click')" translate>
        CANCEL
      </button>
    <button type="button" class="btn btn-sm btn-primary text-uppercase" (click)="split()">
      {{ 'SPLIT' | translate }}
    </button>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
