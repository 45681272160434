import { Injectable } from '@angular/core';
import {
  AuthService,
  ProductService,
  ProductListConfigService,
  Product,
  ProductArticleClassFilterService,
  ProductSortService,
  GungFlowService,
  LocationConfigService,
  CloudPimFiltersService,
  CurrentAssortmentService
} from 'gung-standard';
import { ListLayout, ConfigBaseFilter, ListLayoutMultipleComponent } from 'gung-list';
import { ProductItemGroupFilterService } from '../filters/product-item-group-filter.service';
import { JeevesProductTableComponent } from '../../components/jeeves-product-table/jeeves-product-table.component';
import { JeevesProductGridViewComponent } from '../../components/jeeves-product-grid-view/jeeves-product-grid-view.component';

@Injectable({
  providedIn: 'root'
})
export class JeevesProductListConfigService extends ProductListConfigService {
  constructor(
    protected authService: AuthService,
    protected productService: ProductService,
    protected productItemGroupFilterService: ProductItemGroupFilterService,
    protected productArticleClassFilterService: ProductArticleClassFilterService,
    protected productSortService: ProductSortService,
    protected gungFlowService: GungFlowService,
    protected locationConfigService: LocationConfigService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected currentAssortmentService: CurrentAssortmentService
  ) {
    super(productService, authService, productSortService, gungFlowService, cloudPimFiltersService, currentAssortmentService);
  }

  getFilters(): ConfigBaseFilter<Product>[] {
    return [this.productArticleClassFilterService, this.productItemGroupFilterService];
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => 'fa fa-th',
        getListItemComponent: () => JeevesProductGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      },
      {
        getIconClass: () => 'fa fa-list',
        getListItemComponent: () => JeevesProductTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductList'
      }
    ];
  }

  setPreviousLayout(index: number) {
    this.locationConfigService.addLayout({
      path: 'current-layout', // Save the current layout when click layout
      layoutIndex: index
    });
  }

  getPreviousLayout(): number {
    const defaultLayout = this.locationConfigService.getLayout('products'); // Get previous layout from products
    this.locationConfigService.removeLayout(defaultLayout);
    if (defaultLayout && defaultLayout.layoutIndex) {
      this.setPreviousLayout(defaultLayout.layoutIndex);
    }
    return Number((defaultLayout && defaultLayout.layoutIndex) || 0);
  }

  getAddQueryParameterLimitToUrl(): boolean {
    return true;
  }
}
