import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ProductionOrder } from '../../models/production-order';

@Injectable({
  providedIn: 'root'
})
export class ProductionOrderService {
  private productionOrderSubject: BehaviorSubject<ProductionOrder[]>;

  constructor(protected http: HttpClient) {}

  getProductionOrder(id: string): Observable<ProductionOrder> {
    return this.http.get<ProductionOrder>(`json/production/orders/${id}`);
  }

  getProductionOrders(nocache?: boolean): Observable<ProductionOrder[]> {
    if (!this.productionOrderSubject) {
      this.productionOrderSubject = new BehaviorSubject<ProductionOrder[]>([]);
      this.updateProductionOrderSubject(nocache);
    }
    return this.productionOrderSubject.asObservable();
  }

  updateProductionOrderSubject(nocache?: boolean): void {
    if (!this.productionOrderSubject) {
      this.productionOrderSubject = new BehaviorSubject<ProductionOrder[]>([]);
    }
    this.internalGetAll(nocache).subscribe((data: ProductionOrder[]) => this.productionOrderSubject.next(data));
  }

  protected internalGetAll(nocache?: boolean): Observable<ProductionOrder[]> {
    const url = `json/production/orders`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<ProductionOrder[]>(url, { headers });
  }

  postProductionOrder(productionOrder: ProductionOrder): Observable<ProductionOrder> {
    const url = `json/production/orders`;
    return this.http
      .post<ProductionOrder>(url, productionOrder)
      .pipe(tap(_ => this.updateProductionOrderSubject(true)));
  }
}
