import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StripePaymentService {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: {
      maxAge: '-1'
    }
  };

  getStripePayment(entityId: string, entityType: StripePaymentEntityType): Observable<StripePayment> {
    return this.http.get<StripePayment>(
      `json/stripe/payments?entityId=${entityId}&entityType=${entityType}`,
      this.httpOptions
    );
  }
}

export enum StripePaymentEntityType {
  ORDER = 'ORDER',
  INVOICE = 'INVOICE'
}

export interface StripePayment {
  clientReferenceId: string;
  entityId: string;
  entityType: StripePaymentEntityType;
  stripeReference: string;
  status: string;

  createdAt: Date;
  updatedAt: Date;
}
