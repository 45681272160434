import { Injectable } from '@angular/core';
import { Order } from '../../../models/order';
import {
  RequirementsPlanningOrder,
  RequirementsPlanningOrderType,
  RequirementsPlanningProductData
} from '../models/product-requirements';
import { addDays, format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class ProductionManagementSuggestedOrderToRequestBodyMapperService {
  constructor() {}

  map(order: RequirementsPlanningOrder[], productData: RequirementsPlanningProductData, extra?): Order {
    if (order[0].orderType === RequirementsPlanningOrderType.PRODUCTION_ORDER) {
      return this.mapProductionOrder(order[0], productData);
    } else if (order[0].orderType === RequirementsPlanningOrderType.PURCHASE_ORDER) {
      return this.mapPurchaseOrder(order, productData, extra);
    }
    return null;
  }

  private mapProductionOrder(order: RequirementsPlanningOrder, productDate: RequirementsPlanningProductData): Order {
    // This below is the fortnox mapping. Since at the time of writing, this logic is
    // planned to only run for Fortnox customers, I put it here. In the future if more
    // ERPs require this functionallity, break this out to Fortnox.
    const mappedOrder: unknown = {
      extra: {
        ProductionOrder: {
          itemId: order.productId,
          startDate: format(Date.parse(order.orderDate), 'yyyy-MM-dd'),
          productionDate: addDays(Date.parse(order.orderDate), productDate.leadTime),
          quantity: order.quantity
        }
      },
      rows: []
    };
    return mappedOrder as Order;
  }

  private mapPurchaseOrder(
    orders: RequirementsPlanningOrder[],
    productData: RequirementsPlanningProductData,
    extra
  ): Order {
    // This below is the fortnox mapping. Since at the time of writing, this logic is
    // planned to only run for Fortnox customers, I put it here. In the future if more
    // ERPs require this functionallity, break this out to Fortnox.
    const mappedOrder: unknown = {
      deliveryCustomerId: productData.product.extra?.Article?.SupplierNumber,
      extra: {
        PurchaseOrder: {
          orderDate: format(Math.max(...orders.map(order => Date.parse(order.orderDate))), 'yyyy-MM-dd'),
          ...extra
        }
      },
      rows: orders.map(order => (
        {
          id: order.productId,
          quantity: order.quantity
        }
      ))
    };

    return mappedOrder as Order;
  }
}
