<div *ngIf="data; else loading" class="table-responsive gung-responsive">
  <table class="table">
    <thead>
      <tr>
        <th></th>
        <th translate>CUSTOMER</th>
        <th translate>ACTIVITY_TYPE</th>
        <th translate>STATUS</th>
        <th translate>RESPONSIBLE</th>
        <th translate>ACTIVITY_DATE</th>
        <th translate>MODIFY_DATE</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data">
        <td style="width: 25px">
          <lib-item-selection-button [item]="item" selectionText="" [showBorder]="false"></lib-item-selection-button>
        </td>
        <td [attr.data-label]="'CUSTOMER' | translate">{{ item.customerId }} - {{ item.customerName }}</td>
        <td [attr.data-label]="'ACTIVITY_TYPE' | translate">
          {{
          metadataService.getMetadataValue(
          'ACTIVITYINTERNALTYPE',
          'name',
          item.type
          )
          }}
        </td>
        <td [attr.data-label]="'STATUS' | translate">
          <div class="text-nowrap" [ngClass]="{ 'w-150': item._showEdit }">
            <span *ngIf="!item._showEdit" class="badge rounded-pill" translate>
              {{
              metadataService.getMetadataValue(
              'ACTIVITYINTERNALSTATUS',
              'name',
              item.status + '@ACTIVITYINTERNALSTATUS'
              ) || item.status
              }}
            </span>
            <div class="row">
              <div class="col pr-0 pl-1">
                <lib-meta-data-select-input #metaDataInput class="mb-0" *ngIf="item._showEdit" [(ngModel)]="item.status"
                  table="ACTIVITYINTERNALSTATUS" metaId="id" metaDisplay="name" prefix="@ACTIVITYINTERNALSTATUS"
                  suppressLabel="true" hideEmptyOption="true"></lib-meta-data-select-input>
              </div>
              <div class="col-auto pl-0 pr-1">
                <button #saveBtn *ngIf="item._showEdit" type="button" class="btn btn-info"
                  (click)="item._showEdit = !item._showEdit; saveStatus(item)">
                  <i class="far fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </td>
        <td [attr.data-label]="'RESPONSIBLE' | translate">
          <lib-gung-meta-output
            [value]="item.customerResponsible"
            [gungTranslate]="'CUSTOMER_RESPONSIBLE'"
            [suppressLabel]="true"
            [metadataTable]="activityInternalCustomerResponsibleService.metadataTable"
            [metaOptionName]="activityInternalCustomerResponsibleService.metadataName"
            [showIfNotFound]="true"
            [showOnlyText]="true"
          ></lib-gung-meta-output>
        </td>
        <td [attr.data-label]="'ACTIVITY_DATE' | translate">
          {{ item.activityDate | date: dateUtilService.dateFormat
          }}
        </td>
        <td [attr.data-label]="'MODIFY_DATE' | translate">
          {{ item.updatedAtDate | date: dateUtilService.dateFormat
          }}
        </td>
        <td [attr.data-label]="'BUY' | translate">
          <button type="button" class="btn btn-sm btn-light" (click)="openViewModal(item)">
            <i class="fas fa-comments"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
  LOADING...
</ng-template>