import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { first, switchMap } from 'rxjs';
import { AuthService, Customer, CustomerContactService, SelectedCustomerService } from 'gung-standard';
import { JeevesDeliveryAddressesService, JeevesDeliveryAddressStepComponent, JeevesModalService } from 'gung-standard-jeeves';

@Component({
  selector: 'sjoborgs-delivery-address-step',
  templateUrl: './sjoborgs-delivery-address-step.component.html',
  styleUrls: ['./sjoborgs-delivery-address-step.component.scss']
})
export class SjoborgsDeliveryAddressStepComponent extends JeevesDeliveryAddressStepComponent implements OnInit {
  selectedCustomer: Customer;
  contacts = [];
  ohForm: FormGroup;

  @ViewChild('contactInput', { static: false }) contactInput!: ElementRef;
  isDropdownOpen: boolean = false;

  constructor(
    protected formBuilder: FormBuilder,
    protected selectedCustomerService: SelectedCustomerService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService,
    protected authService: AuthService,
    protected customerContactService: CustomerContactService,
    protected modalService: JeevesModalService
  ) {
    super(formBuilder, selectedCustomerService, jeevesDeliveryAddressesService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(
        first(),
        switchMap(selectedCustomer => {
          this.selectedCustomer = selectedCustomer;
          return this.customerContactService.getCustomerContacts(selectedCustomer.id).pipe(first())
        }
        )
      )
      .subscribe(contacts => {
        this.contacts = contacts;
      });
  }

  protected initForm() {
    super.initForm();

    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => {
        this.ohForm = this.formBuilder.group({
          godsmarke1: this.formBuilder.control(this.checkout.extra.oh.godsmarke1 || ''),
          kundbestnr: this.formBuilder.control(this.checkout.extra.oh.kundbestnr || ''),
          kundref2: this.formBuilder.control(this.checkout.extra.oh.kundref2 || user.name || ''),
          editext: this.formBuilder.control(this.checkout.extra.oh.editext || '')
        });
      });
  }

  protected assignAddressValues() {
    super.assignAddressValues();

    this.checkout.extra.oh = {
      ...this.checkout.extra.oh,
      ...this.ohForm.getRawValue()
    };

    console.log(this.checkout);
  }

  toggleDropdown(open: boolean) {
    this.isDropdownOpen = open;
  }

  onSelectContact(contact: any, event: MouseEvent) {
    this.ohForm.get('kundref2').setValue(contact.name || contact.email);
    this.toggleDropdown(false);
    this.contactInput.nativeElement.blur();
  }

  onInput(event: any) {
    this.ohForm.get('kundref2').setValue(event.target.value);
    this.toggleDropdown(true);
  }

  public isAvailableToAdd(email: string) {
    return !!email && this.checkout.extra.confirmationEmails.indexOf(email) === -1;
  }

  public addContactToRecipients(email: string) {
    this.checkout.extra.confirmationEmails.push(email);
  }

  public addNewContact() {
    this.modalService.openAddContactModalAsPromise(this.selectedCustomer).then(
      result => {
        if (result) {
          this.contacts.push(result);
        }
      },
      reason => {}
    );
  }
}
