import { Component, AfterViewInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ListItemRendererComponent } from 'gung-list';
import { MatrixDiscount } from '../../models/matrix-discount';
import { MatrixDiscountsService } from '../../services/matrix-discounts/matrix-discounts.service';
import { MatrixDiscountModalComponent } from '../matrix-discount-modal/matrix-discount-modal.component';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { MetadataService } from '../../services/metadata/metadata.service';
import { MatrixDiscountModalService } from '../../services/matrix-discounts-modal/matrix-discount-modal.service';

@Component({
  selector: 'lib-matrix-discounts-card-list',
  templateUrl: './matrix-discounts-card-list.component.html',
  styleUrls: ['./matrix-discounts-card-list.component.css']
})
export class MatrixDiscountsCardListComponent extends ListItemRendererComponent<MatrixDiscount[]> implements AfterViewInit {
  constructor(
    public metadataService: MetadataService,
    protected matrixDiscountService: MatrixDiscountsService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    protected matrixDiscountModalService: MatrixDiscountModalService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  onEdit(matrixDiscount: MatrixDiscount) {
    this.matrixDiscountModalService.openModal(matrixDiscount);
  }

  onDelete(matrixDiscount: MatrixDiscount) {
    this.gungModalService
      .openConfirmYesNoModal(
        this.translationService.instant('DELETE') + ' ' + matrixDiscount.id,
        this.translationService.instant('CONFIRM_DELETION', { value: matrixDiscount.id }),
        { size: 'sm' },
        'YES',
        'NO'
      )
      .then(result => {
        if (result) {
          this.matrixDiscountService.deleteMatrixDiscount(matrixDiscount.id).subscribe(_ => {});
        }
      });
  }

  openAddNew() {
    this.matrixDiscountModalService.openModal();
  }
}
