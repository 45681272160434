import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HubspotOwner } from '../../models/hubspot-owner';
import { HubspotProperty } from '../../models/hubspot-property';

@Injectable({
  providedIn: 'root'
})
export class HubspotPropertiesService {
  constructor(protected http: HttpClient) {}

  public getAllPropertiesFromType(objectType: string): Observable<any> {
    const url = `json/hubspot/properties/${objectType}`;
    return this.http.get<any>(url, {});
  }

  public getPropertyFromType(objectType: string, propertyName: string): Observable<HubspotProperty> {
    const url = `json/hubspot/properties/${objectType}/${propertyName}`;
    return this.http.get<HubspotProperty>(url, {});
  }
}
