import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { first, switchMap } from 'rxjs';
import { AuthService, CustomerService, LocationConfigService, RouterEventsService, NavbarConfigService, NavigationConfig } from 'gung-standard';
import {
  JeevesCustomerActionConfigService,
  JeevesCustomerDeliveryLocationListConfigService,
  JeevesCustomerDetailsComponent,
  JeevesDeliveryAddressesService,
  JeevesOrderDeliveryAddress
} from 'gung-standard-jeeves';

@Component({
  selector: 'sjoborg-customer-details',
  templateUrl: './sjoborg-customer-details.component.html',
  styleUrls: ['./sjoborg-customer-details.component.scss']
})
export class SjoborgCustomerDetailsComponent extends JeevesCustomerDetailsComponent implements OnInit {
  isSales = false;
  public deliveryAddresses: JeevesOrderDeliveryAddress[];
  public deliveryAddressesSelectedIndex = -1;

  constructor(
    route: ActivatedRoute,
    customerService: CustomerService,
    public configService: JeevesCustomerActionConfigService,
    public jeevesCustomerDeliveryLocationListConfigService: JeevesCustomerDeliveryLocationListConfigService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService,
    protected authService: AuthService,
    routerEventsService: RouterEventsService,
    locationConfigService: LocationConfigService, navbarConfigService: NavbarConfigService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig
  ) {
    super(
      route,
      customerService,
      configService,
      jeevesCustomerDeliveryLocationListConfigService,
      authService,
      routerEventsService,
      locationConfigService,
      navbarConfigService,
      environment
    );
  }

  ngOnInit() {
    const routeParams = this.route.snapshot.params;
    if (routeParams.customerId != null) {
      this.customerService
        .getCustomer(routeParams.customerId)
        .pipe(
          first(),
          switchMap(customer =>
            forkJoin({
              customer: of(customer),
              deliveryAddresses: this.jeevesDeliveryAddressesService.getOrderedDeliveryAddressesForCustomer(customer.id)
            })
          )
        )
        .subscribe(({ customer, deliveryAddresses }) => {
          this.customer = customer;
          this.deliveryAddresses = deliveryAddresses;

          if (this.deliveryAddresses.length > 0) {
            this.onDeliveryAddressSelected(0);
          }
        });
    }
    this.authService.getCurrentUser().subscribe(user => {
      this.isActuator = user.roles.indexOf('ACTUATOR') > -1;
      this.isSales = user.roles.indexOf('ACTUATOR') > -1 || user.roles.indexOf('ADMIN') > -1 || user.roles.indexOf('SALES') > -1;
    });
  }

  onDeliveryAddressSelected(index: any) {
    this.deliveryAddressesSelectedIndex = index;
  }
}
