import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GungBase } from '../models';
import { OrderRow } from '../models/orderRow';

@Injectable({
  providedIn: 'root'
})
export class DeliveryHistoryService {
  constructor(protected http: HttpClient) {}

  public getDeliveriesByOrderId(orderId: string): Observable<Delivery[]> {
    return this.http.get<Delivery[]>(`json/deliveries/list-by-order-id/${orderId}`);
  }
}

export interface Delivery extends GungBase {
  rows: OrderRow;
}
