import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { OrderPortalEntry, OrderPortalMessage } from '../../models/order-portal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GptErrorExplanationService } from '../../../../services/gpt-error-explanation/gpt-error-explanation.service';

@Component({
  selector: 'lib-order-portal-status-modal',
  templateUrl: './order-portal-status-modal.component.html',
  styleUrls: ['./order-portal-status-modal.component.scss']
})
export class OrderPortalStatusModalComponent {
  entry: OrderPortalEntry;
  messages: ModalMessage[];

  setEntry(entry: OrderPortalEntry) {
    this.entry = entry;
    this.messages = this.convertToInternalMessageFormat(this.entry.messages);
  }

  constructor(public activeModal: NgbActiveModal, protected gptErrorExplanationService: GptErrorExplanationService) {}

  public getErrorExplanation(message: ModalMessage): void {
    message.loadingExplanation = true;
    this.gptErrorExplanationService.getErrorExplanation(message.message).subscribe((explanation: string) => {
      // @ts-ignore
      message.gptExplanation = explanation;
      message.loadingExplanation = false;

      // This is to force the Angular change detection to run.
      this.messages = [...this.messages];
    });
  }

  public convertToInternalMessageFormat(messages: OrderPortalMessage[]): ModalMessage[] {
    return messages.map(m => ({ ...m, gptExplanation: undefined, loadingExplanation: false }));
  }
}

interface ModalMessage extends OrderPortalMessage {
  gptExplanation?: string;
  loadingExplanation: boolean;
}
