import {Component, OnInit, AfterViewInit} from '@angular/core';
import {ListItemRendererComponent} from 'gung-list';
import {EdiMessageBase, EdiPurchaseOrder} from '../../../models/edi';
import {
  EdiPurchasedOrderActionConfigService
} from "../../../services/edi-purchased-order-action-config/edi-purchased-order-action-config.service";

@Component({
  selector: 'lib-edi-purchased-order-card-list',
  templateUrl: './edi-purchased-order-card-list.component.html',
  styleUrls: ['./edi-purchased-order-card-list.component.css']
})
export class EdiPurchasedOrderCardListComponent extends ListItemRendererComponent<EdiPurchaseOrder[]> implements OnInit, AfterViewInit {
  selectedEdiOrders: EdiMessageBase[] = [];

  constructor(public actionConfigService: EdiPurchasedOrderActionConfigService) {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  selectEdiOrder(order: EdiMessageBase, select: boolean) {
    const idx = this.selectedEdiOrders.findIndex(o => o.id === order.id);
    if (select && idx === -1) {
      this.selectedEdiOrders.push(order);
    } else if (!select && idx > -1) {
      this.selectedEdiOrders.splice(idx, 1);
    }
  }

  trackByFn(index: any, item: any) {
    return item.documentReferences.buyerOrder.id + '' + item.erpIds;
  }
}
