import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GungListModule } from 'gung-list';

import { GungCommonModule } from 'gung-common';
import { TranslateModule } from '@ngx-translate/core';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgbModule,
    GungListModule,
    GungCommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedRoutingModule,
    TranslateModule,
    NgChartsModule
  ],
  exports: [
    CommonModule,
    NgbModule,
    GungListModule,
    GungCommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgChartsModule
  ]
})
export class SharedModule {}
