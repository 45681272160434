<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>
    <span *ngIf="gungAddress" translate>EDIT_DELIVERY_LOCATION</span>
    <span *ngIf="!gungAddress" translate>ADD_DELIVERY_LOCATION</span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="crossClick()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" *ngIf="deliveryLocationForm">
  <div class="form" [formGroup]="deliveryLocationForm">

    <div class="form-row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="name" class="control-label" translate>NAME</label>
          <input class="form-control" type="text" name="name" id="name" formControlName="name" />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="street" class="control-label"><span translate>ADDRESS</span> 1</label>
          <input class="form-control" type="text" name="street" id="street" formControlName="address1" />
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="address" class="control-label"><span translate>ADDRESS</span> 2</label>
          <input class="form-control" type="text" name="address" id="address" formControlName="address2" />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="city" class="control-label" translate>CITY</label>
          <input class="form-control" type="text" name="city" id="city" formControlName="city" />
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="cityCode" class="control-label" translate>ZIP_CODE</label>
          <input class="form-control" type="text" name="cityCode" id="cityCode" formControlName="zipCode" />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="country" class="control-label" translate>COUNTRY</label>
          <lib-meta-select-input
            formControlName="countryCode"
            table="ISOCountries2"
            metaId="alpha-2"
            metaDisplay="name"
            [suppressLabel]="true"
          >
          </lib-meta-select-input>
          <!-- <input class="form-control" type="text" name="country" id="citcountry" formControlName="country"> -->
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="phone" class="control-label" translate>PHONE</label>
          <input class="form-control" type="text" name="phone" id="phone" formControlName="phoneNumber" />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="email" class="control-label" translate>EMAIL</label>
          <input class="form-control" type="text" name="email" id="email" formControlName="email" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary ml-auto" (click)="submitForm()" [disabled]="isAddingContact" translate>
    {{ btnString }}
  </button>
</div>
