import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { GungImageUrlService, S3Item, capitalizeFirstLetter } from 'gung-common';
import { NavigationConfig } from '../../models/navigation-config';

@Component({
  selector: 'lib-image-carousel-minimal',
  templateUrl: './image-carousel-minimal.component.html',
  styleUrls: ['./image-carousel-minimal.component.css']
})
export class ImageCarouselMinimalComponent implements OnInit {
  @Input()
  images: S3Item[];

  @Input()
  orientation: 'portrait' | 'landscape' = null;

  private _imageSize: string = this.gungImageUrlService.defaultListThumbnailOptions.size;
  get imageSize() {
    return this._imageSize;
  }
  @Input('imageSize')
  set imageSize(imageSize: string) {
    this._imageSize = imageSize;
    this.calculateSizeImg(imageSize);
  }
  public width: string;
  public height: string;

  @Input()
  interval: number = 0;

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  currentSlide: string = '0';

  @Input()
  imageClass: string;

  @Input()
  public disableProductListImageCarousel: boolean = this.environment.disableProductListImageCarousel

  constructor(
    protected gungImageUrlService: GungImageUrlService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig
  ) { }

  ngOnInit(): void {
    if (this.orientation) {
      this.imageSize = this.gungImageUrlService.defaultListThumbnailOptions['size' + capitalizeFirstLetter(this.orientation)];
    }
    
    this.calculateSizeImg();
  }

  onSlide(slideEvent: NgbSlideEvent) {
    this.currentSlide = slideEvent.current;
	}

  calculateSizeImg(imageSize = this.imageSize) {
    if (imageSize.includes('x')) {
      [this.width, this.height] = imageSize.split('x');
    }
  }
}
