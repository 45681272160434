import { Component, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractSelectionService, FilterListComponent } from 'gung-list';
import { SupplierPurchaseOrder } from '../../../models';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { SupplierPoListConfigService } from '../../../services/supplier-po-list-config/supplier-po-list-config.service';
import { SupplierPoSelectionService } from '../../../services/supplier-po-selection/supplier-po-selection.service';

@Component({
  selector: 'lib-supplier-po-list',
  template: `
    <div class="row">
      <div class="col-12 text-center">
        <h3 *ngIf="purchaseOrderType">
          {{ metadataService.getMetadataValue('POSTATUS', 'name', purchaseOrderType) | titlecase | translate }}
        </h3>
      </div>
    </div>
    <lib-filter-list #list [configService]="listConfigService"></lib-filter-list>
  `,
  styles: [],
  providers: [
    {
      provide: AbstractSelectionService,
      useExisting: forwardRef(() => SupplierPoSelectionService)
    }
  ]
})
export class SupplierPoListComponent implements OnInit {
  @ViewChild('list') list: FilterListComponent<SupplierPurchaseOrder>;
  purchaseOrderType: string;

  constructor(
    public listConfigService: SupplierPoListConfigService,
    public route: ActivatedRoute,
    public metadataService: MetadataService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.purchaseOrderType = params.get('purchaseOrderType');
      if (!!this.purchaseOrderType) {
        this.listConfigService.purchaseOrderType.next(this.purchaseOrderType);
      } else {
        this.listConfigService.purchaseOrderType.next(null);
      }
    });
  }
}
