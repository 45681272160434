import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceStatisticsHelperService {
  constructor(protected http: HttpClient) {}

  getInactiveCustomers(activeWithinMonths: number, inactiveMonths: number): Observable<object[]> {
    const url = 'json/invoice-statistics/fetch-inactive-customers';
    let params = new HttpParams();
    params = params.append('inactiveMonths', inactiveMonths.toString());
    params = params.append('activeWithinMonths', activeWithinMonths.toString());

    return this.http.get<object[]>(url, {
      params
    });
  }

  getInactiveCustomersMonthOptions(): Observable<object[]> {
    return of([
      {
        months: 1,
        text: 'ONE_MONTH'
      },
      {
        months: 2,
        text: 'TWO_MONTHS'
      },
      {
        months: 3,
        text: 'THREE_MONTHS'
      },
      {
        months: 5,
        text: 'FIVE_MONTHS'
      },
      {
        months: 12,
        text: '1_YEAR'
      },
      {
        months: 24,
        text: '2_YEARS'
      }
    ]);
  }
}
