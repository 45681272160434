import { Component, OnInit } from '@angular/core';
import { JeevesProductGridViewComponent } from 'gung-standard-jeeves';
import { AuthService, GungFlowService, LocationConfigService, PriceService } from 'gung-standard';
import { Router } from '@angular/router';

@Component({
  selector: 'sjoborg-product-grid-view',
  templateUrl: './sjoborg-product-grid-view.component.html',
  styleUrls: ['./sjoborg-product-grid-view.component.scss']
})
export class SjoborgProductGridViewComponent extends JeevesProductGridViewComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

  getPackageSize(item: any): number {
    if (!!item.extra.ar.artfsgforp) {
      return parseInt(item.extra.ar.artfsgforp, 10);
    }

    return 0;
  }
}
