<div class="row">
  <div class="col-12">
    <h4 translate>IMPORT_BUDGET_DOCUMENT</h4>
    <div
      class="panel drop-box ng-pristine ng-untouched ng-valid ng-empty dragover"
      style="background: #ffffff; border: 5px dashed #ddd; text-align: center; padding: 50px 10px; margin-left: 10px"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      (click)="fileInput.click()"
    >
      <label
        ><strong>Add file(s) by clicking on</strong
        ><span class="box__dragndrop"> or dragging to this area</span>.</label
      >
      <br />
      <span
        ><small class="" *ngFor="let file of fileInput.files || fileInput.files; let last = last"
          >{{ file?.name }}{{ !last ? ', ' : '' }}</small
        ></span
      >
      <input
        hidden
        #fileInput
        type="file"
        name="files[]"
        id="file"
        [disabled]="isLoading ? true : null"
        (change)="onDrop($event)"
        accept=""
        multiple
      />
    </div>
  </div>
</div>
<div class="row mt-2">
  <div class="col-12 col-md">
    <b>SELECTED_DOCUMENT: </b>
    <span *ngIf="filesDocuments && filesDocuments.length > 0 && filesDocuments[0] as document">{{
      document.name
    }}</span>
  </div>
  <div class="col-auto">
    <button type="button" class="btn btn-primary" [disabled]="isLoading ? true : null" (click)="importFile()" translate>
      IMPORT <i *ngIf="isLoading" class="fas fa-spinner fa-spin"></i>
    </button>
  </div>
</div>
<div class="row" *ngIf="importResult">
  <div class="col-12">
    <div class="alert alert-warning" role="alert">
      {{ importResult }}
    </div>
  </div>
</div>
