<div class="table-responsive gung-responsive">
  <table class="table">
    <thead>
      <tr>
        <th translate>STATUS</th>
        <th translate>ID</th>
        <th translate>ORKK_KUNDREKLAMATIONBESKR</th>
        <th translate>CLAIMWORKFLOW_CLAIMWORKFLOWDESCR</th>
        <th translate>NOTE</th>
        <th translate>ORDERNR</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data">
        <td [attr.data-label]="'STATUS' | translate" class="text-dark">
          <lib-gung-meta-output
            [horizontal]="true"
            [gungTranslate]="''"
            [suppressLabel]="true"
            [value]="item.extra.ork.claimactivitystatus"
            metadataTable="claimactivitystatus"
            metaOptionName="claimactivitystatusdescr"
          ></lib-gung-meta-output>
        </td>
        <td [attr.data-label]="'ID' | translate" class="text-dark">
          {{ item.extra.ork.rmanr }}
        </td>

        <td [attr.data-label]="'ORKK_KUNDREKLAMATIONBESKR' | translate">
          <lib-gung-meta-output
            [horizontal]="true"
            [gungTranslate]="''"
            [suppressLabel]="true"
            [value]="item.extra.ork.kundreklamationorsak"
            metadataTable="orkk"
            metaOptionName="kundreklamationbeskr"
          ></lib-gung-meta-output
          ><!-- kundreklamationorsak -->
        </td>
        <td [attr.data-label]="'CLAIMWORKFLOW_CLAIMWORKFLOWDESCR' | translate">
          <lib-gung-meta-output
            [horizontal]="true"
            [gungTranslate]="''"
            [suppressLabel]="true"
            [value]="item.extra.ork.claimworkflow"
            metadataTable="claimworkflow"
            metaOptionName="claimworkflowdescr"
          ></lib-gung-meta-output
          ><!-- claimworkflow -->
        </td>
        <td [attr.data-label]="'NOTE' | translate">
          {{ item.extra.ork.edit | slice: 0:40 }} {{ item.extra.ork.edit?.length > 40 ? '...' : '' }}
        </td>
        <td [attr.data-label]="'ORDERNR' | translate">
          {{ item.extra.ork.ordernr }}
        </td>
        <td>
          <button type="button" class="btn btn-outline-primary" (click)="viewClaim(item, $event)">
            <i class="fas fa-info"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
