import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first, map } from 'rxjs';
import { LoginModalComponent } from '../../components/login-modal/login-modal.component';
import { User } from '../../models';
import { AuthService } from '../../services/auth/auth.service';
import { AuthGuard } from '../authguard/auth-guard';

@Injectable({
  providedIn: 'root'
})
export class AnonymousWithLoginOptionGuard extends AuthGuard  {
  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected http: HttpClient,
    protected modalService: NgbModal
  ) {
    super(authService, router, http, modalService);
  }

  protected async checkLogin(redirectUrl: string, rolesAllowed?: string[]): Promise<boolean> {
    const authenticated1 = await this.authService.getIsAuthenticated().pipe(first()).toPromise();
    const isAnonymousOld = authenticated1
      ? await this.authService.getHasRoles('ANONYMOUS').pipe(first()).toPromise()
      : true;
    const headers: { [s: string]: string | string[] } = {};
    headers.maxAge = '-1';
    if (isAnonymousOld) {
      return true;
    }
    const isAnonymousNew = await this.http
      .get<User>('user', { headers })
      .pipe(
        first(),
        map(user => user.details.username === '_GUNG_ANONYMOUS')
      )
      .toPromise();
    if (!isAnonymousOld && isAnonymousNew) {
      return await this.modalService.open(LoginModalComponent, { size: 'lg', backdrop: 'static', keyboard: true })
        .result;
    }
    return super.checkLogin(redirectUrl, rolesAllowed);
  }
}
