<div>
  <h3 translate class="page-title text-center">PRODUCTION_ORDERS</h3>
  <div class="row">
    <div class="col-12 text-right">
      <button type="button" class="btn btn-primary" [ngClass]="{'active': isShowList}" (click)="showList()"><i class="fa-regular fa-list"></i></button>
      <button type="button" class="btn btn-primary" [ngClass]="{'active': isShowCalendar}" (click)="showCalendar()"><i class="fal fa-calendar-alt"></i></button>
      <button type="button" class="btn btn-primary" [ngClass]="{'active': isShowStatistics}" (click)="showStatistics()"><i class="fal fa-chart-simple"></i></button>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <lib-production-orders-list *ngIf="isShowList"></lib-production-orders-list>

      <lib-production-order-gung-calendar *ngIf="isShowCalendar"></lib-production-order-gung-calendar>

      <div *ngIf="isShowStatistics">
        <img style="max-width: 1200px;" class="img-responsive" [src]="'demo/waldner-statistics.png' | gungImageUrl" alt="">
      </div>


    </div>
  </div>
</div>
