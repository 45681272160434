<div *ngIf="order">
  <div class="d-flex flex-row justify-content-between align-items-center mb-4 mt-4">
    <div>
      <h2 *ngIf="order.extra.oh.ordstat !== 0">Order {{ order.id }}</h2>
      <h2 *ngIf="order.extra.oh.ordstat === 0">Quote {{ order.id }}</h2>
      <h6 *ngIf="order.extra.deliveryCustomer">{{ order.extra.deliveryCustomer.name }}</h6>
    </div>
    <div class="form-group">
      <lib-action-btn [item]="order" [config]="configService"></lib-action-btn>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      <lib-gung-accordion>
        <lib-gung-accordion-item>
          <ng-template libGungAccordionHeader>{{ 'DETAILS_AND_CONDITIONS' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <div class="row">
              <div class="col col-xs-12 col-md-6">
                <h5 translate>DETAILS</h5>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="ORDER_NUMBER"
                  [(ngModel)]="order.extra.oh.ordernr"
                >
                </lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="YOUR_REFERENCE_NO"
                  [(ngModel)]="order.extra.oh.kundbestnr"
                ></lib-gung-text-input-horizontal>
                <lib-gung-meta-output
                  [value]="order.extra.oh.ordstat"
                  [gungTranslate]="'ORDER_STATUS'"
                  metadataTable="x7"
                  metaOptionName="ordstatbeskr"
                  [horizontal]="true"
                ></lib-gung-meta-output>
                <lib-gung-date-output
                  [horizontal]="true"
                  [gungTranslate]="'ORDER_DATE'"
                  [value]="order.extra.oh.orddatum"
                >
                </lib-gung-date-output>
                <lib-gung-date-output
                  [horizontal]="true"
                  [gungTranslate]="'ESTIMATED_DELIVERY_DATE'"
                  [value]="order.extra.oh.ordberlevdat"
                >
                </lib-gung-date-output>
              </div>

              <div class="col col-xs-12 col-md-6">
                <h5 translate>CONDITIONS</h5>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'PAYMENT_TERMS'"
                  [value]="order.extra.oh.betkod"
                  metadataTable="x2"
                  metaOptionName="betvillbeskr"
                >
                </lib-gung-meta-output>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'DELIVERY_TERMS'"
                  [value]="order.extra.oh.levvillkkod"
                  metadataTable="x2e"
                  metaOptionName="levvillkbeskr"
                >
                </lib-gung-meta-output>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'DELIVERY_METHOD'"
                  [value]="order.extra.oh.levsattkod"
                  metadataTable="x2f"
                  metaOptionName="levsattbeskr"
                >
                </lib-gung-meta-output>
              </div>
            </div>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item>
          <ng-template libGungAccordionHeader>{{ 'SHIPPING_INFORMATION' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <div class="row">
              <div class="col col-12">
                <h5 translate>ADDRESS</h5>
              </div>
              <div class="col col-xs-12 col-md-6">
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="CUSTOMER_REFERENCE"
                  [(ngModel)]="order.extra.oh.kundref2"
                ></lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="ADDRESS1"
                  [(ngModel)]="order.extra.oh.ordlevadr2"
                ></lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="ADDRESS2"
                  [(ngModel)]="order.extra.oh.ordlevadr3"
                ></lib-gung-text-input-horizontal>
              </div>
              <div class="col col-xs-12 col-md-6">
                <lib-gung-text-input-horizontal
                  renderStatic="true"
                  gungTranslate="CITY"
                  [(ngModel)]="order.extra.oh.ordlevadr4"
                ></lib-gung-text-input-horizontal>
                <lib-gung-text-input-horizontal
                  *ngIf="order.extra.oh.ftgpostnr"
                  renderStatic="true"
                  gungTranslate="ZIP"
                  [(ngModel)]="order.extra.oh.ftgpostnr"
                >
                </lib-gung-text-input-horizontal>
                <lib-gung-meta-output
                  [horizontal]="true"
                  [gungTranslate]="'COUNTRY'"
                  [value]="order.extra.oh.ordlevadrlandskod"
                  metadataTable="xr"
                  metaOptionName="land"
                >
                </lib-gung-meta-output>
              </div>
            </div>
          </ng-template>
        </lib-gung-accordion-item>
        <lib-gung-accordion-item>
          <ng-template libGungAccordionHeader>{{ 'ADDITIONAL_INFORMATION' | translate }}</ng-template>
          <ng-template libGungAccordionBody>
            <div class="form-group row">
              <label for="oh.editext" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4" translate
                >MESSAGE</label
              >
              <div class="col">
                <textarea
                  class="form-control"
                  type="text"
                  name="editext"
                  id="oh.editext"
                  disabled="true"
                  [(ngModel)]="order.extra.oh.editext"
                ></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label for="oh.edit" class="col-form-label text-right-sm text-muted col-xs-12 col-sm-4" translate
                >INTERNAL_TEXT</label
              >
              <div class="col">
                <textarea
                  class="form-control"
                  type="text"
                  name="edit"
                  id="oh.edit"
                  disabled="true"
                  [(ngModel)]="order.extra.oh.edit"
                ></textarea>
              </div>
            </div>
          </ng-template>
          </lib-gung-accordion-item>
          </lib-gung-accordion>
    </div>
  </div>

  <div class="row">
    <div class="col-12 table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th translate>PRODUCT_ID</th>
            <th translate>NAME</th>
            <th translate>ESTIMATED_DELIVERY_DATE</th>
            <th translate>QUANTITY</th>
            <th translate>QUANTITY_DELIVERED</th>
            <th translate>QUANTITY_BACKORDER</th>
            <th translate>UNIT_PRICE</th>
            <th translate>DISCOUNT</th>
            <th translate>TOTAL_PRICE</th>
            <th *ngIf="order.extra.oh.ordstat === 0 && isSales"></th>
            <th></th>
          </tr>
        </thead>
        <tbody *ngIf="order">
          <tr *ngFor="let row of order.rows" [ngClass]="row.extra.orp.ordradst === 90 ? 'deleted' : ''">
            <td>
              <a routerLink="/product/{{ row.productId }}">{{ row.productId }}</a>
            </td>
            <td>
              <a routerLink="/product/{{ row.productId }}">
                <span *ngIf="row.extra.orp.artbeskr">
                  {{ row.extra.orp.artbeskr }}
                </span>
                <span *ngIf="this.products && !row.extra.orp.artbeskr">
                  {{ this.products[row.productId].name }}
                </span>
              </a>
            </td>
            <td>
              {{ row.extra.orp.ordberlevdat | date: 'yyyy-MM-dd' }}
            </td>
            <td [attr.data-label]="'QUANTITY' | translate">
              {{ row.quantity }}
            </td>
            <td [attr.data-label]="'QUANTITY_DELIVERED' | translate">
              {{ row.extra.orp.ordlevantal }}
            </td>
            <td [attr.data-label]="'QUANTITY_BACKORDER' | translate">
              {{ row.extra.orp.ordrestant }}
            </td>
            <td>
              {{ row.extra.orp.vb_pris | gungCurrency: order.extra.oh.valkod:'symbol-narrow':'1.2-2' }}
            </td>
            <td>{{ row.extra.orp.rabatt1 | number: '1.0-2' }}%</td>
            <td>
              {{ row.extra.orp.vbordradsum | gungCurrency: order.extra.oh.valkod:'symbol-narrow':'1.2-2' }}
            </td>
            <td *ngIf="isOrderEditable(order, isSales)">
              <gung-jeeves-edit-orderrow-modal (orderRowChange)="orderUpdated($event)" [orderRow]="row">
              </gung-jeeves-edit-orderrow-modal>
            </td>
            <td class="text-right">
              <sjoborg-product-download-pdf [productId]="row.productId"></sjoborg-product-download-pdf>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12" *ngIf="isOrderEditable(order, isSales)">
      <button class="btn btn-primary btn-block" (click)="addRow()" translate>ADD_ROW</button>
    </div>
  </div>
</div>
