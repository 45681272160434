import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GungNotificationService } from 'gung-common';
import { first } from 'rxjs';

@Component({
  selector: 'lib-statistics-excel-export-card',
  templateUrl: './statistics-excel-export-card.component.html',
  styleUrls: ['./statistics-excel-export-card.component.scss']
})
export class StatisticsExcelExportCardComponent {
  constructor(protected http: HttpClient, protected gungNotificationService: GungNotificationService) {}

  generateExcel(): void {
    this.http
      .post<any>('/json/report-central/generate-statistics-excels', undefined)
      .pipe(first())
      .subscribe(result => {
        this.gungNotificationService.notify(
          'Export started succesfully',
          `To view the status and download the resulting files, click here.`,
          'success',
          {
            routerLink: ['report-central'],
            queryParams: {
              search: `DEFAULT__:__${result.id}`
            }
          }
        );
      });
  }

  private getNotificationBody(result: any): string {
    return;
  }
}
