import { Injectable } from '@angular/core';
import { CustomerService } from 'gung-standard';
import { Observable, forkJoin } from 'rxjs';
import { first, map } from 'rxjs';
import { DeliveryLocationsService } from './delivery-locations/delivery-locations.service';

export interface JeevesOrderDeliveryAddress {
  addressValues: {
    ordlevadr1: string;
    ordlevadr2: string;
    ordlevadr3: string;
    ordlevadr4: string;
    ftgpostnr: string;
    ordlevadrbstort?: string;
    ordlevadrlandskod: string;
    ordlevplats1?: string;
  };
  source: 'deliveryLocation' | 'customerAddress';
}

@Injectable({
  providedIn: 'root'
})
export class JeevesDeliveryAddressesService {
  constructor(
    protected deliveryLocationsService: DeliveryLocationsService,
    protected customerService: CustomerService
  ) {}

  public getOrderedDeliveryAddressesForCustomer(customerId: string): Observable<JeevesOrderDeliveryAddress[]> {
    return forkJoin([
      this.customerService.getCustomer(customerId, false).pipe(first()),
      this.deliveryLocationsService.getDeliveryLocationsForCustomer(customerId).pipe(first())
    ]).pipe(
      map(([customer, deliveryLocations]) => {
        const primaryDeliveryLocation = customer.extra.kus.ordlevplats1;
        const result: JeevesOrderDeliveryAddress[] = [];

        if (primaryDeliveryLocation && deliveryLocations) {
          const deliveryLocation = deliveryLocations.find(
            location => location.extra.lp.ordlevplats1 === primaryDeliveryLocation
          );
          if (deliveryLocation) {
            result.push(
              this.getMappedFr(deliveryLocation.extra.fr, 'deliveryLocation', deliveryLocation.extra.lp.ordlevplats1)
            );
          }
        }

        result.push(this.getMappedFr(customer.extra.fr, 'customerAddress', null));

        if (deliveryLocations && deliveryLocations.length > 0) {
          deliveryLocations
            .filter(location => location.extra.lp.ordlevplats1 !== primaryDeliveryLocation)
            .forEach(location => {
              result.push(this.getMappedFr(location.extra.fr, 'deliveryLocation', location.extra.lp.ordlevplats1));
            });
        }

        return result;
      })
    );
  }

  protected getMappedFr(
    fr: any,
    source: 'deliveryLocation' | 'customerAddress',
    deliveryLocationId?: string
  ): JeevesOrderDeliveryAddress {
    if (fr.ftgpostadr5) {
      const res: JeevesOrderDeliveryAddress = {
        addressValues: {
          ordlevadr1: fr.ftgnamn || '',
          ordlevadr2: fr.ordlevadr5 || '',
          ordlevadr3: fr.ftgpostadr5 || '',
          ordlevadr4: fr.ftgpostlevadr3 || '',
          ftgpostnr: fr.ftglevpostnr || '',
          ordlevadrlandskod: fr.landskod || '',
          ordlevplats1: deliveryLocationId
        },
        source
      };

      return res;
    } else {
      const res: JeevesOrderDeliveryAddress = {
        addressValues: {
          ordlevadr1: fr.ftgnamn || '',
          ordlevadr2: fr.ftgpostadr1 || '',
          ordlevadr3: fr.ftgpostadr2 || '',
          ordlevadr4: fr.ftgpostadr3 || '',
          ftgpostnr: fr.ftgpostnr || '',
          ordlevadrlandskod: fr.landskod || '',
          ordlevplats1: deliveryLocationId
        },
        source
      };

      return res;
    }
  }
}
