<div class="draggable-div" [class.landscape]="landscape" cdkDrag>
  <div class="header d-flex justify-content-between bg-primary" cdkDragHandle>
    <span>Drag Helper</span>
    <i class="fa-solid fa-table-pivot orientation" (click)="changeOrientation()"></i>
  </div>
  <div class="drag-helper-body"  (dragover)="onDragOver($event)" (drop)="onDrop($event)">
    
    <div class="item" *ngFor="let item of data; index as i" draggable="true"
    (dragstart)="onDragStart($event, item)">
      <div class="item-otpions">
              
        <i class="fa-solid fa-chevron-down"></i>
        <div class="show-item-options">
          <div class="show-item-option" (click)="deleteItem(i)">Delete</div>
         <!--  <div class="show-item-option">Template</div> -->
        </div>
      </div>
      <div class="grid-item-content" >
        
        <div class="grid-item-image" >
          <img
            [src]="item.extra.images[0].s3Uri | gungImageUrl"
          />
        </div>
        <div class="grid-item-details">
          <div class="grid-item-name">
            {{item.name}}  
          </div>
          <div [innerHTML]="item?.extra?.pim?.spaceManagementInformation"></div>
          <div class="articles">
            Art. no: <br>
            [{{item?.articles}}]
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>