import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ListLayoutSingleComponent, ListLayout } from 'gung-list';
import {
  DeliveryLocationListConfigService,
  CustomerService,
  MetadataService,
  Customer,
  UsersService,
  AuthService,
  CustomerSelectionActionConfigService
} from 'gung-standard';
import { JeevesDeliveryLocationListCardComponent } from '../../components/jeeves-delivery-location-list-card/jeeves-delivery-location-list-card.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class JeevesDeliveryLocationListConfigService extends DeliveryLocationListConfigService {
  constructor(
    public customerService: CustomerService,
    protected usersService: UsersService,
    protected authService: AuthService,
    protected customerSelectionActionConfigService: CustomerSelectionActionConfigService,
    protected translateService: TranslateService,
    public metadataService: MetadataService,
    public router: Router
  ) {
    super(
      customerService,
      usersService,
      authService,
      customerSelectionActionConfigService,
      translateService,
      metadataService,
      router
    );
  }

  getLayouts(): ListLayout<Customer>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => JeevesDeliveryLocationListCardComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Customer list'
      }
    ];
  }
}
