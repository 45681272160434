import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { mergeMap, Observable, of } from 'rxjs';
import { DateUtilService } from 'gung-common';
import { ListLayoutSingleComponent, ListLayout } from 'gung-list';
import { OrderService, SelectedCustomerService, MetadataService, CustomerService, Order } from 'gung-standard';
import { JeevesOrderListConfigService } from 'gung-standard-jeeves';
import { SjoborgOrderCardListComponent } from '../components/sjoborg-order-card-list/sjoborg-order-card-list.component';

@Injectable({
  providedIn: 'root'
})
export class SjoborgOrderListConfigService extends JeevesOrderListConfigService {
  constructor(
    protected translationService: TranslateService,
    protected orderService: OrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService,
    protected dateUtilService: DateUtilService
  ) {
    super(translationService, orderService, selectedCustomerService, metadataService, customerService, dateUtilService);
  }

  getItems(): Observable<Order[]> {
    if (this.customerId == null) {
      return this.selectedCustomerService.getSelectedCustomer().pipe(
        mergeMap(customer => {
          if (customer != null) {
            return this.orderService
              .getOrders(customer.id)
              .pipe(mergeMap((orders: Order[]) => of(orders.filter(o => o.extra.oh.ordstat !== 90))));
          } else {
            return of([]);
          }
        })
      );
    } else {
      return this.orderService
        .getOrders(this.customerId)
        .pipe(mergeMap((orders: Order[]) => of(orders.filter(o => o.extra.oh.ordstat !== 90))));
    }
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => SjoborgOrderCardListComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Order list'
      }
    ];
  }
}
