import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginWrapperComponent, LoginConfigService } from 'gung-standard';

@Component({
  selector: 'sjoborg-login-wrapper',
  templateUrl: './sjoborg-login-wrapper.component.html',
  styleUrls: ['./sjoborg-login-wrapper.component.scss']
})
export class SjoborgLoginWrapperComponent extends LoginWrapperComponent implements OnInit {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    componentFactoryResolver: ComponentFactoryResolver,
    changeDetectorRef: ChangeDetectorRef,
    loginConfigService: LoginConfigService
  ) {
    super(route, router, componentFactoryResolver, changeDetectorRef, loginConfigService);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
