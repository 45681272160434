import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../models/order';

@Injectable({
  providedIn: 'root'
})
export class ProductionManagementPickableOrdersService {

  constructor(protected http: HttpClient) {}

  getOrdersToPick(tagIds?: string[]): Observable<Order[]> {
    let url = `json/production/pickable-orders`;
    let params: HttpParams;
    if (tagIds && tagIds.length > 0) {
      url = `${url}/by-tags`;
      params = new HttpParams().set('tags', tagIds.toString());
    }

    return this.http.get<Order[]>(url, { params });
  }
}
