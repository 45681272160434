<div [formGroup]="myFormGroup">
  <lib-meta-select-input
    (change)="salesPersonSelected($event.target.value)"
    formControlName="saljare"
    table="salj"
    metaId="saljare"
    metaDisplay="saljarenamn"
  >
  </lib-meta-select-input>
</div>
<hr />
