<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>CREATE_PURCHASE_ORDER</h4>
</div>

<div class="modal-body">
  <div *ngIf="purchaseOrder && !creatingOrder">
    <form
    class="form mt-2 mb-3"
    [formGroup]="purchaseOrder"
  >
    
    <div class="row">
      <div class="col-12 col-md-6">
        <lib-gung-date-picker
        [currentselectedDate]="purchaseOrder.get('deliveryDate').value"
        (selectedDate)="purchaseOrder.get('deliveryDate').setValue($event)"
        label="DELIVERY_DATE"
        ></lib-gung-date-picker>
        <lib-forms-message-error [controller]="purchaseOrder.get('deliveryDate')"></lib-forms-message-error>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="metadata" translate>
            PAYMENT_TERMS
          </label>
          <select name="paymentTermsCode" id="metadata" class="form-control form-select" formControlName="paymentTermsCode">
           
            <option [value]="option.Code" *ngFor="let option of this.paymentTerms">
              {{ option.Description}}
            </option>
          </select>
          <lib-forms-message-error [controller]="purchaseOrder.get('paymentTermsCode')"></lib-forms-message-error>
      </div>
      </div>
    </div>

    <div class="mt-2">
        <lib-gung-text-input
        gungTranslate="NAME"
        formControlName="deliveryName"
        placeholder="{{ 'NAME' | translate }}"
        ></lib-gung-text-input>
        <lib-forms-message-error [controller]="purchaseOrder.get('deliveryName')"></lib-forms-message-error>
    </div>

    <div class="mt-2">
        <lib-gung-text-input
        gungTranslate="DELIVERY_ADDRESS"
        formControlName="deliveryAddress"
        placeholder="{{ 'DELIVERY_ADDRESS' | translate }}"
        ></lib-gung-text-input>
        <lib-forms-message-error [controller]="purchaseOrder.get('deliveryAddress')"></lib-forms-message-error>
    </div>
    
    
    <div class="row mt-2">
        <div class="col-12 col-md-6">
            <lib-gung-text-input
            gungTranslate="ZIP_CODE"
            formControlName="deliveryZipCode"
            placeholder="{{ 'ZIP_CODE' | translate }}"
            ></lib-gung-text-input>
            <lib-forms-message-error [controller]="purchaseOrder.get('deliveryZipCode')"></lib-forms-message-error>
        </div>
        <div class="col-12 col-md-6">
            <lib-gung-text-input
            gungTranslate="CITY"
            formControlName="deliveryCity"
            placeholder="{{ 'CITY' | translate }}"
            ></lib-gung-text-input>
            <lib-forms-message-error [controller]="purchaseOrder.get('deliveryCity')"></lib-forms-message-error>
        </div>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col" translate>PRODUCT_ID</th>
                <th scope="col" translate>QUANTITY</th>
                <th scope="col" translate>TRACE</th>
                <th scope="col" translate>DATE</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of (suggestedOrders || [order])">
                <th scope="row">{{ row.productId }}</th>
                <td>{{ row.quantity }}</td>
                <td>{{ row.trace }}</td>
                <td>{{ row.orderDate | date: dateUtilService.dateFormat }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    </form>
  </div>
  <div *ngIf="creatingOrder">
    <div class="w-100 text-center" *ngIf="processing">
        <i class="fa fa-spinner fa-spin fa-3x"></i>
    </div>
    <div *ngIf="!processing">
        <div class="container" *ngIf="!!context">
            <div class="mb-4">
              <h2 class="text-center">
                <span translate>THANK_YOU_HEADER</span>
              </h2>
              <p class="text-center">
                <span translate>ORDER_ID</span>:
                <span *ngFor="let item of context.erpOrderIds; last as isLast"
                  >{{ item }}<span *ngIf="!isLast">, </span></span
                >
              </p>
            </div>
          </div>
    </div>
  </div>
</div>

<div class="modal-footer text-right">
  <button type="button" class="btn btn-light" aria-label="Close" (click)="activeModal.dismiss()" translate>
    CANCEL
  </button>
  <button type="button" class="btn btn-light" aria-label="Close" (click)="save()" translate>
    Save
  </button>
</div>
