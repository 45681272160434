import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GungEdiError, GungEdiMessageItem } from '../../../models/edi';
import { EdiRespondeOrderResponseActionConfigService } from '../../../services/edi-order-response-action-config/edi-order-response-action-config.service';
import { EdiOrderResponseDetailConfigService } from '../../../services/edi-order-response-detail-config/edi-order-response-detail-config.service';
import { EdiService } from '../../../services/edi/edi.service';
import { EdiRowTable, EdiRowTableType } from '../edi-row-table/edi-row-table.component';

@Component({
  selector: 'lib-edi-incoming-order-response-detail',
  templateUrl: './edi-incoming-order-response-detail.component.html',
  styleUrls: ['./edi-incoming-order-response-detail.component.css']
})
export class EdiIncomingOrderResponseDetailComponent {
  orderResponseId: string;
  orderResponseData: any;
  orderId: string;
  itemsColumns: EdiRowTable[] = [
    {
      header: 'IMAGE',
      type: EdiRowTableType.IMAGE,
      getValue: (item: GungEdiMessageItem) => item?.extra?.images
    },
    {
      header: 'ID',
      type: EdiRowTableType.TEXT,
      getValue: (item: GungEdiMessageItem) => item.ids?.gungId
    },
    {
      header: 'EXPECTED_QUANTITY',
      type: EdiRowTableType.TEXT,
      getValue: (item: GungEdiMessageItem) => item.quantities?.expectedQuantity
    },
    {
      header: 'ACTUAL_QUANTITY',
      type: EdiRowTableType.TEXT,
      getValue: (item: GungEdiMessageItem) => item.quantities?.actualQuantity
    },
    {
      header: 'UNIT_NET_PRICE',
      type: EdiRowTableType.TEXT,
      getValue: (item: GungEdiMessageItem) =>
        (item.prices?.actualUnitNetPrice?.value || '') + ' ' + (item.prices?.actualUnitNetPrice?.currency || '')
    },
    {
      header: 'UNIT_GROSS_PRICE',
      type: EdiRowTableType.TEXT,
      getValue: (item: GungEdiMessageItem) =>
        (item.prices?.actualUnitGrossPrice?.value || '') + ' ' + (item.prices?.actualUnitGrossPrice?.currency || '')
    },
    {
      header: 'DELIVERY_DATE',
      type: EdiRowTableType.DATE,
      getValue: (item: GungEdiMessageItem) => item.deliveryDate
    },
    {
      header: 'TOTAL_NET_PRICE',
      type: EdiRowTableType.TEXT,
      getValue: (item: GungEdiMessageItem) =>
        (item.prices?.actualTotalNetPrice?.value || '') + ' ' + (item.prices?.actualTotalNetPrice?.currency || '')
    },
    {
      header: 'SECONDARY_TABLE',
      type: EdiRowTableType.TABLE,
      getValue: (item: GungEdiMessageItem, index: number) => item.errors,
      secondaryColumn: [
        {
          header: 'Type',
          type: EdiRowTableType.TEXT,
          getValue: (error: GungEdiError, index: number) => error.type
        },
        {
          header: 'Message',
          type: EdiRowTableType.TEXT,
          getValue: (error: GungEdiError, index: number) => error.error
        },
        {
          header: 'Source',
          type: EdiRowTableType.TEXT,
          getValue: (error: GungEdiError, index: number) => error.validator
        }
      ]
    }
  ];

  constructor(
    protected route: ActivatedRoute,
    protected ediService: EdiService,
    public configService: EdiOrderResponseDetailConfigService,
    public actionConfig: EdiRespondeOrderResponseActionConfigService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      this.orderResponseId = data.id;
      this.ediService.getIncomingOrderResponse(this.orderResponseId).subscribe(orderResponseData => {
        this.orderResponseData = orderResponseData;
      });
    });
  }
}
