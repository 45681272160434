import { Injectable } from '@angular/core';

import { AuthService } from '../auth/auth.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { combineLatest, debounceTime, first, forkJoin, map, Observable } from 'rxjs';
import { Customer } from '../../models/customer';
import { User } from '../../state/auth/types';


export interface GungMenu{
  heading: string,
  link?: string,
  links?:GungSimpleMenu[],
  icon: string,
  open?: boolean,
  action?(event?): void;
}

export interface GungSimpleMenu{
  heading: string,
  link?: string,
  icon: string,
  action?(event?): void;
}

@Injectable({
  providedIn: 'root'
})
export class GungMainMenuService {

  
  constructor(
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService
  ) { }

  /**
   * Get menu for main menu
   * @returns Menu as Observable
   */
  getMenu(): Observable<GungMenu[]> {
      return combineLatest({
        user: this.authService.getCurrentUser(),
        selectedCustomer: this.selectedCustomerService.getSelectedCustomer(),
        roles: this.authService.getRoles()
      }).pipe(
        debounceTime(500),
        map(({ user, selectedCustomer, roles})=> {
          return this.mountMenu(user, roles, selectedCustomer)
        })
      )
  }

  /**
   * 
   * @param user Current User
   * @param roles Current user roles
   * @param selectedCustomer Selected Customer
   * @param adicionalParams Anything that you need to make validations when you extended functions
   * @returns Menu
   */
  mountMenu(user: User, roles: string[], selectedCustomer: Customer, adicionalParams: any = null ){
    let mainMenu: GungMenu[] = [
      ...this.userMenu(adicionalParams),
    ];
    if(roles.includes('SALES')){
      mainMenu = [
        ...mainMenu,
        ...this.salesMenu(adicionalParams),
      ];
    }
    if(roles.includes('ADMIN')){
      mainMenu = [
        ...mainMenu,
        ...this.adminMenu(adicionalParams),
      ];
    }
    if(roles.includes('ACTUATOR')){
      mainMenu = [
        ...mainMenu,
        ...this.systemMenu(user, roles, adicionalParams),
      ];
    }

    return mainMenu;
  }

  userMenu( adicionalParams: any = null): GungMenu[]{
    let usermenu: GungMenu[] = [
      {
        heading: 'ORDERS',
        icon: 'fa-solid fa-user',
        open: false,
        links:[
          {
            heading: 'MY_CARTS',
            icon: 'fa-solid fa-cart-circle-plus',
            link: '/carts',
          },
          {
            link: '/orders',
            heading: 'PREVIOUS_ORDERS',
            icon: 'fa-solid fa-bags-shopping'
          },
          {
            link: '/news',
            heading: 'NEWS',
            icon: 'fa-solid fa-newspaper'
          },
        ]
      }
    ];

    return usermenu;
  }

  salesMenu(adicionalParams: any): GungMenu[]{
    let salesMenu: GungMenu[] = [
      {
        heading: 'EXPORT_IMPORT',
        icon: 'fa-solid fa-file-export',
        open: false,
        links:[
          {
            heading: 'DOCUMENTS_ARCHIVE',
            icon: 'fa-solid fa-folder-open',
            link: '/admin/documents',
          },
          {
            link: '/admin/document-templates/template-item-editor',
            heading: 'EXPORT_TEMPLATES',
            icon: 'fa-solid fa-download'
          },
          {
            link: '/digital-assets/management',
            heading: 'DIGITAL_ASSETS_MANAGEMENT',
            icon: 'fa-solid fa-images'
          },
          {
            link: '/admin/import-linesheet',
            heading: 'IMPORT_LINESHEET',
            icon: 'fa-regular fa-file-spreadsheet'
          },
          {
            heading: 'PRODUCT_EXPORT_NAV_BAR',
            link: '/products-export',
            icon: 'fa-solid fa-file-arrow-down'
          }
        ]
      },
      {
        heading: 'SALES',
        icon: 'fa-solid fa-messages-dollar',
        open: false,
        links:[
          {
            heading: 'MY_CUSTOMERS',
            icon: 'fa-solid fa-users',
            link: '/customers',
          },
          {
            link: '/return-warranty', 
            heading: 'RETURN_WARRANTY',
            icon: 'fa-solid fa-shield-check',
          },
          {
            link: '/open-orders',
            heading: 'OPEN_ORDERS',
            icon: 'fa-solid fa-folder-open'
          },
          {
            link: '/invoices',
            heading: 'INVOICES',
            icon: 'fa-solid fa-file-invoice-dollar'
          },
          {
            link: '/sales-dashboard',
            heading: 'SALES_DASHBOARD',
            icon: 'fa-solid fa-chart-line'
          },
          {
            link: '/quickorder',
            heading: 'QUICK_ORDER',
            icon: 'fa-solid fa-cart-shopping-fast'
          },
          {
            link: '/activity-internal',
            heading: 'ACTIVITIES',
            icon: 'fa-solid fa-chart-network'
          }
        ]
      }
    ];

    return salesMenu
    
  }

  adminMenu(adicionalParams: any): GungMenu[]{
    let adminMenu: GungMenu[] = [
      {
        heading: 'ADMIN',
        icon: 'fa-solid fa-user-tie',
        open: false,
        links:[
          {
            heading: 'SETTINGS',
            icon: 'fa-solid fa-gear',
            link: '/admin/settings',
          },
          {
            link: '/admin/my-company', 
            heading: 'THE_COMPANY',
            icon: 'fa-solid fa-building',
          },
          {
            link: '/admin/manage-users',
            heading: 'USERS',
            icon: 'fa-solid fa-users'
          },
          {
            link: '/admin/gung-flows',
            heading: 'GUNG_FLOWS',
            icon: 'fa-solid fa-arrow-progress'
          },
          {
            link: '/admin/news',
            heading: 'NEWS_EDITOR',
            icon: 'fa-solid fa-newspaper'
          },
          {
            link: '/admin/edi',
            heading: 'EDI',
            icon: 'fa-solid fa-tower-cell'
          },
          {
            link: '/admin/translations',
            heading: 'TRANSLATIONS',
            icon: 'fa-solid fa-language'
          },
          {
            link: '/admin/order-portal',
            heading: 'ORDER_PORTAL',
            icon: 'fa-solid fa-person-to-portal'
          },
          {
            link: '/admin/custom-page-editor',
            heading: 'MY_PAGES',
            icon: 'fa-solid fa-memo'
          }
        ]
      }
    ];
    return adminMenu;
  }

  systemMenu(user , roles: string[], adicionalParams: any){
    const mockRoleMode = this.authService.mockRolesModeEnabled();
    let systemMenu: GungMenu[] = [
      {
        heading: 'SYSTEM',
        icon: 'fa-solid fa-gear',
        open: false,
        links:[
          {
            link: '/admin/manage-caches',
            heading: 'CACHES',
            icon: 'fa-solid fa-hard-drive'
          },
          {
            link: '/admin/schedulers-monitor',
            heading: 'SCHEDULERS',
            icon: 'fa-solid fa-calendar-days'
          },
          {
            link: '/admin/actuator',
            heading: 'ACTUATOR',
            icon: 'fa-solid fa-user-crown'
          },
        ]
      },
      {
        heading: mockRoleMode ? 'Disable mock roles' : 'Enable mock roles',
        icon: 'fa-solid fa-hat-wizard',
        open:true,
        links: mockRoleMode ? this.mockRoles(user, roles) : [],
        action: () => this.authService.toggleUseMockRoles()
      }
       
     
    ];

    return systemMenu;
  }

  mockRoles(user: User, roles: string[]){
    const roleLinks: GungMenu[] = [];
    user.roles.forEach(role => {
      if (role !== 'ACTUATOR') {
        roleLinks.push({
          heading: role,
          link: 'TOGGLE',
          action: (_inputElement: HTMLInputElement) => this.authService.toggleMockRole(role),
          icon: roles.includes(role) ? 'fa-solid fa-toggle-on' : 'fa-solid fa-toggle-off'
        });
      }
    });
    return roleLinks;
  }
}
