import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomerService, Customer, GungModalService } from 'gung-standard';
import { CustomerModel } from 'gung-standard';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { JsonViewModal } from 'gung-common';

@Component({
  selector: 'gung-jeeves-jeeves-add-customer-modal',
  templateUrl: './jeeves-add-customer-modal.component.html',
  styleUrls: ['./jeeves-add-customer-modal.component.css']
})
export class JeevesAddCustomerModalComponent implements OnInit {
  customerForm: FormGroup;
  isSubmitted = false;

  get formControls() {
    return this.customerForm.controls;
  }
  get name() {
    return this.customerForm.get('name');
  }
  get vat() {
    return this.customerForm.get('vat');
  }

  constructor(
    public activeModal: NgbActiveModal,
    protected customerService: CustomerService,
    protected formBuilder: FormBuilder,
    protected modalService: GungModalService
  ) {}

  ngOnInit() {
    this.customerForm = this.formBuilder.group({
      extra: this.formBuilder.group({
        fr: this.formBuilder.group({
          ftgnamn: [''],
          ftgpostadr1: [''],
          ftgpostadr2: [''],
          ftgpostnr: [''],
          ftgpostadr3: [''],

          ftgpostadr5: [''],
          ftglevpostnr: [''],
          ftgpostlevadr3: ['']
        }),
        contact: this.formBuilder.group({
          name: [''],
          mail: ['', Validators.email],
          phone: ['']
        })
      })
    });
  }

  submitForm() {
    if (this.customerForm.invalid) {
      this.isSubmitted = true;
      return;
    }

    const customer: Customer = { ...this.customerForm.getRawValue() };
    this.customerService.createNewCustomer(customer).pipe(
      catchError((error: HttpErrorResponse, caught) => {
        const data: JsonViewModal = {
          title: 'ERROR',
          subTitle: error.error.status,
          json: error.error.gungMessage || error.error.message,
          typeString: true
        }
  
        this.modalService.openJSONModal(data);
        return throwError(() => error);
      })
    ).subscribe(
      customers => {

      });
  }
}
