import { Injectable, Type } from '@angular/core';
import { ButtonsComponent, CheckoutStepComponent } from 'gung-standard';
import {
  DeliveryLocationsJeevesComponent,
  JeevesCheckoutConfigService,
  JeevesDeliveryAddressStepComponent,
  OrderTermsJeevesComponent,
  SummarizeOrderJeevesComponent
} from 'gung-standard-jeeves';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs';
import { SjoborgButtonsComponent } from '../components/checkout/sjoborg-buttons/sjoborg-buttons.component';
import { SjoborgOrderTermsJeevesComponent } from '../components/checkout/sjoborg-order-terms-jeeves/sjoborg-order-terms-jeeves.component';
import { SjoborgSummarizeOrderJeevesComponent } from '../components/checkout/sjoborg-summarize-order-jeeves/sjoborg-summarize-order-jeeves.component';
import { SjoborgsDeliveryAddressStepComponent } from '../components/sjoborgs-delivery-address-step/sjoborgs-delivery-address-step.component';

@Injectable({
  providedIn: 'root'
})
export class SjoborgCheckoutConfigService extends JeevesCheckoutConfigService {
  getMainProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return super.getMainProcessSteps().pipe(
      first(),
      map(d => {
        if (d.indexOf(DeliveryLocationsJeevesComponent) > -1) {
          d[d.indexOf(DeliveryLocationsJeevesComponent)] = SjoborgsDeliveryAddressStepComponent;
        }

        if (d.indexOf(JeevesDeliveryAddressStepComponent) > -1) {
          d[d.indexOf(JeevesDeliveryAddressStepComponent)] = SjoborgsDeliveryAddressStepComponent;
        }

        d[d.indexOf(OrderTermsJeevesComponent)] = SjoborgOrderTermsJeevesComponent;

        if (d.indexOf(SummarizeOrderJeevesComponent) > -1) {
          d[d.indexOf(SummarizeOrderJeevesComponent)] = SjoborgSummarizeOrderJeevesComponent;
        }
        return d;
      })
    );
  }

  getButtonsComponent(): Type<ButtonsComponent> {
    return SjoborgButtonsComponent;
  }
}
