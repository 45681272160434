import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import {
  ProductGridViewComponent,
  PriceService,
  GungFlowService,
  AuthService,
  LocationConfigService,
  Product,
  GungAnonymousConfigService
} from 'gung-standard';
import { Router } from '@angular/router';

@Component({
  selector: 'gung-jeeves-jeeves-product-grid-view',
  templateUrl: './jeeves-product-grid-view.component.html',
  styleUrls: ['./jeeves-product-grid-view.component.css']
})
export class JeevesProductGridViewComponent extends ProductGridViewComponent implements OnInit {
  adjustedScroll = false;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected gungAnonymousConfigService: GungAnonymousConfigService,
    protected router: Router,
    protected locationConfigService: LocationConfigService
  ) {
    super(priceService, gungFlowService, authService, gungAnonymousConfigService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.mappedDataChanged
      .asObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {

      });
  }

  goToDetail(product: Product): void {
    this.router.navigate(['/product/', product.id]);
  }

  getPreviousLayout(): number {
    const defaultLayout = this.locationConfigService.getLayout('current-layout');
    // this.locationConfigService.removeLayout(defaultLayout);
    return Number((defaultLayout && defaultLayout.layoutIndex) || 0);
  }
}
