<div class="card mb-2">
  <div class="card-body">
    <div class="card-title row">
      <div class="col-auto">
        <a [routerLink]="'/orders/' + data.id">
          <i class="fas fa-eye pr-1" style="font-size: larger"></i>
          <h5>{{ data.id }}</h5>
        </a>
      </div>
      <div class="col">
        <span *ngFor="let packageNumber of data.extra.packageNumber; let i = index">
          <a *ngIf="isDHLTracking(data.extra.packageLev[0])" target="_blank" [href]="trackingUrlDHL + packageNumber">
            <i class="far fa-truck ml-1"></i>
            <span>&nbsp;{{ 'TRACK_ORDER' | translate }}</span>
          </a>
          <a
            *ngIf="isDHLExpressTracking(data.extra.packageLev[0])"
            target="_blank"
            [href]="trackingUrlDHLExpress + packageNumber"
          >
            <i class="far fa-truck ml-1"></i>
            <span>&nbsp;{{ 'TRACK_ORDER' | translate }}</span>
          </a>
          <a *ngIf="isPostNordTracking(data.extra.packageLev[0])" target="_blank" [href]="trackingUrl + packageNumber">
            <i class="far fa-truck ml-1"></i>
            <span>&nbsp;{{ 'TRACK_ORDER' | translate }}</span>
          </a>
        </span>
      </div>
      <div class="col-auto ml-auto">
        <lib-action-btn [item]="data" [config]="configService"></lib-action-btn>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-4">
        <div class="pb-1">
          <lib-gung-date-output [gungTranslate]="'ORDER_DATE'" [value]="data.extra.oh.orddatum"></lib-gung-date-output>
        </div>
        <div class="pb-1">
          <lib-gung-date-output [gungTranslate]="'EXPECTED_DELIVERY_DATE'" [value]="data.extra.oh.ordberlevdat">
          </lib-gung-date-output>
        </div>
      </div>
      <div class="col-xs-12 col-md-4">
        <div class="pb-1">
          <lib-gung-meta-output
            [value]="data.extra.oh.ordstat"
            [gungTranslate]="'ORDER_STATUS'"
            metadataTable="x7"
            metaOptionName="ordstatbeskr"
          ></lib-gung-meta-output>
        </div>
        <div class="pb-1">
          <div class="form-group">
            <label class="mb-0 font-weight-bold" translate>TOTAL</label>
            <lib-price
              [price]="data.extra.oh.ohvbordsum"
              [currency]="data.extra.oh.valkod"
              class="form-control-plaintext p-0"
            ></lib-price>
          </div>
        </div>
      </div>
      <div class="col col-xs-6 col-md-4">
        <div class="pb-1">
          <lib-gung-text-input
            [gungTranslate]="'NAME'"
            [renderStatic]="true"
            [ngModel]="
              data.extra.oh.ordlevadr1 ||
              (data.extra.deliveryCustomer ? data.extra.deliveryCustomer.name : data.extra.customerName)
            "
          >
          </lib-gung-text-input>
          <div class="pb-1">
            <lib-gung-meta-output
              [value]="data.extra.oh.ordlevadrlandskod"
              [gungTranslate]="'COUNTRY'"
              metadataTable="xr"
              metaOptionName="land"
            ></lib-gung-meta-output>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
