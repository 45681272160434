import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { DownloadFile, DownloadFileService } from '../../../services/file-downloader/download-file.service';
import { PdfDownloadIndicatorService } from '../../../services/pdf-download-indicator.service';

@Injectable({
  providedIn: 'root'
})
export class ReplenishmentProductExcelService {
  constructor(
    private http: HttpClient,
    private downloadFileService: DownloadFileService,
    private pdfDownloadIndicatorService: PdfDownloadIndicatorService
  ) {}

  exportActiveProductsToExcel(customerId: string): Observable<any> {
    const url = `download/replenishment/products/excel/${customerId}`;

    return this.http.post(url, {}, { responseType: 'arraybuffer', observe: 'response' }).pipe(
      tap(_ => this.pdfDownloadIndicatorService.setDownloading({ downloading: true, data: null })),
      map((response: HttpResponse<ArrayBuffer>) => {
        return this.downloadFileService.create(response);
      }),
      map((file: DownloadFile) => {
        return this.downloadFileService.download(file);
      }),
      tap(_ => this.pdfDownloadIndicatorService.setDownloading({ downloading: false, data: null }))
    );
  }
}
