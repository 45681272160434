import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-gung-settings-base-view',
  templateUrl: './gung-settings-base-view.component.html',
  styleUrls: ['./gung-settings-base-view.component.css']
})
export class GungSettingsBaseViewComponent {
  public unsavedChanges: boolean = false;
}
