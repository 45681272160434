import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { OrderStatistic } from '../types';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { element } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class OrderStatisticsService {
  protected invoiceStatisticsSubject = new ReplaySubject<OrderStatistic[]>();

  constructor(protected http: HttpClient) {
    this.initService();
  }

  protected initService() {
    this.http
      .get<OrderStatistic[]>('json/order-statistics-nostream')
      .subscribe(data => this.invoiceStatisticsSubject.next(data));
  }

  getOrderStatistics(preConditions: any = null): Observable<OrderStatistic[]> {
    return this.invoiceStatisticsSubject.asObservable().pipe(
      map(elements => {
        if (!preConditions) return elements;
        const filtered = elements.filter(element => {
          if (preConditions.required.customerId) {
            return preConditions.required.customerId.indexOf(element.customerId) != -1;
          }
          return true;
        });
        return filtered;
      })
    );
  }
}
