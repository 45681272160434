import { Component, Input } from '@angular/core';
import { GungModalService } from '../../../../services/gung-modal/gung-modal.service';
import { ComparePlanogram, Planogram } from '../../models/planogram';
import { PlanogramService } from '../../services/planogram.service';
import { first } from 'rxjs';

@Component({
  selector: 'lib-planogram-compare-modal',
  templateUrl: './planogram-compare-modal.component.html',
  styleUrls: ['./planogram-compare-modal.component.css']
})
export class PlanogramCompareModalComponent {

  delegate: GungModalService;
  componentId: string;

  @Input()
  planogram: Planogram

  planograms: Planogram[];

  compareTo: string;

  email: string;
  

  constructor(protected planogramService: PlanogramService) {}

  ngOnInit() {
    this.componentId = PlanogramCompareModalComponent.name;
    this.planogramService.getAllPlanograms().pipe(first()).subscribe(planograms => this.planograms = planograms)

  }

  confirm() {
    let compare: ComparePlanogram={
      email: this.email,
      planogramAId: this.planogram.id,
      planogramBId: this.compareTo
    }
    this.delegate.modalRef.get(this.componentId).close(compare);
    
  }

  cancel() {
    this.delegate.modalRef.get(this.componentId).close();
  }
}
