import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EdiIncomingDespatchAdvice } from '../../../models/edi';
import { EdiService } from '../../../services/edi/edi.service';
import { EdiRowTable, EdiRowTableType } from '../edi-row-table/edi-row-table.component';
import { EdiIncomingDespatchAdvicesActionConfigService } from '../../../services/edi-incoming-despatch-advices-action-config/edi-incoming-despatch-advices-action-config.service';
import { EdiIncomingDespatchAdviceDetailConfigService } from '../../../services/edi-incoming-despatch-detail-config/edi-incoming-despatch-detail-config.service';

@Component({
  selector: 'lib-edi-incoming-despatch-advice-detail',
  templateUrl: './edi-incoming-despatch-advice-detail.component.html',
  styleUrls: ['./edi-incoming-despatch-advice-detail.component.css']
})
export class EdiIncomingDespatchAdviceDetailComponent implements OnInit {
  despatchAdviceId: string;
  despatchAdviceData: any;
  orderId: string;

  itemsColumns: EdiRowTable[] = [
    {
      header: 'IMAGE',
      type: EdiRowTableType.IMAGE,
      getValue: (item: EdiIncomingDespatchAdvice) => item?.extra?.images
    },
    {
      header: 'ITEM_IDS',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiIncomingDespatchAdvice) => {
        let str = '';
        if (item.ids.gungId) {
          str += this.translateService.instant('ID', {}) + ': ' + item.ids.gungId;
        }
        if (item.ids.ean13) {
          if (!!str) {
            str += '\n';
          }
          str += this.translateService.instant('EAN', {}) + ': ' + item.ids.ean13;
        }
        if (item.ids.upca) {
          if (!!str) {
            str += '\n';
          }
          str += this.translateService.instant('UPCA', {}) + ': ' + item.ids.upca;
        }
        if (item.ids.buyerId) {
          if (!!str) {
            str += '\n';
          }
          str += this.translateService.instant('BUYER_ID', {}) + ': ' + item.ids.buyerId;
        }
        return str;
      },
      customClass: 'formatted'
    },
    {
      header: 'NAME',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiIncomingDespatchAdvice) => item.name
    },
    {
      header: 'PACKAGE_SSCC',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiIncomingDespatchAdvice) => {
        let str = '';
        for (const parcel of item.parcels) {
          if (!!str) {
            str += '\n';
          }
          str += parcel.ginIdentifier + ' : ' + this.translateService.instant('QTY', {}) + ' ' + parcel.quantity;
        }
        return str;
      },
      customClass: 'formatted'
    },
    {
      header: 'ORDER_NO',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiIncomingDespatchAdvice) => item.documentReferences?.buyerOrder?.id
    },
    {
      header: 'QUANTITY',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiIncomingDespatchAdvice) => item.quantities?.actualQuantity
    }
  ];

  constructor(
    protected route: ActivatedRoute,
    protected ediService: EdiService,
    public configService: EdiIncomingDespatchAdviceDetailConfigService,
    public actionConfig: EdiIncomingDespatchAdvicesActionConfigService,
    protected translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      this.despatchAdviceId = data.id;
      this.ediService.getIncomingDespatchAdvice(this.despatchAdviceId).subscribe(despatchAdviceData => {
        this.despatchAdviceData = despatchAdviceData.data;
      });
    });
  }
}
