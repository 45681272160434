import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckoutStepComponent, SelectedCustomerService } from 'gung-standard';
import { first, switchMap } from 'rxjs';
import {
  JeevesDeliveryAddressesService,
  JeevesOrderDeliveryAddress
} from '../../services/jeeves-delivery-addresses.service';

@Component({
  selector: 'gung-jeeves-jeeves-delivery-address-step',
  templateUrl: './jeeves-delivery-address-step.component.html',
  styleUrls: ['./jeeves-delivery-address-step.component.css']
})
export class JeevesDeliveryAddressStepComponent extends CheckoutStepComponent implements OnInit {
  public deliveryAddresses: JeevesOrderDeliveryAddress[];
  public form: FormGroup;
  public selectedDeliveryAddress: JeevesOrderDeliveryAddress;

  constructor(
    protected formBuilder: FormBuilder,
    protected selectedCustomerService: SelectedCustomerService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService
  ) {
    super();
  }

  ngOnInit() {
    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked());
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(
        first(),
        switchMap(selectedCustomer =>
          this.jeevesDeliveryAddressesService.getOrderedDeliveryAddressesForCustomer(selectedCustomer.id)
        )
      )
      .subscribe(deliveryAddresses => {
        this.initForm();
        this.deliveryAddresses = deliveryAddresses;
      });
  }

  protected initForm() {
    this.form = this.formBuilder.group({
      ordlevadr1: this.formBuilder.control(this.checkout.extra.procargs.ordlevadr1 || ''),
      ordlevadr2: this.formBuilder.control(this.checkout.extra.procargs.ordlevadr2 || ''),
      ordlevadr3: this.formBuilder.control(this.checkout.extra.procargs.ordlevadr3 || ''),
      ftgpostnr: this.formBuilder.control(this.checkout.extra.procargs.ftgpostnr || ''),
      ordlevadr4: this.formBuilder.control(this.checkout.extra.procargs.ordlevadr4 || ''),
      ordlevadrlandskod: this.formBuilder.control(this.checkout.extra.procargs.ordlevadrlandskod || ''),
      ordlevplats1: this.formBuilder.control(this.checkout.extra.procargs.ordlevplats1 || '')
    });
  }

  public isSelectedAddress(jeevesOrderDeliveryAddress: JeevesOrderDeliveryAddress): boolean {
    const procargs = this.checkout.extra.procargs;
    const addressValues = jeevesOrderDeliveryAddress.addressValues;

    return (
      addressValues.ftgpostnr === procargs.ftgpostnr &&
      addressValues.ordlevadr1 === procargs.ordlevadr1 &&
      addressValues.ordlevadr2 === procargs.ordlevadr2 &&
      addressValues.ordlevadr3 === procargs.ordlevadr3 &&
      addressValues.ordlevadr4 === procargs.ordlevadr4 &&
      addressValues.ordlevadrbstort === procargs.ordlevadrbstort &&
      addressValues.ordlevadrlandskod === procargs.ordlevadrlandskod &&
      addressValues.ordlevplats1 === procargs.ordlevplats1
    );
  }

  public onDeliveryAddressSelected(index: any) {
    const jeevesOrderDeliveryAddress = this.deliveryAddresses[index];
    Object.keys(jeevesOrderDeliveryAddress.addressValues).forEach(key => {
      this.form.get(key).patchValue(jeevesOrderDeliveryAddress.addressValues[key]);
    });
  }

  protected assignAddressValues() {
    this.checkout.extra.procargs = {
      ...this.checkout.extra.procargs,
      ...this.form.getRawValue()
    };
  }

  protected handleNextButtonClicked(): void {
    console.log(this.form.invalid);
    if (this.form.invalid) {
      return;
    }

    this.assignAddressValues();
    this.stepDone.emit(this.checkout);
  }

  getLabel(): string {
    return 'DELIVERY_ADDRESS';
  }
}
