import {
  JeevesDeliveryAddressesService,
  JeevesOrderDeliveryAddress
} from './../../../../services/jeeves-delivery-addresses.service';
import { Component, OnInit } from '@angular/core';
import {
  AuthService,
  Availability,
  AvailabilityService,
  CartRow,
  CartService,
  CheckoutStepComponent,
  Customer,
  CustomerContact,
  CustomerContactService,
  CustomerService,
  GungFlow,
  GungFlowService
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { SelectedCustomerService } from 'gung-standard';
import { first, mergeMap } from 'rxjs';
import { merge } from 'lodash-es';
import { forkJoin, of } from 'rxjs';
import { User } from 'gung-standard/lib/state/auth/types';

@Component({
  selector: 'gung-jeeves-assign-jeeves-defaults',
  templateUrl: './assign-jeeves-defaults.component.html',
  styleUrls: ['./assign-jeeves-defaults.component.css']
})
export class AssignJeevesDefaultsComponent extends CheckoutStepComponent implements OnInit {
  userEmailConfirmation = true;

  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected customerService: CustomerService,
    protected customerContactService: CustomerContactService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected cartService: CartService,
    protected availabilityService: AvailabilityService,
    protected dateUtilService: DateUtilService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.onNextBtnClicked.subscribe(() => {
      forkJoin([
        this.selectedCustomerService.getSelectedCustomer().pipe(first()),
        this.gungFlowService.getSelectedFlow().pipe(first()),
        this.authService.getCurrentUser().pipe(first()),
        this.cartService.getCurrentCart().pipe(first())
      ])
        .pipe(
          mergeMap(([customer, flow, user, cartRows]) => {
            const productIds = cartRows.map(row => row.productId);
            return forkJoin([
              of(customer),
              this.jeevesDeliveryAddressesService.getOrderedDeliveryAddressesForCustomer(customer.id),
              of(flow),
              of(user),
              of(cartRows)
            ]);
          }),
          mergeMap(([customer, deliveryAddressList, flow, user, cartRows]) => {
            return forkJoin([
              of(customer),
              of(deliveryAddressList),
              of(flow),
              of(user),
              of(cartRows),
              this.customerContactService.getCustomerContacts(customer.id).pipe(first()),
              of(undefined)
            ]);
          })
        )
        .subscribe(([customer, deliveryAddressList, flow, user, cartRows, customerContacts, billingCustomer]) => {
          this.setValues(customer, flow, user, customerContacts, billingCustomer, cartRows);
          this.setDeliveryAddress(deliveryAddressList[0]);
          this.stepDone.emit(this.checkout);
        });
    });
  }

  protected setDeliveryAddress(deliveryAddress: JeevesOrderDeliveryAddress) {
    if (!this.checkout.extra.procargs) {
      this.checkout.extra.procargs = {};
    }

    this.checkout.extra.procargs = { ...this.checkout.extra.procargs, ...deliveryAddress.addressValues };
  }

  protected setValues(
    customer: Customer,
    flow: GungFlow,
    user: User,
    customerContacts: CustomerContact[],
    billingCustomer?: Customer,
    cartRows?: CartRow[]
  ) {
    if (customer == null) {
      this.stepError.emit({
        message: 'Could not find a selected customer',
        step: 'JeevesAssignDefaultComponent',
        error: new Error()
      });
      return;
    }
    if (!this.checkout.extra.oh) {
      this.checkout.extra.oh = {};
    }

    if (!this.checkout.extra.procargs) {
      this.checkout.extra.procargs = {};
    }

    this.checkout.extra._flow = flow;

    this.checkout.extra.confirmationEmails = this.checkout.extra.confirmationEmails || [];

    if (this.userEmailConfirmation) {
      if (this.checkout.extra.confirmationEmails.indexOf(user.email) === -1) {
        this.checkout.extra.confirmationEmails.push(user.email);
      }
    }

    this.checkout.extra.procargs.levsattkod = customer.extra.kus.levsattkod;
    this.checkout.extra.procargs.levvillkkod = customer.extra.kus.levvillkkod;
    this.checkout.extra.oh.betkod = customer.extra.kus.betkod;
    this.checkout.extra.procargs.saljare = this.checkout.extra.procargs.saljare || customer.extra.kus.saljare;

    /* this.checkout.extra.procargs.ordlevadr2 = customer.extra.fr.ftgpostadr1;
    this.checkout.extra.procargs.ordlevadr3 = customer.extra.fr.ftgpostadr2;
    this.checkout.extra.procargs.ordlevadr4 = customer.extra.fr.ftgpostadr3;
    this.checkout.extra.procargs.ftgpostnr = customer.extra.fr.ftgpostnr;
    this.checkout.extra.procargs.ordlevadrlandskod = customer.extra.fr.landskod;
    this.checkout.extra.procargs.ordlevadrprovincecode = customer.extra.fr.provincecode; */

    if (!customer.extra.kus.kundbetalarenr) {
      this.checkout.billingCustomerId = customer.extra.kus.id;
    } else {
      this.checkout.billingCustomerId = customer.extra.kus.kundbetalarenr;
    }
  }

  public isVisible() {
    return false;
  }
}
