import { Component, OnInit } from '@angular/core';
import { OrderPortalService } from '../../services/order-portal.service';
import { OrderPortalStatistic } from '../../models/order-portal';
import { first } from 'rxjs';
import { format, startOfMonth, startOfYear } from 'date-fns';

@Component({
  selector: 'lib-order-portal-wrapper',
  templateUrl: './order-portal-wrapper.component.html',
  styleUrls: ['./order-portal-wrapper.component.css']
})
export class OrderPortalWrapperComponent implements OnInit {
  statistics: OrderPortalStatistic[];

  constructor(protected orderPortalService: OrderPortalService) {}

  ngOnInit(): void {
    this.orderPortalService
      .getStatistics()
      .pipe(first())
      .subscribe(statistics => {
        this.statistics = statistics;
      });
  }

  getFirstOfJanuary(): string {
    const firstDayOfYear = startOfYear(new Date()); // Get the first day of the current year
    return format(firstDayOfYear, 'yyyy-MM-dd'); // Format the date as "yyyy-MM-dd"
  }

  getFirstOfThisMonth(): string {
    const firstDayOfMonth = startOfMonth(new Date()); // Get the first day of the current month
    return format(firstDayOfMonth, 'yyyy-MM-dd'); // Format the date as "yyyy-MM-dd"
  }

  getToday(): string {
    return format(new Date(), 'yyyy-MM-dd');
  }
}
