import { Injectable } from '@angular/core';
import { ColumnRowTableType, SupplierDynamicColumnsService, SupplierService, SupplierTableColumns } from 'gung-standard';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JeevesSupplierDynamicColumnsService extends SupplierDynamicColumnsService {
  protected poStatusColumnTable: { [s: string]: SupplierTableColumns[] } = {
    // NEW
    20: [
      {
        name: null,
        path: null,
        type: ColumnRowTableType.INPUTCHECKBOX
      },
      {
        name: 'PO_NUMBER',
        path: ['extra.bh.bestnr'],
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'ROW_INDEX',
        path: 'extra.bp.bestradnr',
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'ITEM',
        path: ['extra.bp.artnr', 'extra.bp.artnrlev', 'extra.bp.levsartbeskr'],
        type: ColumnRowTableType.TEXT,
        extra: {
          thClass: 'col-2'
        }
      },
      {
        name: 'QUANTITY_ORDERED',
        path: 'extra.bp.bestant',
        type: ColumnRowTableType.NUMBER
      },
      {
        name: 'PRICE',
        path: 'extra.bp.vb_inpris',
        type: ColumnRowTableType.PRICE
      },
      {
        name: 'TOTAL_PRICE',
        path: 'extra.bp.vbbestvalue',
        type: ColumnRowTableType.PRICE
      },
      {
        name: 'QUANTITY_CONFIRM',
        path: 'extra.bp_bestant',
        type: ColumnRowTableType.INPUTNUMBER,
        extra: {
          thClass: 'col-1',
          required: true,
          // copyTo: 'extra.bp.bestant',
          delete: 'extra.bp_bestant',
          patchValue: 'replace',
          maxValue: (item) => {
            return item.extra.bp.bestant;
          },
          minValue: (item) => 0,
          conditions: [
            {
              comparator: '<',
              path1: 'extra.bp_bestant',
              path2: 'extra.bp.bestant',
              result: (item) => {
                // If they don't confirm all quantities requested, need to split the row first
                return this.supplierService.postPurchaseOrderSplit(
                  item.id,
                  item.extra.bp_bestant
                ).pipe(first());
              }
            },
          ]
        }
      },
      {
        name: 'REQUEST_DATE',
        path: 'extra.bp.bestbeglevdat',
        type: ColumnRowTableType.DATE,
        extra: {
          thClass: 'col-1'
        }
      },
      {
        name: 'CONFIRM_READY_DATE',
        path: 'extra.bp_bestlovlevdat', 
        type: ColumnRowTableType.INPUTDATE,
        extra: {
          thClass: 'col-1',
          required: true,
          copyTo: 'extra.bp.bestlovlevdat',
          delete: 'extra.bp_bestlovlevdat',
          patchValue: 'replace',
          conditions: [
            {
              comparator: '===',
              path1: 'extra.bp_bestlovlevdat', // 'extra.gungPurchaseLine.promisedReceiptDate',
              path2: null,
              value2: '0001-01-01',
              result: (item) => {
                item.extra.errors = ['extra.bp_bestlovlevdat'];
                return null;
              }
            }
          ]
        }
      },
      {
        name: null,
        path: [/* 'btnDownload',  */'btnMoveNextStatus', 'btnMoveRejectStatus', 'btnSplit', 'btnHistory'],
        type: ColumnRowTableType.ACTION,
        extra: {
          nextStatus: '25',
          rejectStatus: '21'
        }
      },
    ],
    // Confirmed
    25: [
      {
        name: null,
        path: null,
        type: ColumnRowTableType.INPUTCHECKBOX
      },
      {
        name: 'PO_NUMBER',
        path: ['extra.bh.bestnr'],
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'ROW_INDEX',
        path: 'extra.bp.bestradnr',
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'PLANNED_DATE',
        path: 'extra.gungPurchaseLine.plannedReceiptDate',
        type: ColumnRowTableType.DATE,
        extra: {
          thClass: 'col-1'
        }
      },
      {
        name: 'ITEM',
        path: ['extra.bp.artnr', 'extra.bp.artnrlev', 'extra.bp.levsartbeskr'],
        type: ColumnRowTableType.TEXT,
        extra: {
          thClass: 'col-2'
        }
      },
      {
        name: 'QUANTITY_ORDERED',
        path: 'extra.bp.bestant',
        type: ColumnRowTableType.NUMBER
      },
      {
        name: 'QUANTITY_TO_RECEIVE',
        path: 'extra.gungPurchaseLine.qtyToReceive',
        type: ColumnRowTableType.NUMBER
      },
      {
        name: 'PRICE',
        path: 'extra.bp.vb_inpris',
        type: ColumnRowTableType.PRICE
      },
      {
        name: 'TOTAL_PRICE',
        path: 'extra.bp.vbbestvalue',
        type: ColumnRowTableType.PRICE
      },
      {
        name: null,
        path: [/* 'btnDownload',  */'btnMoveNextStatus', 'btnHistory'],
        type: ColumnRowTableType.ACTION,
        extra: {
          thClass: 'col-2',
          nextStatus: '27',
        }
      },
    ],
    // REJECTED
    21: [
      {
        name: null,
        path: null,
        type: ColumnRowTableType.INPUTCHECKBOX
      },
      {
        name: 'PO_NUMBER',
        path: ['extra.bh.bestnr'],
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'ROW_INDEX',
        path: 'extra.bp.bestradnr',
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'PLANNED_DATE',
        path: 'extra.gungPurchaseLine.plannedReceiptDate',
        type: ColumnRowTableType.DATE,
        extra: {
          thClass: 'col-1'
        }
      },
      {
        name: 'ITEM',
        path: ['extra.bp.artnr', 'extra.bp.artnrlev', 'extra.bp.levsartbeskr'],
        type: ColumnRowTableType.TEXT,
        extra: {
          thClass: 'col-2'
        }
      },
      {
        name: null,
        path: ['btnMoveNextStatus', 'btnHistory'],
        type: ColumnRowTableType.ACTION,
        extra: {
          thClass: 'col-2'
        }
      },
    ],
    // SHIPPED
    27: [
      {
        name: null,
        path: null,
        type: ColumnRowTableType.INPUTCHECKBOX
      },
      {
        name: 'PO_NUMBER',
        path: ['extra.bh.bestnr'],
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'ROW_INDEX',
        path: 'extra.bp.bestradnr',
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'ITEM',
        path: ['extra.bp.artnr', 'extra.bp.artnrlev', 'extra.bp.levsartbeskr'],
        type: ColumnRowTableType.TEXT,
        extra: {
          thClass: 'col-2'
        }
      },
      {
        name: null,
        path: ['btnHistory'],
        type: ColumnRowTableType.ACTION,
        extra: {
          thClass: 'col-2'
        }
      },
    ]
  };

  protected poSupplierTableColumnsDetails: { [s:string]: SupplierTableColumns[]} = {
    20: [
      {
        name: 'PO_NUMBER',
        path: ['extra.bh.bestnr'],
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'ROW_INDEX',
        path: ['extra.bp.bestradnr'],
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'REQUEST_DATE',
        path: 'extra.bp.bestbeglevdat',
        type: ColumnRowTableType.TEXT,
      },
      {
        name: 'TEXT1',
        path: 'text1',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT2',
        path: 'text2',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT3',
        path: 'text3',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT4',
        path: 'text4',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT5',
        path: 'text5',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'CHECKBOX1',
        path: 'checkbox1',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX2',
        path: 'checkbox2',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX3',
        path: 'checkbox3',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX4',
        path: 'checkbox4',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX5',
        path: 'checkbox5',
        type: ColumnRowTableType.INPUTCHECKBOX,
      }
    ],
    21: [
      {
        name: 'PO_NUMBER',
        path: ['extra.bh.bestnr'],
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'ROW_INDEX',
        path: ['extra.bp.bestradnr'],
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'REQUEST_DATE',
        path: 'extra.bp.bestbeglevdat',
        type: ColumnRowTableType.TEXT,
      },
      {
        name: 'TEXT1',
        path: 'text1',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT2',
        path: 'text2',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT3',
        path: 'text3',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT4',
        path: 'text4',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT5',
        path: 'text5',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'CHECKBOX1',
        path: 'checkbox1',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX2',
        path: 'checkbox2',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX3',
        path: 'checkbox3',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX4',
        path: 'checkbox4',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX5',
        path: 'checkbox5',
        type: ColumnRowTableType.INPUTCHECKBOX,
      }
    ],
    25: [
      {
        name: 'PO_NUMBER',
        path: ['extra.bh.bestnr'],
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'ROW_INDEX',
        path: ['extra.bp.bestradnr'],
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'REQUEST_DATE',
        path: 'extra.bp.bestbeglevdat',
        type: ColumnRowTableType.TEXT,
      },
      {
        name: 'TEXT1',
        path: 'text1',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT2',
        path: 'text2',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT3',
        path: 'text3',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT4',
        path: 'text4',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT5',
        path: 'text5',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'CHECKBOX1',
        path: 'checkbox1',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX2',
        path: 'checkbox2',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX3',
        path: 'checkbox3',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX4',
        path: 'checkbox4',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX5',
        path: 'checkbox5',
        type: ColumnRowTableType.INPUTCHECKBOX,
      }
    ],
    27: [
      {
        name: 'PO_NUMBER',
        path: ['extra.bh.bestnr'],
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'ROW_INDEX',
        path: ['extra.bp.bestradnr'],
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'REQUEST_DATE',
        path: 'extra.bp.bestbeglevdat',
        type: ColumnRowTableType.TEXT,
      },
      {
        name: 'TEXT1',
        path: 'text1',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT2',
        path: 'text2',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT3',
        path: 'text3',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT4',
        path: 'text4',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'TEXT5',
        path: 'text5',
        type: ColumnRowTableType.INPUTTEXT,
      },
      {
        name: 'CHECKBOX1',
        path: 'checkbox1',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX2',
        path: 'checkbox2',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX3',
        path: 'checkbox3',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX4',
        path: 'checkbox4',
        type: ColumnRowTableType.INPUTCHECKBOX,
      },
      {
        name: 'CHECKBOX5',
        path: 'checkbox5',
        type: ColumnRowTableType.INPUTCHECKBOX,
      }
    ],
  };

  constructor(
    public supplierService: SupplierService,
  ) {
    super(supplierService);
  }
}
