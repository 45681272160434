<div class="d-flex justify-content-end">
  <a class="btn btn-primary px-5 text-center" [routerLink]="'/login'" translate>LOGIN</a>
</div>

<div class="row">
  <div class="col-12">
    <form
      *ngIf="!alertMessage || alertMessage.type !== 'success'"
      class="form mt-2 mb-3"
      [formGroup]="accountForm"
      (ngSubmit)="onSubmit()"
    >
      <lib-gung-text-input
        gungTranslate="COMPANY_NAME"
        formControlName="companyName"
        placeholder="{{ 'ENTER_COMPANY_NAME' | translate }}"
        ngbAutofocus
      ></lib-gung-text-input>

      <lib-gung-text-input
        gungTranslate="COMPANY"
        formControlName="companyId"
        placeholder="{{ 'ENTER_COMPANY' | translate }}"
      ></lib-gung-text-input>

      <lib-gung-text-input
        gungTranslate="VAT_NO"
        formControlName="companyOrganisationId"
        placeholder="{{ 'ENTER_VAT' | translate }}"
      ></lib-gung-text-input>

      <lib-gung-text-input
        gungTranslate="CONTACT"
        formControlName="companyContactName"
        placeholder="{{ 'ENTER_CONTACT' | translate }}"
      ></lib-gung-text-input>

      <lib-gung-text-input
        gungTranslate="EMAIL"
        formControlName="companyContactEmail"
        placeholder="{{ 'ENTER_EMAIL' | translate }}"
      ></lib-gung-text-input>
      <div
        *ngIf="
          getFormControl('companyContactEmail').invalid &&
          (getFormControl('companyContactEmail').dirty || getFormControl('companyContactEmail').touched) &&
          getFormControl('companyContactEmail').errors.email
        "
        class="alert alert-danger"
      >
        <span translate>NOT_VALID_EMAIL</span>!
      </div>

      <div [formGroupName]="'extra'">
        <lib-gung-text-input
          gungTranslate="EMAIL_ORDER_CONFIRMATION"
          formControlName="companyOrderConfirmationEmail"
          placeholder="{{ 'ENTER_EMAIL_ORDER_CONFIRMATION' | translate }}"
        ></lib-gung-text-input>
        <div
          *ngIf="
            getFormControl('extra.companyOrderConfirmationEmail').invalid &&
            (getFormControl('extra.companyOrderConfirmationEmail').dirty ||
              getFormControl('extra.companyOrderConfirmationEmail').touched) &&
            getFormControl('extra.companyOrderConfirmationEmail').errors.email
          "
          class="alert alert-danger"
        >
          <span translate>NOT_VALID_EMAIL</span>!
        </div>
      </div>

      <div [formGroupName]="'extra'">
        <lib-gung-text-input
          gungTranslate="EMAIL_INVOICE"
          formControlName="companyInvoiceEmail"
          placeholder="{{ 'ENTER_EMAIL_INVOICE' | translate }}"
        ></lib-gung-text-input>
        <div
          *ngIf="
            getFormControl('extra.companyInvoiceEmail').invalid &&
            (getFormControl('extra.companyInvoiceEmail').dirty ||
              getFormControl('extra.companyInvoiceEmail').touched) &&
            getFormControl('extra.companyInvoiceEmail').errors.email
          "
          class="alert alert-danger"
        >
          <span translate>NOT_VALID_EMAIL</span>!
        </div>
      </div>

      <lib-gung-text-input
        gungTranslate="PHONE"
        formControlName="companyContactPhone"
        placeholder="{{ 'ENTER_PHONE' | translate }}"
      ></lib-gung-text-input>

      <div class="form-group row">
        <div class="col">
          <label for="companyAddressId" class="col-form-label" translate>ADDRESS</label>
          <textarea
            class="form-control"
            type="text"
            name="companyAddress"
            id="companyAddressId"
            formControlName="companyAddress"
            placeholder="{{ 'ENTER_ADDRESS' | translate }}"
          ></textarea>
        </div>
      </div>

      <div [formGroupName]="'extra'">
        <div class="form-group row">
          <div class="col">
            <label for="companyExtraMessage" class="col-form-label" translate>EXTRA_MESSAGE</label>
            <textarea
              class="form-control"
              type="text"
              name="companyExtraMessage"
              id="companyExtraMessage"
              formControlName="companyExtraMessage"
              placeholder="{{ 'ENTER_EXTRA_MESSAGE' | translate }}"
            ></textarea>
          </div>
        </div>
      </div>

      <div *ngIf="alertMessage" class="alert alert-danger mt-2 text-center" role="alert">
        <span>{{ alertMessage.message | translate }}</span>
      </div>

      <button class="btn btn-primary mt-2 w-100" type="submit" [disabled]="loader ? true : null">
        <span translate>{{ accountForm.valid || loader ? 'REQUEST_ACCOUNT' : 'REQUEST_INVALID_FORM' }}</span>
        <i *ngIf="loader" class="fas fa-spinner fa-spin ml-2"></i>
      </button>
    </form>
    <div
      *ngIf="alertMessage && alertMessage.type === 'success'"
      class="alert alert-success mt-2 text-center"
      role="alert"
    >
      <span>{{ alertMessage.message | translate }}</span>
    </div>
    <button
      *ngIf="alertMessage && alertMessage.type === 'success'"
      class="btn btn-primary mt-2 w-100"
      type="button"
      (click)="alertMessage = null"
    >
      <span translate>CREATE_NEW</span>
    </button>
  </div>
</div>
