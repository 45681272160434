import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CartRowFeatureOptionSelection, RuleActionType } from '../models/product-configuration-types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductConfigurationRuleExecutionService {
  constructor(protected http: HttpClient) {}

  public executeRules(
    productConfigurationId: string,
    selection: CartRowFeatureOptionSelection[]
  ): Observable<{ [s: string]: RuleActionType }> {
    const url = `/json/product-configuration/rule-executions/${productConfigurationId}`;
    return this.http.post<{ [s: string]: RuleActionType }>(url, selection);
  }
}
