import { Component, Input } from '@angular/core';
import { ProductConfigurationFeature } from '../../models/product-configuration-types';

@Component({
  selector: 'lib-cpq-feature-header',
  templateUrl: './cpq-feature-header.component.html',
  styleUrls: ['./cpq-feature-header.component.scss']
})
export class CpqFeatureHeaderComponent {
  @Input()
  feature: ProductConfigurationFeature;
}
