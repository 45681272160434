import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import {
  ProductService,
  AuthService,
  ProductSortService,
  AssortmentRecursive,
  ConceptTreeListConfigService,
  MetadataService,
  DynamicColumnsService
} from 'gung-standard';
import { SjoborgConceptTableViewComponent } from '../components/sjoborg-concept-table-view/sjoborg-concept-table-view.component';
import { Observable, map } from 'rxjs';
import { gungComparatorHelper } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class SjoborgConceptTreeListConfigService extends ConceptTreeListConfigService {
  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productSortService: ProductSortService,
    protected translationService: TranslateService,
    protected metadataService: MetadataService,
    protected dynamicColumnsService: DynamicColumnsService
  ) {
    super(productService, authService, productSortService, translationService, metadataService, dynamicColumnsService);
  }

  getLayouts(): ListLayout<AssortmentRecursive>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => SjoborgConceptTableViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getItems(): Observable<AssortmentRecursive[]> {
    return super.getItems().pipe(
      map(data => data.sort((a: any, b: any) => gungComparatorHelper(a.sequence, b.sequence, -1)))
    );
  }
}
