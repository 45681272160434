import { Component } from '@angular/core';
import { SupplierPoListTableViewComponent } from '../supplier-po-list-table-view/supplier-po-list-table-view.component';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService } from 'gung-common';
import { first, Observable, forkJoin, of } from 'rxjs';
import { SupplierPurchaseOrder, ProductMultiDimension, Supplier, Product } from '../../../models';
import { DownloadFileService } from '../../../services/file-downloader/download-file.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { ProductService } from '../../../services/products/product.service';
import { SelectedSupplierService } from '../../../services/selected-supplier/selected-supplier.service';
import { SupplierDynamicColumnsService } from '../../../services/supplier-dynamic-columns/supplier-dynamic-columns.service';
import { GungPatchValue, SupplierService } from '../../../services/suppliers/supplier.service';
import { TableRecord } from '../../../state/metadata/types';
import { gungAddRemoveSpinner } from '../../../utils/gung-utils';
import { SupplierPoActionConfigService } from '../../../services/supplier-po-action-config/supplier-po-action-config.service';
import { ColumnSortSelectionService } from '../../../services/column-sort-selection/column-sort-selection.service';
import { SupplierSortColumnService } from '../supplier-sort-column/supplier-sort-column.service';

@Component({
  selector: 'lib-supplier-po-all-list-table-view',
  templateUrl: './supplier-po-all-list-table-view.component.html',
  styleUrls: ['./supplier-po-all-list-table-view.component.css']
})
export class SupplierPoAllListTableViewComponent extends SupplierPoListTableViewComponent {

  groupData: { [orderId: string]: SupplierPurchaseOrder[] };
  groupDataKeys: string[];

  public cachedProducts: { [productId: string]: ProductMultiDimension } = {};
  isLoading: boolean;
  selectedSupplier: Supplier;

  suppliers: { [id: string]: Supplier };
  documents: any;

  constructor(
    public dateUtilService: DateUtilService,
    protected supplierService: SupplierService,
    protected http: HttpClient,
    protected modalService: GungModalService,
    protected metadataService: MetadataService,
    protected activatedRoute: ActivatedRoute,
    public supplierPoActionConfigService: SupplierPoActionConfigService,
    public supplierDynamicColumnsService: SupplierDynamicColumnsService,
    protected translationService: TranslateService,
    protected productService: ProductService,
    protected selectedSupplierService: SelectedSupplierService,
    protected downloadFileService: DownloadFileService,
    protected columnSortService: SupplierSortColumnService
  ) {
    super(
      dateUtilService,
      supplierService,
      http,
      modalService,
      metadataService,
      activatedRoute,
      supplierPoActionConfigService,
      supplierDynamicColumnsService,
      translationService,
      columnSortService
    );
    this.selectedSupplierService.getSelectedSupplier().subscribe(selectedSupplier => this.selectedSupplier = selectedSupplier);
    this.supplierService.getSuppliers().pipe(first()).subscribe(suppliers => this.suppliers = suppliers.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}));
  }

  initData() {
    this.groupData = {};
    this.groupDataKeys = [];
    for (const data of this.data) {
      const orderId = Array.isArray(data) ? data[0].orderId : data.orderId;
      const rows = Array.isArray(data) ? data : [data];
      if (this.groupData[orderId]) {
        this.groupData[orderId].push(...rows);
      } else {
        this.groupData[orderId] = rows as any;
      }
      this.groupData[orderId][0].extra.totalQuantity = this.groupData[orderId].reduce((sum, curr) => sum + curr.quantity, 0);
      this.groupData[orderId][0].extra.totalPrice = this.groupData[orderId].reduce((sum, curr) => sum + curr.extra.gungPurchaseLine.amount, 0);
    }
    this.groupDataKeys = Object.keys(this.groupData);
  }


  confirmAllCheckedLines({ target }) {
    gungAddRemoveSpinner(target);
    const lineToUpdate: SupplierPurchaseOrder[] = [];
    for (const item of this.data) {
      if (Array.isArray(item)) {
        for (const subItem of item) {
          if ((subItem as any).checked) {
            lineToUpdate.push(subItem);
          }
        }
        continue;
      }
      if ((item as any).checked) {
        lineToUpdate.push(item);
      }
    }
    if (lineToUpdate.length === 0) {
      gungAddRemoveSpinner(target);
      return;
    }
    let requests: Observable<SupplierPurchaseOrder>[] = lineToUpdate.map(po =>
      this.supplierPoActionConfigService.confirmPurchaseLineRequest(po)
    );
    let errorFound = lineToUpdate.findIndex(line => line.extra.errors) > -1;
    if (errorFound) {
      gungAddRemoveSpinner(target);
      return;
    }
    this.modalService.openConfirmYesNoModal(
      undefined,
      this.translationService.instant('SUPPLIER_PORTAL_MOVE_ORDER_ROW_NEXT_STATUS', { orderId: lineToUpdate[0].orderId, rowNumber: lineToUpdate.map(l => l.rowNumber || l.id).join(', '), nextStatus: this.nextStatusName }) + '?',
      { size: 'sm' }
    )
      .then(result => {
        if (result) {
          forkJoin(requests).subscribe(retult => {
            gungAddRemoveSpinner(target);
          });
        }
      });
  }

  loadProductData(rowGroup: SupplierPurchaseOrder[], show: boolean) {
    if (show) {
      this.isLoading = true;
      let ids = rowGroup.map(row => row.extra.gungPurchaseLine.itemNo + '-' + row.extra.gungPurchaseLine.variantCode);
      ids = ids.filter(id => !Object.keys(this.cachedProducts).includes(id));
      let request: Observable<Product[]> = of([]);
      if (ids.length > 0) {
        request = this.productService.getProductsByIdsUnfiltered(ids).pipe(first());
      }
      request.subscribe(products => {
        for (const product of products) {
          this.cachedProducts[product.id] = product as ProductMultiDimension;
        }
        for (const row of rowGroup) {
          const product = this.cachedProducts[row.extra.gungPurchaseLine.itemNo + '-' + row.extra.gungPurchaseLine.variantCode];
          if (product) {
            row.extra._gung = {
              primaryDimensionName: product.primaryDimension[0].name,
              secondaryDimensionName: product.secondaryDimension[0].name
            }
          }
        }
        this.isLoading = false;
      })
    }
  }

  toNumber(value): Number {
    return Number(value);
  }

  calculatePrice(value1: any, value2: any): Number {
    return (value1 >>> 0) / (value2 >>> 0);
  }

  setValueGroup(rowGroup: SupplierPurchaseOrder[], path: string, value: any) {
    for (const row of rowGroup) {
      super.setValue(row, path, value);
    }
    this.save(rowGroup, [{ op: 'replace', path: path, value: value }]);
  }

  supplierFactoryFilter = (option: { key: string; value: string | TableRecord }) => {
    return (option?.value as any)?.extra?.suppliers?.includes(this.selectedSupplier.id);
  }

  save(rowGroup: SupplierPurchaseOrder[], patchValue: GungPatchValue[]) {
    forkJoin(
      rowGroup.map(r => this.supplierService.patchPurchaseOrder(r.id, patchValue))
    ).subscribe(_ => { })
  }

  getAllDocuments(dataList) {
    let rowIds = dataList.map(d => d[0].id);
    this.supplierService.getDocumentByRowIds(rowIds).pipe(first()).subscribe(docs => {
      for (const data of dataList) {
        const orderId = Array.isArray(data) ? data[0].orderId : data.orderId;
        const rows = Array.isArray(data) ? data : [data];
        if (Array.isArray(docs[rows[0].id])) {
          this.groupData[orderId][0].extra._hasDocuments = this.groupData[orderId][0].extra._hasDocuments || docs[rows[0].id]?.length > 0;
          this.groupData[orderId][0].extra._haveNewDocuments = docs[rows[0].id].filter(d => !d.read).length > 0;
        }
      }
    })
  }

  download(item) {
    //  this.supplierService.downloadExcel(item[0].orderId, "", "DIDRIKSONS_PURCHASED_ORDER_EXPORT").pipe(first()).subscribe();
    return of();
  }

}
