<div class="modal-header">
  <h4 class="modal-title" translate>{{ isDataSlider ? 'SLIDER_ELEMENT_EDITOR' : 'ELEMENT_EDITOR' }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="!isDataSlider; else editSlider">
  <div class="row">
    <div class="col-12">
      <!-- IMAGE EDITOR -->
      <lib-gung-text-input
        *ngIf="data[0]?.image?.enabled"
        gungTranslate="OPTION_IMAGE_URL"
        placeholder="{{ 'FILL_OPTION_IMAGE' | translate }}"
        [(ngModel)]="data[0].image.sourceUrl"
        (change)="imageUpload = undefined"
      ></lib-gung-text-input>
      <div class="form-group" *ngIf="data[0]?.image?.enabled">
        <label class="w-100" for="file-upload" translate>OPTION_IMAGE_UPLOAD</label>
        <div class="input-group">
          <div class="flex-fill">
            <input
              [value]="(imageUpload && imageUpload[0] && imageUpload[0].name) || ('IMAGE_UPLOAD' | translate)"
              class="form-control bg-transparent"
              type="text"
              (click)="inputFile.click()"
              readonly
            />
            <input
              #inputFile
              type="file"
              class="_custom-file-input form-control d-none"
              placeholder="File Upload"
              id="file-upload"
              aria-label="File Upload"
              aria-describedby="file-upload"
              accept=".jpg, .jpeg, .png, .gif"
              [multiple]="false"
              (change)="onFileChange($event, 0)"
            />
          </div>
          <div class="input-group-append" (click)="inputFile.click()">
            <span class="input-group-text" id="file-upload"><i class="fas fa-folder"></i></span>
          </div>
        </div>
      </div>
      <span *ngIf="!data[0]?.image?.enabled" class="d-block" translate>IMAGE_NOT_ENABLED</span>
      <!-- TEXT EDITOR(s) -->
      <div *ngIf="data[0]?.text?.enabled">
        <lib-gung-text-input
          *ngIf="isAssortment"
          [gungTranslate]="'ASSORTMENT'"
          placeholder="{{ 'ASSORTMENT' | translate }}"
          [(ngModel)]="data[0].text.text"
        ></lib-gung-text-input>
        <lib-gung-text-input
          *ngFor="let lang of translateLangs"
          [gungTranslate]="'OPTION_TEXT_' + lang.toUpperCase()"
          placeholder="{{ 'FILL_OPTION_TEXT' | translate }}"
          [(ngModel)]="data[0].text.i18n[lang].title"
        >
        </lib-gung-text-input>
        <div class="d-flex">
          <lib-gung-text-input gungTranslate="COLOR" [(ngModel)]="data[0].text.color" class="w-100"></lib-gung-text-input>
          <i
            (click)="colorPicker.click()"
            class="fas fa-square fa-3x ml-2 mt-3 border"
            [ngStyle]="{ color: data[0].text.color }"
          ></i>
          <input #colorPicker type="color" [(ngModel)]="data[0].text.color" style="height: 0px; width: 0px; visibility: hidden;" class="mt-5">
        </div>
        
      </div>
      <span *ngIf="!data[0]?.text?.enabled" class="d-block" translate>TEXT_NOT_ENABLED</span>
      <!-- LINK EDITOR -->
      <lib-gung-text-input
        *ngIf="data[0]?.link?.enabled"
        gungTranslate="OPTION_LINK"
        placeholder="{{ 'FILL_OPTION_LINK' | translate }}"
        [(ngModel)]="data[0].link.url"
      ></lib-gung-text-input>
      <div class="w-100" *ngIf="data[0]?.link?.enabled">
        <label for="internal" class="mr-1" translate>INTERNAL</label>
        <input type="checkbox" id="internal" name="internal" [(ngModel)]="data[0].link.internal" />
        <label for="open_external" class="ml-2 mr-1" translate>OPEN_EXTERNAL</label>
        <input type="checkbox" id="open_external" name="open_external" [(ngModel)]="data[0].link.openExternal" />
      </div>
      <span *ngIf="!data[0]?.link?.enabled" class="d-block" translate>LINK_NOT_ENABLED</span>
      <b>TAGs</b>
      <ul>
        <li>?flowid=1234</li>
        <li>&#123;userassortment&#125;</li>
        <li>&#123;currentlang&#125; / &#123;CURRENTLANG&#125;</li>
      </ul>
    </div>
  </div>
</div>

<ng-template #editSlider>
  <div class="modal-body">
    
    <div class="row" *ngIf="data.length > 0">
      <div class="col-12 d-flex flex-row justify-content-end mb-2">
        <button class="btn btn-outline-dark" (click)="addSlide()" translate>
          ADD_NEW_SLIDE
        </button>
      </div>
      <div class="col-12">
        <lib-gung-tabset [destroyOnHide]="false" justify="fill">
          <lib-gung-tab *ngFor="let slide of data; index as i;" [title]="'SLIDE_N' | translate: { number: i + 1 }">
            <ng-template libGungTabContent>
              <div class="row">
                <div class="col-12">
                  <!-- IMAGE EDITOR -->
                  <lib-gung-text-input
                    *ngIf="slide?.image?.enabled"
                    gungTranslate="OPTION_IMAGE_URL"
                    placeholder="{{ 'FILL_OPTION_IMAGE' | translate }}"
                    [(ngModel)]="slide.image.sourceUrl"
                    (change)="imageUpload[i] = undefined"
                  ></lib-gung-text-input>
                  <div class="form-group" *ngIf="slide?.image?.enabled">
                    <label class="w-100" for="file-upload" translate>OPTION_IMAGE_UPLOAD</label>
                    <div class="input-group">
                      <div class="flex-fill">
                        <input
                          [value]="(imageUpload && imageUpload[i] && imageUpload[i].name) || ('IMAGE_UPLOAD' | translate)"
                          class="form-control bg-transparent"
                          type="text"
                          (click)="inputFile.click()"
                          readonly
                        />
                        <input
                          #inputFile
                          type="file"
                          class="_custom-file-input form-control d-none"
                          placeholder="File Upload"
                          id="file-upload"
                          aria-label="File Upload"
                          aria-describedby="file-upload"
                          accept=".jpg, .jpeg, .png, .gif"
                          [multiple]="false"
                          (change)="onFileChange($event, i)"
                        />
                      </div>
                      <div class="input-group-append" (click)="inputFile.click()">
                        <span class="input-group-text" id="file-upload"><i class="fas fa-folder"></i></span>
                      </div>
                    </div>
                  </div>
                  <span *ngIf="!slide?.image?.enabled" class="d-block" translate>IMAGE_NOT_ENABLED</span>
                  <!-- TEXT EDITOR(s) -->
                  <div *ngIf="slide?.text?.enabled">
                    <lib-gung-text-input
                      *ngFor="let lang of translateLangs"
                      [gungTranslate]="'OPTION_TEXT_' + lang.toUpperCase()"
                      placeholder="{{ 'FILL_OPTION_TEXT' | translate }}"
                      [(ngModel)]="slide.text.i18n[lang].title"
                    ></lib-gung-text-input>
                  </div>
                  <span *ngIf="!slide?.text?.enabled" class="d-block" translate>TEXT_NOT_ENABLED</span>
                  <!-- LINK EDITOR -->
                  <lib-gung-text-input
                    *ngIf="slide?.link?.enabled"
                    gungTranslate="OPTION_LINK"
                    placeholder="{{ 'FILL_OPTION_LINK' | translate }}"
                    [(ngModel)]="slide.link.url"
                  ></lib-gung-text-input>
                  <div class="w-100" *ngIf="slide?.link?.enabled">
                    <label for="internal" class="mr-1" translate>INTERNAL</label>
                    <input type="checkbox" id="internal" name="internal" [(ngModel)]="slide.link.internal" />
                    <label for="open_external" class="ml-2 mr-1" translate>OPEN_EXTERNAL</label>
                    <input type="checkbox" id="open_external" name="open_external" [(ngModel)]="slide.link.openExternal" />
                  </div>
                  <span *ngIf="!slide?.link?.enabled" class="d-block" translate>LINK_NOT_ENABLED</span>
                  <b>TAGs</b>
                  <ul>
                    <li>?flowid=1234</li>
                    <li>&#123;userassortment&#125;</li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-12 d-flex flex-row justify-content-end mt-2">
                  <button class="btn btn-outline-danger" (click)="removeSlide(i)" translate>
                    REMOVE_SLIDE
                  </button>
                </div>
              </div>
            </ng-template>
          </lib-gung-tab>
        </lib-gung-tabset>
      </div>
    </div>
    <div class="row" *ngIf="data.length === 0">
      <div class="col-12 d-flex flex-row justify-content-center mt-1">
        <button class="btn btn-outline-dark" (click)="addSlide()" translate>
          ADD_YOUR_FIRST_SLIDE
        </button>
      </div>
    </div>
  </div>
</ng-template>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="onSave()" [disabled]="isLoading ? true : null" translate>
    SAVE<i *ngIf="isLoading" class="fas fa-spinner fa-spin ml-2"></i>
  </button>
</div>
