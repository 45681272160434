import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Catalogues } from '../models/catalogues';

@Injectable({
  providedIn: 'root'
})
export class CataloguesService {
  // NOTE: for now the links need to be flowpaper.com
  public catalogueOptions: Catalogues[] = [
    { id: 'GARDEN_2023', name: 'Garden 2023', url: 'https://7596071b.flowpaper.com/Garden2023/' },
    { id: 'CHRISTMAS_2023', name: 'Christmas 2023', url: 'https://7596071b.flowpaper.com/Christmas2023/' }
  ];

  constructor() { }

  getAllCatalogues(): Observable<Catalogues[]> {
    return of(this.catalogueOptions);

  }
}
