import { Injectable } from '@angular/core';
import { ListLayout, ListLayoutSingleComponent, ConfigBaseFilter, SimpleConfigBaseFilter, ListSortOption } from 'gung-list';
import { InvoiceListConfigService, InvoiceService, Invoice, SelectedCustomerService, gungComparatorHelper } from 'gung-standard';
import { JeevesInvoiceCardListComponent } from '../../components/jeeves-invoice-card-list/jeeves-invoice-card-list.component';
import { differenceInMonths, differenceInDays, format } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { ListLayoutMultipleComponent } from 'gung-list';
import { JeevesInvoiceTableComponent } from '../../components/jeeves-invoice-table/jeeves-invoice-table.component';

export class InvoiceDateFilter extends SimpleConfigBaseFilter<Invoice> {
  type = 'dateRangeFilter';

  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'INVOICE_DATE';
  }

  getOptionIds(item: Invoice): string[] {
    return [format(new Date(item.extra.fh.faktdat), 'yyMMdd')];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

class InvoiceExpirationDateFilter extends SimpleConfigBaseFilter<Invoice> {
  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'DUE_DATE';
  }

  getOptionIds(item: Invoice): string[] {
    const dayDifferense = differenceInDays(new Date(), new Date(item.extra.fh.faktdat));

    if (dayDifferense <= 1) {
      return ['TODAY'];
    }
    if (dayDifferense <= 7) {
      return ['THIS_WEEK'];
    }
    if (dayDifferense <= 30) {
      return ['THIS_MONTH'];
    }
    if (dayDifferense <= 90) {
      return ['LAST_THREE_MONTHS'];
    }
    return ['OVER_THREE_MONTHS'];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

export class InvoiceStatusFilter extends SimpleConfigBaseFilter<Invoice> {
  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'FH_FAKTSTKOD';
  }
  getOptionIds(item: Invoice): string[] {
    if (item.extra.fh.faktstkod == null) {
      return ['UNDEFINED'];
    }
    return [`FH_FAKTSTKOD_${item.extra.fh.faktstkod}`];
  }
  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

@Injectable({
  providedIn: 'root'
})
export class JeevesInvoiceListConfigService extends InvoiceListConfigService {
  constructor(
    protected translationService: TranslateService,
    protected invoiceService: InvoiceService,
    protected selectedCustomerService: SelectedCustomerService
  ) {
    super(invoiceService, selectedCustomerService);
  }

  getItems(): Observable<Invoice[]> {
    const itemsObs = super.getItems();
    return itemsObs.pipe(
      map(invoices =>
        invoices.sort((a, b) => {
          const aDate = new Date(a?.extra?.fh?.faktdat);
          const bDate = new Date(b?.extra?.fh?.faktdat);
          if (aDate < bDate) {
            return 1;
          } else if (aDate > bDate) {
            return -1;
          }
          
          return a?.id.localeCompare(b?.id);
        })
      )
    );
  }

  getLayouts(): ListLayout<Invoice>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => JeevesInvoiceCardListComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Invoice grid'
      }
    ];
  }

  getFilters(): ConfigBaseFilter<Invoice>[] {
    return [
      new InvoiceDateFilter(this.translationService),
      new InvoiceExpirationDateFilter(this.translationService),
      new InvoiceStatusFilter(this.translationService)
    ];
  }
}
