<div class="table-responsive gung-responsive">
  <table class="table table-borderless table-striped">
    <thead>
      <tr>
        <th translate>CUSTOMER</th>
        <th translate>ORDER</th>
        <th translate>VALUE</th>
        <th class="align-right" translate>DATE</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let order of data"
        class="clickable"
        [routerLink]="['/orders/', order.id]"
        [ngClass]="{ 'd-none': order.extra.oh.ordstat === 70 }"
      >
        <td [attr.data-label]="'CUSTOMER' | translate" *ngIf="order.deliveryCustomerId as customerId">
          {{ (customerService.getCustomer(customerId) | async).name }}
        </td>
        <td [attr.data-label]="'ORDER' | translate">
          {{ order.id }}
        </td>
        <td [attr.data-label]="'VALUE' | translate">
          {{ order.extra.oh.ohvbordsum | currency: order.extra.oh.valkod:'symbol-narrow' }}
        </td>
        <td [attr.data-label]="'DATE' | translate" class="align-right">
          {{ order.extra.oh.orddatum | date: 'dd-MM-yy' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
