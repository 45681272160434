import { Injectable } from '@angular/core';
import { Invoice } from '../../models/invoice';
import { BackendInterceptor } from '../backend-interceptor/backend-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceCardListService {
  protected downloadEnabled = false;

  constructor(protected backendInterceptor: BackendInterceptor) {}

  public isDownloadEnabled(): boolean {
    return this.downloadEnabled;
  }

  public getDownloadUrl(data: Invoice): string {
    return `${this.backendInterceptor.getBaseUrl()}download/invoice-pdf/${data.id}.pdf`;
  }
}
