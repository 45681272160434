import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ListLayout, ListLayoutMultipleComponent, SimpleConfigBaseFilter } from 'gung-list';
import {
  ActivityInternal,
  ActivityInternalFilter,
  ActivityInternalListConfigService,
  ActivityInternalStatusFilter,
  ActivityInternalTypeFilter,
  MetadataService
} from 'gung-standard';
import { SjoborgActivityInternalTableComponent } from '../components/sjoborg-activity-internal-table/sjoborg-activity-internal-table.component';

@Injectable({
  providedIn: 'root'
})
export class SjoborgActivityInternalListConfigService extends ActivityInternalListConfigService {
  getLayouts(): ListLayout<ActivityInternal>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => SjoborgActivityInternalTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order list'
      }
    ];
  }

  getFilters(): ConfigBaseFilter<ActivityInternal>[] {
    return [
      new ActivityInternalStatusFilter(this.metadataService),
      new ActivityInternalTypeFilter(this.metadataService),
      new SjoborgsActivityInternalFilter(this.metadataService)
    ];
  }
}

export class SjoborgsActivityInternalFilter extends SimpleConfigBaseFilter<ActivityInternal> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getOptionIds(item: ActivityInternal): string[] {
    const value = this.metadataService.getMetadataValue('salj', 'saljarenamn', item['customerResponsible']);
    return [value];
  }
  getOptionName(key: string): string {
    return key;
  }
  getName(): string {
    return 'RESPONSIBLE';
  }
}
