<div *ngIf="edit && rootAssortment" class="row">
  <div class="col">
    <div class="form-group">
      <button type="button" class="btn btn-primary" (click)="openModal('prefixUrl')">PREFIXURL</button>
    </div>
  </div>
</div>
<div class="">
  <div
    class="header-wrapper image-container d-flex flex-column justify-content-center"
    [ngStyle]="{ 'background-image': 'url(\'' + this.getImage(data.elements?.topImage?.image) + '\')' }"
    [class.no-cursor]="!edit"
    (click)="edit ? openModal('topImage') : navigateTo(data.elements?.topImage?.link)"
  >
    @if (getText(data.elements?.topImage?.text)) {
    <div class="header-text">
      {{ getText(data.elements?.topImage?.text) | translate }}
    </div>
    }
    <div>
      @if (getText(data.elements?.topButton?.text)) {
      <button
        class="btn header-button"
        (click)="
          $event.preventDefault();
          $event.stopPropagation();
          edit ? openModal('topButton') : navigateTo(data.elements?.topButton.link)
        "
      >
        {{ getText(data.elements?.topButton?.text) | translate }}
      </button>
      }
    </div>
  </div>
</div>
<button *ngIf="edit" (click)="openModalTextInput('assortment')" class="image-change-button btn btn-primary mt-2 float-right" translate>CHANGE_ASSORTMENT</button>
<div class="" *ngIf="rootAssortment && selectedAssortment">
  <h1 class="mt-5 mb-4" translate>SELECT_CATEGORY</h1>
  <div class="row">
    <div class="col-12 col-md-6 col-xl-4 mb-4" *ngFor="let assortment of selectedAssortment.children">
      <a [routerLink]="!edit ? ('/' + prefixUrl + '/' + assortment.id) : undefined">
        <div
          class="category-image"
          [ngStyle]="getStyle(assortment)"
          [class.no-cursor]="edit"
        >
          <div class="text-banner">
            {{ assortment.name }}
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
