import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FilterListComponent } from 'gung-list';
import { OrderServicesTicket } from '../../../services/order-services/order-services.service';
import { ReturnWarrantyListConfigService } from '../../../services/return-warranty-list-config/return-warranty-list-config.service';

@Component({
  selector: 'lib-return-warranty-list',
  template: '<lib-filter-list #list [configService]="returnWarrantyListConfigService"></lib-filter-list>',
  styles: []
})
export class ReturnWarrantyListComponent implements OnInit {
  @Input()
  customerId?: string;

  @ViewChild('list', { static: false }) list: FilterListComponent<OrderServicesTicket>;

  constructor(public returnWarrantyListConfigService: ReturnWarrantyListConfigService) {}

  ngOnInit() {
    this.returnWarrantyListConfigService.setCustomerId(this.customerId);
  }
}
