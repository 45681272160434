import { Component, OnInit } from '@angular/core';
import {
  JeevesOrderDetailsComponent,
  JeevesOrderActionConfigService,
  JeevesOrderDetailsConfigService
} from 'gung-standard-jeeves';
import { ActivatedRoute } from '@angular/router';
import { OrderService, AuthService, BackendInterceptor, ProductService, BaseViewConfigService, ProductAssortmentParentService } from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sjoborg-jeeves-order-details',
  templateUrl: './sjoborg-jeeves-order-details.component.html',
  styleUrls: ['./sjoborg-jeeves-order-details.component.scss']
})
export class SjoborgJeevesOrderDetailsComponent extends JeevesOrderDetailsComponent implements OnInit {
}
