<div class="dashboard-card card" *ngIf="loaded">
  <div class="card-body dashboard-card-content">
    <div class="card-title">
      <div class="d-flex justify-content-between">
        <div>
          <span class="title" translate>SALES_AMOUNT</span> <br />
          <span class="sub-title" translate>{{ monthString }}</span>
        </div>
        <span class="current-statistic text-primary">{{ salesAmountThisMonth | number: '1.0-0' }}</span>
        <!-- ADD CURRENCY AFTER THIS -->
      </div>
    </div>

    <div class="progress-container">
      <ngb-progressbar [animated]="true" [value]="(salesAmountThisMonth / salesAmountLastYearThisMonth) * 100">
      </ngb-progressbar>
      <div class="d-flex justify-content-between">
        <span class="sub-title"
          >{{ ((salesAmountThisMonth / salesAmountLastYearThisMonth) * 100 || 0).toFixed() }}%</span
        >
        <span class="sub-title" translate>CHANGE_FROM_BEFORE</span>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-card card" *ngIf="!loaded">
  <div class="card-body dashboard-card-content">
    <div class="text-center dashboard-loading-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
