<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title" translate>CREDIT_BLOCKED</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <p>Your account or your invoice account is credit blocked. Please contact your sales person.</p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-default btn-primary text-uppercase" (click)="close()" translate>
    CLOSE
  </button>
</div>
