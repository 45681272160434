import { Component, OnInit, Type, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { CustomPageConfigService } from '../../../services/custom-page/custom-page-config.service';
import { TemplateComponent, BackendTemplate } from '../custom-page-interface/template-component';
import { first } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { CustomPageResolverService } from '../../../services/custom-page/custom-page-resolver.service';
import { CustomPageDirectiveDirective } from '../custom-page-directive/custom-page-directive.directive';
import { CustomPageTemplateComponent } from '../custom-page-class/custom-page-template-component';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { gungAddRemoveSpinner } from '../../../utils/gung-utils';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { OptionsListMulti, gungGetPropertyOfObject, gungSetPropertyOfObject } from 'gung-common';

interface Alert {
  typeClass: string;
  message: string;
}

interface Template {
  id: string;
  name: string;
}

@Component({
  selector: 'lib-custom-page-editor',
  templateUrl: './custom-page-editor.component.html',
  styleUrls: ['./custom-page-editor.component.css']
})
export class CustomPageEditorComponent implements OnInit {
  isAdmin: boolean;
  isActuator: boolean;
  enableCreateCustomPage = false;
  alertMessage: Alert;
  loader = false;
  selectedTemplate: string;
  selectedPage: string;
  editedId: string[] = [];
  labelClass: string | string[] = 'col-12 col-sm-4';

  customPageList: BackendTemplate[] = [];
  templateList: Template[] = [];

  dataTemplate: BackendTemplate;

  @ViewChild(CustomPageDirectiveDirective, { static: true })
  viewChild: CustomPageDirectiveDirective;

  // USER GROUPS
  settingsGroups;
  userGroups: OptionsListMulti[] = [];
  selectedUserGroups: string[];
  previewEmpty = 'Choose groups';

  noGroups: boolean = false;

  constructor(
    protected authService: AuthService,
    protected customPageConfigService: CustomPageConfigService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected customPageResolverService: CustomPageResolverService,
    protected gungFlowService: GungFlowService,
    protected translateService: TranslateService,
    protected gungModalService: GungModalService
  ) { }

  ngOnInit() {
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        this.isAdmin = roles.indexOf('ADMIN') >= 0;
        this.isActuator = roles.indexOf('ACTUATOR') >= 0;
      });
    if (this.isActuator || this.isAdmin) {
      this.enableCreateCustomPage = true;
    }
    // GET CUSTOM PAGES TEMPLATES
    this.customPageConfigService.getTemplates().subscribe((templates: BackendTemplate[]) => {
      if (templates) {
        if (templates.length === 0) {
          this.alertMessage = {
            typeClass: 'alert-info',
            message: 'NO_CUSTOM_PAGE_CONTACT_GUNG'
          };
          return;
        }
        this.customPageList = templates;
        const selectedPage = templates[0];
        this.selectedPage = selectedPage.pageName;
        this.selectedTemplate = selectedPage.templateName;
        this.selectedUserGroups = selectedPage.activeForUserGroups;
        // GET TEMPLATES FOR THAT TYPE OF PAGES
        this.customPageConfigService
          .getDefaultTemplate()
          .pipe(first())
          .subscribe((templateList: Template[]) => {
            if (templateList) {
              this.templateList = templateList;
            }
          });
        // GET DATA FOR THAT PAGE
        if (this.selectedTemplate) {
          this.getTemplateData();
        }
        this.getUserGroupsFromService();
      }
    });
  }

  onSave() {
    const backendTemplate: BackendTemplate = {
      ...this.dataTemplate,
      pageName: this.selectedPage,
      templateName: this.selectedTemplate,
      activeForUserGroups: this.selectedUserGroups
    };
    this.customPageConfigService.updateTemplateData(backendTemplate).subscribe(
      (value: void) => {
        this.alertMessage = {
          typeClass: 'alert-success', // danger
          message: 'CHANGE_SAVED_SUCCESS'
        };
      },
      (error: any) => { }
    );
  }

  /**
   * Use this to create first time template in DB
   */
  createTemplate(pageName = 'homeassortment', templateName = 'homedefault') {
    pageName = prompt('Please enter pageName', pageName);
    if (!pageName) {
      return;
    }
    const dataDefault: TemplateComponent = {} as TemplateComponent;
    const backendTemplate = {
      ...dataDefault,
      pageName, // reference id in custom-pages service
      templateName // template name/id
    };

    this.customPageConfigService.createTemplateData(backendTemplate as BackendTemplate).subscribe(
      result => {
        console.log('createTemplateData - result', result);
        window.location.reload();
      },
      error => {
        console.log('createTemplateData - error', error);
      }
    );
  }

  setSelectedPage(selectedPageName: string) {
    this.selectedPage = selectedPageName;
    this.selectedTemplate = this.customPageList.find(t => t.pageName === selectedPageName).templateName;
    this.selectedUserGroups = this.customPageList.find(t => t.pageName === selectedPageName).activeForUserGroups;
    this.getTemplateData();
    this.setUserGroups();
  }

  changeTemplate({ target: { value } }) {
    this.selectedTemplate = value;
    this.getTemplateData();
  }

  getTemplateData(initialSetup?: boolean) {
    this.customPageConfigService.getTemplateData(this.selectedPage).subscribe((dataTemplate: BackendTemplate) => {
      if (dataTemplate) {
        this.dataTemplate = JSON.parse(JSON.stringify(dataTemplate));
        if (this.selectedTemplate !== dataTemplate.templateName) {
          this.dataTemplate.elements = null;
        }
        this.customPageResolverService
          .resolve(null, null, this.selectedTemplate)
          .subscribe(value => this.loadComponent(value, this.dataTemplate, !this.dataTemplate || initialSetup));
      }
    });
  }

  loadComponent(
    layoutComponent: Type<CustomPageTemplateComponent>,
    itemComponent: TemplateComponent,
    initialSetup?: boolean
  ) {
    const resolver = this.componentFactoryResolver.resolveComponentFactory(layoutComponent);
    const containerRef = this.viewChild.viewContainerRef;
    containerRef.clear();
    const componentFactory = containerRef.createComponent(resolver);
    const typedComponent = componentFactory.instance as CustomPageTemplateComponent;
    if (!itemComponent || !itemComponent.elements || initialSetup) {
      typedComponent.initialSetup = true; // First time with this template
      this.dataTemplate.elements = typedComponent.getDefaultData().elements;
    }
    typedComponent.edit = true;
    typedComponent.data = itemComponent;

    this.checkDataWithDefaultNewFields(typedComponent.data, typedComponent.getDefaultData());
  }

  checkDataWithDefaultNewFields(data: TemplateComponent, defaultData: TemplateComponent) {
    let changes = false;
    const dataKeys = Object.keys(data.elements);
    const defaultDataKeys = Object.keys(defaultData.elements);
    // for (const key of dataKeys) {
    //   if (!gungGetPropertyOfObject(key, defaultData.elements)) {
    //     changes = true;
    //     gungSetPropertyOfObject(data.elements, key, undefined, true);
    //   }
    // }
    for (const key of defaultDataKeys) {
      if (!gungGetPropertyOfObject(key, data.elements)) {
        changes = true;
        gungSetPropertyOfObject(data.elements, key, gungGetPropertyOfObject(key, defaultData.elements));
      }
    }

    if (changes) {
      this.gungModalService.openConfirmYesNoModal(undefined, 'Detected changes in the default data. Check if everything is correct and save the data.', undefined, 'OK', null);
    }
  }

  setUserGroups(): OptionsListMulti[] {
    this.noGroups = false;
    const tmp: OptionsListMulti[] = [];
    if (!this.selectedUserGroups) {
      this.selectedUserGroups = [];
    }

    for (const userGroup of this.selectedUserGroups) {
      tmp.push({
        id: userGroup,
        name: '',
        selected: true
      });
    }
    if (this.settingsGroups && this.settingsGroups.length > 0) {
      (this.settingsGroups as string[]).forEach(settingsGroup => {
        const userGroup = tmp.find(group => group.id === settingsGroup);
        if (!userGroup) {
          tmp.push({
            id: settingsGroup,
            name: '',
            selected: false
          });
        }
      });
    }
    this.userGroups = tmp;
    if (this.selectedUserGroups?.length === 0) {
      this.noGroups = true;
    }
    return tmp;
  }

  getUserGroupsFromService() {
    this.gungFlowService
      .getSystemGung()
      .pipe(first())
      .subscribe(systemSettings => {
        this.settingsGroups = systemSettings.extra.userGroups;
        this.setUserGroups();
      });
  }

  setSelectedValues(event: string[]): void {
    this.noGroups = false;
    if (event?.length === 0) {
      this.noGroups = true;
    }
    this.selectedUserGroups = event;
  }

  deleteCustomPage(template: BackendTemplate, { target }) {
    gungAddRemoveSpinner(target);
    this.gungModalService
      .openConfirmYesNoModal(undefined, this.translateService.instant('REMOVE') + ' ' + template.pageName + '?', {
        size: 'sm'
      })
      .then(result => {
        if (result) {
          this.customPageConfigService.removeTemplateData(template).subscribe(response => {
            window.location.reload();
          });
        }
      })
      .finally(() => gungAddRemoveSpinner(target));
  }
}
