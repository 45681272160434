import { Component, OnInit } from '@angular/core';
import { Catalogues } from '../../models/catalogues';
import { CataloguesService } from '../../services/catalogues.service';

@Component({
  selector: 'lib-catalogues-wrapper',
  templateUrl: './catalogues-wrapper.component.html',
  styleUrls: ['./catalogues-wrapper.component.css']
})
export class CataloguesWrapperComponent implements OnInit {

  public loaded: boolean = false;
  public catalogueOptions: Catalogues[];

  public selectedCatalogId: string;
  public selectedCatalogHtml: string;;

  constructor(
    protected cataloguesService: CataloguesService
  ) { }

  ngOnInit(): void {
    this.cataloguesService.getAllCatalogues().subscribe(catalogueOptions => {
      this.catalogueOptions = catalogueOptions;
      this.selectedCatalogId = this.catalogueOptions?.[0]?.id;
      this.changeCatalog();
      this.loaded = true;
    })
  }

  public changeCatalog(): void {
    this.selectedCatalogHtml = `<iframe
      frameborder="0"
      width="100%"
      height="auto"
      title=""
      src="${this.catalogueOptions.find(o => o.id === this.selectedCatalogId).url}"
      type="text/html"
      scrolling="no"
      marginwidth="0"
      marginheight="0"
      allowFullScreen
    ></iframe>`;
  }
}
