import { AfterViewInit, Component, Directive, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';


@Directive()
export abstract class SearchComponent {
  @Input()
  placeholder = 'SEARCH';

  @Output()
  searchUpdated = new EventEmitter<string>();

  @Output()
  searchEnter = new EventEmitter<string>();

  @Input()
  initSearchTerm: string;

  @HostBinding('class') classes: string;
}

@Component({
  selector: 'lib-filter-list-search',
  templateUrl: './filter-list-search.component.html',
  styleUrls: ['./filter-list-search.component.css']
})
export class FilterListSearchComponent extends SearchComponent implements OnInit, OnDestroy {
  public searchTerm: string;
  public searchTermUpdate: Subject<string> = new Subject<string>();

  @ViewChild('searchInput') searchInput: ElementRef;

  ngOnInit() {
    this.reset();
    this.searchTermUpdate.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this.searchUpdated.emit(value)
      });

    if (this.initSearchTerm) {
      this.searchTerm = this.initSearchTerm;
      this.searchTermUpdate.next(this.initSearchTerm);
    }
  }

  ngOnDestroy(): void {
    // this.searchTermUpdate.next(''); // Should we clear the search term?
    this.searchTermUpdate.unsubscribe();
  }

  reset(): void {
    this.searchTerm = '';
  }

  public focusInput() {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 500);
  }

}
