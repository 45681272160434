import { Component, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs';
import { DashboardKpiService } from '../../../services/dashboard-kpi.service';
import { SalesSummaryEntity, Timespan } from '../../../types';

@Component({
  selector: 'lib-last-vs-this-year-sales-amount-summary-card',
  templateUrl: './last-vs-this-year-sales-amount-summary-card.component.html',
  styleUrls: ['./last-vs-this-year-sales-amount-summary-card.component.scss']
})
export class LastVsThisYearSalesAmountSummaryCardComponent implements OnInit {
  salesAmountThisYear = 0;
  salesAmountLastYear = 0;
  loaded = false;

  @Input()
  preConditions: any;

  constructor(protected dashboardKpiService: DashboardKpiService) {}

  ngOnInit() {
    const thisYearSpan = this.dashboardKpiService.getDateFromTimespan(Timespan.Year);
    const lastYearSpan = this.dashboardKpiService.getDateFromTimespan(Timespan.LastYear);

    forkJoin({
      salesSummaryGroupsThisYear: this.dashboardKpiService
        .getSalesSummary(SalesSummaryEntity.PRODUCT, thisYearSpan.fromDate, null, this.preConditions)
        .pipe(first()),
      salesSummaryGroupsLastYear: this.dashboardKpiService
        .getSalesSummary(SalesSummaryEntity.PRODUCT, lastYearSpan.fromDate, lastYearSpan.toDate, this.preConditions)
        .pipe(first())
    }).subscribe(({ salesSummaryGroupsThisYear, salesSummaryGroupsLastYear }) => {
      this.salesAmountThisYear = salesSummaryGroupsThisYear.reduce((acc, curr) => acc + curr.totalAmount, 0);
      this.salesAmountLastYear = salesSummaryGroupsLastYear.reduce((acc, curr) => acc + curr.totalAmount, 0);
      this.loaded = true;
    });
  }
}
