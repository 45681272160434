import { Injectable } from '@angular/core';
import {
  PriceService,
  SelectedCustomerService,
  ProductService,
  CartRow,
  CustomerProductPrice,
  CartRowPrice,
  GungFlowService,
  BackendPrice,
  Customer
} from 'gung-standard';
import { HttpClient } from '@angular/common/http';
import { first, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JeevesPriceService extends PriceService {
  constructor(
    http: HttpClient,
    customerService: SelectedCustomerService,
    productService: ProductService,
    gungFlowService: GungFlowService
  ) {
    super(http, customerService, productService, gungFlowService);
  }

  protected mapCustomerProductPrice(backendPrice: BackendPrice, customer: Customer, qty?: number) {
    const priceLevel = this.getPriceLevel(backendPrice, qty);

    const customerDiscountPercent = priceLevel.discount;

    // priceLevel.price is with discounts for Jeeves, which is why we calculate gross price by dividing with the
    // discount percent.
    const customerGrossPrice = !!priceLevel.price ? priceLevel.price / ((100 - customerDiscountPercent) / 100) : 0;
    const customerNetPrice = priceLevel.price;
    const recommendedRetailPrice = backendPrice.rrp || priceLevel.extra.ApproximatePrice || 0;

    const customerProductPrice: CustomerProductPrice = {
      productId: backendPrice.productId,
      customerId: customer.id,
      customerDiscountPercent,
      customerGrossPrice: {
        value: customerGrossPrice,
        currencyCode: customer.extra.kus.valkod
      },
      customerNetPrice: {
        value: customerNetPrice,
        currencyCode: customer.extra.kus.valkod
      },
      customerDiscountAmount: {
        value: customerGrossPrice - customerNetPrice,
        currencyCode: customer.extra.kus.valkod
      },
      backendPriceLevel: priceLevel,
      priceFactor: backendPrice.priceFactor,
      backendPrice: backendPrice
    };

    customerProductPrice.recommendedRetailPrice = {
      value: recommendedRetailPrice,
      currencyCode: customer.extra.kus.valkod
    };

    return customerProductPrice;
  }

  public getCartRowPrice(customerPrice: CustomerProductPrice, cartRow: CartRow): CartRowPrice {
   
    const baseCurrencyCode = customerPrice.customerNetPrice.currencyCode;

    const cartRowPrice: CartRowPrice = {
      ...customerPrice,
      productPartialId: cartRow.productPartialId,
      cartRowUnitPrice: customerPrice.customerGrossPrice,
      cartRowUnitPriceInclRowDiscount: customerPrice.customerNetPrice,
      quantity: cartRow.qty,
      cartRowTotalPrice: { value: 0, currencyCode: baseCurrencyCode },
      cartRowTotalPriceInclRowDiscount: { value: 0, currencyCode: baseCurrencyCode },
      cartRowDiscountPercent: customerPrice.customerDiscountPercent,
      cartRowDiscountAmountPerUnit: {
        value: customerPrice.customerDiscountAmount.value,
        currencyCode: baseCurrencyCode
      },
      cartRowDiscountAmountTotal: {
        value: customerPrice.customerDiscountAmount.value * cartRow.qty,
        currencyCode: baseCurrencyCode
      }
    };

    cartRowPrice.cartRowTotalPrice = {
      value: cartRowPrice.cartRowUnitPrice.value * cartRowPrice.quantity,
      currencyCode: cartRowPrice.cartRowUnitPrice.currencyCode
    };

    cartRowPrice.cartRowTotalPriceInclRowDiscount = {
      value: cartRowPrice.cartRowUnitPriceInclRowDiscount.value * cartRowPrice.quantity,
      currencyCode: cartRowPrice.cartRowTotalPriceInclRowDiscount.currencyCode
    };

    /**
     * Start checking cart row for custom values
     */
    if (cartRow.extra && (cartRow.extra.orp || cartRow.extra.modifiedPrice)) {
      if (cartRow.extra.orp.vb_pris) {
        cartRowPrice.cartRowUnitPrice = {
          ...cartRowPrice.cartRowUnitPrice,
          value: cartRow.extra.orp.vb_pris
        };
      } else if (cartRow.extra.modifiedPrice) {
        cartRowPrice.cartRowUnitPrice = {
          ...cartRowPrice.cartRowUnitPrice,
          value: cartRow.extra.modifiedPrice
        };
      }

      const baseDiscounts = {
        rabatt1: customerPrice.backendPriceLevel.extra.Discount1 || 0,
        rabatt2: customerPrice.backendPriceLevel.extra.Discount2 || 0,
        rabatt3: customerPrice.backendPriceLevel.extra.Discount3 || 0,
        kundrabatt: customerPrice.backendPriceLevel.extra.CustomerDiscount || 0,
        volymrabatt: customerPrice.backendPriceLevel.extra.VolumeDiscount || 0
      };

      const discountKeys = Object.keys(baseDiscounts);
      discountKeys.forEach(key => {
        let discountValue = parseFloat('' + baseDiscounts[key]);
        try {
          if (cartRow.extra.orp[key] || cartRow.extra.orp[key] === 0) {
            discountValue = parseFloat('' + cartRow.extra.orp[key]);
          }
        } catch (error) {
          console.error('Error parsing discount', error);
        }
        baseDiscounts[key] = discountValue;
      });

      cartRowPrice.cartRowDiscountPercent = this.calculateDiscountPercent(baseDiscounts);

      cartRowPrice.cartRowDiscountAmountPerUnit = {
        ...cartRowPrice.cartRowDiscountAmountPerUnit,
        value: cartRowPrice.cartRowUnitPrice.value * (cartRowPrice.cartRowDiscountPercent / 100)
      };

      cartRowPrice.cartRowDiscountAmountTotal = {
        ...cartRowPrice.cartRowDiscountAmountTotal,
        value: cartRowPrice.cartRowDiscountAmountPerUnit.value * cartRow.qty
      };
    }

    cartRowPrice.cartRowUnitPriceInclRowDiscount = {
      ...cartRowPrice.cartRowUnitPriceInclRowDiscount,
      value: cartRowPrice.cartRowUnitPrice.value - cartRowPrice.cartRowDiscountAmountPerUnit.value
    };

    cartRowPrice.cartRowTotalPrice = {
      ...cartRowPrice.cartRowTotalPrice,
      value: cartRowPrice.cartRowUnitPrice.value * cartRow.qty
    };

    cartRowPrice.cartRowTotalPriceInclRowDiscount = {
      ...cartRowPrice.cartRowTotalPriceInclRowDiscount,
      value: cartRowPrice.cartRowUnitPriceInclRowDiscount.value * cartRow.qty
    };

    return cartRowPrice;
  }

  protected calculateDiscountPercent(baseDiscounts: { [discountKey: string]: number }): number {
    const discountKeys = Object.keys(baseDiscounts);

    const totalDiscount = discountKeys.reduce((previousValue, currentValue) => {
      return previousValue * ((100 - baseDiscounts[currentValue]) / 100);
    }, 100);

    return 100 - totalDiscount;
  }

  protected internalHttpGetCustomerPrices(
    productIds: string[],
    customerId: string
  ): Observable<{ [productId: string]: BackendPrice }> {
    const newListProductsIds = productIds.filter(productId => !this.cachedCustomerPrices[productId]);

    return this.gungFlowService.getSelectedFlow().pipe(
      first(),
      switchMap(selectedFlow =>
        newListProductsIds.length > 0
          ? this.http.post<{ [productId: string]: BackendPrice }>(
              `json/product-price-customer/${customerId}?flowId=${selectedFlow.id}`,
              newListProductsIds
            )
          : of(null)
      ),
      map((customerPrices: { [productId: string]: BackendPrice }) => {
        // in case any data from backend
        // then store it in cache
        if (!!customerPrices) {
          for (const customerPriceKey of Object.keys(customerPrices)) {
            this.cachedCustomerPrices[customerPriceKey] = customerPrices[customerPriceKey];
          }
        }

        // then return the data for requested productIds
        const toReturn: { [productId: string]: BackendPrice } = {};
        for (const productId of productIds) {
          toReturn[productId] = this.cachedCustomerPrices[productId];
        }

        return toReturn;
      })
    );
  }

}
