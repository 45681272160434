<div class="container mt-5">
  <div class="mb-4">
    <h2 class="text-center">
      <span translate>ADYEN_PAYMENT_FAILED</span>
    </h2>
    <p class="text-center" translate>ADYEN_PAYMENT_FAILED_INFO</p>
    <div class="row">
      <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
        <a class="btn btn-primary btn-block" routerLink="/checkout" translate>RETURN_TO_CART</a>
      </div>
    </div>
  </div>
</div>
