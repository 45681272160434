import { Injectable, Type } from '@angular/core';
import { Observable, first, forkJoin, map } from 'rxjs';
import { CheckoutConfigService } from './checkout-config/checkout-config.service';
import { BulkPlanComponent } from '../components/bulk-checkout/bulk-plan/bulk-plan.component';
import { BulkSummaryComponent } from '../components/bulk-checkout/bulk-summary/bulk-summary.component';
import { StepIndicatorComponent } from './../components/step-indicator/step-indicator.component';
import { CheckoutStepComponent } from './../components/checkout/steps/base/checkout-step.component';
import { ButtonsComponent } from './../components/checkout/buttons/buttons.component';
import { BulkCheckoutButtonsComponent } from '../components/bulk-checkout/bulk-checkout-buttons/bulk-checkout-buttons.component';
import { CheckoutObject } from '../models/checkout';
import { SimplifiedOrderContext } from '../models/simplifiedOrderContext';

@Injectable({
  providedIn: 'root'
})
export class BulkCheckoutConfigService {
  constructor(
    protected checkoutConfigService: CheckoutConfigService
  ) {}

  getCheckoutSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return forkJoin([this.getPreProcessSteps(), this.getMainProcessSteps(), this.getPostProcessSteps()]).pipe(
      map(([pre, main, post]) => [...pre, ...main, ...post])
    );
  }

  getPreProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return this.checkoutConfigService.getPreProcessSteps();
  }

  getMainProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return this.checkoutConfigService.getBulkOrderERPSteps().pipe(
      first(),
      map(ERPSteps => {
        return [
          BulkPlanComponent,
          BulkSummaryComponent,
          ...ERPSteps
        ]
      })
    );
  }

  getPostProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return this.checkoutConfigService.getPostProcessSteps();
  }

  getStepIndicator(): Type<StepIndicatorComponent> {
    return this.checkoutConfigService.getStepIndicator();
  }

  getButtonsComponent(): Type<ButtonsComponent> {
    return BulkCheckoutButtonsComponent;
  }
  
  getSubmitUrl(checkoutObject: CheckoutObject = null): Observable<string> {
    return this.checkoutConfigService.getSubmitUrl(checkoutObject);
  }

  sumbitOffer(checkoutObject: CheckoutObject): Observable<SimplifiedOrderContext> {
    return this.checkoutConfigService.sumbitOffer(checkoutObject)
  }

  onOrderSubmitted(context: SimplifiedOrderContext, isOffer = false): void {
    this.checkoutConfigService.onOrderSubmitted(context, isOffer);
  }

  getShowOrderSubmitError(): Observable<boolean> {
    return this.checkoutConfigService.getShowOrderSubmitError();
  }
}