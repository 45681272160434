import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubspotContacts } from '../../models/hubspot-contacts';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HubspotContactService {
 
  constructor(protected http: HttpClient) { }

  getHubspotContacts(customerId:string):Observable<HubspotContacts[]>{
    const url = 'json/hubspot/contacts/by-company-erp-id?companyErpId=' + customerId;
    return this.http.get<HubspotContacts[]>(url);
  }

}
