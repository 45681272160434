import { Component } from '@angular/core';
import { EdiEanRepositoryListConfigService } from '../../../services/edi-ean-repository-list-config/edi-ean-repository-list-config.service';

@Component({
  selector: 'lib-edi-ean-repository',
  template: `<h3 class="d-block page-title text-center" translate>Ean Repository</h3>
    <lib-filter-list [configService]="ediEanRepositoryListConfigService"></lib-filter-list>`,
  styleUrls: ['./edi-ean-repository.component.css']
})
export class EdiEanRepositoryComponent {
  constructor(public ediEanRepositoryListConfigService: EdiEanRepositoryListConfigService) {}
}
