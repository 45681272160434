<lib-navbar *ngIf="showNavbar" class="navbar-login-component" (navbarHeight)="setContainerMarginTop($event)">
</lib-navbar>
<div class="login-gung-theme" [ngStyle]="containerStyles">
  <div [ngSwitch]="loginType">
    <sjoborg-fashion-login></sjoborg-fashion-login>
  </div>
</div>
<footer class="d-flex justify-content-center align-items-center">
  <a href="https://www.gung.se">www.gung.se</a>
</footer>
