<ng-template #quantityValidationModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" translate>VALIDATION_IN_PROGRESS</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p translate>
      VALIDATION_SECTION_1
      <!--The system is checking the availability of the products in your order.-->
    </p>
    <div
      class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
      role="progressbar"
      aria-valuenow="75"
      aria-valuemin="0"
      aria-valuemax="100"
      style="width: 75%; height: 10px"
    ></div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('Cancel Validation')" translate>
      VALIDATION_CANCEL_BUTTON
    </button>
  </div>
</ng-template>

<div class="table-responsive gung-responsive">
  <table class="table">
    <thead>
      <tr>
        <th translate>PRODUCT</th>
        <th translate>QUANTITY_IN_ORDER</th>
        <th translate>MESSAGE</th>
        <th translate>ACTIONS</th>
      </tr>
    </thead>
    <tbody *ngFor="let validation of validationResults">
      <tr>
        <td [attr.data-label]="'PRODUCT' | translate">{{ validation.product.id }} - {{ validation.product.name }}</td>
        <td [attr.data-label]="'QUANTITY_IN_ORDER' | translate">
          {{ validation.row.quantity | number: '1.0' }}
        </td>
        <td [attr.data-label]="'MESSAGE' | translate">
          {{ validation.description }}
        </td>
        <td class="form-group" [attr.data-label]="'ACTIONS' | translate">
          <select [(ngModel)]="validation.selectedAction" class="form-control form-select form-control form-select-sm">
            <option *ngFor="let action of validation.actions" [ngValue]="action">
              {{ action.description }}
            </option>
          </select>
        </td>
      </tr>
    </tbody>
  </table>
</div>
