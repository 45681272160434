import { Injectable } from '@angular/core';
import {
  NavbarConfigService,
  NavGroup,
  NavLink,
  NavAction,
  Customer
} from 'gung-standard';
import { Observable } from 'rxjs';
import { mergeMap, first, map, filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SjoborgNavbarConfigService extends NavbarConfigService {
  enableActivityInternal = true;
  enableAccountRequestManage = true;
  public getLeftMenus(): Observable<NavGroup[]> {
    const baseMenu: NavGroup[] = [
      {
        heading: 'QUICK_SEARCH',
        link: '/quick-products'
      },
      {
        heading: 'NEWS',
        link: '/news'
      }
    ];

    return this.authService.getCurrentUser().pipe(
      filter(user => !!user),
      first(),
      mergeMap(user => {
        return this.assortmentService.getAssortmentRecursive(user.assortment).pipe(first());
      }),
      map(assortment => {
        const productsMenu = {
          heading: 'PRODUCTS',
          link: '/articles',
          links: undefined
        } as NavGroup;
        if (assortment && assortment.children) {
          productsMenu.links = assortment.children.map(
            childAssortment =>
              ({
                heading: childAssortment.name,
                link: `/articles/${childAssortment.id}`,
                links: childAssortment.children.map(
                  subChildAssortment =>
                    ({
                      heading: subChildAssortment.name,
                      link: `/articles/${childAssortment.id}/${subChildAssortment.id}`
                    } as NavLink)
                )
              } as NavGroup)
          );
        }
        baseMenu.unshift(productsMenu);
        return baseMenu;
      })
    );
  }

  protected getUserMenu(customer: Customer): (NavLink | NavAction | NavGroup)[] {
    const menuLinks = super.getUserMenu(customer);
    (menuLinks[0] as NavGroup).links.splice(
      (menuLinks[0] as NavGroup).links.findIndex(m => m.heading === 'SAVED_QUOTES'),
      1
    );

    if (this.isEnabledShowUserNewsInPage) {
      (menuLinks[0] as NavGroup).links.push({ link: '/news', heading: 'NEWS' });
    }

    return menuLinks;
  }
}
