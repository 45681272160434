import { Injectable } from '@angular/core';
import { gungComparatorHelper, gungGetPropertyOfObject } from 'gung-common';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupplierSortColumnService {

  private selectedColumnSort: Subject<any> = new BehaviorSubject<any>(null);

  constructor() { }


  setSelectedColumnSort(column){
    this.selectedColumnSort.next(column);
  }

  public getSelectedColumnSort(): Observable<any> {
    return this.selectedColumnSort.asObservable();
  }

  public clearSelectedColumnSort() {
    this.selectedColumnSort.next(null);
  
  }

  public sortItemsByColumn(data: any[], column): any[] {
    if(!column){
      return data;
    }
    const ascSort = !column.sort || column.sort === 'asc' ? -1 : 1;
    const path = Array.isArray(column.path) ? column.path[0] : column.path
    return data.sort((a, b) => {
      const aValue2 = gungGetPropertyOfObject(path, a);
      const bValue2 = gungGetPropertyOfObject(path, b);
      return gungComparatorHelper(aValue2, bValue2, ascSort);
    });
  }
}
