import { Component, OnInit } from '@angular/core';
import { first, forkJoin } from 'rxjs';
import { DateUtilService } from 'gung-common';
import { CheckoutStepComponent } from '../../checkout/steps/base/checkout-step.component';
import { Product } from '../../../models';
import { CustomerProductPrice } from '../../../models/price';
import { CartsService } from '../../../services/carts/carts.service';
import { ProductService } from '../../../services/products/product.service';
import { PriceService } from '../../../services/price/price.service';
import { PriceConfigService } from '../../../services/price-config/price-config.service';
import { CartListCheckoutConfigService } from '../../../services/cart-list-checkout-config/cart-list-checkout-config.service';
import { CheckoutRow } from '../../../models/checkout';
import { gungSetPropertyOfObject } from '../../../utils/gung-utils';

@Component({
  selector: 'lib-bulk-summary',
  templateUrl: './bulk-summary.component.html',
  styleUrls: ['./bulk-summary.component.css']
})
export class BulkSummaryComponent extends CheckoutStepComponent implements OnInit {
  productData: { [s: string]: { product: Product; price: CustomerProductPrice } } = {};
  productsIdsToGet = [];
  summaryTable;
  constructor(
    protected savedCartService: CartsService,
    protected productService: ProductService,
    protected priceService: PriceService,
    protected priceConfigService: PriceConfigService,
    protected cartListCheckoutConfigService: CartListCheckoutConfigService,
    protected dateUtilService: DateUtilService
  ) {
    super();
  }

  ngOnInit() {
    this.setCheckoutRows();
    forkJoin({
      products: this.productService.getFullProductsByIds(this.productsIdsToGet).pipe(first()),
      priceService: this.priceService.getCurrentCustomerPrices(this.productsIdsToGet).pipe(first())
    }).subscribe(({ products, priceService }) => {
      for (const id of this.productsIdsToGet) {
        this.productData[id] = {
          price: priceService.find(p => p.productId === id),
          product: products.find(p => p.id === id)
        };
      }
      this.createSummaryTable();
    });

    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked());
  }

  public isVisible() {
    return true;
  }

  public getLabel(): string {
    return 'SUMMARY';
  }

  setCheckoutRows() {
    this.checkout.rows = [];
    for (const day of this.checkout.extra._bulk) {
      for (const cart of day.carts) {
        if (cart.data && cart.data.rows) {
          for (const row of cart.data.rows) {
            const pToAdd: CheckoutRow = {
              extra: {},
              id: row.productId,
              quantity: row.qty,
              targetStockId: row.targetStockId
            };

            const stringDate: string = `${day.year}-${this.addZerobefore(day.month)}-${this.addZerobefore(day.day)}`;

            gungSetPropertyOfObject(
              pToAdd.extra,
              this.cartListCheckoutConfigService.deliveryDateField,
              this.dateUtilService.getFormattedDateString(new Date(stringDate), this.cartListCheckoutConfigService.saveDateFormat)
            );

            this.checkout.rows.push(pToAdd);
            if (!this.productsIdsToGet.includes(row.productId)) {
              this.productsIdsToGet.push(row.productId);
            }
          }
        }
      }
    }
  }

  createSummaryTable() {
    this.summaryTable = JSON.parse(JSON.stringify(this.checkout.extra._bulk));
    let totalCheckout = 0;
    for (const day of this.summaryTable) {
      for (const cart of day.carts) {
        if (cart.data && cart.data.rows) {
          let totalCart = 0;
          let totalQty = 0;
          for (const row of cart.data.rows) {
            totalCart += this.priceConfigService.getStaffedPrice(this.productData[row.productId].price, undefined, row.qty).price * row.qty;
            row.extra.price = this.productData[row.productId].price;
            totalQty += row.qty;
            row.extra.totalPrice =  {
              value: this.priceConfigService.getStaffedPrice(this.productData[row.productId].price, undefined, row.qty).price * row.qty,
              currencyCode: this.productData[row.productId].price.customerNetPrice.currencyCode
            }
            if (!cart.extra.totalCurrency) {
              cart.extra.totalCurrency = this.productData[row.productId].price.customerNetPrice.currencyCode;
            }
          }
          cart.extra.totalCart = totalCart;
          totalCheckout += totalCart;
          cart.extra.totalQty = totalQty;
        }
      }
    }
    this.checkout.extra._total = totalCheckout;
  }

  addZerobefore(value: number): string {
    if (value.toString().length === 1) {
      return `0${value}`;
    }
    return value.toString();
  }

  handleNextButtonClicked() {
    this.stepDone.emit(this.checkout);
  }
}
