import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption, SimpleConfigBaseFilter } from 'gung-list';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Planogram } from '../models/planogram';
import { PlanogramService } from './planogram.service';
import { PlanogramGridViewComponent } from '../components/planogram-grid-view/planogram-grid-view.component';

export class PlanogramFieldFilter extends SimpleConfigBaseFilter<Planogram> {
  constructor(protected name, protected field) {
    super();
  }

  getName(): string {
    return this.name;
  }

  getOptionIds(item: Planogram): string[] {
    if (item[this.field]) {
      return [item[this.field]];
    }
    return [];
  }

  getOptionName(key: string): string {
    return key
  }
}


@Injectable({
  providedIn: 'root'
})
export class PlanogramListConfigService implements ConfigService<Planogram> {

  topFilter =  true;
  public updateList: Subject<any> = new ReplaySubject<any>();

  constructor(protected planogramService: PlanogramService) {}

  getFilters(): ConfigBaseFilter<Planogram>[] {
    return [
      new PlanogramFieldFilter('FURNITURE', 'furniture'),
      new PlanogramFieldFilter('CUSTOMER', 'customer'),
      new PlanogramFieldFilter('COUNTRY', 'country'),
      new PlanogramFieldFilter('YEAR', 'year'),
      new PlanogramFieldFilter('PERIOD', 'period')
    ];
  }

  getSortOptions(): ListSortOption<Planogram>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: Planogram) {
    return item.id;
  }

  getSearchTerms(item: Planogram) {
    return [item.id, item.name];
  }

  getLayouts(): ListLayout<Planogram>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => PlanogramGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'planogram'
      }
    ];
  }

  getItems(): Observable<Planogram[]> {
    return this.planogramService.getAllPlanograms();
  }

  getNothingFoundTranslateTag() {
    return null;
  }

  /* getSearchGroupCss() {
    return 'hide-search';
  } */

  getUdatedList(): Observable<any> {
    return this.updateList.asObservable();
  }
}