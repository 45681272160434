<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>CREATE_NEW_CUSTOMER</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="form" [formGroup]="customerForm">
    <div class="row">
      <div class="col">
        <h5 translate>BILLING_ADDRESS</h5>

        <div [formGroupName]="'extra'">
          <div [formGroupName]="'fr'" class="">
            <div class="form-group">
              <label for="ftgnamn" class="control-label" translate>FR_FTGNAMN</label>
              <input
                class="form-control"
                type="text"
                name="ftgpostadr2"
                id="ftgnamn"
                formControlName="ftgnamn"
                ngbAutofocus
              />
            </div>
          </div>

          <div class="form-row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
              <div [formGroupName]="'fr'" class="">
                <div class="form-group">
                  <label for="ftgpostadr1" class="control-label" translate>FR_FTGPOSTADR1</label>
                  <input
                    class="form-control"
                    type="text"
                    name="ftgpostadr1"
                    id="ftgpostadr1"
                    formControlName="ftgpostadr1"
                  />
                </div>
              </div>
            </div>

            <div class="d-none d-lg-block col-lg-6"></div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
              <div [formGroupName]="'fr'" class="">
                <div class="form-group">
                  <label for="ftgpostadr2" class="control-label" translate>FR_FTGPOSTADR2</label>
                  <input
                    class="form-control"
                    type="text"
                    name="fr.ftgpostadr2"
                    id="ftgpostadr2"
                    formControlName="ftgpostadr2"
                  />
                </div>
              </div>
            </div>
            <div class="col-4 col-sm-4 col-md-3 col-lg-2">
              <div [formGroupName]="'fr'" class="">
                <div class="form-group">
                  <label for="ftgpostnr" class="control-label" translate>FR_FTGPOSTNR</label>
                  <input class="form-control" type="text" name="ftgpostnr" id="ftgpostnr" formControlName="ftgpostnr" />
                </div>
              </div>
            </div>
            <div class="col-8 col-sm-8 col-md-9 col-lg-4">
              <div [formGroupName]="'fr'" class="">
                <div class="form-group">
                  <label for="ftgpostadr3" class="control-label" translate>FR_FTGPOSTADR3</label>
                  <input
                    class="form-control"
                    type="text"
                    name="ftgpostadr3"
                    id="ftgpostadr3"
                    formControlName="ftgpostadr3"
                  />
                </div>
              </div>
            </div>
          </div>

          <h5 class="mt-2" translate>DELIVERY_ADDRESS</h5>
          <div class="form-row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
              <div [formGroupName]="'fr'" class="">
                <div class="form-group">
                  <label for="ftgpostadr5" class="control-label" translate>FR_FTGPOSTADR2</label>
                  <input
                    class="form-control"
                    type="text"
                    name="ftgpostadr5"
                    id="ftgpostadr5"
                    formControlName="ftgpostadr5"
                  />
                </div>
              </div>
            </div>
            <div class="col-4 col-sm-4 col-md-3 col-lg-2">
              <div [formGroupName]="'fr'" class="">
                <div class="form-group">
                  <label for="ftglevpostnr" class="control-label" translate>FR_FTGPOSTNR</label>
                  <input
                    class="form-control"
                    type="text"
                    name="ftglevpostnr"
                    id="ftglevpostnr"
                    formControlName="ftglevpostnr"
                  />
                </div>
              </div>
            </div>
            <div class="col-8 col-sm-8 col-md-9 col-lg-4">
              <div [formGroupName]="'fr'" class="">
                <div class="form-group">
                  <label for="ftgpostlevadr3" class="control-label" translate>FR_FTGPOSTADR3</label>
                  <input
                    class="form-control"
                    type="text"
                    name="ftgpostlevadr3"
                    id="ftgpostlevadr3"
                    formControlName="ftgpostlevadr3"
                  />
                </div>
              </div>
            </div>
          </div>

          <h5 class="mt-2" translate>CONTACT</h5>
          <div class="form-row">
            <div class="col-12 col-sm-6 col-md-12">
              <div [formGroupName]="'contact'" class="">
                <div class="form-group">
                  <label for="name" class="control-label" translate>NAME</label>
                  <input class="form-control" type="text" name="name" id="name" formControlName="name" />
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-8">
              <div [formGroupName]="'contact'" class="">
                <div class="form-group">
                  <label for="mail" class="control-label" translate>EMAIL</label>
                  <input class="form-control" type="text" name="mail" id="mail" formControlName="mail" />
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <div [formGroupName]="'contact'" class="">
                <div class="form-group">
                  <label for="phone" class="control-label" translate>PHONE</label>
                  <input class="form-control" type="text" name="phone" id="phone" formControlName="phone" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-light mr-auto" aria-label="Close" (click)="activeModal.close()" translate>
    CANCEL
  </button>
  <button type="button" class="btn btn-primary ml-auto" (click)="submitForm()" translate>CREATE_CUSTOMER</button>
</div>
