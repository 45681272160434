<div class="row" [ngClass]="{ 'mt-4 mb-4': mappedData && mappedData.length > 0 }">
  <div *ngFor="let data of mappedData; last as isLast" class="col-6 col-md-4 col-lg-{{ itemsPerRow }} mb-2">
    <div class="card product-card">
      <div class="card-body">
        <div>
          <span class="favourite-icon-wrapper">
            <lib-favourite-product-icon [id]="data.product.id"></lib-favourite-product-icon>
          </span>
        </div>
        <div (click)="goToDetail(data.product)">
          <lib-image-carousel-minimal
            [images]="data.product.extra.images"
            imageClass="image-wrapper"
          ></lib-image-carousel-minimal>
        </div>
        <div class="product-text pb-1">
          <div class="small font-weight-light">{{ data.product.id }}</div>
          <div class="text-truncate">{{ data.product.name }}</div>
          <!-- <div translate *ngIf="data.price" class="font-weight-bold">
            {{ data.price.customerNetPrice.value | gungCurrency: data.price.customerNetPrice.currencyCode }}</div> -->
          <div>
            <lib-price
              [price]="data.price"
              type="NET"
              [currency]="data.price.customerNetPrice.currencyCode"
              class="font-weight-bold"
            ></lib-price
            >&nbsp;
            <span *ngIf="data.price.customerDiscountPercent !== 0">(-{{ data.price.customerDiscountPercent }}%)</span>
          </div>
          <div>
            <lib-availability [availability]="data.availability" [product]="data.product" [isSales]="isSales" *ngIf="includeAvailability">
            </lib-availability>
          </div>
          <div class="product-card-button">
            <lib-buy-btn [id]="data.product.id" [product]="data.product"></lib-buy-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="findError" class="col-12 mb-2 text-center">
    {{ errorMessage }}
  </div>
</div>
