import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap, catchError } from 'rxjs';

export interface StockTaking {
  id?: string;
  name: string;
  responsible: string;
  status?: StockTakingStatus;
  date: Date;
  rows: StockTakingRow[];
  extra?: any;
}

export interface StockTakingRow {
  id?: string;
  productId: string;
  stockPointId: string;
  // Only add this if there is a specific location - setting to a blank string will not work.
  stockLocationId?: string;
  countedBy: string;
  countedQuantity: number;
}

export enum StockTakingStatus {
  DRAFT,
  STARTED,
  COMPLETED
}

@Injectable({
  providedIn: 'root'
})
export class ProductionManagementStockCountingService {

  // Define the API endpoint
  private apiUrl = 'json/production/stock-takings';

  // Cache variable
  private cache: StockTaking[] = [];

  constructor(private http: HttpClient) {}

  // Fetch all items with caching
  getAllItems(): Observable<StockTaking[]> {
    if (this.cache.length > 0) {
      return of(this.cache);
    } else {
      return this.http.get<StockTaking[]>(this.apiUrl).pipe(
        tap(items => (this.cache = items)),
        catchError(this.handleError<StockTaking[]>('getAllItems', []))
      );
    }
  }

  // Fetch item by ID
  getItemById(id: number): Observable<StockTaking> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<StockTaking>(url).pipe(catchError(this.handleError<StockTaking>(`getItemById id=${id}`)));
  }

  // Create a new item
  createItem(item: StockTaking): Observable<any> {
    return this.http.post<StockTaking>(this.apiUrl, item).pipe(
      tap(newItem => {
        this.cache.push(newItem);
      }),
      catchError(this.handleError<any>('createItem'))
    );
  }

  // Update an existing item
  updateItem(updatedItem: StockTaking): Observable<any> {
    const url = `${this.apiUrl}`;
    return this.http.post(url, updatedItem).pipe(
      tap(_ => {
        const index = this.cache.findIndex(item => item.id === updatedItem.id);
        if (index !== -1) {
          // this.cache[index] = updatedItem;
        }
      }),
      catchError(this.handleError<any>(`updateItem id=${updatedItem.id}`))
    );
  }

  // Handle HTTP errors
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
