import { Injectable } from '@angular/core';
import { ProductConfigurationOptionPriceService } from './product-configuration-option-price.service';
import { first, forkJoin, Observable, of, switchMap } from 'rxjs';
import { CartRow } from '../../../state/cart/types';
import { CheckoutRow } from '../../../models/checkout';
import { CartRowFeatureOptionSelection } from '../models/product-configuration-types';

@Injectable({
  providedIn: 'root'
})
export class ProductConfigurationCartRowService {
  constructor(protected optionPriceService: ProductConfigurationOptionPriceService) {}

  public isProductConfiguration(row: CartRow | CheckoutRow) {
    // If the row has a field called productConfigurationSelectedOptions in its extra we can assume that it is a product configuration.
    return !!this.getProductConfigurationSelectedOptions(row);
  }

  public getCartRowPrice(row: CartRow): Observable<number> {
    const priceRequests = this.getProductConfigurationSelectedOptions(row).map(o =>
      this.optionPriceService.getPrice(o.option).pipe(first())
    );

    return forkJoin(priceRequests).pipe(
      switchMap(prices => {
        const total = prices.reduce((acc, p) => p.price + acc, 0.0);
        return of(total);
      })
    );
  }

  private getProductConfigurationSelectedOptions(row: CartRow | CheckoutRow): CartRowFeatureOptionSelection[] {
    return row.extra.productConfigurationSelectedOptions;
  }
}
