import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Customer, CustomerService, GungModalService, SelectedCustomerService, gungAddRemoveSpinner } from 'gung-standard';
import { JeevesClaimConfigListService } from '../../services/jeeves-claim-config-list/jeeves-claim-config-list.service';
import { first } from 'rxjs';
import { JeevesModalService } from '../../services/jeeves-modal-service/jeeves-modal.service';

@Component({
  selector: 'gung-jeeves-jeeves-claim-list',
  template: `
    <div class="row">
      <div class="col text-center"><h2 translate>CLAIMS</h2></div>
    </div>
    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-primary float-right" (click)="createClaim($event)" translate>
          CREATE_CLAIM
        </button>
      </div>
    </div>
    <lib-filter-list #list [configService]="listConfigService"></lib-filter-list>
  `,
  styleUrls: ['./jeeves-claim-list.component.css']
})
export class JeevesClaimListComponent implements OnInit {
  @Input()
  customerId?: string;

  currentCustomer: Customer;

  constructor(
    public listConfigService: JeevesClaimConfigListService,
    protected ngbModal: NgbModal,
    protected jeevesModalService: JeevesModalService,
    protected modalService: GungModalService,
    protected selectedCustomerService: SelectedCustomerService,
    protected customerService: CustomerService,
    protected translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.listConfigService.customerId = this.customerId;
    if (this.customerId) {
      this.customerService
        .getCustomer(this.customerId)
        .pipe(first())
        .subscribe(customer => (this.currentCustomer = customer));
    } else {
      this.selectedCustomerService
        .getSelectedCustomer()
        .pipe(first())
        .subscribe(customer => (this.currentCustomer = customer));
    }
  }

  createClaim(event) {
    gungAddRemoveSpinner(event.target);
    this.jeevesModalService
      .openClaimModal(this.currentCustomer)
      .then(
        answer => {
          if (answer) {
            const rmanr =
              answer['#result-set-1'] && answer['#result-set-1'].length > 0 && answer['#result-set-1'][0][''];
            this.modalService
              .openConfirmYesNoModal(
                null,
                this.translateService.instant('CLAIM_CREATED_SUCCESS', { id: rmanr || '' }),
                null,
                'OK',
                null
              )
              .then(_ => {});
            gungAddRemoveSpinner(event.target);
          } else {
            gungAddRemoveSpinner(event.target);
          }
        },
        error => {
          gungAddRemoveSpinner(event.target);
        
        }
      )
      .catch(res => gungAddRemoveSpinner(event.target));
  }
}
