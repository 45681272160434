<div *ngIf="!!data && !!groupDataKeys">
    <div class="row">
      <div class="col-12 table-responsive gung-responsive" style="overflow-x: unset;">
        <table class="table">
          <thead>
            <tr>
              <!-- <th  class="">
                <span translate></span>
              </th> -->
              <th  class="">
                <span translate>ORDER_NUMBER</span>
              </th>
              <th  class="">
                <span translate>SUPPLIER</span>
              </th>
              <th  class="">
                <span translate>ORDER_DATE</span>
              </th>
              <th  class="">
                <span translate>REQUESTED_EX_FACTORY_DATE</span>
              </th>
              <th  class="">
                <span translate>FIRST_ITEM_NAME</span>
              </th>
              <th  class="">
                <span translate>NOTE_OF_GOODS</span>
              </th>
              <th  class="">
                <span translate>LOCATION_CODE</span>
              </th>
              <th  class="">
                <span translate>TOTAL_QUANTITY</span>
              </th>
              <th  class="">
                <span translate>TOTAL_PRICE</span>
              </th>
              <!-- <th  class="">
                <span translate></span>
              </th> -->
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let key of groupDataKeys">
                <ng-container *ngIf="groupData[key] as rowGroup">
                    <tr>
                        <td>
                            <button type="button" class="btn btn-link"
                                (click)="loadProductData(rowGroup, row.classList.contains('d-none')); row.classList.toggle('d-none'); icon.classList.toggle('fa-arrow-turn-up'); icon.classList.toggle('fa-arrow-turn-down');">
                                <i #icon class="fa-solid fa-arrow-turn-up fa-rotate-180"></i>
                            </button>
                            {{key}}
                        </td>
                        <td>
                            <b>{{rowGroup[0].supplierId}}</b>
                            <br>{{rowGroup[0].extra._supplierName}}
                        </td>
                        <td>
                            {{rowGroup[0].extra.gungPurchaseOrder.orderDate}}
                        </td>
                        <td>
                            {{rowGroup[0].extra.gungPurchaseLine.Requested_Ex_Factory_Date}}
                        </td>
                        <td>
                            <b>{{ getValues(rowGroup[0], 'extra.gungPurchaseLine.itemNo') }}</b>
                            <br>{{rowGroup[0].extra.gungPurchaseLine.description}}
                        </td>
                        <td>
                            {{rowGroup[0].extra?.gungPurchaseOrder?.noteofGoods}}
                        </td>
                        <td>
                            {{rowGroup[0].extra?.gungPurchaseOrder?.locationCode}}
                        </td>
                        <td>
                            {{rowGroup[0].extra.totalQuantity}}
                        </td>
                        <td>
                            <lib-price [price]="getValues(rowGroup[0], 'extra.gungPurchaseOrder.totalAmountExcludingTax')" [currency]="rowGroup[0].extra.gungPurchaseOrder.currencyCode"></lib-price>
                        </td>
                        <!-- <td>
                            <lib-action-btn [item]="rowGroup" [config]="supplierPoActionConfigService"></lib-action-btn>
                        </td> -->
                    </tr>
                    <tr #row class="d-none">
                        <td colspan="10" class="p-0" *ngIf="!isLoading; else loading" >
                            <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <!-- <th scope="col" class="">
                                        <span translate><!-- CHECKBOX -- ></span>
                                    </th> -->
                                    <th scope="col" class="">
                                        <span translate>PO_NUMBER</span>
                                    </th>
                                    <th scope="col" class="">
                                        <span translate>REQUESTED_EX_FACTORY_DATE</span>
                                    </th>
                                    <th scope="col" class="">
                                        <span translate>ITEM</span>
                                    </th>
                                    <th scope="col" class="">
                                        <span translate>QUANTITY_ORDERED</span>
                                    </th>
                                    <th scope="col" class="">
                                        <span translate>PRICE</span>
                                    </th>
                                    <th scope="col" class="">
                                        <span translate>TOTAL_PRICE</span>
                                    </th>
                                    <!-- <th scope="col" class="">
                                        <span translate>QUANTITY_CONFIRM</span>
                                    </th>
                                    <th scope="col" class="">
                                        <span translate>CONFIRM_READY_DATE</span>
                                    </th>
                                    <th scope="col" class="">
                                        <span translate></span>
                                    </th> -->
                                  </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of rowGroup; index as i; trackBy: trackByFn" [ngClass]="{ 'line-checked': !!row.checked }">
                                        <!-- <td>
                                            <lib-gung-checkbox-input-horizontal suppressLabel="true" [(ngModel)]="row.checked"></lib-gung-checkbox-input-horizontal>
                                        </td> -->
                                        <td>
                                            {{getValues(row, 'extra.gungPurchaseLine.sequence')}}
                                        </td>
                                        <td>
                                            {{ getValues(row, 'extra.gungPurchaseLine.Requested_Ex_Factory_Date') | date: dateUtilService.dateFormat }}
                                        </td>
                                        <td>
                                            <b>{{ getValues(row, 'extra.gungPurchaseLine.itemNo') }}-{{ getValues(row, 'extra.gungPurchaseLine.variantCode') }}</b>
                                            <br>{{ getValues(row, 'extra.gungPurchaseLine.description') }}
                                            <br><small>{{getValues(row, 'extra._gung.primaryDimensionName')}} {{getValues(row, 'extra._gung.secondaryDimensionName')}}</small>
                                        </td>
                                        <td>
                                            {{ getValues(row, 'extra.gungPurchaseLine.quantity') | number: '1.0-2' }}
                                        </td>
                                        <td>
                                            <!-- <lib-price [price]="getValues(row, 'extra.gungPurchaseLine.unitPrice')" [currency]="row.extra.gungPurchaseOrder.currencyCode || row.currency"></lib-price> -->
                                            <lib-price [price]="calculatePrice(getValues(row, 'extra.gungPurchaseLine.lineAmount'), getValues(row, 'extra.gungPurchaseLine.quantity'))" [currency]="row.extra.gungPurchaseOrder.currencyCode || row.currency"></lib-price>
                                        </td>
                                        <td>
                                            <lib-price [price]="getValues(row, 'extra.gungPurchaseLine.lineAmount')" [currency]="row.extra.gungPurchaseOrder.currencyCode || row.currency"></lib-price>
                                            <span *ngIf="getValues(row, 'extra.gungPurchaseLine.discountAmount') !== 0">
                                                ({{ getValues(row, 'extra.gungPurchaseLine.discountAmount') }} %)
                                            </span>
                                        </td>
                                        <!-- <td>
                                            <lib-gung-number-input
                                                suppressLabel="true"
                                                [ngModel]="getValues(row, 'extra.gungPurchaseLine_qtyToReceive')"
                                                (ngModelChange)="setValue(row, 'extra.gungPurchaseLine_qtyToReceive', (+$event))"
                                                [extraInputClass]="row.extra.errors?.includes('extra.gungPurchaseLine_qtyToReceive') ? 'form-control-sm border border-danger' : 'form-control-sm'"
                                                [maxValue]="row.extra.gungPurchaseLine.quantity"
                                                [minValue]="0"
                                            ></lib-gung-number-input>
                                        </td> -->
                                        <!-- <td>
                                            <lib-gung-date-picker
                                                [minInitialDate]="today"
                                                [currentselectedDate]="getValues(row, 'extra.gungPurchaseLine_promisedReceiptDate')"
                                                (selectedDate)="setValue(row, 'extra.gungPurchaseLine_promisedReceiptDate', dateUtilService.getFormattedDateString($event))"
                                                suppressLabel="true"
                                                [extraInputClass]="row.extra.errors?.includes('extra.gungPurchaseLine_promisedReceiptDate') ? 'form-control-sm border border-danger' : 'form-control-sm'"
                                                extraBtnClass="btn-sm">
                                            </lib-gung-date-picker>
                                        </td> -->
                                        <!-- <td>
                                            <button *ngIf="column.path.includes('btnHistory') && detailsColumn" type="button" class="btn btn-info mr-2" (click)="detailsPurchaseLine(row, $event)" [disabled]="detailsColumn.length === 0">
                                                <span translate>DETAILS</span>
                                                <i class="fa-solid fa-circle-info ml-1"></i>
                                            </button>
                                            <lib-action-btn [item]="row" [config]="supplierPoActionConfigService"></lib-action-btn>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
</div>

<ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>

