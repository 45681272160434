<h4 class="mt-0 text-center text-md-left" translate>USER_DEFAULTS</h4>
<hr>

<div *ngIf="newConfigurations?.['NewUserDefaultsConfig']" class="row">
  <div class="col-12 col-md-6">
    <div class="row">
      <div class="col-12">
        <lib-gung-text-input
          gungTranslate="DEFAULT_ASSORTMENT"
          [(ngModel)]="newConfigurations['NewUserDefaultsConfig'].defaultAssortment"
          (ngModelChange)="checkForChanges()"
        ></lib-gung-text-input>
      </div>

      <div class="col-12 mt-2">
        <lib-gung-input-multi-select
          label="DEFAULT_USER_GROUPS"
          [optionsList]="availableUserGroupOptions"
          (selectedValues)="setSelectedUserGroupValues($event)"
          [showId]="false"
        ></lib-gung-input-multi-select>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-6">
    <div class="row">
      <div class="col-12">
        <label translate>DEFAULT_MULTISTOCK_IDS</label>
        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="0" #value>
          <button type="button" class="btn btn-primary" (click)="addMultistockId(value)">{{ 'ADD' | translate }}</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div
          class="form-check pt-1 d-flex align-items-center justify-content-between"
          *ngFor="let multiStocksId of newConfigurations?.['NewUserDefaultsConfig']?.defaultMultistockIds; let i = index"
        >
          <label class="form-check-label">
            {{ multiStocksId }}
          </label>
          <button type="button" class="btn btn-link p-1" (click)="removeMultistockId(i)"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 action-buttons">
    <button class="btn btn-success mr-3" (click)="onSave()" translate><i class="fa-light fa-floppy-disk mr-2"></i>SAVE</button>
    <button class="btn btn-warning" (click)="reset()" translate><i class="fa-light fa-arrow-rotate-left mr-2"></i>RESET</button>
  </div>
</div>
