<div *ngIf="data; else loading" [class.border]="edit">
  <div class="hero-section">
    <button
      *ngIf="edit"
      (click)="openModal('heroElement')"
      class="image-change-button-relative-pos btn btn-primary mr-2"
      translate
    >
      Change Hero image/Text
    </button>
    <button
      *ngIf="edit"
      (click)="openModal('heroTextSubheader')"
      class="image-change-button-relative-pos btn btn-primary mr-2"
      style="top: 50px"
      translate
    >
      Change Subheader
    </button>
    <img [src]="getImage(data.elements?.heroElement?.image)" alt="Hero Image" />
  </div>
  <div class="title-section">
    <div class="text-content">
      <p>{{ getText(data.elements?.heroTextSubheader?.text) }}</p>
      <h1>{{ getText(data.elements?.heroElement?.text) }}</h1>
    </div>
  </div>

  <div class="nav-bar">
    <a (click)="edit ? openModal('link1') : navigateTo(data.elements?.link1?.link)">
      {{ getText(data.elements?.link1?.text) }}
    </a>
    <a (click)="edit ? openModal('link2') : navigateTo(data.elements?.link2?.link)">
      {{ getText(data.elements?.link2?.text) }}
    </a>
    <a (click)="edit ? openModal('link3') : navigateTo(data.elements?.link3?.link)">
      {{ getText(data.elements?.link3?.text) }}
    </a>
    <a (click)="edit ? openModal('link4') : navigateTo(data.elements?.link4?.link)">
      {{ getText(data.elements?.link4?.text) }}
    </a>
    <a (click)="edit ? openModal('link5') : navigateTo(data.elements?.link5?.link)">
      {{ getText(data.elements?.link5?.text) }}
    </a>
  </div>
  <div *ngIf="edit" class="info text-center alert alert-info fw-bold">
    Click the links to edit their text and target destination
  </div>

  <!--Categories -->
  <div class="category-section">
    <button *ngIf="edit" (click)="openModal('preHeader')" class="btn btn-primary mr-2" translate>
      Change Preheader
    </button>
    <button *ngIf="edit" (click)="openModal('Header')" class="btn btn-primary mr-2" style="left: 200px" translate>
      Change Header
    </button>
    <h3>{{ getText(data.elements?.preHeader?.text) }}</h3>
    <h2 class="fw-bold">{{ getText(data.elements?.Header?.text) }}</h2>

    <div class="categories">
      <!--category 1-->
      <div class="category-item" (click)="edit ? '' : navigateTo(data.elements?.category1?.link)">
        <button
          *ngIf="edit"
          (click)="openModal('category1')"
          class="image-change-button-relative-pos btn btn-primary mr-2"
          translate
        >
          Change Picture/Text
        </button>
        <img [ngClass]="{ 'cursor-pointer': !edit }" [src]="getImage(data.elements?.category1?.image)" alt="Stickat" />
        <div [ngClass]="{ 'cursor-pointer': !edit }" class="label">{{ getText(data.elements?.category1?.text) }}</div>
      </div>
      <!--category 2-->
      <div class="category-item" (click)="edit ? '' : navigateTo(data.elements?.category2?.link)">
        <button
          *ngIf="edit"
          (click)="openModal('category2')"
          class="image-change-button-relative-pos btn btn-primary mr-2"
          translate
        >
          Change Picture/Text
        </button>
        <img [ngClass]="{ 'cursor-pointer': !edit }" [src]="getImage(data.elements?.category2?.image)" alt="Stickat" />
        <div [ngClass]="{ 'cursor-pointer': !edit }" class="label">{{ getText(data.elements?.category2?.text) }}</div>
      </div>
      <!--category 3-->
      <div class="category-item" (click)="edit ? '' : navigateTo(data.elements?.category3?.link)">
        <button
          *ngIf="edit"
          (click)="openModal('category3')"
          class="image-change-button-relative-pos btn btn-primary mr-2"
          translate
        >
          Change Picture/Text
        </button>
        <img [ngClass]="{ 'cursor-pointer': !edit }" [src]="getImage(data.elements?.category3?.image)" alt="Stickat" />
        <div [ngClass]="{ 'cursor-pointer': !edit }" class="label">{{ getText(data.elements?.category3?.text) }}</div>
      </div>
      <!--category 4-->
      <div class="category-item" (click)="edit ? '' : navigateTo(data.elements?.category4?.link)">
        <button
          *ngIf="edit"
          (click)="openModal('category4')"
          class="image-change-button-relative-pos btn btn-primary mr-2"
          translate
        >
          Change Picture/Text
        </button>
        <img [ngClass]="{ 'cursor-pointer': !edit }" [src]="getImage(data.elements?.category4?.image)" alt="Stickat" />
        <div [ngClass]="{ 'cursor-pointer': !edit }" class="label">{{ getText(data.elements?.category4?.text) }}</div>
      </div>
      <!--category 5-->
      <div class="category-item" (click)="edit ? '' : navigateTo(data.elements?.category5?.link)">
        <button
          *ngIf="edit"
          (click)="openModal('category5')"
          class="image-change-button-relative-pos btn btn-primary mr-2"
          translate
        >
          Change Picture/Text
        </button>
        <img [ngClass]="{ 'cursor-pointer': !edit }" [src]="getImage(data.elements?.category5?.image)" alt="Stickat" />
        <div [ngClass]="{ 'cursor-pointer': !edit }" class="label">{{ getText(data.elements?.category5?.text) }}</div>
      </div>
      <!--category 6-->
      <div class="category-item" (click)="edit ? '' : navigateTo(data.elements?.category6?.link)">
        <button
          *ngIf="edit"
          (click)="openModal('category6')"
          class="image-change-button-relative-pos btn btn-primary mr-2"
          translate
        >
          Change Picture/Text
        </button>
        <img [ngClass]="{ 'cursor-pointer': !edit }" [src]="getImage(data.elements?.category6?.image)" alt="Stickat" />
        <div [ngClass]="{ 'cursor-pointer': !edit }" class="label">{{ getText(data.elements?.category6?.text) }}</div>
      </div>
    </div>
  </div>


  <!--Products -->
  <div class="products-section" style="width: 100%">
    <button *ngIf="edit" (click)="openModal('productGroupHeader')" class="btn btn-primary mb-3">
      Change product header
    </button>
    <h2 [ngClass]="{ 'cursor-pointer': !edit }" class="pt-3"> {{getText(data.elements?.productGroupHeader?.text) }}</h2>
    <div>
      <button *ngIf="edit" (click)="openModalTextInput('sectionProducts')" class="btn btn-primary mr-5">
        Change products
      </button>
      <button *ngIf="edit" (click)="openModalTextInput('sectionProductsSize')" class="btn btn-primary">
        Change products per row
      </button>
      <div class="product-list">
    </div>
      <div class="product-item row mt-5" style="width: 100%">
        <div class="col-12" style="width: 100%">
          <lib-home-hero-banner-product-cards
            [productIds]="getTextNoTranslate(data.elements?.sectionProducts?.text)"
            [itemsPerRow]="getTextNoTranslate(data.elements?.sectionProductsSize?.text)"
          ></lib-home-hero-banner-product-cards>
        </div>
      </div>
    </div>
    <a (click)="edit ? openModal('bottomLink') : navigateTo(data.elements?.bottomLink?.link)" class="shop-now-btn">
      {{ getText(data.elements?.bottomLink?.text) }}
    </a>
    <div *ngIf="edit" class="info text-center alert alert-info fw-bold mt-3">
      Click the button to edit the text and target destination
    </div>
  </div>

  <!--Section 1-->

  <!--Section 2-->

  <!--Section 3-->

  <!--Section 4 Tiles-->
</div>

<ng-template #loading translate> LOADING </ng-template>
