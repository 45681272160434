import { Component, Input, ViewChild } from '@angular/core';
import { ProductionManagementStockCountingComponent } from '../production-management-stock-counting/production-management-stock-counting.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductionManagementStockCountingService } from '../../services/production-management-stock-counting.service';
import { GungNotificationService, gungAddRemoveSpinner } from 'gung-common';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../../../services/products/product.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { MetadataService } from '../../../../services/metadata/metadata.service';
import { AvailabilityService } from '../../../../services/availability/availability.service';


export interface StockLocation {
  stockPointId: string;
  stockLocationId: string;
  stockLocationCode: string;
  stockLocationName: string;
  totalQuantity: number;
  availableQuantity: number;
  remainingOrderedQuantity: number;
}

@Component({
  selector: 'lib-production-management-stock-counting-modal',
  templateUrl: './production-management-stock-counting-modal.component.html',
  styleUrls: ['./production-management-stock-counting-modal.component.css']
})
export class ProductionManagementStockCountingModalComponent extends ProductionManagementStockCountingComponent {

  @Input()
  public inputParams: {
    productId: string;
    StockPointCode: string;
    StockPointId: string;
  };

  @ViewChild('stockCountingComponent')
  stockCountingComponent: ProductionManagementStockCountingComponent;

  constructor(
    protected stockCountingService: ProductionManagementStockCountingService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected metadataService: MetadataService,
    protected gungNotificationService: GungNotificationService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected productService: ProductService,
    public ngbActiveModal: NgbActiveModal
  ) {
    super(
      stockCountingService,
      availabilityService,
      authService,
      metadataService,
      gungNotificationService,
      activatedRoute,
      router,
      productService
    );
  }

  close() {
    this.ngbActiveModal.close('Cance click')
  }

  save({target}) {
    gungAddRemoveSpinner(target);
    this.stockCountingComponent.saveAsObservable().subscribe(res => {
      gungAddRemoveSpinner(target);
      this.ngbActiveModal.close(res.rows?.length > 0 && res.rows[0].countedQuantity);
    });
  }
}
