import { Component, OnInit } from '@angular/core';
import { JeevesOpenOrdersListConfigService } from '../../services/jeeves-open-orders-list-config/jeeves-open-orders-list-config.service';

@Component({
  selector: 'gung-jeeves-jeeves-open-order-list',
  template: '<lib-filter-list [configService]="jeevesOpenOrdersListConfig"></lib-filter-list>',
  styleUrls: ['./jeeves-open-order-list.component.css']
})
export class JeevesOpenOrderListComponent {
  constructor(public jeevesOpenOrdersListConfig: JeevesOpenOrdersListConfigService) {}
}
