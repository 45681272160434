<div class="container px-4 py-5" id="featured-3">
    <h2 class="pb-2 border-bottom" translate>ADMIN_MANAGEMENT</h2>
    <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
      <div class="feature col">
        <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
            <i class="fa-solid fa-warehouse fa-xl"></i>
        </div>
        <h3 class="fs-2 text-body-emphasis" translate>TRANSFER_INVENTORY</h3>
        <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
        <a class="btn btn-outline-primary rounded mb-5" routerLink="transfer-inventory" role="button" translate><i class="fa-solid fa-right-long"></i></a>
      </div>
      <div class="feature col">
        <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
            <i class="fa-solid fa-truck-ramp-box fa-xl"></i>
        </div>
        <h3 class="fs-2 text-body-emphasis" translate>ORDERS_TO_PICK</h3>
        <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
        <a class="btn btn-outline-primary rounded mb-5" routerLink="orders-to-pick" role="button" translate><i class="fa-solid fa-right-long"></i></a>
      </div>
      <div class="feature col">
        <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
            <i class="fa-solid fa-truck-moving fa-xl"></i>
        </div>
        <h3 class="fs-2 text-body-emphasis" translate>SHIP_ORDERS</h3>
        <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
        <a class="btn btn-outline-primary rounded mb-5" routerLink="ship-orders" role="button" translate><i class="fa-solid fa-right-long"></i></a>
      </div>
      <div class="feature col">
        <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
            <i class="fa-solid fa-boxes-stacked fa-xl"></i>
        </div>
        <h3 class="fs-2 text-body-emphasis" translate>STOCK_COUNTING</h3>
        <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
        <a class="btn btn-outline-primary rounded mb-5" routerLink="stock-counting" role="button" translate><i class="fa-solid fa-right-long"></i></a>
      </div>
      <div class="feature col">
        <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
            <i class="fa-regular fa-file-lines fa-xl"></i>
        </div>
        <h3 class="fs-2 text-body-emphasis" translate>RECEIVE_PO</h3>
        <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
        <a class="btn btn-outline-primary rounded mb-5" routerLink="." role="button" translate><i class="fa-solid fa-right-long"></i></a>
      </div>
      <div class="feature col">
        <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
          <i class="fa-regular fa-file-lines fa-xl"></i>
        </div>
        <h3 class="fs-2 text-body-emphasis" translate>MATERIAL_REQUIREMENTS_PLANNING</h3>
        <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
        <a class="btn btn-outline-primary rounded mb-5" routerLink="material-requirements-planning" role="button" translate><i class="fa-solid fa-right-long"></i></a>
      </div>
    </div>
</div>
