<div class="dashboard-card card" *ngIf="loaded">
    <div class="card-body dashboard-card-content">
      <div class="title" translate>OFFERS_OPEN_EXPIRED</div>
  
      <div class="d-flex justify-content-between">
        <div class="align-self-center">
          <span translate>OPEN</span>
        </div>
        <span class="current-statistic text-primary">{{ offersOpenExpired.open }}</span>
      </div>
  
      <div class="d-flex justify-content-between">
        <div class="align-self-center">
          <span translate>EXPIRED</span>
        </div>
        <span class="current-statistic text-primary">{{ offersOpenExpired.expired }}</span>
      </div>
  
    </div>
  </div>
  
  <div class="dashboard-card card" *ngIf="!loaded">
    <div class="card-body dashboard-card-content">
      <div class="text-center dashboard-loading-spinner">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
  