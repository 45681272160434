<div class="table-responsive gung-responsive">
  <table class="table">
    <thead>
    <tr>
      <th translate>PRODUCT</th>
      <th translate>STATUS</th>
      <th class="text-right" colspan="2" translate>CURRENT_QUANTITY</th>
      <th class="text-right" translate>QUANTITY_RANGE_MIN_MAX</th>
      <th class="text-right" translate>ACTIVE</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let product of data">
      <td>
        <span class="product-id">{{ product.productId }}</span> <br />
        <span *ngIf="product.productData" class="product-name">{{
          product.productData.name
          }}</span>
      </td>
      <td translate>
        {{ product.status }}
      </td>
      <td class="text-right" colspan="2">
        <span style="font-size: 16px">{{ product.currentStock }}</span>
        <span style="font-size: 14px; font-style: italic">
              ({{ product.currentStockUpdateTime | date: dateUtilService.dateFormat }})</span
        >
      </td>
      <td class="text-right" style="font-size: 16px">
        <div class="d-flex justify-content-end">
          <lib-gung-number-input-horizontal
            [(ngModel)]="product.minimumQuantity"
            [suppressLabel]="true"
            [disabled]="!product.active"
            (change)="changeMinimumQuantity(product, $event.target.valueAsNumber)"
          ></lib-gung-number-input-horizontal>
          <lib-gung-number-input-horizontal
            [(ngModel)]="product.maximumQuantity"
            [suppressLabel]="true"
            [disabled]="!product.active"
            (change)="changeMaximumQuantity(product, $event.target.valueAsNumber)"
          ></lib-gung-number-input-horizontal>
        </div>
      </td>
      <td class="text-right">
        <div class="gung-toggle-switch">
          <label class="switch">
            <input
              id="included"
              name="included"
              type="checkbox"
              [checked]="product.active"
              (change)="changeIncludedInReplenishment(product, $event.target.checked)"
            />
            <span class="slider round bg-primary"></span>
          </label>
        </div>
      </td>

      <td></td>
    </tr>
    </tbody>
  </table>
</div>
