<div class="row" [ngClass]="{ 'mt-4 mb-4': mappedData && mappedData.length > 0 }">
  <div *ngFor="let data of mappedData; last as isLast" class="col-6 col-md-4 col-lg-3 mb-2">
    <div class="card product-card">
      <div class="card-body">
        <div>
          <span class="favourite-icon-wrapper">
            <sjoborg-favourite-product [id]="data.product.id"></sjoborg-favourite-product>
          </span>
        </div>
        <div class="image-wrapper" (click)="goToDetail(data.product)">
          <img
            class="img-fluid"
            src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/250x250/filters:etag({{
              data.product.extra.images[0].s3ETag
            }})/{{ data.product.extra.images[0].s3Uri }}"
            alt=""
          />
        </div>
        <div class="product-text pb-1">
          <div>{{ data.product.id }}</div>
          <div class="text-truncate">{{ data.product.name }}</div>
          <!-- <div translate *ngIf="data.price" class="font-weight-bold">
              {{ data.price.customerNetPrice.value | gungCurrency: data.price.customerNetPrice.currencyCode }}</div> -->
          <lib-price
            [price]="data.price"
            type="NET"
            [currency]="data.price.customerNetPrice.currencyCode"
            class="font-weight-bold d-block"
          ></lib-price>
          <!-- if supplier stock is a neg #, indicates supplier stock is not null, otherwise show reg availability -->
          <div *ngIf="data.product?.extra?._supplierStock >= 0" style="color: green" class="d-inline">
            {{ 'IN_STOCK' | translate }} ({{ data.product.extra._supplierStock }})
          </div>
          <div *ngIf="data.product.extra._supplierStock === -1" class="d-inline">
            <lib-availability [availability]="data.availability" [isSales]="isSales" *ngIf="includeAvailability"></lib-availability>
          </div>
          <div class="product-card-button">
            <sjoborg-buy-btn
              [id]="data.product.id"
              [minimumOrderQuantity]="getPackageSize(data.product)"
              [stepAmount]="getPackageSize(data.product)"
            ></sjoborg-buy-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
