<div *ngIf="!!data">
  <div class="card" *ngFor="let row of data; index as i; trackBy: trackByFn">
    <div class="card-body">
      <div class="card-title row">
        <div class="col">
          <h5 class="m-0">{{ row.id }} - {{ row.name }}</h5>
        </div>
        <div class="col-auto ml-auto">
          <button type="button" class="btn btn-primary" (click)="selectSupplier(row)">
            <span translate>SELECT_SUPPLIER</span>
            <i class="fas fa-file-download ml-1"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
