import { Component, OnInit } from '@angular/core';
import { InvoiceFilteredStatisticsConfigService } from '../../../services/invoice-filtered-statistics-config.service';

@Component({
  selector: 'lib-filter-card-large',
  templateUrl: './filter-card-large.component.html',
  styleUrls: ['./filter-card-large.component.scss']
})
export class FilterCardLargeComponent implements OnInit {
  constructor(public filterCardService: InvoiceFilteredStatisticsConfigService) {}

  ngOnInit() {}
}
