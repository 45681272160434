<div class="d-flex justify-content-end">
  <div class="mb-2">
    <span style="font-size: 12px" class="mr-2" translate>ADD_PRODUCT</span>
    <label class="expandableAddProduct">
      <input
        id="productid"
        type="text"
        class="form-control"
        [resultFormatter]="formatterProduct"
        [ngbTypeahead]="searchProducts"
        (selectItem)="onSelectProduct($event)"
      />
      <i class="far fa-plus"></i>
    </label>
  </div>
</div>
