import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthGuard } from '../authguard/auth-guard';
import { AuthService } from '../../services/auth/auth.service';
import { first, last } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class AnonymousAllowedAuthGuard extends AuthGuard  {
  constructor(authService: AuthService, router: Router, http: HttpClient, modalService: NgbModal) {
    super(authService, router, http, modalService);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = state.url;
    const roles = next.data.rolesAllowed;
    const routePathDefinition = next.routeConfig.path;
    return this.checkLoginAnonymous(routePathDefinition, url, roles);
  }

  protected async checkLoginAnonymous(routePathDefinition, url, roles): Promise<boolean> {
    const isAuthenticated = await this.authService.getIsAuthenticated().pipe(first()).toPromise();
    if (isAuthenticated && this.pathDefinitionIsWhitelisted(routePathDefinition)) {
      return true;
    } else {
      return await super.checkLogin(url, roles);
    }
  }

  protected pathDefinitionIsWhitelisted(url: string): boolean {
    const wl = this.getWhitelist();
    return wl.has(url);
  }

  protected getWhitelist(): Set<string> {
    const wl = new Set<string>();
    wl.add('');
    wl.add('products');
    wl.add('categories/:assortmentId');
    wl.add('products-export');
    wl.add('product/:productId');
    return wl;
  }
}
