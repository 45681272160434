import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ProductConfigurationOption } from '../models/product-configuration-types';

@Injectable({
  providedIn: 'root'
})
export class ProductConfigurationOptionPriceService {
  constructor() {}

  public getPrice(option: ProductConfigurationOption): Observable<OptionPrice> {
    // This is a temporary implementation. Should in reality fetch the correct field
    // based on the selected customer. It somehow models the way we are going to fetch
    // the price in the future, which is why we abstract it behind the service.
    return of({
      id: option.id,
      price: Number(option.properties?.priceUSD) || 0,
      currency: '$'
    });
  }
}

export interface OptionPrice {
  id: string;
  price: number;
  currency: string;
}
