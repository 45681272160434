import {
  CdkDropList,
  CdkDrag,
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  copyArrayItem
} from '@angular/cdk/drag-drop';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { BankgiroService } from '../../services/bankgiro.service';
import { GungModalService } from '../../../../services/gung-modal/gung-modal.service';
import { catchError } from 'rxjs';
import { CsvUtilsService } from 'gung-common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-bankgiro-dynamic',
  templateUrl: './bankgiro-dynamic.component.html',
  styleUrls: ['./bankgiro-dynamic.component.scss']
})
export class BankgiroDynamicComponent {
  @ViewChild('fileDropRef') fileDropRef: ElementRef;

  errorMessage: string;
  enableFreeText: boolean = false;
  delimiter: string = '';
  columnsAll: string[] = [];
  columns: string[] = [...this.columnsAll];
  values: any[] = [];
  selectedValue = 0;
  fieldsInfo: { [s: string]: { field: string; name: string; tooltip: string; mandatory: boolean; error: boolean } } = {
    transactionReferenceHeader: {
      field: 'transactionReferenceHeader',
      name: 'TRANSACTION_REFERENCE',
      tooltip: 'BANKGIRO_TRANSACTION_REFERENCE_TOOLTIP',
      mandatory: true,
      error: false
    },
    orderNumberHeader: {
      field: 'orderNumberHeader',
      name: 'ORDER_NUMBER',
      tooltip: 'BANKGIRO_ORDER_NUMBER_TOOLTIP',
      mandatory: true,
      error: false
    },
    paymentAmountHeader: {
      field: 'paymentAmountHeader',
      name: 'TRANSACTION_AMOUNT',
      tooltip: 'BANKGIRO_TRANSACTION_AMOUNT_TOOLTIP',
      mandatory: true,
      error: false
    }
  };
  fields: string[] = Object.entries(this.fieldsInfo).map(f => f[0]);
  mappings: { [s: string]: string[] } = this.fields.reduce((a, v) => ({ ...a, [v]: [] }), {});
  connectedToMappings = this.fields.map(f => /* 'cdk-drop-list-' +  */ f);
  uploadedFile: File;

  constructor(
    protected bankgiroService: BankgiroService,
    protected modalService: GungModalService,
    protected csvUtilsService: CsvUtilsService,
    public activeModal: NgbActiveModal
  ) {}

  dropMappging(event: CdkDragDrop<string[]>, list: string) {
    // console.log('event', event)
    if (event.previousContainer === event.container) {
      // console.log('previousIndex: ' + event.previousIndex, 'currentIndex: ' + event.currentIndex );
      return;
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // console.log('previousIndex.id: ' + event.previousContainer.id, 'container.id: ' + event.container.id );
      if (list === 'mappings') {
        if (event.container.data.length > 0) {
          return;
        }
        copyArrayItem(this.columnsAll, event.container.data, event.previousIndex, event.currentIndex);
        const idx = event.previousContainer.data.indexOf(event.item.data);
        if (idx > -1) {
          event.previousContainer.data.splice(idx, 1);
        }
      } else {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      }
    }
  }

  /** Predicate function that only allows even numbers to be dropped into a list. */
  mappingsPredicate(item: CdkDrag<string>, drop: CdkDropList) {
    return drop.data?.length === 0;
  }

  /** Predicate function that doesn't allow items to be dropped into a list. */
  columnsPredicate(drag: CdkDrag, drop: CdkDropList) {
    return this.columnsAll?.indexOf(drag.data) === -1;
  }

  public uploadFile(file: File[]): void {
    this.uploadedFile = file[0];
    this.csvUtilsService
      .parse(file[0], this.delimiter, false, 1)
      .pipe(
        catchError(err => {
          console.error('uploadFile() - ERROR', err);
          this.errorMessage = 'Error while parsing CSV: ' + err;
          return err;
        })
      )
      .subscribe((data: string[][]) => {
        // If the response we get back is of length 0 or 1, we can throw an error. The reason for this is that
        // it most likely is not an CSV file that has been uploaded, or the delimiter was selected wrongly.
        // Instead of letting the user be routed to the next page where the data will look wonky, fail fast here and
        // give the user feedback that can be understood.
        if (data.length <= 1) {
          this.errorMessage = 'CSV_FILE_PARSING_ERROR_CHECK_DELIMITER_AND_FORMAT';
          // We need to reset the file input field, otherwise the user will not be able to upload the same file again.
          this.fileDropRef.nativeElement.value = null;
          return;
        }
        // Reset any potential errors.
        this.errorMessage = undefined;

        if (data.length > 0) {
          for (let i = 1; i <= data[0].length - 1; i++) {
            this.values.push({});
          }

          this.columnsAll = data.map(c => c[0]);
          this.columns = [...this.columnsAll];
          // Set ROW values
          for (const key of data) {
            for (let index = 1; index < key.length; index++) {
              const element = key[index];
              this.values[index - 1][key[0]] = element;
            }
          }
        }
      });
  }

  send() {
    // mandatory
    const mandatory = Object.keys(this.fieldsInfo).filter(k => this.fieldsInfo[k].mandatory);
    for (const key of mandatory) {
      if (this.mappings[key].length === 0) {
        this.fieldsInfo[key].error = true;
      } else {
        this.fieldsInfo[key].error = false;
      }
    }

    if (Object.keys(this.fieldsInfo).find(k => this.fieldsInfo[k].error)) {
      return;
    }
    const mappings = {};
    for (const key of Object.keys(this.mappings)) {
      const field = this.mappings[key][0];
      if (field) {
        // mappings[key] = {
        //   type: this.columnsAll.indexOf(field) > -1 ? 'MAPPING' : 'FREETEXT',
        //   field: this.mappings[key][0]
        // }
        mappings[key] = this.mappings[key][0];
      }
    }

    this.bankgiroService.uploadBankgiro(this.uploadedFile, this.delimiter, mappings).subscribe(result => {
      // console.log('result', result);
      this.close();
    });
  }

  freeText(list) {
    if (!this.enableFreeText) {
      return;
    }
    if (list.length > 0) {
    } else {
      list.push('');
    }
  }

  removeField(field: string) {
    if (this.mappings[field].length > 0 && this.mappings[field][0] === '') {
      this.mappings[field] = [];
    }
  }

  close() {
    this.activeModal.close('Closed');
  }

  onFileSelected(files) {
    this.uploadFile(files);
  }
}
