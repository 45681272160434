import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, first, map, Observable, of, tap } from 'rxjs';
import { Bankgiro } from '../models/bankgiro';
import { DownloadFile, DownloadFileService } from '../../../services/file-downloader/download-file.service';

@Injectable({
  providedIn: 'root'
})
export class BankgiroService {
  protected bankgiroSubject: BehaviorSubject<Bankgiro[]>;

  constructor(protected http: HttpClient, protected downloadFileService: DownloadFileService) {}

  private baseUrl = 'json/bankgiro';

  public getAllBankgiro(): Observable<Bankgiro[]> {
    if (!this.bankgiroSubject) {
      this.updateSubject();
    }

    return this.bankgiroSubject.asObservable();
  }

  private updateSubject(): void {
    if (!this.bankgiroSubject) {
      this.bankgiroSubject = new BehaviorSubject<Bankgiro[]>([]);
    }

    this.getAllbankgiroSubjectInternal()
      .pipe(first())
      .subscribe(rc => {
        this.bankgiroSubject.next(rc);
      });
  }

  private getAllbankgiroSubjectInternal(): Observable<Bankgiro[]> {
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<Bankgiro[]>(this.baseUrl, { headers }).pipe(
      catchError(error => {
        // REMOVE THIS WHEN ENDPOINT CREATED
        console.error('getAllbankgiroSubjectInternal() - error', error);
        return of([]);
      })
    );
  }

  uploadQliro(file: File): Observable<Bankgiro> {
    const url = `${this.baseUrl}/upload-csv/qliro`;
    return this.http.post<Bankgiro>(url, file).pipe(
      tap(_ => this.updateSubject()),
      catchError(error => {
        // REMOVE THIS WHEN ENDPOINT CREATED
        console.error('uploadQliro() - error', error);
        return of(null);
      })
    );
  }

  convertToBgmax(ids: string[]): Observable<any> {
    const url = `${this.baseUrl}/convert-to-bgmax`;
    return this.http.post(url, ids, { responseType: 'arraybuffer', observe: 'response' }).pipe(
      map((response: HttpResponse<ArrayBuffer>) => {
        return this.downloadFileService.create(response);
      }),
      map((file: DownloadFile) => {
        return this.downloadFileService.download(file);
      })
    );
  }

  uploadBankgiro(
    file: File,
    delimiter: string = ';',
    fieldToHeaderMapping: { [s: string]: string }
  ): Observable<Bankgiro[]> {
    const url = `${this.baseUrl}/upload-csv`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('delimiter', delimiter);
    formData.append('fieldToHeaderMapping', JSON.stringify(fieldToHeaderMapping));
    return this.http.post<Bankgiro[]>(url, formData).pipe(
      tap(_ => this.updateSubject()),
      catchError(error => {
        console.error('uploadBankgiro() - error', error);
        return of([]);
      })
    );
  }
}
