<div class="row">
  <div class="col-sm-12">
    <div class="text-center mb-3">
      <h3 translate>REPORT_CENTRAL</h3>
      <span class="report-central-disclaimer font-italic small" translate>REPORT_CENTRAL_ITEM_REMOVAL_DISCLAIMER</span>
    </div>

    <lib-filter-list #list [configService]="configService"></lib-filter-list>
  </div>
</div>

