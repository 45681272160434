import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-under-service-notification',
  templateUrl: './under-service-notification.component.html',
  styleUrls: ['./under-service-notification.component.css']
})
export class UnderServiceNotificationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
