<div *ngIf="!loadingComponent && minDate; else loading">
  <!-- ORDER TERMS -->
  <div class="row">
    <div class="col-12 col-md-4">
      <div [formGroup]="form">
        <lib-meta-select-input
          formControlName="levsattkod"
          table="x2f"
          metaId="levsattkod"
          metaDisplay="levsattbeskr"
          [optionsFilterFunction]="optionsFilterDeliveryMethod.bind(this, currentCustomer)"
        >
        </lib-meta-select-input>
      </div>
    </div>
    <div class="col-12 col-md-4" *ngIf="!isUser">
      <div [formGroup]="form">
        <lib-meta-select-input formControlName="betkod" table="x2" metaId="betkod" metaDisplay="betvillbeskr">
        </lib-meta-select-input>
      </div>
    </div>
    <div class="col-12 col-md-4" *ngIf="!isUser">
      <div [formGroup]="form">
        <lib-meta-select-input formControlName="saljare" table="salj" metaId="saljare" metaDisplay="saljarenamn">
        </lib-meta-select-input>
      </div>
    </div>
    <div class="col-12 col-md-4" *ngIf="!isUser">
      <div [formGroup]="form">
        <lib-meta-select-input
          formControlName="levvillkkod"
          table="x2e"
          metaId="levvillkkod"
          metaDisplay="levvillkbeskr"
        >
        </lib-meta-select-input>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <div class="form-group" [formGroup]="form">
        <label for="partDelivery" class="control-label" translate>PART_DELIVERY</label>
        <select
          name="partDelivery"
          id="partDelivery"
          class="form-control"
          formControlName="dellevtillaten"
          (change)="handlePartDeliveryChange()"
        >
          <option [value]="entry.id" *ngFor="let entry of partDeliveryEntries">
            {{ entry.name | translate }}
          </option>
        </select>
      </div>
    </div>
    <div *ngIf="checkout.extra.oh.dellevtillaten == '10'" class="col-12 col-md-4">
      <div>
        <lib-delivery-date-picker-beta
          *ngIf="minDate"
          (selectedDate)="dateSelected($event)"
          [initialDate]="checkout.extra.oh.ordberlevdat"
          [deliveryMethod]="form.get('levsattkod').value"
          [minInitialDate]="minDate"
        ></lib-delivery-date-picker-beta>
      </div>

      <div *ngIf="form.get('dellevtillaten').value === '0'" translate>PART_DELIVERY_MESSAGE</div>
    </div>
    <!-- <div class="col-12 col-md-4" *ngIf="!isUser">
            <div class="form-group" [formGroup]="form">
                <label for="ordstat " class="control-label" translate>ORDER_TYPE</label>
                <select name="ordstat " id="ordstat " class="form-control form-select" formControlName="ordstat">
                    <option [value]="0" translate><span></span>OTTO_OLSEN_QUOTATION</option>
                    <option [value]="10" translate>OTTO_OLSEN_ORDER</option>
                </select>
            </div>
        </div> -->
  </div>
  <div class="row">
    <!-- FREIGHT COST -->
    <div class="col-12">
      <div *ngIf="alertMessageFreightCost" class="alert mt-2 text-center alert-primary" role="alert">
        <span [innerHTML]="alertMessageFreightCost.message"></span>
      </div>
    </div>

    <!-- ADMIN FEES -->
    <div class="col-12 text-center">
      <div
        *ngIf="alertMessageAdminFee"
        class="alert mt-2 text-center alert-{{ alertMessageAdminFee.type }}"
        role="alert"
      >
        {{ alertMessageAdminFee.message | translate }}
      </div>
    </div>
  </div>
  <!-- CART LIST -->
  <div class="row">
    <div class="col-12" *ngIf="!isUser">
      <lib-filter-list #cartList [configService]="salesCartConfigService"></lib-filter-list>
    </div>
    <div class="col-12" *ngIf="isUser">
      <lib-filter-list #cartList [configService]="cartConfigService"></lib-filter-list>
    </div>
  </div>
</div>

<ng-template #loading translate> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>
