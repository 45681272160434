import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs';
import { FrontendSettings } from '../../models/frontend-settings';
import { S3Item } from '../../models/s3';
import { GungDownloadUrl, GungImageBaseUrl, GungImageUrlService } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class FrontendSettingsService {
  fallbackSettings = {
    siteLogo: '',
    favicon: GungDownloadUrl('_gung_common/favicons/favicon.ico')
  };

  imageBaseUri = GungImageBaseUrl;
  baseUrl = 'json/frontend-settings';

  settingsInternal: FrontendSettings;

  constructor(
    protected http: HttpClient,
    protected gungImageUrlService: GungImageUrlService
  ) {
    this.loadFrontendSettings();
  }

  getSiteLogo(): string {
    return this.getImageInternal('siteLogo');
  }

  getSiteLogo$(): Observable<string> {
    return this.getFrontendSettings().pipe(
      tap(settings => (this.settingsInternal = settings)),
      map(settings => this.getImageInternal('siteLogo', settings))
    );
  }

  getFavicon(): string {
    return this.getImageInternal('favicon');
  }

  getFavicon$(): Observable<string> {
    return this.getFrontendSettings().pipe(
      tap(settings => (this.settingsInternal = settings)),
      map(settings => this.getImageInternal('favicon', settings))
    );
  }

  protected getImageInternal(type: string, settings?: FrontendSettings): string {
    let s3Image: S3Item;
    if (!!settings) {
      s3Image = settings.imageSettings[type];
    } else if (!!this.settingsInternal) {
      s3Image = this.settingsInternal.imageSettings[type];
    } else {
      return this.fallbackSettings[type];
    }
    return this.gungImageUrlService.getUrl(s3Image.s3Uri, {etag: s3Image.s3ETag});
  }

  loadFrontendSettings(): void {
    this.getFrontendSettings(true)
      .pipe(first())
      .subscribe(settings => {
        this.settingsInternal = settings;
      });
  }

  getFrontendSettings(noCache?: boolean): Observable<FrontendSettings> {
    const headers = {
      maxAge: noCache ? '-1' : '300'
    };
    return this.http.get<FrontendSettings>('public/' + this.baseUrl, { headers });
  }

  saveFrontendSettings(settings: FrontendSettings): Observable<FrontendSettings> {
    return this.http.put<FrontendSettings>(this.baseUrl, settings);
  }
}
