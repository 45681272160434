import { Component, OnInit, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { GridLayoutComponent } from 'gung-list';
import { Product } from 'gung-standard';

@Component({
  selector: 'gung-jeeves-jeeves-product-grid',
  templateUrl: './jeeves-product-grid.component.html',
  styleUrls: ['./jeeves-product-grid.component.css']
})
export class JeevesProductGridComponent extends GridLayoutComponent<Product> {
  constructor(componentFactoryResolver: ComponentFactoryResolver, changeDetectorRef: ChangeDetectorRef) {
    super(componentFactoryResolver, changeDetectorRef);
  }
}
