import { Component, OnInit, Input, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { MetadataService } from '../../services/metadata/metadata.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map, first, of, tap } from 'rxjs';
import { Observable } from 'rxjs';
import { TableRecord } from '../../state/metadata/types';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-meta-select-input',
  templateUrl: './meta-select-input.component.html',
  styleUrls: ['./meta-select-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaSelectInputComponent),
      multi: true
    }
  ]
})
export class MetaSelectInputComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input()
  private table: string;

  @Input()
  private metaId: string;

  @Input()
  private metaDisplay: string;

  @Input()
  private optionsFilterFunction?: (option: { key: string; value: string | TableRecord }) => boolean;

  @Input()
  private optionsFilterFunctionFullRecord?: (option: TableRecord) => boolean;

  @Input()
  private optionsFilterChangeUpdate?: Observable<any>;

  @Input()
  private optionsSortFunctionFullRecord?: (optionA: TableRecord, optionB: TableRecord) => (0 | 1 | -1);

  @Input()
  public suppressLabel?: boolean;

  @Input()
  public renderStatic?: boolean;

  @Input()
  public showId?: boolean;

  @Input()
  public showIdEnd?: boolean;

  @Input()
  public tooltip?: string;

  @Input()
  public hideEmptyOption?: boolean;

  @Input()
  public emptyOptionLabel = '';

  @Input()
  public nameAsValue?: boolean;

  @Input()
  gungTranslate: string;

  @Input()
  public valueTranslated?: boolean = false;

  @Input()
  public extraFormClass?: string;

  @Input()
  public footnote = false

  @Input()
  public companyIdentifier?: string;

  selectedValue: string = null;

  id: string;

  label: string;

  disabled = false;

  metaOptions$: Observable<{ key: string; value: string }[]>;

  onChange = (_: any) => { };

  get selected() {
    return this.selectedValue;
  }

  set selected(val: string) {
    this.selectedValue = val;
    this.onChange(this.selectedValue);
  }

  writeValue(val: any): void {
    if (this.selectedValue !== val && ((val !== undefined && val !== null) || (this.selectedValue !== undefined && this.selectedValue !== null))) {
      this.selected = val;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void { }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  constructor(protected metadataService: MetadataService, protected translateService: TranslateService) { }

  ngOnInit() {
    this.id = `uuid$${uuidv4()}`;
    this.label = this.gungTranslate || `${this.table}_${this.metaDisplay}`.toUpperCase();
    this.filterMetadata();
    this.optionsFilterChangeUpdate?.pipe().subscribe((_) => {
      this.filterMetadata();
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    // if (!!simpleChanges.optionsFilterFunction  || !!simpleChanges.optionsFilterFunctionFullRecord) {
    if ((!!simpleChanges.optionsFilterFunction && this.optionsFilterFunction !== simpleChanges.optionsFilterFunction?.currentValue)
      || !!simpleChanges.optionsFilterFunctionFullRecord && this.optionsFilterFunctionFullRecord !== simpleChanges.optionsFilterFunctionFullRecord.currentValue) {
      this.filterMetadata();
    }
  }

  filterMetadata() {
    if (this.optionsFilterFunction) {
      this.metaOptions$ = this.metadataService
        .getMetadataValuesAsArray$(this.table, this.metaId, this.metaDisplay, this.companyIdentifier)
        .pipe(
          map(array => array.filter(this.optionsFilterFunction)),
          map(array => {
            if (this.optionsSortFunctionFullRecord) {
              return array.sort((a, b) => this.optionsSortFunctionFullRecord(a as TableRecord, b as TableRecord));
            }
            return array;
          })
        ) as Observable<{ key: string; value: string }[]>;
    } else if (this.optionsFilterFunctionFullRecord) {
      const options = this.metadataService.getMetadataTableList(this.table, this.companyIdentifier).filter(option => {
        return this.optionsFilterFunctionFullRecord(option);
      });
      this.metaOptions$ = of(options).pipe(
        map(array => {
          return array.map(a => {
            return {
              key: a[this.metaId],
              value: a[this.metaDisplay]
            };
          });
        }),
        map(array => {
          if (this.optionsSortFunctionFullRecord) {
            return array.sort((a, b) => this.optionsSortFunctionFullRecord(a as TableRecord, b as TableRecord));
          }
          return array;
        })
      ) as Observable<{ key: string; value: string }[]>;
    } else {
      this.metaOptions$ = this.metadataService.getMetadataValuesAsArray$(
        this.table,
        this.metaId,
        this.metaDisplay,
        this.companyIdentifier
      ).pipe(
        map(array => {
          if (this.optionsSortFunctionFullRecord) {
            return array.sort((a, b) => this.optionsSortFunctionFullRecord(a as TableRecord, b as TableRecord));
          }
          return array;
        })
      ) as Observable<{ key: string; value: string }[]>;
    }
  }

  getSelectedValue(key: string): Observable<string> {
    return this.metaOptions$.pipe(
      first(),
      map(options => {
        const value = options.find(metaObject => metaObject.key === '' + key);
        if (this.valueTranslated) {
          return value ? this.translateService.instant(value.value) : '';
        }
        return value ? value.value : '';
      })
    );
  }

  trackByFn(index, item) {
    return item.key;
  }
}
