import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { Product, MetadataService } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class ProductItemGroupFilterService extends SimpleConfigBaseFilter<Product> {
  constructor(protected metadataService: MetadataService) {
    super();
  }
  getName(): string {
    return 'ITEM_GROUP';
  }
  getOptionIds(item: Product): string[] {
    return [item.extra?.ar?.varugruppkod] || [];
  }
  getOptionName(key: string): string {
    const metaValue = this.metadataService.getMetadataValue('vg', 'varugruppbeskr', key);
    return metaValue || key;
  }
}
