<div class="modal-header">
  <h5 class="modal-title" translate>CREATE_MATRIX_DISCOUNT</h5>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row" *ngIf="form" [formGroup]="form">
    <div class="col-12">
        <div class="form-group">
            <label for="id" class="control-label" translate>ID</label>
            <input formControlName="id" id="id" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label for="id" class="control-label" translate>CUSTOMERDISCOUNTGROUP</label>
            <lib-meta-select-input
              [suppressLabel]="true"
              formControlName="customerDiscountGroup"
              [table]="matrixDiscountService.customerDiscountMetadata.table"
              [metaId]="matrixDiscountService.customerDiscountMetadata.id"
              [metaDisplay]="matrixDiscountService.customerDiscountMetadata.name"
            >
            </lib-meta-select-input>
          </div>
          <div class="form-group">
            <label for="itemdiscount" class="control-label" translate>ITEMDISCOUNTGROUP</label>
            <lib-meta-select-input
              [suppressLabel]="true"
              formControlName="productDiscountGroup"
              [table]="matrixDiscountService.itemDiscountMetadata.table"
              [metaId]="matrixDiscountService.itemDiscountMetadata.id"
              [metaDisplay]="matrixDiscountService.itemDiscountMetadata.name"
            >
            </lib-meta-select-input>
          </div>
          <div class="form-group">
              <label for="discountPercent" class="control-label" translate>DISCOUNT_PERCENT</label>
              <input formControlName="discountPercent" id="discountPercent" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label for="lowerLimit" class="control-label" translate>LOWERLIMIT</label>
            <input formControlName="lowerLimit" id="lowerLimit" type="text" class="form-control" />
        </div>
    </div>
    
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="activeModal.close(false)" translate>
    CLOSE
  </button>
  <button type="button" class="btn btn-primary" (click)="onSave($event)" translate *ngIf="!saved">SAVE</button>
  <span *ngIf="saved" translate>SAVED</span>
</div>
