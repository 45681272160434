import { Component, Input, OnInit } from '@angular/core';
import { first, forkJoin } from 'rxjs';
import { DashboardKpiService } from '../../../services/dashboard-kpi.service';
import { OffersOpenExpired, SalesSummaryEntity, Timespan } from '../../../types';

@Component({
  selector: 'lib-offer-types-card',
  templateUrl: './offer-types-card.component.html',
  styleUrls: ['./offer-types-card.component.css']
})
export class OfferTypesCardComponent implements OnInit {

  
  @Input()
  preConditions: any;

  offersOpenExpired: OffersOpenExpired;
  loaded = false;
  
  constructor(protected dashboardKpiService: DashboardKpiService) {}
  
  ngOnInit() {
    const formDate = new Date(2015,0,1);
    const toDate = new Date();

    
    this.dashboardKpiService.getStatisticsOffersAmount(formDate)
    .subscribe((data) => {
      this.offersOpenExpired = data;

      this.loaded = true;
    });
  }
}
  