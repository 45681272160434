<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>CONFIRM</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="col-12">
    <div class="form-group pb-2">
      <label for="name" class="control-label" translate>ENTER_EMAIL_TO_SEND_EXPORT</label>
      <input
        class="form-control"
        type="text"
        name="name"
        id="name"
        [(ngModel)]="email"
        ngbAutofocus
        (keyup.enter)="confirm()"
      />
    </div>
    <div class="form-group pb-2">
        <lib-gung-checkbox-input-horizontal
            gungTranslate="SHOW_TEXT"
            [(ngModel)]="showText"
        >
    </lib-gung-checkbox-input-horizontal>
    <div class="alert alert-info" role="alert">
      <span translate>EXPORT_DATA_FEEDBACK</span>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="btn-group btn-group-justified w-100">
    <button class="btn btn-secondary" (click)="cancel()" translate>CANCEL</button>
    <button class="btn btn-primary" (click)="confirm()" translate>OK</button>
  </div>
</div>
