import { first, mergeMap } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { JeevesProductDetailsComponent } from 'gung-standard-jeeves';
import {
  ProductService,
  AvailabilityService,
  AuthService,
  PriceService,
  BaseDetailData,
  AssortmentRecursive,
  Product,
  GungFlowService,
  MetadataService,
  AssortmentService,
  BaseViewConfigService
} from 'gung-standard';
import { Observable, forkJoin, of } from 'rxjs';

interface ProductExtended extends Product {
  documents?: any[];
}
@Component({
  selector: 'sjoborg-product-details',
  templateUrl: './sjoborg-product-details.component.html',
  styleUrls: ['./sjoborg-product-details.component.scss']
})
export class SjoborgProductDetailsComponent extends JeevesProductDetailsComponent {
  userAssortment: string;

  public product: ProductExtended;
  currentLang = this.translateService.currentLang || 'se';

  public pimSparePartsProductsIds: string[] = [];

  @Input()
  currentAssortment?: AssortmentRecursive;

  constructor(
    protected route: ActivatedRoute,
    protected productService: ProductService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected translateService: TranslateService,
    protected gungFlowService: GungFlowService,
    protected metadataService: MetadataService,
    protected assortmentService: AssortmentService,
    protected baseViewConfigService: BaseViewConfigService
  ) {
    super(
      route,
      productService,
      availabilityService,
      authService,
      priceService,
      translateService,
      gungFlowService,
      metadataService,
      assortmentService
    );
  }

  protected handleObservable(observable: Observable<BaseDetailData>): void {
    observable
      .pipe(
        mergeMap(data => forkJoin([of(data), this.authService.getCurrentUser().pipe(first())])),
        mergeMap(([data, user]) => {
          this.userAssortment = user.assortment;
          return forkJoin([of(data)]);
        })
      )
      .subscribe(([d]) => {
        const { product: productAux, availability, price } = d;
        const product: ProductExtended = productAux;
        if (product.extra.artv) {
          this.addonIds = product.extra.artv.map(r => r.artnrtillvalart);
        }
        if (product.extra.qMatchCode1ProductsId || product.extra.qMatchCode2ProductsId) {
          this.pimRelatedProductsIds = [];
          if (product.extra.qMatchCode1ProductsId) {
            this.pimRelatedProductsIds.push(...product.extra.qMatchCode1ProductsId);
          }
          if (product.extra.qMatchCode2ProductsId) {
            this.pimRelatedProductsIds.push(...product.extra.qMatchCode2ProductsId);
          }
        }
        if (product.extra.pim.relatedProducts) {
          this.pimSparePartsProductsIds = product.extra.pim.relatedProducts.split(',');
        }
        const gungObject = this.currentAssortment || product;
        if (gungObject.extra.documents) {
          const baseUrl = 'https://cdn2.gung.io/'; // [s3Uri]?etag=[s3ETag]
          const documents: { name: string; url: string }[] = [];

          for (const doc of gungObject.extra.documents) {
            let name: string;
            if (doc.extra.i18n && doc.extra.i18n[this.currentLang]) {
              name = doc.extra.i18n[this.currentLang].documentType;
            }
            if (!name) {
              name = doc.name;
            }
            documents.push({
              name: name || 'TECHINCAL_CHARACTERISTICS_CATALOG',
              url: baseUrl + doc.s3Uri + '?etag=' + doc.s3ETag
            });
          }
          product.documents = documents;
        }
        if (product.extra.i18n && gungObject.extra.i18n[this.currentLang]) {
          product.description = product.extra.i18n[this.currentLang].description || '';
        }
        if (!product.description && product.extra.pim) {
          product.description = product.extra.pim.description || '';
        }
        this.product = product;
        this.availability = availability;
        this.price = price;

        this.initImagesCarousel();
      });
  }

  getPackageSize(item: any): number {
    if (!!item.extra.ar.artfsgforp) {
      return parseInt(item.extra.ar.artfsgforp, 10);
    }

    return 0;
  }

  getMetaArArtkod(product) {
    return this.metadataService.getMetadataValue('xm', 'arttypbeskr', product.extra.ar.artkod);
  }
}
