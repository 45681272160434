import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format, parse } from 'date-fns';
import { ListItemRendererComponent } from 'gung-list';
import { first, switchMap } from 'rxjs';
import { Activity } from '../../../models/activity';
import { ActivityService } from '../../../services/activitys/activity.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { SelectedCustomerService } from '../../../services/selected-customer/selected-customer.service';

@Component({
  selector: 'lib-activities-card-list',
  templateUrl: './activities-card-list.component.html',
  styleUrls: ['./activities-card-list.component.css']
})
export class ActivitiesCardListComponent extends ListItemRendererComponent<Activity> implements OnInit, AfterViewInit {
  constructor(
    protected activityService: ActivityService,
    protected metadataService: MetadataService,
    protected modalService: GungModalService,
    protected gungModalService: GungModalService,
    protected selectedCustomerService: SelectedCustomerService,
    protected translationService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  getMetadataValue(tableName: string, key: string): string {
    const metaValue = this.metadataService.getMetadataTableList(tableName)?.find(item => item.KEY === key);
    return metaValue ? metaValue.TX1 : '';
  }

  editActivity(activity: Activity) {
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(selectedCustomer => this.modalService.openEditActivityModal(selectedCustomer, activity));
  }

  removeActivity(activity: Activity) {
    this.gungModalService
      .openConfirmYesNoModal(undefined, this.translationService.instant('CONFIRM_DELETION', { value: '' }) + '?', {
        size: 'sm'
      })
      .then(
        result => {
          if (result) {
            this.sendDeleteRequest(activity);
          }
        },
        reason => {}
      );
  }

  sendDeleteRequest(activity: Activity): void {
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(
        first(),
        switchMap(selectedCustomer => this.activityService.removeActivity(selectedCustomer.id, activity))
      )
      .subscribe();
  }

  getDisplayStartDate(startDate): string {
    if (startDate) {
      try {
        return format(parse(startDate.split(' ')[0], 'yyyyMMdd', new Date()), 'yyyy-MM-dd');
      } catch (error) {}
    }
    return '';
  }
}
