<div *ngIf="!!this.invoices">
  <div style="position: relative; height: 650px">
    <canvas
      baseChart
      [datasets]="lineChartData"
      [labels]="lineChartLabels"
      [options]="lineChartOptions"
      [legend]="lineChartLegend"
      [type]="lineChartType"
    ></canvas>
  </div>
</div>
