import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { ImportLinesheet, ExcelToJson, MatchProducts } from '../../models/linesheet';
import { Assortment, AssortmentService } from '../../services/assortment.service';
import { AuthService } from '../../services/auth/auth.service';
import { CartService } from '../../services/cart/cart.service';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { LinesheetImportSupportService } from '../../services/linesheet/linesheet-import-support.service';
import { LinesheetService } from '../../services/linesheet/linesheet.service';
import { GungFlow } from '../../state/flow/types';
import { UploadItemsFromExcelConfigService } from '../../services/upload-items-from-excel-config.service';
import { User } from '../../state/auth/types';

@Component({
  selector: 'lib-upload-items-from-excel',
  templateUrl: './upload-items-from-excel.component.html',
  styleUrls: ['./upload-items-from-excel.component.css']
})
export class UploadItemsFromExcelComponent implements OnInit {
  loading: boolean;
  uploadFormat: string="COLUMN_FORMAT";
  replaceMode = 'REPLACE_CURRENT_ORDER';
  selectedFlow: GungFlow;
  selectedAssortment: Assortment;
  currentUser: User;
  result: ImportLinesheet;
  getSelectionActions;

  constructor(
    protected linesheetService: LinesheetService,
    protected gungFlowService: GungFlowService,
    protected assortmentService: AssortmentService,
    protected cartService: CartService,
    protected authService: AuthService,
    protected linesheetImportSupportService: LinesheetImportSupportService,
    protected uploadItemsFromExcelConfigService: UploadItemsFromExcelConfigService

  ) {}
 
  ngOnInit() {
    this.gungFlowService
      .getSelectedFlow()
      .pipe(first())
      .subscribe(flow => (this.selectedFlow = flow));
    this.assortmentService
      .getUserAssortment()
      .pipe(first())
      .subscribe(assortment => (this.selectedAssortment = assortment));
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => (this.currentUser = user));

      
  }

  uploadFile({ files }) {
    if (files.length === 0) {
      return;
    }

    this.uploadFileColumn(files);
    
  }

  uploadFileColumn(files: File[]) {
    this.loading = true;
    const formData = new FormData();
    for (const file of files) {
      formData.append('file', file);
    }
    this.linesheetService.cartsMatchProducts(this.selectedAssortment.id, this.selectedFlow.id, formData).subscribe(
      (event: MatchProducts) => {
        if (typeof event === 'object') {
          if (event) {
            this.result = {
              errors: event.errors,
              rows: event.mapped.map(r => ({
                rownr: r.rowCount,
                productId: r.productId,
                quantity: r.amount,
                eanCode: r.eanCode,
                product: r.product,
                discountPercent: r.discountPercent,
                externalPrice: r.externalPrice,
                id:r.productId
              }))
            };
            this.uploadItemsFromExcelConfigService.getSelectionActions().pipe(first()).subscribe(
              data => this.getSelectionActions = data
            ); 
          }
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }
  
  replaceGungDot(productId: string): string {
    if (productId.includes('_GUNGDOT_')) {
      return productId.replace('_GUNGDOT_', '.');
    }
  }



  
}
