import { Product } from '../../../models';
import { Availability } from '../../../models/availability';

export interface ReplenishmentCustomer {
  customerId: string;
  active: boolean;
}

export interface ReplenishmentProduct {
  id: string;
  productId: string;
  customerId: string;
  storeId: string;

  minimumQuantity: number;
  maximumQuantity: number;

  active: boolean;

  currentStock: number;
  inTransitQuantity?: number;
  currentStockUpdateTime: Date;

  extra: { [s: string]: any };
}

export interface ReplenishmentProductExpanded extends ReplenishmentProduct {
  availability: Availability;
  productData: Product;
  status: ReplenishmentProductStatus;
}

export enum ReplenishmentProductStatus {
  NO_PRESSURE = 'NO_PRESSURE', // No need from the customer
  SHORTAGE = 'SHORTAGE', // No disposable stock to create order
  OK_FOR_ORDER = 'OK_FOR_ORDER', // OK for order
  NEVER_SYNCED = 'NEVER_SYNCED' // OK for order
}
