import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RequestAccountComponent } from 'gung-standard';

@Component({
  selector: 'sjoborg-request-account',
  templateUrl: './sjoborg-request-account.component.html',
  styleUrls: ['./sjoborg-request-account.component.scss']
})
export class SjoborgRequestAccountComponent extends RequestAccountComponent implements OnInit {
  constructor(
    protected formBuilder: FormBuilder,
    protected httpClient: HttpClient,
    protected translateService: TranslateService
  ) {
    super(formBuilder, httpClient, translateService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  initForm() {
    this.accountForm = this.formBuilder.group({
      companyName: this.formBuilder.control('', [Validators.required]),
      companyId: this.formBuilder.control('', [Validators.required]), // foretag
      companyAddress: this.formBuilder.control('', [Validators.required]), // Leveransadress
      companyOrganisationId: this.formBuilder.control('', [Validators.required]), // Organisationsnummer
      // companyCountry: this.formBuilder.control('', [Validators.required]),
      companyContactName: this.formBuilder.control('', [Validators.required]), // Kontaktperson
      companyContactEmail: this.formBuilder.control('', [Validators.required, Validators.email]), // E-post
      companyContactPhone: this.formBuilder.control('', [Validators.required]), // Telefon

      extra: this.formBuilder.group({
        companyOrderConfirmationEmail: this.formBuilder.control('', [Validators.required, Validators.email]), // ORDER_CONFIRMATION_EMAIL
        companyInvoiceEmail: this.formBuilder.control('', [Validators.required, Validators.email]), // INVOICE_EMAIL
        companyExtraMessage: this.formBuilder.control('', []) // EXTRA_MESSAGE
      })
    });
  }
}
