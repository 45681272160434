<div class="dashboard-card card" *ngIf="loaded">
  <div class="card-body dashboard-card-content">
    <div class="title" translate>PLANNED_ACTIVITIES</div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>COMING_SEVEN_DAYS</span>
      </div>
      <span class="current-statistic text-primary">{{ activitiesUpcomingSevenDays | number: '1.0-0' }}</span>
    </div>

    <div class="d-flex justify-content-between">
      <div class="align-self-center">
        <span translate>LAST_SEVEN_DAYS</span>
      </div>
      <span class="current-statistic text-primary">{{ activitiesLastSevenDays | number: '1.0-0' }}</span>
    </div>

    <div class="progress-container mt-2">
      <ngb-progressbar [animated]="true" [value]="(activitiesUpcomingSevenDays / activitiesLastSevenDays) * 100">
      </ngb-progressbar>
      <div class="d-flex justify-content-between">
        <span class="sub-title"
          >{{ ((activitiesUpcomingSevenDays / activitiesLastSevenDays) * 100 || 0).toFixed() }}%</span
        >
        <span class="sub-title" translate>COMPARED_TO_LAST_SEVEN_DAYS</span>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-card card" *ngIf="!loaded">
  <div class="card-body dashboard-card-content">
    <div class="text-center dashboard-loading-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
