import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer, CustomerService } from 'gung-standard';
import { first } from 'rxjs';

@Component({
  selector: 'gung-jeeves-jeeves-user-claim-list',
  templateUrl: './jeeves-user-claim-list.component.html',
  styleUrls: ['./jeeves-user-claim-list.component.css']
})
export class JeevesUserClaimListComponent implements OnInit {

  customer: Customer;

  constructor(
    protected route: ActivatedRoute,
    protected customerService: CustomerService
  ) { }

  ngOnInit(): void {

    const routeParams = this.route.snapshot.params;
    if (routeParams.customerId != null) {
      this.customerService
        .getCustomer(routeParams.customerId)
        .pipe(first())
        .subscribe(customer => {
          this.customer = customer;
        });
    }
  }

}
