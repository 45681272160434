<div class="form-group row">
  <label *ngIf="!suppressLabel" class="col-form-label" [ngClass]="'col-12 col-sm-4'">
    {{ label | translate }}
    <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
  </label>
  <div class="col">
    <select *ngIf="!renderStatic" [disabled]="disabled" [name]="id" [id]="id" class="form-control form-select" [(ngModel)]="selected">
      <option *ngIf="!hideEmptyOption" value="">{{ emptyOptionLabel | translate }}</option>
      <option [value]="nameAsValue ? option.value : option.key" *ngFor="let option of metaOptions$ | async; trackBy: trackByFn">
        {{ showId ? option.key + ' - ' : '' }} <span *ngIf="valueTranslated">{{ option.value | translate }}</span> <span *ngIf="!valueTranslated">{{ option.value }}</span> {{ showIdEnd ? ' - ' + option.key : '' }}
      </option>
    </select>

    <input
     *ngIf="renderStatic"
      type="text"
      readonly
      class="form-control-plaintext"
      [id]="id"
      [value]="getSelectedValue(selected) | async"
    />
  </div>
</div>
