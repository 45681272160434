import { Injectable, Input } from '@angular/core';
import { parse } from 'date-fns';
import { SimpleConfigBaseFilter, ConfigService, ConfigBaseFilter, ListSortOption, ListLayout, ListLayoutSingleComponent } from 'gung-list';
import { Observable, switchMap, map } from 'rxjs';
import { ActivitiesCardListComponent } from '../../components/activity-hrp/activities-card-list/activities-card-list.component';
import { Activity } from '../../models/activity';
import { ActivityService } from '../activitys/activity.service';
import { MetadataService } from '../metadata/metadata.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';

export class ActivityHrpTypeFilter extends SimpleConfigBaseFilter<Activity> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'ACTIVITY_TYPE';
  }

  getOptionIds(item: Activity): string[] {
    if (item.extra.gungData) {
      return [item.extra.gungData.Aktivitet || ''];
    }
    return [''];
  }

  getOptionName(key: string): string {
    const metaValue = this.metadataService.getMetadataTableList('TA51').find(item => item.KEY === key);
    return metaValue ? metaValue.TX1 : '';
  }
}

export class ActivityHrpResponsibleFilter extends SimpleConfigBaseFilter<Activity> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'RESPONSIBLE';
  }

  getOptionIds(item: Activity): string[] {
    if (item.extra.gungData) {
      return [item.extra.gungData.Ansvarig || ''];
    }
    return [''];
  }

  getOptionName(key: string): string {
    const metaValue = this.metadataService.getMetadataTableList('TA04').find(item => item.KEY === key);
    return metaValue ? metaValue.TX1 : '';
  }
}

export class ActivityHrpStatusFilter extends SimpleConfigBaseFilter<Activity> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'STATUS';
  }

  getOptionIds(item: Activity): string[] {
    if (item.extra.gungData) {
      return [item.extra.gungData.Status || ''];
    }
    return [''];
  }

  getOptionName(key: string): string {
    const metaValue = this.metadataService.getMetadataTableList('TA54').find(item => item.KEY === key);
    return metaValue ? metaValue.TX1 : '';
  }
}

export class ActivityHrpContactFilter extends SimpleConfigBaseFilter<Activity> {
  constructor() {
    super();
  }

  getName(): string {
    return 'CONTACT';
  }

  getOptionIds(item: Activity): string[] {
    if (item.extra.gungData) {
      return [item.extra.gungData.Kontaktperson];
    }
    return [''];
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ActivityHrpListConfigService implements ConfigService<Activity> {
  @Input()
  customerId: string;

  constructor(
    protected activityService: ActivityService,
    protected metadataService: MetadataService,
    protected selectedCustomerService: SelectedCustomerService
  ) {}

  private parseDate(date: string): Date {
    if (date) {
      try {
        return parse(date, 'yyyyMMdd HH:mm:ss', new Date());
      } catch (error) {}
    }
    return new Date();
  }

  getItems(): Observable<Activity[]> {
    return this.selectedCustomerService.getSelectedCustomer().pipe(
      switchMap(selectedCustomer => this.activityService.getActivitys(selectedCustomer.id)),
      map((activities: Activity[]) => {
        const sorted = activities.sort((a, b) => {
          if (this.parseDate(a.extra.hrp.strdatetime) < this.parseDate(b.extra.hrp.strdatetime)) {
            return 1;
          }
          if (this.parseDate(a.extra.hrp.strdatetime) > this.parseDate(b.extra.hrp.strdatetime)) {
            return -1;
          }
          return 0;
        });
        return sorted;
      })
    );
  }

  getFilters(): ConfigBaseFilter<Activity>[] {
    return [
      // new ActivityHrpTypeFilter(this.metadataService),
      // new ActivityHrpResponsibleFilter(this.metadataService),
      // new ActivityHrpStatusFilter(this.metadataService),
      // new ActivityHrpContactFilter()
    ];
  }

  getSortOptions(): ListSortOption<Activity>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<Activity>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ActivitiesCardListComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'ActivityList'
      }
    ];
  }

  getSearchTerms(item: Activity): string[] {
    const searchTerms = [item.id, item.name];
    if (item.customerId) {
      searchTerms.push(item.customerId);
    }
    if (item.extra.gungData && item.extra.gungData.Kontaktperson) {
      searchTerms.push(item.extra.gungData.Kontaktperson);
    }
    return searchTerms;
  }

  getItemId(item: Activity): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
