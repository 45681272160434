<div class="dashboard-card card">
  <div class="card-body dashboard-card-content">
    <div class="title" translate>STATISTICS_EXCEL_EXPORT</div>
    <div class="d-flex justify-content-start">
      <span translate>STATISTICS_EXCEL_CARD_EXPORT_DESCRIPTION</span>
    </div>

    <div class="d-flex justify-content-between mt-3">
      <div class="align-self-center">
        <button class="btn btn-primary"
                (click)="generateExcel()"
                translate
        >GENERATE</button>
      </div>
    </div>

  </div>
</div>
