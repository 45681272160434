import { InjectionToken, Type } from '@angular/core';
import { Observable, of } from 'rxjs';

export class DashboardCard {
  component: Type<any>;
  sizeQuery: string;
  preConditions: any;
  extra: any;

  constructor(component: Type<any>, sizeQuery: string, preConditions: any = null, extra: any = null) {
    this.component = component;
    this.sizeQuery = sizeQuery;
    this.preConditions = preConditions;
    this.extra = extra;
  }
}

export class InvoiceStatistic {
  productId: string;
  productModelId: string;
  productName: string;
  productPrimaryDimensionId: string;
  productPrimaryDimensionName: string;
  productSecondaryDimensionId: string;
  productSecondaryDimensionName: string;
  productSeasonCode: string;
  productSeasonDescription: string;
  productCategoryCode: string;
  productCategoryDescription: string;
  id: string;
  invoiceRowNumber: number;
  invoiceRowTotalQuantity: number;
  invoiceRowTotalValue: number;
  invoiceRowTotalValueCurrency: number;
  invoice: string;
  invoiceCreationDateYearMonth: string;
  invoiceCreationDateYearWeek: string;
  invoiceCreationDateYearQuarter: string;
  invoiceTypeCode: string;
  invoiceTypeDescription: string;
  invoiceSeasonCode: string;
  invoiceSeasonDescription: string;
  invoiceId: string;
  invoiceCreationDate: any;
  invoiceDueDate: number;
  invoiceDueDateYearQuarter: string;
  invoiceDueDateYearMonth: string;
  invoiceDueDateYearWeek: string;
  customerId: string;
  customerName: string;
  orderIds: string[];
  invoiceOurReference: string;
  invoiceCountry: string;
}

export class InvoiceStatisticSubset {
  productId: string;
  productName: string;
  invoiceId: string;
  invoiceCreationDateYearMonth: string;
  invoiceRowTotalQuantity: number;
  invoiceRowTotalValue: number;
  invoiceRowTotalValueCurrency: number;
  invoiceOurReference: string;
  customerId: string;
  customerName: string;
  invoiceSeasonCode: string;
  productCategoryCode: string;
}

export class OrderStatistic {
  productId: string;
  productModelId: string;
  productName: string;
  productPrimaryDimensionId: string;
  productPrimaryDimensionName: string;
  productSecondaryDimensionId: string;
  productSecondaryDimensionName: string;
  productSeasonCode: string;
  productSeasonDescription: string;
  productCategoryCode: string;
  productCategoryDescription: string;
  id: string;
  customerId: string;
  customerName: string;
  orderRowNumber: number;
  orderRowTotalQuantity: number;
  orderRowTotalValue: number;
  orderRowTotalValueCurrency: number;
  orderCreationDateYearMonth: string;
  orderCreationDateYearWeek: string;
  orderCreationDateYearQuarter: string;
  orderTypeCode: string;
  orderTypeDescription: string;
  orderSeasonCode: string;
  orderSeasonDescription: string;
  orderId: string;
  orderCreationDate: Date;
  orderDeliveryDate: Date;
  orderDeliveryDateYearQuarter: string;
  orderDeliveryDateYearMonth: string;
  orderDeliveryDateYearWeek: string;
  orderRowDeliveryStatusCode: string;
  orderRowDeliveryStatusDescription: string;
}

export class EntityAmountComparison {
  entityAmount: number;
  entityCollectionSize: number;
}

export class ThisYearLastYearComparison {
  thisYear: number;
  lastYear: number;
}

export class SalesSummaryGroup {
  id: string;
  name: string;
  totalQuantity: number;
  totalAmount: number;
  entity: SalesSummaryEntity;
}

export enum SalesSummaryEntity {
  PRODUCT,
  CUSTOMER,
  ITEM_GROUP,
  MAIN_ENTITY
}

export enum Timespan {
  Week,
  LastWeek,
  Month,
  LastMonth,
  Year,
  LastYear,
  Today,
  TodayWeek,
  TodayLastWeek,
  TodayMonth,
  TodayLastMonth,
  TodayYear,
  TodayLastYear,
  MonthLastYear,
  SamePeriodLastYear,
  SameWeekLastYear
}

export class MonthlyChartSummary {
  labels: string[][];
  lastYear: number[];
  thisYear: number[];
  twelveMonthTotal: number[];
}

export class OffersOpenExpired {
  expired: number;
  open: number;
}
