import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  PriceService,
  GungFlowService,
  AuthService,
  ConceptDetailProductListViewComponent,
  AssortmentTreeImageSelectionService
} from 'gung-standard';
import { first } from 'rxjs';

@Component({
  selector: 'sjoborg-concept-detail-product-list-view',
  templateUrl: './sjoborg-concept-detail-product-list-view.component.html',
  styleUrls: ['./sjoborg-concept-detail-product-list-view.component.scss']
})
export class SjoborgConceptDetailProductListViewComponent
  extends ConceptDetailProductListViewComponent
  implements OnInit
{
  isSales: boolean = false;

  ngOnInit(): void {
    super.ngOnInit();
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(
        roles =>
          (this.isSales =
            roles.filter(role => role.toUpperCase() === 'ADMIN' || role.toUpperCase() === 'SALES').length > 0)
      );
    console.log(this.isSales);
  }

  getPackageSize(item: any): number {
    if (!!item.extra.ar.artfsgforp) {
      return parseInt(item.extra.ar.artfsgforp, 10);
    }

    return 0;
  }
}
