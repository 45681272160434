import { Component } from '@angular/core';
import { ProductionOrdersListConfigService } from '../../services/production-orders-list-config/production-orders-list-config.service';

@Component({
  selector: 'lib-production-orders-list',
  template:
    '<lib-filter-list [configService]="config"></lib-filter-list>',
  styleUrls: ['./production-orders-list.component.css']
})
export class ProductionOrdersListComponent {
  constructor(public config: ProductionOrdersListConfigService) {}
}
