import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { InvoiceStatistic } from '../../../types';
import { ChartConfiguration, ChartDataset, ChartOptions } from 'chart.js';
import { MetadataService } from 'gung-standard';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-invoice-statistics-field-chart',
  templateUrl: './invoice-statistics-field-chart.component.html',
  styleUrls: ['./invoice-statistics-field-chart.component.scss']
})
export class InvoiceStatisticsFieldChartComponent implements OnChanges {
  @Input()
  invoices: InvoiceStatistic[];

  @Input()
  invoiceField: string;

  chart: any;

  public lineChartData: ChartDataset[];
  public lineChartLabels: string[];
  public lineChartOptions: ChartConfiguration<'line'>['options'];
  public lineChartLegend = true;
  public lineChartType = 'line';

  chartData;

  constructor(protected metadataService: MetadataService, protected translateService: TranslateService) {}

  ngOnChanges(): void {
    this.activate();
    this.initChartOptions();
  }

  activate() {
    const groupedInvoices = this.groupInvoices(this.invoices);

    const labels = [];
    const data = [];

    groupedInvoices.forEach((invoices: InvoiceStatistic[], key: string) => {
      labels.push(key);

      const totalValue = invoices.reduce((accumulator: number, invoice: InvoiceStatistic) => {
        return accumulator + invoice.invoiceRowTotalValue;
      }, 0);

      data.push(totalValue);
    });

    this.lineChartLabels = labels;
    this.chartData = [data];
  }

  /**
   * Gets the key of the group the invoice belongs to. Function wrapper exists to enable
   * manipulation the pure key from the invoice, for an example, by fetching cleartext
   * version from metadata.
   * @param invoice The invoice to fetch the grouping key from.
   */
  getGroupKey(invoice: InvoiceStatistic): string {
    return invoice[this.invoiceField];
  }

  groupInvoices(invoices: InvoiceStatistic[]): Map<string, InvoiceStatistic[]> {
    const groupedInvoices = new Map();

    invoices.forEach((invoice: InvoiceStatistic) => {
      const key = this.getGroupKey(invoice);
      const collection = groupedInvoices.get(key);

      if (!collection) {
        groupedInvoices.set(key, [invoice]);
      } else {
        collection.push(invoice);
      }
    });

    return groupedInvoices;
  }

  initChartOptions(): void {
    this.lineChartData = [
      {
        label: this.translateService.instant(this.invoiceField.toUpperCase()),
        data: this.chartData[0],
        borderWidth: 0,
        yAxisID: 'y-axis-1',
        backgroundColor: '#6c757d',
        hoverBackgroundColor: '#464b50'
      }
    ];

    this.lineChartOptions = {
      plugins: {
        tooltip: {
          enabled: true,
          mode: 'index',
          callbacks: {
            label: context => {
              return context.dataset.data[context.dataIndex].toLocaleString();
            }
          }
        }
      },
      responsive: true,
      maintainAspectRatio: true,
      animation: {
        duration: 0
      },
      scales: {
        'y-axis-1': {
          type: 'linear',
          display: true,
          position: 'left',
          title: {
            display: true,
            text: this.translateService.instant('TOTAL'),
            font: {
              size: 20
            }
          }
        }
      }
    };
  }
}
