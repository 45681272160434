<div *ngIf="replenishmentCustomer">
  <div class="d-flex justify-content-between">
    <h1>{{ replenishmentCustomer.customerId }}</h1>

   <div class="d-flex flex-column">
     <lib-add-replenishment-product [replenishmentCustomerId]="replenishmentCustomer.customerId"></lib-add-replenishment-product>
     <div class="mt-auto ml-auto mb-2">
      <button class="btn btn-primary mr-2" (click)="createExcel()" translate>EXPORT_XLSX</button>
      <lib-create-replenishment-button [replenishmentCustomerId]="replenishmentCustomer.customerId"></lib-create-replenishment-button>
    </div>
   </div>

  </div>

  <lib-replenishment-product-list></lib-replenishment-product-list>
</div>
