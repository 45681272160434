import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { OrderPortalListViewComponent } from './components/order-portal-list-view/order-portal-list-view.component';
import { OrderPortalListComponent } from './components/order-portal-list/order-portal-list.component';
import { OrderPortalStatusDisplayComponent } from './components/order-portal-status-display/order-portal-status-display.component';
import { OrderPortalStatusModalComponent } from './components/order-portal-status-modal/order-portal-status-modal.component';
import { AuthGuard } from '../../guards/authguard/auth-guard';
import { AdminGuard } from '../../guards/adminguard/admin-guard.guard';
import { OrderPortalStatisticsChartComponent } from './components/order-portal-statistics-chart/order-portal-statistics-chart.component';
import { OrderPortalWrapperComponent } from './components/order-portal-wrapper/order-portal-wrapper.component';
import { featureActivatedGuard } from '../../guards/feature-activated/feature-activated.guard';

const routes: Routes = [
  {
    path: '',
    component: OrderPortalWrapperComponent,
    canActivate: [AuthGuard, AdminGuard, featureActivatedGuard],
    data: {
      featureId: 'orderPortal',
      minimumGungBaseVersion: '3.0.18'
    }
  },
  {
    path: 'list',
    component: OrderPortalListComponent,
    canActivate: [AuthGuard, AdminGuard, featureActivatedGuard],
    data: {
      featureId: 'orderPortal',
      minimumGungBaseVersion: '3.0.18'
    }
  }
];

@NgModule({
  declarations: [
    OrderPortalListViewComponent,
    OrderPortalListComponent,
    OrderPortalStatusDisplayComponent,
    OrderPortalStatusModalComponent,
    OrderPortalStatisticsChartComponent,
    OrderPortalWrapperComponent
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      extend: true
    }),
    RouterModule.forChild(routes)
  ]
})
export class OrderPortalModule {}
