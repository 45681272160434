import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Planogram } from '../../models/planogram';
import { PlanogramActionButtonConfigService } from '../../services/planogram-action-button-config.service';

@Component({
  selector: 'lib-planogram-grid-view',
  templateUrl: './planogram-grid-view.component.html',
  styleUrls: ['./planogram-grid-view.component.scss']
})
export class PlanogramGridViewComponent extends ListItemRendererComponent<Planogram[]> implements OnInit, AfterViewInit {

  blockBtns = true;

  constructor(public actionButtonConfig: PlanogramActionButtonConfigService) {
    super();
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
