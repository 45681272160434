import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReportCentralReportListComponent } from './components/report-central-report-list/report-central-report-list.component';
import { ReportCentralReportListTableComponent } from './components/report-central-report-list-table/report-central-report-list-table.component';
import { AuthGuard } from '../../guards/authguard/auth-guard';

const routes: Routes = [{ path: '', component: ReportCentralReportListComponent, canActivate: [AuthGuard] }];

@NgModule({
  declarations: [ReportCentralReportListComponent, ReportCentralReportListTableComponent],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      extend: true
    }),
    RouterModule.forChild(routes)
  ]
})
export class ReportCentralModule {}
