import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductionManagementMenuComponent } from './components/production-management-menu/production-management-menu.component';
import { ProductionManagementOrdersToPickComponent } from './components/production-management-orders-to-pick/production-management-orders-to-pick.component';
import { ProductionManagementShipOrdersComponent } from './components/production-management-ship-orders/production-management-ship-orders.component';
import { ProductionManagementStockCountingComponent } from './components/production-management-stock-counting/production-management-stock-counting.component';
import { ProductionManagementTransferInventoryComponent } from './components/production-management-transfer-inventory/production-management-transfer-inventory.component';
import { ProductionManagementOrdersToPickTableComponent } from './components/production-management-orders-to-pick-table/production-management-orders-to-pick-table.component';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '../../guards/adminguard/admin-guard.guard';
import { AuthGuard } from '../../guards/authguard/auth-guard';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { ProductionManagementProductSelectDropdownComponent } from './components/production-management-product-select-dropdown/production-management-product-select-dropdown.component';
import { ProductionManagementMetaDataSelectInputComponent } from './components/production-management-meta-data-select-input/production-management-meta-data-select-input.component';
import { ProductionManagementMrpListComponent } from './components/production-management-mrp-list/production-management-mrp-list.component';
import { ProductionManagementMrpComponent } from './components/production-management-mrp/production-management-mrp.component';
import { ProductionManagementCreatePurchaseOrderModalComponent } from './components/production-management-create-purchase-order-modal/production-management-create-purchase-order-modal.component';
import { ProductionManagementStockCountingModalComponent } from './components/production-management-stock-counting-modal/production-management-stock-counting-modal.component';

const routes: Routes = [
  {
    path: '',
    component: ProductionManagementMenuComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'stock-counting',
    component: ProductionManagementStockCountingComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'ship-orders',
    component: ProductionManagementShipOrdersComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'transfer-inventory',
    component: ProductionManagementTransferInventoryComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'orders-to-pick',
    component: ProductionManagementOrdersToPickComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'material-requirements-planning',
    component: ProductionManagementMrpListComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'orders-to-pick/:id',
    component: ProductionManagementShipOrdersComponent,
    canActivate: [AuthGuard, AdminGuard]
  }
];

@NgModule({
  declarations: [
    ProductionManagementMenuComponent,
    ProductionManagementOrdersToPickComponent,
    ProductionManagementShipOrdersComponent,
    ProductionManagementStockCountingComponent,
    ProductionManagementTransferInventoryComponent,
    ProductionManagementOrdersToPickTableComponent,
    ProductionManagementProductSelectDropdownComponent,
    ProductionManagementMetaDataSelectInputComponent,
    ProductionManagementMrpListComponent,
    ProductionManagementMrpComponent,
    ProductionManagementCreatePurchaseOrderModalComponent,
    ProductionManagementStockCountingModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild({
      extend: true
    }),
    RouterModule.forChild(routes)
  ]
})
export class ProductionManagementModule {}
