<div class="container">
  <div class="row">
    <div class="col-12 text-center">
      <h2 translate>SHIP_ORDERS</h2>
    </div>
    <div class="col-auto position-absolute">
      <a type="button" class="btn btn-info" routerLink=".."
      ><i class="fa-solid fa-arrow-left-long mr-1"></i><span translate>GO_BACK</span></a
      >
    </div>
  </div>
  <div class="row mb-2" *ngIf="!detailsView">
    <div class="col-12">
      <lib-gung-text-input-typeahead
        *ngIf="ordersList"
        gungTranslate="ORDER"
        [(ngModel)]="orderId"
        [suppressLabel]="false"
        [renderStatic]="false"
        [list]="ordersList"
        (selectItem)="selectItem($event)"
        [search]="searchTypeahead"
        [resultFormatter]="resultFormatter"
      ></lib-gung-text-input-typeahead>
    </div>
  </div>
  <div *ngIf="order" class="row mb-2">
    <div class="col-12"><span translate>ORDER</span>: {{ order?.id }}</div>

    <div class="col-12">
      <div class="alert alert-success" *ngIf="isDelivered(order)">
        <span translate>ORDER_IS_DELIVERED</span>
      </div>
    </div>
  </div>
  <div *ngIf="order" class="row mb-2">
    <div class="col-12">
      <button
        (click)="deliverOrder(order.rows)"
        type="button"
        class="btn btn-primary d-block w-100 mb-2"
        [disabled]="!order || isDelivered(order)"
        translate
      >
        DELIVER_ORDER
      </button>
    </div>
    <div class="col-4">
      <button
        (click)="openPrintPlocksedelModal()"
        type="button"
        class="btn btn-primary d-block w-100 mb-2"
        translate
        [disabled]="!productionManagementModalService.printPlocksedelModalEnabled">
        PRINT_PLOCKSEDEL
      </button>
    </div>
    <div class="col-4">
      <button
        (click)="openPrintFoljesedelModal()"
        type="button"
        class="btn btn-primary d-block w-100 mb-2"
        translate
        [disabled]="!productionManagementModalService.printFoljesedelModalEnabled">
        PRINT_FÖLJESEDEL
      </button>
    </div>
    <div class="col-4">
      <button
        (click)="openPrintShippingModal()"
        type="button"
        class="btn btn-primary d-block w-100 mb-2"
        translate
        [disabled]="!order || !isDelivered(order) || !productionManagementModalService.printShippingModalEnabled">
        PRINT_SHIPPING_LABELS
      </button>
    </div>
  </div>
  <div *ngIf="order" class="row mb-2">
    <div class="col-12">
      <span translate>SHIPPING_INFORMATION</span>
    </div>
  </div>
  <div *ngIf="loading" class="text-center">
    <i class="fa fa-spinner fa-spin fa-5x"></i>
  </div>
  <div *ngIf="order && !loading" class="row mb-2">
    <div class="col-12 table-responsive gung-responsive">
      <table class="table table-striped">
        <thead>
        <tr>
          <th colspan="3" translate>PRODUCT</th>
          <th translate>STOCK_LOCATION</th>
          <th translate>QTY_ON_ORDER</th>
          <th translate>DELIVERED_QUANTITY</th>
          <th translate>AVAILABLE</th>
          <th *ngIf="!isDelivered(order)" translate>QTY_TO_DELIVER</th>
          <th></th>
          <th translate>PICKED</th>
        </tr>
        </thead>
        <tbody *ngIf="order">
        <ng-template ngFor let-row [ngForOf]="order?.rows" let-i="index">
        <tr *ngIf="!freightProducts?.includes(row.productId)">
          <td [attr.data-label]="'ROW_NR' | translate">
            {{ row.rownr }}
          </td>
          <td [attr.data-label]="'PRODUCT_ID' | translate">
            {{ row.productId }}<br>
            {{products[row.productId]?.name}}
          </td>
          <td [attr.data-label]="'STOCKPOINT' | translate">
            {{ row.extra._StockPoint }}
          </td>
          <td>
            <span *ngIf="products[row.productId] && products[row.productId].extra?.Article?.DefaultStockLocation">
              {{products[row.productId].extra?.Article?.DefaultStockLocation}}
            </span>
          </td>
          <td [attr.data-label]="'QTY_ON_ORDER' | translate">
            {{ row.quantity }}
          </td>
          <td [attr.data-label]="'DELIVERED_QUANTITY' | translate">
            {{ row.extra.OrderRows?.DeliveredQuantity}}
          </td>
          <td [attr.data-label]="'AVAILABLE' | translate">
            {{ row.extra._availability?.currentStock }}
          </td>
          <td *ngIf="!isDelivered(order)" [attr.data-label]="'QTY_TO_DELIVER' | translate">
            <lib-gung-number-input
              suppressLabel="true"
              [disabled]="row.extra._qtyToDeliver === 0"
              [extraFormClass]="'mb-0'"
              [(ngModel)]="row.extra._qtyToDeliver"
              [maxValue]="row.extra._availability?.currentStock"
            ></lib-gung-number-input>
          </td>
          <td [attr.data-label]="'' | translate">
            <div class="text-nowrap">
              <button [disabled]="row.extra._availability?.currentStock !== 0"
                  title="{{ 'EMAIL' | translate}}"
                  type="button" class="btn btn-secondary btn-sm ml-1" (click)="sendEmailRowLastBox(i)">
                    Sista lådan
              </button>
              <button [disabled]="row.extra._availability?.currentStock !== 0"
                  title="{{ 'EMAIL' | translate}}"
                  type="button" class="btn btn-secondary btn-sm ml-1" (click)="sendEmailRowNoStock(i)">
                    Sista bågen
              </button>
              <button
                title="{{ 'STOCK_COUNTING' | translate}}"
                type="button" class="btn btn-secondary btn-sm ml-1" (click)="stockCountRow(i)">
                  <i class="fa-solid fa-boxes-stacked"></i>
              </button>
              <button
                title="{{ 'CANCEL' | translate}}"
                [disabled]="row.extra._qtyToDeliver === 0"
                type="button" class="btn btn-secondary btn-sm ml-1" (click)="cancelRow(i)">
                  <i class="fa-solid fa-ban"></i>
              </button>
            </div>
          </td>
          <td>
            <i *ngIf="row.extra._pickStatus?.picked" class="fa-solid fa-square-check fa-2x text-success cursor-pointer" (click)="toggleRow(row)" ngbTooltip="{{'PICKED_BY' | translate: {updatedBy: row.extra._pickStatus.updatedBy, updatedAt: (row.extra._pickStatus.updatedAt | date: 'yyyy-MM-dd HH:mm:ss') } }}"></i>
            <i *ngIf="!row.extra._pickStatus?.picked" class="fa-regular fa-square fa-2x cursor-pointer" (click)="toggleRow(row)"></i>
          </td>
        </tr>
        </ng-template>
        </tbody>
      </table>
    </div>
  </div>
</div>
