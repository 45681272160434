import { Injectable } from '@angular/core';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { SupplierPoListConfigService, SupplierService, SelectedSupplierService, SupplierPurchaseOrder, SupplierSortColumnService } from 'gung-standard';
import { JeevesSupplierPoListTableViewComponent } from '../../components/supplier/jeeves-supplier-po-list-table-view/jeeves-supplier-po-list-table-view.component';

@Injectable({
  providedIn: 'root'
})
export class JeevesSupplierPoListConfigService extends SupplierPoListConfigService {

  constructor(
    protected supplierService: SupplierService,
    protected selectedSupplierService: SelectedSupplierService,
    protected columnSortSelectionService: SupplierSortColumnService
  ) {
    super(supplierService, selectedSupplierService, columnSortSelectionService);
  }

  getSearchTerms(item: SupplierPurchaseOrder): string[] {
    return [item.orderId, item.productId, item.extra.bp.artbeskr, item.extra.bp.artnrlev, item.extra.bp.artnr, item.extra.bp.levsartbeskr];
  }

  getLayouts(): ListLayout<any>[] {
    return [
      {
        getIconClass: () => 'fas fa-th-list',
        getListItemComponent: () => JeevesSupplierPoListTableViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'SupplierPurchaseOrderTable'
      }
    ];
  }
}
