import { Injectable } from '@angular/core';
import { JeevesCustomerContactListConfigService } from 'gung-standard-jeeves';
import { CustomerContact, CustomerContactService, SelectedCustomerService } from 'gung-standard';
import { ListLayout, ListLayoutSingleComponent } from 'gung-list';
import { SjoborgJeevesCustomerContactCardListComponent } from '../components/sjoborg-jeeves-customer-contact-card-list/sjoborg-jeeves-customer-contact-card-list.component';

@Injectable({
  providedIn: 'root'
})
export class SjoborgJeevesCustomerContactListConfigService extends JeevesCustomerContactListConfigService {
  constructor(
    protected customerContactService: CustomerContactService,
    protected selectedCustomerService: SelectedCustomerService
  ) {
    super(customerContactService, selectedCustomerService);
  }

  getLayouts(): ListLayout<CustomerContact>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => SjoborgJeevesCustomerContactCardListComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'CustomerContacts grid'
      }
    ];
  }
}
