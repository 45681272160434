import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { HubspotContacts } from '../../models/hubspot-contacts';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { HubspotContactCardListComponent } from '../../components/hubspot-contact-card-list/hubspot-contact-card-list.component';
import { HubspotContactService } from '../hubspot-contact/hubspot-contact.service';

@Injectable({
  providedIn: 'root'
})
export class HubspotContactListConfigService implements ConfigService<HubspotContacts>  {

  customerId = new BehaviorSubject<string>(null);
  setCustomerId(customerId: string) {
    this.customerId.next(customerId);
  }

  constructor(protected hubspotContactService: HubspotContactService){

  }

  getItems(): Observable<HubspotContacts[]> {
    if (this.customerId == null) {
      return of([]);
    }
    return this.customerId.pipe(
      switchMap(customerId=>
        this.hubspotContactService.getHubspotContacts(customerId)
      ));
  }

  getFilters(): ConfigBaseFilter<HubspotContacts>[] {
    return [];
  }

  getSortOptions(): ListSortOption<HubspotContacts>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<HubspotContacts>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => HubspotContactCardListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'hubspot grid'
      }
    ];
  }

  getSearchTerms(item: HubspotContacts): string[] {
    return [];
  }

  getItemId(item: HubspotContacts): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
