{
  "EXPRESS_DELIVERY_NOT_AVAILABLE_SPIKES_SIPING_ASSEMBLY": "Express delivery not available in combination with spikes, siping or assembly",
  "SELECT_ACTION": "Select action",
  "VALIDATION": "Validation",
  "TRY_AGAIN": "Try again",
  "RECYCLING_FEES_WILL_BE_ADDED": "Recycling fees will be added",
  "ITEMS_ADDED_AS_MANDATORY_EXTRAS_TO_ABOVE_CART": "The items below has been automatically added",
  "CART_CONTAINS_AUTO_ADDED_PRODUCTS": "Cart contains auto added products",
  "OH_SALESTYPE": "Salestype",
  "PRODUCTS_NOT_IN_CAMPAIGN": "Products not in campaign",
  "COLMEC_NEW_PRODUCT": "New",
  "USER_AVAILABILITY_OUT_OF_STOCK": "Out of stock. Contact us on 011 - 13 40 37 for information",
  "COLMEC_ADD_DUBBING": "Spikes",
  "COLMEC_NO_PRODUCTS_FOUND_CONTACT_US": "We could not find what you are looking for <br/>Contact us on 011 - 13 40 37 and we will be happy to help you.",
  "YES_EXPRESS_DELIVERY_INCL_COST_INFO": "Yes - Cost of SEK 150 per tire will be added",
  "EXPRESS_DELIVERY": "Express Shipping - external carrier - next day delivery",
  "AR_VARUGRUPPKOD": "Brand",
  "NO_BRAND_DEFINED": "No brand defined",
  "NO_PATTERN_DEFINED": "No pattern defined",
  "AR_ITEMTYPECD6": "Usage type",
  "NO_USAGE_TYPE_DEFINED": "No usage type defined",
  "AR_Q_MS": "M+S",
  "NO_MS_DEFINED": "No M+S defined",
  "AR_Q_3PMSF": "3PMSF",
  "NO_3PMSF_DEFINED": "No 3PMSF defined",
  "DIMENSION": "Dimension",
  "NO_DIMENSION_DEFINED": "No dimension defined",
  "PRODUCT_RELATED_PRODUCTS": "Related products",
  "NO_PRODUCT_RELATED_PRODUCTS_FOUND": "No related products have been found",
  "PRODUCT_RELATED_ADDONS": "Accessories",
  "NO_PRODUCT_RELATED_ADDONS_FOUND": "No related accessories have been found",
  "SUBASSORTMENTS": "Subassortments",
  "ITEM_NO": "Item No.",
  "NET_PRICE": "Net price",
  "NET_PRICE_INC_FRAME": "Net price inc. frame",
  "AR_ITEMTYPECD1": "Width",
  "AR_ITEMTYPECD2": "Profile",
  "AR_ITEMTYPECD3": "Diameter",
  "AR_ITEMTYPECD4": "Manufacturer",
  "AR_ITEMTYPECD5": "Pattern",
  "AR_Q_POSITION": "Position",
  "AR_Q_MONSTERDJUP": "Tread depth",
  "AR_Q_KLASSID": "Stomklass",
  "NO_STOMKLASS": "No stomklass",
  "LISTPRIS": "LISTPRIS",
  "PRODUCT_DETAIL_PROPERTIES": "Properties",
  "PRODUCT_DETAIL_ADDONS": "Addons",
  "ADDONS": "Addons",
  "PRODUCT_DETAIL_DOCUMENTS": "Documents",
  "SPIKES": "Spikes",
  "THANK_YOU_EMAIL_CONFIRMATION_FROM_CONTEXT_ORIGINAL_INPUT": "An order confimation has been sent to the following addresses",
  "SIPING": "Siping",
  "EU_LABELING": "EU Labeling",
  "ADDITIONAL_COSTS": "Additional costs",
  "EXPRESS_FREIGHT": "Express Freight",
  "BOOLEAN_NO": "No",
  "COLMEC_CHECKOUT_NO_ADDONS": "No options available for selected products",
  "PRICE_EXCLUSIVE_FRAME": "Price excluding frame",
  "ADDITIONAL_DELIVERY_TIME_SIPING_SPIKES": "Delivery time for spikes / siping: 3 days",
  "COLMEC_TOOLTIP_AVAILABIITY_MISSING": "Available stock exceeded",
  "DELIVERY_DATE_REQUIRED": "The Delivery Date is required",
  "PRODUCT_LIST_TOTAL": "Displays {{render}} of {{total}}",
  "COLMEC_DELIVERY_DATE": "Departing from Colmec",
  "COLMEC_SEARCH_PRODUCTS_PLACEHOLDER": "Search: Ex. 315/80 BSG Continental",
  "COLMEC_PLACE_ORDER_TERMS_AND_CONDITIONS": "By submitting the order you agree to our ",
  "COLMEC_PLACE_ORDER_TERMS_AND_CONDITIONS_LINK": "General Terms and Conditions",
  "COLMEC_LOGIN_CONTACT_US": "Missing login information?<br/>Contact us on webshop@colmec.se and we will help you get started",
  "COLMEC_RIM": "Rim",
  "COLMEC_ASSEMBLY": "Assembly",
  "COLMEC_AUTO_ADDED": "Auto added",
  "COLMEC_ADDON": "Addon",
  "ADD_RIM": "Add Rim",
  "ADD_RIMS": "Add Rims",
  "CLEAR_AND_CLOSE": "Clear and close",
  "ADD_AND_CLOSE": "Add and close",
  "SELECT_ASSEMBLY": "Select Assembly",
  "RIM_REQUIRED": "Need to select one rim and assembly",
  "READ_MORE_AND_ORDER": "Read more & Order",
  "SUB_CATEGORY_1": "Filter 1",
  "SUB_CATEGORY_2": "Filter 2",
  "SUB_CATEGORY_3": "Filter 3",
  "SUB_CATEGORY_4": "Filter 4",
  "SEND_QUOTE": "Send quote",
  "SUPPLIER_STOCK": "Not in stock (0)",
  "SJOBORG_FREIGHT_COST": "Freight cost of <strong>{{value}} {{currencyCode}}</strong> will be added",
  "SPAREPARTS": "Spareparts",
  "ENTER_COMPANY": "Enter company",
  "EMAIL_ORDER_CONFIRMATION": "Order confirmation email",
  "ENTER_EMAIL_ORDER_CONFIRMATION": "Enter order confirmation email",
  "EMAIL_INVOICE": "Invoice email",
  "ENTER_EMAIL_INVOICE": "Enter invoice email",
  "EXTRA_MESSAGE": "Extra message",
  "ENTER_EXTRA_MESSAGE": "Enter extra message",
  "QUANTITY_ADJUSTED_UP_CLOSET_PACKAGE_SIZE": "Qty adjusted up to the closest package size",
  "CART_ARTICLE_SKU_TOTAL": "{{article}} articles and {{sku}} st",
  "MODIFY_DATE": "Modify date",
  "OUR_RECOMMENDATION": "Our recommendation"
}
