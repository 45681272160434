import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigBaseFilter, FilterListRoutingService } from 'gung-list';
import {
  AssortmentService,
  AssortmentTreeImageSelectionService,
  AssortmentTreeNavigationComponent,
  AssortmentTreeNavigationConfigService,
  AuthService,
  GungFlowService,
  MetadataService,
  PriceService,
  ProductService,
  CloudPimFiltersService,
  Product,
  GungTitleService
} from 'gung-standard';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'sjoborg-assortment-tree-navigation',
  templateUrl: './sjoborg-assortment-tree-navigation.component.html',
  styleUrls: ['./sjoborg-assortment-tree-navigation.component.scss']
})
export class SjoborgAssortmentTreeNavigationComponent extends AssortmentTreeNavigationComponent {
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected authService: AuthService,
    protected assortmentService: AssortmentService,
    protected productService: ProductService,
    protected translateService: TranslateService,
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected metadataService: MetadataService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected changeDetectorRef: ChangeDetectorRef,
    protected assortmentTreeNavigationConfigService: AssortmentTreeNavigationConfigService,
    protected assortmentTreeImageSelectionService: AssortmentTreeImageSelectionService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected filterListRoutingService: FilterListRoutingService,
    protected titleService: Title,
    protected gungTitleService: GungTitleService
  ) {
    super(
      router,
      route,
      authService,
      assortmentService,
      productService,
      translateService,
      priceService,
      gungFlowService,
      metadataService,
      componentFactoryResolver,
      changeDetectorRef,
      assortmentTreeNavigationConfigService,
      assortmentTreeImageSelectionService,
      cloudPimFiltersService,
      titleService,
      gungTitleService
    );
  }

  getDocuments() {
    if (this.currentAssortment && this.currentAssortment.extra.documents) {
      this.currentAssortment.documents = [];
      const baseUrl = 'https://cdn2.gung.io/';
      const documents: { name: string; url: string }[] = [];

      for (const doc of this.currentAssortment.extra.documents) {
        let name: string;
        if (doc.i18n && doc.i18n[this.currentLang]) {
          name = doc.i18n[this.currentLang].description;
        }
        if (!name && doc.extra.i18n && doc.extra.i18n[this.currentLang]) {
          name = doc.extra.i18n[this.currentLang].description;
        }
        if (!name) {
          name = doc.name;
        }
        documents.push({
          name: name || 'TECHINCAL_CHARACTERISTICS_CATALOG',
          url: baseUrl + doc.s3Uri + '?etag=' + doc.s3ETag
        });
      }
      this.currentAssortment.documents = documents;
    }
  }
}
