<div class="row pb-2 d-none d-md-flex" *ngIf="!!mappedData">
  <div class="col-10 text-right">
    <div class="row">
      <div class="col">
        <span translate>REQUEST_DELIVERY_DATE</span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-link p-0" (click)="restoreMinDate()">
          (<span class="small" translate>RESTORE_AVAILABLE_DATES</span>)
        </button>
      </div>
    </div>
  </div>
  <div class="col-2">
    <lib-delivery-date-picker
      class="request-delivery-date"
      [label]="null"
      [initialDate]="deliveryDate"
      (selectedDate)="dateSelected($event)"
    ></lib-delivery-date-picker>
  </div>
</div>
<div class="row pb-2 d-none d-md-flex">
  <div class="col-4 col-lg-1"></div>
  <div class="d-none d-xl-block col-12 col-md-6 col-lg-4 col-xl-2"></div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-2">
    <b translate>QUANTITY</b>
  </div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-2">
    <b translate>PRICE</b>
  </div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-1">
    <b translate>DISCOUNT_PERCENT</b>
  </div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-1">
    <b translate>TOTAL</b>
  </div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-2">
    <b translate>DELIVERY</b>
  </div>
  <div class="col-1"></div>
</div>
<span *ngFor="let row of mappedData; trackBy: trackByFn">
  <div class="row pb-2 align-items-center">
    <div
      class="col-3 col-lg-1 d-flex align-items-center navigate-to-product-details"
      [routerLink]="row.cartRow.extra._path ? row.cartRow.extra._path : ('/product/' + row.product.id)"
    >
      <img
        class="img-fluid"
        *ngIf="row.product.extra.images && row.product.extra.images[0]; else noImage"
        [src]="row.product.extra.images[0].s3Uri | gungImageUrl
          :'type:list'
          :'etag:'+row.product.extra.images[0].s3ETag
        "
        alt=""
      />
      <ng-template #noImage>
        <img
          class="img-fluid"
          [src]="'images/no-image.jpg' | gungImageUrl"
          alt="NO IMAGE"
          alt=""
        />
      </ng-template>
    </div>

    <div
      class="col-8 col-lg-4 col-xl-2 text-truncate navigate-to-product-details"
      [routerLink]="row.cartRow.extra._path ? row.cartRow.extra._path : ('/product/' + row.product.id)"
    >
      <div>
        <b>{{ row.product.id }}</b> - {{ row.product.name }}
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-2 offset-sm-3 offset-lg-0 col-request-quantity">
      <div class="row pt-1">
        <div class="col-4 d-flex d-xl-none align-items-center">
          <b translate>QUANTITY</b>
        </div>
        <div class="col-8 col-xl-12">
          <!-- <input type="number" class="form-control" [value]="row.quantity"
            (blur)="updateQty(row, $event.target.value)" /> -->
          <lib-buy-btn [id]="row.product.id" [product]="row.product" [productPartialId]="row.productPartialId" [checkoutCart]="true"></lib-buy-btn>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-2 offset-sm-3 offset-lg-0">
      <div class="row pt-1">
        <div class="col-4 d-flex d-xl-none align-items-center">
          <b translate>PRICE</b>
        </div>
        <div class="col-8 col-xl-12 d-flex">
          <input
            type="number"
            class="form-control"
            [value]="parseFloatFixed(row.overridePrice, 2)"
            [placeholder]="row.originalPrice | number: '1.2-2'"
            (blur)="updateOverridePrice(row, parseFloatFixed($event.target.value, 2))"
          />
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-1 offset-sm-3 offset-lg-1 offset-xl-0">
      <div class="row pt-1">
        <div class="col-4 col-lg-5 d-flex d-xl-none align-items-center">
          <b translate>DISCOUNT_PERCENT</b>
        </div>
        <div class="col-8 col-lg-7 col-xl-12 d-flex">
          <input
            type="number"
            class="form-control discount-control"
            [value]="row.discountPercent || row.cartRowPriceElement.cartRowDiscountPercent || 0"
            (blur)="updateDiscountPercentage(row, $event.target.value)"
            placeholder="%"
          />
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-1 offset-sm-3 offset-lg-1 offset-xl-0">
      <div class="row pt-1">
        <div class="col-4 d-flex d-xl-none">
          <b translate>TOTAL</b>
        </div>
        <div class="col-8 col-xl-12 d-flex">
          <lib-price [price]="row.cartRowPriceElement" type="cartRowTotalPriceInclRowDiscount"></lib-price>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-2 offset-sm-3 offset-lg-0">
      <div class="row pt-1">
        <div class="col-4 d-flex d-xl-none align-items-center">
          <b translate>DELIVERY</b>
        </div>
        <div class="col-8 col-xl-12 d-flex">
          <!-- <div class="input-group">
            <input class="form-control input-date" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #d="ngbDatepicker" readonly
              container="body" [value]="row.deliveryDate ? row.deliveryDate : formatNgbDate(row.minDate)"
              [minDate]="row.minDate" (dateSelect)="updateDeliveryDate(row, $event)" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                <i class="fal fa-calendar-alt"></i>
              </button>
            </div>
          </div> -->
          <lib-delivery-date-picker
            class="request-delivery-date"
            [label]="null"
            [initialDate]="row.deliveryDate ? row.deliveryDate : formatNgbDate(row.minDate)"
            (selectedDate)="updateDeliveryDate(row, $event)"
            [currentselectedDate]="row.deliveryDate"
          ></lib-delivery-date-picker>
        </div>
      </div>
    </div>

    <div
      class="col-2 col-md-1 order-first order-xl-0 offset-11 offset-xl-0 d-flex align-items-center col-delete-button"
    >
      <button class="btn btn-light delete-button" (click)="removeRow(row)">
        <i class="fal fa-minus-circle"></i>
      </button>
    </div>
  </div>
  <div class="row hr"></div>
</span>

<!-- <div class="table-responsive">
  <table class="table">
    <thead>
      <tr>
        <th translate>ITEM</th>
        <th translate>QUANTITY</th>
        <th translate>PRICE</th>
        <th translate>DISCOUNT_PERCENT</th>
        <th translate>TOTAL</th>
        <th translate width="200px">DELIVERY</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of mappedData; trackBy: trackByFn">
        <td class="text-nowrap">
          {{ row.productId }}
          <span *ngIf="row.productId !== row.name" class="text-nowrap">
            <br>
            {{ row.name }}
          </span>
        </td>
        <td>
          <input type="number" class="form-control" [value]="row.quantity"
            (blur)="updateQty(row, $event.target.value)" />
        </td>
        <td>
          <input type="number" class="form-control" [value]="row.overridePrice"
            [placeholder]="row.originalPrice | number: '1.0-2'"
            (blur)="updateOverridePrice(row, $event.target.value)"/>
        </td>
        <td>
          <input type="number" class="form-control discount-control" [value]="row.discountPercent || 0"
            (blur)="updateDiscountPercentage(row, $event.target.value)" placeholder="%" />
        </td>
        <td>
          {{ row.cartRowPriceElement.cartRowTotalPriceInclRowDiscount.value | gungCurrency: row.cartRowPriceElement.cartRowTotalPriceInclRowDiscount.currencyCode }}
        </td>
        <td>
          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #d="ngbDatepicker" readonly container="body"
              [value]="row.deliveryDate ? row.deliveryDate : formatNgbDate(row.minDate)" [minDate]="row.minDate"
              (dateSelect)="updateDeliveryDate(row, $event)"/>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                <i class="fas fa-calendar"></i>
              </button>
            </div>
          </div>
        </td>
        <td>
          <button class="btn" type="button" (click)="removeRow(row)">
            <i class="fas fa-times"></i>
          </button>
        </td>

      </tr>
    </tbody>
  </table>
</div> -->
