import { Component, OnInit } from '@angular/core';
import { JeevesQuotesListConfigService } from '../../services/jeeves-quotes-list-config/jeeves-quotes-list-config.service';

@Component({
  selector: 'gung-jeeves-jeeves-quotes-list',
  template: '<lib-filter-list [configService]="jeevesQuotesListConfig"></lib-filter-list>',
  styleUrls: ['./jeeves-quotes-list.component.css']
})
export class JeevesQuotesListComponent {
  constructor(public jeevesQuotesListConfig: JeevesQuotesListConfigService) {}
}
