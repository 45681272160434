import { Component } from '@angular/core';
import { ProductTreeListViewComponent } from 'gung-standard';

@Component({
  selector: 'sjoborg-product-tree-list-view',
  templateUrl: './sjoborg-product-tree-list-view.component.html',
  styleUrls: ['./sjoborg-product-tree-list-view.component.scss']
})
export class SjoborgProductTreeListViewComponent extends ProductTreeListViewComponent {
  public headerDynamicTranslationKeys: string[] = [];
  public mappedData: any[] = [];

  protected subscribeToProducts() {
    super.subscribeToProducts();

    const headerDynamic = this.mappedData.find(
      m => m && m.headerDynamicTranslationKeys && m.headerDynamicTranslationKeys.length > 0
    );
    this.headerDynamicTranslationKeys = headerDynamic ? headerDynamic.headerDynamicTranslationKeys : [];
  }

  getPackageSize(item: any): number {
    if (!!item.extra.ar.artfsgforp) {
      return parseInt(item.extra.ar.artfsgforp, 10);
    }

    return 0;
  }
}
