import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationPrefixService {
  getPrefixes() {
    return ['i18n/', 'i18n/erp/', 'i18n/custom/'];
  }
  constructor() {}
}
