import { Component } from '@angular/core';
import { ProductSelectDropdownComponent } from '../../../../components/product-select-dropdown/product-select-dropdown.component';

@Component({
  selector: 'lib-production-management-product-select-dropdown',
  templateUrl: './production-management-product-select-dropdown.component.html',
  styleUrls: ['./production-management-product-select-dropdown.component.css']
})
export class ProductionManagementProductSelectDropdownComponent extends ProductSelectDropdownComponent {

}
