<div *ngIf="!!customer">
  <div class="row mb-4">
    <div class="col d-flex flex-direction-row justify-content-between">
      <h3>{{ customer.name }}</h3>
      <lib-action-btn [item]="customer" [config]="configService"></lib-action-btn>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <gung-jeeves-jeeves-customer-details></gung-jeeves-jeeves-customer-details>
    </div>
  </div>
</div>
