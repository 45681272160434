import { Injectable } from '@angular/core';
import { LoggedInUsersService } from './logged-in-users.service';
import { ConfigBaseFilter, ConfigService, ListLayout, ListSortOption } from 'gung-list';
import { Observable, first, forkJoin, map, of, switchMap } from 'rxjs';
import { User } from '../state/auth/types';
import { UsersListConfigService } from './users-list-config/users-list-config.service';
import { UsersService } from './users/users.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInUserListConfigService implements ConfigService<User> {
  constructor(
    protected loggedInUsersService: LoggedInUsersService,
    protected usersListConfigService: UsersListConfigService,
    protected usersService: UsersService
  ) {}

  getItems(): Observable<User[]> {
    return this.usersService.getAllUsersSubject().pipe(
      switchMap(users => forkJoin({
        users: of(users),
        loggedUsers: this.loggedInUsersService.getLoggedInUsers().pipe(first())
      })),
      map(({users, loggedUsers}) => users?.filter(user => loggedUsers?.includes(user?.username)) || [])
    );
  }

  getFilters(): ConfigBaseFilter<User>[] {
    return this.usersListConfigService.getFilters();
  }

  getSortOptions(): ListSortOption<User>[] {
    return this.usersListConfigService.getSortOptions();
  }

  getBatchSizes(): number[] {
    return this.usersListConfigService.getBatchSizes();
  }

  getLayouts(): ListLayout<User>[] {
    return this.usersListConfigService.getLayouts();
  }

  getSearchTerms(item: User): string[] {
    return this.usersListConfigService.getSearchTerms(item);
  }

  getItemId(item: User): string {
    return this.usersListConfigService.getItemId(item);
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
