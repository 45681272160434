import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ProductService } from '../../services/products/product.service';
import { Observable, filter, first, map, switchMap } from 'rxjs';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';

@Injectable({
  providedIn: 'root'
})
export class GungBlockedProductFlowGuard implements CanActivate {
  constructor(
    protected productService: ProductService,
    protected flowService: GungFlowService,
    protected router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const productId = route.params.productId;
    return this.flowService
      .getSelectedFlow()
      .pipe(
        filter(flow => !!flow),
        first()
      )
      .pipe(
        switchMap(flow => this.productService.getBlockedProduct(productId, flow.id).pipe(first())),
        map(blocked => {
          if (blocked?.[productId]) {
            return this.router.createUrlTree(['/']);
          }
          return !blocked?.[productId];
        })
      );
  }
}
