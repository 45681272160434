import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { addWeeks, isSameDay, isWithinInterval, parse, parseISO, subDays, subWeeks } from 'date-fns';
import { ActivityInternalService } from 'gung-standard';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-upcoming-activities-card',
  templateUrl: './upcoming-activities-card.component.html',
  styleUrls: ['./upcoming-activities-card.component.scss']
})
export class UpcomingActivitiesCardComponent implements OnInit, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();

  activitiesLastSevenDays = 0;
  activitiesUpcomingSevenDays = 0;
  loaded = false;

  constructor(protected activityInternalService: ActivityInternalService) {}

  ngOnInit(): void {
    const oneWeekBack = subWeeks(new Date(), 1);
    const oneWeekForward = addWeeks(new Date(), 1);
    const today = new Date();

    this.activityInternalService
      .getAll()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        res.forEach(a => {
          const activityDate = parseISO(a.activityDate);
          const isWithinOneWeekForward =
            isSameDay(activityDate, today) || isWithinInterval(activityDate, { start: today, end: oneWeekForward });
          if (isWithinOneWeekForward) {
            this.activitiesUpcomingSevenDays++;
            return;
          }
          const isWithinOneWeekBack = isWithinInterval(activityDate, { start: oneWeekBack, end: today });
          if (isWithinOneWeekBack) {
            this.activitiesLastSevenDays++;
            return;
          }
        });

        this.loaded = true;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
