<div class="row" *ngIf="!loadingComponent && minDate; else loading">
  <div class="col-12 col-md-6">
    <div [formGroup]="form">
      <lib-meta-select-input formControlName="levsattkod" table="x2f" metaId="levsattkod" metaDisplay="levsattbeskr">
      </lib-meta-select-input>
    </div>
    <div [formGroup]="form">
      <lib-meta-select-input formControlName="levvillkkod" table="x2e" metaId="levvillkkod" metaDisplay="levvillkbeskr">
      </lib-meta-select-input>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <div [formGroup]="form">
      <lib-meta-select-input formControlName="betkod" table="x2" metaId="betkod" metaDisplay="betvillbeskr">
      </lib-meta-select-input>
    </div>
    <div [formGroup]="form">
      <lib-meta-select-input formControlName="saljare" table="salj" metaId="saljare" metaDisplay="saljarenamn">
      </lib-meta-select-input>
    </div>
    <div>
      <lib-delivery-date-picker
        *ngIf="minDate"
        (selectedDate)="dateSelected($event)"
        [deliveryMethod]="checkout.extra.procargs.levsattkod"
        [minInitialDate]="minDate"
      ></lib-delivery-date-picker>
    </div>
  </div>
</div>

<ng-template #loading translate> LOADING </ng-template>
