<div class="modal-header">
  <h4 class="modal-title" id="modal-title" translate>CREATE_USER</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
@if(userForm){
  <form class="form" [formGroup]="userForm" (ngSubmit)="onAdd()">
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <lib-gung-text-input
            gungTranslate="USERNAME"
            formControlName="username"
            [placeholder]="'USERNAME' | translate"
          ></lib-gung-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <lib-gung-password-input
            gungTranslate="PASSWORD"
            [placeholder]="'PASSWORD' | translate"
            [passwordRequirement]="passwordRequirements"
            [(ngModel)]="password"
            (validPassword)="isValid($event)"
            autocomplete="new-password"
            [ngModelOptions]="{standalone: true}"
          >
          </lib-gung-password-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <lib-gung-text-input
            gungTranslate="NAME"
            formControlName="name"
            [placeholder]="'NAME' | translate"
          ></lib-gung-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <lib-gung-text-input
            gungTranslate="EMAIL"
            formControlName="email"
            [placeholder]="'EMAIL' | translate"
          ></lib-gung-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <lib-gung-text-input
            gungTranslate="PHONE"
            formControlName="phone"
            [placeholder]="'PHONE' | translate"
          ></lib-gung-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <lib-gung-text-input
            gungTranslate="ASSORTMENT"
            formControlName="assortment"
            [placeholder]="'ASSORTMENT' | translate"
          ></lib-gung-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <lib-gung-text-input
            gungTranslate="CUSTOMER_ID"
            formControlName="managedCompanyIds"
            [placeholder]="'CUSTOMER_ID' | translate"
          ></lib-gung-text-input>
        </div>
      </div>
  
      <div class="row">
        <div class="col-12">
          <ngb-alert class="mt-2" *ngIf="alertMessage" [type]="alertMessage.type" (close)="alertMessage = null">
            {{ alertMessage.message | translate }}
            <br />
            <span *ngFor="let field of alertMessage.invalidFields; let last = last">
              {{ field | translate }}<span *ngIf="!last">, </span>
            </span>
          </ngb-alert>
          <!-- <div *ngIf="alertMessage" class="alert mt-2 text-center" role="alert"
            [ngClass]="{alertMessage.type}"
            (click)="alertMessage = null">
            <span>{{alertMessage.message | translate}}</span>
          </div> -->
        </div>
      </div>
      <div *ngIf="allowSendEmailToUser" class="row justify-content-end">
        <div class="col-auto">
          <lib-gung-checkbox-input-horizontal
            name="SEND_EMAIL_TO_USER"
            [ngModelOptions]="{standalone: true}"
            gungTranslate="SEND_EMAIL_TO_USER"
            [(ngModel)]="sendEmail"
            labelClass="col-auto"
          >
          </lib-gung-checkbox-input-horizontal>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row w-100">
        <div class="col d-flex justify-content-end">
          <button type="button" class="btn btn-light btn-sm" (click)="activeModal.close(false)" translate>CANCEL</button>
          <button type="submit" class="btn btn-primary btn-sm ml-2" translate>
            CREATE <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
}

