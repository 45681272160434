import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { InvoiceCardListComponent, CustomerService, OrderService, Invoice } from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { ListItemRendererComponent } from 'gung-list';
import { of, Observable, forkJoin, merge } from 'rxjs';
import { first, switchMap, mergeMap, map } from 'rxjs';
import { InvoiceTableComponent, InvoiceActionConfigService } from 'gung-standard';
import { filter } from 'minimatch';

@Component({
  selector: 'gung-jeeves-jeeves-invoice-table',
  templateUrl: './jeeves-invoice-table.component.html',
  styleUrls: ['./jeeves-invoice-table.component.css']
})
export class JeevesInvoiceTableComponent extends InvoiceTableComponent implements OnInit, OnChanges {
  mappedInvoices: {}[];

  constructor(
    public customerService: CustomerService,
    public orderService: OrderService,
    public dateUtilService: DateUtilService,
    public actionConfigService: InvoiceActionConfigService
  ) {
    super(dateUtilService, actionConfigService);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit() {
    this.customerService
      .getCustomers()
      .pipe(
        map(customers => {
          return this.data.map(invoice => {
            const customer = customers.filter(c => c.id === invoice.extra.fh.ftgnr)[0];
            return {
              ...invoice,
              customer
            };
          });
        })
      )
      .subscribe(i => {
        this.mappedInvoices = i;
      });
  }

  getStatus(test: string, val: string): string {
    return `${test}${val}`;
  }

  classSelector(key: number) {
    if (key === 5) {
      return {
        'invoice-status-overdue': true
      };
    }
    if (key === 12) {
      return {
        'invoice-status-partially': true
      };
    }
    if (key === 13) {
      return {
        'invoice-status-paid': true
      };
    }
    return {};
  }
}
