import { Component, forwardRef } from '@angular/core';
import { catchError, first } from 'rxjs';
import { BankgiroListConfigService } from '../../services/bankgiro-list-config.service';
import { BankgiroModalService } from '../../services/bankgiro-modal.services';
import { BankgiroSelectionService } from '../../services/bankgiro-selection.service';
import { BankgiroService } from '../../services/bankgiro.service';
import { AbstractSelectionService } from 'gung-list';

@Component({
  selector: 'lib-bankgiro-wrapper',
  templateUrl: './bankgiro-wrapper.component.html',
  styleUrls: ['./bankgiro-wrapper.component.scss'],
  providers: [
    {
      provide: AbstractSelectionService,
      useExisting: forwardRef(() => BankgiroSelectionService)
    }
  ]
})
export class BankgiroWrapperComponent {
  constructor(
    public configService: BankgiroListConfigService,
    protected modalService: BankgiroModalService,
    protected bankgiroService: BankgiroService
  ) {}

  openUploadModal() {
    this.modalService.openBankgiroDynamicModal().then(_ => {});
  }
}
