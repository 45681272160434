  
  <div class="row pt-3 align-items-end">
    <div class="col-6 order-1" [ngClass]="{ 'col-lg-8': !enableDiscount, 'col-lg-4': enableDiscount }">
      <button *ngIf="!isFirstStep"
        type="button"
        class="btn btn-outline-primary btn-sm checkout-btn-previous"
        (click)="prevClicked.emit()"
        translate
      >
        {{ prevBtnText }}
      </button>
    </div>
    <div class="col-6 col-lg-4 order-3">
      <div class="row align-items-end">
        <div class="col-12 col-md-6">
          
        </div>
  
        <div class="col-12 col-md-6">
          <button class="btn btn-primary w-100 btn-sm mt-1 checkout-btn-next" type="button" (click)="nextClicked.emit()">
            <span translate>{{ isLastStep ? 'SUBMIT_ORDERS' : nextBtnText }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  