import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter, ConfigService, ConfigBaseFilter, ListSortOption, ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { Observable, map } from 'rxjs';
import { ReturnWarrantyTableComponent } from '../../components/return-warranty/return-warranty-table/return-warranty-table.component';
import { OrderServicesService, OrderServicesTicket } from '../order-services/order-services.service';

export class ReturnWarrantyFilter extends SimpleConfigBaseFilter<OrderServicesTicket> {
  constructor(private filterName: string, private filterKey: string) {
    super();
  }

  getOptionIds(item: OrderServicesTicket): string[] {
    return [item[this.filterKey]];
  }
  getOptionName(key: string): string {
    return key;
  }
  getName(): string {
    return this.filterName;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReturnWarrantyListConfigService implements ConfigService<OrderServicesTicket> {
  constructor(protected orderServicesService: OrderServicesService) {}

  customerId: string;

  setCustomerId(customerId) {
    this.customerId = customerId;
  }

  getItems(): Observable<OrderServicesTicket[]> {
    return this.orderServicesService.getAll().pipe(
      map(result => {
        if (this.customerId) {
          return result.filter(r => r.customerId === this.customerId);
        }
        return result;
      }),
      map(result =>
        result.sort((a, b) => {
          if (new Date(a.date) > new Date(b.date)) {
            return -1;
          }
          if (new Date(a.date) < new Date(b.date)) {
            return 1;
          }
          return 0;
        })
      )
    );
  }

  getFilters(): ConfigBaseFilter<OrderServicesTicket>[] {
    return [
      new ReturnWarrantyFilter('STATUS', 'status'),
      new ReturnWarrantyFilter('CUSTOMER_NAME', 'customerName'),
      new ReturnWarrantyFilter('CREATED_BY', 'customerResponsible'),
      new ReturnWarrantyFilter('ASSIGN_TO', 'assignTo')
    ];
  }

  getSortOptions(): ListSortOption<OrderServicesTicket>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<OrderServicesTicket>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ReturnWarrantyTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order list'
      }
    ];
  }

  getSearchTerms(item: OrderServicesTicket): string[] {
    return [item.id];
  }

  getItemId(item: OrderServicesTicket): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
