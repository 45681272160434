import { Component } from '@angular/core';
import { SupplierPoAllListConfigService } from '../../../services/supplier-po-all-list-config/supplier-po-all-list-config.service';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { SupplierPoListComponent } from '../supplier-po-list/supplier-po-list.component';

@Component({
  selector: 'lib-supplier-po-all-list',
  template: `
  <div class="row">
    <div class="col-12 text-center">
      <h3 *ngIf="purchaseOrderType">{{metadataService.getMetadataValue('POSTATUS', 'name', purchaseOrderType) | titlecase}}</h3>
    </div>
  </div>
  <lib-filter-list #list [configService]="listConfigService"></lib-filter-list>
`,
styles: []
})
export class SupplierPoAllListComponent extends SupplierPoListComponent {

  constructor(
    public listConfigService: SupplierPoAllListConfigService,
    public route: ActivatedRoute,
    public metadataService: MetadataService
  ) {
    super(
      listConfigService,
      route,
      metadataService
    );
  }

  ngOnInit(): void {
  }
}
