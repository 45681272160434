import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sjoborg-product-download-pdf',
  templateUrl: './sjoborg-product-download-pdf.component.html',
  styleUrls: ['./sjoborg-product-download-pdf.component.scss']
})
export class SjoborgProductDownloadPdfComponent implements OnInit {
  @Input()
  productId: string;

  constructor() {}

  ngOnInit() {}
}
