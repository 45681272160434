import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardKpiService } from '../../../services/dashboard-kpi.service';

@Component({
  selector: 'lib-active-products-card',
  templateUrl: './active-products-card.component.html',
  styleUrls: ['./active-products-card.component.scss']
})
export class ActiveProductsCardComponent implements OnInit, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();

  amountOfUniqueProducts = 0;
  amountOfUniqueOrderedProducts = 0;
  loaded = false;

  constructor(protected dashboardKpiService: DashboardKpiService) {}

  ngOnInit() {
    this.dashboardKpiService
      .getActiveProductsThisYearComparison()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.amountOfUniqueProducts = res.entityCollectionSize;
        this.amountOfUniqueOrderedProducts = res.entityAmount;
        this.loaded = true;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
