import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addDays, format } from 'date-fns';
import { CalendarDate, CartRow, CheckoutObject, DeliveryDateService, GungFlowService } from 'gung-standard';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JeevesDeliveryDateService extends DeliveryDateService {
  public getDeliveryDatesForCheckout(checkout: CheckoutObject): Observable<CalendarDate[]> {
    let calenderDates: Observable<CalendarDate[]>;
    let levsattkod = checkout.extra.procargs?.levsattkod;
    if (levsattkod == null) {
      levsattkod = checkout.extra.oh?.levsattkod;
    }
    const levsattkod2 = checkout.extra.oh?.levsattkod;
    if (levsattkod != null) {
      console.log('RESOLVING VALID DATES BY LEVSATTKOD', levsattkod);
      calenderDates = this.getDeliveryDates(levsattkod);
    } else {
      console.log('RESOLVING VALID DATES BY DEFAULT');
      return super.getDeliveryDatesForCheckout(checkout);
    }
    return calenderDates;
  }

  constructor(http: HttpClient, protected gungFlowService: GungFlowService) {
    super(http, gungFlowService);
  }

  public getDeliveryDates(deliveryMethod: string, rows?: CartRow[]): Observable<CalendarDate[]> {
    const url = `json/delivery-dates?deliveryMethodCode=${deliveryMethod || ''}`;
    return this.http.get<CalendarDate[]>(url).pipe(
      map(dates => {
        // https://gung-se.freshdesk.com/a/tickets/106152
        // Customers in brazil got broken calendars
        const offset = new Date().getTimezoneOffset();
        return dates.map(date => {
          let localDate = new Date(date.date);
          if (offset > 0) { // https://stackoverflow.com/questions/33890898/javascript-gettimezoneoffset-vs-moment-utcoffset
            localDate = addDays(localDate, 1);
          }
          const localDateFormatted = format(localDate, 'yyyy-MM-dd');

          return {
            ...date,
            date: new Date(localDateFormatted)
          };
        });
      }),
      switchMap(calendarDates => super.filterDeliveryDatesBySelectedFlow(calendarDates))
    );
  }
}
