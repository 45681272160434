<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">Welcome to our Supplier Portal</h2>

        <p class="card-text">
          This portal is divided into four areas as seen in the menu above. Your input on this portal is important to
          make the cooperation between us and your company better, tighter, more efficient and in the end more
          profitable.
        </p>
      </div>
    </div>
  </div>
  <div class="col-6" *ngFor="let status of postatus">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title" translate>{{ status.name }}</h5>
        <p class="card-text">
          {{ (status.name | uppercase) + '_DESCRIPTION' | translate }}
          <span *ngIf="hasNewData(status.id)"><svg height="20" width="20">
          <circle cx="10" cy="10" r="10" fill="green" />
        </svg>
          </span>
        </p>
        <a [routerLink]="['/suppliers-purchase-order', status.id]" class="btn btn-primary"
          >Go To - {{ status.name | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
