import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

export interface CustomerDeliveryLocation {
  id: string;
  description: string;
  name: string;
  street: string;
  address: string;
  phone: string;
  email: string;
  city: string;
  cityCode: string;
  country: string;
  customerId: string;
}


@Injectable({
  providedIn: 'root'
})
export class CustomerDeliveryAddressService {

  private locationsSubjects: { [customerId: string]: BehaviorSubject<CustomerDeliveryLocation[]> } = {};

  constructor(protected http: HttpClient) {}

  getDeliveryLocations(customerId: string): Observable<CustomerDeliveryLocation[]> {
    const url = `json/delivery-address/${customerId}`;
    return this.http.get<CustomerDeliveryLocation[]>(url);
  }

  addCustomerDeliveryLocation(
    customerId: string,
    address: CustomerDeliveryLocation
  ): Observable<CustomerDeliveryLocation[]> {
    const url = `json/delivery-address/update`;
    return this.http.post<CustomerDeliveryLocation[]>(url, address).pipe(
      tap(
        // Log the result or error
        data => this.updateCustomerLocationSubject(customerId),
        error => console.log('ERROR', error)
      )
    );
  }

  updateCustomerLocationSubject(customerId: string): void {
    if (!this.locationsSubjects[customerId]) {
      this.locationsSubjects[customerId] = new BehaviorSubject<CustomerDeliveryLocation[]>([]);
    }
    this.internalGetCustomerLocationFromUrl(customerId).subscribe((cc: CustomerDeliveryLocation[]) =>
      this.locationsSubjects[customerId].next(cc)
    );
  }

  protected internalGetCustomerLocationFromUrl(customerId: string): Observable<CustomerDeliveryLocation[]> {
    const url = `/json/delivery-address/${customerId}`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<CustomerDeliveryLocation[]>(url, { headers });
  }

  getCustomerDeliveryLocationsFromSubject(customerId: string): Observable<CustomerDeliveryLocation[]> {
    return this.getCustomerDeliveryLocations(customerId);
  }

  getCustomerDeliveryLocations(customerId: string): Observable<CustomerDeliveryLocation[]> {
    if (!this.locationsSubjects[customerId]) {
      this.locationsSubjects[customerId] = new BehaviorSubject<CustomerDeliveryLocation[]>([]);
      this.updateCustomerLocationSubject(customerId);
    }
    return this.locationsSubjects[customerId].asObservable();
  }

  deleteDeliveryLocations(customerId, locationId): Observable<CustomerDeliveryLocation> {
    const url = `/json/delivery-address/delete/${locationId}`;
    return this.http.delete<CustomerDeliveryLocation>(url).pipe(
      tap(
        // Log the result or error
        data => this.updateCustomerLocationSubject(customerId),
        error => console.log('ERROR', error)
      )
    );
  }
}
