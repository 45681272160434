import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CompactType, DisplayGrid, GridsterComponent, GridsterConfig, GridsterItem, GridType } from 'angular-gridster2';
import { DynamicPdfService } from '../../services/dynamic-pdf.service';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs';
import { PdfExportEditModalComponent } from '../pdf-export-edit-modal/pdf-export-edit-modal.component';
import { ExportProductTemplateModalComponent } from '../export-product-template-modal/export-product-template-modal.component';
import { ExportDragProductService } from '../../services/export-drag-product.service';
import { PdfFlowService } from '../../services/pdf-flow.service';
import { PdfSelectedCustomerService } from '../../services/pdf-selected-customer.service';
import { PdfProductService } from '../../services/pdf-product.service';
import { PdfDocument } from '../../models/pdf-document';
import { CommonModalService } from 'gung-common';
import { PdfExportEditNoteModalComponent } from '../pdf-export-edit-note-modal/pdf-export-edit-note-modal.component';

interface GungGridItem extends GridsterItem {
  productId: string;
}

@Component({
  selector: 'lib-product-export-drag-drop',
  templateUrl: './product-export-drag-drop.component.html',
  styleUrls: ['./product-export-drag-drop.component.scss']
})
export class ProductExportDragDropComponent implements OnInit, OnChanges {
  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();

  options: GridsterConfig[] = [];
  productList = {};
  pageOrientation = 'LANDSCAPE';
  showPages = false;
  zoom = 100;
  loadGrid = true;
  isEdit = false;
  name: string;
  firstLoad = true;
  flow;
  customer;

  @Input() refresh: unknown;

  @Input() pdfDocument: PdfDocument;

  templates;
  productDragExport = {
    pages: [{ items: [] }]
  };

  @ViewChild('gridster', {
    static: true
  })
  gridster!: GridsterComponent;

  intOptions = {
    gridType: GridType.ScrollVertical,
    displayGrid: DisplayGrid.Always,
    compactType: CompactType.None,
    setGridSize: true,
    pushItems: true,
    margin: 3,
    draggable: {
      enabled: true
    },
    resizable: {
      enabled: true
    },
    minCols: 24,
    maxCols: 24,
    minRows: 24,
    maxRows: 24,
    maxItemCols: 24,
    minItemCols: 1,
    maxItemRows: 24,
    minItemRows: 1,
    maxItemArea: 2500,
    minItemArea: 1,
    defaultItemCols: 3,
    defaultItemRows: 3,
    mobileBreakpoint: 10,
    enableEmptyCellClick: false,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: true,
    enableEmptyCellDrag: true,
    enableOccupiedCellDrop: false,
    emptyCellClickCallback: this.dropProduct.bind(this),
    emptyCellContextMenuCallback: this.dropProduct.bind(this),
    emptyCellDropCallback: this.dropProduct.bind(this),
    emptyCellDragCallback: this.dropProduct.bind(this)
  };

  constructor(
    public exportDragProductService: ExportDragProductService,
    protected dynamicPdfService: DynamicPdfService,
    protected gungModalService: CommonModalService,
    protected ngbModal: NgbModal,
    protected translationService: TranslateService,
    protected gungFlowService: PdfFlowService,
    protected selectedCustomerService: PdfSelectedCustomerService,
    protected productService: PdfProductService
  ) {}

  ngOnInit() {
    this.exportDragProductService.productsGrid.next(null);
    this.changeOrientation(this.exportDragProductService.defaultOrientation);
    const productsIds = [];
    forkJoin({
      flow: this.gungFlowService.getSelectedFlow().pipe(first()),
      customer: this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      templates: this.dynamicPdfService.getProductTemplates().pipe(first())
    }).subscribe(({ flow, customer, templates }) => {
      this.flow = flow;
      this.customer = customer;
      this.templates = templates;
      this.exportDragProductService.selectedTemplate = this.templates[0];
      this.exportDragProductService.flow = this.flow;
      this.exportDragProductService.customer = this.customer;
    });
    if (this.pdfDocument) {
      this.isEdit = true;
      this.name = this.pdfDocument.name;
      this.exportDragProductService.fillPages(this.pdfDocument);
      if (!this.pdfDocument.isHorizontal) {
        this.changeOrientation('PORTRAIT');
      }
    } else {
      this.exportDragProductService.clearPdfExport();
    }
    this.exportDragProductService.getGrids().subscribe(grids => {
      for (const page in grids) {
        if (Object.prototype.hasOwnProperty.call(grids, page)) {
          if (!this.productDragExport.pages[page]) {
            this.productDragExport.pages[page] = {
              items: []
            };
          }
          if (!this.options[page]) {
            this.options[page] = this.intOptions;
          }
          if (this.productDragExport.pages[page].items.length !== grids[page].length) {
            this.exportDragProductService.selectedPage = Number(page);
            for (const item of grids[page]) {
              if (item.fragment === 'image-cover-wrapper') {
                if (!this.productDragExport.pages[page].items.map(p => p.data?.url).includes(item.data.url)) {
                  this.addItem(item, item.x, item.y, item.cols, item.rows);
                }
              } else if (item.fragment === 'text-display') {
                if (!this.productDragExport.pages[page].items.map(p => p.data.id).includes(item.data.id)) {
                  this.addItem(item, item.x, item.y, item.cols, item.rows);
                }
              } else {
                if (
                  !this.productDragExport.pages[page].items.map(p => p.data.productId).includes(item.data.productId)
                ) {
                  if (!productsIds.includes(item.data.productId)) {
                    productsIds.push(item.data.productId);
                  }
                  this.addItem(item, item.x, item.y, item.cols, item.rows);
                }
              }
            }
          }
        }
      }
      if (this.firstLoad) {
        this.exportDragProductService.selectedPage = 0;
        this.firstLoad = false;
      }
      const getByIds = productsIds.filter(id => !this.productList[id]);
      this.productService.getFullProductsByIds(getByIds).subscribe(pds => {
        for (const prod of pds) {
          this.productList[prod.id] = prod;
        }
      });
    });
    this.loadGrid = false;
  }

  ngOnChanges() {
    this.changedOptions();
  }

  dropProduct(event: DragEvent, item: GungGridItem) {
    if (this.exportDragProductService.draggedProduct) {
      this.exportDragProductService.setOneProduct(
        this.exportDragProductService.draggedProduct,
        this.exportDragProductService.selectedPage,
        item.x,
        item.y
      );
      this.exportDragProductService.draggedProduct = null;
    }
    if (this.exportDragProductService.draggedDigitalAsset) {
      this.exportDragProductService.setOneDigitalAsset(
        this.exportDragProductService.draggedDigitalAsset,
        this.exportDragProductService.selectedPage,
        item.x,
        item.y
      );
      this.exportDragProductService.draggedDigitalAsset = null;
    }
  }

  changedOptions() {
    this.loadGrid = true;
    setTimeout(() => {
      this.refreshAllGrids(false);
    }, 200);
    setTimeout(() => {
      this.refreshAllGrids(true);
    }, 500);
    setTimeout(() => {
      this.loadGrid = false;
    }, 600);
  }

  refreshAllGrids(size) {
    for (let index = 0; index < this.productDragExport.pages.length; index++) {
      if (this.options && this.options[index] && this.options[index].api) {
        this.options[index].setGridSize = size;
        this.options[index].api.optionsChanged();
      }
    }
  }

  removeItem(item, page) {
    this.productDragExport.pages[page].items.splice(this.productDragExport.pages[page].items.indexOf(item), 1);
    this.exportDragProductService.removeItem(item, page);
  }

  editItem(item, page) {
    if (item.fragment === 'text-display') {
      const id = PdfExportEditNoteModalComponent.name;
      const ref = this.ngbModal.open(PdfExportEditNoteModalComponent, {
        size: 'md'
      });
      ref.componentInstance.delegate = this;
      ref.componentInstance.note = item;
      ref.componentInstance.page = page;
      ref.result.then(_ => {});
      this.modalRef.set(id, ref);
    } else {
      const id = ExportProductTemplateModalComponent.name;
      const ref = this.ngbModal.open(ExportProductTemplateModalComponent, {
        size: 'lg'
      });
      ref.componentInstance.delegate = this;
      ref.componentInstance.product = item;
      ref.componentInstance.page = page;
      ref.componentInstance.flow = this.flow;
      ref.componentInstance.language = item.data?.language;
      ref.componentInstance.selectedCustomer = this.customer;
      ref.componentInstance.templates = this.templates;
      ref.result.then(result => {
        const index = this.productDragExport.pages[page].items.findIndex(
          i => i.data.modelId + i.data.productId === item.data.modelId + item.data.productId
        );
        if (index > -1) {
          this.productDragExport.pages[page].items[index].fragment = result.template.fragment;
          this.productDragExport.pages[page].items[index].fragmentFile = result.template.fragmentFile;
          this.productDragExport.pages[page].items[index].data = result.template.data;
          this.exportDragProductService.changeTemplate(item);
        }
      });
      this.modalRef.set(id, ref);
    }
  }

  addItem(item, x = 0, y = 0, cols = 3, rows = 5) {
    if (
      this.options[this.exportDragProductService.selectedPage].api &&
      this.options[this.exportDragProductService.selectedPage].api.getNextPossiblePosition({ x, y, cols, rows }) ===
        false
    ) {
      this.removeNotFitItem(item);

      return;
    }
    if (this.pageOrientation === 'LANDSCAPE') {
      this.productDragExport.pages[this.exportDragProductService.selectedPage].items.push({
        x,
        y,
        cols,
        rows,
        productId: item.productId,
        data: item.data,
        fragment: item.fragment,
        fragmentFile: item.fragmentFile
      });
    } else {
      this.productDragExport.pages[this.exportDragProductService.selectedPage].items.push({
        x,
        y,
        cols,
        rows,
        productId: item.productId,
        fragment: item.fragment,
        fragmentFile: item.fragmentFile,
        data: item.data
      });
    }
  }

  removeNotFitItem(item) {
    this.exportDragProductService.removeItem(item, this.exportDragProductService.selectedPage);
  }

  addNewPage(position) {
    if (!position) {
      this.productDragExport.pages.push({ items: [] });
    } else {
      this.productDragExport.pages.splice(position, 0, { items: [] });
      this.exportDragProductService.addNewPage(position);
    }
    this.options.push(this.intOptions);
  }

  removePage(pageIndex) {
    this.productDragExport.pages.splice(pageIndex, 1);
    this.options.splice(pageIndex, 1);
    this.exportDragProductService.removePage(pageIndex);
  }

  duplicatePage(pageIndex) {
    const copy = JSON.parse(JSON.stringify(this.productDragExport.pages[pageIndex]));
    this.productDragExport.pages.push(copy);
    this.options.push(this.intOptions);
  }

  goToPage(pageIndex) {
    const el = document.getElementById('page-' + pageIndex);
    this.exportDragProductService.selectedPage = pageIndex;
    this.scroll(el as HTMLElement);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  export() {
    const payload = {
      pages: this.mapPagesToBackendStructure(),
      isHorizontal: this.pageOrientation === 'LANDSCAPE',
      recipient: ''
    } as PdfDocument;
    this.gungModalService.openEmailConfirmExportModal('').then(res => {
      payload.recipient = res.email;
      this.dynamicPdfService.exportPdf(payload).subscribe();
    });
  }

  mapPagesToBackendStructure() {
    const exportPages = this.productDragExport.pages.map(page => {
      page.items = page.items.map(pageItem => {
        if (!pageItem.fragment) {
          pageItem.fragment = 'product-information';
          pageItem.data = {
            productId: pageItem.productId
          };
        }
        if (!pageItem.fragmentFile) {
          pageItem.fragmentFile = 'dynamic-product-fragments';
        }
        return pageItem;
      });
      return page;
    });
    return exportPages;
  }

  changeOrientation(orientation) {
    if (this.options.length < 1) {
      this.options.push(this.intOptions);
    }
    if (orientation === 'LANDSCAPE') {
      this.pageOrientation = 'LANDSCAPE';
      for (let index = 0; index < this.productDragExport.pages.length; index++) {
        this.options[index].minCols = 24;
        this.options[index].maxCols = 24;
        this.options[index].minRows = 24;
        this.options[index].maxRows = 24;
        this.options[index].maxItemCols = 24;
        this.options[index].maxItemRows = 24;
        this.options[index].rowHeightRatio = 0.71;
      }
    } else {
      this.pageOrientation = 'PORTRAIT';
      for (let index = 0; index < this.productDragExport.pages.length; index++) {
        this.options[index].minCols = 24;
        this.options[index].maxCols = 24;
        this.options[index].minRows = 24;
        this.options[index].maxRows = 24;
        this.options[index].maxItemCols = 24;
        this.options[index].maxItemRows = 24;
        this.options[index].rowHeightRatio = 1.4142;
      }
    }
    this.changedOptions();
  }

  getYPosition(e: Event): number {
    return (e.target as Element).scrollTop;
  }

  onScroll(event) {
    if (event.scrollTop === 0) {
      this.exportDragProductService.selectedPage = 0;
    } else {
      // scroll end
      if (event.offsetHeight + event.scrollTop >= event.scrollHeight) {
        this.exportDragProductService.selectedPage = this.productDragExport.pages.length - 1;
      } else {
        let tempSetect;
        const inMiddle = event.scrollTop + event.offsetHeight / 2;
        for (let index = 0; index < this.productDragExport.pages.length; index++) {
          const page = document.getElementById('page-' + index) as HTMLElement;
          if (inMiddle > page.offsetTop) {
            tempSetect = index;
          }
        }
        this.exportDragProductService.selectedPage = tempSetect;
      }
    }
  }

  showPagesLeft() {
    this.showPages = !this.showPages;
    this.changedOptions();
  }

  zoomOut() {
    if (this.zoom > 20) {
      this.zoom = this.zoom - 10;
    }
  }
  zoomIn() {
    if (this.zoom < 200) {
      this.zoom = this.zoom + 10;
    }
  }

  getGridsterPos(event) {
    console.log(event);
  }

  changePagePosition(currentPosition, newPosition) {
    const copyCurrent = JSON.parse(JSON.stringify(this.productDragExport.pages[currentPosition]));
    const copyNew = JSON.parse(JSON.stringify(this.productDragExport.pages[newPosition]));
    this.productDragExport.pages[currentPosition] = copyNew;
    this.productDragExport.pages[newPosition] = copyCurrent;
    this.exportDragProductService.changePagePosition(currentPosition, newPosition);
    this.options.push(this.intOptions);
  }

  save() {
    const payload = {
      pages: this.mapPagesToBackendStructure(),
      isHorizontal: this.pageOrientation === 'LANDSCAPE',
      recipient: '',
      name: this.name
    } as PdfDocument;
    const modal = this.ngbModal.open(PdfExportEditModalComponent);
    modal.componentInstance.pdfDocument = payload;
    modal.componentInstance.isCreate = true;
    if (this.isEdit) {
      payload.id = this.pdfDocument.id;
      payload.users = this.pdfDocument.users;
      modal.componentInstance.isCreate = false;
    }
    modal.result.then(reason => {
      console.log(`Dismissed ${reason}`);
    });
  }

  editName() {
    this.gungModalService.openInputSaveModal('Name', 'Change Name', this.name).then(data => {
      if (data) {
        this.name = data.value;
      }
    });
  }

  openVideoModal(product, url) {
    const title = product.productId ? product.productId : product.data.productId;
    const videoUrl = url || undefined;
    let html = 'No video found';
    if (videoUrl) {
      html =
        '<video playsinline="" autoplay="" loop="" width="100%"><source src="' +
        videoUrl +
        '" type="video/mp4"><source src="' +
        videoUrl +
        '" type="video/webm"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Not Supported.</font></font></video>';
    }
    this.gungModalService.openBasicModal(title, html);
  }

  /**
   * change all product templates (not used right now)
   */
  applyTemplate(template) {
    this.loadGrid = true;
    for (const pages of this.productDragExport.pages) {
      for (const item of pages.items) {
        const tempData = JSON.parse(JSON.stringify(item.data));
        item.fragment = template.template.fragment;
        item.fragmentFile = template.template.fragmentFile;
        item.data = { ...item.data, ...template.template.data };
        if (template.template.data.productId) {
          item.data.productId = tempData.productId || tempData.variantIds[0];
        }
        if (template.template.data.modelId) {
          item.data.modelId = tempData.modelId;
        }
        if (template.template.data.variantIds) {
          item.data.variantIds = tempData.variantIds;
        }
        if (template.template.data.flowId) {
          item.data.flowId = this.flow.id;
        }
        if (template.template.data.customerId) {
          item.data.customerId = this.customer.id;
        }
        this.exportDragProductService.changeTemplate(item);
      }
    }
    this.loadGrid = false;
  }

  setDefaultTemplate(template) {
    this.exportDragProductService.selectedTemplate = template;
  }
}
