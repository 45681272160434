import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-cpq-buy-btn',
  templateUrl: './cpq-buy-btn.component.html',
  styleUrls: ['./cpq-buy-btn.component.css']
})
export class CpqBuyBtnComponent implements OnInit {
  @Input()
  id: string;

  constructor(protected router: Router) {}

  ngOnInit() {}

  openConfigurator() {
    this.router.navigateByUrl(`cpq/configurator/${this.id}`);
  }
}
