<div class="table-responsive">
  <table class="table">
    <thead>
      <tr>
        <th translate>ID</th>
        <th translate>NAME</th>
        <th *ngIf="hasArticleClass" translate>ARTICLE_CLASS</th>
        <th translate>AVAILABILITY</th>
        <th translate>PRICE</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of mappedData">
        <td>
          <a routerLink="/product/{{ item.id }}" class="text-dark">
            {{ item.id }}
          </a>
        </td>
        <td>
          <a routerLink="/product/{{ item.id }}" class="text-dark">
            {{ item.name }}
          </a>
        </td>
        <td *ngIf="hasArticleClass">
          <a routerLink="/product/{{ item.id }}" class="text-dark">
            {{ item.product?.extra?.articleClass }}
          </a>
        </td>
        <td>
          <!-- if supplier stock is a neg #, indicates supplier stock is not null, otherwise show reg availability -->
          <div *ngIf="item.product?.extra?._supplierStock >= 0" style="color: green">
            {{ 'IN_STOCK' | translate }} ({{ item.product.extra._supplierStock }})
          </div>
          <div *ngIf="item.product.extra._supplierStock === -1">
            <lib-availability
              *ngIf="item.availability"
              [availability]="item.availability"
              [isSales]="isSales"
            ></lib-availability>
          </div>
        </td>
        <td>
          <span *ngIf="item.price">
            <span *ngIf="item.price">
              <lib-price [price]="item.price" type="GROSS"></lib-price>
              <span *ngIf="item.price.customerDiscountPercent !== 0">(-{{ item.price.customerDiscountPercent }}%)</span>
            </span>
          </span>
        </td>
        <td>
          <sjoborg-buy-btn
            *ngIf="item.replacementId == null"
            [id]="item.id"
            [minimumOrderQuantity]="getPackageSize(item.product)"
            [stepAmount]="getPackageSize(item.product)"
          ></sjoborg-buy-btn>
        </td>
        <td>
          <sjoborg-favourite-product [id]="item.id"></sjoborg-favourite-product>
        </td>
      </tr>
    </tbody>
  </table>
</div>
