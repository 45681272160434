import { Component, OnInit, Optional, Inject } from '@angular/core';
import { NgbDate, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DateUtilService } from 'gung-common';
import { addMonths, differenceInMonths, format, lastDayOfMonth, subDays } from 'date-fns';
import { first } from 'rxjs';
import { SelectSavedCartModalComponent } from '../select-saved-cart-modal/select-saved-cart-modal.component';
import { CartCalendarRow } from '../../../models/bulk-orders';
import { CartsService } from '../../../services/carts/carts.service';
import { CheckoutStepComponent } from '../../checkout/steps/base/checkout-step.component';
import { Cart } from '../../../models/cart';
import { NavigationConfig } from '../../../models/navigation-config';

@Component({
  selector: 'lib-bulk-plan',
  templateUrl: './bulk-plan.component.html',
  styleUrls: ['./bulk-plan.component.scss']
})
export class BulkPlanComponent extends CheckoutStepComponent implements OnInit {
  minDate: NgbDate;
  minDateValue: string;
  minDateValueDate: Date;
  minDateToMaxDate: NgbDate;
  currentDate = new Date();
  calendarRows: CartCalendarRow[][];
  maxDateValueDate: Date = new Date();
  maxDateValue: string;
  savedCarts: Cart[];

  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();

  gungCalendarFirstWeekday: string = this.environment.gungCalendarFirstWeekday || 'Monday';
  public gungCalendarWeekdayOrder: number[] = this.getOrderOfWeekdaysAsNumbers(this.gungCalendarFirstWeekday);

  public weekdayTranslationMap: {[s:string]: {full: string, abr: string}} = {
    0: { full: 'SUNDAY', abr: 'SUNDAY_ABREVIATION' },
    1: { full: 'MONDAY', abr: 'MONDAY_ABREVIATION' },
    2: { full: 'TUESDAY', abr: 'TUESDAY_ABREVIATION' },
    3: { full: 'WEDNESDAY', abr: 'WEDNESDAY_ABREVIATION' },
    4: { full: 'THURSDAY', abr: 'THURSDAY_ABREVIATION' },
    5: { full: 'FRIDAY', abr: 'FRIDAY_ABREVIATION' },
    6: { full: 'SATURDAY', abr: 'SATURDAY_ABREVIATION' }
  }

  constructor(
    protected ngbModal: NgbModal,
    protected savedCartService: CartsService,
    protected dateUtilService: DateUtilService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig
  ) {
    super();
  }

  ngOnInit() {
    const today = new Date();
    this.minDate = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
    this.minDateValueDate = this.currentDate;
    this.minDateValue = format(today, 'yyyy-MM-dd');
    this.minDateToMaxDate = this.minDate;
    this.maxDateValueDate = addMonths(this.currentDate, 1);
    this.maxDateValue = format(this.maxDateValueDate, 'yyyy-MM-dd');
    this.calendarRows = [];
    if (this.checkout.extra._calendar) {
      this.calendarRows = this.checkout.extra._calendar;
    } else {
      this.createCalendar();
    }

    this.savedCartService
      .getSavedCartsFromSubject()
      .pipe(first())
      .subscribe(savedCarts => {
        this.savedCarts = savedCarts.filter(s => s.extra?.tag !== 'QUOTE');
      });
    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked());
  }

  updateInitDate(event) {
    this.minDateToMaxDate = event;
    this.minDateValueDate = new Date(event.year, event.month - 1, event.day);
    this.createCalendar();
  }

  updateEndDate(event) {
    this.maxDateValueDate = new Date(event.year, event.month - 1, event.day);
    this.createCalendar();
  }

  public isVisible() {
    return true;
  }

  public getLabel(): string {
    return 'PLAN';
  }

  createCalendar() {
    this.calendarRows = [];
    let firstDay = this.minDateValueDate;
    let lastDay = this.maxDateValueDate;
    
    let startIndex = this.dateUtilService.getGungWeekDay(firstDay, this.gungCalendarFirstWeekday);
    let totalMonths = differenceInMonths(lastDayOfMonth(lastDay), new Date(firstDay)) + 1;
    let daysInMonth = lastDay.getDate();

    let currentDay = firstDay.getDate(),
      currentRow = 0;
    this.calendarRows.push([]);
    for (let i = 0; i <= startIndex; i++) {
      this.calendarRows[currentRow][i] = null;
    }
    let currentMonth = firstDay.getMonth() + 1;
    let correntYear = firstDay.getFullYear();

    if (totalMonths > 1) {
      for (let m = 0; m < totalMonths - 1; m++) {
        currentMonth = addMonths(firstDay, m).getMonth() + 1;
        if (addMonths(firstDay, m).getFullYear() > correntYear) {
          correntYear++;
        }

        let monthDays = this.getDays(correntYear, currentMonth);

        if (m === 0) {
          for (let i = 0; i < startIndex; i++) {
            this.calendarRows[currentRow][i] = { show: false, date: subDays(new Date(correntYear, currentMonth - 1, currentDay), startIndex - i) }
          }

          // 1st line 1st month
          for (let i = startIndex; i < 7; i++) {
            if (currentDay <= monthDays) {
              this.calendarRows[currentRow][i] = {
                day: currentDay,
                month: currentMonth,
                getMonthName: this.getMonthName(currentMonth),
                year: correntYear,
                carts: [],
                disabled: this.isSaturday(new Date(correntYear, currentMonth - 1, currentDay)),
                show: true,
                date: new Date(correntYear, currentMonth - 1, currentDay)
              };
              currentDay++;
            } else {
              break;
            }
          }
        }

        while (currentDay <= monthDays) {
          for (let i = 0; i < 7; i++) {
            if (currentDay <= monthDays) {
              if (!this.calendarRows[currentRow][i] && this.calendarRows[currentRow][i] !== null) {
                this.calendarRows[currentRow][i] = {
                  day: currentDay,
                  month: currentMonth,
                  getMonthName: this.getMonthName(currentMonth),
                  year: correntYear,
                  carts: [],
                  disabled: this.isSaturday(new Date(correntYear, currentMonth - 1, currentDay)),
                  show: true,
                  date: new Date(correntYear, currentMonth - 1, currentDay)
                };
                currentDay++;
              }
            } else {
              break;
            }
          }
          // if not month end in the middle of week
          if (this.calendarRows[currentRow].length === 7) {
            currentRow++;
            this.calendarRows.push([]);
          }
        }
        currentDay = 1;
      }
      currentMonth = currentMonth + 1;
      if (currentMonth > 12) {
        correntYear++;
        currentMonth = 1;
      }

      while (currentDay <= daysInMonth) {
        for (let i = this.calendarRows[currentRow].length; i < 7; i++) {
          if (currentDay <= daysInMonth) {
            this.calendarRows[currentRow][i] = {
              day: currentDay,
              month: currentMonth,
              getMonthName: this.getMonthName(currentMonth),
              year: correntYear,
              carts: [],
              disabled: this.isSaturday(new Date(correntYear, currentMonth - 1, currentDay)),
              show: true,
              date: new Date(correntYear, currentMonth - 1, currentDay)
            };
          } else {
            this.calendarRows[currentRow][i] = null;
          }
          currentDay++;
        }
        currentRow++;
        this.calendarRows.push([]);
      }
    } else {
      for (let i = startIndex; i < 7; i++) {
        this.calendarRows[currentRow][i] = {
          day: currentDay,
          month: currentMonth,
          getMonthName: this.getMonthName(currentMonth),
          year: correntYear,
          carts: [],
          disabled: this.isSaturday(new Date(correntYear, currentMonth - 1, currentDay)),
          show: true,
          date: new Date(correntYear, currentMonth - 1, currentDay)
        };
        currentDay++;
      }
      currentRow++;
      this.calendarRows.push([]);
      while (currentDay <= daysInMonth) {
        for (let i = 0; i < 7; i++) {
          if (currentDay <= daysInMonth) {
            this.calendarRows[currentRow][i] = {
              day: currentDay,
              month: currentMonth,
              getMonthName: this.getMonthName(currentMonth),
              year: correntYear,
              carts: [],
              disabled: this.isSaturday(new Date(correntYear, currentMonth - 1, currentDay)),
              show: true,
              date: new Date(correntYear, currentMonth - 1, currentDay)
            };
          } else {
            this.calendarRows[currentRow][i] = null;
          }
          currentDay++;
        }
        currentRow++;
        this.calendarRows.push([]);
      }
    }

    if (this.calendarRows[this.calendarRows.length - 1].length === 0) {
      this.calendarRows.pop();
    }
  }

  getDays(year, month) {
    return new Date(year, month, 0).getDate();
  }

  getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', {
      month: 'long'
    });
  }

  addSavedCart(cell) {
    const id = SelectSavedCartModalComponent.name;

    const ref = this.ngbModal.open(SelectSavedCartModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: true
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.cell = cell;
    ref.componentInstance.savedCarts = this.savedCarts;

    this.modalRef.set(id, ref);
  }

  removeSavedCart(cell, cart) {
    const index = cell.carts.indexOf(cart);
    cell.carts.splice(index, 1);
  }

  handleNextButtonClicked() {
    this.checkout.extra._calendar = this.calendarRows;
    this.onlySelected();
    this.stepDone.emit(this.checkout);
  }

  onlySelected() {
    this.checkout.extra._bulk = [];
    for (const rows of this.calendarRows) {
      for (const days of rows) {
        if (days && days.carts && days.carts.length > 0) {
          this.checkout.extra._bulk.push(days);
        }
      }
    }
  }

  copyToNextLine(line) {
    for (let index = 0; index < this.calendarRows[line + 1].length; index++) {
      if (
        this.calendarRows[line + 1] &&
        this.calendarRows[line + 1][index] &&
        this.calendarRows[line + 1][index].carts &&
        this.calendarRows[line] &&
        this.calendarRows[line][index] &&
        this.calendarRows[line][index].carts
      ) {
        const carts = JSON.parse(JSON.stringify(this.calendarRows[line][index].carts));
        this.calendarRows[line + 1][index].carts = carts;
      }
    }
  }

  copyToLine(origin: number, destination: number) {
    for (let index = 0; index < this.calendarRows[origin].length; index++) {
      if (
        this.calendarRows[destination] &&
        this.calendarRows[destination][index] &&
        this.calendarRows[destination][index].carts &&
        this.calendarRows[origin] &&
        this.calendarRows[origin][index] &&
        this.calendarRows[origin][index].carts
      ) {
        const carts = JSON.parse(JSON.stringify(this.calendarRows[origin][index].carts));
        this.calendarRows[destination][index].carts = carts;
      }
    }
  }

  copyToAllNextLines(line) {
    for (let i = line; i < this.calendarRows.length - 1; i++) {
      this.copyToNextLine(i);
    }
  }

  copyToEveryOtherLine(line: number) {
    for (let i = line; i < this.calendarRows.length; i+=2) {
      if (i !== line) {
        this.copyToLine(line, i);
      }
    }
  }

  isSaturday(date: Date): boolean {
    let result: boolean = false;
    const weekDay: number = this.dateUtilService.getGungWeekDay(date, 'Monday');
    
    if (weekDay === 5) {
      result = true;
    }

    return result;
  }

  public getOrderOfWeekdaysAsNumbers(firstWeekDay: string = 'Monday'): number[] {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const firstWeekDayIndex = weekdays.indexOf(firstWeekDay);
    let weekdayNumbers: number[] = Array(7).fill(0).map((_, index) => (index));
    const splitPart = weekdayNumbers.slice(0, firstWeekDayIndex);
    const restPart = weekdayNumbers.slice(firstWeekDayIndex);
    return [...restPart, ...splitPart];
  }
}
