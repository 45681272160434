<!-- <ng-template #uploadImage let-fileImagePrev let-imgTag="imgTag"> -->
<div
  *ngIf="enableDragDrop"
  class="panel drop-box ng-pristine ng-untouched ng-valid ng-empty dragover position-relative"
  style="background: #ffffff; border: 5px dashed #ddd; text-align: center; padding: 50px 10px"
  (dragover)="disabled ? undefined : onDragOver($event)"
  (dragleave)="disabled ? undefined : onDragLeave($event)"
  (drop)="disabled ? undefined : onDrop($event)"
  (click)="fileInput.click()"
>
      <div class="row">
        <div class="col-12">
          <mat-icon> cloud_upload </mat-icon>
        </div>

        <div class="col-12">
          <h3>
            <span>{{ 'DROP_FILE(S)_TO_UPLOAD' | translate }} </span>
            <br />
            <small>{{ 'OR' | translate }}</small>
          </h3>
          <label class="btn browse-btn" for="filedrop" translate>{{ 'BROWSE_FILE_SYSTEM' | translate }}</label>
        </div>
      </div>

  <span
    ><small class="" *ngFor="let file of value; let last = last"
      >{{ file?.name }}{{ !last ? ', ' : '' }}</small
    ></span
  >
  @if(uploading) {
    <div  class="position-absolute w-100 text-center" style="top: 0px;">
      <i class="fa fa-spinner fa-spin fa-7x"></i>
    </div>
  }
</div>
<input
  hidden
  #fileInput
  type="file"
  [class]="extraInputClass || '_custom-file-input'"
  [ngClass]="renderStatic ? 'form-control-plaintext' : undefined"
  [readonly]="renderStatic"
  [id]="inputId || 'file-upload'"
  [name]="inputId || 'file-upload'"
  [attr.aria-describedby]="inputId || 'file-upload'"
  [required]="required"
  [disabled]="disabled"
  [placeholder]="placeholder | translate"
  [accept]="accept"
  [multiple]="multiple"
  (change)="onDrop($event)"
/>

<button *ngIf="!enableDragDrop" type="button" class="btn btn-light" (click)="fileInput.click()">{{ uploadBtn | translate }}</button>
<br />
<span *ngIf="!enableDragDrop">
  <small class="" *ngFor="let file of fileInput.files || fileInput.files; let last = last">
    {{ file?.name }}{{ !last ? ', ' : '' }}
  </small>
</span>
@if(uploading) {
  <lib-progress-bar [uploadFileName]="value?.length > 0 && value[0]?.name" unsubscribeProgress="true" />
}
<!-- [(ngModel)]="value" -->
<div *ngIf="enablePreview && objectFileImagePreview" class="text-center">
  <span class="d-block">{{ 'PREVIEW' | translate }}</span>
  <img
    *ngIf="objectFileImagePreview.s3ETag; else imageTemp"
    class="img-fluid"
    [src]="objectFileImagePreview.s3Uri | gungImageUrl
      :'type:thumbnail'
      :'etag:'+objectFileImagePreview.s3ETag
    "
    alt="{{ objectFileImagePreview.description }}"
  />
  <ng-template #imageTemp>
    <i *ngIf="objectFileImagePreview && !objectFileImagePreview.s3Uri" class="fa fa-spinner fa-spin fa-7x"></i>
    <img
      *ngIf="objectFileImagePreview.s3Uri"
      class="img-fluid"
      [src]="objectFileImagePreview.s3Uri"
      alt="{{ objectFileImagePreview.name }}"
    />
  </ng-template>
</div>
