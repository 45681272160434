<div *ngIf="!!data">
    <div class="row" *ngIf="supplierDynamicColumnsService.shouldDisplayConfirmCheckedButton()">
      <div class="col-12">
        <button type="button" class="btn btn-secondary btn-sm btn-block" (click)="confirmAllCheckedLines($event)">
          <span translate>CONFIRM_ALL_CHECKED</span>
          <i class="fas fa-check ml-1"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 table-responsive gung-responsive" style="overflow-x: unset;">
        <table class="table table-striped">
            <thead>
                <tr>
                <!-- <th scope="col" class="">
                    <span translate><!-- CHECKBOX -- ></span>
                </th> -->
                <th scope="col" class="">
                    <span translate>PO_NUMBER</span>/<span translate>ROW</span>
                </th>
                <th scope="col" class="">
                    <span translate>PRODUCT</span>
                </th>
                <th scope="col" class="">
                    <span translate>QUANTITY</span>
                </th>
                <th scope="col" class="">
                    <span translate>PRICE</span>/<span translate>TOTAL</span>
                </th>
                <th scope="col" class="" *ngIf="currentStatus===20">
                    <span translate>QUANTITY_CONFIRM</span>
                </th>
                <th scope="col" class="">
                    <span translate>REQUEST_DATE</span>
                </th>
                <th scope="col" class="" *ngIf="currentStatus===20">
                    <span translate>CONFIRM_READY_DATE</span>
                </th>
                <th scope="col" class="">
                    <span translate><!-- ACTIONS --></span>
                </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of data; index as i; trackBy: trackByFn" [ngClass]="{ 'line-checked': !!row.checked }">
                    <ng-container [ngSwitch]="currentStatus">
                        <ng-template *ngSwitchCase="20" 
                            [ngTemplateOutlet]="supplierRows20"
                            [ngTemplateOutletContext]="{ $implicit: row }">
                        </ng-template>
                        <ng-template *ngSwitchDefault
                            [ngTemplateOutlet]="supplierRows"
                            [ngTemplateOutletContext]="{ $implicit: row }">
                        </ng-template>
                    </ng-container>
                </tr>
            </tbody>
        </table>
      </div>
    </div>
</div>

<ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>

<ng-template #supplierRows let-row let-column="column">
    <!-- <td>
        <lib-gung-checkbox-input-horizontal suppressLabel="true" [(ngModel)]="row.checked"></lib-gung-checkbox-input-horizontal>
    </td> -->
    <td>
        {{getValues(row, 'extra.bh.bestnr')}}-{{getValues(row, 'extra.bp.bestradnr')}}
    </td>
    <td>
        <b>{{getValues(row, 'extra.bp.artnr')}}</b> {{ getValues(row, 'extra.bp.ordartbeskr') }}
        <br><b>{{getValues(row, 'extra.bp.artnrlev')}}</b> {{ getValues(row, 'extra.bp.levsartbeskr') }}
    </td>
    <td>
        {{ getValues(row, 'extra.bp.bestant') | number: '1.0-2' }}
    </td>
    <td>
        <lib-price [price]="getValues(row, 'extra.bp.vb_inpris')" [currency]="row.extra.bh.valkod"></lib-price>
        <br><lib-price [price]="getValues(row, 'extra.bp.vbbestvalue')" [currency]="row.extra.bh.valkod"></lib-price>
    </td>
    <td>
        {{ getValues(row, 'extra.bp.bestbeglevdat') | date: dateUtilService.dateFormat }}
    </td>
    <td>
        <!-- <button *ngIf="column.path.includes('btnHistory') && detailsColumn" type="button" class="btn btn-info mr-2" (click)="detailsPurchaseLine(row, $event)" [disabled]="detailsColumn.length === 0">
            <span translate>DETAILS</span>
            <i class="fa-solid fa-circle-info ml-1"></i>
        </button> -->
        <lib-action-btn [item]="row" [config]="supplierPoActionConfigService"></lib-action-btn>
    </td>
</ng-template>

<ng-template #supplierRows20 let-row let-column="column">
    <!-- <td>
        <lib-gung-checkbox-input-horizontal suppressLabel="true" [(ngModel)]="row.checked"></lib-gung-checkbox-input-horizontal>
    </td> -->
    <td>
        {{getValues(row, 'extra.bh.bestnr')}}-{{getValues(row, 'extra.bp.bestradnr')}}
    </td>
    <td>
        <b>{{getValues(row, 'extra.bp.artnr')}}</b> {{ getValues(row, 'extra.bp.ordartbeskr') }}
        <br><b>{{getValues(row, 'extra.bp.artnrlev')}}</b> {{ getValues(row, 'extra.bp.levsartbeskr') }}
    </td>
    <td>
        {{ getValues(row, 'extra.bp.bestant') | number: '1.0-2' }}
    </td>
    <td>
        <lib-price [price]="getValues(row, 'extra.bp.vb_inpris')" [currency]="row.extra.bh.valkod"></lib-price>
        <br><lib-price [price]="getValues(row, 'extra.bp.vbbestvalue')" [currency]="row.extra.bh.valkod"></lib-price>
    </td>
    <td width="100px">
        <lib-gung-number-input
            suppressLabel="true"
            [ngModel]="getValues(row, 'extra.bp_bestant')"
            (ngModelChange)="setValue(row, 'extra.bp_bestant', (+$event))"
            [extraInputClass]="row.extra.errors?.includes('extra.bp_bestant') ? 'form-control-sm border border-danger' : 'form-control-sm'"
            [maxValue]="getValues(row, 'extra.bp.bestant')"
            [minValue]="0"
        ></lib-gung-number-input>
    </td>
    <td>
        {{ getValues(row, 'extra.bp.bestbeglevdat') | date: dateUtilService.dateFormat }}
    </td>
    <td width="150px">
        <lib-gung-date-picker
            [minInitialDate]="today"
            [currentselectedDate]="getValues(row, 'extra.bp_bestlovlevdat')"
            (selectedDate)="setValue(row, 'extra.bp_bestlovlevdat', dateUtilService.getFormattedDateString($event))"
            suppressLabel="true"
            [extraInputClass]="row.extra.errors?.includes('extra.bp_bestlovlevdat') ? 'form-control-sm border border-danger' : 'form-control-sm'"
            extraBtnClass="btn-sm">
        </lib-gung-date-picker>
    </td>
    <td>
        <!-- <button *ngIf="column.path.includes('btnHistory') && detailsColumn" type="button" class="btn btn-info mr-2" (click)="detailsPurchaseLine(row, $event)" [disabled]="detailsColumn.length === 0">
            <span translate>DETAILS</span>
            <i class="fa-solid fa-circle-info ml-1"></i>
        </button> -->
        <lib-action-btn [item]="row" [config]="supplierPoActionConfigService"></lib-action-btn>
    </td>
</ng-template>