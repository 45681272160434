import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { ReplenishmentCustomer } from '../models/replenishment';
import { Observable } from 'rxjs';
import { ReplenishmentCustomerService } from './replenishment-customer.service';
import { ReplenishmentCustomerViewComponent } from '../components/replenishment-customer-view/replenishment-customer-view.component';

@Injectable({
  providedIn: 'root'
})
export class ReplenishmentCustomerListConfigService implements ConfigService<ReplenishmentCustomer> {
  topFilter = false;

  constructor(protected replenishmentCustomerService: ReplenishmentCustomerService) {}

  getBatchSizes(): number[] {
    return [24];
  }

  getFilters(): ConfigBaseFilter<ReplenishmentCustomer>[] {
    return [];
  }

  getItemId(item: ReplenishmentCustomer): string {
    return item.customerId;
  }

  getItems(): Observable<ReplenishmentCustomer[]> {
    return this.replenishmentCustomerService.getAllReplenishmentCustomers();
  }

  getLayouts(): ListLayout<ReplenishmentCustomer>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ReplenishmentCustomerViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order list'
      }
    ];
  }

  getSearchTerms(item: ReplenishmentCustomer): string[] {
    return [item.customerId];
  }

  getSortOptions(): ListSortOption<ReplenishmentCustomer>[] {
    return [];
  }
}
