import { Injectable } from '@angular/core';
import { ListLayoutMultipleComponent, ListLayout } from 'gung-list';
import {
  ProductListByIdsConfigService,
  ProductService,
  AuthService,
  ProductSortService,
  Product,
  GungFlowService,
  MetadataService
} from 'gung-standard';
import { ProductPaginationListTableComponent } from '../components/product-pagination-list-table/product-pagination-list-table.component';
import { map, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SjoborgProductListByIdsConfigService extends ProductListByIdsConfigService {
  tabType: string;

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ProductPaginationListTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductTable'
      }
    ];
  }

  getItems() {
    return this.productIdsSubject
    .asObservable()
    .pipe(
      mergeMap(productIds => this.productService.getProductsByIds(productIds)),
      map(products => {
        return products.map(product => {
          if(this.tabType === 'spareParts') {
              const articleClass = this.getMetadataValues(product.extra?.ar?.artkod, 'xm', 'arttypbeskr' );
              product.extra = {...product.extra, articleClass};
            }
            return product;
          });
        })
      );
  }

  getMetadataValues(value, table, field) {
    const metaKeys = value;
    let metaValue = '';
    
      metaValue = this.metadataService.getMetadataValue(table, field, metaKeys);
    
    return metaValue;
  }
}
