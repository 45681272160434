<div *ngIf="product != null; else loading">
  <!-- <div class="row pt-2">
    <div class="col-12">
      <lib-back-button class="ml-5"></lib-back-button>
    </div>
  </div> -->

  <div class="row pt-1 product-detail-data-section">
    <!-- Product img -->
    <div class="col-xs-12 col-sm-6 container"></div>

    <!-- Product data -->
    <div class="col-xs-12 col-sm-6">
      <div class="row">
        <div class="col-12">
          <h5 class="mb-0">
            <!-- <span>{{ 'ITEM_NO' | translate }}</span>:  -->
            {{ product.id }}
          </h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h1>{{ product.name }}</h1>
        </div>
      </div>

      <div class="row" *ngIf="getProductDescription() | async as productDescription">
        <div class="col-12">
          <p><span translate>DOMAINS</span>: {{ productDescription }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <i class="fas fa-box"></i>&nbsp;
          <!-- <lib-availability [availability]="availability" [isSales]="isSales"></lib-availability> -->
          <!-- if supplier stock is a neg #, indicates supplier stock is not null, otherwise show reg availability -->
          <div *ngIf="product?.extra?._supplierStock >= 0" style="color: green" class="d-inline">
            {{ 'IN_STOCK' | translate }} ({{ product.extra._supplierStock }})
          </div>
          <div *ngIf="product.extra._supplierStock === -1" class="d-inline">
            <lib-availability [availability]="availability" [isSales]="isSales"></lib-availability>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <span><span translate>ARTICLE_CLASS</span>: {{ getMetaArArtkod(product) }} </span>
        </div>
        <div class="col-12">
          <p *ngIf="product.extra.ar?.artnrean"><span translate>EAN</span>: {{ product.extra.ar?.artnrean }}</p>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <h4>
            <!-- <span>{{ 'NET_PRICE' | translate }}</span>: -->
            <strong>{{
              price.customerNetPrice.value | gungCurrency : price.customerNetPrice.currencyCode : 'code'
            }}</strong>
          </h4>
        </div>
      </div>

      <div class="row" *ngIf="!isOnlyDisplayFlow">
        <div class="col-12 col-md-6">
          <sjoborg-buy-btn
            [id]="product.id"
            [minimumOrderQuantity]="getPackageSize(product)"
            [stepAmount]="getPackageSize(product)"
          ></sjoborg-buy-btn>
        </div>
      </div>

      <div class="row" *ngIf="product?.documents && product?.documents.length > 0">
        <div class="col-12 mt-2">
          <div class="table-responsive">
            <table class="table table-borderless table-striped table-sm">
              <thead>
                <tr>
                  <th scope="col" translate>DOCUMENT</th>
                  <th scope="col" translate>DOWNLOAD_DOCUMENT</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let doc of product?.documents">
                  <td translate>
                    {{ doc.name || 'TECHINCAL_CHARACTERISTICS_CATALOG' }}
                  </td>
                  <td>
                    <a href="{{ doc.url }}" target="_blank" translate>DOWNLOAD</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <p>{{ product.description }}</p>
        </div>
      </div>

      <!-- Product Jeeves Details -->
      <div class="pt-2">
        <lib-gung-accordion>
          <lib-gung-accordion-item>
            <ng-template libGungAccordionHeader>{{ 'PRODUCT_DETAILS' | translate }}</ng-template>
            <ng-template libGungAccordionBody>
              <div class="table-responsive">
                <table class="table table-borderless table-striped table-sm">
                  <tbody>
                    <tr *ngIf="product.extra.ar.enhetskod">
                      <th translate>UNIT</th>
                      <td>
                        {{ product.extra.ar.enhetskod }}
                      </td>
                    </tr>
                    <tr *ngIf="product.extra.ar.artfsgforp">
                      <th translate>PACKAGE_SIZE</th>
                      <td>
                        {{ product.extra.ar.artfsgforp }}
                      </td>
                    </tr>
                    <tr *ngIf="product.extra.ar.artvikt">
                      <th translate>NET_WEIGHT</th>
                      <td>
                        {{ product.extra.ar.artvikt }}
                      </td>
                    </tr>
                    <tr *ngIf="product.extra.ar.artbtotvikt">
                      <th translate>GROSS_WEIGHT</th>
                      <td>
                        {{ product.extra.ar.artbtotvikt }}
                      </td>
                    </tr>
                    <tr *ngIf="product.extra.ar.artstatnr">
                      <th translate>TARIC_CODE</th>
                      <td>
                        {{ product.extra.ar.artstatnr }}
                      </td>
                    </tr>
                    <tr *ngIf="product.extra.ar.artnrean">
                      <th translate>EAN</th>
                      <td>
                        {{ product.extra.ar.artnrean }}
                      </td>
                    </tr>
                    <tr *ngIf="product.extra.ar.artvolym">
                      <th translate>VOLUME</th>
                      <td>
                        {{ product.extra.ar.artvolym }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </lib-gung-accordion-item>
          <lib-gung-accordion-item *ngIf="product.extra.pdm && product.extra.pdm.length > 0">
            <ng-template libGungAccordionHeader>{{ 'DOCUMENTS' | translate }}</ng-template>
            <ng-template libGungAccordionBody>
              <div class="table-responsive">
                <table class="table table-hover table-borderless table-striped table-sm">
                  <thead>
                    <tr>
                      <th translate>NAME</th>
                      <th class="text-right" translate>DOWNLOAD_DOCUMENT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let document of product.extra.pdm">
                      <td>
                        {{ document.docdescr }}
                      </td>
                      <td class="text-right">
                        <a
                          target="_NEW"
                          [href]="
                            'https://cdn2.gung.io/sjoborgs/' +
                            document.pdmdummyuniqueid +
                            document.documentext
                          "
                        >
                          {{ document.pdmdummyuniqueid }}{{ document.documentext }}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </lib-gung-accordion-item>
          </lib-gung-accordion>
      </div>
    </div>
  </div>

  <div class="row mt-3 product-detail-data-section">
    <div class="col-12">
      <lib-gung-tabset *ngIf="addonIds.length > 0 || pimRelatedProductsIds.length > 0 || pimSparePartsProductsIds.length > 0">
        <lib-gung-tab *ngIf="addonIds.length > 0" [title]="'ADDONS' | translate">
          <ng-template libGungTabContent>
            <lib-product-list-by-ids [productIds]="addonIds"></lib-product-list-by-ids>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab *ngIf="pimRelatedProductsIds.length > 0" [title]="'PRODUCT_RELATED_PRODUCTS' | translate">
          <ng-template libGungTabContent>
            <lib-product-list-by-ids [productIds]="pimRelatedProductsIds"></lib-product-list-by-ids>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab *ngIf="pimSparePartsProductsIds.length > 0" [title]="'SPAREPARTS' | translate">
          <ng-template libGungTabContent>
            <sjoborg-product-list-by-ids-spare-parts [tabType]="'spareParts'" [productIds]="pimSparePartsProductsIds"></sjoborg-product-list-by-ids-spare-parts>
          </ng-template>
        </lib-gung-tab>
      </lib-gung-tabset>
    </div>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
