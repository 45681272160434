<div class="dashboard-card card" *ngIf="loaded">
    <div class="card-body dashboard-card-content">
      <div class="title" translate>OFFER_PER_WEEK</div>
  
      <div class="d-flex justify-content-between">
        <div class="align-self-center">
          <span translate>THIS_WEEK</span>
        </div>
        <span class="current-statistic text-primary">{{ numberOffersThisWeek }}</span>
      </div>
  
      <div class="d-flex justify-content-between">
        <div class="align-self-center">
          <span translate>LAST_WEEK</span>
        </div>
        <span class="current-statistic text-primary">{{ numberOffersLastWeek }}</span>
      </div>
  
    </div>
  </div>
  
  <div class="dashboard-card card" *ngIf="!loaded">
    <div class="card-body dashboard-card-content">
      <div class="text-center dashboard-loading-spinner">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
  