<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>ADD_CONTACT</h4>
  <button type="button" class="close" aria-label="Close" (click)="crossClick()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" *ngIf="contactForm">
  <div class="form" [formGroup]="contactForm">
    <div class="form-row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="name" class="control-label" translate>NAME</label>
          <input class="form-control" type="text" name="name" id="name" [formControlName]="'name'" ngbAutofocus />
        </div>
      </div>

      <div [formGroupName]="'CS8'" class="col-12 col-md-6">
        <div class="form-group">
          <label for="email" class="control-label" translate>CS8_COMNR</label>
          <input class="form-control" type="text" name="email" id="emailId" formControlName="comnr" />
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-12 col-md-6">
        <div [formGroupName]="'CS1'" class="">
          <div class="form-group">
            <label for="phone" class="control-label" translate>CS1_COMNR</label>
            <input class="form-control" type="text" name="phone" id="phoneId" formControlName="comnr" />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div [formGroupName]="'CS4'" class="">
          <div class="form-group">
            <label for="mobile" class="control-label" translate>CS4_COMNR</label>
            <input class="form-control" type="text" name="mobile" id="mobileId" formControlName="comnr" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary ml-auto" (click)="submitForm()" [disabled]="isAddingContact" translate>
    ADD_CONTACT
  </button>
</div>
