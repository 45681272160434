import { Component, OnInit } from '@angular/core';
import { Product } from '../../models';
import { ProductInlineSearchCardComponent } from '../product-inline-search-card/product-inline-search-card.component';

interface ProductExtended extends Product {
  _path: string[];
}

@Component({
  selector: 'lib-product-concept-inline-search-card',
  templateUrl: './product-concept-inline-search-card.component.html',
  styleUrls: ['./product-concept-inline-search-card.component.css']
})
export class ProductConceptInlineSearchCardComponent extends ProductInlineSearchCardComponent {
  data: ProductExtended;

}
