import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { OrderPortalEntry } from '../../models/order-portal';
import { OrderPortalChannelService } from '../../services/order-portal-channel.service';
import { OrderPortalActionConfigService } from '../../services/order-portal-action-config.service';

@Component({
  selector: 'lib-order-portal-list-view',
  templateUrl: './order-portal-list-view.component.html',
  styleUrls: ['./order-portal-list-view.component.scss']
})
export class OrderPortalListViewComponent extends ListItemRendererComponent<OrderPortalEntry[]> implements OnInit, AfterViewInit {
  loaded = false;

  constructor(
    protected orderPortalChannelService: OrderPortalChannelService,
    protected actionConfigService: OrderPortalActionConfigService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.data) {
      return;
    }
    this.loaded = true;
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  getCreatedEntityTranslationTag(entry: OrderPortalEntry): string {
    const entityName = this.orderPortalChannelService.getCreatedEntityName(entry);

    if (entry.createdOrderIds.length > 1) {
      return `CREATED_${entityName}_IDS`;
    } else {
      return `CREATED_${entityName}_ID`;
    }
  }

  getRouterLink(entry: OrderPortalEntry, id: string): string {
    return this.orderPortalChannelService.getCreatedEntityRoute(entry, id).link;
  }
  getRouterLinkParams(entry: OrderPortalEntry, id: string): {} {
    return this.orderPortalChannelService.getCreatedEntityRoute(entry, id).params;
  }

  trackByFn(index: number, item: any) {
    return index;
  }
}
