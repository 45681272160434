import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { Subject } from 'rxjs';
import { DashboardKpiService } from '../../../services/dashboard-kpi.service';

@Component({
  selector: 'lib-customers-who-purchased-card',
  templateUrl: './customers-who-purchased-card.component.html',
  styleUrls: ['./customers-who-purchased-card.component.scss']
})
export class CustomersWhoPurchasedCardComponent implements OnInit, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();

  @Input()
  preConditions: any;

  constructor(protected dashboardKpiService: DashboardKpiService) {}

  amountOfCustomers: number;
  amountOfCustomersWhoOrdered: number;
  loaded = false;

  ngOnInit() {
    this.dashboardKpiService
      .getActiveCustomersThisYearComparison(this.preConditions)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.amountOfCustomers = res.entityCollectionSize;
        this.amountOfCustomersWhoOrdered = res.entityAmount;
        this.loaded = true;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
