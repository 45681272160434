import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject, debounceTime, first, tap } from 'rxjs';
import { ProductionManagementTransferInventoryService, StockTransferRequest } from '../../services/production-management-transfer-inventory.service';
import { AvailabilityService } from '../../../../services/availability/availability.service';
import { MetadataService } from '../../../../services/metadata/metadata.service';
import { Product } from '../../../../models';
import { GungModalService } from '../../../../services/gung-modal/gung-modal.service';
import { GungNotificationService, OptionsList, gungAddRemoveSpinner } from 'gung-common';
import { StockLocation } from '../production-management-stock-counting/production-management-stock-counting.component';

@Component({
  selector: 'lib-production-management-transfer-inventory',
  templateUrl: './production-management-transfer-inventory.component.html',
  styleUrls: ['./production-management-transfer-inventory.component.css']
})
export class ProductionManagementTransferInventoryComponent implements OnInit, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();
  form: FormGroup;
  stockLocations: StockLocation[];
  stockLocationList: {[s: string]:  OptionsList[]} = {'from': [], 'to': []};
  product: Product;
  productStock: {
    from?: { inventory: number; available: number };
    to?: { inventory: number; available: number };
  } = {};
  disabledTransfer = true;
  productSearchDisabled = false;

  formatterFunction = (result: Product) => result.id + ' - ' + result.name;
  formatterInputFunction = (result: Product) => result.id;
  getSearchTerms = (product: Product) => [
    product.id,
    product.name,
    product.description,
    product.extra.ArticleNumber,
    product.extra.Description,
    product.extra.EAN
  ];

  constructor(
    protected transferInventoryService: ProductionManagementTransferInventoryService,
    protected availabilityService: AvailabilityService,
    protected gungModalService: GungModalService,
    protected metadataService: MetadataService,
    protected gungNotificationService: GungNotificationService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected initForm(): void {
    this.form = new FormGroup({
      productId: new FormControl({ value: '', disabled: false }),
      fromStockPointId: new FormControl({ value: null, disabled: true }),
      toStockPointId: new FormControl({ value: null, disabled: true }),
      fromLocationId: new FormControl({ value: null, disabled: true }),
      toLocationId: new FormControl({ value: null, disabled: true }),
      note: new FormControl({ value: '', disabled: true }),
      quantity: new FormControl({ value: null, disabled: true })
    });

    this.form.controls['productId'].valueChanges.pipe(debounceTime(500)).subscribe(value => {
      if (!value || this.form.controls['productId'].disabled) {
        return;
      }
      this.form.controls['fromStockPointId'].enable();
      this.form.controls['fromStockPointId'].updateValueAndValidity();
      this.form.controls['toStockPointId'].enable();
      this.form.controls['toStockPointId'].updateValueAndValidity();
    });

    this.form.controls['fromStockPointId'].valueChanges.pipe(debounceTime(500)).subscribe(value => {
      if (!value || this.form.controls['fromStockPointId'].disabled) {
        return;
      }
      this.getAvailability(this.product.id, value, 'from').subscribe(res => {
        this.form.controls['fromLocationId'].enable();
        this.form.controls['fromLocationId'].updateValueAndValidity();
        if (this.form.controls['toStockPointId'].value
            && this.form.controls['fromLocationId'].value
            && this.form.controls['toLocationId'].value) {
          this.form.controls['quantity'].enable();
          this.form.controls['quantity'].updateValueAndValidity();
        }
      })
      
    });

    this.form.controls['toStockPointId'].valueChanges.pipe(debounceTime(500)).subscribe(value => {
      if (!value || this.form.controls['toStockPointId'].disabled) {
        return;
      }
      this.getAvailability(this.product.id, value, 'to').subscribe(res => {
        this.form.controls['toLocationId'].enable();
        this.form.controls['toLocationId'].updateValueAndValidity();
        if (this.form.controls['fromStockPointId'].value
            && this.form.controls['fromLocationId'].value
            && this.form.controls['toLocationId'].value) {
          this.form.controls['quantity'].enable();
          this.form.controls['quantity'].updateValueAndValidity();
        }
      });
      
    });

    this.form.controls['fromLocationId'].valueChanges.pipe(debounceTime(500)).subscribe(value => {
      if (!value || this.form.controls['fromLocationId'].disabled) {
        return;
      }

      this.setCurrentProductStock('from');
      if (this.form.controls['toLocationId'].value) {
        this.form.controls['quantity'].enable();
        this.form.controls['quantity'].updateValueAndValidity();
      }
    });

    this.form.controls['toLocationId'].valueChanges.pipe(debounceTime(500)).subscribe(value => {
      if (!value || this.form.controls['toLocationId'].disabled) {
        return;
      }

      this.setCurrentProductStock('to');

      if (this.form.controls['fromLocationId'].value) {
        this.form.controls['quantity'].enable();
        this.form.controls['quantity'].updateValueAndValidity();
      }
    });

    this.form.controls['quantity'].valueChanges.pipe(debounceTime(500)).subscribe(value => {
      if (this.form.controls['quantity'].disabled) {
        return;
      }
      this.disabledTransfer = !value;
    });
  }

  selectProduct(product: Product) {
    this.product = product;
    this.form.get('productId').setValue(product.id);
  }

  transfer({target}) {
    gungAddRemoveSpinner(target);
    const formRaw = this.form.getRawValue();
    if (formRaw.quantity > this.productStock.from.inventory) {
      this.gungModalService.openConfirmYesNoModal(
        'ERROR',
        'The quantity entered is greater than the available stock',
        undefined,
        'OK',
        null
      );
      gungAddRemoveSpinner(target);
      return;
    }

    const transfer: StockTransferRequest = {
      fromStockLocationId: '',
      fromStockPointId: this.resolveStockPointId(formRaw.fromStockPointId),
      note: 'Gung Transfer',
      productId: this.product.id,
      quantity: formRaw.quantity,
      toStockLocationId: '',
      toStockPointId: this.resolveStockPointId(formRaw.toStockPointId)
    };
    this.transferInventoryService.stockTransfers(transfer).subscribe(res => {
      this.getAvailability(this.product.id, formRaw.toStockPointId, 'to').subscribe(res => {
        this.setCurrentProductStock('to');
      });
      this.getAvailability(this.product.id, formRaw.fromStockPointId, 'from').subscribe(res => {
        this.setCurrentProductStock('from');
      });
      this.productSearchDisabled = true;
      this.form.controls['fromStockPointId'].disable();
      this.form.controls['fromStockPointId'].updateValueAndValidity();
      this.form.controls['toStockPointId'].disable();
      this.form.controls['toStockPointId'].updateValueAndValidity();
      this.form.controls['fromLocationId'].disable();
      this.form.controls['fromLocationId'].updateValueAndValidity();
      this.form.controls['toLocationId'].disable();
      this.form.controls['toLocationId'].updateValueAndValidity();
      this.form.controls['quantity'].disable();
      this.form.controls['quantity'].updateValueAndValidity();
      this.disabledTransfer = true;
      gungAddRemoveSpinner(target);
    });
  }

  newTransfer(productEle) {
    productEle.model = undefined;
    this.product = undefined;
    this.productStock = {};
    this.form.reset();
    this.form.patchValue({
      quantity: 0,
      fromStockPointId: null,
      toStockPointId: null,
      fromLocationId: null,
      toLocationId: null
    });
    this.form.updateValueAndValidity();
    this.productSearchDisabled = false;
  }

  _getAvailability(productId: string, stockId: string, location: string) {
    this.availabilityService
      .getAvailability(productId, stockId, true)
      .pipe(first())
      .subscribe(availability => {
        this.productStock[location] = {
          inventory: availability.currentStock,
          available: availability.currentAvailability
        };
      });
  }

  getAvailability(productId: string, stockId: string, location: 'to' | 'from') {
    return this.availabilityService
      .getAvailability(productId, stockId, true)
      .pipe(
        first(),
        tap(availability => {
          // Fortnox specific
          if (availability.extra.stockLocations) {
            // Temporary solution - Flatten nested array to single array
            this.stockLocations = availability.extra.stockLocations?.flat();
            this.stockLocationList[location] = this.stockLocations.map(sl => {
              return {
                id: sl.stockLocationId || '', // If it is blank in Fortnox the key does not exist
                name: sl.stockLocationName || 'UNSPECIFIED'
              };
            });

          }
        })
      );
  }

  setCurrentProductStock(location: string) {
    const formRaw = this.form.getRawValue();

    const stockLocationId = formRaw[location + 'LocationId'];

    let stockLocation = this.stockLocations.find(sl => sl.stockLocationId === stockLocationId);
    if (!stockLocationId) {
      stockLocation = this.stockLocations.find(sl => !sl.stockLocationId);
    }

    if (stockLocation) {
      this.productStock[location] = {
        inventory: stockLocation.totalQuantity,
        available: stockLocation.availableQuantity
      };

    } else {
      this.gungNotificationService.notify('Stock Counting', 'Stock Location not found', 'error');
    }
  }

  add(transferAmount: string, currentStock: number): number {
    const transferAmountNumber = Number(transferAmount);
    if (isNaN(transferAmountNumber) && isNaN(currentStock)) {
      return 0;
    } else if (isNaN(transferAmountNumber)) {
      return currentStock;
    } else if (isNaN(currentStock)) {
      return transferAmountNumber;
    }
    return transferAmountNumber + currentStock;
  }

  resolveStockPointId(stockCode: string): string {
    return this.metadataService.getMetadataValue('StockPoints', 'id', stockCode);
  }
}

