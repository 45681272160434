import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CartRowFeatureOptionSelection,
  FeatureOptionType,
  ProductConfiguration,
  ProductConfigurationFeature,
  ProductConfigurationOption,
  RuleActionType
} from '../../models/product-configuration-types';
import { first, forkJoin, of, Subject, switchMap, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { GungNotificationService } from 'gung-common';
import { ProductConfigurationService } from '../../services/product-configuration.service';
import { ProductConfigurationRuleExecutionService } from '../../services/product-configuration-rule-execution.service';
import { Product } from '../../../../models';
import { ProductService } from '../../../../services/products/product.service';
import { CartService } from '../../../../services/cart/cart.service';

@Component({
  selector: 'lib-cpq-configurator',
  templateUrl: './cpq-configurator.component.html',
  styleUrls: ['./cpq-configurator.component.scss']
})
export class CpqConfiguratorComponent implements OnInit, OnDestroy {
  featureResults: Map<string, ProductConfigurationOption[]>;
  groupedFeatures: Map<string, ProductConfigurationFeature[]>;
  keyedOptions: Map<string, ProductConfigurationOption>;
  keyedFeatures: Map<string, ProductConfigurationFeature>;
  featureOptions: Map<string, ProductConfigurationOption[]>;

  disabledOptions = new Map<string, boolean>();
  hiddenOptions = new Map<string, boolean>();

  featureNgModels = {};

  @Input()
  productId: string;

  product: Product;
  configuration: ProductConfiguration;

  isLoading = true;

  private unsubscribe = new Subject<void>();
  private executeRuleSubject = new Subject<void>();

  constructor(
    protected route: ActivatedRoute,
    protected productService: ProductService,
    protected productConfigurationService: ProductConfigurationService,
    protected ruleExecutionService: ProductConfigurationRuleExecutionService,
    protected cartService: CartService,
    protected gungNotificationService: GungNotificationService
  ) {}

  ngOnInit(): void {
    this.productId = this.getProductId();
    if (!this.productId) {
      return;
    }

    // Initialize the rule execution subscription.
    // Each time we select an option, this subscription will fire and get
    // the updated results.
    this.initRuleExecutionSubscription();

    this.featureResults = new Map<string, any[]>();

    this.productService
      .getProduct(this.productId)
      .pipe(
        first(),
        switchMap(product =>
          forkJoin([of(product), this.productConfigurationService.getOne(product.id).pipe(first())])
        ),
        switchMap(([product, configuration]) => {
          const featureIds = [];
          const optionIds = [];
          for (const feature of configuration.features) {
            featureIds.push(feature.featureId);
            optionIds.push(...feature.optionIds);
          }

          return forkJoin([
            of(product),
            of(configuration),
            this.productConfigurationService.getFeatures(featureIds).pipe(first()),
            this.productConfigurationService.getOptions(optionIds).pipe(first())
          ]);
        })
      )
      .subscribe(([product, configuration, features, options]) => {
        this.product = product;
        this.configuration = configuration;
        this.groupedFeatures = this.groupFeatures(features);
        this.keyedOptions = this.keyOptions(options);
        this.keyedFeatures = this.keyFeatures(features);
        this.featureOptions = this.groupOptionsByFeature(options);
        this.initNgModels(features.map(f => f.id));
        this.executeRuleSubject.next();

        this.isLoading = false;
      });
  }

  private initRuleExecutionSubscription() {
    this.executeRuleSubject.pipe(takeUntil(this.unsubscribe)).subscribe(undefined => {
      // Whenever we get notified that a selection has been made through the subject, we should execute the rules on the
      // new selection in order to see if anything has been changed according to the ruleset.
      this.ruleExecutionService
        .executeRules(this.productId, this.buildSelection())
        .pipe(first())
        .subscribe(ruleResults => {
          // This is done in a separate loop to ensure that we can still operate on other options than the one currently
          // in iteration without having to depend on the loop order. For example, we might want to disable all other
          // options for a specific feature. If we do this in one loop, we might iterate over one of those other options
          // after we mark them as disabled, and would overwrite that with the default.
          for (const optionId of Object.keys(ruleResults)) {
            // Set defaults on all options. Will be overwritten in the next step if needed.
            this.hiddenOptions.set(optionId, false);
            this.disabledOptions.set(optionId, false);
          }

          for (const optionId of Object.keys(ruleResults)) {
            const value = ruleResults[optionId];

            const featureId = this.configuration.features.find(f => {
              return f.optionIds.indexOf(optionId) >= 0;
            }).featureId;

            if (value == RuleActionType.HIDE) {
              this.hiddenOptions.set(optionId, true);
              // Ensure that we remove the option from the current selection/ngmodel if it is selected previously.
              this.cleanupOptionFromSelections(featureId, optionId);
            } else if (value == RuleActionType.SHOW_AND_DISABLE) {
              this.disabledOptions.set(optionId, true);
              // Ensure that we remove the option from the current selection/ngmodel if it is selected previously.
              this.cleanupOptionFromSelections(featureId, optionId);
            } else if (value == RuleActionType.SHOW) {
              this.hiddenOptions.set(optionId, false);
            } else if (value == RuleActionType.SHOW_AND_SELECT) {
              this.featureNgModels[featureId] = optionId;

              // Disable the other options since they are not possible to select because of this auto select.
              this.configuration.features
                .find(f => f.featureId == featureId)
                .optionIds.forEach(id => this.disabledOptions.set(id, true));
              // Make sure to enable the current option again.
              this.disabledOptions.set(optionId, false);

              // IMPORTANT!!!
              // It is easy to cause an infinity loop here. We only want to run this when the option is not already
              // selected. Otherwise, it will select itself and rerun the rules every time.
              if (!this.isOptionSelected(featureId, optionId)) {
                this.featureOptionSelected(this.keyedFeatures.get(featureId), this.keyedOptions.get(optionId));
              }
            }
          }
          this.hiddenOptions = new Map<string, boolean>(this.hiddenOptions);
          this.disabledOptions = new Map<string, boolean>(this.disabledOptions);
          this.featureNgModels = { ...this.featureNgModels };
        });
    });
  }

  private cleanupOptionFromSelections(featureId: string, optionId: string): void {
    if (this.featureNgModels[featureId] == optionId) {
      delete this.featureNgModels[featureId];
    }
    this.featureOptionRemoved(this.keyedFeatures.get(featureId), this.keyedOptions.get(optionId));
  }

  private isOptionSelected(featureId: string, optionId: string): boolean {
    if (!this.featureResults.has(featureId)) {
      return false;
    }
    return this.featureResults.get(featureId).findIndex(o => o.id == optionId) >= 0;
  }

  getProductId(): string {
    if (this.productId) {
      return this.productId;
    }
    return this.route.snapshot.paramMap.get('id');
  }

  private groupFeatures(features: ProductConfigurationFeature[]): Map<string, any[]> {
    const result = new Map<string, ProductConfigurationFeature[]>();
    for (const feature of features) {
      let groupId = feature.properties?.featureGroup || 'GENERAL';
      groupId = '' + groupId;
      if (!result.has(groupId)) {
        result.set(groupId, []);
      }
      result.get(groupId).push(feature);
    }
    return result;
  }

  private keyOptions(options: ProductConfigurationOption[]): Map<string, ProductConfigurationOption> {
    const result = new Map<string, ProductConfigurationOption>();
    for (const option of options) {
      result.set(option.id, option);
    }
    return result;
  }

  private keyFeatures(features: ProductConfigurationFeature[]): Map<string, ProductConfigurationFeature> {
    const result = new Map<string, ProductConfigurationFeature>();
    features.forEach(f => result.set(f.id, f));
    return result;
  }

  private initNgModels(featureIds: string[]): void {
    for (const id of featureIds) {
      this.featureNgModels[id] = undefined;
    }
  }

  private groupOptionsByFeature(options: ProductConfigurationOption[]): Map<string, ProductConfigurationOption[]> {
    const result = new Map<string, any[]>();
    for (const option of options) {
      if (!result.has(option.featureId)) {
        result.set(option.featureId, []);
      }
      result.get(option.featureId).push(option);
    }
    return result;
  }

  getPriceForOption(feature: ProductConfigurationFeature, option: ProductConfigurationOption): number {
    // TODO Fix this mega hack. This was added in a quick hackish way in order to finish before a demo. Should be fetched
    // based on selected customer and propbably even from the backend.
    if (option.properties?.priceSEK) {
      return Number(option.properties?.priceSEK) || 0;
    } else if (option.properties?.priceUSD) {
      return Number(option.properties?.priceUSD) || 0;
    } else {
      return 0;
    }
  }

  getCurrencyForOption(feature: ProductConfigurationFeature, option: ProductConfigurationOption): string {
    // TODO Fix this mega hack. This was added in a quick hackish way in order to finish before a demo. Should be fetched
    // based on selected customer and propbably even from the backend.
    if (option.properties.priceSEK) {
      return 'SEK';
    } else if (option.properties.priceUSD) {
      return 'USD';
    } else {
      return '';
    }
  }

  featureOptionSelected(feature: ProductConfigurationFeature, option: ProductConfigurationOption) {
    if (feature.featureOptionType == FeatureOptionType.ADD_ON) {
      this.featureOptionMultipleSelected(feature, option);
      return;
    }
    if (feature.featureOptionType == FeatureOptionType.NUMBER) {
      this.featureOptionNumberSelected(feature, option);
      return;
    }

    this.featureResults.set(feature.id, [option]);
    // To refresh the ngModels
    this.featureResults = new Map<string, ProductConfigurationOption[]>(this.featureResults);
    this.executeRuleSubject.next();
  }

  private featureOptionMultipleSelected(feature: ProductConfigurationFeature, option: ProductConfigurationOption) {
    if (this.featureResults.has(feature.id)) {
      this.featureResults.set(feature.id, [...this.featureResults.get(feature.id), option]);
    } else {
      this.featureResults.set(feature.id, [option]);
    }
    // To refresh the ngModels
    this.featureResults = new Map<string, ProductConfigurationOption[]>(this.featureResults);
    this.executeRuleSubject.next();
  }

  private featureOptionNumberSelected(feature: ProductConfigurationFeature, option: ProductConfigurationOption) {
    const quantity = option.properties.quantityAdded || 0;
    if (quantity == 0) {
      this.featureOptionRemoved(feature, option);
      return;
    }

    if (this.featureResults.has(feature.id)) {
      // We already have added a selection for this feature. Now we need to check some things.
      // If we have selected the same option again, we should just update the quantity.
      // else we can insert it as a new option.
      const indexOfOption = this.featureResults.get(feature.id).findIndex(o => o.id == option.id);
      if (indexOfOption > -1) {
        // remove the old option
        this.featureResults.get(feature.id).splice(indexOfOption, 1);
      }
      this.featureResults.set(feature.id, [...this.featureResults.get(feature.id), option]);
    } else {
      this.featureResults.set(feature.id, [option]);
    }

    this.featureResults = new Map<string, ProductConfigurationOption[]>(this.featureResults);
    this.executeRuleSubject.next();
  }

  featureOptionRemoved(feature: ProductConfigurationFeature, option: ProductConfigurationOption) {
    if (!this.featureResults.has(feature.id)) {
      return;
    }

    const filtered = this.featureResults.get(feature.id).filter(o => o.id != option.id);
    this.featureResults.set(feature.id, filtered);
    // To refresh the ngModels
    this.featureResults = new Map<string, ProductConfigurationOption[]>(this.featureResults);
  }

  getFilteredOptions(feature: ProductConfigurationFeature): ProductConfigurationOption[] {
    // TODO do the filtering
    return this.featureOptions.get(feature.id);
  }

  hasOptionSelected(featureId: string, option: ProductConfigurationOption) {
    return this.featureResults.has(featureId) && !!this.featureResults.get(featureId).find(o => o.id == option.id);
  }

  isAllowedToOrder(): boolean {
    const allIds = this.configuration.features.map(f => f.featureId);
    const featuresRequiringAction = this.getFiltersRequiringAction(allIds);
    return this.featuresHasInputValues(featuresRequiringAction);
  }

  requiredFieldsInGroupHasActions(groupId: string): boolean {
    const featureIds = this.groupedFeatures.get(groupId).map(f => f.id);
    const featuresRequiringAction = this.getFiltersRequiringAction(featureIds);
    return this.featuresHasInputValues(featuresRequiringAction);
  }

  private featuresHasInputValues(featureIds: string[]) {
    for (const id of featureIds) {
      // Bad case, we have not received any input for this feature.
      if (!this.featureResults.has(id) || this.featureResults.get(id).length == 0) {
        return false;
      }
    }
    return true;
  }

  private getFiltersRequiringAction(featureIds: string[]): string[] {
    return featureIds
      .map(id => this.keyedFeatures.get(id))
      .filter(feature => {
        // Get optional from PIM. Fallback to not being optional.
        return (feature.properties?.optional || false) == false;
      })
      .map(feature => feature.id);
  }

  addToCart(): void {
    const productConfigurationSelectedOptions: CartRowFeatureOptionSelection[] = this.buildSelection();
    const extra = {
      productConfigurationSelectedOptions: this.filterSelectionsAddedAsAdditionalRows(
        productConfigurationSelectedOptions
      )
    };

    const cartRowsToAdd = [
      {
        productId: this.configuration.id,
        qty: 1,
        extra: extra
        // This is a hack to make sure that we can identify the cart rows that are added from the configurator.
        // Should be product.id + "@" + currentTimeMillis
        // TODO, this does not work with the current cart implementation. Waiting until the scenario comes up for a customer.
        //productPartialId: this.product.id + '@' + new Date().getTime()
      },
      ...this.createCartRowObjectsForAdditionalRows(productConfigurationSelectedOptions)
    ];

    this.cartService.bulkAddToCart(cartRowsToAdd);
    this.gungNotificationService.notify('Added to cart', 'The configuration was added to the current cart', 'success');
  }

  private filterSelectionsAddedAsAdditionalRows(
    selections: CartRowFeatureOptionSelection[]
  ): CartRowFeatureOptionSelection[] {
    return selections.filter(selection => !this.isSelectionThatShouldBeAddedOnSeparateRow(selection));
  }

  private isSelectionThatShouldBeAddedOnSeparateRow(selection: CartRowFeatureOptionSelection): boolean {
    return !!selection.feature?.properties?.orderOnSeparateRow || false;
  }

  private createCartRowObjectsForAdditionalRows(selections: CartRowFeatureOptionSelection[]): any {
    return (
      selections
        // We only care about the ones that should be on a separate row.
        .filter(selection => this.isSelectionThatShouldBeAddedOnSeparateRow(selection))
        .flatMap(selection => {
          // We need to create rows for each of the materials.
          return selection.option.materials.map(material => {
            return {
              productId: material.productId,
              // Material.quantity is the quantity required for each selection. If we have multiple of the selection we
              // need to multiply by that amount in order to get the total.
              qty: material.quantity * selection.qty
            };
          });
        })
    );
  }

  buildSelection(): CartRowFeatureOptionSelection[] {
    const productConfigurationSelectedOptions: CartRowFeatureOptionSelection[] = [];
    if (!this.featureResults || this.featureResults.size == 0) {
      return productConfigurationSelectedOptions;
    }

    for (const featureId of this.featureResults.keys()) {
      const options = this.featureResults.get(featureId);
      if (options.length == 0) {
        continue;
      }

      const mappedOptions = options.map(option => {
        return {
          option: option,
          feature: this.keyedFeatures.get(featureId),
          qty: Number(option.properties?.quantityAdded) || 1
        };
      });

      productConfigurationSelectedOptions.push(...mappedOptions);
    }
    return productConfigurationSelectedOptions;
  }

  protected readonly FeatureOptionType = FeatureOptionType;

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected readonly String = String;
}
