import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GridsterItem } from 'angular-gridster2';
import { ReplaySubject, Subject } from 'rxjs';
import { PdfExportDefaultTemplateModalComponent } from '../components/pdf-export-default-template-modal/pdf-export-default-template-modal.component';
import { DynamicPdfService } from './dynamic-pdf.service';
import { first } from 'rxjs/operators';
import { PdfDocument } from '../models/pdf-document';
import { TranslateService } from '@ngx-translate/core';
import { GungImageUrlService } from 'gung-common';

interface GungGridItem extends GridsterItem {
  productId?: string;
  data: { [s: string]: any };
}

@Injectable({
  providedIn: 'root'
})
export class ExportDragProductService {
  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();

  currentLang = this.translateService.currentLang;
  defaultOrientation = 'LANDSCAPE';

  constructor(
    protected dynamicPdfService: DynamicPdfService,
    private sanitizer: DomSanitizer,
    protected http: HttpClient,
    protected ngbModal: NgbModal,
    protected translateService: TranslateService,
    protected gungImageUrlService: GungImageUrlService
  ) { }

  selectedPage = 0;
  draggedProduct: string;
  draggedDigitalAsset;

  public renderedItem: { [s: string]: SafeHtml } = {};
  productsGrid: Subject<{ [n: number]: GungGridItem[] }> = new ReplaySubject<{ [n: number]: GungGridItem[] }>(1);

  serviceGrid: { [n: number]: GungGridItem[] } = { 0: [] };

  public selectedTemplate;
  public flow;
  public customer: any;

  addListOfProducts(products, page = this.selectedPage, clearPdfExport: boolean = true) {
    const id = PdfExportDefaultTemplateModalComponent.name;
    const ref = this.ngbModal.open(PdfExportDefaultTemplateModalComponent, {
      size: 'lg'
    });
    ref.componentInstance.delegate = this;
    ref.result.then(
      result => {
        if (result) {
          if (clearPdfExport) {
            this.clearPdfExport();
          }
          this.selectedTemplate = result;
          let numberItens = 0;
          for (const productId of products) {
            const product = {
              modelId: productId.substr(0, productId.indexOf('-')),
              id: productId
            };
            const lastpage = Number(Object.keys(this.serviceGrid)[Object.keys(this.serviceGrid).length - 1]) || 0;
            this.setOneProduct(product, lastpage);
            numberItens++;
            if (numberItens === this.selectedTemplate.template.maxPerPage) {
              numberItens = 0;
              this.addNewPage(null);
            }
          }
        }
      },
      reason => undefined
    );
    this.modalRef.set(id, ref);
  }

  clearPdfExport() {
    this.serviceGrid = {};
    this.productsGrid.next(this.serviceGrid);
  }

  /* Add product to page grid */
  setOneProduct(product, page = this.selectedPage, x = 0, y = 0) {
    if (!this.serviceGrid[page]) {
      this.serviceGrid[page] = [];
    }
    const index = this.serviceGrid[page].findIndex(
      i => i.data.modelId + i.data.productId === product.modelId + product.id
    );
    if (index < 0) {
      const productToAdd = {
        x,
        y,
        cols: this.selectedTemplate.template.cols,
        rows: this.selectedTemplate.template.rows,
        productId: product.id,
        fragment: this.selectedTemplate.template.fragment,
        fragmentFile: this.selectedTemplate.template.fragmentFile,
        data: {
          modelId: product.modelId,
          variantIds: [product.id],
          flowId: undefined,
          productId: product.id,
          customerId: undefined,
          language: this.currentLang
        }
      };
      if (this.selectedTemplate.template.data.flowId) {
        productToAdd.data.flowId = this.flow.id;
      }
      if (this.selectedTemplate.template.data.customerId) {
        productToAdd.data.customerId = this.customer.id;
      }
      this.serviceGrid[page].push(productToAdd);
      this.productsGrid.next(this.serviceGrid);
      this.getRenderedItemHTML(productToAdd, product.modelId + product.id);
    }
  }

  getRenderedItemHTML(itemToAdd, id) {
    this.dynamicPdfService
      .getRenderedItemHtml(itemToAdd)
      .pipe(first())
      .subscribe(renderHtml => {
        // We should in general never do what is done below since it opens up for XSS attacks on our applications.
        // However since the HTML we fetch comes from either our git-repo or from documents stored on the customers
        // server it means that we are basically fucked anyways if one of them is compromised.
        const unsanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(renderHtml);
        this.renderedItem[id] = unsanitizedHtml;
      });
  }

  setOneDigitalAsset(imageDigitalAsset, page = this.selectedPage, x = 0, y = 0) {
    if (!this.serviceGrid[page]) {
      this.serviceGrid[page] = [];
    }
    const index = this.serviceGrid[page].findIndex(i => i.data?.url?.includes(imageDigitalAsset.s3Uri));
    if (index < 0) {
      const digitalAssetToAdd = {
        x,
        y,
        cols: 3,
        rows: 5,
        fragment: 'image-cover-wrapper',
        fragmentFile: 'dynamic-product-fragments',
        data: {
          url: this.gungImageUrlService.getUrl(imageDigitalAsset.s3Uri)
        }
      };
      this.serviceGrid[page].push(digitalAssetToAdd);

      this.productsGrid.next(this.serviceGrid);

      this.getRenderedItemHTML(digitalAssetToAdd, this.gungImageUrlService.getUrl(imageDigitalAsset.s3Uri));
    }
  }

  setOneNote(note, page = this.selectedPage, x = 0, y = 0) {
    if (!this.serviceGrid[page]) {
      this.serviceGrid[page] = [];
    }

    const noteToAdd = {
      x,
      y,
      cols: 3,
      rows: 3,
      fragment: 'text-display',
      fragmentFile: 'dynamic-product-fragments',
      data: {
        id: 'note_' + +new Date(),
        heading: note.heading,
        note: note.note,
        font: note.font
      }
    };
    this.serviceGrid[page].push(noteToAdd);

    this.productsGrid.next(this.serviceGrid);

    this.getRenderedItemHTML(noteToAdd, noteToAdd.data.id);
  }

  editNote(note, page = this.selectedPage) {
    const index = this.serviceGrid[page].findIndex(n => n.data.id === note.data.id);
    if (index > -1) {
      this.serviceGrid[page][index] = note;
      this.productsGrid.next(this.serviceGrid);
      this.getRenderedItemHTML(note, note.data.id);
    }
  }

  changeTemplate(product) {
    const saveHtmlIndex = product.data.modelId + product.data.productId;
    this.dynamicPdfService
      .getRenderedItemHtml(product)
      .pipe(first())
      .subscribe(renderHtml => {
        // We should in general never do what is done below since it opens up for XSS attacks on our applications.
        // However since the HTML we fetch comes from either our git-repo or from documents stored on the customers
        // server it means that we are basically fucked anyways if one of them is compromised.
        const unsanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(renderHtml);
        this.renderedItem[saveHtmlIndex] = unsanitizedHtml;
      });
  }

  getGrids() {
    return this.productsGrid.asObservable();
  }

  changePagePosition(currentPos, newPos) {
    if (!this.serviceGrid[newPos]) {
      this.serviceGrid[newPos] = [];
    }
    const copyCurrent = JSON.parse(JSON.stringify(this.serviceGrid[currentPos]));
    const copyNew = JSON.parse(JSON.stringify(this.serviceGrid[newPos]));
    this.serviceGrid[currentPos] = copyNew;
    this.serviceGrid[newPos] = copyCurrent;
    this.productsGrid.next(this.serviceGrid);
  }

  removeItem(item, page) {
    const index = this.serviceGrid[page].findIndex(i => i.productId === item.productId);
    if (index > -1) {
      this.serviceGrid[page].splice(index, 1);
    }
    this.productsGrid.next(this.serviceGrid);
  }

  removePage(page) {
    delete this.serviceGrid[page];
  }

  addNewPage(position) {
    const lastpage = Number(Object.keys(this.serviceGrid)[Object.keys(this.serviceGrid).length - 1]);
    if (!position) {
      this.serviceGrid[lastpage + 1] = [];
    } else {
      for (let index = lastpage; index >= position; index--) {
        this.serviceGrid[index + 1] = this.serviceGrid[index];
      }
      this.serviceGrid[position] = [];
    }
    this.productsGrid.next(this.serviceGrid);
  }

  fillPages(pdfExport: PdfDocument) {
    this.serviceGrid = {};
    let i = 0;
    for (const itemsPage of pdfExport.pages) {
      this.serviceGrid[i] = itemsPage.items;
      for (const item of itemsPage.items) {
        if (item.fragment === 'text-display') {
          this.getRenderedItemHTML(item, item.data.id);
        } else if (!item.data?.url) {
          this.getRenderedItemHTML(item, item.data.modelId + item.data.productId);
        } else {
          this.getRenderedItemHTML(item, item.data.url);
        }
      }
      i++;
    }
    this.productsGrid.next(this.serviceGrid);
  }
}
