import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Customer, CustomerContact, CustomerContactService, SelectedCustomerService } from 'gung-standard';

@Component({
  selector: 'gung-jeeves-jeeves-add-contact-modal',
  templateUrl: './jeeves-add-contact-modal.component.html',
  styleUrls: ['./jeeves-add-contact-modal.component.css']
})
export class JeevesAddContactModalComponent implements OnInit {
  public contact: Customer;
  contactForm: FormGroup;
  isAddingContact = false;

  get formControls() {
    return this.contactForm.controls;
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected customerContactService: CustomerContactService,
    protected selectedCustomerService: SelectedCustomerService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      name: this.formBuilder.control(''),
      CS1: this.formBuilder.group({
        comkod: '1',
        comnr: this.formBuilder.control('')
      }),
      CS4: this.formBuilder.group({
        comkod: '4',
        comnr: this.formBuilder.control('')
      }),
      CS8: this.formBuilder.group({
        comkod: '8',
        comnr: this.formBuilder.control('')
      })
    });
  }

  crossClick() {
    this.activeModal.dismiss('Cross click');
  }

  close(result?) {
    this.activeModal.close(result || 'Closed');
  }

  submitForm() {
    this.isAddingContact = true;

    const newContact: CustomerContact = JSON.parse(JSON.stringify(this.contact));
    newContact.name = this.contactForm.get('name').value;

    Object.keys(this.contactForm.value).forEach(key => {
      if (this.contactForm.value[key].comnr !== '') {
        newContact.extra[key] = {
          ...newContact.extra[key],
          ...this.contactForm.value[key]
        };
      }
    });

    this.customerContactService.addCustomerContact(this.contact.id, newContact).subscribe(result => {
      this.isAddingContact = false;
      this.customerContactService.updateCustomerContactsSubject(this.contact.id);
      this.close(result);
    });
  }
}
