import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { PlanogramGridItem } from '../models/planogram';

@Injectable({
  providedIn: 'root'
})
export class PlanogramAddItemsService {

  protected planogramListSubject: Subject<PlanogramGridItem> = new Subject<PlanogramGridItem>();
  draggedItem;
  protected dragHelperListSubject: Subject<any> = new Subject<any>();

  constructor() { }

  add(item){
    this.planogramListSubject.next(item);
  }

  get():Observable<PlanogramGridItem>{
    return this.planogramListSubject;
  }

  addDragHelper(item){
    this.dragHelperListSubject.next(item);
  }

  getDragHelper(){
    return this.dragHelperListSubject;
  }

  clear(){
    this.planogramListSubject.next(null);
  }
}
