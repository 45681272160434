import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReplenishmentCustomerViewComponent } from './components/replenishment-customer-view/replenishment-customer-view.component';

import { ReplenishmentCustomerListComponent } from './components/replenishment-customer-list/replenishment-customer-list.component';
import { ReplenishmentCustomerComponent } from './components/replenishment-customer/replenishment-customer.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard } from '../../guards/authguard/auth-guard';
import { SharedModule } from '../../shared/shared.module';
import { ReplenishmentProductListComponent } from './components/replenishment-product-list/replenishment-product-list.component';
import { ReplenishmentProductViewComponent } from './components/replenishment-product-view/replenishment-product-view.component';
import { AddReplenishmentProductComponent } from './components/add-replenishment-product/add-replenishment-product.component';
import { CreateReplenishmentButtonComponent } from './components/create-replenishment-button/create-replenishment-button.component';

const routes: Routes = [
  {
    path: '',
    component: ReplenishmentCustomerListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ':customerId',
    component: ReplenishmentCustomerComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    ReplenishmentCustomerViewComponent,
    ReplenishmentCustomerListComponent,
    ReplenishmentCustomerComponent,
    ReplenishmentProductListComponent,
    ReplenishmentProductViewComponent,
    AddReplenishmentProductComponent,
    CreateReplenishmentButtonComponent
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      extend: true
    }),
    RouterModule.forChild(routes)
  ],
  exports: [
    ReplenishmentCustomerComponent,
    AddReplenishmentProductComponent,
    CreateReplenishmentButtonComponent,
    ReplenishmentProductListComponent,
    AddReplenishmentProductComponent
  ]
})
export class ReplenishmentModule {}
