import { Injectable, Type, Optional, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthService,
  CartSalesStepComponent,
  CartTotalStepComponent,
  CheckoutConfigService,
  CheckoutStepComponent,
  GungFlowService,
  QuantityValidationStepComponent,
  NavigationConfig,
  CheckoutCartListComponent
} from 'gung-standard';
import { forkJoin, Observable, of } from 'rxjs';
import { first, map } from 'rxjs';
import { AssignJeevesDefaultsComponent } from '../../components/checkout/steps/assign-jeeves-defaults/assign-jeeves-defaults.component';
import { AssignOrderRowsJeevesComponent } from '../../components/checkout/steps/assign-order-rows-jeeves/assign-order-rows-jeeves.component';
import { DeliveryLocationsJeevesComponent } from '../../components/checkout/steps/delivery-locations-jeeves/delivery-locations-jeeves.component';
import { JeevesCreditBlockedCheckerComponent } from '../../components/checkout/steps/jeeves-credit-blocked-checker/jeeves-credit-blocked-checker.component';
import { OrderTermsJeevesComponent } from '../../components/checkout/steps/order-terms-jeeves/order-terms-jeeves.component';
import { SummarizeOrderJeevesComponent } from '../../components/checkout/steps/summarize-order-jeeves/summarize-order-jeeves.component';
import { JeevesCustomerConfigService } from '../jeeves-customer-config.service';

@Injectable({
  providedIn: 'root'
})
export class JeevesCheckoutConfigService extends CheckoutConfigService {
  constructor(
    router: Router,
    protected authService: AuthService,
    protected gungFlowService: GungFlowService,
    protected jeevesCustomerConfigService: JeevesCustomerConfigService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig
  ) {
    super(router, gungFlowService, environment, authService);
  }

  getMainProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return this.getMainProcessStepsByRole();
  }

  protected getMainProcessStepsByRole(): Observable<Type<CheckoutStepComponent>[]> {
    return forkJoin([
      this.authService.getRoles().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ]).pipe(
      first(),
      map(([roles, flow]) => {
        const steps = [];
        const filteredRoles = roles.filter(role => role.toUpperCase() === 'ADMIN' || role.toUpperCase() === 'SALES');
        const isSalesOrAdmin = filteredRoles.length > 0;
        if (this.checkoutOnCartList) {
          steps.push(CheckoutCartListComponent);
        } else {
          if (isSalesOrAdmin) {
            steps.push(CartSalesStepComponent);
          } else {
            steps.push(CartTotalStepComponent);
          }
        }
        steps.push(AssignOrderRowsJeevesComponent);

        if (this.jeevesCustomerConfigService.useCheckoutCreditCheck) {
          steps.push(JeevesCreditBlockedCheckerComponent);
        }

        steps.push(DeliveryLocationsJeevesComponent);

        if (isSalesOrAdmin) {
          steps.push(OrderTermsJeevesComponent);
        }

        steps.push(SummarizeOrderJeevesComponent);

        if (flow.requireAvailability || flow.requireCurrentAvailability || flow.disableNonAvailable) {
          steps.push(QuantityValidationStepComponent);
        }

        return steps;
      })
    );
  }

  getPreProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return forkJoin([this.standardPreSteps(), this.getInternalPreProcessSteps()]).pipe(
      map(([superSteps, internalPre]) => [...superSteps, ...internalPre])
    );
  }

  getInternalPreProcessSteps(): Observable<Type<CheckoutStepComponent>[]> {
    const result = [AssignJeevesDefaultsComponent] as Type<CheckoutStepComponent>[];

    return of(result);
  }

  getBulkOrderERPSteps(): Observable<Type<CheckoutStepComponent>[]> {
    return of([DeliveryLocationsJeevesComponent]);
  }
}
