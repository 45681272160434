import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { CheckoutObject, CheckoutStepError } from '../../../../models/checkout';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-checkout-step',
  templateUrl: './checkout-step.component.html',
  styleUrls: ['./checkout-step.component.css']
})
export class CheckoutStepComponent implements OnInit {
  @Output()
  public stepDone = new EventEmitter<CheckoutObject>();

  @Output()
  public stepPrevious = new EventEmitter<CheckoutObject>();

  @Output()
  public stepError = new EventEmitter<CheckoutStepError>();

  @Output()
  public disableNextButton = new EventEmitter<boolean>();

  @Input()
  public checkout: CheckoutObject;

  @Input()
  public onNextBtnClicked: Observable<void | any>;

  public enablePreviousBtnClickedHandler: boolean = false;
  @Input()
  public onPrevBtnClicked: Observable<void | any>;

  public getLabel(): string {
    return '';
  }

  public getBtnTexts(checkout?: CheckoutObject): { next: string | void; prev: string | void } | void { }

  public isVisible(): boolean {
    return true;
  }

  public isCreateOfferStep(): boolean {
    return false;
  }

  public isExternalPaymentStep(): boolean {
    return false;
  }

  ngOnInit(): void { }
}
