import {
  ProductPaginationListConfigService,
  ProductService,
  AuthService,
  Product,
  AssortmentService
} from 'gung-standard';
import { ListLayout, ListLayoutMultipleComponent, SearchRequest, SearchResult } from 'gung-list';
import { Injectable } from '@angular/core';
import { ProductPaginationListTableComponent } from '../components/product-pagination-list-table/product-pagination-list-table.component';
import { Observable } from 'rxjs';
import { first, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SjoborgProductPaginationListConfigService extends ProductPaginationListConfigService {
  private searchFlow = 'sSEARCH';

  constructor(
    protected productService: ProductService,
    authService: AuthService,
    private assortmentService: AssortmentService
  ) {
    super(productService, authService);
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ProductPaginationListTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Product grid'
      }
    ];
  }

  getPagedItems(request: SearchRequest): Observable<SearchResult<Product>> {
    const searchRequest: SearchRequest = {
      ...request,
      assortment: this.searchFlow
    };
    return this.productService.getPagedProducts(searchRequest);
  }

  getSearchButtonEnabled(): boolean {
    return true;
  }

  getSearchButtonText(): string {
    return 'SEARCH';
  }
}
