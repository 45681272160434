import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { GungTableActiveFilter, GungTableConfig, GungTableCustomBodyComponent } from '../../models/common-table';
import { gungComparatorHelper, gungGetPropertyOfObject } from '../../utils/gung-utils';


@Component({
  selector: 'gung-table',
  templateUrl: './gung-table.component.html',
  styleUrl: './gung-table.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class GungTableComponent<T> implements OnInit, OnChanges, AfterViewInit {
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if(this.openFilter){
      
      const clickedInsideMenu = (event.target as HTMLElement).closest('.filter-box');
      if (!clickedInsideMenu) {
        this.openFilter.showFilterBox = false;
      }
    }
    
  }

  @Input() config: GungTableConfig<T>;

  dynamicComponentRef: ComponentRef<GungTableCustomBodyComponent<T>>;
  
  @ViewChild('gungTableCustom', { read: ViewContainerRef , static: false}) viewChild;

  useCustomComponent = false;
  
  indexSorted: number;
  filterItems: T[];

  dataTable: any;
  originalData: any;
  activeFilters: GungTableActiveFilter[] = [];
  openFilter:GungTableActiveFilter;
  search: string;
  pageSize: number = 30; 
  currentPage = 1;
  totalPages = 1;
  paginationRange: number[] = [];


  constructor(
    protected changeDetector : ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
 
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(this.config){
      this.filterItems = structuredClone(this.config.data);
      this.pageSize = this.config.itemsPerPage || 30;
      
      if(this.config.customBodyComponent){
        this.useCustomComponent = true;
        this.changeDetector.detectChanges();
        this.renderLayout(this.config.customBodyComponent);
      }
      this.mountData();
    }
    
   
  }

  ngOnInit() {
    /* console.log('gungTableService', this.config); */
  }

  mountData() {
    this.dataTable = [];
    for (const item of this.filterItems) {
      const dataItem = [];
      for (const header of this.config.headers) {
        const value = gungGetPropertyOfObject(header.path, item);
        dataItem.push(value);
      }
      this.dataTable.push(dataItem);
    }
    this.originalData = structuredClone(this.dataTable);
    this.updatePagination();
    
  }

  updatePagination() {
    this.totalPages = Math.ceil(this.filterItems.length / this.pageSize);
    this.calculatePaginationRange();
  }

  updateTableData() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.dataTable = this.originalData.slice(startIndex, endIndex);
    if(this.useCustomComponent){
      this.dynamicComponentRef.instance.dataTable = [...this.dataTable];
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updateTableData();
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateTableData();
    }
  }

  gungTableSort(index: number) {
    const header = this.config.headers[index];
    let sort;
  
    // Atualiza o estado de ordenação do cabeçalho
    if (header.sortActivated === 'ASC') {
      header.sortActivated = 'DESC';
      sort = -1;
    } else if (header.sortActivated === 'DESC') {
      header.sortActivated = 'NONE';
    } else {
      header.sortActivated = 'ASC';
      sort = 1;
    }
  
    // Se a ordenação estiver ativa, aplicá-la aos itens filtrados
    if (header.sortActivated !== 'NONE') {
      this.indexSorted = index;
      this.filterItems.sort((a, b) => {
        const aValue = gungGetPropertyOfObject(header.path, a);
        const bValue = gungGetPropertyOfObject(header.path, b);
        return gungComparatorHelper(aValue, bValue, sort);
      });
    } else {
      this.filterData();
      this.indexSorted = null;
    }
  
    this.mountData();
  }

  searchChange(search) {
    this.search = search;
    this.filterData();
  }

  openFilterOptions(index){
    const header = this.config.headers[index];
    let activeFilter = this.activeFilters.find(f => f.headerIndex === index);
    if(!activeFilter){
      activeFilter = {activeValues:[], headerIndex: index, values:[], showFilterBox: true};
      this.activeFilters = [...this.activeFilters, activeFilter];
      const valueCountMap: { [key: string]: number } = {};
      for (const item of this.filterItems) {
        const value = gungGetPropertyOfObject(header.path, item);
        if (value) {
          if (valueCountMap[value]) {
            valueCountMap[value]++;
          } else {
            valueCountMap[value] = 1;
          }
        }
        
      }
      activeFilter.values = Object.keys(valueCountMap).map(value => ({
        value,
        qty: valueCountMap[value],
        active: false
      }));
    }else{
      activeFilter.showFilterBox = true;
    }
    this.openFilter = activeFilter;
    
  }

  selectFilter(filter: GungTableActiveFilter, value: string){
    if(!filter.activeValues.includes(value)){
      filter.activeValues.push(value);
    }else{
      filter.activeValues = filter.activeValues.filter(v => v !== value);
    }
    let changeFilter = this.activeFilters.find(f => f.headerIndex === filter.headerIndex);
    changeFilter = filter;
    this.activeFilters = [...this.activeFilters];
    this.filterData();
  }

  filterData(){
    this.currentPage = 1;
    this.filterItems = structuredClone(this.config.data);
    if (this.search) {
      this.filterItems = this.filterItems.filter(item => {
        return this.config.headers.some(header => {
          const value = gungGetPropertyOfObject(header.path, item);

          if (header.searchable && value && value.toString().toLowerCase().includes(this.search.toLowerCase())) {
            return true;
          }
          return false;
        });
      });
    }
    if(this.activeFilters && this.activeFilters.length > 0){
      for (const filter of this.activeFilters) {
        if(filter.activeValues.length > 0){
          const head = this.config.headers[filter.headerIndex]
          this.filterItems = this.filterItems.filter(item => {
            const value = gungGetPropertyOfObject(head.path, item);
            return filter.activeValues.includes(value);
          })
        }
        
      }
    }
    this.mountData();
  }


  calculatePaginationRange() {
    const range = [];
    const totalNumbersToShow = 1; // Page number to show before and after current page
  
    if (this.totalPages <= 1) {
      this.paginationRange = [1];
      
    }else{
      range.push(1);
  
      const startPage = Math.max(2, this.currentPage - totalNumbersToShow);
      const endPage = Math.min(this.totalPages - 1, this.currentPage + totalNumbersToShow);
    
      if (startPage > 2) {
        range.push(-1); 
      }
      for (let i = startPage; i <= endPage; i++) {
        range.push(i);
      }
      if (endPage < this.totalPages - 1) {
        range.push(-1); 
      }
      range.push(this.totalPages);
    
      this.paginationRange = range;
    }
    
    this.updateTableData();
  }

  goToPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.calculatePaginationRange();
      
    }
  }

  goToFirstPage() {
    this.currentPage = 1;
    this.calculatePaginationRange();
  }
  
  goToPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.calculatePaginationRange();
    }
  }
  
  goToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.calculatePaginationRange();
    }
  }
  
  goToLastPage() {
    this.currentPage = this.totalPages;
    this.calculatePaginationRange();
  }

  renderLayout(layoutComponent: Type<GungTableCustomBodyComponent<T>>): void {
    this.dynamicComponentRef = this.viewChild.createComponent(layoutComponent);
    this.dynamicComponentRef.instance.data = this.config.data;
  }
}
