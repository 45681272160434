import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, NavigationStart } from '@angular/router';
import { FastSearchConfigService } from 'gung-list';
import { Subject, Observable, takeUntil, filter, debounce, interval, first } from 'rxjs';
import { BaseViewConfigService, ProductViewType } from '../../services/base-view-config/base-view-config.service';
import { ProductAssortmentParentService } from '../../services/product-assortment-parent/product-assortment-parent.service';
import { ProductInlineAndCategoriesSearchListConfigService } from '../../services/product-inline-and-categories-search-list-config/product-inline-and-categories-search-list-config.service';
import { ProductInlineSearchListConfigService } from '../../services/product-inline-search-list-config/product-inline-search-list-config.service';
import { ProductConcept } from '../concept-detail-v2/concept-detail-v2.component';
import { ProductInlineSearchCardComponent } from '../product-inline-search-card/product-inline-search-card.component';

@Component({
  selector: 'lib-product-inline-and-categories-search',
  templateUrl: './product-inline-and-categories-search.component.html',
  styleUrls: ['./product-inline-and-categories-search.component.css']
})
export class ProductInlineAndCategoriesSearchComponent<T> extends ProductInlineSearchCardComponent implements OnInit {


  // @Input()
  // protected configService: FastSearchConfigService<T>;
  searchTerm = '';
  searchTermForm: FormControl;

  public totalItems = new Subject<number>();
  public resultsLimit = 10;

  public renderItems = new Subject<T[]>();
  public renderItems$: Observable<T[]> = this.renderItems.asObservable();

  public searching = false;

  public totalCountConcepts = 0;
  public totalItemsConcepts = new Subject<number>();
  public renderItemsConcepts = new Subject<T[]>();
  public renderItemsConcepts$: Observable<T[]> = this.renderItemsConcepts.asObservable();

  products = [];
  concepts = [];

  public resultsShow = false; // show results list
  public isSmallScreen = true; // is mobile
  protected unsubscribeRouter: Subject<void> = new Subject<void>();
  public totalCount = 0;

  showProductsColumn = this.baseViewConfigService.getShowProductsColumn();
  showCategoriesColumn = this.baseViewConfigService.getShowCategoriesColumn();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.path && event.path.findIndex(t => t.dataset && !!t.dataset.inlineSearchProducts) > -1) {
      // Click on a product, close results
      this.resultsShow = false;
      return;
    }
    const insideClick = this.eRef.nativeElement.contains(event.target);
    if (!this.resultsShow && insideClick && !this.isSmallScreen) {
      this.resultsShow = true;
      return;
    } else if (this.resultsShow && !insideClick) {
      this.resultsShow = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallScreen = window.innerWidth <= 767;
  }

  constructor(
    protected router: Router,
    protected productInlineSearchListConfigService: ProductInlineSearchListConfigService,
    protected productInlineAndCategoriesSearchListConfigService: ProductInlineAndCategoriesSearchListConfigService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService,
    protected eRef: ElementRef
  ) {
    super(router, productInlineAndCategoriesSearchListConfigService, baseViewConfigService, productAssortmentParentService);
    router.events
      .pipe(
        takeUntil(this.unsubscribeRouter),
        filter(event => event instanceof NavigationStart)
      )
      .subscribe(event => {
        this.resultsShow = false;
      });
  }

  ngOnInit(): void {
    this.searchTermForm = new FormControl(this.searchTerm);
    this.searchTermForm.valueChanges
      .pipe(
        takeUntil(this.unsubscribeRouter),
        debounce(() => interval(250))
      )
      .subscribe(valueChanged => {
        this.search(valueChanged);
      });

    const mediaQuery = matchMedia('screen and (max-width: 767px)');
    this.isSmallScreen = mediaQuery.matches;

    this.totalItems.pipe(takeUntil(this.unsubscribeRouter)).subscribe(total => (this.totalCount = total));

    this.renderItems.pipe(takeUntil(this.unsubscribeRouter)).subscribe(products => (this.products = products));
    this.renderItemsConcepts.pipe(takeUntil(this.unsubscribeRouter)).subscribe(concepts => (this.concepts = concepts));
    this.totalItemsConcepts.pipe(takeUntil(this.unsubscribeRouter)).subscribe(total => (this.totalCountConcepts = total));
  }

  ngOnDestroy(): void {
    this.unsubscribeRouter.next();
    this.unsubscribeRouter.complete();
  }

  public showMoreProducts({ value }) {
    this.resultsShow = false;
    // this.router.navigate(['/no-operation'], { skipLocationChange: true }).then(() => {
    this.router.navigate(['/view-more'], { queryParams: { search: 'DEFAULT__:__' + value, limit: 24, skuLevel: true } });
    // });
  }

  public showMoreConcepts({ value }) {
    this.resultsShow = false;
    // this.router.navigate(['/no-operation'], { skipLocationChange: true }).then(() => {
    this.router.navigate(['/view-more'], { queryParams: { search: 'DEFAULT__:__' + value, limit: 24, skuLevel: false } });
    // });
  }

  goToDetail(productId) {
    this.resultsShow = false;
    const item = this.products.filter(p => p.id === productId)[0] || this.concepts.filter(c => c.id === productId)[0] || undefined;
    console.log(this.products);

    if (!item) {
      return;
    }

    const isConcept: boolean = item?.productType === 'concept' ? true : false;

    if (this.baseViewConfigService.productViewType === ProductViewType.assortmentTreeView) {
      if (item?.productType === 'concept') {
        const productConcept = (item as ProductConcept);
        if (productConcept.subProductIds?.length > 0) {
          productId = productConcept.subProductIds[0];
        }
      }
      this.productAssortmentParentService.postProductAssortmentParentByProductIds([productId]).pipe(
        filter(assortmentParent => !!assortmentParent),
        first()
      ).subscribe(data => {
        if (data?.[productId]) {
          const path = data[productId];
          path.shift();
          const urlPath = path.map(path => path.id);
          if (!isConcept) {
            urlPath.push(productId);
          }
          this.router.navigate(['/articles', ...urlPath]);
        } else {

          this.router.navigate([
            item?.productType === 'concept' ?
              this.productInlineSearchListConfigService.productDetailRoute : this.productInlineSearchListConfigService.productDetailRoute,
            productId
          ]);
        }
      });
      return;
    }
    if (item?.productType === 'concept') {
      this.router.navigate([this.productInlineSearchListConfigService.conceptDetailRoute, productId]);
    } else {
      this.router.navigate([this.productInlineSearchListConfigService.productDetailRoute, productId]);
    }
  }

  search(term: string) {
    this.searching = true;
    this.productInlineAndCategoriesSearchListConfigService
      .getItems([term], 0, this.resultsLimit)
      .pipe(first())
      .subscribe(response => {
        this.searching = false;
        this.totalItems.next(response[0].totalItems);
        this.renderItems.next(response[0].items);
        this.totalItemsConcepts.next(response[1].totalItems);
        this.renderItemsConcepts.next(response[1].items);
      });
  }

  clearSearch() {
    this.searchTerm = '';
    this.search(this.searchTerm);
  }

  public keyPressedHandler(event, searchInput) {
    if (event.keyCode === 13) {
      //ENTER
      this.showMoreProducts(searchInput);
    }
  }

}
