import { Injectable } from '@angular/core';
import { MetadataService } from '../../services/metadata/metadata.service';
import { Product } from '../../models/product';
import { SimpleConfigBaseFilter } from 'gung-list';

@Injectable({
  providedIn: 'root'
})
export class ProductArticleClassFilterService extends SimpleConfigBaseFilter<Product> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getOptionIds(item: Product): string[] {
    const id = item.extra?.ar?.artkod;
    return [id];
  }
  getOptionName(key: string): string {
    const arttypbeskr = this.metadataService.getMetadataValue('xm', 'arttypbeskr', key);
    const webbesk = this.metadataService.getMetadataValue('xm', 'webbeskr', key);
    return webbesk ? webbesk : arttypbeskr;
  }
  getName(): string {
    return 'ARTICLE_CLASS';
  }
}
