import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { CustomHostDirective } from 'gung-list';
import { Subject, Subscription, first, switchMap } from 'rxjs';
import { CheckoutObject, CheckoutStepError } from '../../../models/checkout';
import { CheckoutStepComponent } from '../../checkout/steps/base/checkout-step.component';
import { ButtonsComponent } from '../../checkout/buttons/buttons.component';
import { StepIndicatorComponent } from '../../step-indicator/step-indicator.component';
import { BulkCheckoutConfigService } from '../../../services/bulk-checkout-config.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { OrderSubmitService } from '../../../services/order-submit.service';

@Component({
  selector: 'lib-bulk-checkout-wrapper',
  templateUrl: './bulk-checkout-wrapper.component.html',
  styleUrls: ['./bulk-checkout-wrapper.component.css']
})
export class BulkCheckoutWrapperComponent implements OnInit, AfterViewInit {

  public error?: CheckoutStepError;

  @ViewChild(CustomHostDirective)
  protected viewChild: CustomHostDirective;

  @ViewChild('indicator', { read: ViewContainerRef })
  protected indicator: ViewContainerRef;

  @ViewChild('btns', { read: ViewContainerRef })
  protected btns: ViewContainerRef;

  protected currentIndex = 0;
  protected visibleIndex = 0;

  protected steps: Type<CheckoutStepComponent>[];

  protected stepIndexVisible: boolean[];

  public get showLoading(): boolean {
    return this.submitting || !this.stepIndexVisible || !this.stepIndexVisible[this.currentIndex];
  }

  public get isSubmitting(): boolean {
    return this.submitting;
  }

  public sideButtons = true;

  protected submitting = false;
  public orderSubmitError: { show: boolean; error?: any } = { show: false };

  private currentStepDoneSubscription?: Subscription;
  private currentStepErrorSubscription?: Subscription;

  protected currentCheckout: CheckoutObject;

  protected nextStepSubject = new Subject<void | any>();

  protected renderedButtons: ButtonsComponent;

  protected renderedIndicator: StepIndicatorComponent;

  get index(): number {
    return this.currentIndex;
  }

  get nextBtnText(): string {
    return 'next';
  }

  get prevBtnText(): string {
    return 'previous';
  }

  
  constructor(
    protected checkoutConfig: BulkCheckoutConfigService,
    protected viewContainerRef: ViewContainerRef,
    protected changeDetectorRef: ChangeDetectorRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected gungModalService: GungModalService,
    protected orderSubmitService: OrderSubmitService
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.checkoutConfig.getCheckoutSteps().subscribe(steps => {
      this.steps = steps;
      this.stepIndexVisible = this.steps.map(s => s.prototype.isVisible());
      this.renderDynamicComponents();
      this.renderCurrentStep();
    });
  }

  protected renderDynamicComponents() {
    // render the indicator
    const indicatorFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.checkoutConfig.getStepIndicator()
    );
    this.indicator.clear();
    this.renderedIndicator = this.indicator.createComponent(indicatorFactory).instance;
    this.renderedIndicator.currentIndex = this.visibleIndex;
    this.renderedIndicator.stepIndexVisible = this.stepIndexVisible;
    this.renderedIndicator.steps = this.steps;

    // render the buttons
    this.sideButtons = false;
    const buttonsFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.checkoutConfig.getButtonsComponent()
    );
    this.btns.clear();
    this.renderedButtons = this.btns.createComponent(buttonsFactory).instance;
    this.renderedButtons.enableDiscount = false;
    this.renderedButtons.allSteps = this.steps.filter(step => step.prototype.isVisible());
    this.renderedButtons.currentStep = this.steps[this.currentIndex];
    /* if (this.currentIndex === this.steps.length-1) {
      this.renderedButtons.createOffers = this.checkoutConfig.enableOffers;
    } */
    /* this.renderedButtons.createOffer.subscribe(_ => this.createOffer()); */
    this.renderedButtons.nextClicked.subscribe(_ => this.nextBtnClicked(_));
    this.renderedButtons.prevClicked.subscribe(_ => this.previousBtnClicked());
   /*  this.renderedButtons.applyDiscountCode.subscribe(discountCode => this.applyDiscountCode(discountCode)); */
    this.renderedButtons.discountCode = this.currentCheckout?.extra?._discountCode || undefined;
    this.renderedButtons.updateBtnTexts(this.currentCheckout);

    this.changeDetectorRef.detectChanges();
  }

  // EVENT HANDLERS
  nextBtnClicked(nextInfo?: any) {
    if (nextInfo) {
      this.nextStepSubject.next(nextInfo);
    } else {
      this.nextStepSubject.next(undefined);
    }
  }

  previousBtnClicked() {
    const slicedSteps = this.steps.slice(0, this.currentIndex);
    const filteredSteps = slicedSteps.filter(step => step.prototype.isVisible());
    const lastStep = filteredSteps[filteredSteps.length - 1];
    this.currentIndex = this.steps.indexOf(lastStep);
    this.visibleIndex--;
    this.nextStepSubject.complete();
    this.renderCurrentStep();
    this.renderDynamicComponents();
  }

  protected renderCurrentStep(): void {
    this.unsubscribe();

    const componentToRender: Type<CheckoutStepComponent> = this.steps[this.currentIndex];
    const factory = this.componentFactoryResolver.resolveComponentFactory(componentToRender);
    const containerRef = this.viewChild.viewContainerRef;
    containerRef.clear();
    const componentRef = containerRef.createComponent(factory);
    const typedComponent = componentRef.instance as CheckoutStepComponent;

    if (this.currentCheckout !== undefined) {
      typedComponent.checkout = JSON.parse(JSON.stringify(this.currentCheckout));
    } else {
      typedComponent.checkout = this.currentCheckout;
    }
    this.nextStepSubject = new Subject<void | any>();
    typedComponent.onNextBtnClicked = this.nextStepSubject.asObservable();

    // subscribe to the outputs of the step
    typedComponent.stepDone.subscribe(newCheckout => {
      if (typedComponent.isVisible()) {
        this.visibleIndex++;
      }
      this.nextStepSubject.complete();
      this.currentCheckout = newCheckout;
      /* if (this.discountCode !== undefined) {
        this.currentCheckout.extra._discountCode = this.discountCode;
      } */
      this.nextStep();
    });
    // subscribe to the outputs of the step
    typedComponent.stepPrevious.subscribe(newCheckout => {
      this.previousBtnClicked();
    });

    typedComponent.stepError.subscribe(err => {
      this.displayError(err);
    });

    // trigger lifecycle hooks on the step
    this.changeDetectorRef.detectChanges();
    if (!typedComponent.isVisible()) {
      // automatically trigger next if the step is not visible
      this.nextStepSubject.next(undefined);
    }
  }
  protected unsubscribe() {
    if (this.currentStepDoneSubscription) {
      this.currentStepDoneSubscription.unsubscribe();
    }
    if (this.currentStepErrorSubscription) {
      this.currentStepErrorSubscription.unsubscribe();
    }
    if (this.nextStepSubject) {
      this.nextStepSubject.complete();
    }
  }

  protected displayError(checkoutError: CheckoutStepError) {
    this.error = checkoutError;
  }

  protected nextStep() {
    this.currentIndex++;
    if (this.currentIndex === this.steps.length) {
      this.submitOrder();
      return;
    }
    // render the next component
    this.renderCurrentStep();
    if (this.steps[this.currentIndex].prototype.isVisible()) {
      this.updateDynamicComponents();
    }
  }


  private updateDynamicComponents() {
    // TODO update the buttons and the stepper
    this.changeDetectorRef.detectChanges();
    this.renderDynamicComponents();
  }

  protected submitOrder() {
    if (this.currentCheckout.rows.length === 0) {
      // Empty rows card
      this.gungModalService
        .openConfirmYesNoModal('ERROR', 'ERROR_SUBMIT_ORDER_EMPTY_CART', { size: 'sm' }, 'OK', null)
        .then();
      this.previousBtnClicked();
      return;
    }
    this.submitting = true;
    this.orderSubmitError.show = false;
    delete this.currentCheckout.extra._calendar;
    //this.currentCheckout.targetOrderChannel = 'GUNG_WEB';
    
    this.checkoutConfig
      .getSubmitUrl(this.currentCheckout)
      .pipe(switchMap(url => this.orderSubmitService.postOrder(url, this.currentCheckout)))
      .subscribe({
          next: (response) =>{
            this.checkoutConfig.onOrderSubmitted(response);
          },
          error: (err) => {
            this.checkoutConfig
              .getShowOrderSubmitError()
              .pipe(first())
              .subscribe(show => {
                if (!!show) {
                  this.orderSubmitError.error = err.error;
                  this.orderSubmitError.show = !!show;                
                }
                this.submitting = false;
              });
            console.error('ERROR POSTING ORDER', err.error);
          },
        }
      );
  }
}
