import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { OrderRow, OrderService, Order } from 'gung-standard';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { switchMap } from 'rxjs';

@Component({
  selector: 'gung-jeeves-edit-orderrow-modal',
  templateUrl: './jeeves-edit-orderrow-modal.component.html',
  styleUrls: ['./jeeves-edit-orderrow-modal.component.css']
})
export class JeevesEditOrderrowModalComponent implements OnChanges {
  @Input()
  public orderRow: OrderRow;

  @Output()
  public orderRowChange = new EventEmitter();

  public editedOrderRow: OrderRow;

  public isUpdating = false;

  private allowedEditableFields = ['ordantal', 'vb_pris', 'kundrabatt', 'orpradtext'];

  private modal: NgbModalRef;

  constructor(protected modalService: NgbModal, protected orderService: OrderService) { }

  open(content) {
    this.modal = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static'
    });
  }

  updateRow() {
    console.log('UPDATING ROW');
    this.saveEditedOrderRow();
  }

  deleteRow() {
    this.editedOrderRow.extra.orp.ordradst = '90';
    this.updateOrder();
  }

  protected saveEditedOrderRow() {
    for (const field in this.editedOrderRow.extra.orp) {
      if (this.editedOrderRow.extra.orp[field] == null) {
        delete this.editedOrderRow.extra.orp[field];
      }
    }
    this.updateOrder();
  }

  private updateOrder() {
    this.isUpdating = true;
    this.orderService
      .updateOrderRow(this.editedOrderRow)
      .pipe(
        switchMap(() => {
          return this.orderService.getOrder(this.orderRow.orderId, true);
        })
      )
      .subscribe((order: Order) => {
        this.orderRowChange.emit(order);
        this.isUpdating = false;
        if (this.modal) {
          this.modal.close();
        }
      });
  }

  ngOnChanges() {
    // Clone the orderRow, do not modify it directly.
    this.editedOrderRow = JSON.parse(JSON.stringify(this.orderRow));

    const orp = {};
    this.allowedEditableFields.forEach(field => {
      orp[field] = this.orderRow.extra.orp[field];
    });
    this.editedOrderRow.extra.orp = orp;
  }
}
