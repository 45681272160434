<div class="inline-search-container" [ngClass]="{ 'inline-search-container-mobile': isSmallScreen && resultsShow }"
  (keydown.enter)="showMoreProducts(searchInput)">
  <div class="row">
    <div class="col">
      <div class="input-group inline-search d-none d-md-flex" [ngClass]="{ 'd-flex': resultsShow }">
        <input #searchInput type="text" class="form-control" [attr.placeholder]="'SEARCH' | translate"
          [formControl]="searchTermForm" (keypress)="keyPressedHandler($event, searchInput)" />
        <div class="input-group-append">
          <span class="input-group-text d-none d-md-flex" (click)="search(searchInput.value)"><i
              class="fas fa-search"></i></span>
          <span class="input-group-text d-md-none"
            (click)="$event.stopPropagation(); $event.preventDefault(); resultsShow = false"><i
              class="fas fa-times"></i></span>
        </div>
      </div>
      <div class="inline-search-mobile d-md-none" [class.d-none]="isSmallScreen && resultsShow">
        <div class="input-group-append">
          <span class="input-group-text" (click)="resultsShow = true"><i class="fas fa-search"></i></span>
        </div>
      </div>
    </div>
  </div>
  <div class="row inline-search-products" [class.d-none]="!resultsShow" data-inline-search-products="1">
    <div [ngClass]="{'col-6': totalCountConcepts != 0, 'col-12': totalCountConcepts === 0 || !showCategoriesColumn}"
      *ngIf="totalCount != 0 && showProductsColumn">
      <div class="row">
        <div class="col-12 p-0 text-center bg-light-title border-top-left-radius"
          [ngClass]="{'border-top-right-radius': totalCountConcepts === 0}">
          <h5 class="mt-2" translate>PRODUCTS</h5>
        </div>
        <div class="col-12 p-0 inline-search-scroll bg-white">
          <div class="row">
            <div class="col-12" *ngFor="let item of products">
              <button type="button" class="btn btn-white btn-block btn-sm text-left"
                (click)="$event.stopPropagation(); $event.preventDefault(); goToDetail(item.id); resultsShow = false">
                <div class="row">
                  <div class="col-3 p-0">
                    <img alt="{{ item.name }}" class="img-fluid"
                      *ngIf="item?.extra?.images && item?.extra?.images[0]; else noimage" [src]="item?.extra?.images[0]?.s3Uri| gungImageUrl
                        :'type:list'
                        :'etag:'+item?.extra?.images[0]?.s3ETag
                      " />
                    <ng-template #noimage>
                      <img class="card-img-top img-fluid w-100" [src]="'images/no-image.jpg' | gungImageUrl"
                        alt="NO IMAGE" class="img-fluid mx-auto d-block" />
                    </ng-template>
                  </div>
                  <div class="col-9 text-truncate">
                    <span class="d-block">
                      <small><b>{{ item.id }}</b></small>
                      <lib-price class="float-right" *ngIf="!!item.extra.price" [price]="item.extra.price" type="NET"
                        [currency]="item.extra.price.customerNetPrice.currencyCode" [product]="item"
                        [useStaffedPrice]="false"></lib-price>
                    </span>
                    <br />
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 p-0">
          <button type="button" class="btn btn-light btn-block btn-sm border-bottom-left-radius btn-min-height"
            [ngClass]="{'border-bottom-right-radius': totalCountConcepts === 0}"
            [disabled]="!(totalCount > resultsLimit)" (click)="showMoreProducts(searchInput)">
            <small>{{ (totalCount > resultsLimit ? 'GUNG_PRODUCT_LIST_LOAD_MORE' : '') | translate: { limit:
              resultsLimit, total: totalCount } }}</small>
          </button>
        </div>
      </div>
    </div>

    <div [ngClass]="{'col-6': totalCount != 0, 'col-12': totalCount === 0 || !showProductsColumn}"
      *ngIf="totalCountConcepts != 0 && showCategoriesColumn">
      <div class="row">
        <div class="col-12 p-0 text-center bg-light-title border-top-right-radius"
          [ngClass]="{'border-top-left-radius': totalCount === 0}">
          <h5 class="mt-2" translate>CATEGORIES</h5>
        </div>
        <div class="col-12 p-0 inline-search-scroll bg-white border-left-0">
          <div class="row">
            <div class="col-12" *ngFor="let item of concepts">
              <button type="button" class="btn btn-white btn-block btn-sm text-left"
                (click)="$event.stopPropagation(); $event.preventDefault(); goToDetail(item.id); resultsShow = false">
                <div class="row">
                  <div class="col-3 p-0">
                    <img alt="{{ item.name }}" class="img-fluid"
                      *ngIf="item?.extra?.images && item?.extra?.images[0]; else noimage" [src]="item?.extra?.images[0]?.s3Uri | gungImageUrl
                        :'type:list'
                        :'etag:'+item?.extra?.images[0]?.s3ETag
                    " />
                    <ng-template #noimage>
                      <img class="card-img-top img-fluid w-100" [src]="'images/no-image.jpg' | gungImageUrl"
                        alt="NO IMAGE" class="img-fluid mx-auto d-block" />
                    </ng-template>
                  </div>
                  <div class="col-9 text-truncate">
                    <span class="d-block">
                      <lib-price class="float-right" *ngIf="!!item.extra.price" [price]="item.extra.price" type="NET"
                        [currency]="item.extra.price.customerNetPrice.currencyCode" [product]="item"></lib-price>
                    </span>
                    <br />
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 p-0">
          <button type="button" class="btn btn-light btn-block btn-sm border-bottom-right-radius btn-min-height"
            [ngClass]="{'border-bottom-left-radius': totalCount === 0}"
            [disabled]="!(totalCountConcepts > resultsLimit)" (click)="showMoreConcepts(searchInput)">
            <small>{{ (totalCountConcepts > resultsLimit ? 'GUNG_PRODUCT_LIST_LOAD_MORE' : '') | translate: { limit:
              resultsLimit, total: totalCountConcepts } }}</small>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
