<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    <span translate>EDIT_YOUR_PRODUCT_INFORMATION</span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="!!kuarForm" [formGroup]="kuarForm">
    <lib-gung-text-input-horizontal gungTranslate="ID" formControlName="productId" placeholder="ID" renderStatic="true">
    </lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="NAME"
      formControlName="productName"
      placeholder="NAME"
      renderStatic="true"
    >
    </lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal gungTranslate="YOUR_PRODUCT_ID" formControlName="customerProductId">
    </lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal gungTranslate="YOUR_PRODUCT_NAME" formControlName="customerProductName">
    </lib-gung-text-input-horizontal>
    <!-- <lib-gung-text-input-horizontal gungTranslate="YOUR_PRODUCT_DESCRIPTION" formControlName="customerProductDescription">
    </lib-gung-text-input-horizontal> -->
  </div>

  <div class="row">
    <div class="col-12">
      <div
        *ngIf="alertMessage"
        class="alert mt-2 text-center"
        role="alert"
        [ngClass]="{ 'alert-success': alertMessage.type === 'success', 'alert-danger': alertMessage.type === 'danger' }"
        (click)="alertMessage = null"
      >
        <span>{{ alertMessage.message | translate }}</span>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-primary btn-default px-4" (click)="activeModal.close(false)" translate>CANCEL</button>
  <button class="btn btn-sm btn-outline-primary px-4" [disabled]="submited" (click)="onSave()" translate>SAVE</button>
</div>
