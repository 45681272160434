<div *ngIf="loginRequired || (!isLoading && !errorOccured); else noApp">
  <mat-sidenav-container [ngStyle]="containerStyles" [hasBackdrop]="true">
    <mat-sidenav
      #sidenav
      mode="over"
      [fixedInViewport]="true"
      [fixedTopGap]="marginTop"
      position="end"
      (opened)="sideMenuChange(true)"
      (closed)="sideMenuChange(false)"
      [(opened)]="sideMenuExpanded"
    >
      <ul *ngIf="mainMenu" class="no-style p-2 main-menu">
        <li *ngIf="!!userInfo && !userInfo?.hide" class="col-12 link-block">
          <div class="d-flex flex-column text-center text-secondary">
            <a *ngIf="userInfo.link; else noUserInfoLink" class="font-weight-bold user-info-link" [routerLink]="userInfo.link ? userInfo.link : undefined">{{ userInfo.name }}</a>
            <ng-template #noUserInfoLink>
              <span class="font-weight-bold">{{ userInfo.name }}</span>
            </ng-template>
            <span class="mt-1">{{ userInfo.role | translate }}</span>
          </div>
        </li>
        <hr class="mt-0">

        <li class="col-12 link-block" *ngFor="let linkGroup of mainMenu.links">
          <div *ngIf="linkGroup.link || linkGroup.action; else noLink">
            <a
              *ngIf="linkGroup.heading !== 'HIDE_PRICE'; else switchHidePrice"
              [routerLink]="linkGroup.link ? linkGroup.link : undefined"
              [routerLinkActive]="linkGroup.link ? 'active' : ''"
              (click)="linkGroup.action ? linkGroup.action() : undefined"
            >
              <i *ngIf="linkGroup.icon" class="fa {{ linkGroup.icon }} mr-2"></i>
              <span translate>{{ linkGroup.heading }}</span>
            </a>
            <ng-template #switchHidePrice>
              <div class="gung-toggle-switch">
                <label class="switch">
                  <input
                    id="hidePrice"
                    name="hidePrice"
                    type="checkbox"
                    #hidePriceInput
                    (change)="linkGroup.action(hidePriceInput)"
                  />
                  <span class="slider round bg-primary"></span>
                </label>
                <label for="hidePrice" class="ml-1" translate>{{ linkGroup.heading }}</label>
              </div>
            </ng-template>
          </div>

          <ng-template #noLink>
            <h6 class="text-uppercase font-weight-bold">
              <i *ngIf="linkGroup.icon" class="fa {{ linkGroup.icon }} mr-2"></i>
              <span translate>{{ linkGroup.heading }}</span>
            </h6>
          </ng-template>

          <ul class="pl-0 no-style">
            <li *ngFor="let childLink of linkGroup.links">
              <a
                [routerLink]="childLink.link ? childLink.link : undefined"
                [routerLinkActive]="childLink.link ? 'active' : ''"
                (click)="childLink.action ? childLink.action() : undefined"
              >
                <i *ngIf="childLink.icon" class="fa {{ childLink.icon }} mr-2"></i>
                <span translate>{{ childLink.heading }}</span>
              </a>
              <ul>
                <li *ngFor="let subLink of childLink.links">
                  <a
                    [routerLink]="subLink.link ? subLink.link : undefined"
                    [routerLinkActive]="subLink.link ? 'active' : ''"
                    (click)="subLink.action ? subLink.action() : undefined"
                  >
                    <i *ngIf="subLink.icon" class="fa {{ subLink.icon }} mr-2"></i>
                    <span translate>{{ subLink.heading }}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </mat-sidenav>

    <mat-sidenav-content>
      <lib-navbar
        #navbar
        *ngIf="!navbarConfig.hideNavbar"
        [class.d-none]="navbarConfig.hideNavbar"
        (navbarHeight)="setContainerMarginTop($event)"
        (sidenavToggle)="sidenav.toggle()"
        (topMenuExpanded)="topMenuChange($event)"
      ></lib-navbar>
      <div id="app-wrapper-content-container" class="wrapper-content-container" (click)="collapseTopMenu()">
        <div class="main" [ngClass]="showFullScreen()">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <ng-template [libCustomHostDynamic]="footerComponent" [inputs]="{}" [outputs]="{}" />
</div>
<ng-template #noApp>
  <div class="wrapper d-flex align-items-center justify-content-center m-5">
    <div *ngIf="isLoading && !errorOccured">
      <i class="fa fa-spinner fa-spin fa-7x"></i>
    </div>
    <div *ngIf="errorOccured">
      <h1 class="text-center">An error occured, please log out and contact your supplier</h1>
      <div class="d-flex justify-content-center">
        <button class="btn btn-primary" (click)="logout()" translate>LOGOUT</button>
      </div>
    </div>
  </div>
  <div class="dev-mode" *ngIf="actuator">
    <div>Dev Mode</div>
    <div class="error">
      Check Console
    </div>
    <button class="dev-btn"  (click)="enableDev()" translate>Enable</button>
  </div>
</ng-template>

<div class="dev-mode" *ngIf="devMode">
  <span>Dev Mode </span>
  <button class="dev-btn" (click)="disableDev()" translate>Disable</button>
</div>

<div class="mockroles-warning">
  <lib-mockroles-warning></lib-mockroles-warning>
</div>

<lib-gung-notification></lib-gung-notification>

<div *ngIf="cookiesEnabled">
  <lib-gung-cookie-consent></lib-gung-cookie-consent>
</div>
