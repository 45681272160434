import { Component, OnInit } from '@angular/core';
import { ProductSelectDropdownComponent, ProductService, ProductListConfigService, AuthService, ProductFastSearchListConfigService } from 'gung-standard';
import { Observable, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';

@Component({
  selector: 'jeeves-product-select-dropdown',
  templateUrl: './jeeves-product-select-dropdown.component.html',
  styleUrls: ['./jeeves-product-select-dropdown.component.css']
})
export class JeevesProductSelectDropdownComponent extends ProductSelectDropdownComponent implements OnInit {

  constructor(
    protected productService: ProductService,
    protected productListConfig: ProductListConfigService,
    protected authService: AuthService,
    protected productFastSearchListConfigService: ProductFastSearchListConfigService
  ) {
    super(productService, productListConfig, authService)
  }

  search = (searchTerm$: Observable<string>) => {
    return searchTerm$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.productFastSearchListConfigService.getItems([term], 0, 10).pipe(
          map(products =>
          products.items.filter(product => {
            let hasHitAllTerms = true;
            const queryTerms = term.split(' ');
            const terms = this.productListConfig.getSearchTerms(product);
            queryTerms.forEach(queryTerm => {
              const locatedTerm = terms.find(searchTerm => {
                return !!searchTerm && searchTerm.toUpperCase().indexOf(queryTerm.toUpperCase()) >= 0;
              });

              hasHitAllTerms = hasHitAllTerms && !!locatedTerm;
            });
            return hasHitAllTerms;
          })
          .slice(0, 10)
        ))
      )
    );
  };

}
