import { Component } from '@angular/core';
import { PimVariantsListConfigService } from '../../services/pim-variants-list-config/pim-variants-list-config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractSelectionService } from 'gung-list';
import { PimService } from '../../services/pim/pim.service';
import { ProductSelectionService } from '../../services/product-selection-service/product-selection.service';
import { PimWrapperComponent } from '../pim/pim-wrapper/pim-wrapper.component';

@Component({
  selector: 'lib-pim-variants-wrapper',
  templateUrl: './pim-variants-wrapper.component.html',
  styleUrls: ['./pim-variants-wrapper.component.css'],
  providers: [
    {
      provide: AbstractSelectionService,
      useClass: ProductSelectionService
    }
  ]
})
export class PimVariantsWrapperComponent extends PimWrapperComponent {
  constructor(
    public pimListConfigService: PimVariantsListConfigService,
    protected modalService: NgbModal,
    protected pimService: PimService
  ) {
    super(
      pimListConfigService,
      modalService,
      pimService
    );
  }
}
