import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { AuthService } from '../../services/auth/auth.service';
import { first, forkJoin, of, switchMap } from 'rxjs';
import { ProductService } from '../../services/products/product.service';
import { PriceService } from '../../services/price/price.service';
import { AvailabilityService } from '../../services/availability/availability.service';

@Component({
  selector: 'lib-carousel-product-grid-view',
  templateUrl: './carousel-product-grid-view.component.html',
  styleUrls: ['./carousel-product-grid-view.component.scss']
})
export class CarouselProductGridViewComponent implements OnInit {
  // Add this imports to styles.scss of each customer that uses this component:
  // @import "../../../node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css";
  // @import "../../../node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css";

  @Input()
  productIds: string[] | string;

  @Input()
  products: any;
  isSales: boolean = false;
  includeAvailability: boolean = false;

  constructor(
    protected authService: AuthService,
    protected gungFlowService: GungFlowService,
    protected productService: ProductService,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService
  ) {}

  ngOnInit(): void {
    if (this.productIds?.length > 0 && !this.products) {
      this.products = [];
      const productIds = Array.isArray(this.productIds) ? this.productIds : this.productIds.split(',');
      this.productService.getProductsByIds(productIds).pipe(
        first(),
        switchMap(products => forkJoin({
          products: of(products),
          prices: this.priceService.getCurrentCustomerPrices(productIds).pipe(first()),
          availabilities: this.availabilityService.getAvailabilities(productIds).pipe(first())
        }))
      ).subscribe(({ products, prices, availabilities }) => {
        for (const product of products) {
          const price = prices.find(p => p.productId === product.id);
          const av = availabilities.find(a => a.productId === product.id);
  
          this.products.push({
            product: product,
            price: price,
            availability: av
          });
        }
      });
    }

    this.authService
    .getRoles()
    .pipe(first())
    .subscribe(roles => {
      this.isSales =
        roles.filter(role => role.toUpperCase() === 'ADMIN' || role.toUpperCase() === 'SALES').length > 0;
    });

    this.gungFlowService.getSelectedFlow().pipe(first()).subscribe(
      flow => {this.includeAvailability = flow.useAvailabilities || flow.requireAvailability;}
    )
  }

  customOptions: OwlOptions = {
    lazyLoad: true,
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'],
    margin: 10,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  };
}
