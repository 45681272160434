import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first, switchMap, forkJoin, of, map } from 'rxjs';
import { OrderRow } from '../../models/orderRow';
import { CartService } from '../cart/cart.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { GungStringConverterService } from '../gung-string-converter.service';
import { OfferService } from '../offers/offer.service';
import { ProductService } from '../products/product.service';

@Injectable({
  providedIn: 'root'
})
export class OfferToBasketService {
  constructor(
    protected cartService: CartService,
    protected offerService: OfferService,
    protected productService: ProductService,
    protected modalService: GungModalService,
    protected translationService: TranslateService
  ) {}

  protected addRow(row: OrderRow, position: number) {
    const extra = this.buildExtraToCopy(row);
    this.cartService.addToCart(row.productId, row.quantity, undefined, undefined, undefined, extra, position);
  }

  // In some cases we will need to copy properties from the row into the cart to get correct behaviour.
  // This function is supposed to be the function that brings those values out from the rows, and builds the extra
  // to add to the new cart rows.
  protected buildExtraToCopy(row: OrderRow): { [s: string]: any } | undefined {
    // TODO When we move the product configuration stuff from Lex to standard, use the below helper service instead
    // productConfigurationCartRowService.isProductConfiguration()
    const result = {};
    if (!!row.extra.productConfigurationSelectedOptions) {
      // We want to bind the productConfigurationSelectedOptions in order to be able to correctly create those
      // configurations again when we copy the carts.
      result['productConfigurationSelectedOptions'] = row.extra.productConfigurationSelectedOptions;
    }

    if (Object.keys(result).length == 0) {
      // Return undefined when we have no keys only to keep behaviour similar to how it was before this change.
      return undefined;
    } else {
      return result;
    }
  }

  public getExcludedProductIds(): string[] {
    return [];
  }

  public async addOfferToBasket(offerId: string): Promise<void> {
    const excluded = this.getExcludedProductIds();
    // check if each product in the offer is blocked
    // add the product to the cart only when is not blocked
    this.offerService
      .getOffer(offerId)
      .pipe(
        first(),
        switchMap(offer =>
          forkJoin({
            offer: of(offer),
            blockedProducts: this.productService.getBlockedProducts(offer.rows.map(row => row.productId)).pipe(first())
          })
        ),
        map(({ offer, blockedProducts }) => {
          this.modalService
            .openConfirmYesNoModal(undefined, this.translationService.instant('LOAD_ORDER_CART_CONFIRMATION'), {
              size: 'sm'
            })
            .then(result => {
              const blocked: string[] = [];
              const offerRows: OrderRow[] = [];
              if (result) {
                this.cartService.clearCart();

                for (const row of offer.rows) {
                  // Enable exclusion of specific product ids, such as freight products
                  if (excluded.includes(row.productId)) {
                    continue;
                  }

                  if (!blockedProducts[GungStringConverterService.toGungString(row.productId)]) {
                    offerRows.push(row);
                  } else {
                    blocked.push(GungStringConverterService.toGungString(row.productId));
                  }
                }
              }
              if (blocked.length > 0) {
                this.modalService
                  .openConfirmYesNoModal(
                    this.translationService.instant('EXCLUDED_PRODUCTS'),
                    this.translationService.instant('EXCLUDED_PRODUCTS_MESSAGE', { products: blocked.join(', ') }),
                    null,
                    'OK',
                    null
                  )
                  .then(proceed => {
                    if (proceed) {
                      offerRows.forEach((offerRow, index) => this.addRow(offerRow, index));
                    }
                  });
              } else {
                offerRows.forEach((offerRow, index) => this.addRow(offerRow, index));
              }
            });
        })
      )
      .toPromise();
  }
}
