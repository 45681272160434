import { Component, OnInit } from '@angular/core';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { TableRecord } from '../../../state/metadata/types';
import { gungComparatorHelper } from '../../../utils/gung-utils';
import {SupplierService} from "../../../services/suppliers/supplier.service";
import {first, forkJoin, mergeMap, of, switchMap} from "rxjs";
import {SelectedSupplierService} from "../../../services/selected-supplier/selected-supplier.service";

export interface SupplierPortalMetadata extends TableRecord{
  status?: string;
}

@Component({
  selector: 'lib-supplier-start-page',
  templateUrl: './supplier-start-page.component.html',
  styleUrls: ['./supplier-start-page.component.css']
})
export class SupplierStartPageComponent implements OnInit {

  postatus: [TableRecord];
  newStatusList: [];

  constructor(
    protected metadataService: MetadataService,
    protected supplierService: SupplierService,
    protected selectedSupplierService: SelectedSupplierService
  ) { }

  ngOnInit(): void {
    this.postatus = this.metadataService.getMetadataTableList('POSTATUS');
    this.postatus = Array(...this.postatus).sort((a, b) => gungComparatorHelper(a.id, b.id, 1)) as any
    this.mapStatus();
  }

  mapStatus(){
    this.selectedSupplierService.getSelectedSupplier()
      .pipe(
        first(),
        switchMap(selectedSupplier =>
          this.supplierService.getSupplierPurchaseOrderStatus(selectedSupplier.id).pipe(first())
        )
      )
      .subscribe(orderStatus => {
        this.newStatusList = orderStatus
      });

  }

  hasNewData(id){
    if(!this.newStatusList){
      return
    }
    return this.newStatusList[id];
  }
}
