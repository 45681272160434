import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GungCustomerAddress, GungCustomerAddressService } from '../../services/gung-customer-address.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-customer-add-gung-address-modal',
  templateUrl: './customer-add-gung-address-modal.component.html',
  styleUrls: ['./customer-add-gung-address-modal.component.css']
})
export class CustomerAddGungAddressModalComponent implements OnInit {
  
  public customerId: string;
  deliveryLocationForm: FormGroup;
  isAddingContact = false;
  gungAddress: GungCustomerAddress;
  btnString = 'ADD_ADDRESS';

  get formControls() {
    return this.deliveryLocationForm.controls;
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected gungCustomerAddressService: GungCustomerAddressService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.initForm();
    if (this.gungAddress) {
      this.btnString = 'EDIT_ADDRESS';
    }
  }

  initForm() {
    this.deliveryLocationForm = this.formBuilder.group({
      name: this.formBuilder.control(this.gungAddress?.name || ''),
      address1: this.formBuilder.control(this.gungAddress?.address1 || ''),
      address2: this.formBuilder.control(this.gungAddress?.address2 || ''),
      phoneNumber: this.formBuilder.control(this.gungAddress?.phoneNumber || ''),
      email: this.formBuilder.control(this.gungAddress?.email || ''),
      city: this.formBuilder.control(this.gungAddress?.city || ''),
      zipCode: this.formBuilder.control(this.gungAddress?.zipCode || ''),
      countryCode: this.formBuilder.control(this.gungAddress?.countryCode || ''),
      id: this.gungAddress?.id || ''
    });
  }

  crossClick() {
    this.activeModal.dismiss('Cross click');
  }

  close(id: string = '') {
    this.activeModal.close(id);
  }

  submitForm() {
    this.isAddingContact = true;
    /* this.deliveryLocationForm.valid */
    const newCustomerLocation: GungCustomerAddress = {
      customerId: this.customerId,
      ...this.deliveryLocationForm.getRawValue()
    };
    this.gungCustomerAddressService.addCustomerDeliveryAddresses(this.customerId, newCustomerLocation).subscribe(res => {
      this.isAddingContact = false;
      /*    this.deliveryLocationService.updateCustomerLocationSubject(this.customer.id); */
      this.close(res?.['id'] || '');
    });
  }
}
