import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckoutStepComponent, Customer, SelectedCustomerService, AuthService } from 'gung-standard';
import { forkJoin, of } from 'rxjs';
import { first, mergeMap } from 'rxjs';
import { JeevesDeliveryLocation } from '../../../../models/jeevesDeliveryLocation';
import { DeliveryLocationsService } from '../../../../services/delivery-locations/delivery-locations.service';

@Component({
  selector: 'gung-jeeves-delivery-locations-jeeves',
  templateUrl: './delivery-locations-jeeves.component.html',
  styleUrls: ['./delivery-locations-jeeves.component.css']
})
export class DeliveryLocationsJeevesComponent extends CheckoutStepComponent implements OnInit {
  deliveryLocations: JeevesDeliveryLocation[] = [];
  selectedDeliveryLocation: JeevesDeliveryLocation;
  selectedCustomer: Customer;
  postalCode: string;
  city: string;
  levDat: Date;
  public form: FormGroup;
  public formOh: FormGroup;

  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected deliveryLocationsService: DeliveryLocationsService,
    protected formBuilder: FormBuilder,
    protected authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked());
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(
        first(),
        mergeMap(selectedCustomer =>
          forkJoin([
            of(selectedCustomer),
            this.deliveryLocationsService.getDeliveryLocationsForCustomer(selectedCustomer.id)
          ])
        )
      )
      .subscribe(([selectedCustomer, deliveryLocations]) => {
        this.selectedCustomer = selectedCustomer;
        this.initForm();
        this.deliveryLocations = [...deliveryLocations];

        if (this.selectedCustomer.extra.kus.ordlevplats1) {
          const deliveryLocation = this.deliveryLocations.find(location => {
            return location.extra.lp.ordlevplats1 === this.selectedCustomer.extra.kus.ordlevplats1;
          });
          this.deliveryLocationId = deliveryLocation.id;
        } else {
          this.deliveryLocations.unshift({
            id: this.selectedCustomer.id,
            name: this.selectedCustomer.name,
            extra: this.selectedCustomer.extra,
            timestamp: new Date()
          });
          this.deliveryLocationId = this.deliveryLocations[0].id;
        }
      });
  }

  public get deliveryLocationId(): string {
    return this.selectedDeliveryLocation.id;
  }

  public set deliveryLocationId(deliveryLocationId: string) {
    // https://github.com/microsoft/TypeScript/issues/338
    // SHould not implement logic on setter, rather delegate it to method in order to allow overrides
    this.afterDeliveryLocationSet(deliveryLocationId);
  }

  protected handleNextButtonClicked(): void {
    if (this.formOh.invalid) {
      this.formOh.markAllAsTouched();
      return;
    }
    if (this.form.invalid) {
      return;
    }

    this.assignPostalCodeAndCity(this.postalCode, this.city);
    this.assignFormValues(this.form, this.formOh);
    this.stepDone.emit(this.checkout);
  }

  protected assignPostalCodeAndCity(postalCode: string, city: string): void {
    this.checkout.extra.procargs.ordlevadr4 = ((this.postalCode || '') + ' ' + (this.city || '')).trim();
  }

  protected initForm() {
    this.form = this.formBuilder.group({
      ordlevadr1: this.formBuilder.control(this.checkout.extra.procargs.ordlevadr1 || '', {
        validators: [Validators.required]
      }),
      ordlevadr2: this.formBuilder.control(this.checkout.extra.procargs.ordlevadr2 || ''),
      ordlevadr3: this.formBuilder.control(this.checkout.extra.procargs.ordlevadr3 || ''),
      ftgpostnr: this.formBuilder.control(this.checkout.extra.procargs.ftgpostnr || ''),
      ordlevadrlandskod: this.formBuilder.control(this.checkout.extra.procargs.ordlevadrlandskod || '')
    });

    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => {
        this.formOh = this.formBuilder.group({
          kundref2: this.formBuilder.control(this.checkout.extra.oh.kundref2 || user.name, [Validators.required]),
          kundbestnr: this.formBuilder.control(this.checkout.extra.oh.kundbestnr || '', [Validators.required]),
          editext: this.formBuilder.control(this.checkout.extra.oh.editext || '')
        });
      });
  }

  protected assignFormValues(form: FormGroup, formOh: FormGroup) {
    const rawFormData = form.getRawValue();
    this.checkout.extra.procargs = {
      ...this.checkout.extra.procargs,
      ...rawFormData
    };

    const rawFormOhData = formOh.getRawValue();
    this.checkout.extra.oh = {
      ...this.checkout.extra.oh,
      ...rawFormOhData
    };
  }

  getLabel(): string {
    return 'DELIVERY_ADDRESS';
  }

  protected afterDeliveryLocationSet(deliveryLocationId: string) {
    const newDeliveryLocation = this.deliveryLocations.find(location => location.id === deliveryLocationId);
    if (!newDeliveryLocation) {
      console.error(new Error(`Could not find delivery location: ${deliveryLocationId}`));
      return;
    }
    this.selectedDeliveryLocation = newDeliveryLocation;

    if (this.selectedDeliveryLocation.extra.fr.ftgpostadr5) {
      this.checkout.extra.procargs = this.checkout.extra.procargs || {};
      this.checkout.extra.procargs.ordlevadr1 = this.selectedDeliveryLocation.extra.fr.ftgnamn;
      this.checkout.extra.procargs.ordlevadr2 = this.selectedDeliveryLocation.extra.fr.ordlevadr5;
      this.checkout.extra.procargs.ordlevadr3 = this.selectedDeliveryLocation.extra.fr.ftgpostadr5;
      this.checkout.extra.procargs.ordlevadr4 = this.selectedDeliveryLocation.extra.fr.ftgpostlevadr3;
      this.checkout.extra.procargs.ordlevadrlandskod = this.selectedDeliveryLocation.extra.fr.landskod;
      this.checkout.extra.procargs.ordlevadrprovincecode = this.selectedDeliveryLocation.extra.fr.provincecode;
    } else {
      this.checkout.extra.procargs = this.checkout.extra.procargs || {};
      this.checkout.extra.procargs.ordlevadr1 = this.selectedDeliveryLocation.extra.fr.ftgnamn;
      this.checkout.extra.procargs.ordlevadr2 = this.selectedDeliveryLocation.extra.fr.ftgpostadr1;
      this.checkout.extra.procargs.ordlevadr3 = this.selectedDeliveryLocation.extra.fr.ftgpostadr2;
      this.checkout.extra.procargs.ordlevadrlandskod = this.selectedDeliveryLocation.extra.fr.landskod;
      this.checkout.extra.procargs.ordlevadrprovincecode = this.selectedDeliveryLocation.extra.fr.provincecode;
    }

    this.postalCode = this.selectedDeliveryLocation.extra.fr.ftgpostnr;
    this.city =
      this.selectedDeliveryLocation.extra.fr.ftgpostlevadr3 || this.selectedDeliveryLocation.extra.fr.ftgpostadr3;

    this.assignPostalCodeAndCity(this.postalCode, this.city);

    const procargsKeys = Object.keys(this.form.getRawValue());

    const valueMap = {};
    procargsKeys.forEach(key => {
      valueMap[key] = this.checkout.extra.procargs[key] || '';
    });

    this.form.setValue(valueMap);
  }
}
