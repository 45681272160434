import { Injectable } from '@angular/core';
import { JeevesInvoiceListConfigService } from '../jeeves-invoice-list-config/jeeves-invoice-list-config.service';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceService, SelectedCustomerService, Invoice } from 'gung-standard';
import { ListLayout, ListLayoutMultipleComponent, ListLayoutSingleComponent } from 'gung-list';
import { JeevesInvoiceTableComponent } from '../../components/jeeves-invoice-table/jeeves-invoice-table.component';
import { Observable, of, forkJoin } from 'rxjs';
import { CustomerService } from 'gung-standard';
import { take, switchMap, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JeevesInvoiceListTableConfigService extends JeevesInvoiceListConfigService {
  constructor(
    protected translationService: TranslateService,
    protected invoiceService: InvoiceService,
    protected selectedCustomerService: SelectedCustomerService
  ) {
    super(translationService, invoiceService, selectedCustomerService);
  }

  getItems(): Observable<Invoice[]> {
    const itemsObs = super.getItems();
    return itemsObs.pipe(
      map(results =>
        results.sort((a, b) => {
          return a.extra.fh.faktdat < b.extra.fh.faktdat ? 1 : -1;
        })
      )
    );
  }

  getLayouts(): ListLayout<Invoice>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => JeevesInvoiceTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Invoice grid'
      }
    ];
  }
}
