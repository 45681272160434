import { Component } from '@angular/core';
import { OpenOrderTableComponent } from 'gung-standard';

@Component({
  selector: 'gung-jeeves-jeeves-open-order-table-list',
  templateUrl: './jeeves-open-order-table-list.component.html',
  styleUrls: ['./jeeves-open-order-table-list.component.css']
})
export class JeevesOpenOrderTableListComponent extends OpenOrderTableComponent{


  classSelector(key: number) {
    if (key === 13) {
      return {
        registered: true
      };
    }
    if (key === 11) {
      return {
        quote: true
      };
    }
    if (key === 10) {
      return {
        registrationInProgress: true
      };
    }
    if (key === 12) {
      return {
        blocked: true
      };
    }
    if (key === 5) {
      return {
        webRegistrationInProgress: true
      };
    }

    return {};
  }
}
