import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, interval, startWith, Subscription, switchMap } from 'rxjs';
import { SimplifiedOrderContext } from '../../models/simplifiedOrderContext';
import { OrderContextService } from '../../services/order-context.service';

@Component({
  selector: 'lib-offer-confirmation',
  templateUrl: './offer-confirmation.component.html',
  styleUrls: ['./offer-confirmation.component.css']
})
export class OfferConfirmationComponent implements OnInit {

  public context?: SimplifiedOrderContext;

  timeInterval: Subscription;
  public error?: any;
  public processing = true;

  constructor(protected orderContextService: OrderContextService, protected router: ActivatedRoute) {}

  ngOnInit() {
    const contextId = this.router.snapshot.params.contextId;

    this.timeInterval = interval(1000)
      .pipe(
        startWith(0),
        switchMap(() => this.orderContextService.getSimplifiedOrderContext(contextId)),
        first(e => e.finalized || (e.erpOrderIds && e.erpOrderIds.length > 0))
      )
      .subscribe({
        next:(context) => {
          this.processing = false;
          this.context = context;
        },
        error: (e) => {
          console.log('err', e);
        }
      });
  }

}
