import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartDataset } from 'chart.js';
import { Subject, takeUntil } from 'rxjs';
import { DashboardKpiService } from '../../../services/dashboard-kpi.service';
import { InvoiceStatistic } from '../../../types';

@Component({
  selector: 'lib-invoice-statistics-chart',
  templateUrl: './invoice-statistics-chart.component.html',
  styleUrls: ['./invoice-statistics-chart.component.css']
})
export class InvoiceStatisticsChartComponent implements OnInit, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();

  @Input()
  invoices: InvoiceStatistic[];

  @Input()
  preConditions: any;

  chart: any;

  public lineChartData: ChartDataset[];
  public lineChartLabels: string[][];
  public lineChartOptions: ChartConfiguration<'bar'>['options'];
  public lineChartLegend = true;
  public lineChartType = 'bar';

  data = [];
  loaded = false;

  constructor(protected translateService: TranslateService, protected dashboardKpiService: DashboardKpiService) {}

  ngOnInit() {
    this.dashboardKpiService
      .getMonthlyChartSummary(this.preConditions)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        res.labels.map(label => {
          label[0] = this.translateService.instant(label[0]);
        });
        this.lineChartLabels = res.labels;

        this.data.push(res.thisYear);
        this.data.push(res.lastYear);
        this.data.push(res.twelveMonthTotal);

        this.initChartOptions();
        this.loaded = true;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initChartOptions(): void {
    this.lineChartData = [
      {
        label: this.translateService.instant('12_MONTH_TOTAL'),
        data: this.data[2],
        type: 'line',
        borderWidth: 2,
        yAxisID: 'y-axis-2',
        backgroundColor: 'rgba(255,99,132, 0.0)'
      },
      {
        label: this.translateService.instant('LAST_YEAR'),
        data: this.data[1],
        borderWidth: 0,
        yAxisID: 'y-axis-1',
        backgroundColor: '#ced4da'
      },
      {
        label: this.translateService.instant('CURRENT_YEAR'),
        data: this.data[0],
        borderWidth: 0,
        yAxisID: 'y-axis-1',
        backgroundColor: '#6c757d'
      }
    ];

    this.lineChartOptions = {
      plugins: {
        tooltip: {
          enabled: true,
          mode: 'index',
          callbacks: {
            label: context => {
              return context.dataset.data[context.dataIndex].toLocaleString();
            }
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0
      },
      scales: {
        'y-axis-1': {
          type: 'linear',
          display: true,
          position: 'left',
          title: {
            display: true,
            text: this.translateService.instant('MONTHLY_BARS'),
            font: {
              size: 20
            }
          }
        },
        'y-axis-2': {
          type: 'linear',
          display: true,
          grid: {
            display: false
          },
          position: 'right',
          title: {
            display: true,
            text: this.translateService.instant('12_MONTH_TOTAL_LINE'),
            font: {
              size: 20
            }
          }
        }
      }
    };
  }
}
