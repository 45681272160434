import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  CustomerService,
  ProductService,
  AuthService,
  PriceService,
  CartRow,
  TotalObject,
  SelectedCustomerService,
  CartTotalsService,
  Customer,
  CheckoutObject,
  Product,
  GungGoogleTagManagerService
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { JeevesDeliveryAddressesService, SummarizeOrderJeevesComponent } from 'gung-standard-jeeves';
import { Observable, Subject, first, takeUntil } from 'rxjs';

@Component({
  selector: 'sjoborg-summarize-order-jeeves',
  templateUrl: './sjoborg-summarize-order-jeeves.component.html',
  styleUrls: ['./sjoborg-summarize-order-jeeves.component.scss']
})
export class SjoborgSummarizeOrderJeevesComponent extends SummarizeOrderJeevesComponent implements OnInit, OnDestroy {
  @Input()
  public onNextAlternativeBtnClicked: Observable<void>;

  private shipping: Product;
  private total: TotalObject;
  private freightCostProductId = '902';
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected customerService: CustomerService,
    protected productService: ProductService,
    protected authService: AuthService,
    protected priceService: PriceService,
    private selectedCustomerService: SelectedCustomerService,
    private cartTotalService: CartTotalsService,
    dateUtilService: DateUtilService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService,
    @Optional()
    protected gungGoogleTagManagerService: GungGoogleTagManagerService
  ) {
    super(customerService, productService, authService, priceService, dateUtilService, jeevesDeliveryAddressesService, gungGoogleTagManagerService);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit() {
    super.ngOnInit();

    this.cartTotalService
      .getTotals()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(totalsObject => {
        if (!!totalsObject && totalsObject.totalValue > 0) {
          this.productService.getProduct(this.freightCostProductId).subscribe(product => {
            this.shipping = product;
            this.total = totalsObject;
            this.selectedCustomerService
              .getSelectedCustomer()
              .pipe(first())
              .subscribe(customer => {
                this.calculateFreightCost(customer);
              });
          });
        }
      });
    this.onNextAlternativeBtnClicked.subscribe(() => {
      this.stepDone.emit({
        ...this.checkout,
        extra: { ...this.checkout.extra, oh: { ...this.checkout.extra.oh, ordstat: 0 } }
      });
    });
  }

  private calculateFreightCost(selectedCustomer: Customer): void {
    // If ordervalue is below 2000 SEK or 200 EUR add item id 900 with price 196 SEK or 28 EUR.
    let freightCostValue = 0;
    if (!!selectedCustomer.extra.kus) {
      if (selectedCustomer.extra.kus.valkod === 'SEK' && this.total.totalValue < 2000) {
        freightCostValue = this.shipping.extra.ar.artlistpris;
      } else if (selectedCustomer.extra.kus.valkod === 'EUR' && this.total.totalValue < 200) {
        freightCostValue = 28;
      }
    }

    delete this.checkout.extra.additionalCosts;

    // has freight ?
    if (freightCostValue > 0) {
      this.checkout.extra.additionalCosts = {
        ...this.checkout.extra.additionalCosts,
        freight: {
          productId: this.freightCostProductId,
          name: this.shipping.name,
          value: freightCostValue,
          currencyCode: selectedCustomer.extra.kus.valkod,
          quantity: 1
        }
      };
    }
  }

  public getBtnTexts(checkout: CheckoutObject): { next: string | void; prev: string | void; nextAlternative: string | void } | void {
    return {
      next: 'SUBMIT_ORDER',
      nextAlternative: 'SEND_QUOTE',
      prev: null
    };
  }
}
