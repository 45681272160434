import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { gungGetPropertyOfObject } from '../../utils/gung-utils';
import { OptionsList } from '../gung-select-option-input/gung-select-option-input.component';

export interface OptionsListMulti extends OptionsList {
  selected: boolean;
}

@Component({
  selector: 'lib-gung-input-multi-select',
  templateUrl: './gung-input-multi-select.component.html',
  styleUrls: ['./gung-input-multi-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungInputMultiSelectComponent),
      multi: true
    }
  ]
})
export class GungInputMultiSelectComponent implements OnInit, OnChanges {
  @Input()
  public label: string;

  @Input()
  public showId = true;

  @Input()
  previewEmpty = 'Choose available options';

  @Input()
  public tooltip?: string;
  @Input('optionsList') set setOptionList(value: OptionsListMulti[]) {
    this.optionsList = JSON.parse(JSON.stringify(value));
    this.ngOnInit();
  }
  @Input()
  public previewField?: string | string[];

  @Input()
  public dropdownCustomClass: string;

  @Input()
  public optionsList: OptionsListMulti[];
  optionsFilterList: OptionsListMulti[];

  @Output()
  selectedValues = new EventEmitter<string[]>();

  @Output()
  selectedValuesClose = new EventEmitter<string[]>();
  changedBeforeClose = false;

  dropdownIsOpen = false;
  searchString: string;
  currentSelectedValues: string[];

  previewList: string[];

  @Input()
  singleSelection: boolean;

  @Input()
  disabled: boolean;

  @ViewChild('dropDownEle')
  public dropDownEle: NgbDropdown;

  @Input()
  focusInput?: boolean;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.optionsFilterList = this.optionsList;
    this.currentSelectedValues = this.optionsList.filter(o => o.selected).map(o => o.id);
    this.updatePreview();
  }

  ngOnInit(): void {
    this.optionsFilterList = this.optionsList;
    this.currentSelectedValues = this.optionsList.filter(o => o.selected).map(o => o.id);
    this.updatePreview();
  }

  private updatePreview() {
    this.previewList = this.optionsList
      .filter(o => this.currentSelectedValues.includes(o.id))
      .map(o => {
        if (this.previewField) {
          if (Array.isArray(this.previewField)) {
            return this.previewField.map(field => {
              if (['', ' ', ',', ', ', ' - ', '-'].includes(field)) {
                return field;
              }
              return gungGetPropertyOfObject(field, o);
            }).join('');
          }
          return o[this.previewField];
        }
        return o.id;
      });
  }

  setSearch(search: string): void {
    this.searchString = search;
    if (!search || search.trim() === '') {
      this.optionsFilterList = this.optionsList;
      return;
    }
    this.optionsFilterList = this.filterBySearchTerm(search, this.optionsList);
  }

  filterBySearchTerm(searchTerm: string, items: OptionsList[]): any[] {
    return items.filter(item => {
      let hasHitAllTerms = true;
      const queryTerms = searchTerm.split(' ');
      const terms = [item.id, item.name];
      if (this.previewField) {
        let previewValue = undefined;
        if (Array.isArray(this.previewField)) {
          previewValue = this.previewField.map(field => {
            if (['', ' ', ',', ', ', ' - ', '-'].includes(field)) {
              return field;
            }
            return gungGetPropertyOfObject(field, item);
          }).join('');
        } else {
          previewValue = item[this.previewField];
        }
        terms.push(previewValue);
      }
      queryTerms.forEach(queryTerm => {
        const locatedTerm = terms.find(term => {
          if (term === null || term === undefined) {
            return false;
          }
          return term.toUpperCase().indexOf(queryTerm.toUpperCase()) >= 0;
        });

        hasHitAllTerms = hasHitAllTerms && !!locatedTerm;
      });

      return hasHitAllTerms;
    });
  }

  setSelectValue(valueId: string, selected: boolean) {
    if (this.singleSelection) {
      this.currentSelectedValues = [valueId];
      this.optionsList.forEach(o => o.selected = o.id === valueId);
      this.dropDownEle.close();
    }
    else if (!selected) {
      this.currentSelectedValues = this.currentSelectedValues.filter(id => id !== valueId);
    } else {
      if (!this.currentSelectedValues.includes(valueId)) {
        this.currentSelectedValues.push(valueId);
      }
    }
    this.updatePreview();
    this.selectedValues.emit(this.currentSelectedValues);
    this.changedBeforeClose = true;
  }

  openChange(event: boolean) {
    this.dropdownIsOpen = event;
    if (!event && this.changedBeforeClose) {
      this.selectedValuesClose.emit(this.currentSelectedValues);
      this.changedBeforeClose = false;
    }
  }

  getPreviewField(option): string {
    if (this.previewField) {
      if (Array.isArray(this.previewField)) {
        return this.previewField.map(field => {
          if (['', ' ', ',', ', ', ' - ', '-'].includes(field)) {
            return field;
          }
          return gungGetPropertyOfObject(field, option);
        }).join('');
      }
      return option[this.previewField];
    }
    return option.id;
  }
}
