import { Component, OnInit, OnDestroy } from '@angular/core';
import { PimTemplateService, PimTemplate, PimTemplateColumn } from '../../../services/pim-template.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { takeUntil, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RoutingUtilService } from '../../../services/utils/routing-util.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'lib-pim-template-item-editor',
  templateUrl: './pim-template-item-editor.component.html',
  styleUrls: ['./pim-template-item-editor.component.css']
})
export class PimTemplateItemEditorComponent implements OnInit, OnDestroy {
  templatesList: PimTemplate[];
  selectedTemplate: PimTemplate;
  templateId: string;
  editedTemplatesId: string[] = [];
  searchString: string;

  columns: PimTemplateColumn[] = [];
  filteredColumns: PimTemplateColumn[] = [];

  loader: boolean = false;
  enableCreateTemplate: boolean = this.pimTemplateService.enableCreateTemplate;
  alertMessage: Alert;
  unsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected modalService: GungModalService,
    protected pimTemplateService: PimTemplateService,
    protected translationService: TranslateService,
    protected routingUtilService: RoutingUtilService,
    protected router: Router
  ) {}

  ngOnInit(): void {
    window.addEventListener('beforeunload', e => {
      if (!this.forceExit && this.editedTemplatesId.length > 0) {
        const confirmationMessage = 'o/';
        e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
        return confirmationMessage; // Gecko, WebKit, Chrome <34
      }
    });
    this.pimTemplateService.getAllTemplatesSubject().pipe(takeUntil(this.unsubscribe)).subscribe((templates: PimTemplate[]) => {
      this.templatesList = structuredClone(templates);
      this.filteredColumns = [];
      this.columns = [];
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
    window.removeEventListener('beforeunload',  e => {});
  }

  openCreatePIMTemplateModal() {
    this.modalService.openPimTemplateCreateModal().then(
      (result: string) => {
        if (result.localeCompare('Cance click') !== 0) {
          const template = {
            ...JSON.parse(result),
            columns: []
          }
          this.pimTemplateService.createTemplate(template).subscribe(_ => {});
        }
      },
      reason => {}
    );
  }

  openEditPIMTemplateModal() {
    const selectedTemplate = this.templatesList.find(t => t.id === this.templateId);
    this.modalService.openPimTemplateCreateModal(selectedTemplate).then(
      (result: string) => {
        if (result.localeCompare('Cance click') !== 0) {
          const template = {
            ...selectedTemplate,
            ...JSON.parse(result)
          }
          this.pimTemplateService.updateTemplate(template).subscribe(_ => {});
        }
      },
      reason => {}
    );
  }

  setTemplate(templateId: string) {
    this.templateId = templateId;
    const template = this.templatesList.find(t => t.id === templateId);
    this.selectedTemplate = template;
    this.columns = template.columns;
    this.filteredColumns = this.columns;
  }

  setSearch(search: string): void {
    this.searchString = search;
    if (!search || search.trim() === '') {
      this.filteredColumns = this.columns;
      return;
    }
    this.filteredColumns = this.filterBySearchTerm(search, this.columns);
  }

  // COPY from filter-list.service
  private filterBySearchTerm(searchTerm: string, items: any[]): any[] {
    return items.filter(item => {
      let hasHitAllTerms = true;
      const queryTerms = searchTerm.split(' ');
      const terms = [item.columnName];
      queryTerms.forEach(queryTerm => {
        const locatedTerm = terms.find(term => {
          if (term === null || term === undefined) {
            return false;
          }
          return term.toUpperCase().indexOf(queryTerm.toUpperCase()) >= 0;
        });

        hasHitAllTerms = hasHitAllTerms && !!locatedTerm;
      });

      return hasHitAllTerms;
    });
  }

  clickClone(i: number) {
    const item = this.columns[i];
    const newItem = { ...item, columnName: item.columnName + ' (copy)' };
    this.clickEdit(i, newItem);
  }

  clickRemove(i: number) {
    this.columns.splice(i, 1);
    this.unsaved(this.templateId);
  }

  clickEdit(i: number, newColumn?: PimTemplateColumn) {
    if (this.columns.length !== this.filteredColumns.length) {
      i = this.columns.findIndex(r => r === this.filteredColumns[i]);
    }
    this.modalService.openPimTemplateEditorModal(this.columns, i, newColumn).then(
      (result: string) => {
        if (result.localeCompare('Cance click') !== 0) {
          const column = JSON.parse(result) as PimTemplateColumn;
          this.unsaved(this.templateId);
          if (newColumn) {
            this.columns.splice(i + 1, 0, column);
          } else {
            this.columns[i] = column;
          }
          if (this.columns.length !== this.filteredColumns.length) {
            this.setSearch(this.searchString);
          }
        }
      },
      reason => {}
    );
  }

  addRow() {
    const newItem: PimTemplateColumn = {
      additionalTargetPaths: [],
      blankType: null,
      columnName: '',
      path: '',
      required: false,
      targetType: null
    };
    this.clickEdit(this.columns.length - 1, newItem);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.unsaved(this.templateId);
  }

  unsaved(id: string, saved?: boolean) {
    if (saved) {
      // Remove after save
      this.editedTemplatesId = this.editedTemplatesId.filter(t => t !== id);
      return;
    }
    if (this.editedTemplatesId.includes(id)) {
      return;
    }
    this.editedTemplatesId.push(id);
  }

  onSaveTemplate() {
    const template: PimTemplate = this.selectedTemplate;
    const templateName: string = template.id;
    this.loader = true;
    const dataRows: PimTemplateColumn[] = this.columns.map(r => {
      return {
        additionalTargetPaths: r.additionalTargetPaths,
        blankType: r.blankType,
        columnName: r.columnName,
        path: r.path,
        required: r.required,
        targetType: r.targetType
      };
    });

    const dataTemplate: PimTemplate = {
      ...template,
      columns: dataRows
    };

    this.pimTemplateService.updateTemplate(dataTemplate).subscribe(
      () => {
        this.alertMessage = {
          type: 'success',
          message: 'CHANGE_SAVED_SUCCESS'
        };
        this.unsaved(this.templateId, true);
        this.loader = false;
      },
      () => {
        this.alertMessage = {
          type: 'danger',
          message: 'CHANGE_SAVED_FAIL'
        };
        this.loader = false;
      }
    );
  }

  savedUrl: string;
  forceExit = false;
  hasUnsavedChanges(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean {
    if (this.forceExit) {
      return false;
    }
    if (this.editedTemplatesId.length > 0) {
      this.savedUrl = nextState.url;
      this.exit();
    }
    return this.editedTemplatesId.length > 0;
  }
  exit(): void {
    this.modalService
      .openConfirmYesNoModal(
        undefined,
        'You have data to save. Are you sure you want to leave?',
        { size: 'sm' }
      )
      .then(
        result => {
          this.forceExit = result;
          if (result) {
            window.onpopstate = () => {};
            const pathWithParams = this.routingUtilService.parsePathWithQueryParams(this.savedUrl);
            this.router.navigate([pathWithParams.path], { queryParams: pathWithParams.params });
          }
        },
        reason => {}
      );
  }
}
