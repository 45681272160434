<div class="checkout-error" *ngIf="error; else steps">
  {{ error | json }}
</div>

<ng-template #steps>
  <ng-template #indicator></ng-template>

  <div class="gung-stepper-indicator-offset"></div>
  <div *ngIf="sideButtons; else bottomButtons">
    <span [hidden]="showLoading" class="checkout-step">
      <div class="row">
        <div class="col-sm-10">
          <ng-template #step libCustomHost></ng-template>
        </div>
        <div class="col-md-2 col-sm-4 border-left">
          <ng-template #btns></ng-template>
        </div>
      </div>
    </span>
  </div>
  <ng-template #bottomButtons>
    <span [hidden]="showLoading" class="checkout-step">
      <ng-template #step libCustomHost></ng-template>
    </span>

    <div class="container checkout-step-bottom-buttons-container p-3">
      <div class="row checkout-step-bottom-buttons">
        <div class="col-12">
          <ng-template #btns></ng-template>
        </div>
      </div>
    </div>
  </ng-template>

  <div>
    <div *ngIf="showLoading" class="progress mt-10">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
        role="progressbar"
        aria-valuenow="75"
        aria-valuemin="0"
        aria-valuemax="100"
        style="width: 75%"
      ></div>
    </div>
    <div *ngIf="showLoading && isSubmitting">
      <h2 translate>SUBMITTING_ORDER</h2>
    </div>
  </div>
</ng-template>
