import { Injectable } from '@angular/core';
import { BackendTemplate } from '../../components/custom-page/custom-page-interface/template-component';
import { forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { first, map, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomPageConfigService {
  protected serviceUrl = `json/custom-pages`;

  /** @deprecated use getUserActiveGroupsAsObservable() instead */
  protected userActiveGroups: string[] = [];

  constructor(
    protected http: HttpClient,
    protected authService: AuthService
  ) {
    this.getUserActiveGroups();
  }

  public getDefaultTemplate(type?): Observable<any[]> {
    const templateList = [
      {
        id: 'homedefault', // templateName
        name: 'DEFAULT_HOME'
      },
      {
        id: 'homeassortment',
        name: 'ASSORTMENT_HOME'
      },
      {
        id: 'homefastsearch',
        name: 'FAST_SEARCH_PRODUCT'
      },
      {
        id: 'homefashion',
        name: 'FASHION_HOME'
      },
      {
        id: 'homeslideproductlist',
        name: 'SLIDER_AND_PRODUCT_LIST_HOME'
      },
      {
        id: 'homeimagesandproducts',
        name: 'IMAGES_AND_PRODUCTS_HOME'
      },
      {
        id: 'htmleditor',
        name: 'HTML_EDITOR'
      },
      {
        id: 'faq',
        name: 'FAQ'
      },
      {
        id: 'marketing',
        name: 'MARKETING'
      },{
        id: 'homeherobannerandcategories',
        name: 'HERO_BANNER_AND_CATEGORIES_HOME'
      }
    ];
    return of(templateList);
  }
  public getFallbackLink(): string {
    return '/products';
  }

  getTemplates(): Observable<BackendTemplate[]> {
    const url = this.serviceUrl;
    return this.http.get<BackendTemplate[]>(url);
  }

  getTemplateData(pageName: string): Observable<BackendTemplate> {
    const url = this.serviceUrl + `/${pageName}`;
    return this.http.get<BackendTemplate>(url);
  }

  getTemplateDataByUserGroups(pageName: string): Observable<BackendTemplate> {
    return forkJoin([this.getTemplates().pipe(first()), this.getUserActiveGroupsAsObservable()]).pipe(
      mergeMap(([templateData, userActiveGroups]) => {
        let resultTemplate;
        if (templateData && templateData.length > 0) {
          templateData.forEach(element => {
            if (element.activeForUserGroups?.length > 0) {
              const groupsInCommon = element.activeForUserGroups.filter(o1 => userActiveGroups.some(o2 => o1 === o2));
              if (groupsInCommon && groupsInCommon.length > 0) {
                if (element.pageName === pageName || (!resultTemplate && pageName === 'home')) {
                  resultTemplate = element;
                }
              }
            }
          });
        }
        if (resultTemplate) {
          return of(resultTemplate);
        } else {
          return of(undefined);
        }
      })
    );
  }

  createTemplateData(template: BackendTemplate): Observable<void> {
    const url = this.serviceUrl;
    return this.http.post<void>(url, template);
  }

  updateTemplateData(template: BackendTemplate): Observable<void> {
    const url = this.serviceUrl; // + `/${template.pageName}`;
    return this.http.put<void>(url, template);
  }

  removeTemplateData(template: BackendTemplate): Observable<boolean> {
    const url = this.serviceUrl + `/${template.pageName}`;
    return this.http.delete<boolean>(url);
  }

  uploadImageS3(formData: FormData): Observable<any> {
    const url = `import/s3-image`;
    return this.http.post<any>(url, formData);
  }

  /** @deprecated use getUserActiveGroupsAsObservable() instead */
  getUserActiveGroups() {
    this.authService
      .getUserActiveGroups()
      .pipe(first())
      .subscribe(activeGroups => {
        const result = Object.keys(activeGroups).filter(userGroup => activeGroups[userGroup] === true);
        this.userActiveGroups = result;
      });
  }

  getUserActiveGroupsAsObservable(): Observable<string[]> {
    return this.authService.getUserActiveGroups().pipe(
      first(),
      map(activeGroups => {
        const result = Object.keys(activeGroups).filter(userGroup => activeGroups[userGroup] === true);
        return result;
      })
    );
  }
}
