import { Injectable } from '@angular/core';
import { FeatureActivatedGuardWrapper } from '../../guards/feature-activated/feature-activated.guard';
import { first, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GptErrorExplanationService {
  private BACKEND_URL = 'json/gpt/error-explanation';
  private FEATURE_ID = 'gungGpt';
  private isFeatureEnabled = false;

  constructor(protected featureActivatedGuardWrapper: FeatureActivatedGuardWrapper, protected http: HttpClient) {
    featureActivatedGuardWrapper
      .isActivated(this.FEATURE_ID, '3.0.19')
      .pipe(first())
      .subscribe(enabled => {
        this.isFeatureEnabled = enabled;
      });
  }

  public isEnabled(): boolean {
    return this.isFeatureEnabled;
  }

  public getErrorExplanation(errorMessage: string): Observable<string> {
    return this.http
      .post<{ result: string }>(this.BACKEND_URL, { prompt: errorMessage })
      .pipe(map(response => response.result));
  }
}
