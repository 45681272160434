import { Injectable } from '@angular/core';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import {
  ProductService,
  AuthService,
  ProductSortService,
  MetadataService,
  ProductTreeListConfigService,
  Product,
  GungFlowService,
  DynamicColumnsService,
  CloudPimFiltersService,
  CurrentAssortmentService
} from 'gung-standard';
import { SjoborgProductTreeListViewComponent } from '../components/sjoborg-product-tree-list-view/sjoborg-product-tree-list-view.component';

@Injectable({
  providedIn: 'root'
})
export class SjoborgProductTreeListConfigService extends ProductTreeListConfigService {
  topFilter = true;

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productSortService: ProductSortService,
    protected metadataService: MetadataService,
    protected gungFlowService: GungFlowService,
    protected dynamicColumnsService: DynamicColumnsService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected currentAssortmentService: CurrentAssortmentService
  ) {
    super(productService, authService, productSortService, gungFlowService, metadataService, dynamicColumnsService, cloudPimFiltersService, currentAssortmentService);
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => SjoborgProductTreeListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getSearchGroupCss() {
    return '';
  }
}
