<div *ngFor="let location of data">
  <div class="card-header">
    <div class="row">
      <div class="col my-auto">
        <h5>{{ location.description }}</h5>
      </div>
      <div class="col-auto">
        <button class="btn btn-danger mr-2" type="button" (click)="deleteDeliveryLocation(location)" translate>
          DELETE
        </button>
        <button class="btn btn-primary" type="button" (click)="editDeliveryLocation(location)" translate>
          EDIT_DELIVERY_LOCATION
        </button>
      </div>
    </div>
  </div>

  <div class="card-body text-dark">
    <div class="card-text row">
      <div class="col-12 col-sm-4 mb-2 mb-xs-0">
        <lib-gung-text-input [gungTranslate]="'NAME'" [renderStatic]="true" [(ngModel)]="location.name">
        </lib-gung-text-input>
      </div>
      <div class="col-12 col-sm-4 mb-2 mb-xs-0">
        <lib-gung-text-input [gungTranslate]="'ADDRESS_1'" [renderStatic]="true" [(ngModel)]="location.address1">
        </lib-gung-text-input>
      </div>
      <div class="col-12 col-sm-4 mb-2 mb-xs-0">
        <lib-gung-text-input [gungTranslate]="'ADDRESS_2'" [renderStatic]="true" [(ngModel)]="location.address2">
        </lib-gung-text-input>
      </div>
      <div class="col-12 col-sm-4 mb-2 mb-xs-0">
        <lib-gung-text-input [gungTranslate]="'ZIP_CODE'" [renderStatic]="true" [(ngModel)]="location.zipCode">
        </lib-gung-text-input>
      </div>
      <div class="col-12 col-sm-4 mb-2 mb-xs-0">
        <lib-gung-text-input [gungTranslate]="'CITY'" [renderStatic]="true" [(ngModel)]="location.city">
        </lib-gung-text-input>
      </div>
      <div class="col-12 col-sm-4 mb-2 mb-xs-0">
        <!-- <lib-gung-meta-output
          [gungTranslate]="'COUNTRY'"
          [value]="location.countryCode"
          metalocationTable="ISOCountries2"
          metaOptionName="name"
        ></lib-gung-meta-output> -->
        <lib-gung-text-input [gungTranslate]="'COUNTRY'" [renderStatic]="true" [(ngModel)]="location.countryCode">
        </lib-gung-text-input>

      </div>
      <div class="col-12 col-sm-4 mb-2 mb-xs-0">
        <lib-gung-text-input [gungTranslate]="'PHONE'" [renderStatic]="true" [(ngModel)]="location.phoneNumber">
        </lib-gung-text-input>
      </div>
      <div class="col-12 col-sm-4 mb-2 mb-xs-0">
        <lib-gung-text-input [gungTranslate]="'EMAIL'" [renderStatic]="true" [(ngModel)]="location.email">
        </lib-gung-text-input>
      </div>
    </div>
  </div>
</div>
