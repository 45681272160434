import { Component } from '@angular/core';
import { InvoiceCardListComponent } from 'gung-standard';
import { JeevesInvoiceActionConfigService } from '../../services/jeeves-invoice-action-config/jeeves-invoice-action-config.service';

@Component({
  selector: 'gung-jeeves-jeeves-invoice-card-list',
  templateUrl: './jeeves-invoice-card-list.component.html',
  styleUrls: ['./jeeves-invoice-card-list.component.css']
})
export class JeevesInvoiceCardListComponent extends InvoiceCardListComponent {
  constructor(public configService: JeevesInvoiceActionConfigService) {
    super();
  }

  getStatus(test: string, val: string): string {
    return `${test}${val}`;
  }
}
