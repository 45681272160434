import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtilService, SchedulersMonitorComponent, SchedulersService } from 'gung-common';
import { first } from 'rxjs';
import { SchedulersListConfigService } from '../../services/schedulers-list-config/schedulers-list-config.service';

@Component({
  selector: 'lib-schedulers-monitor-list',
  templateUrl: './schedulers-monitor-list.component.html',
  styleUrls: ['./schedulers-monitor-list.component.css']
})
export class SchedulersMonitorListComponent {
  constructor(
    public schedulersService: SchedulersService,
    protected modalService: NgbModal,
    public dateUtilService: DateUtilService,
    public schedulersConfigService: SchedulersListConfigService
  ) {
  }

  clearAllError() {
    this.schedulersService
      .clearAllErrors()
      .pipe(first())
      .subscribe();
  }
}
