import { Component } from '@angular/core';
import { PlanogramListConfigService } from '../../services/planogram-list-config.service';

@Component({
  selector: 'lib-planogram-main-page',
  templateUrl: './planogram-main-page.component.html',
  styleUrls: ['./planogram-main-page.component.css']
})
export class PlanogramMainPageComponent {
  constructor(protected planogramListConfigService: PlanogramListConfigService ){

  }

}
