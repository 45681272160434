import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { JeevesOpenOrdersConfigService } from '../../services/jeeves-open-orders-config/jeeves-open-orders-config.service';
import { OpenOrdersConfigService } from 'gung-standard';
import { JeevesOpenOrdersListTableConfigService } from '../../services/jeeves-open-orders-list-table-config/jeeves-open-orders-list-table-config.service';

@Component({
  selector: 'gung-jeeves-jeeves-open-orders',
  templateUrl: './jeeves-open-orders.component.html',
  styleUrls: ['./jeeves-open-orders.component.css']
})
export class JeevesOpenOrdersComponent {
  constructor(public config: JeevesOpenOrdersListTableConfigService) {}
}
