import { Injectable } from '@angular/core';
import { ConfigService, ConfigBaseFilter, ListSortOption, ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { Observable, first } from 'rxjs';
import { SupplierGridViewComponent } from '../../components/supplier/supplier-grid-view/supplier-grid-view.component';
import { Supplier } from '../../models';
import { AuthService } from '../auth/auth.service';
import { SupplierService } from '../suppliers/supplier.service';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierListConfigService implements ConfigService<Supplier> {
  constructor(
    public supplierService: SupplierService,
    protected usersService: UsersService,
    protected authService: AuthService
  ) {}

  getItems(): Observable<Supplier[]> {
    return this.supplierService.getSuppliers().pipe(
      first()
    );
  }

  getFilters(): ConfigBaseFilter<Supplier>[] {
    return [];
  }

  getSortOptions(): ListSortOption<Supplier>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<Supplier>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => SupplierGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Supplier list'
      }
    ];
  }

  getSearchTerms(item: Supplier): string[] {
    return [item.id, item.name];
  }

  getItemId(item: Supplier): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
