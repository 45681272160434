import {
  Component,
  OnInit,
  ViewContainerRef,
  ComponentFactoryResolver,
  Injector,
  Input,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { DashboardCard } from '../../types';

@Component({
  selector: 'lib-dashboard-card-creator',
  template: `<div><ng-template #spawn></ng-template></div>`,
  styleUrls: ['./dashboard-card-creator.component.css']
})
export class DashboardCardCreatorComponent implements OnInit, AfterViewInit {
  @ViewChild('spawn', { read: ViewContainerRef }) container;

  constructor(private resolver: ComponentFactoryResolver) { }

  @Input()
  card: DashboardCard;

  ngAfterViewInit() {
    if (!this.card.component) {
      // Add empty line
      return;
    }
    const factory = this.resolver.resolveComponentFactory(this.card.component);
    const ref = this.container.createComponent(factory);
    if (this.card.preConditions) {
      ref.instance.preConditions = this.card.preConditions;
    }
    if (this.card.extra) {
      ref.instance.extra = this.card.extra;
    }
    ref.changeDetectorRef.detectChanges();
  }

  ngOnInit() { }
}
