import { Injectable } from '@angular/core';
import { Product } from '../../../models';
import { ConfigBaseFilter, ConfigFilterGroup, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption, PimTemplateProperties, SearchField, SelectionAction, SimpleConfigBaseFilter } from 'gung-list';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, first, forkJoin, of, switchMap } from 'rxjs';
import { PlanogramService } from './planogram.service';
import { ProductConcept } from '../../../components/concept-detail-v2/concept-detail-v2.component';
import { PlanogramModuleGridComponent } from '../components/planogram-module-grid/planogram-module-grid.component';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { ProductService } from '../../../services/products/product.service';


export class ModuleWidthFilter extends SimpleConfigBaseFilter<ProductConcept> {
  constructor() {
    super();
  }

  getName(): string {
    return 'WIDTH_MM';
  }
  getOptionIds(item: ProductConcept): string[] {
    return [item.extra.pim.spaceManagementWidth];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class ModuleSegmentFilter extends SimpleConfigBaseFilter<ProductConcept> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'SEGMENT';
  }
  getOptionIds(item): string[] {
    if (item.extra?.pim?.itemSegment?.code) {
      return [item.extra.pim.itemSegment.code];
    }
    return [];
  }

  getOptionName(key: string): string {
    return this.metadataService.getMetadataValue('ITEM_SEGMENT', 'description', key);
  }
}

export class ModuleSectionFilter extends SimpleConfigBaseFilter<ProductConcept> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'SECTION';
  }

  getOptionIds(item: ProductConcept): string[] {
    if (item.extra.pim.itemSection?.code) {
      return [item.extra.pim.itemSection.code];
    }
    return [];
  }

  getOptionName(key: string): string {
    return this.metadataService.getMetadataValue('ITEM_SECTION', 'description', key);
  }
}
@Injectable({
  providedIn: 'root'
})
export class PlanogramModulesListConfigService implements ConfigService<ProductConcept> {

  constructor( 
    protected planogramService: PlanogramService, 
    protected metadataService: MetadataService, 
    protected productService: ProductService) {
  }

  topFilter = true;

  getItems(route?: ActivatedRouteSnapshot): Observable<ProductConcept[]> {
    return this.planogramService.getModules().pipe(
      switchMap((modules)=> {
        let allProductsIds = [];
        for (const m of modules) {
          allProductsIds = [...allProductsIds,...m.extra?.SpaceManagementModuleProducts?.map(p=> p.itemId)];
        }
        const productsIds = [...new Set(allProductsIds)];
        
        return forkJoin({
          products: this.productService.getFullProductsByIds(productsIds).pipe(first()),
          modules: of(modules)
        })
      }),
      switchMap(({products, modules}) => {
        for (const module of modules) {
          module.extra._articles = module.extra?.SpaceManagementModuleProducts?.map(p=> p.itemId)
          const modulesProducts = products.filter(m => module.extra._articles.includes(m.id));
          this.setProductsData(module, modulesProducts)
        }
        return of(modules);
      })
    );
  }
  getFilters(route?: ActivatedRouteSnapshot): ConfigBaseFilter<ProductConcept>[] {
    return [
      new ModuleWidthFilter(),
      new ModuleSegmentFilter(this.metadataService),
      new ModuleSectionFilter(this.metadataService)
    ];
  }
  
  getBatchSizes(route?: ActivatedRouteSnapshot): number[] {
    return [24];
  }
  getLayouts(route?: ActivatedRouteSnapshot): ListLayout<ProductConcept>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => PlanogramModuleGridComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }
 
  getItemId(item: Product): string {
    return item.id;
  }

  getSortOptions(route?: ActivatedRouteSnapshot): ListSortOption<ProductConcept>[] {
    return [];
  }

  getSearchTerms(item: ProductConcept): string[] {
    const productsNames = item.extra.SpaceManagementModuleProducts.map(p => p.itemName);
    const productsIds = item.extra.SpaceManagementModuleProducts.map(p => p.itemId);
    return [item.id, item.name, ...productsNames, ...productsIds];
  }
  
  setProductsData(module, products){
    let artno = [];
    let floorOne = [];
    let floorTwo = [];
    let floorThree = [];
    for (const product of products) {
      if(product.extra.pim.erpProductType==='MERCH'){
        if(artno.includes(product.id)){
          continue;
        }
        artno.push(product.id);
      }
      const itemModule = module.extra.SpaceManagementModuleProducts.find(pm=> pm.itemId === product.id);
      if(itemModule.properties.floorOne && itemModule.properties.floorOne > -1){
        floorOne.push({id: product.extra.pim.colorCode, sort: itemModule.properties.floorOne });
        if(itemModule.properties.quantityOfSequenceFloorOne && Number(itemModule.properties.quantityOfSequenceFloorOne)>1){
          for (let index = 1; index < Number(itemModule.properties.quantityOfSequenceFloorOne); index++) {
            floorOne.push({id: product.extra.pim.colorCode, sort: itemModule.properties.floorOne });
          }
        }
      }
      if(itemModule.properties.floorTwo && itemModule.properties.floorTwo > -1){
        floorTwo.push({id: product.extra.pim.colorCode, sort: itemModule.properties.floorTwo });
        if(itemModule.properties.quantityOfSequenceFloorTwo && Number(itemModule.properties.quantityOfSequenceFloorTwo)>1){
          for (let index = 1; index < Number(itemModule.properties.quantityOfSequenceFloorTwo); index++) {
            floorTwo.push({id: product.extra.pim.colorCode, sort: itemModule.properties.floorTwo });
          }
        }
      }

      if(itemModule.properties.floorThree && itemModule.properties.floorThree > -1){
        floorThree.push({id: product.extra.pim.colorCode, sort: itemModule.properties.floorThree });
        if(itemModule.properties.quantityOfSequenceFloorThree && Number(itemModule.properties.quantityOfSequenceFloorThree)>1){
          for (let index = 1; index < Number(itemModule.properties.quantityOfSequenceFloorThree); index++) {
            floorThree.push({id: product.extra.pim.colorCode, sort: itemModule.properties.floorThree });
          }
        }
      }

    }
    module.extra._artNo = artno.toString()
    module.extra.floorOne = floorOne.sort((a, b) =>  a.sort - b.sort);
    module.extra.floorTwo = floorTwo.sort((a, b) =>  a.sort - b.sort);
    module.extra.floorThree = floorThree.sort((a, b) =>  a.sort - b.sort);

  }
}
