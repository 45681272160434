import { Component, AfterViewInit, OnChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService, EmailInfo, JobKey, SchedulersService } from 'gung-common';
import { ListItemRendererComponent } from 'gung-list';
import { catchError, first, map, of } from 'rxjs';
import { SchedulerV2 } from '../../services/schedulers-list-config/schedulers-list-config.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'lib-schedulers-monitor-list-view',
  templateUrl: './schedulers-monitor-list-view.component.html',
  styleUrls: ['./schedulers-monitor-list-view.component.css']
})
export class SchedulersMonitorListViewComponent extends ListItemRendererComponent<SchedulerV2[]> implements AfterViewInit {

  
  isLoading: boolean = true;
  panelOpenStates: string[] = [];
  lastException: { jobName: string; lastExceptionDate: Date; lastExceptionTrace: string };
  dateFormat = this.dateUtilService.dateFormat;
  schedulersEmailList: EmailInfo[] = [];
  consecutiveThresholdList: { JobName: number };
  longRunningThresholdList: { JobName: number };
  bufferSizeList: { JobName: number };
  newEmail: string = '';
  newEmailJobName: string = '';
  emailError: string = '';
  isEditableView: boolean = false;
  hasBackendSupport: boolean = true;

  constructor(
    protected schedulersService: SchedulersService,
    protected modalService: NgbModal,
    public dateUtilService: DateUtilService,
    protected translateService: TranslateService,
    protected authService: AuthService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
    this.getSchedulersEmails();
    this.getBufferSize();
    this.getConsecutiveThreshold();
    this.getLongRunningThreshold();
    this.getBufferSize();
  }

  triggerJob(jobKey: JobKey) {
    const scheduler = this.data.find(s => s.jobKey === jobKey);
    if (scheduler?.jobdatamap) {
      scheduler.jobdatamap.running = true;
    }
    this.schedulersService
      .triggerJob(jobKey)
      .pipe(first())
      .subscribe((jobkey: { jobKey: JobKey }) => {});
  }

  panelOpenState(jobName: string, add?: boolean) {
    if (add === undefined) {
      return this.panelOpenStates.includes(jobName);
    }
    if (add) {
      this.panelOpenStates.push(jobName);
    } else {
      this.panelOpenStates = this.panelOpenStates.filter(p => p !== jobName);
    }
  }

  updateNewEmail(email: string, jobName: string): void {
    this.newEmail = email;
    this.newEmailJobName = jobName;
  }

  showLastExceptionTrace(scheduler: SchedulerV2, content) {
    if (!scheduler?.jobdatamap?.lastExceptionTrace) {
      return;
    }
    this.lastException = {
      jobName: scheduler.jobName,
      lastExceptionDate: scheduler.jobdatamap.lastExceptionDate,
      lastExceptionTrace: scheduler.jobdatamap.lastExceptionTrace
    };
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', windowClass: 'modal-xl-custom' })
      .result.finally(() => {
        this.lastException = undefined;
      });
  }

  parseCronExpression(cronExpression: string): string {
    return this.schedulersService.parseCronExpressionToText(cronExpression);
  }

  isJobScheduled(jobName: string, emailInfo: EmailInfo): boolean {
    for (const job of emailInfo.JobNames) {
      if (job.includes(jobName)) {
        return true;
      }
    }
    return false;
  }

  getSchedulersEmails() {
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        const isActuator = roles.indexOf('ACTUATOR') > -1;
        this.schedulersService
          .getSchedulersEmailList()
          .pipe(
            map(schedulersEmails => schedulersEmails.filter(email => email.Email !== null)),
            map(schedulersEmails => {
              if (!isActuator) {
                return schedulersEmails.filter(email => !email.Email.endsWith('@gung.se'));
              }
              return schedulersEmails;
            }),
            catchError(error => {
              // Handle the error here
              console.error('Error fetching schedulers email list:', error);
              // Return an empty array or any fallback value
              this.hasBackendSupport = false;
              return of([]);
            })
          )
          .subscribe(filteredEmails => (this.schedulersEmailList = filteredEmails));
      });
  }

  onCheckboxChange(jobName: string, email: string, isChecked: boolean): void {
    const scheduler = this.schedulersEmailList.find(s => s.Email?.toLowerCase() === email?.toLowerCase());
    if (scheduler) {
      if (isChecked) {
        if (!scheduler.JobNames.includes(jobName)) {
          scheduler.JobNames.push(jobName);
        }
      } else {
        scheduler.JobNames = scheduler.JobNames.filter(name => name !== jobName);
      }

      this.schedulersService.updateSchedulerEmail(scheduler).subscribe(() => {
        this.getSchedulersEmails();
      });
    }
  }

  onEditChange(isChecked: boolean): void {
  
      if (isChecked) {
        this.isEditableView = true;
      } else {
        this.isEditableView = false
      }
  }

  validateEmail(email: string): boolean {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  addNewEmail(jobName:string): void {
    if (this.validateEmail(this.newEmail)) {
      var jobNamesList = [];
      const emailObject = this.schedulersEmailList.find(emailObj => emailObj.Email.toUpperCase() === this.newEmail.toUpperCase());

      if (emailObject) {
        // Get the jobNames list from the found object
        const jobNamesList = emailObject.JobNames;
        jobNamesList.push(jobName);
      
      } else {
        jobNamesList.push(jobName);
      }
      const newScheduler = { Email: this.newEmail, JobNames: jobNamesList };
      //this.schedulersEmailList.push(newScheduler);
      this.schedulersService.updateSchedulerEmail(newScheduler).subscribe(() => {
        this.newEmail = '';
        this.emailError = '';
        this.getSchedulersEmails();
      });
    } else {
      this.emailError = 'Please enter a valid email address';
    }
  }

  getBufferSize() {
    this.schedulersService.getBufferSizeList().subscribe(bufferSize => (this.bufferSizeList = bufferSize));
  }

  setBufferSize(jobName: string, value: number) {
    this.schedulersService.updateBufferSize(jobName, value).subscribe(() => {
      this.getBufferSize();
    });
  }

  getConsecutiveThreshold() {
    this.schedulersService
      .getConsecutiveThresholdList()
      .subscribe(consecutiveThreshold => (this.consecutiveThresholdList = consecutiveThreshold));
  }

  setConsecutiveThreshold(jobName: string, value: number) {
    var bufferSize = this.bufferSizeList[jobName];
    console.log("Buffer size: " + bufferSize);
    console.log("Value: " + value);
    if(value > bufferSize || bufferSize == undefined){ //wont work if buffer is smaller
      this.schedulersService.updateBufferSize(jobName, value).subscribe(() => {
        this.getBufferSize();
      });
    }
    this.schedulersService.updateConsecutiveThreshold(jobName, value).subscribe(() => {
      this.getConsecutiveThreshold();
    });
  }

  getLongRunningThreshold() {
    this.schedulersService
      .getLongRunningThresholdList()
      .subscribe(longRunningThreshold => {
         this.longRunningThresholdList = longRunningThreshold;
         this.convertThresholdsToSeconds();}
        );
  }

  setLongRunningThreshold(jobName: string, value: number) {
    value = value*1000;
    this.schedulersService.updateLongRunningThreshold(jobName, value.toString()).subscribe(() => {
      this.getLongRunningThreshold();
    });
  }

  convertThresholdsToSeconds(): { [jobName: string]: number } {
    let convertedThresholds: { [jobName: string]: number } = {};
    for (let jobName in this.longRunningThresholdList) {
      this.longRunningThresholdList[jobName] = this.longRunningThresholdList[jobName] / 1000;
    }
    return convertedThresholds;
  }
}
