import { Component } from '@angular/core';
import { EdiIncomingOrderResponseListConfigService } from '../../../services/edi-incoming-order-response-list-config/edi-incoming-order-response-list-config.service';

@Component({
  selector: 'lib-edi-incoming-order-response',
  template: `<h3 class="d-block page-title text-center" translate>Manage incoming order responses</h3>
    <lib-filter-list [configService]="ediIncomingOrderResponseListConfigService"></lib-filter-list>`,
  styles: []
})
export class EdiIncomingOrderResponseComponent {
  constructor(public ediIncomingOrderResponseListConfigService: EdiIncomingOrderResponseListConfigService) {}

  ngOnInit(): void {}
}
