<div class="row">
  <div *ngFor="let child of data" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
    <div class="card card-assortment-tree h-100 card-border-top">
      <a [routerLink]="['./', child.id]" class="border-0">
        <img
          *ngIf="child.extra.images && child.extra.images[0] as image; else noimage"
          class="card-img-top img-fluid mx-auto d-block"
          src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/350x350/filters:fill(white):background_color(white):etag({{
            image.s3ETag
          }}):format(jpeg)/{{ image.s3Uri }}"
          alt="{{ image.description }}"
        />
        <ng-template #noimage>
          <img
            class="card-img-top img-fluid w-100"
            src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/300x300/images/no-image.jpg"
            alt="NO IMAGE"
            class="img-fluid mx-auto d-block"
          />
        </ng-template>
        <div class="card-body d-flex">
          <div class="card-title align-self-end w-100">
            <a
              [routerLinkActive]="'active-assortment'"
              [routerLink]="['./', child.id]"
              class="d-flex align-items-center justify-content-between text-break border-0"
            >
              <span>{{ child.name }}</span>
              <i class="fa fa-angle-right align-self-end" aria-hidden="true" style="font-size: x-large"></i>
            </a>
          </div>
          <p class="card-text"></p>
        </div>
      </a>
    </div>
  </div>
</div>
