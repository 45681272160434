import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '../../services/products/product.service';
import { Product } from '../../models';
import { forkJoin, of, timer, Observable, catchError, filter } from 'rxjs';
import { tap, mergeMap, map, first, debounce, switchMap } from 'rxjs';
import { AvailabilityService } from '../../services/availability/availability.service';
import { Availability } from '../../models/availability';
import { AuthService } from '../../services/auth/auth.service';
import { PriceService } from '../../services/price/price.service';
import { CustomerProductPrice } from '../../models/price';
import { User } from '../../state/auth/types';
import { PimTemplateProperties } from 'gung-list';
import { MetadataService } from '../../services/metadata/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { GungFlow } from '../../state/flow/types';
import { AssortmentRecursiveExtended, AssortmentService } from '../../services/assortment.service';

@Component({
  selector: 'lib-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  public id: string;

  @Input('productId')
  set productId(value: string) {
    this.setId(value);
  }

  get productId() {
    return this.id;
  }

  public noCache = false;
  public isAnonymous = true;
  public isSales = false;
  public product: Product;
  public availability: Availability;
  public price: CustomerProductPrice;
  public flow: GungFlow;
  public components: Product[];

  public componentAmounts: { [productId: string]: number } = {};
  public productDetailsTable: { title: string; value: string; type?: string }[] = [];
  currentLang = 'se';
  currentAssortment?: AssortmentRecursiveExtended;

  constructor(
    protected route: ActivatedRoute,
    protected productService: ProductService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected metadataService: MetadataService,
    protected translateService: TranslateService,
    protected assortmentService: AssortmentService,
    protected gungFlowService: GungFlowService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang || this.currentLang;

    // Doing this without check break for all customers that get currentAssortment as an component input param (OTTO)
    if (!this.currentAssortment) {
      this.assortmentService
        .getUserAssortment()
        .pipe(first())
        .subscribe(assortment => (this.currentAssortment = assortment));
    }

    const baseObservable = this.route.params.pipe(
      map(params => {
        return this.id || (params.productId as string);
      }),
      switchMap(productId =>
        forkJoin({
          productId: of(productId),
          multistock: this.authService.getCurrentUser().pipe(
            filter(user => !!user),
            first(),
            map(user => user.managedMultistockIds[0])
          )
        })
      ),
      switchMap(({ productId, multistock }) =>
        forkJoin({
          product: this.productService.getProduct(productId).pipe(catchError(error => of(error))),
          availability: this.availabilityService
            .getAvailability(productId, multistock, this.noCache)
            .pipe(catchError(error => of(error))),
          price: this.priceService.getCurrentCustomerPrice(productId).pipe(
            first(),
            catchError(error => of(error))
          ),
          user: this.authService.getCurrentUser().pipe(
            first(),
            catchError(error => of(error))
          ),
          flow: this.gungFlowService.getSelectedFlow().pipe(
            first(),
            catchError(error => of(error))
          )
        })
      )
    );
    this.handleObservable(baseObservable);
  }

  protected handleObservable(observable: Observable<BaseDetailData>): void {
    observable.subscribe(({ product, availability, price, user, flow }) => {
      this.product = product;
      this.availability = availability;
      this.price = price;
      this.flow = flow;

      this.performErpSpecificSetup(product, availability, price, user, flow);

      this.isAnonymous = user.roles.filter(role => role.toUpperCase() === 'ANONYMOUS').length > 0;
      this.isSales = user.roles.filter(role => role.toUpperCase() === 'SALES').length > 0;
      this.getTemplate();
    });
  }

  protected performErpSpecificSetup(
    product: Product,
    availability: Availability,
    price: CustomerProductPrice,
    user: User,
    flow: GungFlow
  ): void { }

  protected setId(id: string): void {
    this.id = id;
  }

  getTemplate() {
    // Get template
    if (this.product?.extra?.itemProperties || this.product?.extra?.productTemplateProperties) {
      const itemProperties = [
        ...(this.product.extra.itemProperties || []),
        ...(this.product.extra.productTemplateProperties || [])
      ];
      for (const templateProp of itemProperties) {
        if (
          templateProp?.isDisplayDetails &&
          this.productDetailsTable.findIndex(prop => prop.title === templateProp.translationKey) === -1
        ) {
          const value = this.getProductDetailTable(templateProp);
          if (Array.isArray(value)) {
            const valueArray = value.map(v => v.description).join(', ');
            this.productDetailsTable.push({
              title: templateProp.translationKey,
              value: valueArray,
              type: templateProp.type
            });
          } else if (templateProp.type === 'number' && !isNaN(value as any)) {
            this.productDetailsTable.push({ title: templateProp.translationKey, value, type: templateProp.type });
          } else if (typeof value === 'string' && value.trim().length !== 0) {
            this.productDetailsTable.push({ title: templateProp.translationKey, value, type: templateProp.type });
          } else if (!!value) {
            this.productDetailsTable.push({ title: templateProp.translationKey, value, type: templateProp.type });
          }
        }
      }
    }
    if (
      this.currentAssortment &&
      (this.currentAssortment.extra.itemProperties || this.currentAssortment.extra.productTemplateProperties)
    ) {
      const itemProperties = [
        ...(this.currentAssortment.extra.itemProperties || []),
        ...(this.currentAssortment.extra.productTemplateProperties || [])
      ];
      for (const templateProp of itemProperties) {
        if (
          templateProp.isDisplayDetails &&
          this.productDetailsTable.findIndex(prop => prop.title === templateProp.translationKey) === -1
        ) {
          const value = this.getProductDetailTable(templateProp);
          if (Array.isArray(value)) {
            const valueArray = value.map(v => v.description).join(', ');
            this.productDetailsTable.push({
              title: templateProp.translationKey,
              value: valueArray,
              type: templateProp.type
            });
          } else if (typeof value === 'string' && value.trim().length !== 0) {
            this.productDetailsTable.push({ title: templateProp.translationKey, value, type: templateProp.type });
          } else if (templateProp.type === 'number' && !isNaN(value as any)) {
            this.productDetailsTable.push({ title: templateProp.translationKey, value, type: templateProp.type });
          } else if (!!value) {
            this.productDetailsTable.push({ title: templateProp.translationKey, value, type: templateProp.type });
          }
        }
      }
    }
  }

  getProductDetailTable(templateProp: PimTemplateProperties): string {
    const fields = templateProp.path.split('.');

    if (!this.product || !fields || fields.length === 0) {
      return;
    }

    if (fields[0] === 'i18n') {
      fields.splice(1, 0, this.currentLang);
    }

    // Get field form object if exist
    const getField = (obje: object, field: string) => {
      if (!obje.hasOwnProperty(field)) {
        return null;
      }
      return obje[field];
    };

    // Get property from object
    let obj: any = Object.assign({}, this.product.extra);
    for (const field of fields) {
      if (!obj) {
        break;
      }
      obj = getField(obj, field);
    }

    if (obj !== undefined && obj !== null) {
      if (templateProp.type === 'text') {
        return obj.description || obj;
      }
      if (templateProp.type === 'metadata') {
        let translatedDescriptionString = 'translatedDescription';
        const translatedDescription = this.getTranslatedDescription(obj);
        if (translatedDescription) {
          return translatedDescription;
        }
        if (obj.hasOwnProperty('description')) {
          // PIM metadata
          return obj.description;
        }
        if (templateProp.metaReference) {
          return this.metadataService.getMetadataValue(
            templateProp.metaReference.table,
            templateProp.metaReference.field,
            obj
          );
        }
        if (templateProp.metadata) {
          if (templateProp.metadata.split('.').length === 2) {
            const splitmeta = templateProp.metadata.split('.');
            const metaTable = splitmeta[0];
            const metaField = splitmeta[1];
            return this.metadataService.getMetadataValue(metaTable, metaField, obj);
          }
          return this.metadataService.getMetadataValue(templateProp.metadata, 'description', obj);
        }
      }
      return obj;
    }

    return null;
  }

  getTranslatedDescription(obj) {
    let translatedDescriptionString = 'translatedDescription';
    if (
      obj &&
      obj.translateProperties &&
      obj.translateProperties[this.currentLang] &&
      obj.translateProperties[this.currentLang][translatedDescriptionString] &&
      obj.translateProperties[this.currentLang][translatedDescriptionString] !== ''
    ) {
      return obj.translateProperties[this.currentLang][translatedDescriptionString];
    }

    return null;
  }
}

export interface BaseDetailData {
  product: Product;
  price: CustomerProductPrice;
  availability: Availability;
  user: User;
  flow: GungFlow;
}
