<div *ngIf="!!customer">
  <div class="row mb-4">
    <div class="col d-flex flex-direction-row justify-content-between">
      <h3>{{ customer.name }}</h3>
      <lib-action-btn [item]="customer" [config]="configService"></lib-action-btn>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <lib-gung-tabset justify="fill" *ngIf="!!customer">
        <lib-gung-tab [title]="'DELIVERY_ADDRESS' | translate">
          <ng-template libGungTabContent>
            <!-- missing ordlevadr4 ordlevadr1 -->
            <div class="row pt-4" *ngIf="deliveryAddressesSelectedIndex > -1">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="deliveryLocation" class="control-label" translate>DELIVERY_LOCATION</label>
                  <select
                    name="deliveryLocation"
                    id="deliveryLocation"
                    class="form-control"
                    (change)="onDeliveryAddressSelected($event.target.value)"
                  >
                    <option [value]="i" *ngFor="let option of deliveryAddresses; let i = index">
                      {{ option.addressValues.ordlevadr1 }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="control-label" for="nameInput" translate>OH_ORDLEVADR1</label>
                  <input
                    class="form-control"
                    name="nameInput"
                    type="text"
                    [(ngModel)]="deliveryAddresses[deliveryAddressesSelectedIndex].addressValues.ordlevadr1"
                    disabled
                  />
                </div>
                <!-- <div class="form-group">
                  <label class="control-label" for="nameInput" translate>CUSTOMER_NAME</label>
                  <input class="form-control" name="nameInput" type="text" [(ngModel)]="customer.name" disabled />
                </div> -->
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="control-label" for="address" translate>OH_ORDLEVADR2</label>
                  <!-- ordlevadr2 -->
                  <input
                    class="form-control"
                    name="address"
                    type="text"
                    [(ngModel)]="deliveryAddresses[deliveryAddressesSelectedIndex].addressValues.ordlevadr2"
                    disabled
                  />
                </div>

                <div class="form-group">
                  <label class="control-label" for="city" translate>OH_ORDLEVADR3</label>
                  <!-- ordlevadr3 -->
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="deliveryAddresses[deliveryAddressesSelectedIndex].addressValues.ordlevadr3"
                    disabled
                  />
                </div>

                <div class="form-group">
                  <label class="control-label" for="city" translate>OH_ORDLEVADR4</label>
                  <!-- ordlevadr3 -->
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="deliveryAddresses[deliveryAddressesSelectedIndex].addressValues.ordlevadr4"
                    disabled
                  />
                </div>

                <div class="form-group">
                  <label class="control-label" for="postalCode" translate>OH_FTGPOSTNR</label>
                  <!-- ftgpostnr -->
                  <input
                    class="form-control"
                    name="postalCode"
                    type="text"
                    [(ngModel)]="deliveryAddresses[deliveryAddressesSelectedIndex].addressValues.ftgpostnr"
                    disabled
                  />
                </div>

                <div class="form-group">
                  <label class="control-label" for="city" translate>FR_LANDSKOD</label>
                  <!-- ordlevadrlandskod -->
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="deliveryAddresses[deliveryAddressesSelectedIndex].addressValues.ordlevadrlandskod"
                    disabled
                  />
                </div>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'BILLING_INFORMATION' | translate">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="control-label" for="city" translate>NAME</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra._billing.ftgnamn"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label class="control-label" for="address" translate>FR_FTGPOSTADR1</label>
                  <input
                    class="form-control"
                    name="address"
                    type="text"
                    [(ngModel)]="customer.extra._billing.ftgpostadr1"
                    disabled
                  />
                </div>

                <div class="form-group">
                  <label class="control-label" for="city" translate>FR_FTGPOSTADR2</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra._billing.ftgpostadr2"
                    disabled
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="control-label" for="city" translate>FR_FTGPOSTNR</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra._billing.ftgpostnr"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label class="control-label" for="city" translate>FR_LANDSKOD</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra._billing.landskod"
                    disabled
                  />
                </div>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'CONTACTS' | translate">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <lib-customer-contact-list></lib-customer-contact-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab *ngIf="isSales" [title]="'ACTIVITIES' | translate">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <lib-activity-internal-list [customerId]="customer.id"></lib-activity-internal-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'ORDERS' | translate">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <lib-order-list></lib-order-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'INVOICES' | translate">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <gung-jeeves-jeeves-invoice-list></gung-jeeves-jeeves-invoice-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
      </lib-gung-tabset>
    </div>
  </div>
</div>
