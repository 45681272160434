import { Injectable } from '@angular/core';
import { AssortmentTreeNavigationConfigService } from 'gung-standard';
import { BaseViewConfigService } from 'gung-standard';
import { SjoborgProductDetailsComponent } from '../components/sjoborg-product-details/sjoborg-product-details.component';

@Injectable({
  providedIn: 'root'
})
export class SjoborgAssortmentTreeNavigationConfigService extends AssortmentTreeNavigationConfigService {
  constructor(protected baseViewConfigService: BaseViewConfigService) {
    super(baseViewConfigService);
  }

  getProductDetailLayout(): any {
    return SjoborgProductDetailsComponent;
  }
}
