<div class="dashboard-card card">
  <div class="card-body dashboard-card-content">
    <div class="card-title">
      <div class="d-flex justify-content-between align-items-center">
        <span class="title" translate>INACTIVE_CUSTOMERS</span>
        <div class="btn-group">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle translate>
              ACTIVE_WITHING_THE_LAST
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem (click)="loadData(option.months, inactiveMonths)" *ngFor="let option of options">
                <span translate>{{ option.text }}</span>
              </button>
            </div>
          </div>
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-primary" id="dropdownBasic2" ngbDropdownToggle translate>
              INACTIVE_WITHIN_THE_LAST
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button
                ngbDropdownItem
                (click)="loadData(activeWithinMonths, option.months)"
                *ngFor="let option of options"
              >
                <span translate>{{ option.text }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-between align-items-center search-parameter-box"
      *ngIf="!!inactiveCustomerInfo && !!inactiveMonths"
    >
      <div>
        <span>
          {{ 'INACTIVE_CUSTOMERS_AMOUNT' | translate: { amountFound: inactiveCustomerInfo.length } }}
        </span>
      </div>

      <div>
        <span>
          {{
            'INACTIVE_SHOWING_FOR_THE_LAST' | translate: { months: inactiveMonths, activeMonths: activeWithinMonths }
          }}
        </span>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table">
        <tr>
          <th translate>ID</th>
          <th translate>NAME</th>
          <th translate>AMOUNT_OF_INVOICES</th>
          <th translate>LATEST_INVOICE_DATE</th>
        </tr>
        <tr *ngFor="let inactiveInfo of inactiveCustomerInfo">
          <td>
            <a routerLink="/customers/{{ inactiveInfo.customerId }}">
              {{ inactiveInfo.customerId }}
            </a>
          </td>
          <td>{{ inactiveInfo.customerName }}</td>
          <td>{{ inactiveInfo.amountOfInvoices }}</td>
          <td>{{ inactiveInfo.latestInvoiceDate }}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
