import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { GungCustomerAddress, GungCustomerAddressService } from './gung-customer-address.service';
import { Observable, of } from 'rxjs';
import { CustomerGungAddressCardListComponent } from '../components/customer-gung-address-card-list/customer-gung-address-card-list.component';

@Injectable({
  providedIn: 'root'
})
export class CustomerGungAddressConfigService implements ConfigService<GungCustomerAddress> {
    /*
     This variable and setter is called in the customer-contact-list-component to set
     the customerId to the id in the route params. It did not work to get the routeparams
     from this service.
     */
    customerId: string;
    setCustomerId(customerId: string) {
      this.customerId = customerId;
    }
  
    constructor(protected gungCustomerAddressService: GungCustomerAddressService) {}
  
    getItems(): Observable<GungCustomerAddress[]> {
      if (this.customerId == null) {
        return of([]);
      }
      return this.gungCustomerAddressService.getCustomerGungAddressesFromSubject(this.customerId);
    }
  
    getFilters(): ConfigBaseFilter<GungCustomerAddressService>[] {
      return [];
    }
  
    getSortOptions(): ListSortOption<GungCustomerAddress>[] {
      return [];
    }
  
    getBatchSizes(): number[] {
      return [24];
    }
  
    getLayouts(): ListLayout<GungCustomerAddress>[] {
      return [
        {
          getIconClass: () => '',
          getListItemComponent: () => CustomerGungAddressCardListComponent,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'DeliveryLocation grid'
        }
      ];
    }
  
    getSearchTerms(item: any): string[] {
      return [item.id];
    }
  
    getItemId(item: any): string {
      return item.id;
    }
  
    getNothingFoundTranslateTag() {
      return 'NOTHING_FOUND';
    }
}
