import { Component, OnInit } from '@angular/core';
import { MatrixDiscountListConfigService } from '../../services/matrix-discount-list-config/matrix-discount-list-config.service';

@Component({
  selector: 'lib-matrix-discounts',
  templateUrl: './matrix-discounts.component.html',
  styleUrls: ['./matrix-discounts.component.css']
})
export class MatrixDiscountsComponent implements OnInit {
  constructor(public configService: MatrixDiscountListConfigService) {}

  ngOnInit(): void {}
}
