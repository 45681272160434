import { Component, OnInit } from '@angular/core';
import { GungNoImageUrl } from 'gung-common';
import { CustomPageTemplateComponent } from '../../custom-page-class/custom-page-template-component';
import { TemplateComponent } from '../../custom-page-interface/template-component';

const dataDefault: TemplateComponent = {
  templateName: null,
  elements: {
    title: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: 'Marketing Materials'
      }
    },
    category1: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl
      },
      text: {
        enabled: true,
        text: 'Category 1'
      },
      extra: {
        childs: ['element1']
      },
    },
    element1: {
      link: {
        enabled: true,
        internal: false,
        url: '',
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl
      },
      text: {
        enabled: true,
        text: 'Element 1',
      }
    }
  }
};

@Component({
  selector: 'lib-marketing-materials-template',
  templateUrl: './marketing-materials-template.component.html',
  styleUrls: ['./marketing-materials-template.component.scss']
})
export class MarketingMaterialsTemplateComponent extends CustomPageTemplateComponent implements OnInit {
  public categories: string[] = [];
  public allElements: string[] = [];
  public elements: {[catefgory: string]: string[]} = {};

  getDefaultData(): TemplateComponent {
    return dataDefault;
  }

  ngOnInit() {
    this.readCategories();
    this.readElements();
  }

  readCategories() {
    const categories = [...new Set(Object.keys(this.data.elements).filter(e =>  e.startsWith('category')))];
    this.categories = categories;
  }

  readElements() {
    const allElements = Object.keys(this.data.elements).filter(e =>  e.startsWith('element'));
    this.allElements = [...new Set(allElements)];
    for (const category of this.categories) {
      const elements = [...new Set(allElements.filter(e => this.data.elements[category].extra.childs.includes(e)))];
      this.elements[category] = elements;
    }
  }

  addElement(category) {
    const last = (this.allElements.length > 0 && this.allElements[this.allElements.length-1]) || undefined;
    const lastId = (last && Number(last.slice('element'.length))) || 0;
    this.data.elements['element' + (lastId+1)] =  {
      link: {
        enabled: true,
        internal: false,
        url: ''
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl
      },
      text: {
        enabled: true,
        text: 'Element ' + (lastId+1)
      }
    }

    this.data.elements[category].extra.childs.push('element' + (lastId+1));
    this.readElements();
  }

  removeElement(element: string, category: string, skipRead?: boolean) {
    this.data.elements[category].extra.childs = this.data.elements[category].extra.childs.filter(c => c !== element);
    delete this.data.elements[element];
    this.readElements();
  }

  removeCategory(category) {
    const childs = this.data.elements[category].extra.childs;
    for (const child of childs) {
      this.removeElement(child, category, true);
    }
    delete this.data.elements[category];
    this.readCategories();
    this.readElements();
  }

  addCategory() {
    const last = (this.categories.length > 0 && this.categories[this.categories.length-1]) || undefined;
    const lastId = (last && Number(last.slice('category'.length))) || 0;
    this.data.elements['category' + (lastId + 1)] = {
      link: {
        enabled: false,
        internal: false,
        url: ''
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl
      },
      text: {
        enabled: true,
        text: 'Category ' + (lastId + 1)
      },
      extra: {
        childs: []
      }
    };
    this.addElement('category' + (lastId + 1));
    this.readCategories();
    this.readElements();
  }
}
