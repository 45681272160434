import { Component } from '@angular/core';
import { GungModalService } from '../../../../services/gung-modal/gung-modal.service';
import { Planogram } from '../../models/planogram';
/* /services/gung-modal/gung-modal.service'; */

@Component({
  selector: 'lib-planogram-create-config-modal',
  templateUrl: './planogram-create-config-modal.component.html',
  styleUrls: ['./planogram-create-config-modal.component.css']
})
export class PlanogramCreateConfigModalComponent {
  delegate: GungModalService;
  componentId: string;
  isEdit = false;

  additionalProducts:{ id: string, qty: number }[] = [];
 
  
  planogram: Planogram = {
    id: null,
    name: '',
    assortmentId: '',
    width: null,
    widthStep: null,
    shelfs: null,
    readOnly: false,
    items: [],
    furniture: '',
    customer: '',
    country: '',
    year: '',
    period: '',
    additionalProducts: {},
    expiredAndRemovedItems: []
  }

  constructor() {}

  ngOnInit() {
    this.componentId = PlanogramCreateConfigModalComponent.name;
    if(typeof this.planogram.additionalProducts === 'string'){
      this.planogram.additionalProducts = {};
    }
    if(this.isEdit){
      if(!this.planogram.additionalProducts){
        this.planogram.additionalProducts = {};
      }
      this.getAdditionalProducts();
    }
  }

  confirm() {
    this.setAdditionalProducts();
    console.log('this.planogram',this.planogram)
    if(this.planogram.width && this.planogram.shelfs && this.planogram){
      this.delegate.modalRef.get(this.componentId).close(this.planogram);
    }
  }

  cancel() {
    this.delegate.modalRef.get(this.componentId).close({ });
  }

  addAdditionalProduct(){
    this.additionalProducts.push({
      id:'',
      qty: 1
    });
  }

  removeAdditionalProduct(i){
    this.additionalProducts.splice(i,1);
  }

  setAdditionalProducts(){
    this.planogram.additionalProducts = {};
    for (const addProduct of this.additionalProducts) {
      this.planogram.additionalProducts[addProduct.id.toString()] = addProduct.qty; 
    }
  }

  getAdditionalProducts(){
    for (const key in this.planogram.additionalProducts) {
      if (Object.prototype.hasOwnProperty.call(this.planogram.additionalProducts, key)) {
        const element = this.planogram.additionalProducts[key];
        this.additionalProducts.push({id: key, qty: element});
      }
    }
  }

  removeExpired(item){
    const index = this.planogram.expiredAndRemovedItems.indexOf(item);
    if (index !== -1) {
      this.planogram.expiredAndRemovedItems.splice(index, 1);
    }
  }
}
