import { Injectable } from '@angular/core';
import { Order } from '../../../models/order';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductionOrderService {
  constructor(protected http: HttpClient) { }

  public create(order: Order): Observable<Order> {
    return this.http.post<Order>('json/production/orders', order);
  }

  public getGungCompanyInfo(): Observable<GungCompanyInfo> {
    const url: string = 'json/gung-company';
    return this.http.get<GungCompanyInfo>(url);
  }
}

export interface GungCompanyInfo {
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  organizationNumber: string;
  name: string
}
