import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoceStatisticsService {

  baseUrl = 'json/invoice-statistics-nostream'; // ?minDate=2022-01-27&fromIndex=0&toIndex=100

  constructor(protected http: HttpClient) {}

  getInvoiceStatistics(date): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}?minDate=${date}`);
  }

  getInvoiceStatisticsFiltered(date?, filters?): Observable<any> {
    const url = this.baseUrl + (!!date ? `?minDate=${date}` : '');
    return this.http.post<any>(url, filters);
  }
}
