<div class="row py-2" *ngIf="!isLoading; else loading">
  <div class="col-sm-12">

    <ng-container *ngFor="let product of data">
      <table class="w-100 mb-4 my-3">
        <tr>
          <td style="width: 150px;">
            <button type="button" class="btn btn-link"
                    (click)="icon.classList.toggle('fa-arrow-turn-up'); icon.classList.toggle('fa-arrow-turn-down'); showDetails(product.productId)">
              <i #icon class="fa-solid fa-arrow-turn-up fa-rotate-180"></i>
            </button>
            <span class="product-id">{{ product.productId }}</span>
          </td>
          <td style="width: 255px;">
            <span class="product-name">{{ product.productData.product.name }}</span><br>
          </td>
          <td style="width: 120px">
            <img class="img-fluid" [src]="product.productData.product.extra.images[0].s3Uri | gungImageUrl
                :'type:list'
                :'etag:'+product.productData.product.extra.images[0].s3Etag
            ">
          </td>
          <td></td>
        </tr>
        <tr #productsRow *ngIf="!show[product.productId]">
          <td colspan="10" class="p-0" style="border-bottom: none;">
            <table class="table">
              <thead>
              <tr>
                <th class="p-0 pr-2"></th>
                <th scope="col">
                  <span translate>TYPE</span>
                </th>
                <th scope="col">
                  <span translate>ORDER_NUMBER</span>
                </th>
                <th scope="col">
                  <span translate>QUANTITY</span>
                </th>
                <th scope="col">
                  <span translate>DATE</span>
                </th>
                <th scope="col">
                  <span translate>TRACE</span>
                </th>
                <th scope="col" class="text-right">
                  <span translate></span>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr class="stock-row">
                <td class="p-0 border-0 left-color-border"></td>
                <td>
                  <span translate>STOCK</span>
                </td>
                <td></td>
                <td>
                  <span>{{ product.availability.currentStock }}</span>
                </td>
                <td>
                  <span>{{ product.availability.date | date: 'yyyy-MM-dd' }}</span>
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr *ngFor="let order of product.outgoingOrders; let idx = index"
                  [ngClass]="{
                  'sales-order': order.orderType === RequirementsPlanningOrderType.SALES_ORDER,
                  'purchase-order': order.orderType === RequirementsPlanningOrderType.PURCHASE_ORDER,
                  'production-order': order.orderType === RequirementsPlanningOrderType.PRODUCTION_ORDER,
                  }">
                <td class="p-0 border-0 left-color-border"></td>
                <td>
                  <span translate>{{ order.orderType }}</span>
                </td>
                <td>
                  {{ order.orderId }}
                </td>
                <td>
                  <span>{{ order.quantity }}</span>
                </td>
                <td>
                  <span>{{ order.orderDate | date: 'yyyy-MM-dd' }}</span>
                </td>
                <td>
                  <span>{{ order.trace }}</span>
                </td>
                <td></td>
              </tr>

              <tr *ngFor="let order of product.suggestedOrders; let idx = index"
                  class="suggested-order"
                  [ngClass]="{
                  'sales-order': order.orderType === RequirementsPlanningOrderType.SALES_ORDER,
                  'purchase-order': order.orderType === RequirementsPlanningOrderType.PURCHASE_ORDER,
                  'production-order': order.orderType === RequirementsPlanningOrderType.PRODUCTION_ORDER,
                  }">
                <td class="p-0 border-0 left-color-border"></td>
                <td>
                  <span translate>{{ order.orderType }}</span>
                  (<span translate>SUGGESTED_ORDER</span>)
                </td>
                <td>
                  {{ order.orderId }}
                </td>
                <td>
                  <span>{{ order.quantity }}</span>
                </td>
                <td>
                  <span>{{ order.orderDate | date: 'yyyy-MM-dd' }}</span>
                </td>
                <td>
                  <span>{{ order.trace }}</span>
                </td>

                <td class="text-right">
                  <div ngbDropdown class="d-inline-block">
                    <button [disabled]="createdOrders[getSuggestedOrderId(order, idx)]"  type="button" class="btn btn-outline-primary" id="dropdownBasic_1" ngbDropdownToggle>
                      <span translate>CREATE</span> {{ order.orderType | translate | lowercase }}
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic_1">
                      <button (click)="createSuggestion(order, idx, product.productData)" ngbDropdownItem><span translate>FOR_THIS_ROW</span></button>
                      <button *ngIf="order.orderType === RequirementsPlanningOrderType.PURCHASE_ORDER && product.suggestedOrders.length > 0" (click)="createSuggestion(order, idx, product.productData, product.suggestedOrders)" ngbDropdownItem><span translate>ALL_ROWS_ON_THIS_PRODUCT</span></button>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </table>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <i class="fa fa-spinner fa-spin fa-fw fa-4x"></i>
  </div>
</ng-template>
