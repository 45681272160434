import { Component, Input } from '@angular/core';
import { OrderPortalEntry } from '../../models/order-portal';
import { OrderPortalModalService } from '../../services/order-portal-modal.service';

@Component({
  selector: 'lib-order-portal-status-display',
  templateUrl: './order-portal-status-display.component.html',
  styleUrls: ['./order-portal-status-display.component.scss']
})
export class OrderPortalStatusDisplayComponent {
  @Input()
  entry: OrderPortalEntry;

  constructor(protected modalService: OrderPortalModalService) {}

  hasErrors(): boolean {
    return !this.entry.finishedSuccessfully;
  }

  hasMessages(): boolean {
    return this.entry.messages.length > 0;
  }
  openInfo() {
    this.modalService.openOrderPortalStatusModal(this.entry).then(
      () => {},
      err => {}
    );
  }
}
