import { Component, Input, OnInit } from '@angular/core';
import { Assortment, AssortmentService, AuthService, Customer, Product, ProductService, TableRecord, gungAddRemoveSpinner } from 'gung-standard';
import { Claim } from '../../models/claim';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, filter, first, forkJoin, map, of, switchMap } from 'rxjs';
import { SearchRequest } from 'gung-list';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JeevesClaimService } from '../../services/jeeves-claim-service/jeeves-claim.service';
import { User } from 'gung-standard/lib/state/auth/types';

@Component({
  selector: 'gung-jeeves-jeeves-create-claim-modal',
  templateUrl: './jeeves-create-claim-modal.component.html',
  styleUrls: ['./jeeves-create-claim-modal.component.css']
})
export class JeevesCreateClaimModalComponent implements OnInit {
  @Input()
  customer: Customer;

  @Input()
  claim?: Claim;

  orkForm: FormGroup;

  assortment: Assortment;

  user: User;

  public modelSearch: any;
  formatter = (product: Product) => product.id /*  + ' - ' + product.name */;
  resultFormatter = (product: Product) => product.id + ' - ' + product.name;

  search: OperatorFunction<string, readonly { id; name }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(term => term.length >= 2),
      switchMap(term => {
        const terms = term.split(' ');
        const searchRequest: SearchRequest = {
          terms,
          skip: 0,
          limit: 10,
          assortment: this.assortment?.id || 's0'
        };
        return forkJoin([of(term), this.productService.getPagedProducts(searchRequest)]);
      }),
      map(([term, products]) =>
        products.items.slice(0, 10)
      )
    );

  constructor(
    public activeModal: NgbActiveModal,
    protected jeevesClaimService: JeevesClaimService,
    protected formBuilder: FormBuilder,
    protected productService: ProductService,
    protected assortmentService: AssortmentService,
    protected authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.getCurrentUser().pipe(first()).subscribe(user => {
      this.user = user;
      this.initForm();
    });
    
    this.assortmentService
      .getUserAssortment()
      .pipe(first())
      .subscribe(assortment => (this.assortment = assortment));
  }

  getSubstring(str, start, end) {
    const char1 =  str?.indexOf(start) + 2;
    const char2 =  str?.indexOf(end);
    return str?.substring(char1, char2);
  }


  initForm() {
    let name = this.claim ? '' : this.user.name;
    let email = this.claim ? '' : this.user.email;
    let edit = this.claim?.extra?.ork?.edit || '';
    if(this.claim && this.claim?.extra?.ork?.edit?.includes('--')){
      name = this.claim?.extra?.ork?.edit?.substring(0, this.claim?.extra?.ork?.edit.indexOf("--"));
      email = this.getSubstring(this.claim?.extra?.ork?.edit, '--', '\n');
      edit = this.claim?.extra?.ork?.edit?.substring(this.claim?.extra?.ork?.edit.indexOf("\n")+1, this.claim?.extra?.ork?.edit.length);
    }
    
    this.orkForm = this.formBuilder.group({
      kundreklamationorsak: this.formBuilder.control(
        { value: this.claim?.extra?.ork?.kundreklamationorsak || '', disabled: false },
        [Validators.required]
      ),
      claimworkflow: this.formBuilder.control(this.claim?.extra?.ork?.claimworkflow || '', [Validators.required]),
      edit: this.formBuilder.control(edit || '', [Validators.required]),
      /* saljare: this.formBuilder.control(
        { value: (this.claim ? this.claim?.extra?.ork?.saljare : this.customer.name) || '', disabled: true },
        []
      ), */
      /* ftgnr: this.formBuilder.control(
        { value: (this.claim ? this.claim?.extra?.ork?.ftgnr : this.customer.id) || '', disabled: true },
        []
      ), */
      name: this.formBuilder.control(name || '', []),
      email: this.formBuilder.control(email || '', [Validators.email]),
      ordernr: this.formBuilder.control(this.claim?.extra?.ork?.ordernr || '', [Validators.required]),
      faktnr: this.formBuilder.control(this.claim?.extra?.ork?.faktnr || '', []),
      claimqty: this.formBuilder.control(this.claim?.extra?.ork?.claimqty || '', [Validators.required]),
      eventnote: this.formBuilder.control({ value: this.claim?.extra?.ork?.eventnote || '', disabled: true }, []),
      artnr: this.formBuilder.control({ value: this.claim?.extra?.ork?.artnr || '', disabled: this.claim?.id ? true : false }, [Validators.required]),
    });
  }

  filterClaimWork(option: { key: string; value: string | TableRecord }): boolean {
    return option.key === '10' || option.key === '20';
  }

  onCreateClaim(element) {
    if (!this.orkForm.valid) {
      this.orkForm.markAllAsTouched();
      return;
    }
    const rawValues = this.orkForm.getRawValue()
    const name = rawValues.name;
    const email = rawValues.email;
    delete rawValues.name;
    delete rawValues.email;
    const edit = name + '--' + email + '\n' + rawValues.edit;
    const requestBody: Claim = {
      customerId: this.customer.id,
      extra: {
        ork: {
          ...rawValues,
          edit
        }
      }
    };
    gungAddRemoveSpinner(element);
    this.jeevesClaimService.postClaim(this.customer.id, requestBody).subscribe(
      result => {
        this.activeModal.close(result);
      },
      error => {
        gungAddRemoveSpinner(element);
        // this.activeModal.close(false);
      },
      () => {
        gungAddRemoveSpinner(element);
      }
    );
  }

  selectProduct({ item, preventDefault }) {
    this.orkForm.get('artnr').patchValue(item.id);
  }

  ngModelChange(item, target) {
    if (!item) {
      this.orkForm.get('artnr').patchValue('');
    }
  }

  focusout({ target }, modelSearch) {
    if (!modelSearch) {
      target.value = '';
    }
  }
}
