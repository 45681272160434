import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs';
import { PdfDownloadIndicatorService } from '../../services/pdf-download-indicator.service';

@Component({
  selector: 'lib-pdf-download-indicator',
  templateUrl: './pdf-download-indicator.component.html',
  styleUrls: ['./pdf-download-indicator.component.css']
})
export class PdfDownloadIndicatorComponent implements OnInit, OnDestroy {
  public unsubscribe: Subject<void> = new Subject();

  public isDownloading = false;
  data;

  constructor(protected pdfDownloadIndicatorService: PdfDownloadIndicatorService) {}

  ngOnInit(): void {
    this.pdfDownloadIndicatorService
      .isDownloading()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ downloading, data }) => {
        this.isDownloading = downloading;
        this.data = data;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
