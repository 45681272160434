import { Inject, Injectable, Optional, Type } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { DashboardCard } from '../types';

import { AuthService, NavigationConfig } from 'gung-standard';

import { SalesSummaryCardComponent } from '../components/cards/sales-summary-card/sales-summary-card.component';

import { InvoiceStatisticsChartCardComponent } from '../components/cards/invoice-statistics-chart-card/invoice-statistics-chart-card.component';
import { LastVsThisYearSalesAmountSummaryCardComponent } from './../components/cards/last-vs-this-year-sales-amount-summary-card/last-vs-this-year-sales-amount-summary-card.component';
import { LastVsThisMonthSalesAmountSummaryCardComponent } from '../components/cards/last-vs-this-month-sales-amount-summary-card/last-vs-this-month-sales-amount-summary-card.component';
import { LastVsThisWeekSalesAmountSummaryCardComponent } from '../components/cards/last-vs-this-week-sales-amount-summary-card/last-vs-this-week-sales-amount-summary-card.component';
import { LastVsThisYearOfferAmountSummaryCardComponent } from '../components/cards/last-vs-this-year-offer-amount-summary-card/last-vs-this-year-offer-amount-summary-card.component';
import { LastVsThisMonthOffersAmountSummaryCardComponent } from '../components/cards/last-vs-this-month-offers-amount-summary-card/last-vs-this-month-offers-amount-summary-card.component';
import { OfferStatisticsChartCardComponent } from '../components/cards/offer-statistics-chart-card/offer-statistics-chart-card.component';
import { OfferTypesCardComponent } from '../components/cards/offer-types-card/offer-types-card.component';
import { StatisticsExcelExportCardComponent } from '../components/cards/statistics-excel-export-card/statistics-excel-export-card.component';

@Injectable({
  providedIn: 'root'
})
export class DashboardCardService {
  constructor(
    protected authService: AuthService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig
  ) {
    this.addCard(new DashboardCard(LastVsThisYearSalesAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3'));
    this.addCard(new DashboardCard(LastVsThisMonthSalesAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3'));
    this.addCard(new DashboardCard(LastVsThisWeekSalesAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3'));
    if (environment.enableOffers) {
      this.getOffersCards();
    }
    // This below card is removed since it is very ineffective and makes all the applications take long time to load.
    // this.addCard(
    //   new DashboardCard(LastSevenDaysOrdersSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3')
    // );
    // this.addCard(
    //   new DashboardCard(MonthlySalesSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3')
    // );
    // this.addCard(
    //   new DashboardCard(MonthlySalesAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3')
    // );
    // this.addCard(
    //   new DashboardCard(CustomersWhoPurchasedCardComponent, 'col-xs-12 col-md-6 col-xl-3')
    // );
    // this.addCard(
    //   new DashboardCard(ActiveProductsCardComponent, 'col-xs-12 col-md-6 col-xl-3')
    // );
    this.addCard(new DashboardCard(InvoiceStatisticsChartCardComponent, 'col-xs-12 col-md-12 col-xl-12'));
    if (environment.enableOffers) {
      this.addCard(new DashboardCard(OfferStatisticsChartCardComponent, 'col-xs-12 col-md-12 col-xl-12'));
    }
    this.addCard(new DashboardCard(SalesSummaryCardComponent, 'col-xs-12 col-md-12 col-xl-12'));

    // Not worth putting into the environment type, this is only here temporary, will be controlled elsewhere soon
    // @ts-ignore
    if (environment.enableStatisticsExcelExport) {
      this.addCard(new DashboardCard(StatisticsExcelExportCardComponent, 'col-xs-12 col-md-6 col-xl-3'));
    }
  }

  private cards: BehaviorSubject<DashboardCard[]> = new BehaviorSubject<DashboardCard[]>([]);

  protected addCard(card: DashboardCard): void {
    this.cards.next(this.cards.getValue().concat(card));
  }

  protected clearCards(): void {
    this.cards.next([]);
  }

  protected removeCard(card: DashboardCard): void {
    this.cards.next(this.cards.getValue().filter(c => c !== card));
  }

  getCards(): BehaviorSubject<DashboardCard[]> {
    return this.cards;
  }

  getOffersCards() {
    this.addCard(new DashboardCard(LastVsThisYearOfferAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3'));
    this.addCard(new DashboardCard(LastVsThisMonthOffersAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3'));
    this.addCard(new DashboardCard(OfferTypesCardComponent, 'col-xs-12 col-md-6 col-xl-3'));
  }
}
