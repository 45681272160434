<div class="card mb-2">
  <div class="card-body">
    <div class="card-title row">
      <div class="col">
        <a [routerLink]="'/orders/' + data.id">
          <i class="fas fa-eye pr-1" style="font-size: larger"></i>
          <h5>{{ data.id }}</h5>
        </a>
      </div>
      <div class="col-auto ml-auto">
        <lib-action-btn [item]="data" [config]="configService"></lib-action-btn>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-3">
        <div class="pb-1">
          <lib-gung-date-output [gungTranslate]="'ORDER_DATE'" [value]="data.extra.oh.orddatum"></lib-gung-date-output>
        </div>
        <div class="pb-1">
          <lib-gung-date-output [gungTranslate]="'EXPECTED_DELIVERY_DATE'" [value]="data.extra.oh.ordberlevdat">
          </lib-gung-date-output>
        </div>
      </div>
      <div class="col-xs-12 col-md-3">
        <div class="pb-1">
          <lib-gung-meta-output
            [value]="data.extra.oh.ordstat"
            [gungTranslate]="'ORDER_STATUS'"
            metadataTable="x7"
            metaOptionName="ordstatbeskr"
          ></lib-gung-meta-output>
        </div>
        <div class="pb-1">
          <div class="form-group">
            <label class="mb-0 font-weight-bold" translate>TOTAL</label>
            <lib-price
              [price]="data.extra.oh.ohvbordsum"
              [currency]="data.extra.oh.valkod"
              class="form-control-plaintext p-0"
            ></lib-price>
          </div>
        </div>
      </div>
      <div class="col col-xs-6 col-md-3">
        <div class="pb-1">
          <lib-gung-text-input
            [gungTranslate]="'NAME'"
            [renderStatic]="true"
            [ngModel]="
              data.extra.oh.ordlevadr1 ||
              (data.extra.deliveryCustomer ? data.extra.deliveryCustomer.name : data.extra.customerName)
            "
          >
          </lib-gung-text-input>
          <div class="pb-1">
            <lib-gung-meta-output
              [value]="data.extra.oh.ordlevadrlandskod"
              [gungTranslate]="'COUNTRY'"
              metadataTable="xr"
              metaOptionName="land"
            ></lib-gung-meta-output>
          </div>
        </div>
      </div>
      <div class="col col-xs-6 col-md-3">
        <div class="pb-1">
          <lib-gung-text-input
            [gungTranslate]="'YOUR_REFERENCE'"
            [renderStatic]="true"
            [ngModel]="data.extra.oh.kundref2"
          >
          </lib-gung-text-input>
          <div class="pb-1">
            <lib-gung-text-input
              [gungTranslate]="'PO_NUMBER'"
              [renderStatic]="true"
              [ngModel]="data.extra.oh.kundbestnr"
            >
            </lib-gung-text-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
