import { Component, OnInit } from '@angular/core';
import { GungSettingsBaseViewComponent } from '../../gung-settings-base-view/gung-settings-base-view.component';
import { ConfigurationsService, GungConfiguration } from '../../../services/configurations.service';
import { Observable, Subscription, first, forkJoin, of, switchMap } from 'rxjs';
import { OptionsListMulti, checkObjectEquals } from 'gung-common';
import { UsersService } from '../../../services/users/users.service';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { SystemSettings } from '../../../models/system-setting';

@Component({
  selector: 'lib-gung-settings-users-view',
  templateUrl: './gung-settings-users-view.component.html',
  styleUrls: ['./gung-settings-users-view.component.css']
})
export class GungSettingsUsersViewComponent extends GungSettingsBaseViewComponent implements OnInit {
  protected systemSettings: SystemSettings;
  protected configurations: { [s: string]: GungConfiguration } = {};
  public newConfigurations: { [s: string]: GungConfiguration } = {};

  public availableUserGroupOptions: OptionsListMulti[] = [];


  constructor(
    protected configurationsService: ConfigurationsService,
    protected gungFlowService: GungFlowService
  ) {
    super();
  }

  ngOnInit(): void {
    this.configurationsService.getConfigurationsByIds(['NewUserDefaultsConfig']).pipe(
      first(),
      switchMap(configs => forkJoin({
        configs: of(configs),
        systemSettings: this.gungFlowService.getSystemGung().pipe(first())
      }))
    ).subscribe(({ configs, systemSettings }) => {
      for (const config of configs) {
        this.configurations[config.id] = config;
      }
      this.systemSettings = systemSettings;
      this.reset();
    })
  }

  public reset(): void {
    this.newConfigurations = structuredClone(this.configurations);
    this.availableUserGroupOptions = [];
    for (const group of this.systemSettings?.extra?.userGroups) {
      this.availableUserGroupOptions.push({
        id: group,
        name: group,
        selected: this.newConfigurations?.['NewUserDefaultsConfig']?.defaultActiveUserGroups?.includes(group)
      });
    }
    this.checkForChanges();
  }

  public onSave(): void {
    if (Object.keys(this.newConfigurations).length === 0) {
      return;
    }

    this.checkForChanges();

    const subscriptions: Observable<any>[] = [];

    for (const configKey in this.newConfigurations) {
      if (this.newConfigurations.hasOwnProperty(configKey)) {
        const config = this.newConfigurations[configKey];
        subscriptions.push(this.configurationsService.updateConfiguration(config).pipe(first()));
      }
    }

    forkJoin(subscriptions).pipe(first()).subscribe(res => {
      console.log('Res', res);
      this.unsavedChanges = false;
    });
  }

  protected checkForChanges(): void {
    if (checkObjectEquals(this.configurations, this.newConfigurations)) {
      this.unsavedChanges = false;
    } else {
      this.unsavedChanges = true;
    }
  }

  public addMultistockId(ele: HTMLInputElement): void {
    if (!ele.value) {
      return;
    }
    this.newConfigurations?.['NewUserDefaultsConfig']?.defaultMultistockIds.push(ele.value);
    ele.value = '';
    this.checkForChanges();
  }

  public removeMultistockId(index: number): void {
    this.newConfigurations?.['NewUserDefaultsConfig']?.defaultMultistockIds.splice(index, 1);
    this.checkForChanges();
  }

  public setSelectedUserGroupValues(values: string[]): void {
    this.newConfigurations['NewUserDefaultsConfig'].defaultActiveUserGroups = values;
    this.checkForChanges();
  }
}
