<div class="container" *ngIf="loaded; else loading">
    <div class="row">
        <div class="col-12 col-sm-6 col-md-5 col-lg-3">
            <lib-gung-select-option-input
                gungTranslate="LIST"
                [optionsList]="catalogueOptions"
                [(ngModel)]="selectedCatalogId"
                (change)="changeCatalog()"
            ></lib-gung-select-option-input>
        </div>
    </div>
      
    <div class="row h-100">
        <div class="col-12 d-flex flex-row justify-content-center" *ngIf="selectedCatalogHtml" [innerHTML]="selectedCatalogHtml | safeHtml">
        </div>
    </div>
</div>

<ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>
  