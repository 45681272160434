import { Component, OnInit } from '@angular/core';
import { JeevesCustomerContactCardListComponent, JeevesModalService } from 'gung-standard-jeeves';
import { GungModalService } from 'gung-standard';

@Component({
  selector: 'sjoborg-jeeves-customer-contact-card-list',
  templateUrl: './sjoborg-jeeves-customer-contact-card-list.component.html',
  styleUrls: ['./sjoborg-jeeves-customer-contact-card-list.component.scss']
})
export class SjoborgJeevesCustomerContactCardListComponent
  extends JeevesCustomerContactCardListComponent
  implements OnInit
{
  constructor(protected modalService: JeevesModalService, gungModalService: GungModalService) {
    super(modalService);
  }
  ngOnInit() {
    this.phone = this.data.extra.CS0 ? this.data.extra.CS0.comnr || '-' : '-';
    this.mobile = this.data.extra.CS4 ? this.data.extra.CS4.comnr || '-' : '-';
    this.email = this.data.extra.CS7 ? this.data.extra.CS7.comnr || '-' : '-';
  }
}
