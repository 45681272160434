import { Directive, Type } from "@angular/core";
import { ActionButton } from "./action-button/action-button";

export interface GungTableHeader{
    name: string;
    path: string;
    sortable: boolean;
    searchable?: boolean;
    sortActivated?: 'NONE' | 'ASC' | 'DESC';
    enableFilter?: boolean;
}

export interface GungTableActiveFilter{
    headerIndex: number;
    values: {value: string, qty: number, active: boolean}[],
    activeValues: string[],
    showFilterBox: boolean
}

export interface GungTableConfig<T> {
    headers: GungTableHeader[];
    data: T[];
    enableSearch: boolean
    title?: string;
    customBodyComponent?: Type<GungTableCustomBodyComponent<T>>;
    itemsPerPage?: number
    headActions?: ActionButton<T>[]
}


@Directive()
export class GungTableCustomBodyComponent<T> {
    private _dataTable: string[][];
    private _data: T[];

    set dataTable(value: string[][]) {
        this._dataTable = value;
        this.handleDataTableChange();
    }

    get dataTable(): string[][] {
        return this._dataTable;
    }

    set data(value: T[]) {
        this._data = value;
    }

    get data(): T[] {
        return this._data;
    }

    
    /**
     * if you want do something when detect changes
     */
    handleDataTableChange(){

    }
}
