<div class="row" *ngIf="form && selectedDeliveryLocation">
  <div class="col-12">
    <div class="form-group">
      <label for="deliveryLocation" class="control-label" translate>DELIVERY_LOCATION</label>
      <select name="deliveryLocation" id="deliveryLocation" class="form-control form-select" [(ngModel)]="deliveryLocationId">
        <option [value]="option.id" *ngFor="let option of deliveryLocations; let first = first">
          {{ first ? ('CUSTOMER_ADDRESS' | translate) : option.name }}
        </option>
      </select>
    </div>
  </div>

  <div class="col-12 col-md-6">
    <div class="form-group" [formGroup]="form">
      <label for="ordlevadr1" class="control-label" translate>OH_ORDLEVADR1</label>
      <input class="form-control" type="text" name="ordlevadr1" id="ordlevadr1" [formControlName]="'ordlevadr1'" />
    </div>
    <div class="form-group" [formGroup]="form">
      <label for="ordlevadr2" class="control-label" translate>OH_ORDLEVADR2</label>
      <input class="form-control" type="text" name="ordlevadr2" id="ordlevadr2" [formControlName]="'ordlevadr2'" />
    </div>
    <div class="form-group" [formGroup]="form">
      <label for="ordlevadr3" class="control-label" translate>OH_ORDLEVADR3</label>
      <input class="form-control" type="text" name="ordlevadr3" id="ordlevadr3" [formControlName]="'ordlevadr3'" />
    </div>
    <div class="form-group">
      <label for="postalCode" class="control-label" translate>OH_FTGPOSTNR</label>
      <input class="form-control" type="text" name="postalCode" id="postalCode" [(ngModel)]="postalCode" />
    </div>
    <div class="form-group">
      <label for="city" class="control-label" translate>OH_ORDLEVADR4</label>
      <input class="form-control" type="text" name="city" id="city" [(ngModel)]="city" />
    </div>
    <div [formGroup]="form">
      <lib-meta-select-input formControlName="ordlevadrlandskod" table="xr" metaId="landskod" metaDisplay="land">
      </lib-meta-select-input>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <lib-confirmation-emails
      [emails]="checkout.extra.confirmationEmails"
      (emailsUpdated)="checkout.extra.confirmationEmails = $event"
    ></lib-confirmation-emails>
    <div class="form-group mb-0" [formGroup]="formOh">
      <label for="kundbestnr" class="control-label" translate>OH_KUNDBESTNR</label>
      <input class="form-control" type="text" name="kundbestnr" id="kundbestnr" formControlName="kundbestnr" />
    </div>
    <div
      *ngIf="formOh.get('kundbestnr').invalid && (formOh.get('kundbestnr').dirty || formOh.get('kundbestnr').touched)"
      class="alert alert-danger"
    >
      <span translate>FORM_ERROR_REQUIRED</span>
    </div>
    <div class="form-group mb-0" [formGroup]="formOh">
      <label for="kundref2" class="control-label" translate>OH_KUNDREF2</label>
      <input class="form-control" type="text" name="kundref2" id="kundref2" formControlName="kundref2" />
    </div>
    <div
      *ngIf="formOh.get('kundref2').invalid && (formOh.get('kundref2').dirty || formOh.get('kundref2').touched)"
      class="alert alert-danger"
    >
      <span translate>FORM_ERROR_REQUIRED</span>
    </div>
    <div class="form-group" [formGroup]="formOh">
      <label for="editext" lass="control-label" translate>MESSAGE</label>
      <textarea
        class="form-control"
        type="text"
        name="editext"
        id="editext"
        rows="6"
        formControlName="editext"
      ></textarea>
    </div>
  </div>
</div>

<!--
    oh.ordberlevdat // Leverans datum TODO-AL
 -->
