import { Component, OnInit } from '@angular/core';
import {
  ProductDetailComponent,
  PriceService,
  AuthService,
  AvailabilityService,
  ProductService,
  BaseDetailData,
  GungFlowService,
  AssortmentService,
  MetadataService
} from 'gung-standard';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gung-jeeves-jeeves-product-details',
  templateUrl: './jeeves-product-details.component.html',
  styleUrls: ['./jeeves-product-details.component.css']
})
export class JeevesProductDetailsComponent extends ProductDetailComponent implements OnInit {
  public addonIds: string[] = [];
  public pimRelatedProductsIds: string[] = [];
  public isOnlyDisplayFlow = false;

  isSales: boolean;
  selectedImageIndex = 0;
  carousel: {
    images: any[];
    countAll: number;
    containsAll: boolean;
    startIndex: number;
    endIndex: number;
  };

  constructor(
    protected route: ActivatedRoute,
    protected productService: ProductService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected translateService: TranslateService,
    protected gungFlowService: GungFlowService,
    metadataService: MetadataService,
    assortmentService: AssortmentService
  ) {
    super(
      route,
      productService,
      availabilityService,
      authService,
      priceService,
      metadataService,
      translateService,
      assortmentService,
      gungFlowService
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(
        roles =>
          (this.isSales =
            roles.filter(role => role.toUpperCase() === 'ADMIN' || role.toUpperCase() === 'SALES').length > 0)
      );

    // if flow is only display then do not show the buy-btn, because for only display it becomes a go to product details (where we are)
    this.gungFlowService
      .getSelectedFlow()
      .pipe(first())
      .subscribe(flow => {
        this.isOnlyDisplayFlow = !!flow && !!flow.displayOnly;
      });
  }

  protected handleObservable(observable: Observable<BaseDetailData>): void {
    observable.subscribe(d => {
      this.pimRelatedProductsIds = [];
      this.addonIds = [];
      const { product, availability, price, user } = d;
      if (product.extra.artv) {
        this.addonIds = product.extra.artv.map(r => r.artnrtillvalart);
      }
      if (product.extra.pim && product.extra.pim.relatedProducts) {
        this.pimRelatedProductsIds = product.extra.pim.relatedProducts.split(',');
      }
      this.product = product;
      this.availability = availability;
      this.price = price;

      this.isAnonymous = user.roles.filter(role => role.toUpperCase() === 'ANONYMOUS').length > 0;

      this.initImagesCarousel();
      this.getTemplate();
    });
  }

  trackByFn(index: number, item: any) {
    return index;
  }

  initImagesCarousel(): void {
    // take first 3 images to build the carousel
    if (this.product?.extra?.images?.length > 1) {
      this.carousel = {
        countAll: this.product.extra.images.length,
        startIndex: 0,
        endIndex: 2,
        containsAll: this.product.extra.images.length <= 3,
        images: this.product.extra.images.slice(0, 3)
      };
    }
  }

  setSelectedImage(s3Uri: string): void {
    this.selectedImageIndex = this.product.extra.images.findIndex(image => image.s3Uri === s3Uri);
  }

  scrollImages(direction: string): void {
    if (direction === 'right') {
      this.carousel.startIndex++;
      this.carousel.endIndex++;
    } else {
      this.carousel.startIndex--;
      this.carousel.endIndex--;
    }

    this.carousel.images = this.product.extra.images.slice(this.carousel.startIndex, this.carousel.endIndex + 1);
  }

  getProductDescription(): Observable<string> {
    const lang = this.translateService.currentLang || 'se';

    let description = '';
    if (this.product?.extra?.i18n && this.product?.extra?.i18n[lang]) {
      description = this.product.extra.i18n[lang].description || '';
    }

    return of(description);
  }
}
