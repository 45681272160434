export interface ReportCentralReport {
  id: string;
  urls: ReportCentralUrl[];
  userId: string;
  description: string;
  createdAt: Date;
  finishedAt: Date;
  status: ReportCentralReportStatus;
}

export interface ReportCentralUrl {
  url: string;
  name: string;
}

export enum ReportCentralReportStatus {
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR'
}
