import { Injectable } from '@angular/core';
import { Product } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class PackSizeHelperService {
  constructor() {}

  getPackageSize(product: Product) {
    // Default to 1 since in standard we don't have this information available.
    return 1;
  }
}
