import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, first, forkJoin, map, Observable, of, Subject, switchMap } from 'rxjs';
import { Product } from '../../models';
import { BackendPriceLevel, CustomerProductPrice, Price } from '../../models/price';
import { CartService } from '../cart/cart.service';
import { PriceService } from '../price/price.service';
import { ProductService } from '../products/product.service';
import { GungCurrencyPipe } from '../../pipes/gung-currency.pipe';
import { GungAnonymousConfigService } from '../gung-anonymous-config/gung-anonymous-config.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PriceConfigService {
  public enableHidePrice = false;
  public hidePrice: Subject<boolean> = new BehaviorSubject<boolean>(false);

  public isAnonymous = true;
  public showAnonymousPrices = this.gungAnonymousConfigService.showAnonymousPrices;

  constructor(
    protected currencyPipe: GungCurrencyPipe,
    protected cartService: CartService,
    protected priceService: PriceService,
    protected productService: ProductService,
    protected authService: AuthService,
    protected gungAnonymousConfigService: GungAnonymousConfigService
  ) {
    this.authService.getCurrentUser().pipe(filter(user => !!user), first()).subscribe(user => {
      this.isAnonymous = user.roles.filter(role => role.toUpperCase() === 'ANONYMOUS').length > 0;
    });
  }

  protected getDisplay(): 'code' | 'symbol' | 'symbol-narrow' | string | boolean {
    return 'symbol-narrow';
  }

  protected getDigitsInfo(): string {
    return '1.2-2';
  }

  public getCurrency(): string {
    return 'SEK';
  }

  protected getLocale(): string {
    return;
  }

  public getPrice(
    price: CustomerProductPrice | string,
    mode: string,
    display?: string | boolean,
    currency?: string,
    product?: Product
  ): string {
    if (this.isAnonymous && !this.showAnonymousPrices) {
      return '';
    }
    const priceObject: boolean = typeof price === 'object';
    const priceValue = (priceObject && mode) ? (price[mode]?.value ?? price[mode] ?? price) : price;
    const currencyValue = (priceObject && mode) ? (price[mode]?.currencyCode ?? currency) : currency;

    return this.currencyPipe.transform(
      priceValue,
      currencyValue || this.getCurrency(),
      display || this.getDisplay(),
      this.getDigitsInfo(),
      this.getLocale()
    );
  }

  public getStaffedPrice(
    price: CustomerProductPrice,
    mode: string,
    quantity: number,
    display?: string | boolean,
    currency?: string
  ): BackendPriceLevel {
    return this.priceService.getPriceLevel(price.backendPrice, quantity);
  }

  public getPriceHTML(
    price: CustomerProductPrice | string,
    mode: string,
    display?: string | boolean,
    currency?: string,
    product?: Product
  ): string {
    const priceString = this.getPrice(price, mode, display, currency, product);
    return `<span>${priceString}</span>`;
  }

  public getPriceHtmlObservale(
    price: CustomerProductPrice | string,
    mode: string,
    display?: string | boolean,
    currency?: string
  ): Observable<string> {
    return this.hidePrice.pipe(
      switchMap(hidePrice => {
        if (hidePrice) {
          return of(null);
        }
        return of(this.getPriceHTML(price, mode, display, currency));
      })
    );
  }

  public getStaffedPriceHTML(
    price: CustomerProductPrice,
    mode: string,
    display?: string | boolean,
    currency?: string,
    displayOnlyPrice?: boolean,
    overrideQty?: number,
    product?: Product
  ): Observable<string> {
    return this.getProductQtyInCart(price.productId).pipe(
      map(quantity => {
        const priceLevel = this.getStaffedPrice(price, mode, overrideQty || quantity || 1, display, currency);
        const discountToUse = this.priceService.getCustomerDiscountPercent(priceLevel, price.backendPrice);
        const priceVal = this.getStaffedPriceValue(price.customerGrossPrice, discountToUse);

        const priceString = this.currencyPipe.transform(
          price.customerGrossPrice.value,
          price.customerGrossPrice.currencyCode || this.getCurrency(),
          display || this.getDisplay(),
          this.getDigitsInfo(),
          this.getLocale()
        );
        const priceStaffedString = this.currencyPipe.transform(
          priceVal,
          price.customerGrossPrice.currencyCode || this.getCurrency(),
          display || this.getDisplay(),
          this.getDigitsInfo(),
          this.getLocale()
        );
        return this.createStaffedHTML(priceStaffedString, discountToUse, priceString, displayOnlyPrice, product);
      })
    );
  }

  protected getProductQtyInCart(productId: string): Observable<number> {
    switch (this.priceService.qtyGroupingLevel) {
      case 'MODEL':
        return this.getGroupedProductQty(productId, 'MODEL');
      case 'VARIANT': {
        return this.getGroupedProductQty(productId, 'MODEL');
      }
      default: {
        return this.cartService.getProductQty(productId);
      }
    }
  }

  protected getGroupedProductQty(productId: string, groupingLevel: string): Observable<number> {
    return forkJoin([
      groupingLevel === 'MODEL'
        ? this.productService.getModelId(productId).pipe(first())
        : this.productService.getVariantProductId(productId).pipe(first()),
      this.cartService.getCurrentCart().pipe(first())
    ]).pipe(
      first(),
      switchMap(([groupedId, cartRows]) => {
        let qty = 0;
        cartRows.forEach(row => {
          if (row.productId.startsWith(groupedId)) {
            qty += row.qty;
          }
        });
        return of(qty);
      })
    );
  }

  protected createStaffedHTML(
    priceStaffedString: string,
    discountPercent: number,
    priceString: string,
    displayOnlyPrice?: boolean,
    product?: Product
  ) {
    if (displayOnlyPrice) {
      return `<span>${priceStaffedString}</span>`;
    } else if (!!discountPercent && discountPercent !== 0) {
      return `<div>${priceStaffedString} <small>(-${discountPercent?.toFixed(
        2
      )}%)</small><span class='text-muted float-right'>${priceString}</span></div>`;
    } else {
      return `<span>${priceString}</span>`;
    }
  }

  public setHidePrice(hidePrice: boolean) {
    this.hidePrice.next(hidePrice);
  }

  public getCartPriceTotalHTML(price: number, priceAfterDiscount: number, currencyCode: string): string {
    const showDiscount: boolean = price !== priceAfterDiscount;
    const priceString = this.currencyPipe.transform(
      price,
      currencyCode || this.getCurrency(),
      this.getDisplay(),
      this.getDigitsInfo(),
      this.getLocale()
    );
    const priceDiscountString = this.currencyPipe.transform(
      priceAfterDiscount,
      currencyCode || this.getCurrency(),
      this.getDisplay(),
      this.getDigitsInfo(),
      this.getLocale()
    );
    if (showDiscount) {
      return `<span class="price-without-discount" style="text-decoration: line-through;">${priceString}</span>
              <span class="price-with-discount">${priceDiscountString}</span>`;
    } else {
      return `<span>${priceString}</span>`;
    }
  }

  private getStaffedPriceValue(customerGrossPrice: Price, discount: number) {
    return customerGrossPrice.value * (1 - discount / 100);
  }
}
