import { HttpClient } from '@angular/common/http';
import { Component, OnInit, SimpleChanges, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService } from 'gung-common';
import { ListItemRendererComponent } from 'gung-list';
import { Subject, takeUntil, Observable, forkJoin } from 'rxjs';
import { SupplierPurchaseOrder, ColumnRowTableType, SupplierTableColumns } from '../../../models';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { MetadataService } from '../../../services/metadata/metadata.service';
import {
  SupplierDynamicColumnsService,
  getValuesDynamic,
  setValueDynamic
} from '../../../services/supplier-dynamic-columns/supplier-dynamic-columns.service';
import { SupplierPoActionConfigService } from '../../../services/supplier-po-action-config/supplier-po-action-config.service';
import { SupplierService } from '../../../services/suppliers/supplier.service';
import { TableRecord } from '../../../state/metadata/types';
import { gungAddRemoveSpinner, gungComparatorHelper } from '../../../utils/gung-utils';
import { ColumnSortSelectionService } from '../../../services/column-sort-selection/column-sort-selection.service';
import { SupplierSortColumnService } from '../supplier-sort-column/supplier-sort-column.service';

@Component({
  selector: 'lib-supplier-po-list-table-view',
  templateUrl: './supplier-po-list-table-view.component.html',
  styleUrls: ['./supplier-po-list-table-view.component.css']
})
export class SupplierPoListTableViewComponent
  extends ListItemRendererComponent<SupplierPurchaseOrder[]>
  implements OnInit, AfterViewInit
{
  public ColumnRowTableType = ColumnRowTableType;
  tableColumn: SupplierTableColumns[];
  detailsColumn: SupplierTableColumns[];

  today = new Date();
  postatus: [TableRecord];
  // private subscriptions = new Subscription();
  private destroyed: Subject<void> = new Subject();

  public currentStatus: number;
  nextStatus: number;
  nextStatusName: string;
  rejectStatus: number;
  rejectStatusName: string;

  constructor(
    public dateUtilService: DateUtilService,
    protected supplierService: SupplierService,
    protected http: HttpClient,
    protected modalService: GungModalService,
    protected metadataService: MetadataService,
    protected activatedRoute: ActivatedRoute,
    public supplierPoActionConfigService: SupplierPoActionConfigService,
    public supplierDynamicColumnsService: SupplierDynamicColumnsService,
    protected translationService: TranslateService,
    protected columnSortService: SupplierSortColumnService
  ) {
    super();
  }

  ngOnInit(): void {
    this.postatus = this.metadataService.getMetadataTableList('POSTATUS');
    if (this.postatus?.length > 0) {
      this.postatus = Array(...this.postatus).sort((a, b) => gungComparatorHelper(a.id, b.id, 1)) as any;
    }
    this.initData();
    this.activatedRoute.params.pipe(takeUntil(this.destroyed)).subscribe(data => {
      // this.data.splice(0, this.data.length);
      this.data = undefined;
    });
  }
  
  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initData();
  }

  ngOnDestroy(): void {
    this.destroyed.next;
  }

  initData() {
    this.currentStatus = this.activatedRoute.snapshot.params?.purchaseOrderType;
    this.tableColumn = this.supplierDynamicColumnsService.getPoStatusColumnTable(this.currentStatus);
    if (!this.tableColumn) {
      console.error('Supplier portal - missing dynamic table info');
    }
    // Next status
    const nextStatus = this.tableColumn?.find(c => c.path?.indexOf('btnMoveNextStatus') > -1)?.extra.nextStatus;
    if (nextStatus) {
      this.nextStatus = Number(nextStatus);
      const postatus = this.postatus?.find(s => s.id === nextStatus);
      if (postatus) {
        this.nextStatusName = postatus.name;
      }
    } else {
      this.nextStatus = this.currentStatus !== undefined && this.currentStatus !== null && Number(this.getNextStatus(this.currentStatus.toString()));
      this.nextStatusName = this.postatus?.find(p => p.id === this.nextStatus.toString())?.name;
    }
    // Reject status
    const rejectStatus = this.tableColumn?.find(c => c.path?.indexOf('btnMoveRejectStatus') > -1)?.extra.rejectStatus;
    if (rejectStatus) {
      this.rejectStatus = Number(rejectStatus);
      const postatus = this.postatus?.find(s => s.id === rejectStatus);
      if (postatus) {
        this.rejectStatusName = postatus.name;
      }
    }

    this.detailsColumn = this.supplierDynamicColumnsService.getPoSupplierTableColumnsDetails(this.currentStatus);
    const idxAction = this.tableColumn.findIndex(c => c.type === ColumnRowTableType.ACTION);
    if (idxAction > -1) {
      this.supplierPoActionConfigService.currentStatus = this.currentStatus;
      this.supplierPoActionConfigService.nextStatus = this.nextStatus;
      this.supplierPoActionConfigService.nextStatusName = this.nextStatusName;
      this.supplierPoActionConfigService.rejectStatus = this.rejectStatus;
      this.supplierPoActionConfigService.rejectStatusName = this.rejectStatusName;
      this.supplierPoActionConfigService.tableColumn = this.tableColumn;
      this.supplierPoActionConfigService.detailsColumn = this.detailsColumn;
      const actions = this.tableColumn[idxAction].path;
      this.supplierPoActionConfigService.setActions(actions);
    }
  }

  trackByFn(index: any, item: SupplierPurchaseOrder) {
    return index + '_' + item.id;
  }

  confirmAllCheckedLines({ target }) {
    gungAddRemoveSpinner(target);
    const lineToUpdate: SupplierPurchaseOrder[] = [];
    for (const item of this.data) {
      if ((item as any).checked) {
        lineToUpdate.push(item);
      }
    }
    if (lineToUpdate.length === 0) {
      gungAddRemoveSpinner(target);
      return;
    }
    let requests: Observable<SupplierPurchaseOrder>[] = lineToUpdate.map(po =>
      this.supplierPoActionConfigService.confirmPurchaseLineRequest(po)
    );
    let errorFound = lineToUpdate.findIndex(line => line.extra.errors) > -1;
    if (errorFound) {
      gungAddRemoveSpinner(target);
      return;
    }
    this.modalService.openConfirmYesNoModal(
      undefined,
      this.translationService.instant('SUPPLIER_PORTAL_MOVE_ORDER_ROW_NEXT_STATUS', { orderId: lineToUpdate[0].orderId, rowNumber: lineToUpdate.map(l => l.rowNumber || l.id).join(', '), nextStatus: this.nextStatusName }) + '?',
      { size: 'sm' }
    )
    .then(result => {
      if (result) {
        forkJoin(requests).subscribe(retult => {
          gungAddRemoveSpinner(target);
        });
      }
    });
  }

  public detailsPurchaseLine(item: SupplierPurchaseOrder, { target }) {
    this.supplierPoActionConfigService.detailsPurchaseLine(item, { target });
  }

  getValues(row: SupplierPurchaseOrder, path: string | string[] | (string | string[])[], iteration = 0) {
    return getValuesDynamic(row, path);
    // if (Array.isArray(path)) {
    //   if (iteration === 0) {
    //     return path.map(p => this.getValues(row, p, iteration++)).join(', ');
    //   }
    //   return path.map(p => this.getValues(row, p, iteration++)).join('');
    // }
    // return gungGetPropertyOfObject(path, row) || (path.length === 1 ? path : null);
  }

  setValue(row: SupplierPurchaseOrder, path: string, value: any) {
    return setValueDynamic(row, path, value);
    // const result = gungSetPropertyOfObject(row, path, value);
    // if (!result) {
    //   console.log('NOT SAVED - result', result);
    // }
  }

  public isArray(arr: any | any[]) {
    return Array.isArray(arr);
  }

  getNextStatus(statusId: string, previous?: boolean): string {
    const idx = this.postatus?.findIndex(s => s.id === statusId);
    if (!previous && idx > -1 && idx < this.postatus?.length - 1) {
      if (this.rejectStatus && this.rejectStatus?.toString() === this.postatus[idx + 1]?.id) {
        return this.getNextStatus(this.postatus[idx + 1]?.id); // Next is rejected get next
      }
      return this.postatus[idx + 1]?.id.toString();
    }
    // Get status before rejected
    if (previous && idx > 0 && idx < this.postatus.length - 1) {
      return this.postatus[idx - 1]?.id.toString();
    }
    return null;
  }

  sortColumn(column){
    this.clearSortColumns(column);
    const ascSort = !column.sort || column.sort === 'asc' ? -1 : 1;
    column.sort = ascSort === 1 ? 'asc' : 'desc';
    
    this.columnSortService.setSelectedColumnSort(column);
  }

  clearSortColumns(column) {
    // clear all other columns sorts
    for (const c of this.tableColumn) {
      if(c.name !== column.name){
        c.sort = undefined;
      }
      
    }
  }

}
