import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { OptionsListMulti } from 'gung-common';
import { map, Observable } from 'rxjs';
import { MetadataService } from '../../services/metadata/metadata.service';
import { MetaSelectInputComponent } from '../meta-select-input/meta-select-input.component';

@Component({
  selector: 'lib-meta-multi-select-input',
  templateUrl: './meta-multi-select-input.component.html',
  styleUrls: ['./meta-multi-select-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaMultiSelectInputComponent),
      multi: true
    }
  ]
})
export class MetaMultiSelectInputComponent extends MetaSelectInputComponent implements OnInit {

  metaOptionsMulti$: Observable<OptionsListMulti[]>;
  metaOptionsMulti: OptionsListMulti[];

  @Output()
  selectedValues = new EventEmitter<string[]>();

  @Input()
  currentSelectedValues: string[];

  @Input()
  previewEmpty?: string;

  @Input()
  public previewField?: string | string[];

  @Input()
  public dropdownCustomClass = 'form-control p-0';

  ngOnInit() {
    super.ngOnInit();
    this.metaOptionsMulti$ = this.metaOptions$.pipe(
      map(options => options.map(o => ({id: o.key, name: o.value, selected: this.currentSelectedValues?.includes(o.key)})))
    )
    this.metaOptionsMulti$.subscribe(data => this.metaOptionsMulti = data);
  }

  setSelectedValues(currentSelectedValues) {
    this.selectedValues.emit(currentSelectedValues);
  }

}
