import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { first, map, Observable, of } from 'rxjs';
import {
  ConfigBaseFilter,
  ConfigService,
  ExportSelection,
  FilterListRoutingService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SelectionAction,
  SimpleConfigBaseFilter
} from 'gung-list';
import { Bankgiro } from '../models/bankgiro';
import { BankgiroService } from './bankgiro.service';
import { BankgiroListViewComponent } from '../components/bankgiro-list-view/bankgiro-list-view.component';

export class BankgiroHasBeenExportedToBgMax extends SimpleConfigBaseFilter<Bankgiro> {
  getName(): string {
    return 'HAS_BEEN_EXPORTED';
  }

  getOptionIds(item: Bankgiro): string[] {
    return [item.usedInReport ? 'YES' : 'NO'];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class BankgiroAmountRangeFilter extends SimpleConfigBaseFilter<Bankgiro> {
  type = 'RangeConfigBaseFilter';

  getName(): string {
    return 'TRANSACTION_AMOUNT';
  }

  getOptionIds(item: Bankgiro): string[] {
    return ['' + item.paymentAmount];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class BankgiroCreationDateFilter extends SimpleConfigBaseFilter<Bankgiro> {
  type = 'dateRangeFilter';

  getName(): string {
    return 'DATE';
  }

  getOptionIds(item: Bankgiro): string[] {
    return [format(new Date(item.createdDate || Date()), 'yyMMdd')];
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class BankgiroListConfigService implements ConfigService<Bankgiro> {
  constructor(protected bankgiroService: BankgiroService, private filterListRoutingService: FilterListRoutingService) {}

  getFilters(): ConfigBaseFilter<Bankgiro>[] {
    return [new BankgiroHasBeenExportedToBgMax(), new BankgiroCreationDateFilter(), new BankgiroAmountRangeFilter()];
  }

  getItems(): Observable<Bankgiro[]> {
    return this.bankgiroService.getAllBankgiro().pipe(
      // Sort the list on createdDate so that newest is on top. Should maybe be moved to the backend, but works for now.
      map(bgs => bgs.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1)))
    );
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: Bankgiro): string {
    return item.id;
  }

  getLayouts(): ListLayout<Bankgiro>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => BankgiroListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Bankgiro List View'
      }
    ];
  }

  getSearchTerms(item: Bankgiro): string[] {
    return [item.id, item.orderNumber, item.ocrReference];
  }

  getSortOptions(): ListSortOption<Bankgiro>[] {
    return [];
  }

  getSelectionActions(): Observable<SelectionAction<Bankgiro>[]> {
    const actions: SelectionAction<Bankgiro>[] = [];

    actions.push({
      label: 'EXPORT_TO_BGMAX',
      performAction: (selection: ExportSelection<Bankgiro>): Observable<any> => {
        const ids = Object.keys(selection.selectedItems);
        this.bankgiroService
          .convertToBgmax(ids)
          .pipe(first())
          .subscribe(() => {
            this.filterListRoutingService.clearQueryParams();
            setTimeout(() => {
              location.reload();
            }, 500);
          });
        return of();
      }
    });

    return of(actions);
  }
}
