import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { EMPTY, catchError, delay, expand, of, repeat, retry, retryWhen, skipWhile, take, takeLast, tap, throwError, timer } from 'rxjs';
import { AuthBackendService } from '../../services/auth-backend/auth-backend.service';

@Component({
  selector: 'lib-gung-maintenance',
  templateUrl: './gung-maintenance.component.html',
  styleUrls: ['./gung-maintenance.component.css']
})
export class GungMaintenanceComponent implements OnInit {

  constructor(
    private router: Router,
    private authBackendService: AuthBackendService
  ) { }

  ngOnInit(): void {
    this.checkOnline();
  }

  checkOnline() {
    this.authBackendService.getUser().pipe(
      repeat({ delay: 5000 }),
      catchError((error) => throwError(() => new Error(error))),
      retry({ count: 10, delay: (error, retryCount) => timer(5000) }),
      take(1),
    ).subscribe({
      next: (v) =>{
        this.refreshApp();
      },
      error: (e) => { },
      complete: () => { } 
    });
  }

  refreshApp(){
    // Navigate to the root path
    this.router.navigateByUrl('/').then(() => {
      // Reload the current page
      window.location.reload();
    });
  }

}
