import { Component, OnInit } from '@angular/core';
import { reduceEachTrailingCommentRange } from 'typescript';

@Component({
  selector: 'lib-import-budget',
  templateUrl: './import-budget.component.html',
  styleUrls: ['./import-budget.component.css']
})
export class ImportBudgetComponent implements OnInit {
  public filesDocuments: File[] = [];
  public isLoading = false;
  public importResult: string;

  constructor() {}

  ngOnInit(): void {}

  onDragOver(event /* : DragEvent */) {
    // console.log(`Drag Over`, event);
    event.target.style.opacity = 0.5;
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event /* : DragEvent */) {
    // console.log('Drag Leave', event);
    event.target.style.opacity = 1;
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event) {
    if (this.isLoading) {
      return;
    }
    // console.log('Drop', event);
    event.preventDefault();
    event.stopPropagation();
    event.target.style.opacity = 1;
    const files: File[] = event.target.files || event.dataTransfer.files;
    if (files.length > 0) {
      this.filesDocuments = [];
      this.filesDocuments.push(...files);
      event.target.value = '';
    }
  }

  importFile() {
    if (this.isLoading || !this.filesDocuments || this.filesDocuments.length === 0) {
      return;
    }

    this.isLoading = true;
    this.importResult = undefined;
  }
}
