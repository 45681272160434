import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavbarSearchViewMoreListConfigService } from '../../services/navbar-search-view-more-list-config.service';
import { ProductService } from '../../services/products/product.service';
import { AuthService } from '../../services/auth/auth.service';
import { Subject, debounce, first, forkJoin, from, interval, mergeMap, of, switchMap, takeUntil } from 'rxjs';
import { FilterListLocationConfigService, GungListRouterEventsService, SearchRequest } from 'gung-list';
import { User } from '../../state/auth/types';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ProductInlineSearchListConfigService } from '../../services/product-inline-search-list-config/product-inline-search-list-config.service';

@Component({
  selector: 'lib-navbar-search-view-more-list',
  templateUrl: './navbar-search-view-more-list.component.html',
  styleUrls: ['./navbar-search-view-more-list.component.css']
})
export class NavbarSearchViewMoreListComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  user: User;

  searchTerm: string = '';
  skip: number;
  batch: number;
  skuLevel: boolean;
  requireAvailability: boolean = this.productInlineSearchListConfigService.requireAvailability;
  totalItems: number = 0;

  protected unsubscribe: Subject<boolean> = new Subject<boolean>();

  searchTermForm: FormControl;

  scrollPosition: number;

  disableViewMoreSearch: boolean = false;

  currentUrl: string;
  firstLoad: boolean = true;
  firstBatch: number;

  constructor(
    public navbarSearchViewMoreListConfigService: NavbarSearchViewMoreListConfigService,
    protected productService: ProductService,
    protected authService: AuthService,
    protected route: ActivatedRoute,
    protected changeDetectorRef: ChangeDetectorRef,
    protected productInlineSearchListConfigService: ProductInlineSearchListConfigService,
    protected router: Router,
    protected gungListRouterEventsService: GungListRouterEventsService,
    protected filterListLocationConfigService: FilterListLocationConfigService
  ) { }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.gungListRouterEventsService.getRouterNavigationStart().pipe(
      takeUntil(this.unsubscribe),
      switchMap(nav => forkJoin({
        nav: of(nav),
        savedLocation: this.filterListLocationConfigService.getSavedLocation(nav?.url?.split('?')?.[0] || '').pipe(first())
      }))
    ).subscribe(({ nav, savedLocation }) => {
      if (nav && nav.navigationTrigger !== 'popstate') {
        const urlToUse: string = this.currentUrl?.split('?')?.[0] || '';
        // Debug Log //
        //console.log(`Save: ${urlToUse}, X: ${window.scrollX}, Y: ${window.scrollY}, Limit: ${this.limit}, Layout: ${this.currentLayoutIdx || this.defaultLayout}`);
        this.filterListLocationConfigService.setSavedLocation(urlToUse, window.scrollX, window.scrollY, this.skip + this.batch, 0);
      }
    });

    this.gungListRouterEventsService.getRouterNavigationStart().pipe(
      first(),
      switchMap(nav => forkJoin({
        nav: of(nav),
        savedLocation: this.filterListLocationConfigService.getSavedLocation(nav?.url?.split('?')?.[0] || '').pipe(first())
      }))
    ).subscribe(({ nav, savedLocation }) => {
      if (nav?.navigationTrigger === 'popstate' && savedLocation) {
        this.firstBatch = savedLocation.pagination;
      }
      this.initVars();
    });
  }

  protected initVars(): void {
    this.authService.getCurrentUser().subscribe(user => { this.user = user })
    this.disableViewMoreSearch = this.navbarSearchViewMoreListConfigService.disableViewMoreSearch;
    this.searchTermForm = new FormControl('');

    this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.skip = 0;
      this.navbarSearchViewMoreListConfigService.productsSubject.next([]);
      const search: string = params?.search?.split('__:__').pop() || this.searchTerm;
      this.searchTermForm.setValue(search);
      this.setDefaults(params);
      this.search();
    });

    this.searchTermForm.valueChanges.pipe(debounce(() => interval(250))).subscribe(_ => {
      this.skip = 0;
      this.navbarSearchViewMoreListConfigService.productsSubject.next([]);
      this.search();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(false);
    this.unsubscribe.complete();
  }

  setDefaults(params) {
    // Set Defaults
    this.skip = 0; // Number of products to skip
    this.batch = 24;  // Maximum number of products to show
    this.firstBatch = this.firstBatch || this.batch;
    this.skuLevel = params?.skuLevel === 'true'; // True - Products | False - Concepts
  }

  showMore() {
    // Save scroll position
    this.scrollPosition = window.scrollY;

    // Add batch size to current skip
    this.skip = this.skip + this.batch;
    this.search();
  }

  search(
    terms: string[] = this.searchTermForm.value.split(' '),
    skip: number = this.skip,
    limit: number = this.batch,
    skuLevel: boolean = this.skuLevel,
    requireAvailability: boolean = this.requireAvailability
  ) {
    this.loading = true;
    if (!this.user) {
      this.authService.getCurrentUser().pipe(
        first(),
        mergeMap(user => {
          this.user = user;
          skip = 0; // On the first time skip is always 0
          const searchRequest: SearchRequest = {
            terms,
            skip,
            limit: this.firstLoad ? this.firstBatch : limit,
            assortment: user.assortment,
            skuLevel,
            requireAvailability
          };
          return this.productService.getPagedProducts(searchRequest);
        }),
      ).subscribe(result => {
        this.navbarSearchViewMoreListConfigService.productsSubject.next(result.items);
        this.totalItems = result.totalItems;
        this.loading = false;
        if (this.firstLoad && this.firstBatch > this.batch) {
          this.skip = this.firstBatch - 24;
        }
        this.changeDetectorRef.detectChanges();
        setTimeout(() => {
          if (this.scrollPosition) {
            window.scrollTo(0, this.scrollPosition);
          }
        }, 500);
      });
    } else {
      from(of(this.user)).pipe(
        first(),
        mergeMap(user => {
          const searchRequest: SearchRequest = {
            terms,
            skip,
            limit: this.firstLoad ? this.firstBatch : limit,
            assortment: user.assortment,
            skuLevel,
            requireAvailability
          };
          return forkJoin({
            result: this.productService.getPagedProducts(searchRequest).pipe(first()),
            cache: this.navbarSearchViewMoreListConfigService.productsSubject.asObservable().pipe(first())
          });
        })
      ).subscribe(({ result, cache }) => {
        this.navbarSearchViewMoreListConfigService.productsSubject.next([...cache, ...result.items]);
        this.totalItems = result.totalItems;
        this.loading = false;
        if (this.firstLoad && this.firstBatch > this.batch) {
          this.skip = this.firstBatch - 24;
        }
        this.firstLoad = false;
        this.changeDetectorRef.detectChanges();
        setTimeout(() => {
          if (this.scrollPosition) {
            window.scrollTo(0, this.scrollPosition);
          }
        }, 500);
      });
    }
  }
}
