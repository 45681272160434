import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';
import { Offer } from '../../models/offer';
import { OfferService } from '../../services/offers/offer.service';
import { ProductService } from '../../services/products/product.service';

@Component({
  selector: 'lib-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.css']
})
export class OfferDetailsComponent implements OnInit {

  offer: Offer;
  products;
  isDataReady = false;

  constructor( 
    protected route: ActivatedRoute, 
    protected offerService: OfferService,
    protected productService: ProductService ) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.params;
    if (routeParams.offerId != null) {
      this.offerService
        .getOffer(routeParams.offerId, true)
        .pipe(first())
        .subscribe(offer => {
          console.log(offer);
          this.offer = offer;
          const productIds = offer.rows.map(row => row.productId);
          this.productService.getProductsByIds(productIds).subscribe(products => {
            this.products = {};
            products.forEach(product => {
              this.products[product.id] = product;
            });
            this.isDataReady = true;
          });
        });
    }
  }

  offerUpdated(offer: Offer) {
    this.offer = offer;
  }

  protected mapOffer(offer: Offer): Offer {
    return offer;
  }
}
