import { Component } from '@angular/core';

@Component({
  selector: 'lib-production-order-wrapper',
  templateUrl: './production-order-wrapper.component.html',
  styleUrls: ['./production-order-wrapper.component.css']
})
export class ProductionOrderWrapperComponent {
  isShowList: boolean = true;
  isShowCalendar: boolean = false;
  isShowStatistics: boolean = false;

  public showCalendar(): void {
    this.isShowList = false;
    this.isShowCalendar = true;
    this.isShowStatistics = false;
  }

  public showList(): void {
    this.isShowList = true;
    this.isShowCalendar = false;
    this.isShowStatistics = false;
  }

  public showStatistics(): void {
    this.isShowList = false;
    this.isShowCalendar = false;
    this.isShowStatistics = true;
  }
}
