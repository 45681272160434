import { Component, OnInit } from '@angular/core';
import { InvoiceListConfigService } from 'gung-standard';
import { JeevesInvoiceListConfigService } from '../../services/jeeves-invoice-list-config/jeeves-invoice-list-config.service';

@Component({
  selector: 'gung-jeeves-jeeves-invoice-list',
  templateUrl: './jeeves-invoice-list.component.html',
  styleUrls: ['./jeeves-invoice-list.component.css']
})
export class JeevesInvoiceListComponent implements OnInit {
  constructor(public configService: JeevesInvoiceListConfigService) {}

  ngOnInit() {}
}
