<div class="form-group position-relative">
  <input
    #searchInput
    [attr.placeholder]="placeholder | translate"
    class="form-control"
    type="text"
    [(ngModel)]="searchTerm"
    (ngModelChange)="searchTermUpdate.next($event)"
    (keydown.enter)="searchEnter.emit(searchTerm)"
  />
  <button
    type="button"
    *ngIf="searchTerm"
    class="btn border-left-0 clear-button"
    aria-label="Clear"
    (click)="searchTerm = ''; searchUpdated.emit(searchTerm)"
  >
    <i class="fa fa-times"></i>
  </button>
</div>
