<div class="row pb-2 d-none d-md-flex">
  <div class="col-4 col-lg-1"></div>
  <div class="d-none d-lg-block col-12 col-md-6 col-lg-3"></div>
  <div class="d-none d-lg-block col-4 col-lg-3">
    <b translate>REQUESTED_QUANTITY</b>
  </div>
  <div class="d-none d-lg-block col-4 col-lg-2">
    <b translate>UNIT_PRICE</b>
  </div>
  <div class="vhr d-none d-lg-block col-4 col-lg-2">
    <b translate>TOTAL_PRICE</b>
  </div>
  <div class="col-1"></div>
</div>
<span *ngFor="let mappedDataRow of mappedData">
  <div class="row pt-1 pb-1 align-items-center">
    <div
      class="col-3 col-lg-1 d-flex align-items-center navigate-to-product-details"
      [routerLink]="['/product/', mappedDataRow.product.id]"
    >
      <img
        class="img-fluid"
        *ngIf="mappedDataRow.product.extra.images && mappedDataRow.product.extra.images.length > 0"
        src="https://d27ahaa1qqlr90.cloudfront.net/fit-in/64x64/filters:fill(white):background_color(white):format(jpeg)/{{
          mappedDataRow.product.extra.images[0].s3Uri
        }}"
        alt=""
      />
    </div>

    <div
      class="col-8 col-lg-3 text-truncate navigate-to-product-details"
      [routerLink]="['/product/', mappedDataRow.product.id]"
    >
      <div>
        <b>{{ mappedDataRow.product.id }}</b> - {{ mappedDataRow.product.name }}
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 offset-sm-3 offset-lg-0 col-request-quantity">
      <div class="row pt-1">
        <div class="col-4 d-flex d-lg-none">
          <b translate>REQUESTED_QUANTITY</b>
        </div>
        <div class="col-8 col-lg-12 d-flex">
          <sjoborg-buy-btn
            [id]="mappedDataRow.product.id"
            [checkoutCart]="true"
            [minimumOrderQuantity]="getPackageSize(mappedDataRow.product)"
            [stepAmount]="getPackageSize(mappedDataRow.product)"
          >
          </sjoborg-buy-btn>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-2 offset-sm-3 offset-lg-0">
      <div class="row pt-1">
        <div class="col-4 d-flex d-lg-none">
          <b translate>UNIT_PRICE</b>
        </div>
        <div class="col-8 col-lg-12 d-flex">
          <b>
            {{
              mappedDataRow.price.cartRowUnitPrice.value
                | gungCurrency: mappedDataRow.price.cartRowUnitPrice.currencyCode:'symbol-narrow':'1.2-2'
            }}
            <span *ngIf="mappedDataRow.price.cartRowDiscountPercent !== 0"
              >(-{{ mappedDataRow.price.cartRowDiscountPercent }}%)</span
            >
          </b>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-2 offset-sm-3 offset-lg-0">
      <div class="row pt-1">
        <div class="col-4 d-flex d-lg-none">
          <b translate>TOTAL_PRICE</b>
        </div>
        <div class="col-8 col-lg-12 d-flex">
          {{
            mappedDataRow.price.cartRowTotalPriceInclRowDiscount.value
              | gungCurrency: mappedDataRow.price.cartRowTotalPriceInclRowDiscount.currencyCode
          }}
        </div>
      </div>
    </div>

    <div
      class="col-2 col-md-1 order-first order-lg-0 offset-11 offset-lg-0 d-flex align-items-center col-delete-button"
    >
      <button class="btn btn-light delete-button" (click)="removeRow(mappedDataRow.cartRow)">
        <i class="fal fa-minus-circle"></i>
      </button>
    </div>
  </div>

  <div class="row hr"></div>
</span>

<div class="pt-3" *ngIf="isSalesOrAdmin">
  <button class="btn btn-primary btn-circle" type="button" (click)="addNewOrderLine()">
    <i class="fas fa-plus"></i>
  </button>
</div>
