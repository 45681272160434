
<div *ngIf="!!data" >
    <div *ngFor="let hubspotContact of data" class="card">
        <div class="card-header">
          <div class="row">
            <div class="col my-auto">
              <h5>{{ hubspotContact.name }} ({{hubspotContact.extra?.hubspotContact?.properties?.jobtitle}})</h5>
            </div>
       
          </div>
        </div>
      
        <div class="card-body text-dark">
          <div class="card-text row">
            <div class="col-12 col-sm-4 mb-2 mb-xs-0">
              <lib-gung-text-input [gungTranslate]="'PHONE'" [renderStatic]="true" [ngModel]="hubspotContact.extra?.hubspotContact?.properties?.mobilephone">
              </lib-gung-text-input>
            </div>
            <div class="col-12 col-sm-4 mb-2 mb-xs-0">
              <lib-gung-text-input [gungTranslate]="'EMAIL'" [renderStatic]="true" [ngModel]="hubspotContact.extra?.hubspotContact?.properties?.email">
              </lib-gung-text-input>
            </div>
            <div class="col-12 col-sm-4 mb-2 mb-xs-0">
                <lib-gung-text-input [gungTranslate]="'COMPANY'" [renderStatic]="true" [ngModel]="hubspotContact.extra?.hubspotContact?.properties?.company">
                </lib-gung-text-input>
              </div>
          </div>
        </div>
      </div>
</div>

  