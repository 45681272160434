<div *ngIf="cartRows; else loading">
  <div class="modal-header">
    <h3>
      {{ productName }} <span class="small">{{ productId }}</span>
    </h3>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th style="width: 30%" translate>PRODUCT</th>
                <th style="width: 15%"></th>
                <th style="width: 20%" translate>PRICE</th>
                <th style="width: 15%" translate>DISCOUNT_PERCENTAGE</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of cartRows; index as rowIndex">
                <td class="d-flex justify-content-start align-items-center">
                  <img
                    *ngIf="!!row.product.extra.images && row.product.extra.images.length > 0"
                    [src]="row.product.extra.images[0].s3Uri | gungImageUrl
                      :'type:list'
                      :'etag:'+row.product.extra.images[0].s3ETag
                    "
                    alt="{{ row.product.extra.images[0].description }}"
                    class="img-fluid"
                  />
                  <div>
                    <span class="product-id">{{ row.cartRow.productId }}</span> <br />
                    <span class="multidimensions" *ngIf="!!row.isMultiDimensional">
                      <span
                        >{{ row.product.primaryDimension[0].name }} - {{ row.product.secondaryDimension[0].name }}</span
                      >
                    </span>
                  </div>
                </td>
                <td>
                  <div *ngIf="rowIndex !== cartRows.length - 1">
                    <span class="pr-1" *ngIf="rowIndex !== cartRows.length - 2">
                      <button
                        type="button"
                        class="fa fa-angle-double-down"
                        tabindex="-1"
                        [title]="getTranslation('MATRIX_COPY_ROW_DOWN_ALL')"
                        (click)="copyDown('all', rowIndex)"
                      ></button>
                    </span>
                    <button
                      type="button"
                      class="fa fa-angle-down"
                      tabindex="-1"
                      [title]="getTranslation('MATRIX_COPY_ROW_DOWN_ONCE')"
                      (click)="copyDown('once', rowIndex)"
                    ></button>
                  </div>
                </td>
                <td>
                  <lib-gung-text-input
                    [suppressLabel]="true"
                    [(ngModel)]="row.editExtra.orp.vb_pris"
                    [placeholder]="row.product.extra._gung.oemPrice"
                  ></lib-gung-text-input>
                </td>
                <td>
                  <lib-gung-text-input
                    [suppressLabel]="true"
                    [(ngModel)]="row.editExtra.orp.rabatt1"
                    [placeholder]="row.product.extra._gung.oemDiscount"
                  ></lib-gung-text-input>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-default btn-primary text-uppercase" (click)="closeMatrix()" translate>
      CANCEL
    </button>
    <button
      type="button"
      class="btn btn-sm btn-default btn-primary text-uppercase"
      (click)="applyAndCloseMatrix()"
      translate
    >
      OK
    </button>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
