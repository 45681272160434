<div class="row">
    <div *ngFor="let module of data" class="col-12 col-md-6">
        <div class="card product-card" draggable="true"
        (dragstart)="onDragStart($event, module)">
            <div class="card-body">
              <div class="image-wrapper" >
                <img
                  class="img-fluid"
                  *ngIf="module.extra && module.extra.images && module.extra.images[0]"
                  [src]="module.extra.images[0].s3Uri | gungImageUrl
                    :'type:thumbnail'
                    :'etag:'+module.extra.images[0].s3ETag
                  "
                  alt=""
                />
              </div>
              <div class="module-text pb-1">                
                <div class="" translate>{{ module.name }}</div>
                <div class="floor" *ngIf="module.extra.floorOne && module.extra.floorOne.length > 0">
                  No: <span *ngFor="let floorOne of module.extra.floorOne">{{floorOne.id}} </span>
                </div>
                <div class="floor" *ngIf="module.extra.floorTwo && module.extra.floorTwo.length > 0">
                  No: <span *ngFor="let floorTwo of module.extra.floorTwo">{{floorTwo.id}} </span>
                </div>  
                <div class="floor" *ngIf="module.extra.floorThree && module.extra.floorThree.length > 0">
                  No: <span *ngFor="let floorThree of module.extra.floorThree">{{floorThree.id}} </span>
                </div>  
                
                <div class="module-card-cm">
                  {{module.extra?.pim?.spaceManagementWidth}} mm
                </div>
              </div>
            </div>
          </div>
    </div>
</div>

