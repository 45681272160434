import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Order } from 'gung-standard';
import { JeevesAddOrderRowModalComponent } from '../components/jeeves-add-order-row-modal/jeeves-add-order-row-modal.component';

@Injectable({
  providedIn: 'root'
})
export class JeevesOrderDetailsConfigService {
  constructor(protected modalService: NgbModal) {}

  isOrderEditable(order: Order, isSales: boolean) {
    return order.extra.oh.ordstat === 0 && isSales;
  }
  openAddOrderRowModal(order: Order) {
    const modalRef = this.modalService.open(JeevesAddOrderRowModalComponent, {
      size: 'lg',
      backdrop: 'static'
    });
    modalRef.componentInstance.order = order;
  }
}
