import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerContact, CustomerContactService, CustomerService } from 'gung-standard';

@Component({
  selector: 'gung-jeeves-jeeves-edit-contact-modal',
  templateUrl: './jeeves-edit-contact-modal.component.html',
  styleUrls: ['./jeeves-edit-contact-modal.component.css']
})
export class JeevesEditContactModalComponent implements OnInit {
  public customerContact: CustomerContact;

  contactForm: FormGroup;
  isUpdatingContact = false;

  get formControls() {
    return this.contactForm.controls;
  }

  constructor(
    protected formBuilder: FormBuilder,
    protected customerContactService: CustomerContactService,
    protected customerService: CustomerService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      name: this.formBuilder.control(this.customerContact.name ? this.customerContact.name : ''),
      CS1: this.formBuilder.group({
        comkod: '1',
        comnr: this.formBuilder.control(this.customerContact.extra.CS1 ? this.customerContact.extra.CS1.comnr : '')
      }),
      CS4: this.formBuilder.group({
        comkod: '4',
        comnr: this.formBuilder.control(this.customerContact.extra.CS4 ? this.customerContact.extra.CS4.comnr : '')
      }),
      CS8: this.formBuilder.group({
        comkod: '8',
        comnr: this.formBuilder.control(this.customerContact.extra.CS8 ? this.customerContact.extra.CS8.comnr : '')
      })
    });
  }

  crossClick() {
    this.activeModal.dismiss('Cross click');
  }

  submitForm() {
    if (this.contactForm.invalid) {
      return;
    }

    this.isUpdatingContact = true;

    const updateContact = JSON.parse(JSON.stringify(this.customerContact));
    updateContact.name = this.contactForm.get('name').value;

    Object.keys(this.contactForm.value).forEach(key => {
      if (this.contactForm.value[key].comnr !== '') {
        updateContact.extra[key] = {
          ...updateContact.extra[key],
          ...this.contactForm.value[key]
        };
      }
    });

    this.customerContactService.addCustomerContact(this.customerContact.customerId, updateContact).subscribe(() => {
      this.isUpdatingContact = false;
      this.customerContactService.updateCustomerContactsSubject(this.customerContact.customerId);
      this.activeModal.close('Add Contact procedure completed.');
    });
  }
}
