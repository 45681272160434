import { Injectable } from '@angular/core';
import { CustomerContactListConfigService, CustomerContact } from 'gung-standard';
import { ListLayoutSingleComponent, ListLayout, ConfigBaseFilter } from 'gung-list';
import { JeevesCustomerContactCardListComponent } from '../../components/jeeves-customer-contact-card-list/jeeves-customer-contact-card-list.component';

@Injectable({
  providedIn: 'root'
})
export class JeevesCustomerContactListConfigService extends CustomerContactListConfigService {
  getLayouts(): ListLayout<CustomerContact>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => JeevesCustomerContactCardListComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'CustomerContacts grid'
      }
    ];
  }

  getFilters(): ConfigBaseFilter<CustomerContact>[] {
    return [];
  }

  getSearchTerms(item: CustomerContact): string[] {
    return [item.id, item.name, item.customerId];
  }
}
