<div class="container">
    <div class="row mb-2">
        <div class="col-12 d-flex justify-content-end">
          <div class="import-button d-flex align-items-baseline"
               (click)="openUploadModal()"
               [ngbTooltip]="'IMPORT_TRANSACTION_DATA' | translate">
            <span class="small mr-1" translate>IMPORT</span>
            <i class="fa-light fa-file-import"></i>
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <lib-filter-list #list [configService]="configService"></lib-filter-list>
        </div>
    </div>

</div>
