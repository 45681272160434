import { S3Item } from 'gung-common';

export interface PropertiesModel {
  properties: {
    [key: string]: string | number | boolean | string[] | number[] | boolean[];
  };
  translateProperties: {
    [languageKey: string]: {
      [key: string]: string;
    };
  };
  extendedProperties?: {
    [key: string]: any;
  };
}

export interface ProductConfiguration {
  id: string;
  name: string;
  features: ProductConfigurationConnectedFeature[];
}

export interface ProductConfigurationConnectedFeature {
  featureId: string;
  optionIds: string[];
}

export interface ProductConfigurationFeature extends PropertiesModel {
  id: string;
  name: string;
  featureOptionType: FeatureOptionType;
}

export enum FeatureOptionType {
  SELECT = 'SELECT',
  RADIO = 'RADIO',
  ADD_ON = 'ADD_ON',
  NUMBER = 'NUMBER'
}

// Standalone
export interface ProductConfigurationOption extends PropertiesModel {
  id: string;
  name: string;
  featureId: string;
  partialResultingProductNumber: string;
  images: S3Item[];
  materials: OptionMaterial[];
  ruleIds: string[];
}

export interface OptionMaterial {
  productId: string;
  quantity: number;
}

// Standalone
export interface ProductConfigurationRule {
  id: string;
  name: string;

  triggerMode: RuleTriggerMode;
  triggers: RuleTrigger[];
  actions: RuleAction[];
}

export enum RuleTriggerMode {
  ANY = 'ANY', // Will trigger if any of the triggers is matching
  ALL = 'ALL' // Will only trigger if all the triggers are matching
}

export const RuleTriggerMatchAnyFeature = '__GUNG_MATCH_ANY__';

export interface RuleTrigger extends PropertiesModel {
  featureId: string;
  fieldId: string;
  operator: RuleTriggerOperator;
  // The values can be found in the properties. This was done to be able to reuse the
  // fieldDefinition input components. Should not matter too much since we got the field
  // saved aswell.
}

export enum RuleTriggerOperator {
  EQUALS = 'EQUALS',
  DOES_NOT_EQUAL = 'DOES_NOT_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATHER_THAN_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL'
}

export interface RuleAction {
  type: RuleActionType;
}

export enum RuleActionType {
  NONE = 'NONE',
  SHOW = 'SHOW',
  SHOW_AND_SELECT = 'SHOW_AND_SELECT',
  SHOW_AND_DISABLE = 'SHOW_AND_DISABLE',
  HIDE = 'HIDE'
}

export interface CartRowFeatureOptionSelection {
  option: ProductConfigurationOption;
  feature: ProductConfigurationFeature;
  qty: number;
}
