import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { AuthService, FavouriteProductListConfigService, FavouriteProductsService, Product, ProductListConfigService, ProductService } from 'gung-standard';
import { ProductPaginationListTableComponent } from '../components/product-pagination-list-table/product-pagination-list-table.component';
import { SjoborgProductGridViewComponent } from '../components/sjoborg-product-grid-view/sjoborg-product-grid-view.component';

@Injectable({
  providedIn: 'root'
})
export class SjoborgFavouriteProductListConfigService extends FavouriteProductListConfigService {

  constructor(
    protected productService: ProductService,
    protected productListConfigService: ProductListConfigService,
    protected authService: AuthService,
    protected favouritesService: FavouriteProductsService
  ) {
    super(productService, productListConfigService, authService, favouritesService);
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => 'fa fa-th',
        getListItemComponent: () => SjoborgProductGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      },
      {
        getIconClass: () => 'fa fa-list',
        getListItemComponent: () => ProductPaginationListTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductList'
      }
    ];
  }
}
