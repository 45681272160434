import { Component, Input, OnInit } from '@angular/core';
import { filter, first } from 'rxjs';
import { isAfter, isBefore, subDays } from 'date-fns';
import { OrderStatistic } from '../../../types';
import { DashboardKpiService } from '../../../services/dashboard-kpi.service';
import { OrderStatisticsService } from '../../../services/order-statistics.service';

@Component({
  selector: 'lib-last-seven-days-orders-summary-card',
  templateUrl: './last-seven-days-orders-summary-card.component.html',
  styleUrls: ['./last-seven-days-orders-summary-card.component.scss']
})
export class LastSevenDaysOrdersSummaryCardComponent implements OnInit {
  @Input()
  preConditions: any;

  private numberOfDays = 7;
  private orderStatisticsLastXDays: { [orderId: string]: number } = {};
  private orderStatisticsXDaysBeforeLastXDays: { [orderId: string]: number } = {};

  loaded = false;
  orderLastXDaysCount = 0;
  orderLastXDaysAvgValue = 0;
  orderXDaysBeforeLastXDaysCount = 0;
  orderXDaysBeforeLastXDaysAvgValue = 0;

  constructor(
    protected dashboardKpiService: DashboardKpiService,
    protected orderStatisticsService: OrderStatisticsService
  ) {}

  ngOnInit() {
    this.orderStatisticsService
      .getOrderStatistics(this.preConditions)
      .pipe(first())
      .subscribe((orderStatistics: OrderStatistic[]) => {
        const dateXDaysBefore = subDays(new Date(), this.numberOfDays);
        const dateTwoXDaysBefore = subDays(new Date(), 2 * this.numberOfDays);

        for (const orderStatistic of orderStatistics.filter(os => !!os.orderCreationDate)) {
          if (isAfter(new Date(orderStatistic.orderCreationDate), dateXDaysBefore)) {
            // last x days orders
            if (!!this.orderStatisticsLastXDays[orderStatistic.orderId]) {
              this.orderStatisticsLastXDays[orderStatistic.orderId] += orderStatistic.orderRowTotalValue;
            } else {
              this.orderStatisticsLastXDays[orderStatistic.orderId] = orderStatistic.orderRowTotalValue;
            }
          } else if (
            isAfter(new Date(orderStatistic.orderCreationDate), dateTwoXDaysBefore) && // x days before the last 7 days
            isBefore(new Date(orderStatistic.orderCreationDate), dateXDaysBefore)
          ) {
            if (!!this.orderStatisticsXDaysBeforeLastXDays[orderStatistic.orderId]) {
              this.orderStatisticsXDaysBeforeLastXDays[orderStatistic.orderId] += orderStatistic.orderRowTotalValue;
            } else {
              this.orderStatisticsXDaysBeforeLastXDays[orderStatistic.orderId] = orderStatistic.orderRowTotalValue;
            }
          }
        }

        // last x days
        this.orderLastXDaysCount = Object.keys(this.orderStatisticsLastXDays).length;
        this.orderLastXDaysAvgValue =
          Object.values(this.orderStatisticsLastXDays).reduce((acc, curr) => acc + curr, 0) /
            this.orderLastXDaysCount || 0;

        // x days before the last x days
        this.orderXDaysBeforeLastXDaysCount = Object.keys(this.orderStatisticsXDaysBeforeLastXDays).length;

        this.loaded = true;
      });
  }
}
