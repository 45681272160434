import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutStepComponent, SelectedCustomerService } from 'gung-standard';
import { CreditBlockedModalComponent } from 'gung-standard';
import { first } from 'rxjs/operators';

@Component({
  selector: 'gung-jeeves-jeeves-credit-blocked-checker',
  templateUrl: './jeeves-credit-blocked-checker.component.html',
  styleUrls: ['./jeeves-credit-blocked-checker.component.css']
})
export class JeevesCreditBlockedCheckerComponent extends CheckoutStepComponent implements OnInit {
  protected modalRef: NgbModalRef;
  isCreditBlocked = false;

  constructor(protected selectedCustomerService: SelectedCustomerService, protected modalService: NgbModal) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked());
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => {
        if (customer.extra.kus.kreditsparr || customer.extra._billingCustomer.extra.kus.kreditsparr) {
          this.isCreditBlocked = true;
          this.modalRef = this.modalService.open(CreditBlockedModalComponent);
        }
      });
  }
  protected handleNextButtonClicked(): void {
    if (this.isCreditBlocked) {
      this.stepPrevious.emit();
      return;
    } else {
      this.stepDone.emit(this.checkout);
    }
  }

  isVisible() {
    return false;
  }
}
