<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>ADD_ORDER_ROW</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th translate>PRODUCT_NAME</th>
              <th translate>QUANTITY</th>
              <th translate>PRICE</th>
              <th translate>DISCOUNT_PERCENT</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of editRows; let rowIndex = index">
              <td width="40%">
                <lib-product-select-dropdown (productSelected)="selectProduct($event, row, rowIndex)">
                </lib-product-select-dropdown>
              </td>
              <td>
                <input *ngIf="this.prices[rowIndex]" class="form-control" type="number" [(ngModel)]="row.qty" />
              </td>
              <td>
                <input
                  *ngIf="this.prices[rowIndex]"
                  class="form-control"
                  type="number"
                  [(ngModel)]="row.extra.orp.vb_pris"
                  [placeholder]="this.prices[rowIndex].customerNetPrice.value"
                />
              </td>
              <td>
                <input
                  *ngIf="this.prices[rowIndex]"
                  class="form-control"
                  type="number"
                  [(ngModel)]="row.extra.orp.rabatt1"
                />
              </td>
              <td width="5%">
                <i class="fa fa-trash-alt clickable" (click)="removeRow(rowIndex)"></i>
              </td>
            </tr>
            <tr>
              <td colspan="5">
                <button class="btn btn-primary btn-block" (click)="addNewRow()" translate>ADD_ORDER_ROW</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    *ngIf="editRows.length > 0"
    type="button"
    class="btn btn-primary"
    (click)="submitRows()"
    [disabled]="submitingRowsWaiting"
    translate
  >
    SUBMIT_ROWS
    <i *ngIf="submitingRowsWaiting" class="fa fa-spinner fa-spin fa-lg"></i>
  </button>
</div>
