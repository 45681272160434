<div class="row mt-4 mb-4" *ngIf="itemProperties">
  <div class="col-12 table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col" translate>NAME</th>
          <th></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let concept of data">
          <td scope="row">
            <div *ngIf="!!concept.sequence">
              <span class="badge badge-primary" translate>OUR_RECOMMENDATION</span>
            </div>
            <div class="d-flex flex-row">
              <div class="p-2"><img [src]="getAssortmentImg(concept)" /></div>
              <div class="p-2">
                <h5>{{ concept.name }}</h5>
                <span *ngIf="concept.productType === 'singleDimension'">{{ concept.id }}</span>
                <span [innerHTML]="getDescription(concept) | safeHtml"></span>
              </div>
            </div>
          </td>
          <th>
            <div *ngFor="let prop of itemProperties">
              <span translate>{{ prop.translationKey }}</span
              >:
              <span translate>{{ getPropertyValue(concept, prop) }}</span>
            </div>
              <sjoborg-buy-btn
                *ngIf="concept.productType === 'singleDimension'"
                [id]="concept.id"
                [minimumOrderQuantity]="getPackageSize(concept)"
                [stepAmount]="getPackageSize(concept)"
              ></sjoborg-buy-btn>
              <button
                *ngIf="concept.productType !== 'singleDimension'"
                [routerLinkActive]="'active-assortment'"
                [routerLink]="concept.id"
                queryParamsHandling="merge"
                type="button"
                class="btn btn-primary text-capitalize btn-more"
              >
                <span>{{ 'READ_MORE_AND_ORDER' | translate }}</span>
              </button>
  
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</div>
