import { Injectable } from '@angular/core';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { BaseViewConfigService, CartRow, CartService, ProductAssortmentParentService, SalesCartConfigService } from 'gung-standard';
import { JeevesSalesCartListComponent } from '../../components/jeeves-sales-cart-list/jeeves-sales-cart-list.component';

@Injectable({
  providedIn: 'root'
})
export class JeevesSalesCartConfigService extends SalesCartConfigService {
  constructor(
    protected cartService: CartService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService
  ) {
    super(cartService, baseViewConfigService, productAssortmentParentService);
  }

  getLayouts(): ListLayout<CartRow>[] {
    return [
      {
        getIconClass: () => '',
        getName: () => 'Cart table',
        getListItemComponent: () => JeevesSalesCartListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent
      }
    ];
  }
}
