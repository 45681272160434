import { Inject, Injectable, Optional } from '@angular/core';
import { CheckoutObject } from '../../models/checkout';

@Injectable({
  providedIn: 'root'
})
export class StripeCheckoutService {
  constructor(
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {
    if (this.isEnabled()) {
      this.addStripeScriptToHeader();
    }
  }

  public isEnabled(): boolean {
    // By checking the environment for the pk live we can support multiple websites where some should have stripe
    // and some should not.
    return !!this.environment.stripePkLive;
  }

  public shouldPayWithStripe(checkout: CheckoutObject): boolean {
    // Default implementation is to never pay with Stripe. This will almost always be customer specific.
    return false;
  }

  public decorateCheckout(checkout: CheckoutObject): void {
    // We want to decorate the checkout with the url origin in the cases we have it. That will make the backend
    // automatically know the correct redirect base urls to send to Stripe. Otherwise, we need to specify it as a
    // property in the backend, which only works when the customer have 1 frontend.
    if (location.origin) {
      checkout.extra._urlOrigin = location.origin;
    }

    checkout.billingCustomerId = checkout?.billingCustomerId || checkout.deliveryCustomerId;
  }

  /*
  We need the stripe script in the head of the page in order for Stripe to work.
   */
  private addStripeScriptToHeader() {
    const headScriptId = 'gung-stripe-script';

    if (!document.getElementById(headScriptId)) {
      const script = document.createElement('script');
      script.id = headScriptId;
      script.src = 'https://js.stripe.com/v3/';
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }
}
