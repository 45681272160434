import { Availability } from '../../../models/availability';
import { Product } from '../../../models/product';

export interface ProductRequirements {
  productId: string;
  productData: RequirementsPlanningProductData;
  availability: Availability;
  incomingOrders: RequirementsPlanningOrder[];
  outgoingOrders: RequirementsPlanningOrder[];
  suggestedOrders: RequirementsPlanningOrder[];
}

export interface RequirementsPlanningOrder {
  orderId: string;
  productId: string;
  orderDate: string;
  quantity: number;
  orderType: RequirementsPlanningOrderType;
  // A trace is a string that describes where the order came from. For regular orders this will be empty or null.
  // For suggested orders, this will be a string that describes the shortage order that caused the suggested order
  trace: string;
}

export enum RequirementsPlanningOrderType {
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  PRODUCTION_ORDER = 'PRODUCTION_ORDER',
  SALES_ORDER = 'SALES_ORDER'
}

export interface RequirementsPlanningProductData {
  product: Product;
  leadTime: number;
  lotSize: number;
  orderType: RequirementsPlanningOrderType;
}
