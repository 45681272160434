
@if(minimal) {
  <button
    *ngIf="selectionsEnabled"
    class="btn btn-link btn-sm"
    (click)="toggleSelection($event)"
  >
    <i class="{{iconClass}} {{isSelected ? selectedIconClass : unselectedIconClass}}"></i>
  </button>
} @else {
  <button
    *ngIf="selectionsEnabled"
    class="btn btn-outline-primary btn-sm w-100"
    (click)="toggleSelection($event)"
    [class.border-0]="!showBorder"
  >
    <span *ngIf="!!selectionText">{{ selectionText | translate | uppercase }}</span>
    <i class="fa"  [ngClass]="{ 'fa-square': !isSelected, 'fa-check-square': isSelected, 'ml-1': !!selectionText }"></i>
  </button>
}