<div class="bg-container">
  <div class="container maintence">
    <div class="maintence-container">
      <div class="text-center maintence-logo mb-3">
        <img src="./assets/logo.png" />
      </div>

      <p><b>Down for maintenance:</b></p>
      <p>This site is currently down for maintenance but should be back up in a couple of minutes.</p>
      <div class="text-center">
        <button class="btn btn-primary" (click)="refreshApp()" >Refresh</button>
      </div>
    </div>
  </div>
  
</div>
