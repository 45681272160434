import { Component } from '@angular/core';

@Component({
  selector: 'lib-inactive-feature',
  templateUrl: './inactive-feature.component.html',
  styleUrls: ['./inactive-feature.component.scss']
})
export class InactiveFeatureComponent {
  constructor() {}
}
