import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Supplier } from '../../../models';
import { SelectedSupplierService } from '../../../services/selected-supplier/selected-supplier.service';

@Component({
  selector: 'lib-supplier-grid-view',
  templateUrl: './supplier-grid-view.component.html',
  styleUrls: ['./supplier-grid-view.component.css']
})
export class SupplierGridViewComponent extends ListItemRendererComponent<Supplier[]> implements OnInit, AfterViewInit {
  constructor(protected selectedSupplierService: SelectedSupplierService) {
    super();
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  selectSupplier(supplier: Supplier) {
    this.selectedSupplierService.selectSupplier(supplier.id);
  }

}
