<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title" translate>STOCK_COUNTING</h5>
  <button type="button" class="close" aria-label="Close" (click)="ngbActiveModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <lib-production-management-stock-counting #stockCountingComponent [inputParams]="inputParams"></lib-production-management-stock-counting>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-default btn-outline-secondary text-uppercase" (click)="close()" translate>
    CLOSE
  </button>
  <button type="button" class="btn btn-sm btn-default btn-primary text-uppercase" (click)="save($event)" translate>
    SAVE
  </button>
</div>