import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslationPrefixService } from '../translation-prefix/translation-prefix.service';
import { Observable, of, forkJoin } from 'rxjs';
import { map, tap, mergeMap } from 'rxjs';
import { TranslationLocalService } from '../translation-local/translation-local.service';

export interface TranslationResult {
  [key: string]: string;
}

export class GungTranslateHttpLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    // Get all the languages at once and merge them
    const prefixes = this.translationPrefix.getPrefixes();
    return of(prefixes).pipe(
      mergeMap(ps => forkJoin(ps.map(prefix => this.http.get<TranslationResult>(`${prefix}${lang}${this.suffix}`)))),
      map(result => {
        // map the result to a map
        return result.reduce((prev, curr) => ({ ...prev, ...curr }), {});
      })
    );
  }
  constructor(private http: HttpClient, private translationPrefix: TranslationPrefixService, private suffix: string) {}
}

export class GungTranslateLocalLoader implements TranslateLoader {
  constructor(private translationLocalService: TranslationLocalService) {}

  getTranslation(lang: string): Observable<any> {
    return this.translationLocalService.getTranslations(lang);
  }
}

export function createTranslateLocalLoader(translationLocalService: TranslationLocalService) {
  return new GungTranslateLocalLoader(translationLocalService);
}

export function createTranslateHttpLoader(http: HttpClient, translationPrefix: TranslationPrefixService) {
  return new GungTranslateHttpLoader(http, translationPrefix, '.json');
}
