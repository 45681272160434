import { Component, OnInit, Optional } from '@angular/core';
import {
  CheckoutStepComponent,
  CustomerService,
  Customer,
  PriceService,
  ProductService,
  Product,
  CartRow,
  CartRowPrice,
  AuthService,
  CheckoutObject,
  GungGoogleTagManagerService
} from 'gung-standard';
import { DateUtilService } from 'gung-common';
import { first } from 'rxjs';
import { JeevesDeliveryAddressesService } from '../../../../services/jeeves-delivery-addresses.service';
@Component({
  selector: 'gung-jeeves-summarize-order-jeeves',
  templateUrl: './summarize-order-jeeves.component.html',
  styleUrls: ['./summarize-order-jeeves.component.css']
})
export class SummarizeOrderJeevesComponent extends CheckoutStepComponent implements OnInit {
  customer: Customer;
  sales: boolean;
  productsMap?: { [productId: string]: Product };
  pricesMap?: { [productId: string]: CartRowPrice };
  today: Date = new Date();

  defaultDeliveryAddress: any = {};

  constructor(
    protected customerService: CustomerService,
    protected productService: ProductService,
    protected authService: AuthService,
    protected priceService: PriceService,
    public dateUtilService: DateUtilService,
    protected jeevesDeliveryAddressesService: JeevesDeliveryAddressesService,
    
    @Optional()
    protected gungGoogleTagManagerService: GungGoogleTagManagerService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.customerService
      .getCustomer(this.checkout.deliveryCustomerId)
      .pipe(first())
      .subscribe(customer => {
        this.customer = customer;
      });

    this.authService
      .getHasRoles('SALES')
      .pipe(first())
      .subscribe(ev => {
        this.sales = ev;
      });

    this.jeevesDeliveryAddressesService
      .getOrderedDeliveryAddressesForCustomer(this.checkout.deliveryCustomerId)
      .pipe(first())
      .subscribe(deliveryAddressList => {
        if (!!deliveryAddressList && deliveryAddressList.length > 0) {
          this.defaultDeliveryAddress = deliveryAddressList[0].addressValues;
        }
      });

    const ids = this.checkout.rows.map(row => row.id);

    const cartRows: CartRow[] = this.checkout.rows.map(checkoutRow => {
      const rowMap: CartRow = {
        extra: checkoutRow.extra,
        qty: checkoutRow.quantity,
        productId: checkoutRow.id,
        productPartialId: checkoutRow.partialId,
        timeUpdated: new Date(),
        timeAdded: new Date()
      };
      return rowMap;
    });

    this.mapProductsPrice(cartRows);
    this.mapProducts(ids);

    this.onNextBtnClicked.subscribe((nextInfo?: any) => this.nextBtnClickedCb(nextInfo));
  }

  protected mapProductsPrice(cartRows: CartRow[]) {
    this.priceService
      .getCartRowPricesObservable(cartRows)
      .pipe(first())
      .subscribe(priceElements => {
        this.pricesMap = {};
        priceElements.forEach(priceElement => (this.pricesMap[priceElement.productId] = priceElement));

        this.checkout.rows.forEach(row => {
          row.extra.googleAnalytics = 
          {
            price: this.pricesMap[row.id].cartRowUnitPriceInclRowDiscount.value,
            currency: this.pricesMap[row.id].cartRowUnitPriceInclRowDiscount.currencyCode,
            hasDiscount: this.pricesMap[row.id].cartRowDiscountPercent > 0,
            discount: this.pricesMap[row.id].cartRowDiscountPercent
          };
        });
      });
  }

  protected mapProducts(ids: string[]) {
    this.productService.getProductsByIds(ids).subscribe(products => {
      this.productsMap = {};
      products.forEach(product => (this.productsMap[product.id] = product));
      
    });
  }

  protected nextBtnClickedCb(nextInfo?: any) {
    this.stepDone.emit(this.checkout);
  }

  public getBtnTexts(checkout: CheckoutObject): { next: string | void; prev: string | void } | void {
    return {
      next: 'SUBMIT_ORDER',
      prev: null
    };
  }

  getLabel() {
    return 'CONFIRM';
  }

  public isCreateOfferStep(): boolean {
    return true;
  }
}
