<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>Messages</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row" *ngIf="entry">
    <div class="col">
      <table class="table w-100">
        <thead>
        <tr>
          <th translate>STATUS</th>
          <th translate>MESSAGE</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let message of messages">
          <td>
            <span class="badge badge-{{message.status}}">
              {{message.status}}
            </span>
          </td>
          <td>
            <p>
              {{message.message}}
            </p>
            <p *ngIf="message.gptExplanation" class="gpt-explanation">
              {{message.gptExplanation}}
            </p>

          </td>
          <td class="text-right">
            <button *ngIf="gptErrorExplanationService.isEnabled && !message.gptExplanation"
                    class="btn btn-primary"
                    ngbTooltip="{{'ERROR_EXPLANATION_THROUGH_GUNG_AI' | translate }}"
                    (click)="getErrorExplanation(message)">
              <i *ngIf="!message.loadingExplanation" class="fal fa-question-circle" ></i>
              <i *ngIf="message.loadingExplanation" class="fa fa-spinner fa-spin" ></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-primary btn-default px-4" (click)="activeModal.close(true)" translate>
    CLOSE
  </button>
</div>


