<div class="table-responsive" *ngIf="mappedData; else loading">
  <table class="table table-striped">
    <thead>
      <tr>
        <th translate>PRODUCT_ID</th>
        <th translate>NAME</th>
        <th *ngFor="let headerKey of dynamicColumns">{{ headerKey.translationKey | translate }}</th>
        <th translate>AVAILABILITY</th>
        <th translate>PRICE</th>
        <th width="15%"></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of mappedData; index as i">
        <td>
          <a routerLink="{{ item.product.id }}" class="product-link">
            {{ item.product?.extra.ar.artnr || item.product.id }}
          </a>
        </td>
        <td>
          {{ item.product?.extra.ar.artbeskr }}
        </td>
        <td *ngFor="let value of item.product.dynamicCollumnsValues">{{ value }}</td>
        <td>
          <div *ngIf="item.product.extra._supplierStock >= 0" style="color: green">
            {{ 'IN_STOCK' | translate }} ({{ item.product.extra._supplierStock }})
          </div>
          <div *ngIf="!(item.product.extra._supplierStock >= 0)">
            <lib-availability
              *ngIf="item.product.extra.availabilities[0]"
              [availability]="item.product.extra.availabilities[0]"
              [isSales]="isSales"
            ></lib-availability>
          </div>
        </td>
        <td>
          <span *ngIf="item.price">
            <lib-price [price]="item.price" type="GROSS"></lib-price>
            <span *ngIf="item.price.customerDiscountPercent !== 0">(-{{ item.price.customerDiscountPercent }}%)</span>
          </span>
        </td>
        <td class="column-buy-btn">
          <sjoborg-buy-btn
            [id]="item.product.id"
            [minimumOrderQuantity]="getPackageSize(item.product)"
            [stepAmount]="getPackageSize(item.product)"
          >
          </sjoborg-buy-btn>
        </td>
        <td>
          <sjoborg-favourite-product [id]="item.product.id"></sjoborg-favourite-product>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</ng-template>
