import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-credit-blocked-modal',
  templateUrl: './credit-blocked-modal.component.html',
  styleUrls: ['./credit-blocked-modal.component.css']
})
export class CreditBlockedModalComponent implements OnInit {
  constructor(protected activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  close() {
    this.activeModal.close();
  }
}
