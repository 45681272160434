import { Component, Input, OnChanges } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import { ReplenishmentProduct } from '../../models/replenishment';
import { debounceTime, distinctUntilChanged, first, forkJoin, map, Observable, switchMap } from 'rxjs';
import { ReplenishmentProductService } from '../../services/replenishment-product.service';
import { Product, ProductMultiDimension } from '../../../../models';
import { isMultiDimensionProduct } from '../../../../utils/product-utils';
import { AuthService } from '../../../../services/auth/auth.service';
import { ProductService } from '../../../../services/products/product.service';

@Component({
  selector: 'lib-add-replenishment-product',
  templateUrl: './add-replenishment-product.component.html',
  styleUrls: ['./add-replenishment-product.component.scss']
})
export class AddReplenishmentProductComponent implements OnChanges {
  @Input()
  replenishmentCustomerId: string;

  products = [];
  loadedReplenishmentProducts = new Set<string>();

  constructor(
    private productService: ProductService,
    private authService: AuthService,
    private replenishmentProductService: ReplenishmentProductService
  ) {}

  ngOnChanges(): void {
    this.authService
      .getCurrentUser()
      .pipe(
        first(),
        switchMap(user =>
          forkJoin([
            this.productService.getProductsByAssortment(user.assortment).pipe(first()),
            this.replenishmentProductService.getAllForCustomer(this.replenishmentCustomerId).pipe(first())
          ])
        )
      )
      .subscribe(([userProducts, replenishmentProducts]) => {
        this.products = userProducts;
        replenishmentProducts.forEach(p => this.loadedReplenishmentProducts.add(p.productId));
      });
  }

  onSelectProduct(event: NgbTypeaheadSelectItemEvent) {
    event.preventDefault();
    const product = event.item as Product;

    const skuIds = this.getSkuIdsFromProduct(product);
    for (const id of skuIds) {
      // Don't add products we already have.
      if (this.loadedReplenishmentProducts.has(id)) {
        continue;
      }

      const replenishmentProduct = {
        id: id + '@' + this.replenishmentCustomerId,
        customerId: this.replenishmentCustomerId,
        productId: id,
        active: true
      } as ReplenishmentProduct;

      this.replenishmentProductService.save(replenishmentProduct).subscribe(_ => undefined);
    }
  }

  private getSkuIdsFromProduct(product: Product): string[] {
    const result = [];
    if (isMultiDimensionProduct(product)) {
      var pmd = product as ProductMultiDimension;
      for (const color of pmd.primaryDimension) {
        for (const size of pmd.secondaryDimension) {
          result.push(pmd.modelId + color.id + size.id);
        }
      }
    } else {
      result.push(product.id);
    }

    return result;
  }

  searchProducts = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        !this.products
          ? []
          : (!term
              ? this.products
              : this.products.filter(
                  item =>
                    (item.id.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                      item.name.toLowerCase().indexOf(term.toLowerCase()) > -1) &&
                    !this.loadedReplenishmentProducts.has(item.id)
                )
            ).slice(0, 10)
      )
    );

  formatterProduct = (result: Product): string => result.id + ' - ' + result.name;
}
