import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BarcodeScannerConfigService, BaseViewConfigService } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class JeevesBarcodeScannerConfigService extends BarcodeScannerConfigService {
  constructor(protected ngbModal: NgbModal, protected baseViewConfigService: BaseViewConfigService) {
    super(ngbModal, baseViewConfigService);
  }
}
