import { ChangeDetectorRef, Component, ComponentFactoryResolver, Type } from '@angular/core';
import {
  CheckoutWrapperComponent,
  OrderSubmitService,
  CheckoutConfigService,
  CartService,
  CheckoutStepComponent,
  GungModalService,
  CartKeyService
} from 'gung-standard';
import { Subject } from 'rxjs';
import { SjoborgButtonsComponent } from '../sjoborg-buttons/sjoborg-buttons.component';

@Component({
  selector: 'sjoborg-checkout-wrapper',
  templateUrl: './sjoborg-checkout-wrapper.component.html',
  styleUrls: ['./sjoborg-checkout-wrapper.component.scss']
})
export class SjoborgCheckoutWrapperComponent extends CheckoutWrapperComponent {
  private nextAlternativeStepSubject = new Subject<void>();

  protected renderedButtons: SjoborgButtonsComponent;

  constructor(
    protected orderSubmitService: OrderSubmitService,
    protected checkoutConfig: CheckoutConfigService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected cartService: CartService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected gungModalService: GungModalService,
    protected cartKeyService: CartKeyService
  ) {
    super(
      orderSubmitService,
      checkoutConfig,
      componentFactoryResolver,
      cartService,
      changeDetectorRef,
      gungModalService,
      cartKeyService
    );
  }

  // EVENT HANDLERS
  nextAlternativeBtnClicked() {
    this.nextAlternativeStepSubject.next();
  }

  protected unsubscribe() {
    super.unsubscribe();

    if (this.nextAlternativeStepSubject) {
      this.nextAlternativeStepSubject.complete();
    }
  }

  protected renderCurrentStep(): void {
    this.unsubscribe();

    const componentToRender: Type<any /* CheckoutStepComponent */> = this.steps[this.currentIndex];
    const factory = this.componentFactoryResolver.resolveComponentFactory(componentToRender);
    const containerRef = this.viewChild.viewContainerRef;
    containerRef.clear();
    const componentRef = containerRef.createComponent(factory);
    const typedComponent = componentRef.instance; /* as CheckoutStepComponent */
    // TODO freeze this object ?
    // set up the inputs to the step

    if (this.currentCheckout !== undefined) {
      typedComponent.checkout = JSON.parse(JSON.stringify(this.currentCheckout));
    } else {
      typedComponent.checkout = this.currentCheckout;
    }
    this.nextStepSubject = new Subject<void>();
    typedComponent.onNextBtnClicked = this.nextStepSubject.asObservable();
    this.nextAlternativeStepSubject = new Subject<void>();
    typedComponent.onNextAlternativeBtnClicked = this.nextAlternativeStepSubject.asObservable();

    // subscribe to the outputs of the step
    typedComponent.stepDone.subscribe(newCheckout => {
      if (typedComponent.isVisible()) {
        this.visibleIndex++;
      }
      this.nextStepSubject.complete();
      this.nextAlternativeStepSubject.complete();
      this.currentCheckout = newCheckout;
      this.nextStep();
    });

    typedComponent.stepError.subscribe(err => {
      this.displayError(err);
    });

    // trigger lifecycle hooks on the step
    this.changeDetectorRef.detectChanges();
    if (!typedComponent.isVisible()) {
      // automatically trigger next if the step is not visible
      this.nextStepSubject.next(false);
    }
  }

  protected renderDynamicComponents() {
    // super.renderDynamicComponents();
    // this.renderedButtons.nextAlternativeClicked.subscribe(_ => this.nextBtnClicked());

    // render the indicator
    const indicatorFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.checkoutConfig.getStepIndicator()
    );
    this.indicator.clear();
    this.renderedIndicator = this.indicator.createComponent(indicatorFactory).instance;
    this.renderedIndicator.currentIndex = this.visibleIndex;
    this.renderedIndicator.stepIndexVisible = this.stepIndexVisible;
    this.renderedIndicator.steps = this.steps;

    // render the buttons
    this.sideButtons = this.checkoutConfig.sideButtons;
    const buttonsFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.checkoutConfig.getButtonsComponent()
    );
    this.btns.clear();
    this.renderedButtons = this.btns.createComponent(buttonsFactory).instance as SjoborgButtonsComponent;
    this.renderedButtons.allSteps = this.steps.filter(step => step.prototype.isVisible());
    this.renderedButtons.currentStep = this.steps[this.currentIndex];
    this.renderedButtons.nextClicked.subscribe(_ => this.nextBtnClicked());
    this.renderedButtons.prevClicked.subscribe(_ => this.previousBtnClicked());
    this.renderedButtons.nextAlternativeClicked.subscribe(_ => this.nextAlternativeBtnClicked());

    this.changeDetectorRef.detectChanges();
    this.renderedButtons.updateBtnTexts(this.currentCheckout);
  }
}
