import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  AuthService,
  AvailabilityService,
  CartTotalsService,
  Customer,
  SelectedCustomerService,
  TotalObject
} from 'gung-standard';
import { OrderTermsJeevesComponent } from 'gung-standard-jeeves';
import { TranslateService } from '@ngx-translate/core';
import { first, takeUntil } from 'rxjs';
import { Subject } from 'rxjs';

interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'sjoborg-order-terms-jeeves',
  templateUrl: './sjoborg-order-terms-jeeves.component.html',
  styleUrls: ['./sjoborg-order-terms-jeeves.component.scss']
})
export class SjoborgOrderTermsJeevesComponent extends OrderTermsJeevesComponent implements OnInit, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();
  alertMessageFreightCost: Alert = null;

  constructor(
    protected authService: AuthService,
    protected formBuilder: FormBuilder,
    protected datePipe: DatePipe,
    protected availabilityService: AvailabilityService,
    private translateService: TranslateService,
    private selectedCustomerService: SelectedCustomerService,
    private cartTotalService: CartTotalsService
  ) {
    super(authService, formBuilder, datePipe, availabilityService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
