import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService, PriceService, Product } from 'gung-standard';
import { first } from 'rxjs';

@Component({
  selector: 'gung-jeeves-jeeves-checkout-add-row-modal',
  templateUrl: './jeeves-checkout-add-row-modal.component.html',
  styleUrls: ['./jeeves-checkout-add-row-modal.component.css']
})
export class JeevesCheckoutAddRowModalComponent implements OnInit {
  private orderLineItemProductId = '';

  modalRows: {
    productId: string;
    qty: number;
    price: number;
    discount?: number;
    product?: Product
  }[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    protected cartService: CartService,
    protected priceService: PriceService
  ) {}

  ngOnInit(): void {
    this.addNewRow();
  }

  addNewRow() {
    this.modalRows.push({
      productId: this.orderLineItemProductId,
      qty: 1,
      price: 0
    });
  }

  removeRow(index) {
    this.modalRows.splice(index, 1);
  }

  selectProduct(product: Product, rowIndex: number) {
    this.modalRows[rowIndex].productId = product.id;
    this.priceService
      .getCurrentCustomerPrice(product.id)
      .pipe(first())
      .subscribe(price => (this.modalRows[rowIndex].price = price.customerNetPrice.value));
  }

  validateQuantity(value: number, rowIndex: number): void {
    if (value <= 0) {
      this.modalRows[rowIndex].qty = 1;
    }
  }

  submitRows() {
    if (this.modalRows.length === 0) {
      this.activeModal.close();
      return;
    }
    const ids = [];
    for (const modalRow of this.modalRows) {
      if (modalRow.productId) {
        let theOrp: { [k: string]: any } = {
          vb_pris: modalRow.price
        };

        this.cartService.addToCart(modalRow.productId, modalRow.qty, undefined, undefined, undefined, { orp: theOrp });
        ids.push(modalRow.productId);
      }
    }

    this.activeModal.close({ ids });
  }

  productSearchFormatter(result: Product) {
    return result.id + ' - ' + result.name;
  }

  productSearchInputFormatter(result: Product) {
    return result.id;
  }
}
