import { Inject, Injectable, Optional } from '@angular/core';
import {
  DashboardCard,
  DashboardCardService,
  InvoiceStatisticsChartCardComponent,
  LastSevenDaysOrdersSummaryCardComponent,
  LastVsThisMonthSalesAmountSummaryCardComponent,
  LastVsThisWeekSalesAmountSummaryCardComponent,
  LastVsThisYearSalesAmountSummaryCardComponent,
  SalesSummaryCardComponent
} from 'gung-dashboard';
import { AuthService, NavigationConfig } from 'gung-standard';
import { map } from 'rxjs';
import { SjoborgSalespersonSelectComponent } from '../../components/dashboard/sjoborg-salesperson-select/sjoborg-salesperson-select.component';
import { DashboardDataService } from './dashboard-data.service';

@Injectable({
  providedIn: 'root'
})
export class SjoborgDashboardCardService extends DashboardCardService {
  constructor(
    protected authService: AuthService, 
    protected dashboardDataService: DashboardDataService,       
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig) {
    super(authService, environment);
    console.log('SjoborgDashboardCardService');

    authService
      .getHasRoles('SALES')
      .pipe(
        map(isAdmin => {
          console.log('SjoborgDashboardCardService [isAdmin]', isAdmin);

          dashboardDataService.currentMessage.subscribe(preCondition => {
            console.log('SjoborgDashboardCardService [preCondition]', preCondition);
            this.clearCards();

            this.addCard(
              new DashboardCard(LastVsThisYearSalesAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3')
            );
            this.addCard(
              new DashboardCard(LastVsThisMonthSalesAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3')
            );
            this.addCard(
              new DashboardCard(LastVsThisWeekSalesAmountSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3')
            );
            this.addCard(new DashboardCard(LastSevenDaysOrdersSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3'));

            if (isAdmin) {
              this.addCard(
                new DashboardCard(SjoborgSalespersonSelectComponent, 'col-xs-12 col-md-12 col-xl-12', preCondition)
              );
            }
            if (preCondition) {
              this.addCard(
                new DashboardCard(
                  LastVsThisYearSalesAmountSummaryCardComponent,
                  'col-xs-12 col-md-6 col-xl-3',
                  preCondition
                )
              );
              this.addCard(
                new DashboardCard(
                  LastVsThisMonthSalesAmountSummaryCardComponent,
                  'col-xs-12 col-md-6 col-xl-3',
                  preCondition
                )
              );
              this.addCard(
                new DashboardCard(
                  LastVsThisWeekSalesAmountSummaryCardComponent,
                  'col-xs-12 col-md-6 col-xl-3',
                  preCondition
                )
              );
              this.addCard(
                new DashboardCard(LastSevenDaysOrdersSummaryCardComponent, 'col-xs-12 col-md-6 col-xl-3', preCondition)
              );
            }

            this.addCard(
              new DashboardCard(InvoiceStatisticsChartCardComponent, 'col-xs-12 col-md-12 col-xl-12', preCondition)
            );
            this.addCard(new DashboardCard(SalesSummaryCardComponent, 'col-xs-12 col-md-12 col-xl-12', preCondition));
          });
        })
      )
      .subscribe();
  }
}
