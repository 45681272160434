import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice } from '../../models/invoice';
import { HttpClient } from '@angular/common/http';
import { AdyenInvoiceMarkAsPayedRequest, AdyenInvoicePaymentStatus } from '../../models/adyen';

@Injectable({
  providedIn: 'root'
})
export class AdyenInvoiceService {
  baseUrl = 'json/adyen/invoices';
  httpOptions = {
    headers: {
      maxAge: '-1'
    }
  };
  constructor(protected http: HttpClient) {}

  public markInvoiceAsPayed(request: AdyenInvoiceMarkAsPayedRequest): Observable<Invoice> {
    return this.http.post<Invoice>(`${this.baseUrl}/markAsPayed`, request, this.httpOptions);
  }

  public getPaymentStatus(invoiceId: string): Observable<AdyenInvoicePaymentStatus> {
    return this.http.get<AdyenInvoicePaymentStatus>(`${this.baseUrl}/${invoiceId}/status`, this.httpOptions);
  }
}
