<div class="row" *ngIf="form && deliveryAddresses && ohForm">
  <div class="col-12 col-md-6" [formGroup]="form">
    <div class="form-group">
      <label for="deliveryLocation" class="control-label" translate>DELIVERY_LOCATION</label>
      <select
        name="deliveryLocation"
        id="deliveryLocation"
        class="form-control"
        (change)="onDeliveryAddressSelected($event.target.value)"
      >
        <option
          [value]="i"
          *ngFor="let option of deliveryAddresses; let i = index"
          [selected]="isSelectedAddress(option)"
        >
          {{ option.addressValues.ordlevadr1 }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="ordlevadr1" class="control-label" translate>OH_ORDLEVADR1</label>
      <input class="form-control" type="text" name="ordlevadr1" id="ordlevadr1" [formControlName]="'ordlevadr1'" />
    </div>
    <div class="form-group">
      <label for="ordlevadr2" class="control-label" translate>OH_ORDLEVADR2</label>
      <input class="form-control" type="text" name="ordlevadr2" id="ordlevadr2" [formControlName]="'ordlevadr2'" />
    </div>
    <div class="form-group">
      <label for="ordlevadr3" class="control-label" translate>OH_ORDLEVADR3</label>
      <input class="form-control" type="text" name="ordlevadr3" id="ordlevadr3" [formControlName]="'ordlevadr3'" />
    </div>
    <div class="form-group">
      <label for="ftgpostnr" class="control-label" translate>OH_FTGPOSTNR</label>
      <input class="form-control" type="text" name="ftgpostnr" id="ftgpostnr" [formControlName]="'ftgpostnr'" />
    </div>
    <div class="form-group">
      <label for="ordlevadr4" class="control-label" translate>OH_ORDLEVADR4</label>
      <input class="form-control" type="text" name="ordlevadr4" id="ordlevadr4" [formControlName]="'ordlevadr4'" />
    </div>
    <div>
      <lib-meta-select-input formControlName="ordlevadrlandskod" table="xr" metaId="landskod" metaDisplay="land">
      </lib-meta-select-input>
    </div>
  </div>
  <div class="col-12 col-md-6" [formGroup]="ohForm">
    <div class="form-group">
      <label for="godsmarke1" class="control-label" translate>OH_GODSMARKE1</label>
      <input class="form-control" type="text" name="godsmarke1" id="godsmarke1" formControlName="godsmarke1" />
    </div>
    <div class="form-group">
      <label for="kundbestnr" class="control-label" translate>OH_KUNDBESTNR</label>
      <input class="form-control" type="text" name="kundbestnr" id="kundbestnr" formControlName="kundbestnr" />
    </div>
    <div class="form-group">
      <label for="editext" class="control-label w-100" translate>OH_EDITEXT</label>
      <textarea
        id="editext"
        name="editext"
        class="form-control w-100"
        formControlName="editext"
        rows="9"
        cols="50"
      ></textarea>
    </div>
    <div class="form-group">
      <label for="kundref2" class="control-label" translate>OH_KUNDREF2</label>
      <div class="dropdown">
        <input
          #contactInput
          class="form-control"
          type="text"
          name="kundref2"
          id="kundref2"
          (input)="onInput($event)"
          (focus)="toggleDropdown(true)"
          (blur)="toggleDropdown(false)"
          formControlName="kundref2"
        />
        <div class="dropdown-menu w-100" [class.show]="isDropdownOpen" (mousedown)="$event.preventDefault()">
          <button
            class="dropdown-item"
            *ngFor="let contact of contacts"
            (click)="onSelectContact(contact, $event)"
          >
            {{ contact.name || contact.email }}
          </button>
        </div>
      </div>
    </div>
    <lib-confirmation-emails
      [emails]="checkout.extra.confirmationEmails"
      (emailsUpdated)="checkout.extra.confirmationEmails = $event"
    ></lib-confirmation-emails>
    <table class="table table-striped table-sm">
      <thead>
        <tr>
          <th colspan="2" translate>SELECT_CONTACT</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let contact of contacts">
          <tr *ngIf="contact.email">
            <td>
              {{ contact.name }}
            </td>
            <td>
              {{ contact.email }}
            </td>
            <td class="col-1">
              <button
                class="btn btn-sm btn-primary"
                *ngIf="isAvailableToAdd(contact.email)"
                class="pull-right"
                (click)="addContactToRecipients(contact.email)"
              >
                +
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <button class="btn btn-sm btn-block btn-primary" (click)="addNewContact()" translate>ADD_CONTACT</button>
  </div>
</div>
