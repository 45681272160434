import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService, CommonModalService, gungAddRemoveSpinner } from 'gung-common';
import {
  OrderDetailsComponent,
  AuthService,
  BackendInterceptor,
  ProductService,
  Order,
  BaseViewConfigService,
  ProductAssortmentParentService,
  GungModalService,
  PriceConfigService
} from 'gung-standard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JeevesOrderDetailsConfigService } from '../../services/jeeves-order-details-config.service';
import { JeevesOrderActionConfigService } from '../../services/jeeves-order-action-config/jeeves-order-action-config.service';
import { JeevesOrderService } from '../../services/jeeves-order.service';
@Component({
  selector: 'gung-jeeves-jeeves-order-details',
  templateUrl: './jeeves-order-details.component.html',
  styleUrls: ['./jeeves-order-details.component.css']
})
export class JeevesOrderDetailsComponent extends OrderDetailsComponent {
  constructor(
    protected route: ActivatedRoute,
    protected orderService: JeevesOrderService,
    protected authService: AuthService,
    protected backendInterceptor: BackendInterceptor,
    protected productService: ProductService,
    public configService: JeevesOrderActionConfigService,
    protected modalService: NgbModal,
    protected orderDetailsConfig: JeevesOrderDetailsConfigService,
    public dateUtilService: DateUtilService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService,
    protected commonModalService: CommonModalService,
    protected gungModalService: GungModalService,
    protected translateService: TranslateService,
    public priceConfigService: PriceConfigService
  ) {
    super(
      route,
      orderService,
      authService,
      backendInterceptor,
      productService,
      dateUtilService,
      baseViewConfigService,
      productAssortmentParentService,
      commonModalService,
      priceConfigService,
      configService
    );
  }

  public isOrderEditable = (order: Order, isSales: boolean) => {
    return this.orderDetailsConfig.isOrderEditable(order, isSales);
  };

  public addRow() {
    this.orderDetailsConfig.openAddOrderRowModal(this.order);
  }

  saveOrderMessage(element) {
    gungAddRemoveSpinner(element.target);
    let oh = { oh: { editext: this.order.extra.oh.editext } };

    this.orderService.updateOrderHeader({ id: this.order.id, extra: oh } as any).subscribe(
      result => {
        this.gungModalService
          .openConfirmYesNoModal(
            undefined,
            this.translateService.instant('CHANGE_SAVED_SUCCESS'),
            { size: 'sm' },
            'OK',
            null
          )
          .then(_ => {});
      },
      error => {
        gungAddRemoveSpinner(element.target);
        this.gungModalService
          .openConfirmYesNoModal(
            undefined,
            this.translateService.instant('AN_ERROR_HAS_OCCURED'),
            { size: 'sm' },
            'OK',
            null
          )
          .then(_ => {});
      },
      () => {
        gungAddRemoveSpinner(element.target);
      }
    );
  }
}
