import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CheckoutObject } from '../../models/checkout';
import { HttpClient } from '@angular/common/http';
import { Order } from '../../models/order';

export interface AdyenOrder {
  id: string;
  orderIds: string[];
  adyenId: string;
  originalOrder: Order;
  lineItems: any[];
  amount: any;
  orderContextId: string;
}

export interface AdyenPaymentLinkResponse {
  // A unique identifier of the payment link.
  id: string;
  // The date when the payment link expires.  [ISO 8601](https://www.w3.org/TR/NOTE-datetime) format: YYYY-MM-DDThh:mm:ss+TZD, for example, **2020-12-18T10:15:30+01:00**.  The maximum expiry date is 70 days after the payment link is created.  If not provided, the payment link expires 24 hours after it was created.
  expiresAt: Date;
  // A reference that is used to uniquely identify the payment in future communications about the payment status.
  reference: string;
  // Status of the payment link. Possible values: * **active**: The link can be used to make payments. * **expired**: The expiry date for the payment link has passed. Shoppers can no longer use the link to make payments. * **completed**: The shopper completed the payment. * **paymentPending**: The shopper is in the process of making the payment. Applies to payment methods with an asynchronous flow.
  status: string;
  // The URL at which the shopper can complete the payment.
  url: string;
  amount: {
    // Currency code
    currency: string;
    // Value, most often multiplied by 100, sometimes by 1000 (https://docs.adyen.com/development-resources/currency-codes/)
    value: number;
  };
}

export interface AdyenPaymentLinkRequest {
  // The three-character [ISO currency code](https://docs.adyen.com/development-resources/currency-codes).
  currency: string;
  // The shopper's two-letter country code.
  countryCode: string;
  // A short description visible on the payment page. Maximum length: 280 characters.
  description: string;
  // The language to be used in the payment page, specified by a combination of a language and country code. For example, `en-US`.
  shopperLocale: string;
  // Your reference to uniquely identify this shopper, for example user ID or account ID. Minimum length: 3 characters. > Your reference must not include personally identifiable information (PII), for example name or email address.
  shopperReference: string;
  // The merchant account identifier for which the payment link is created.
  merchantAccount?: string;
  // Gung order to be created if payment succeeds.
  order: CheckoutObject;
}

@Injectable({
  providedIn: 'root'
})
export class AdyenService {
  constructor(
    protected http: HttpClient,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {}

  /**
   * Used for synchronous payments (a session is created towards Adyen in which the user pays immediately)
   */
  createSession(checkout: CheckoutObject): Observable<any> {
    return this.http.post<any>('json/adyen/session', checkout);
  }

  /**
   * Used for asynchronous payments (the user gets a link which they can use to pay later).
   */
  createPaymentLink(paymentLinkRequest: AdyenPaymentLinkRequest): Observable<AdyenPaymentLinkResponse> {
    return this.http.post<AdyenPaymentLinkResponse>('json/adyen/link', paymentLinkRequest);
  }

  public cancelPaymentLink(paymentLinkId: string): Observable<AdyenPaymentLinkResponse> {
    const url: string = `json/adyen/link/${paymentLinkId}/cancel`;
    return this.http.post<AdyenPaymentLinkResponse>(url, null);
  }

  public isEnabled(): boolean {
    // By checking the environment for the key we can support multiple websites where some should have Adyen
    // and some should not.
    return !!this.environment.adyenClientKey;
  }

  public decorateCheckout(checkout: CheckoutObject): Observable<CheckoutObject> {
    // We want to decorate the checkout with the url origin in the cases we have it. That will make the backend
    // automatically know the correct redirect base urls to send to Adyen. Otherwise, we need to specify it as a
    // property in the backend, which only works when the customer have 1 frontend.
    if (location.origin) {
      checkout.extra._urlOrigin = location.origin;
    }

    checkout.billingCustomerId = checkout?.billingCustomerId || checkout.deliveryCustomerId;
    return of(checkout);
  }

  public shouldUseAdyenStep(checkout: CheckoutObject): boolean {
    // Default implementation is to never pay with Adyen. This will almost always be customer specific.
    return false;
  }

  public shouldUsePayByLink(checkout: CheckoutObject): boolean {
    // Default implementation is to pay directly in Gung.
    return false;
  }

  public getAdyenOrder(adyenOrderId: string): Observable<AdyenOrder> {
    const urlString = `json/adyen-order/${adyenOrderId}`;
    return this.http.get<AdyenOrder>(urlString);
  }

  public pollLinkStatus(paymentLinkId: string): Observable<AdyenPaymentLinkResponse> {
    const url: string = `json/adyen/link/${paymentLinkId}`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<AdyenPaymentLinkResponse>(url, { headers });
  }

  public pollOrderStatus(paymentLinkReference: string): Observable<AdyenOrder> {
    const url: string = `json/adyen-order/${paymentLinkReference}`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<AdyenOrder>(url, { headers });
  }
}
