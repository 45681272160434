import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  DeliveryLocationListCardComponent,
  SelectedCustomerService,
  MetadataService,
  DeliveryLocationActionConfigService
} from 'gung-standard';

@Component({
  selector: 'gung-jeeves-jeeves-delivery-location-list-card',
  templateUrl: './jeeves-delivery-location-list-card.component.html',
  styleUrls: ['./jeeves-delivery-location-list-card.component.css']
})
export class JeevesDeliveryLocationListCardComponent extends DeliveryLocationListCardComponent {
  constructor(
    public selectCustomerService: SelectedCustomerService,
    public metadataService: MetadataService,
    public actionConfig: DeliveryLocationActionConfigService,
    public router: Router
  ) {
    super(selectCustomerService, metadataService, actionConfig, router);
  }
}
