import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CataloguesWrapperComponent } from './components/catalogues-wrapper/catalogues-wrapper.component';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AdminGuard } from '../../guards/adminguard/admin-guard.guard';
import { AuthGuard } from '../../guards/authguard/auth-guard';
import { SharedModule } from '../../shared/shared.module';

const routes: Routes = [
  {
    path: '',
    component: CataloguesWrapperComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
]

@NgModule({
  declarations: [
    CataloguesWrapperComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild({
      extend: true
    }),
    RouterModule.forChild(routes)
  ]
})
export class CataloguesModule { }
