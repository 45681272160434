import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankgiroWrapperComponent } from './components/bankgiro-wrapper/bankgiro-wrapper.component';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../guards/authguard/auth-guard';
import { AdminGuard } from '../../guards/adminguard/admin-guard.guard';
import { BankgiroListViewComponent } from './components/bankgiro-list-view/bankgiro-list-view.component';
import { BankgiroDynamicComponent } from './components/bankgiro-dynamic/bankgiro-dynamic.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PriceComponent } from '../../components/price/price.component';


const routes: Routes = [
  {
    path: '',
    component: BankgiroWrapperComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: 'dynamic',
    component: BankgiroDynamicComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
]

@NgModule({
  declarations: [
    BankgiroWrapperComponent,
    BankgiroListViewComponent,
    BankgiroDynamicComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DragDropModule,
    TranslateModule.forChild({
      extend: true
    }),
    RouterModule.forChild(routes)
  ]
})
export class BankgiroModule { }