<div *ngIf="!!data" class="card">
  <div class="card-header">
    <div class="row">
      <div class="col">
        <h5 class="customer-contact-name">{{ data.name }}</h5>
      </div>
      <div class="col-auto">
        <button class="btn btn-primary" type="button" (click)="editContact(data)" translate>EDIT_CONTACT</button>
      </div>
    </div>
  </div>

  <div class="card-body text-dark">
    <div class="card-text row">
      <div class="col-12 col-sm-4 mb-2 mb-xs-0">
        <lib-gung-text-input [gungTranslate]="'CS1_COMNR'" [renderStatic]="true" [(ngModel)]="phone">
        </lib-gung-text-input>
      </div>
      <div class="col-12 col-sm-4 mb-2 mb-xs-0">
        <lib-gung-text-input [gungTranslate]="'CS4_COMNR'" [renderStatic]="true" [(ngModel)]="mobile">
        </lib-gung-text-input>
      </div>
      <div class="col-12 col-sm-4 mb-2 mb-xs-0">
        <lib-gung-text-input [gungTranslate]="'CS8_COMNR'" [renderStatic]="true" [(ngModel)]="email">
        </lib-gung-text-input>
      </div>
    </div>
  </div>
</div>
