import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerListCardComponent, CustomerService, MetadataService, SelectedCustomerService } from 'gung-standard';
import { first } from 'rxjs/operators';
import { JeevesCustomerActionConfigService } from '../../services/jeeves-customer-action-config/jeeves-customer-action-config.service';
import { JeevesCustomerConfigService } from '../../services/jeeves-customer-config.service';

@Component({
  selector: 'gung-jeeves-jeeves-customer-list-card',
  templateUrl: './jeeves-customer-list-card.component.html',
  styleUrls: ['./jeeves-customer-list-card.component.css']
})
export class JeevesCustomerListCardComponent extends CustomerListCardComponent implements OnInit {
  invoiceCustomerBlocked = false;
  enableBlockedInvoiceCustomer = false;

  constructor(
    public selectCustomerService: SelectedCustomerService,
    public metadataService: MetadataService,
    public actionConfig: JeevesCustomerActionConfigService,
    public router: Router,
    protected customerService: CustomerService,
    protected jeevesCustomerConfigService: JeevesCustomerConfigService
  ) {
    super(selectCustomerService, router);
  }

  ngOnInit(): void {
    this.enableBlockedInvoiceCustomer = this.jeevesCustomerConfigService.useInvoiceCreditBlocked;
    if (this.data.extra.kus.kundbetalarenr && (this.data.id != this.data.extra.kus.kundbetalarenr)) {
      this.customerService
        .getCustomer(this.data.extra.kus.kundbetalarenr)
        .pipe(first())
        .subscribe(customer => {
          if (customer.extra.kus.kreditsparr) {
            this.invoiceCustomerBlocked = true;
          }
        });
    }
  }
}
