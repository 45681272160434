import { Injectable } from '@angular/core';
import {
  CheckoutQuantityValidationService,
  Availability,
  ValidationResult,
  CheckoutRow,
  ValidationAction,
  ValidationRequest,
  CheckoutObject
} from 'gung-standard';
import { TranslateService } from '@ngx-translate/core';
import { parse, format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class JeevesCheckoutQuantityValidationService extends CheckoutQuantityValidationService {
  constructor(protected translateService: TranslateService) {
    super(translateService);
  }

  public getPushDeliveryDateAction(validationResult: ValidationResult): ValidationAction {
    const futureDeliveryDate = this.getFutureAvailability(
      validationResult.availability,
      validationResult.row.quantity
    ).date;
    const futureDeliveryDateStr = format(futureDeliveryDate, 'yyyy-MM-dd');

    return {
      description: this.translateService.instant('PUSH_DELIVERY_DATE') + ' -> ' + futureDeliveryDateStr,
      callback(): void {
        validationResult.row.extra.orp.ordberlevdat = futureDeliveryDateStr;
      }
    };
  }

  public getQtyValidationResult(validationRequest: ValidationRequest): ValidationResult {
    const validationResult: ValidationResult = {
      row: validationRequest.row,
      product: validationRequest.product,
      availability: validationRequest.availability,
      valid: true,
      actions: []
    };

    if (validationRequest.availability.currentAvailability < validationRequest.row.quantity) {
      // LET'S CHECK IF ANY QTY AVAILABLE IN THE FUTURE HIGHER OR EQUAL THAN REQUESTED QTY
      // IF YES
      //    THEN IF ORDER DELIVERY DATE IS ON_OR_AFTER THAN THE DATE WHEN THE FUTURE AVAILABILITY IS AVAILABLE
      //        THEN
      //            RETURN OK
      //        OTHERWISE
      //            ADD PUSH DELIREY DATE ACTION
      // OTHERWISE
      //    DO NOT ADD THE ACTION TO PUSH DELIVERY DATE
      let addPushDeliveryDateAction = false;
      if (validationRequest.availability.maxFutureStock >= validationRequest.row.quantity) {
        const deliveryDate = this.getRowDeliveryDate(validationRequest.row, validationRequest.checkout);
        const futureAvailability = this.getFutureAvailability(
          validationRequest.availability,
          validationRequest.row.quantity
        );

        if (deliveryDate >= futureAvailability.date) {
          // DELIVERY DATE FOR THIS ITEM IS ON_OR_AFTER THAN IT WILL BE AVAILABLE IN FUTURE
          return validationResult;
        }

        addPushDeliveryDateAction = true;
      }

      // set not valid action
      validationResult.valid = false;
      const availabilityValue = validationResult.availability.currentAvailability;
      validationResult.description =
        this.translateService.instant('NOT_ENOUGH_AVAILABILITY') +
        ' (' +
        validationResult.row.quantity +
        '>' +
        availabilityValue +
        ')';

      if (availabilityValue > 0) {
        // Reduce quantity not relevant if no quantity exists
        validationResult.actions.push(this.getLowerAvailabilityAction(validationResult));
      } else if (
        validationRequest.availability.maxFutureStock > 0 &&
        validationRequest.availability.maxFutureStock < validationRequest.row.quantity
      ) {
        // only add if not added the action to lower availability above not added
        validationResult.description =
          this.translateService.instant('NOT_ENOUGH_AVAILABILITY') +
          ' (' +
          validationResult.row.quantity +
          '>' +
          validationRequest.availability.maxFutureStock +
          ')';
        validationResult.actions.push(this.getLowerAvailabilityFutureStockAction(validationResult));
      }

      validationResult.actions.push(this.getRemoveProductAction(validationResult));
      if (addPushDeliveryDateAction) {
        validationResult.actions.push(this.getPushDeliveryDateAction(validationResult));
      }
      validationResult.selectedAction = validationResult.actions[0];
    }

    return validationResult;
  }

  public getRowDeliveryDate(row: CheckoutRow, checkout: CheckoutObject): Date {
    if (!row.extra.orp.ordberlevdat) {
      row.extra.orp.ordberlevdat = checkout.extra.oh.ordberlevdat;
    }

    return row.extra.orp.ordberlevdat ? new Date(row.extra.orp.ordberlevdat) : new Date();
  }
}
