import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CompactType, DisplayGrid, GridType, GridsterConfig, GridsterItemComponentInterface } from 'angular-gridster2';
import { PlanogramCreateConfigModalComponent } from '../planogram-create-config-modal/planogram-create-config-modal.component';
import { PlanogramProductListConfigService } from '../../services/planogram-product-list-config.service';
import { PlanogramAddItemsService } from '../../services/planogram-add-items.service';
import { GridTemplate, Planogram, PlanogramGridItem } from '../../models/planogram';
import { GungModalService } from '../../../../services/gung-modal/gung-modal.service';
import { PlanogramModulesListConfigService } from '../../services/planogram-modules-list-config.service';
import { PlanogramService } from '../../services/planogram.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../../../services/products/product.service';
import { PlanogramConfigService } from '../../services/planogram-config.service';
import { TranslateService } from '@ngx-translate/core';
import { CdkDrag, CdkDragDrop } from '@angular/cdk/drag-drop';
import { Subject, first, takeUntil } from 'rxjs';
import { GungNotificationService } from 'gung-common';

@Component({
  selector: 'lib-planogram',
  templateUrl: './planogram.component.html',
  styleUrls: ['./planogram.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlanogramComponent implements OnInit, OnDestroy {
  isEdit = false;
  optionOpen = true;
  listSelected = 'modules';
  width: number;
  loadGrid = true;
  zoom = 100;
  id: string;
  dragHelper = false;
  showText = true;
  displayTemplate: GridTemplate = 'text-over-image';
  unsubscribe: Subject<void> = new Subject();
  displayImageText = false;
  firstLoad = true;
  inicialRatio = 2;

  options: GridsterConfig = {
    gridType: GridType.ScrollVertical,
    displayGrid: DisplayGrid.Always,
    compactType: CompactType.None,
    rowHeightRatio: 2,
    setGridSize: true,
    pushItems: false,
    margin: 0,
    swap: false,
    draggable: {
      enabled: true,
      ignoreContentClass: 'gung-move'
    },
    resizable: {
      enabled: false
    },
    minCols: 24,
    maxCols: 24,
    minRows: 24,
    maxRows: 24,
    maxItemCols: 24,
    minItemCols: 1,
    maxItemRows: 24,
    minItemRows: 1,
    maxItemArea: 2500,
    minItemArea: 1,
    defaultItemCols: 1,
    defaultItemRows: 1,
    mobileBreakpoint: 10,
    enableEmptyCellClick: false,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: true,
    enableEmptyCellDrag: true,
    enableOccupiedCellDrop: false,
    emptyCellClickCallback: this.dropProduct.bind(this),
    emptyCellContextMenuCallback: this.dropProduct.bind(this),
    emptyCellDropCallback: this.dropProduct.bind(this),
    emptyCellDragCallback: this.dropProduct.bind(this),
    itemInitCallback: this.itemInit.bind(this),
    disableAutoPositionOnConflict: true
  };

  data: PlanogramGridItem[] = [];

  planogram: Planogram;

  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();

  droppedProduct;

  @ViewChildren('gridItemDetails') gridItemDetailsElements: QueryList<ElementRef>;

  constructor(
    protected ngbModal: NgbModal,
    protected productListConfig: PlanogramProductListConfigService,
    protected planogramAddItemsService: PlanogramAddItemsService,
    protected gungModalService: GungModalService,
    protected modulesListConfig: PlanogramModulesListConfigService,
    protected planogramService: PlanogramService,
    protected route: ActivatedRoute,
    protected productService: ProductService,
    protected router: Router,
    public planogramConfig: PlanogramConfigService,
    protected translateService: TranslateService,
    protected gungNotificationService: GungNotificationService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.data = [];
      if (params.id) {
        this.isEdit = true;
        this.planogramService.getPlanogram(params.id).subscribe(planogram => {
          this.planogram = planogram;
          this.getPlanogramData(this.planogram.items);
          this.setGridSizes(this.planogram);          
          let queryParams = { ...this.route.snapshot.queryParams };
          if(queryParams.layout){
            this.pickLayout(queryParams.layout);
          }
        });
      } else {
        this.openConfigModal().then(result => {
          if (result && result.width && result.widthStep && result.shelfs) {
            this.initPlanogram(result);
            this.setGridSizes(result);
          } else {
            this.router.navigate(['planogram']);
          }
        });
      }
     
          
        
    });

    this.planogramAddItemsService
      .get()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(item => {
        if (item) {
          this.data.push(item);
        }
      });
  }

  ngOnDestroy(): void {
    this.planogramAddItemsService.clear();
    this.unsubscribe.complete();
  }

  initPlanogram(planogram: Planogram) {
    this.planogram = {
      ...planogram,
      name: planogram.name || 'New Planogram'
    };
  }

  getPlanogramData(items: PlanogramGridItem[]) {
    const itemsIds = items.map(i => i.itemId);
    this.productService
      .getFullProductsByIds(itemsIds)
      .pipe(first())
      .subscribe(modules => {
        let allProductsIds = [];
        for (const m of modules) {
          allProductsIds = [...allProductsIds, ...m.extra?.SpaceManagementModuleProducts?.map(p => p.itemId)];
        }
        const productsIds = [...new Set(allProductsIds)];
        this.productService
          .getFullProductsByIds(productsIds)
          .pipe(first())
          .subscribe(products => {
            for (const item of items) {
              const module = modules.find(p => p.id === item.itemId);
              item.data = {
                articles: module?.extra?.SpaceManagementModuleProducts?.map(p => p.itemId),
                ...module
              };
              const modulesProducts = products.filter(m => item.data?.articles?.includes(m.id));
              this.setProductsData(item, modulesProducts);
              this.data.push(item);
            }
          });
      });
  }

  openConfigModal(): Promise<any> {
    const id = PlanogramCreateConfigModalComponent.name;

    const ref = this.ngbModal.open(PlanogramCreateConfigModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: true
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.isEdit = this.isEdit;
    if (this.planogram) {
      ref.componentInstance.planogram = this.planogram;
    }

    /*  ref.componentInstance.name = this.planogram?.name;
    ref.componentInstance.assortmentId = this.planogram?.assortmentId;
    ref.componentInstance.width = this.planogram?.width, 
    ref.componentInstance.widthStep = this.planogram?.widthStep, 
    ref.componentInstance.shelfs = this.planogram?.shelfs */

    this.modalRef.set(id, ref);

    return ref.result;
  }

  setGridSizes(sizes) {
    this.width = sizes.width;
    const cols = sizes.width / sizes.widthStep;
    this.options.minCols = cols;
    this.options.maxCols = cols;
    this.options.maxItemCols = cols;
    if (cols > 20) {
      //row ratio needs to be calculated if we have more than 20 cols
      this.options.rowHeightRatio = Math.floor(((cols * 2) / 16) * 100) / 100;
    }
    if (this.firstLoad) {
      this.inicialRatio = this.options.rowHeightRatio;
      this.firstLoad = false;
    }

    this.options.minRows = sizes.shelfs;
    this.options.maxRows = sizes.shelfs;
    this.options.maxItemRows = sizes.shelfs;
    this.options.minRows = sizes.shelfs;
    this.changedOptions();
  }

  changedOptions(): void {
    this.loadGrid = true;
    setTimeout(() => {
      if (this.options.api && this.options.api.optionsChanged) {
        this.options.api.optionsChanged();
      }
      this.loadGrid = false;
    }, 200);
  }

  dropProduct(event: DragEvent, item) {
    if (this.planogramAddItemsService.draggedItem) {
      let cols = 1;
      if (this.planogramAddItemsService.draggedItem?.extra?.pim?.spaceManagementWidth) {
        const width = Number(this.planogramAddItemsService.draggedItem?.extra?.pim?.spaceManagementWidth);
        cols = Math.floor(width / this.planogram.widthStep);
      }
      const gridItem: PlanogramGridItem = {
        cols,
        rows: 1,
        x: item.x,
        y: item.y,
        itemId: this.planogramAddItemsService.draggedItem.id,
        type: 'modules',
        data: {
          articles: this.planogramAddItemsService.draggedItem.extra?.SpaceManagementModuleProducts?.map(p => p.itemId),
          ...this.planogramAddItemsService.draggedItem,
          artNo: ''
        }
      };
      this.planogramAddItemsService.add(gridItem);
      this.getProductsData(gridItem);

      this.droppedProduct = gridItem;
      this.planogramAddItemsService.draggedItem = null;
    }
  }

  itemInit(item: PlanogramGridItem, itemComponent: GridsterItemComponentInterface): void {
    if (itemComponent.notPlaced == true) {
      this.removeItem(null, item);
      this.gungNotificationService.notify(
        'Module not added',
        'Module ' + item.data.name + 'was removed due to lack of space',
        'error'
      );
    }
  }

  getProductsData(gridItem) {
    this.productService
      .getFullProductsByIds(gridItem.data.articles)
      .pipe(first())
      .subscribe(products => {
        this.setProductsData(gridItem, products);
      });
  }

  setProductsData(gridItem: PlanogramGridItem, products) {
    let artno = [];
    let floorOne = [];
    let floorTwo = [];
    let floorThree = [];
    let quantityInDisplay = null;
    for (const product of products) {
      if (product.extra.pim.erpProductType === 'MERCH') {
        if (artno.includes(product.id)) {
          continue;
        }
        artno.push(product.id);
      }
      const itemModule = gridItem.data.extra.SpaceManagementModuleProducts.find(pm => pm.itemId === product.id);
      if (itemModule.properties.floorOne && itemModule.properties.floorOne > -1) {
        floorOne.push({ id: product.extra.pim.colorCode, sort: itemModule.properties.floorOne });
        if (
          itemModule.properties.quantityOfSequenceFloorOne &&
          Number(itemModule.properties.quantityOfSequenceFloorOne) > 1
        ) {
          for (let index = 1; index < Number(itemModule.properties.quantityOfSequenceFloorOne); index++) {
            floorOne.push({ id: product.extra.pim.colorCode, sort: itemModule.properties.floorOne });
          }
        }
      }
      if (itemModule.properties.floorTwo && itemModule.properties.floorTwo > -1) {
        floorTwo.push({ id: product.extra.pim.colorCode, sort: itemModule.properties.floorTwo });
        if (
          itemModule.properties.quantityOfSequenceFloorTwo &&
          Number(itemModule.properties.quantityOfSequenceFloorTwo) > 1
        ) {
          for (let index = 1; index < Number(itemModule.properties.quantityOfSequenceFloorTwo); index++) {
            floorTwo.push({ id: product.extra.pim.colorCode, sort: itemModule.properties.floorTwo });
          }
        }
      }
      if (itemModule.properties.floorThree && itemModule.properties.floorThree > -1) {
        floorThree.push({ id: product.extra.pim.colorCode, sort: itemModule.properties.floorThree });
        if (
          itemModule.properties.quantityOfSequenceFloorThree &&
          Number(itemModule.properties.quantityOfSequenceFloorThree) > 1
        ) {
          for (let index = 1; index < Number(itemModule.properties.quantityOfSequenceFloorThree); index++) {
            floorThree.push({ id: product.extra.pim.colorCode, sort: itemModule.properties.floorThree });
          }
        }
      }
      if (gridItem.data?.extra?.pim?.imageFromProductId === product.id) {
        quantityInDisplay = product.extra.pim.quantityInDisplay;
      }
    }
    gridItem.data.artNo = artno.join(', ');
    gridItem.data.floorOne = floorOne.sort((a, b) => a.sort - b.sort);
    gridItem.data.floorTwo = floorTwo.sort((a, b) => a.sort - b.sort);
    gridItem.data.floorThree = floorThree.sort((a, b) => a.sort - b.sort);
    gridItem.data.quantityInDisplay = quantityInDisplay;
  }

  selectMenu(menu) {
    if (this.listSelected !== menu) {
      this.listSelected = menu;
      this.optionOpen = true;
    } else {
      this.optionOpen = false;
      this.listSelected = null;
    }
    this.changedOptions();
  }

  removeItem($event: MouseEvent | TouchEvent, item): void {
    $event?.preventDefault();
    $event?.stopPropagation();
    this.data.splice(this.data.indexOf(item), 1);
  }

  editName() {
    this.gungModalService.openInputSaveModal('Name', 'Change Name', this.planogram.name).then(data => {
      if (data) {
        this.planogram.name = data.value;
      }
    });
  }

  editPlanogram() {
    this.openConfigModal().then(result => {
      if (result && result.width && result.widthStep && result.shelfs) {
        this.initPlanogram(result);
        this.setGridSizes(result);
      }
    });
  }

  zoomOut() {
    if (this.zoom > 10) {
      this.zoom = this.zoom - 10;
      this.changedOptions();
    }
  }

  zoomIn() {
    if (this.zoom < 300) {
      this.zoom = this.zoom + 10;
      this.changedOptions();
    }
  }

  savePlanogram() {
    this.planogram.items = this.data;

    this.planogramService.savePlanogram(this.planogram).subscribe(planogram =>
      this.gungModalService
        .openConfirmYesNoModal(undefined, this.translateService.instant('PLANOGRAM_SAVED'), { size: 'sm' }, 'OK', null)
        .then(result => {
          if (!this.isEdit) {
            this.router.navigate(['planogram', planogram.id]);
          }
        })
    );
  }

  tootleDargHelper() {
    this.dragHelper = !this.dragHelper;
  }

  toDragHelper(item) {
    this.data.splice(this.data.indexOf(item), 1);
    this.planogramAddItemsService.addDragHelper(item.data);
  }

  markAsNew(item: PlanogramGridItem) {
    item.newModule = true;
  }

  markAsNotNew(item: PlanogramGridItem) {
    item.newModule = false;
  }

  markAsExpired(item: PlanogramGridItem) {
    item.expired = true;
  }

  removeAsExpired(item: PlanogramGridItem) {
    item.expired = false;
  }

  toLineToDragHelper(item: PlanogramGridItem) {
    const itemsOnLine = this.data.filter(i => i.y === item.y);
    for (const itemOnLine of itemsOnLine) {
      this.toDragHelper(itemOnLine);
    }
  }

  pickLayout(layout: GridTemplate) {
    this.options.rowHeightRatio = this.inicialRatio;
    this.displayTemplate = layout;
    switch (layout) {
      case 'image':
        this.showText = false;
        this.displayImageText = false;
        this.changedOptions();        
        break;
      case 'image-text':
        this.showText = true;
        this.displayImageText = true;
        this.options.rowHeightRatio = this.options.rowHeightRatio * 1.6;
        this.changedOptions();
        break;
      default:
        this.showText = true;
        this.displayImageText = false;
        this.changedOptions();
        break;
    }
    this.changeLayoutUrl();
  }

  changeLayoutUrl(){
    let queryParams = { ...this.route.snapshot.queryParams };
    queryParams['layout'] = this.displayTemplate;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  printPage(id) {
    this.gungModalService.openConfirmYesNoModal(
      '',
      'If you made any changes, save the planogram before going to the print page. Do you want to continue to the print page?',
      { size: 'sm' },
      'YES',
       'NO'
    ).then(result =>{
      if(result){
        let queryParams = {...this.route.snapshot.queryParams};
        const params = {layout: queryParams?.layout};
        this.router.navigate(['planogram/preview', id], {queryParams: params})
      }
    });
  }

  expiredRemove(item: PlanogramGridItem){
    if(!this.planogram.expiredAndRemovedItems){
      this.planogram.expiredAndRemovedItems = [];
    }
    this.planogram.expiredAndRemovedItems.push(item.itemId);
    this.data.splice(this.data.indexOf(item), 1);
  }
}
