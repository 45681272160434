import { Injectable, Input, Directive } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { CustomerService, GungBase, MetadataService } from 'gung-standard';
import { Observable } from 'rxjs';
import { JeevesCustomerDeliveryLocationCartListComponent } from '../../components/jeeves-customer-delivery-location-cart-list/jeeves-customer-delivery-location-cart-list.component';
import { DeliveryLocationsService } from '../delivery-locations/delivery-locations.service';

export class CustomerSalesPersonFilter extends SimpleConfigBaseFilter<GungBase> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'SALESPERSON';
  }
  getOptionIds(item: GungBase): string[] {
    // return [item.extra.fr.landskod];
    const metadataValue = this.metadataService.getMetadataValue('xr', 'land', item.extra.fr.landskod);
    if (metadataValue == null) {
      return [];
    } else {
      return [metadataValue];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Directive()
@Injectable({
  providedIn: 'root'
})
export class JeevesCustomerDeliveryLocationListConfigService implements ConfigService<GungBase> {
  @Input()
  customerId: string;

  constructor(
    protected deliveryLocationsService: DeliveryLocationsService,
    protected metadataService: MetadataService
  ) {}

  getItems(): Observable<GungBase[]> {
    return this.deliveryLocationsService.getDeliveryLocationsForCustomer(this.customerId);
  }

  getFilters(): ConfigBaseFilter<GungBase>[] {
    return [new CustomerSalesPersonFilter(this.metadataService)];
  }

  getSortOptions(): ListSortOption<GungBase>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<GungBase>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => JeevesCustomerDeliveryLocationCartListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Customer list'
      }
    ];
  }

  getSearchTerms(item: GungBase): string[] {
    return [item.id, item.name];
  }

  getItemId(item: GungBase): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
