<div class="row mx-3" *ngIf="!isLoading; else loading">
  <div class="col-12 mb-2">
    <div class="d-flex justify-content-between">
      <h3>{{configuration.name}}</h3>
      <button class="btn btn-primary" [disabled]="!isAllowedToOrder()" (click)="addToCart()" translate>ADD_TO_CART
      </button>
    </div>
  </div>
  <div class="col-12">
    <lib-gung-tabset style="width: 100%">
      <lib-gung-tab *ngFor="let group of groupedFeatures | keyvalue">
        <ng-template libGungTabTitle>
          <span translate>{{group.key}}</span>
          <span *ngIf="requiredFieldsInGroupHasActions(group.key)" class="text-success ml-1">
            <i class="fa fa-circle-check"></i>
          </span>
        </ng-template>
        <ng-template libGungTabContent>
          <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-3"
                 [ngClass]="feature.properties?.containerClass ? String(feature.properties.containerClass) : ''"
                 *ngFor="let feature of group.value; let i = index">

              <lib-cpq-feature-header [feature]="feature"></lib-cpq-feature-header>


              <ng-container [ngSwitch]="feature.featureOptionType">
                <ng-container *ngSwitchCase="FeatureOptionType.SELECT"
                              [ngTemplateOutlet]="SELECT_FEATURE"
                              [ngTemplateOutletContext]="{feature: feature, featureIndex: i}"></ng-container>
                <ng-container *ngSwitchCase="FeatureOptionType.RADIO"
                              [ngTemplateOutlet]="RADIO_FEATURE"
                              [ngTemplateOutletContext]="{feature: feature, featureIndex: i}"></ng-container>
                <ng-container *ngSwitchCase="FeatureOptionType.ADD_ON"
                              [ngTemplateOutlet]="ADD_ON_FEATURE"
                              [ngTemplateOutletContext]="{feature: feature, featureIndex: i}"></ng-container>
                <ng-container *ngSwitchCase="FeatureOptionType.NUMBER"
                              [ngTemplateOutlet]="NUMBER_FEATURE"
                              [ngTemplateOutletContext]="{feature: feature, featureIndex: i}"></ng-container>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </lib-gung-tab>

      <lib-gung-tab [title]="'SUMMARY' | translate">
        <ng-template libGungTabContent>
          <lib-cpq-configuration-summary [configuration]="configuration"
                                         [selections]="buildSelection()">
          </lib-cpq-configuration-summary>
        </ng-template>
      </lib-gung-tab>
    </lib-gung-tabset>
  </div>
</div>

<ng-template #loading> LOADING...</ng-template>

<!-- TODO Make this a component -->
<ng-template #SELECT_FEATURE let-feature="feature" let-featureIndex="featureIndex">
  <div class="d-flex flex-column mb-3">
    <div class="form-group">
      <select
        name="{{feature.name}}"
        id="{{feature.id}}"
        class="form-control"
        [(ngModel)]="featureNgModels[feature.id]"
        (change)="featureOptionSelected(feature, this.keyedOptions.get($event.target.value))"
      >
        <ng-container *ngFor="let option of getFilteredOptions(feature)">
          <option [value]="option.id" *ngIf="!hiddenOptions.get(option.id)"
                  [disabled]="disabledOptions.get(option.id)">
            {{option.name}}
          </option>
        </ng-container>
      </select>
    </div>
  </div>
</ng-template>

<!-- TODO Make this a component -->
<ng-template #RADIO_FEATURE let-feature="feature" let-featureIndex="featureIndex">
  <!-- TODO SHOULD MAKE EACH CASE SEPARATE COMPONENT -->
  <div class="d-flex flex-column mb-3" *ngIf="feature.properties?.displayOptionImages">
    <div class="row">

      <ng-container *ngFor="let option of getFilteredOptions(feature)">

        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 my-2" *ngIf="!hiddenOptions.get(option.id)">
          <label class="d-flex align-items-center radio-image-wrapper h-100" translate
                 *ngIf="!hiddenOptions.get(option.id)">
            <input class="mr-2" type="radio" name="feature-{{featureIndex}}" value="{{option.id}}"
                   [disabled]="disabledOptions.get(option.id)"
                   (change)="featureOptionSelected(feature, option)"
                   [(ngModel)]="featureNgModels[feature.id]"
            >

            <div class="card h-100">
              <img
                class="img-fluid mx-auto d-block card-img-top"
                *ngIf="option.images[0]; else noimage"
                [src]="option.images[0].s3Uri | gungImageUrl
                  :'type:list'
                  :'etag:'+option.images[0].s3ETag
                "
                alt=""
              />
              <div class="card-body card-text">
                <div class="d-flex justify-content-between mb-1">
                  <span style="font-weight: bold">{{option.name}}</span>
                  <span>{{ getPriceForOption(feature, option) | currency: getCurrencyForOption(feature, option) }}</span>
                </div>
                <span>{{option.properties.description}}</span> <br/>
              </div>
            </div>
          </label>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- TODO SHOULD MAKE EACH CASE SEPARATE COMPONENT -->
  <div class="d-flex flex-column mb-3" *ngIf="!feature.properties?.displayOptionImages">
    <ng-container *ngFor="let option of getFilteredOptions(feature)">
      <label class="my-1 d-flex align-items-center" translate *ngIf="!hiddenOptions.get(option.id)">
        <input class="mr-2" type="radio" name="feature-{{featureIndex}}" value="{{option.id}}"
               [disabled]="disabledOptions.get(option.id)"
               (change)="featureOptionSelected(feature, option)"
               [(ngModel)]="featureNgModels[feature.id]"
        >
        <span>{{option.name}} <span
          *ngIf="getPriceForOption(feature, option) != 0">(${{ getPriceForOption(feature, option) | currency: getCurrencyForOption(feature, option) }})</span></span>
      </label>
    </ng-container>
  </div>
</ng-template>

<!-- TODO Make this a component -->
<ng-template #ADD_ON_FEATURE let-feature="feature" let-featureIndex="featureIndex">
  <div class="row">
    <ng-container *ngFor="let option of getFilteredOptions(feature)">
      <div class="col-lg-4 col-md-4 col-sm-6" *ngIf="!hiddenOptions.get(option.id)">
        <div class="d-flex flex-column mb-3 add-on-wrapper justify-content-between">
          <div>
            <div class="image-wrapper">
              <img
                class="img-fluid mx-auto d-block"
                *ngIf="option.images[0]; else noimage"
                [src]="option.images[0].s3Uri | gungImageUrl
                  :'type:list'
                  :'etag:'+option.images[0].s3ETag
                "
                alt=""
              />
            </div>
            <div>
              <span style="font-weight: bold">{{option.name}}</span> <br/>
                <span>{{ getPriceForOption(feature, option) | currency: getCurrencyForOption(feature, option) }}</span>
                 <br/>
              <span>{{option.properties.description}}</span> <br/>
            </div>

          </div>
          <div>
            <button class="btn btn-outline-primary"
                    [disabled]="disabledOptions.get(option.id)"
                    (click)="featureOptionSelected(feature, option)"
                    *ngIf="!hasOptionSelected(feature.id, option)" translate>
              ADD
            </button>
            <button class="btn btn-outline-primary"
                    [disabled]="disabledOptions.get(option.id)"
                    (click)="featureOptionRemoved(feature, option)"
                    *ngIf="hasOptionSelected(feature.id, option)" translate>
              REMOVE
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>


<ng-template #NUMBER_FEATURE let-feature="feature" let-featureIndex="featureIndex">
  <div class="d-flex flex-column mb-3">
    <div class="row">
      <ng-container *ngFor="let option of getFilteredOptions(feature)">

        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 my-2" *ngIf="!hiddenOptions.get(option.id)">
            <div class="card h-100">
              <img
                class="img-fluid mx-auto d-block card-img-top"
                *ngIf="option.images[0]; else noimage"
                [src]="option.images[0].s3Uri | gungImageUrl
                  :'type:list'
                  :'etag:'+option.images[0].s3ETag
                "
                alt=""
              />
              <div class="card-body card-text">
                <div class="d-flex justify-content-between mb-1">
                  <span style="font-weight: bold">{{option.name}}</span>
                  <span>{{ getPriceForOption(feature, option) | currency: getCurrencyForOption(feature, option) }}</span>
                </div>
                <span>{{option.properties.description}}</span> <br/>
                <input
                  type="number"
                  class="form-control"
                  [disabled]="disabledOptions.get(option.id)"
                  placeholder="0"
                  [(ngModel)]="option.properties.quantityAdded" (blur)="featureOptionSelected(feature, option)" />
              </div>
            </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #noimage>
  <img
    class="card-img-top img-fluid mx-auto d-block"
    [src]="'images/no-image.jpg' | gungImageUrl"
    alt="NO IMAGE"
  />
</ng-template>
