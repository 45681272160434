import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, first, map, Observable, of, switchMap, tap } from 'rxjs';
import { GungBase } from '../../models';
import { AuthService } from '../auth/auth.service';

export interface AssortmentConceptSearchInside extends GungBase {
  sortIndex: number;
}

export interface AssortmentConceptSearch {
  [id: string]: AssortmentConceptSearchInside[]
}

@Injectable({
  providedIn: 'root'
})
export class AssortmentConceptSearchService {
  private baseUrl = `json/feature/all-search-concepts`;

  protected assortmentConceptSearchSubject: BehaviorSubject<AssortmentConceptSearch>;
  protected assortmentConceptSearch: AssortmentConceptSearch;

  constructor(
    protected http: HttpClient,
    protected authService: AuthService
  ) {}

  public getAssortmentConceptSearch(
    query: string,
    assortmentId?: string,
    noCache?: boolean
  ): Observable<AssortmentConceptSearch> {
    if (this.assortmentConceptSearchSubject && !noCache) {
      return this.assortmentConceptSearchSubject;
    }
    this.assortmentConceptSearchSubject = new BehaviorSubject(null);

    let assortment = of(assortmentId);
    if (!assortmentId) {
      assortment = this.authService.getCurrentUser().pipe(first(), map(user => user.assortment))
    }
    return assortment.pipe(
      switchMap(id => {
    const url = `json/feature/all-search-concepts/${id}?query=${query}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '7200');
    return this.http
      .get<AssortmentConceptSearch>(url, { headers })
      .pipe(tap(data => {
        this.assortmentConceptSearchSubject.next(data);
        this.assortmentConceptSearch = data;
      })
    );
      })
    )
  }

  public getAssortmentConceptSearchById(
    id: string,
    assortmentId: string,
    noCache?: boolean
  ): Observable<AssortmentConceptSearchInside[]> {
    return this.getAssortmentConceptSearch(id, assortmentId, noCache).pipe(
      map(data => {
        return data?.[id];
      })
    )
  }
}
