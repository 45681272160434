import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

export interface StockTransferRequest {
  productId: string;
  fromStockPointId: string;
  toStockPointId: string;
  fromStockLocationId: string;
  toStockLocationId: string;
  note: string;
  quantity: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProductionManagementTransferInventoryService {

  // Define the API endpoint
  private apiUrl = 'json/production/stock-transfers';

  constructor(private http: HttpClient) { }

  stockTransfers(item: StockTransferRequest) {
    return this.http.post<any>(this.apiUrl, item).pipe(
      catchError(this.handleError<any>('createItem'))
    ); 
  }

   // Handle HTTP errors
   private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
