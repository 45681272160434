import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GungMenu } from '../../../services/gung-main-menu/gung-main-menu.service';

@Component({
  selector: 'lib-gung-expand-menu',
  templateUrl: './gung-expand-menu.component.html',
  styleUrl: './gung-expand-menu.component.scss'
})
export class GungExpandMenuComponent {

  @Input() menu: GungMenu[];

  @Input() top: number;

  @Input() left: number;

  @Input() levels: number;

  @Output() closeExpandMenu = new EventEmitter<boolean>()

  secondMenuLinks: GungMenu[] = null;

  isSecondMenuVisible = false;

  showSecondMenu(menus): void {
    this.secondMenuLinks = menus;
  }

  menuClick(link: GungMenu){
    if(link.action){
      link.action();
    }
    this.closeExpandMenu.emit(true);
  }

  hideSecondMenu(): void {
    this.secondMenuLinks = null;
  }
}
