import { Injectable } from '@angular/core';
import { CartService } from '../cart/cart.service';
import { Observable, timer, forkJoin, of, BehaviorSubject } from 'rxjs';
import { map, debounce, switchMap, first, tap, debounceTime, distinctUntilChanged } from 'rxjs';
import { Product } from '../../models';
import { CustomerProductPrice, CartRowPrice } from '../../models/price';
import { CartRow } from '../../state/cart/types';

import { PriceService } from '../price/price.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { ProductService } from '../products/product.service';
import { CartTotalsService } from '../cart-totals/cart-totals.service';

export interface NavbarCartItem {
  product: Product;
  price: CartRowPrice;
  cartRow: CartRow;
}
export interface NavbarCartModel {
  rows: NavbarCartItem[];
  totalValue: number;
  currency: string;
  rowsMapped: { [id: string]: NavbarCartItem };
}
@Injectable({
  providedIn: 'root'
})
export class NavbarCartService {
  excludeBlockedProducts = true;

  devMode = localStorage.getItem('dev') || false;
  protected data = new BehaviorSubject<NavbarCartModel>({
    rows: [],
    totalValue: 0,
    currency: '',
    rowsMapped: {}
  });

  constructor(
    protected cartService: CartService,
    protected productService: ProductService,
    protected priceService: PriceService,
    protected customerService: SelectedCustomerService,
    protected cartTotalsService: CartTotalsService
  ) {
    if(!this.devMode){
      this.getContent$().subscribe(d => this.data.next(d));
    }
      
  }

  clearCart() {
    this.cartService.clearCart();
  }

  protected getContent$() {
    return this.cartTotalsService.getTotals().pipe(
      debounceTime(500),
      switchMap(totals => forkJoin([of(totals), this.cartService.getCurrentCart().pipe(first())])),
      switchMap(([totals, rows]) => {
        const idQtyMap = rows.reduce((acc, curr) => ({ ...acc, [curr.productId]: curr.qty }), {});
        const ids = Object.keys(idQtyMap);
        return forkJoin({
          rows: of(rows),
          products: ids.length > 0 ? this.productService.getFullProductsByIds(ids, this.excludeBlockedProducts).pipe(first()) : of([]),
          prices: this.priceService.getCartRowPricesObservable(rows).pipe(first()),
          totals: of(totals).pipe(first())
        });
      }),
      map(({ rows, products, prices, totals }) => {
        const toReturnRows = rows.map(row => {
          const rowProduct = products.find(p => p.id === row.productId);
          const rowPrice = prices.find(
            r => r.productId === row.productId && r.productPartialId === row.productPartialId
          );
          if (!rowProduct || !rowPrice) {
            throw new Error('Could not find mapped product or price');
          }
          const data: NavbarCartItem = {
            product: rowProduct,
            price: rowPrice,
            cartRow: row
          };
          return data;
        });
        const totalValue = totals.totalValue;
        const currency = totals.totalCurrency;

        const rowsMapped = {};
        toReturnRows.forEach(row => {
          rowsMapped[row.cartRow.productId + (row.cartRow.productPartialId ? '_' + row.cartRow.productPartialId : '')] =
            row;
        });
        return {
          rows: toReturnRows,
          rowsMapped,
          totalValue,
          currency
        };
      })
    );
  }

  getContents(): Observable<NavbarCartModel> {
    return this.data.asObservable();
  }
}
