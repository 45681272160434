@for (item of dataTable; track index; let index = $index) {
  <tr>
    <td [attr.data-label]="'ID' | translate">
      <a [routerLink]="[ '/customers', item[0] ]" (click)="selectCustomer(item[0])">{{ item[0] }}</a>
    </td>
    <td [attr.data-label]="'NAME' | translate">
      <a [routerLink]="[ '/customers', item[0] ]" (click)="selectCustomer(item[0])">{{ item[1] }}</a>
    </td>
    <td [attr.data-label]="'ADDRESS' | translate">
      {{ item[2] }}
    </td>
    <td [attr.data-label]="'CITY' | translate">
      {{ item[3] }}
    </td>
    <td [attr.data-label]="'COUNTRY' | translate">
      {{ item[4] }}
    </td>
    <td [attr.data-label]="'ORG_NR' | translate">
      {{ item[5] }}
    </td>
    <td>
      <lib-action-btn [item]="originalData[item[0]]" [config]="actionButtonsConfig"></lib-action-btn>
    </td>
  </tr>
}
