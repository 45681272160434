import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { ReportCentralReport } from '../models/report-central';
import { Observable } from 'rxjs';
import { ReportCentralReportListTableComponent } from '../components/report-central-report-list-table/report-central-report-list-table.component';
import { ReportCentralReportService } from './report-central-report.service';

@Injectable({
  providedIn: 'root'
})
export class ReportCentralReportListConfigService implements ConfigService<ReportCentralReport> {
  topFilter = false;

  constructor(protected reportCentralReportService: ReportCentralReportService) {}

  getItems(): Observable<ReportCentralReport[]> {
    return this.reportCentralReportService.getAllForUser();
  }

  getLayouts(): ListLayout<ReportCentralReport>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ReportCentralReportListTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order list'
      }
    ];
  }

  getSearchTerms(item: ReportCentralReport): string[] {
    return [item.id, item.description, ...item.urls.map(u => u.url), ...item.urls.map(u => u.name)];
  }

  getItemId(item: ReportCentralReport): string {
    return item.id;
  }

  getSortOptions(): ListSortOption<ReportCentralReport>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getFilters(): ConfigBaseFilter<ReportCentralReport>[] {
    return [new StatusFilter()];
  }
}

class StatusFilter extends SimpleConfigBaseFilter<ReportCentralReport> {
  getName(): string {
    return 'STATUS';
  }

  getOptionIds(item: ReportCentralReport): string[] {
    return [item.status];
  }

  getOptionName(key: string): string {
    return key;
  }
}
