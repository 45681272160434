<h3 translate class="page-title text-center">PIM Variants</h3>
<div class="row">
  <div class="col text-right">
    <div ngbDropdown placement="bottom" class="d-inline-block">
      <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle translate>ACTIONS</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-container">
        <button ngbDropdownItem (click)="openModalUploadImages()" translate>UPLOAD_MULTIPLE_IMAGES</button>
        <button ngbDropdownItem (click)="openModalUploadDocuments()" translate>UPLOAD_MULTIPLE_DOCUMENTS</button>
        <!-- <button ngbDropdownItem translate>CREATE_PRODUCT_MASTER</button> -->
        <button ngbDropdownItem (click)="openModalUploadExcel()" translate>IMPORT_EXCEL</button>
        <!-- <button ngbDropdownItem (click)="fixPimProducts()" translate>FIX_PIM_PRODUCTS</button> -->
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <lib-filter-list [configService]="pimListConfigService"></lib-filter-list>
  </div>
</div>
