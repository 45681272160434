<div class="card product-card">
  <div class="card-body">
    <div class="image-wrapper" [routerLink]="['/product/', data.id]">
      <img
        class="img-fluid"
        [src]="data.extra.images[0].s3Uri | gungImageUrl
          :'type:list'
          :'etag:'+data.extra.images[0].s3ETag
        "
        alt=""
      />
    </div>
    <div class="product-text pb-1">
      <div>{{ data.id }}</div>
      <div class="text-truncate" translate>{{ data.name }}</div>
      <div translate *ngIf="price" class="font-weight-bold">
        {{ price.customerNetPrice.value | gungCurrency: price.customerNetPrice.currencyCode }}
      </div>
      <lib-availability [availability]="availability" [isSales]="isSales" *ngIf="availability"></lib-availability>
      <div class="product-card-button">
        <lib-buy-btn [id]="data.id" [product]="data"></lib-buy-btn>
      </div>
    </div>
  </div>
</div>
