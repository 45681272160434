import { Injectable } from '@angular/core';
import { ProductListConfigService } from '../../../services/product-list-config/product-list-config.service';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { Product } from '../../../models';
import { PlanogramProductGridComponent } from '../components/planogram-product-grid/planogram-product-grid.component';

@Injectable({
  providedIn: 'root'
})
export class PlanogramProductListConfigService extends ProductListConfigService{

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => PlanogramProductGridComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }
}
