import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EdiIncomingDespatchAdviceDetailConfigService {
  disable = {
    despatchAdviceNumber: true,
    shipper: true,
    despatchAdviceDate: true,
    shipmentNumber: true,
    deliveryDate: true,
    shippingDate: true,
    buyerOrdeNumber: true,
    notes: true
  };

  constructor() {}
}
