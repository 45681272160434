import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import {
  Customer,
  AuthService,
  SelectedCustomerService,
  CreateUserModalComponent,
  NavbarConfigService,
  GungModalService,
  ReportCentralReportService
} from 'gung-standard';
import { ActionButton, ActionButtonConfigService } from 'gung-common';
import { JeevesModalService } from '../jeeves-modal-service/jeeves-modal.service';
import { first, switchMap, filter, map } from 'rxjs';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class JeevesCustomerActionConfigService implements ActionButtonConfigService<Customer> {
  enableContacts = this.navbarConfigService.enableContacts;
  enableActivityInternal = this.navbarConfigService.enableActivityInternal;
  enableStatisticsExcelExport = this.navbarConfigService.enableStatisticsExcelExport;

  base = [
    {
      title: 'ADD_CONTACT',
      action: (item: Customer) => {
        this.modalService.openAddContactModal(item);
      }
    },
    {
      title: 'PLACE_ORDER',
      action: (item: Customer) => {
        this.selectedCustomerService.selectCustomer(item.id);
        this.authService
          .getCurrentUser()
          .pipe(first())
          .subscribe(user => {
            this.router.navigate(['products']);
          });
      }
    },
    {
      title: 'CREATE_USER',
      action: (item: Customer) => {
        this.createUser(item);
      }
    }
  ];

  extra = [
    {
      title: 'SELECT_CUSTOMER',
      action: (item: Customer) => {
        this.router.navigate(['customers', item.id]);
        this.selectedCustomerService.selectCustomer(item.id);
      }
    }
  ];

  statisticsExcelExport = [
    {
      title: "EXPORT_ORDER_STATISTICS",
      action: (item) => {
        this.reportCentralReportService.exportOrderStatistics(item.id).pipe(first()).subscribe(_ => {
          this.gungModalService.openConfirmYesNoModal('EXPORT_ORDER_STATISTICS', 'EXPORT_ORDER_STATISTICS_MODAL', {}, 'GO_TO_REPORT_CENTRAL', 'CLOSE').then(
            result => {
              if (result) {
                this.router.navigate(['/report-central']);
              }
            },
            reject => {}
          );
        });
      }
    },
    {
      title: "EXPORT_INVOICE_STATISITCS",
      action: (item) => {
        this.reportCentralReportService.exportInvoiceStatistics(item.id).pipe(first()).subscribe(_ => {
          this.gungModalService.openConfirmYesNoModal('EXPORT_INVOICE_STATISITCS', 'EXPORT_INVOICE_STATISITCS_MODAL', {}, 'GO_TO_REPORT_CENTRAL', 'CLOSE').then(
            result => {
              if (result) {
                this.router.navigate(['/report-central']);
              }
            },
            reject => {}
          );
        });
      }
    }
  ];

  activityActions = [
    {
      title: 'ACTIVITY_CREATE',
      action: (customer: Customer) => {
        this.gungModalService.openCreateActivityModal().then(
          result => {},
          reason => {
            console.log(`Dismissed ${reason}`);
          }
        );
      }
    },
    // {
    //   title: 'ACTIVITIES',
    //   action: (item2: Customer) => {
    //     this.authService
    //       .getCurrentUser()
    //       .pipe(first())
    //       .subscribe(user => {
    //         this.router.navigate(['activity-internal']);
    //       });
    //   }
    // }
  ]

  contactsActions = [
    {
      title: 'ADD_CONTACT',
      action: (item: Customer) => {
        this.gungModalService.openAddContactModal(item);
      }
    }
  ];

  constructor(
    protected modalService: JeevesModalService,
    protected authService: AuthService,
    protected router: Router,
    protected selectedCustomerService: SelectedCustomerService,
    protected ngbModal: NgbModal,
    protected gungModalService: GungModalService,
    protected navbarConfigService: NavbarConfigService,
    protected reportCentralReportService: ReportCentralReportService
  ) {}

  checkIfSales(values: string[]): boolean {
    return values.indexOf('SALES') > -1;
  }

  checkIfOnRoute(value: string): boolean {
    return value.localeCompare('customers/') === 0;
  }
  getButtons(item: Customer): Observable<ActionButton<Customer>[]> {
    return this.authService.getRoles().pipe(
      filter(roles => !!roles),
      first(),
      switchMap(roles => forkJoin([of(roles), of(this.router.url)])),
      switchMap(([roles, url]) => {
        if (roles.indexOf('SALES') === -1) {
          return of([]);
        }

        if (this.router.url === '/customers') {
          return new Observable<ActionButton<Customer>[]>(subscriber => {
            subscriber.next([
              ...this.extra,
              ...this.base,
              ...(this.enableActivityInternal? this.activityActions : []),
              ...(this.enableContacts ? this.contactsActions : []),
              ...(this.enableStatisticsExcelExport ? this.statisticsExcelExport : [])
            ]);
            subscriber.complete();
          });
        } else {
          return new Observable<ActionButton<Customer>[]>(subscriber => {
            subscriber.next([
              ...this.base,
              ...(this.enableActivityInternal? this.activityActions : []),
              ...(this.enableContacts ? this.contactsActions : []),
              ...(this.enableStatisticsExcelExport ? this.statisticsExcelExport : [])
            ]);
            subscriber.complete();
          });
        }
      })
    );
  }

  createUser(customer: Customer): void {
    const userToCreateFrom = {
      username: '',
      name: customer.name,
      email: '',
      password: '',
      managedCompanyIds: customer.id,
      roles: ['USER'],
      managedMultistockIds: [],
      managedAssortmentIds: [],
      assortment: '',
      activeGroups: {},
      phone: ''
    };

    const modalRef = this.ngbModal.open(CreateUserModalComponent, { size: 'lg' });
    modalRef.componentInstance.createUserFrom = userToCreateFrom;
    modalRef.result.then(
      (u: any) => {
        if (!!u) {
          this.router.navigate(['/admin/users-details/' + u.username]);
        }
      },
      reason => {}
    );
  }
}
