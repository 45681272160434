<div class="modal-header">
  <h4 class="modal-title" id="modal-title" translate>{{ !!claim?.id ? 'CLAIM' : 'CREATE_CLAIM' }} {{ claim?.id }}</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="orkForm">
  <form class="form" [formGroup]="orkForm" (ngSubmit)="onCreateClaim(saveBtn)">
    <div class="modal-body">
      <fieldset [disabled]="!!claim?.id">
        <div class="row">
          <div class="col-12 col-md-6">
            <lib-meta-select-input
              formControlName="kundreklamationorsak"
              table="orkk"
              metaId="kundreklamationorsak"
              metaDisplay="kundreklamationbeskr"
            ></lib-meta-select-input>
            <lib-forms-message-error [controller]="orkForm.get('kundreklamationorsak')"></lib-forms-message-error>
            <lib-meta-select-input
              formControlName="claimworkflow"
              table="claimworkflow"
              metaId="claimworkflow"
              metaDisplay="claimworkflowdescr"
              [optionsFilterFunction]="filterClaimWork.bind(this)"
            ></lib-meta-select-input>
            <lib-forms-message-error [controller]="orkForm.get('claimworkflow')"></lib-forms-message-error>
            <!-- <lib-gung-text-input gungTranslate="EDIT" formControlName="edit" placeholder="EDIT"></lib-gung-text-input> -->
            
            <lib-gung-text-input gungTranslate="NAME" formControlName="name" placeholder="NAME"></lib-gung-text-input>
            <lib-gung-text-input gungTranslate="EMAIL" formControlName="email" placeholder="EMAIL"></lib-gung-text-input>
            <lib-forms-message-error [controller]="orkForm.get('email')"></lib-forms-message-error>
            <!-- <lib-gung-text-input
              gungTranslate="SALJARE"
              formControlName="saljare"
              placeholder="SALJARE"
            ></lib-gung-text-input>
            <lib-gung-text-input gungTranslate="FTGNR" formControlName="ftgnr" placeholder="FTGNR"></lib-gung-text-input> -->
          </div>
          <div class="col-12 col-md-6">
            <lib-gung-text-input
              gungTranslate="ORDERNR"
              formControlName="ordernr"
              placeholder="ORDERNR"
            ></lib-gung-text-input>
            <lib-forms-message-error [controller]="orkForm.get('ordernr')"></lib-forms-message-error>
            <lib-gung-text-input
              gungTranslate="FAKTNR"
              formControlName="faktnr"
              placeholder="FAKTNR"
            ></lib-gung-text-input>
            <lib-gung-text-input
              gungTranslate="CLAIMQTY"
              formControlName="claimqty"
              placeholder="CLAIMQTY"
            ></lib-gung-text-input>
            <lib-forms-message-error [controller]="orkForm.get('claimqty')"></lib-forms-message-error>
            <lib-gung-text-input gungTranslate="EVENTNOTE" formControlName="eventnote"></lib-gung-text-input>
            <lib-gung-text-input
              gungTranslate="ARTNR"
              formControlName="artnr"
              placeholder="ARTNR"
              *ngIf="!!claim?.id; else searchArticle"
            ></lib-gung-text-input>
            <lib-forms-message-error [controller]="orkForm.get('artnr')"></lib-forms-message-error>
          </div>
  
          <div class="col-12">
            <lib-gung-text-area-input
              gungTranslate="NOTE"
              formControlName="edit"
              placeholder="NOTE"
            ></lib-gung-text-area-input>
            <lib-forms-message-error [controller]="orkForm.get('edit')"></lib-forms-message-error>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light btn-sm" (click)="activeModal.dismiss('cancel click')" translate>
        CLOSE
      </button>
      <button [ngClass]="{ 'd-none': claim?.id }" type="submit" class="btn btn-primary btn-sm ml-2" #saveBtn translate>
        CREATE
      </button>
    </div>
  </form>
</div>


<ng-template #searchArticle>
  <div class="">
    <label for="typeahead-prevent-manual-entry" translate>ARTNR</label>
    <input
      #searchlement
      id="typeahead-prevent-manual-entry"
      type="text"
      class="form-control"
      [(ngModel)]="modelSearch"
      [ngbTypeahead]="search"
      [inputFormatter]="formatter"
      [resultFormatter]="resultFormatter"
      (selectItem)="selectProduct($event)"
      placeholder="{{ 'ARTNR' | translate }}"
      (focusout)="focusout($event, modelSearch)"
      (ngModelChange)="ngModelChange($event, searchlement)"
      [editable]="false"
    />
  </div>
</ng-template>
