import { Component } from '@angular/core';
import { LoggedInUserListConfigService } from '../../services/logged-in-user-list-config.service';

@Component({
  selector: 'lib-logged-in-user-list',
  templateUrl: './logged-in-user-list.component.html',
  styleUrls: ['./logged-in-user-list.component.css']
})
export class LoggedInUserListComponent {
  constructor(
    public loggedInUserListConfigService: LoggedInUserListConfigService
  ) {}
}
