import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { OrderPortalStatistic } from '../../models/order-portal';
import { ChartConfiguration, ChartDataset } from 'chart.js';
import { Subject } from 'rxjs';
import { format, subMonths } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-order-portal-statistics-chart',
  templateUrl: './order-portal-statistics-chart.component.html',
  styleUrls: ['./order-portal-statistics-chart.component.css']
})
export class OrderPortalStatisticsChartComponent implements OnChanges, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();

  @Input()
  statistics: OrderPortalStatistic[];

  public lineChartData: ChartDataset[];
  public lineChartLabels: string[][];
  public lineChartOptions: ChartConfiguration<'bar'>['options'];
  public lineChartLegend = true;
  public lineChartType = 'line';

  data = [];
  loaded = false;

  constructor(protected translateService: TranslateService) { }

  ngOnChanges(): void {
    if (!this.statistics) {
      return;
    }

    const seenChannel = new Set<string>();
    const allFoundChannelsSuccess = [];
    const groupedOnYearMonth: { [key: string]: SuccessFailWrapper } = {};
    this.statistics.forEach(statistic => {
      const yearMonth = statistic.dateYearMonthDay.substring(0, 7);
      groupedOnYearMonth[yearMonth] = groupedOnYearMonth[yearMonth] || { failed: 0, success: 0, perChannel: {} };
      groupedOnYearMonth[yearMonth].failed += statistic.totalFailed;
      groupedOnYearMonth[yearMonth].success += statistic.totalSuccess;

      for (const channel of Object.keys(statistic.channelToSucceeded)) {
        if (!seenChannel.has(channel)) {
          allFoundChannelsSuccess.push(channel);
          seenChannel.add(channel);
        }

        groupedOnYearMonth[yearMonth].perChannel[channel] = groupedOnYearMonth[yearMonth].perChannel[channel] || {
          failed: 0,
          success: 0
        };
        groupedOnYearMonth[yearMonth].perChannel[channel].success += statistic.channelToSucceeded[channel] || 0;
      }
    });

    const labels = [];
    const succededByChannel: { [s: string]: number[] } = {};

    const today = new Date();
    for (let i = 0; i < 12; i++) {
      const subbed = subMonths(today, i);
      const yearMonth = format(subbed, 'yyyy-MM');

      labels.push(yearMonth);
      for (const channel of allFoundChannelsSuccess) {
        succededByChannel[channel] = succededByChannel[channel] || [];
        succededByChannel[channel].push(groupedOnYearMonth[yearMonth]?.perChannel[channel]?.success || 0);
      }
    }

    this.lineChartLabels = labels.reverse();
    const lineChartData = [];
    for (const channel of Object.keys(succededByChannel)) {
      lineChartData.push({
        label: this.translateService.instant(channel),
        data: succededByChannel[channel].reverse(),
        type: 'line',
        borderWidth: 2,
        yAxisID: 'y-axis-1'
      });
    }
    this.lineChartData = lineChartData;

    this.initChartOptions();
    this.loaded = true;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initChartOptions(): void {
    this.lineChartOptions = {
      plugins: {
        tooltip: {
          enabled: true,
          mode: 'index',
          callbacks: {
            label: context => {
              return context.dataset.label + ': ' + context.dataset.data[context.dataIndex].toLocaleString();
            }
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0
      },
      scales: {
        'y-axis-1': {
          type: 'linear',
          display: true,
          position: 'left',
          title: {
            display: true,
            text: this.translateService.instant('SUCCESSFULL_ORDER_CREATIONS'),
            font: {
              size: 18
            }
          }
        }
      }
    };
  }
}

interface SuccessFailWrapper {
  failed: number;
  success: number;

  perChannel: { [s: string]: { failed: number; success: number } };
}
