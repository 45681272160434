import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Pim } from '../../models/pim';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { MetadataService } from '../../services/metadata/metadata.service';
import { PimService } from '../../services/pim/pim.service';
import { ProductService } from '../../services/products/product.service';
import { PimProductVariantsComponent } from '../pim/pim-product/pim-product-variants/pim-product-variants.component';

@Component({
  selector: 'lib-pim-variants-product',
  templateUrl: './pim-variants-product.component.html',
  styleUrls: ['./pim-variants-product.component.css']
})
export class PimVariantsProductComponent  extends PimProductVariantsComponent {
  @Input()
  pimProductVariants: Pim[];

  @Input()
  pimProduct: Pim;

  isLoading: boolean;

  constructor(
    protected pimService: PimService,
    protected metadataService: MetadataService,
    protected productService: ProductService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.pimProductVariants.forEach(variant => {
      if (!variant.extra.pim) {
        variant.extra.pim = {};
      }
      variant.extra.pim.season = variant?.extra?.pim?.season || [''];
    });
    this.pimProductVariants = this.pimProductVariants.filter(variant => {
      return !!variant?.erpReadOnlyData?.AGA;
    });
  }

  onSaveVariants() {
    this.isLoading = true;
    this.pimProductVariants.forEach(variant => {
      if (!Array.isArray(variant.extra.pim.season)) {
        if (variant.extra.pim.season.includes(',')) {
          variant.extra.pim.season = variant.extra.pim.season.split(',');
        }
        else variant.extra.pim.season = [variant.extra.pim.season];
      }
    })
    this.pimService.postPimProductsBatch(this.pimProductVariants).subscribe(next => {
      this.isLoading = false;
      this.gungModalService.openConfirmYesNoModal(
        undefined,
        this.translationService.instant('VARIANTS_SAVED'),
        { size: 'sm' },
        'OK',
        null
      );
    });
  }
}