<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>IMPORT_TRANSACTION_DATA</h4>

  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="container">

    <div class="row" *ngIf="columns.length == 0">
      <div class="col-12 d-flex flex-column align-items-center">
        <h3 translate>UPLOAD_CSV_FILE</h3>
        <span class="d-inline-block" style="max-width: 600px;" translate>BANKGIRO_UPLOAD_CSV_LONG</span>

        <div *ngIf="!!errorMessage">
          <div class="alert alert-danger" role="alert" translate>
            {{errorMessage}}
          </div>
        </div>

        <div class="d-flex my-3" style="gap: 1rem;">
          <div ngbDropdown class="d-inline-block">
            <label for="delimiter" class="control-label" translate>CSV_DELIMITER</label>
            <select id="delimiter" name="delimiter"
                    class="form-control shadow-sm" [(ngModel)]="delimiter">
              <option translate ngValue=""></option>
              <option translate ngValue=",">,</option>
              <option translate ngValue=";">;</option>
            </select>
          </div>

          <div class="dropzone">
            <input [disabled]="!delimiter" type="file" #fileDropRef id="fileDropRef" [multiple]="false"
                   (change)="onFileSelected($event.target.files)" accept=".csv">
            <i class="fa-light fa-upload"></i>
            <h3 translate>DRAG_AND_DROP_FILE_HERE</h3>
            <h3 class="text-lowercase" translate>OR</h3>
            <label for="fileDropRef" class="bankgiro-upload-file-label bg-primary" translate>BROWSE_FOR_FILE</label>
          </div>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="!!columns && columns.length > 0">

      <div class="col-12 col-lg-6">
        <span class="drag-heading" translate>YOUR_FILE</span> <br/>
        <span class="drag-subtext" translate>CHOOSE_INFORMATION_LOCATION_BY_DRAG_AND_DROP</span>
        <div class="mt-2 pt-2">
          <div
            id="columns"
            cdkDropList
            class="example-list"
            [cdkDropListData]="columns"
            [cdkDropListConnectedTo]="connectedToMappings"
            (cdkDropListDropped)="dropMappging($event, 'columns')"
            [cdkDropListEnterPredicate]="columnsPredicate.bind(this)"

            cdkDropListAutoScrollDisabled="true"
            cdkDropListAutoScrollStep="false"
            cdkDropListLockAxis=""
            cdkDropListOrientation=""
            cdkDropListSortingDisabled="true"

            cdkDragRootElement=".modal-lg-custom"
          >
            <div class="fields field-headers d-flex align-items-center justify-content-between">
              <span translate>COLUMN_HEADER_FIELD</span>

              <!--
              We need to match this header with the columns below.
              The width of 220 here is because the right box below will always be 220px. Hackish solution, I'm sorry.
              -->
              <span style="width: 240px;" translate>COLUMN_EXAMPLE_VALUE</span>

            </div>
            <div class="fields d-flex align-items-center justify-content-between pb-1" *ngFor="let column of columnsAll">
              <span>{{column}}</span>
              <div class="d-flex align-items-center">
              <span class="border float-right example-box form-control text-center"
                    style="width: 200px;"
                    [ngClass]="{'drag-success': columns.indexOf(column) === -1}"
                    [cdkDragData]="columns[columns.indexOf(column)]"
                    cdkDrag
                    [cdkDragDisabled]="columns.indexOf(column) === -1"
              >
                                        {{values[selectedValue][column]}}
                                    </span>
                <span class="px-3" style="width: 40px;">
                  <i *ngIf="columns.indexOf(column) === -1" class="fa fa-check icon-success"></i>
                </span>
              </div>
              <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <span class="drag-heading d-inline-block px-2" style="margin-bottom: 65px" translate>AVAILABLE_FIELDS</span>

        <div *ngFor="let field of fields" class="mappings">
          <div
            id="{{field}}"
            cdkDropList
            class="example-list"
            [cdkDropListData]="mappings[field]"
            cdkDropListConnectedTo="columns"
            (cdkDropListDropped)="dropMappging($event, 'mappings')"
            [cdkDropListEnterPredicate]="mappingsPredicate"

            cdkDropListAutoScrollDisabled="true"
            cdkDropListAutoScrollStep="false"
            cdkDropListLockAxis=""
            cdkDropListOrientation="horizontal"
            cdkDropListSortingDisabled="false"
          >
            <div class="form-group px-2"
            >
              <span class="small" translate>{{fieldsInfo[field].name}}</span>
              <div class="d-flex align-items-center">
                <div class="form-control border" (click)="freeText(mappings[field])"
                     style="width: 230px;"
                     [ngClass]="{'border-danger': fieldsInfo[field].error, 'drag-success': mappings[field].length > 0}">
                                    <span
                                      *ngFor="let map of mappings[field]"
                                      class="example-box d-inline-block text-center"
                                      cdkDrag
                                      [cdkDragData]="mappings[field]"
                                      [cdkDragDisabled]="columnsAll.indexOf(map) === -1"
                                      style="width: 180px;"
                                    >
                                        {{ values[selectedValue][map]}}
                                    </span>
                  <input *ngIf="mappings[field]?.length > 0 && columnsAll.indexOf(mappings[field][0]) === -1"
                         type="text" name="" id="" [(ngModel)]="mappings[field][0]" (blur)="removeField(field)"
                         autofocus>
                  <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                </div>
                <i class="fa-regular fa-circle-question ml-1"
                   ngbTooltip="{{ fieldsInfo[field].tooltip | translate }}"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<div class="modal-footer">
  <div class="ml-auto">
    <button type="button" class="btn btn-primary ml-1" (click)="close()" translate>
      CLOSE
    </button>
    <button *ngIf="columns.length > 0" type="button" class="btn btn-secondary" (click)="send()"
            [disabled]="columnsAll.length === 0 ? true : undefined" translate>IMPORT
    </button>
  </div>
</div>
