import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { Product } from '../../../../../models';
import { CustomerProductPrice } from '../../../../../models/price';
import { Availability } from '../../../../../models/availability';
import { ProductService } from '../../../../../services/products/product.service';
import { PriceService } from '../../../../../services/price/price.service';
import { AvailabilityService } from '../../../../../services/availability/availability.service';
import { SelectedCustomerService } from '../../../../../services/selected-customer/selected-customer.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../../../services/auth/auth.service';

import { filter, first, forkJoin, mergeMap, of } from 'rxjs';

export interface GridViewProductSlideshow {
  product: Product;
  price: CustomerProductPrice;
  availability: Availability;
}
@Component({
  selector: 'lib-home-hero-banner-product-cards',
  templateUrl: './home-hero-banner-product-cards.component.html',
  styleUrl: './home-hero-banner-product-cards.component.scss'
})
export class HomeHeroBannerProductCardsComponent implements OnInit, OnChanges {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = Number(window.innerWidth);
    this.resolveCurrentItemsPerRow(this.itemsPerRow, this.innerWidth);
  }

  innerWidth: number;

  @Input()
  productIds: string;

  @Input()
  itemsPerRow: number;

  loading: boolean = true;
  noProducts: boolean = false;

  mappedData: GridViewProductSlideshow[] = [];

  currentItemsPerRow: number;

  currentLang: string;

  isAnonymous: boolean;

  constructor(
    protected productService: ProductService,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    protected selectedCustomerService: SelectedCustomerService,
    protected router: Router,
    protected translateService: TranslateService,
    protected authService: AuthService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
    this.innerWidth = Number(window.innerWidth);
    this.resolveCurrentItemsPerRow(this.itemsPerRow, this.innerWidth);

    if (!!this.productIds) {
      this.initSlideshow();
    } else {
      this.loading = false;
      this.noProducts = true;
    }

    this.authService
      .getCurrentUser()
      .pipe(
        filter(user => !!user),
        first()
      )
      .subscribe(user => (this.isAnonymous = user.roles.filter(role => role.toUpperCase() === 'ANONYMOUS').length > 0));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes?.productIds?.firstChange &&
      changes?.productIds?.previousValue != changes?.productIds?.currentValue &&
      !!this.productIds
    ) {
      this.initSlideshow();
    } else {
      if (!this.productIds) {
        this.noProducts = true;
      }
      this.loading = false;
    }
  }

  initSlideshow(): void {
    this.loading = true;

    const newIds: string[] = this.productIds.split(',');

    if (!newIds || newIds.length === 0) {
      this.loading = false;
      return;
    }

    this.productService
      .getFullProductsByIds(newIds)
      .pipe(
        first(),
        mergeMap(products => {
          return forkJoin([of(products), this.selectedCustomerService.getSelectedCustomer().pipe(first())]);
        }),
        mergeMap(([products, customer]) => {
          return forkJoin([
            of(products),
            this.priceService.getCustomerPrices(newIds, customer.id).pipe(first()),
            this.availabilityService.getAvailabilities(newIds).pipe(first())
          ]);
        })
      )
      .subscribe(([products, prices, availabilities]) => {
        const result: GridViewProductSlideshow[] = [];

        for (const product of products) {
          const data: GridViewProductSlideshow = {
            product: product,
            price: prices.filter(price => price.productId === product.id)[0] || undefined,
            availability: availabilities.filter(av => av.productId === product.id)[0] || undefined
          };
          result.push(data);
        }

        this.mappedData = [...result];
        this.loading = false;
      });
  }

  resolveCurrentItemsPerRow(itemsPerRow: number, innerWidth: number) {
    if (innerWidth < 768 && itemsPerRow > 2) {
      this.currentItemsPerRow = 2;
      return;
    }

    if (this.currentItemsPerRow !== this.itemsPerRow) {
      this.currentItemsPerRow = this.itemsPerRow;
    }
  }

  goToDetail(product: Product): void {
    if (product?.productType === 'concept') {
      this.router.navigate(['/concept/', product.id]);
    } else {
      if (product?.extra?.conceptId) {
        this.router.navigate(['/concept/', product.extra.conceptId], {
          queryParams: {
            productid: product.id
          }
        });
      } else {
        this.router.navigate(['/product/', product.id]);
      }
    }
  }
}
