import { Component } from '@angular/core';
import { AvailabilityModalComponent } from 'gung-standard';

@Component({
  selector: 'gung-jeeves-jeeves-availability-modal',
  templateUrl: './jeeves-availability-modal.component.html',
  styleUrl: './jeeves-availability-modal.component.css'
})
export class JeevesAvailabilityModalComponent extends AvailabilityModalComponent {

}
