import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JeevesDeliveryLocation } from '../../models/jeevesDeliveryLocation';

@Injectable({
  providedIn: 'root'
})
export class DeliveryLocationsService {
  constructor(protected http: HttpClient) {}

  getDeliveryLocationsForCustomer(customerId: string): Observable<JeevesDeliveryLocation[]> {
    const url = `json/customer/${customerId}/delivery-locations`;
    return this.http.get<JeevesDeliveryLocation[]>(url);
  }
}
