<div *ngIf="offer">
    <div class="d-flex flex-row justify-content-between align-items-center mb-4 mt-4">
      <div>
        <h2>Offer {{ offer.id }}</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-12 table-responsive gung-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th translate>PRODUCT_ID</th>
              <th translate>NAME</th>
              <th translate>QUANTITY</th>
            </tr>
          </thead>
          <tbody *ngIf="offer">
            <tr *ngFor="let row of offer.rows">
              <td [attr.data-label]="'PRODUCT_ID' | translate">
                <a [routerLink]="['/product/', row.productId]">{{ row.productId }}</a>
              </td>
              <td [attr.data-label]="'NAME' | translate">
                <a [routerLink]="['/product/', row.productId]">
                  {{(products && products[row.productId]?.name) ? products[row.productId]?.name : row.name }}
                </a>
              </td>
              <td [attr.data-label]="'QUANTITY' | translate">
                {{ row.quantity }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  