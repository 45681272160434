<div *ngIf="!!data">
    <div class="row" *ngIf="supplierDynamicColumnsService.shouldDisplayConfirmCheckedButton()">
      <div class="col-12">
        <button type="button" class="btn btn-secondary btn-sm btn-block" (click)="confirmAllCheckedLines($event)">
          <span translate>CONFIRM_ALL_CHECKED</span>
          <i class="fas fa-check ml-1"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 table-responsive gung-responsive" style="overflow-x: unset;">
        <table class="table">
          <thead>
            <tr>
              <th *ngFor="let column of tableColumn" class="{{ column.extra?.thClass }}">
                <div class="d-flex justify-content-between" [class.sort-col]="column.sorted" (click)="column.sorted ? sortColumn(column): null">
                  <span translate>{{ column.name }}</span>
                  
                  <i class="fa-solid fa-sort" *ngIf="column.sorted && !column?.sort"></i>
                  <i class="fa-duotone fa-sort-up" *ngIf="column?.sort ==='asc'"></i>
                  <i class="fa-duotone fa-sort-down" *ngIf="column?.sort ==='desc'"></i>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of data; index as i; trackBy: trackByFn" [ngClass]="{ 'line-checked': !!row.checked }">
              <td *ngFor="let column of tableColumn">
                <ng-container [ngSwitch]="column.type">
                  <ng-template *ngSwitchCase="ColumnRowTableType.INPUTCHECKBOX" [ngTemplateOutlet]="checkboxTemplate"
                    [ngTemplateOutletContext]="{ $implicit: row, column }">
                  </ng-template>
                  <ng-template *ngSwitchCase="ColumnRowTableType.NUMBER" [ngTemplateOutlet]="numberTemplate"
                    [ngTemplateOutletContext]="{ $implicit: row, column }">
                  </ng-template>
                  <ng-template *ngSwitchCase="ColumnRowTableType.PRICE" [ngTemplateOutlet]="priceTemplate"
                    [ngTemplateOutletContext]="{ $implicit: row, column }">
                  </ng-template>
                  <ng-template *ngSwitchCase="ColumnRowTableType.TEXT" [ngTemplateOutlet]="textTemplate"
                    [ngTemplateOutletContext]="{ $implicit: row, column }">
                  </ng-template>
                  <ng-template *ngSwitchCase="ColumnRowTableType.DATE" [ngTemplateOutlet]="dateTemplate"
                    [ngTemplateOutletContext]="{ $implicit: row, column }">
                  </ng-template>
                  <ng-template *ngSwitchCase="ColumnRowTableType.INPUTNUMBER" [ngTemplateOutlet]="inputNumberTemplate"
                    [ngTemplateOutletContext]="{ $implicit: row, column }">
                  </ng-template>
                  <ng-template *ngSwitchCase="ColumnRowTableType.INPUTDATE" [ngTemplateOutlet]="inputDateTemplate"
                    [ngTemplateOutletContext]="{ $implicit: row, column }">
                  </ng-template>
                  <ng-template *ngSwitchCase="ColumnRowTableType.ACTION" [ngTemplateOutlet]="actionTemplate"
                    [ngTemplateOutletContext]="{ $implicit: row, column }">
                  </ng-template>
                  <ng-template ngSwitchDefault>{{ column.name }}</ng-template>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</div>

<ng-template #checkboxTemplate let-row let-column="column">
  <lib-gung-checkbox-input-horizontal
     suppressLabel="true"
    [(ngModel)]="row.checked"
  ></lib-gung-checkbox-input-horizontal>
</ng-template>

<ng-template #numberTemplate let-row let-column="column">
  {{ getValues(row, column.path) | number: '1.0-2' }}
</ng-template>

<ng-template #priceTemplate let-row let-column="column">
  <lib-price [price]="getValues(row, isArray(column.path) ? column.path[0] : column.path)" [currency]="row.currency"></lib-price>
  <span *ngIf="isArray(column.path) && getValues(row, column.path[1]) !== 0">
    ({{getValues(row, column.path[1])}} %)
  </span>
</ng-template>

<ng-template #textTemplate let-row let-column="column">
  {{ getValues(row, column.path) }}
</ng-template>

<ng-template #dateTemplate let-row let-column="column">
  {{ getValues(row, column.path) | date: dateUtilService.dateFormat }}
</ng-template>

<ng-template #inputDateTemplate let-row let-column="column">
  <lib-gung-date-picker
    [minInitialDate]="today"
    [currentselectedDate]="getValues(row, column.path)"
    (selectedDate)="setValue(row, column.path, dateUtilService.getFormattedDateString($event))"
    suppressLabel="true"
    [extraInputClass]="row.extra.errors?.includes(column.path) ? 'form-control-sm border border-danger' : 'form-control-sm'"
    extraBtnClass="btn-sm">
  </lib-gung-date-picker>
</ng-template>

<ng-template #inputNumberTemplate let-row let-column="column">
  <!-- [placeholder]="getValues(row, column.path) ?? ''" -->
  <lib-gung-number-input
    suppressLabel="true"
    [ngModel]="getValues(row, column.path)"
    (ngModelChange)="setValue(row, column.path, (+$event))"
    [extraInputClass]="row.extra.errors?.includes(column.path) ? 'form-control-sm border border-danger' : 'form-control-sm'"
    [maxValue]="column.extra?.maxValue && column.extra.maxValue(row)"
    [minValue]="column.extra?.minValue && column.extra.minValue(row)"
  ></lib-gung-number-input>
</ng-template>

<ng-template #inputTextTemplate let-row let-column="column">
  <!-- [placeholder]="getValues(row, column.path) ?? ''" -->
  <lib-gung-text-input
    suppressLabel="true"
    [ngModel]="getValues(row, column.path)"
    (ngModelChange)="setValue(row, column.path, $event)"
    [extraInputClass]="row.extra.errors?.includes(column.path) ? 'form-control-sm border border-danger' : 'form-control-sm'"
  ></lib-gung-text-input>
</ng-template>

<ng-template #actionTemplate let-row let-column="column">
  <button *ngIf="column.path.includes('btnHistory') && detailsColumn" type="button" class="btn btn-info mr-2" (click)="detailsPurchaseLine(row, $event)" [disabled]="detailsColumn.length === 0">
    <span translate>DETAILS</span>
    <i class="fa-solid fa-circle-info ml-1"></i>
  </button>
  <lib-action-btn [item]="row" [config]="supplierPoActionConfigService"></lib-action-btn>
</ng-template>
