import { Component, OnInit } from '@angular/core';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-return-warranty',
  template: `
  <div>
    <h3 translate class="page-title text-center">RETURN_WARRANTY</h3>
    <div class="row">
      <div class="col-12 text-right">
        <button type="button" class="btn btn-primary" (click)="openCreateModal()" translate>
          CREATE_NEW
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <lib-return-warranty-list></lib-return-warranty-list>
      </div>
    </div>
  </div>
  `,
  styles: []
})
export class ReturnWarrantyComponent implements OnInit {

  constructor(protected gungModalService: GungModalService) {}

  ngOnInit(): void {
  }

  openCreateModal() {
    this.gungModalService.openViewCreateReturnWarrantyModal().then(
      result => {},
      reason => {
      }
    );
  }

}
