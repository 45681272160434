import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatrixDiscountsService } from '../../services/matrix-discounts/matrix-discounts.service';
import { MatrixDiscount } from '../../models/matrix-discount';
import { gungAddRemoveSpinner } from 'gung-common';

@Component({
  selector: 'lib-matrix-discount-modal',
  templateUrl: './matrix-discount-modal.component.html',
  styleUrls: ['./matrix-discount-modal.component.css']
})
export class MatrixDiscountModalComponent implements OnInit {
  form: FormGroup;
  matrixDiscount: MatrixDiscount;
  loading = true;
  saved = false;

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    public matrixDiscountService: MatrixDiscountsService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  protected initForm() {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.matrixDiscount ? this.matrixDiscount.id : ''),
      customerDiscountGroup: this.formBuilder.control(
        this.matrixDiscount ? this.matrixDiscount.customerDiscountGroup : ''
      ),
      productDiscountGroup: this.formBuilder.control(
        this.matrixDiscount ? this.matrixDiscount.productDiscountGroup : ''
      ),
      discountPercent: this.formBuilder.control(this.matrixDiscount ? this.matrixDiscount.discountPercent : ''),
      lowerLimit: this.formBuilder.control(this.matrixDiscount ? this.matrixDiscount.lowerLimit : '')
    });
  }

  onSave({ target }) {
    gungAddRemoveSpinner(target);
    const mDiscount = this.form.getRawValue();
    this.matrixDiscountService.postMatrixDiscount(mDiscount).subscribe(data => {
      gungAddRemoveSpinner(target);
      this.saved = true;
    });
  }
}
