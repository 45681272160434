import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { GungMainMenuService, GungMenu } from '../../../services/gung-main-menu/gung-main-menu.service';
import { NavGroup } from '../../../services/navbar-config/navbar-config.service';
import { AuthService } from '../../../services/auth/auth.service';
import { GungNavbarService } from '../../../services/gung-navbar/gung-navbar.service';
import { User } from '../../../state/auth/types';
import { Location } from '@angular/common';

export interface UserOnMenu {
  name: string;
  inicials: string;
  role: string;
  link?: string;
}

@Component({
  selector: 'lib-gung-main-menu',
  templateUrl: './gung-main-menu.component.html',
  styleUrls: ['./gung-main-menu.component.scss']
})
export class GungMainMenuComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject<void>();

  @Output() menuClickEvent = new EventEmitter<boolean>();

  mainMenu: GungMenu[];
  userInfo: UserOnMenu;

  constructor(
    protected gungMainMenu: GungMainMenuService,
    protected authService: AuthService,
    protected gungNavbar: GungNavbarService,
    protected location: Location
  ) {}

  ngOnInit(): void {
    this.authService
      .getCurrentUser()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(user => {
        if (user) {
          this.userInfo = {
            name: user.name,
            role: this.gungNavbar.getUserRole(user.roles),
            inicials: this.getInitials(user)
          };
        }
      });

    this.gungMainMenu
      .getMenu()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(menu => {
        this.mainMenu = menu;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getInitials(user: User) {
    const getNameInitials = name => {
      const words = name.split(' ');
      if (words.length === 1) {
        return words[0][0].toUpperCase();
      }
      return (words[0][0] + words[words.length - 1][0]).toUpperCase();
    };

    const getEmailInitials = email => {
      return email ? email.slice(0, 2).toUpperCase() : '';
    };

    if (user.name) {
      return getNameInitials(user.name);
    } else if (user.email) {
      return getEmailInitials(user.email);
    }
  }

  menuClick(menu: GungMenu){
    this.menuClickEvent.emit(true);    
    if(menu?.action){
      menu.action();
    }
  }

  parentMenuClick(menu){
    for (const m of this.mainMenu) {
      if(m.open && m !== menu){
        m.open = false;
      }
    }
    menu.open = !menu.open;
  }

  logout() {
    this.location.go('/');
    this.authService.logout();
  }
}
