import { Component, Input } from '@angular/core';
import { OrderPortalListConfigService } from '../../services/order-portal-list-config.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-order-portal-list',
  templateUrl: './order-portal-list.component.html',
  styleUrls: ['./order-portal-list.component.css']
})
export class OrderPortalListComponent {
  constructor(public configService: OrderPortalListConfigService, protected route: ActivatedRoute) {
    configService.setFromDateYearMonthDay(route.snapshot.queryParams.fromDate);
  }
}
