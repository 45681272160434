import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AssortmentTreeListViewComponent } from 'gung-standard';

@Component({
  selector: 'sjoborg-assortment-tree-list-view',
  templateUrl: './sjoborg-assortment-tree-list-view.component.html',
  styleUrls: ['./sjoborg-assortment-tree-list-view.component.scss']
})
export class SjoborgAssortmentTreeListViewComponent extends AssortmentTreeListViewComponent {}
