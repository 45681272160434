import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { DashboardKpiService } from '../../../services/dashboard-kpi.service';

@Component({
  selector: 'lib-dashboard-totals-card',
  templateUrl: './dashboard-totals-card.component.html',
  styleUrls: ['./dashboard-totals-card.component.css']
})
export class DashboardTotalsCardComponent implements OnInit {
  loaded = false;
  totals;
  constructor(protected dashboardKpiService: DashboardKpiService) { }

  ngOnInit(): void {
    this.dashboardKpiService.getDashboardTotals().pipe(first()).subscribe(totals => {
      this.totals = totals;
      this.loaded = true;
    })
  }

}
