import { Component, OnInit } from '@angular/core';
import { CustomerContactCardListComponent, CustomerContact } from 'gung-standard';
import { JeevesModalService } from '../../services/jeeves-modal-service/jeeves-modal.service';

@Component({
  selector: 'gung-jeeves-jeeves-customer-contact-card-list',
  templateUrl: './jeeves-customer-contact-card-list.component.html',
  styleUrls: ['./jeeves-customer-contact-card-list.component.css']
})
export class JeevesCustomerContactCardListComponent extends CustomerContactCardListComponent implements OnInit {
  phone: string;
  mobile: string;
  email: string;

  constructor(protected modalService: JeevesModalService) {
    super();
  }

  ngOnInit() {
    this.phone = this.data.extra.CS1 ? this.data.extra.CS1.comnr || '-' : '-';
    this.mobile = this.data.extra.CS4 ? this.data.extra.CS4.comnr || '-' : '-';
    this.email = this.data.extra.CS8 ? this.data.extra.CS8.comnr || '-' : '-';
  }

  editContact(customerContact: CustomerContact) {
    this.modalService.openEditContactModal(customerContact);
  }
}
