import { Component } from '@angular/core';
import { JeevesSalesCartListComponent } from 'gung-standard-jeeves';
import { AvailabilityService, CartService, GungFlowService, PriceService, ProductService, SelectedCustomerService, GungModalService } from 'gung-standard';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'sjoborg-sales-cart-list',
  templateUrl: './sjoborg-sales-cart-list.component.html',
  styleUrls: ['./sjoborg-sales-cart-list.component.scss']
})
export class SjoborgSalesCartListComponent extends JeevesSalesCartListComponent {
  constructor(
    protected productService: ProductService,
    protected cartService: CartService,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    protected selectedCustomerService: SelectedCustomerService,
    protected dateUtilService: DateUtilService,
    protected flowService: GungFlowService,
    protected gungModalService: GungModalService
  ) {
    super(productService, cartService, priceService, availabilityService, selectedCustomerService, dateUtilService, flowService);
  }

  getPackageSize(item: any): number {
    if (!!item.extra?.ar?.artfsgforp) {
      return parseInt(item.extra.ar.artfsgforp, 10);
    }

    return 0;
  }

  addNewOrderLine(): void {
    this.gungModalService.addRowCheckoutModal().then(
      result => {
        if (result) {
        }
      },
      reason => {}
    );
  }
}
