import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SaveCartModalConfigService } from '../save-cart-modal-config/save-cart-modal-config.service';
import { ActionButton, ActionButtonConfigService } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class SaveCartActionConfigService implements ActionButtonConfigService<any> {
  extra = [
    {
      title: 'SAVE_CART',
      action: () => {
        this.modalService.openSaveCartModal();
      }
    }
  ];

  constructor(protected modalService: SaveCartModalConfigService) {}

  getButtons(): Observable<ActionButton<any>[]> {
    return of(this.extra);
  }
}
