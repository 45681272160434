import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ProductListByIdsComponent, ProductListByIdsConfigService, ProductService } from 'gung-standard';
import { SjoborgProductListByIdsConfigService } from '../../services/sjoborg-product-list-by-ids-config.service';

@Component({
  selector: 'sjoborg-product-list-by-ids-spare-parts',
  template: '<lib-filter-list #productList [configService]="configService"></lib-filter-list>',
  styleUrls: ['./sjoborg-product-list-by-ids-spare-parts.component.scss']
})
export class SjoborgProductListByIdsSparePartsComponent extends ProductListByIdsComponent implements OnChanges {
  @Input()
  tabType: string;


  constructor(public configService: SjoborgProductListByIdsConfigService, protected productService: ProductService) {
    super(configService, productService);
    configService.tabType = this.tabType;
  }
  
  ngOnChanges(){
    this.configService.tabType = this.tabType;
  }

}
