import { Injectable, Input } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption, SimpleConfigBaseFilter } from 'gung-list';
import { MetadataService, SelectedCustomerService } from 'gung-standard';
import { Observable } from 'rxjs';
import { Claim } from '../../models/claim';
import { JeevesClaimService } from '../jeeves-claim-service/jeeves-claim.service';
import { JeevesClaimTableViewComponent } from '../../components/jeeves-claim-table-view/jeeves-claim-table-view.component';


class ClaimStatus extends SimpleConfigBaseFilter<Claim> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'STATUS';
  }
  getOptionIds(item: Claim): string[] {
    const value = this.metadataService.getMetadataValue(
      'claimactivitystatus',
      'claimactivitystatusdescr',
      item.extra.ork.claimactivitystatus
    );
    return [value];
  }
  getOptionName(key: string): string {
    return key;
  }
}

class ClaimKundReklamationBeskr extends SimpleConfigBaseFilter<Claim> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'ORKK_KUNDREKLAMATIONBESKR';
  }
  getOptionIds(item: Claim): string[] {
    const value = this.metadataService.getMetadataValue(
      'orkk',
      'kundreklamationbeskr',
      item.extra.ork.kundreklamationorsak
    );
    return [value];
  }
  getOptionName(key: string): string {
    return key;
  }
}

class ClaimWorkFlowFilter extends SimpleConfigBaseFilter<Claim> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'CLAIMWORKFLOW_CLAIMWORKFLOWDESCR';
  }
  getOptionIds(item: Claim): string[] {
    const value = this.metadataService.getMetadataValue(
      'claimworkflow',
      'claimworkflowdescr',
      item.extra.ork.claimworkflow
    );
    return [value];
  }
  getOptionName(key: string): string {
    return key;
  }
}

class ClaimActivityStatusFilter extends SimpleConfigBaseFilter<Claim> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  getName(): string {
    return 'STATUS';
  }
  getOptionIds(item: Claim): string[] {
    const value = this.metadataService.getMetadataValue(
      'claimactivitystatus',
      'claimactivitystatusdescr',
      item.extra.ork.claimactivitystatus
    );
    return [value];
  }
  getOptionName(key: string): string {
    return key;
  }
}


@Injectable({
  providedIn: 'root'
})
export class JeevesClaimConfigListService implements ConfigService<Claim> {
  @Input()
  public customerId?: string;

  constructor(
    protected jeevesClaimService: JeevesClaimService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService
  ) {}

  getItems(): Observable<Claim[]> {
    if (this.customerId) {
      return this.jeevesClaimService.getClaims(this.customerId);
    }
    return this.jeevesClaimService.getAllClaims();
  }

  getFilters(): ConfigBaseFilter<Claim>[] {
    return [
      new ClaimActivityStatusFilter(this.metadataService),
      new ClaimKundReklamationBeskr(this.metadataService),
      new ClaimWorkFlowFilter(this.metadataService),
      
    ];
  }

  getSortOptions(): ListSortOption<Claim>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<Claim>[] {
    return [
      {
        getIconClass: () => 'fas fa-th-list',
        getListItemComponent: () => JeevesClaimTableViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ClaimTableViewComponent'
      }
    ];
  }

  getSearchTerms(item: Claim): string[] {
    return [item.customerId, item.extra.ork.artnr];
  }

  getItemId(item: Claim): string {
    return item.customerId + '_' + item.extra.ork.artnr;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
