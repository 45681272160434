import { Component, Input, OnInit } from '@angular/core';
import { ActivityHrpListConfigService } from '../../../services/activity-hrp-list-config/activity-hrp-list-config.service';

@Component({
  selector: 'lib-activities-list',
  template: `<lib-filter-list [configService]="activityHrpListConfigService"></lib-filter-list>`,
  styles: []
})
export class ActivitiesListComponent implements OnInit {
  @Input()
  customerId?: string;

  constructor(public activityHrpListConfigService: ActivityHrpListConfigService) {}

  ngOnInit() {
    this.activityHrpListConfigService.customerId = this.customerId;
  }
}
