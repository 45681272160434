import { Injectable } from '@angular/core';
import { Product } from '../../../models';

@Injectable({
  providedIn: 'root'
})
export class ProductConfigurationActiveService {
  constructor() {}

  isActive(product: Product) {
    return !!product.extra?.isProductConfiguration;
  }
}
