import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ProductionManagementStockCountingService,
  StockTaking,
  StockTakingRow,
  StockTakingStatus
} from '../../services/production-management-stock-counting.service';
import { FormGroup, FormControl } from '@angular/forms';
import { first, debounceTime, takeUntil, Subject, tap } from 'rxjs';
import { User } from '../../../../state/auth/types';
import { AvailabilityService } from '../../../../services/availability/availability.service';
import { MetadataService } from '../../../../services/metadata/metadata.service';
import { Product } from '../../../../models';
import { AuthService } from '../../../../services/auth/auth.service';
import { GungNotificationService, OptionsList, gungAddRemoveSpinner } from 'gung-common';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../../../services/products/product.service';

export interface StockLocation {
  stockPointId: string;
  stockLocationId: string;
  stockLocationCode: string;
  stockLocationName: string;
  totalQuantity: number;
  availableQuantity: number;
  remainingOrderedQuantity: number;
}

@Component({
  selector: 'lib-production-management-stock-counting',
  templateUrl: './production-management-stock-counting.component.html',
  styleUrls: ['./production-management-stock-counting.component.css']
})
export class ProductionManagementStockCountingComponent implements OnInit, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject();
  form: FormGroup;
  productStock: { inventory: number; available: number };
  stockLocations: StockLocation[];
  product: Product;
  currentUser: User;
  stockLocationList: OptionsList[] = [];

  disabledSave = true;
  productSearchDisabled = false;

  formatterFunction = (result: Product) => result.id + ' - ' + result.name;
  formatterInputFunction = (result: Product) => result.id;
  getSearchTerms = (product: Product) => [
    product.id,
    product.name,
    product.description,
    product.extra.ArticleNumber,
    product.extra.Description,
    product.extra.EAN
  ];

  selectDefaultStockLocation: boolean = false;

  @Input()
  inputParams: {
    productId: string;
    StockPointCode: string;
    StockPointId: string;
  };

  constructor(
    protected stockCountingService: ProductionManagementStockCountingService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected metadataService: MetadataService,
    protected gungNotificationService: GungNotificationService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected productService: ProductService
  ) {
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(currentUser => {
        this.currentUser = currentUser;
      });
  }

  ngOnInit(): void {
    /* this.stockCountingService.getAllItems().subscribe(items => {
    }); */

    this.initForm();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected initForm(): void {
    this.productSearchDisabled = false;
    this.form = new FormGroup({
      itemNo: new FormControl({ value: '', disabled: false }),
      location: new FormControl({ value: null, disabled: true }),
      stockLocation: new FormControl({ value: null, disabled: true }),
      newQty: new FormControl({ value: null, disabled: true })
    });

    this.form.controls['itemNo'].valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500)).subscribe(value => {
      if (!value || this.form.controls['itemNo'].disabled) {
        return;
      }
      this.form.controls['location'].enable();
      this.form.controls['location'].updateValueAndValidity();
    });

    this.form.controls['location'].valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500)).subscribe(value => {
      this.form.controls['stockLocation'].disable();
      this.form.controls['stockLocation'].updateValueAndValidity();
      this.form.controls['newQty'].disable();
      this.form.controls['newQty'].updateValueAndValidity();
      if (!value || this.form.controls['location'].disabled) {
        return;
      }

      this.getAvailability(this.product.id, value, true);
    });

    this.form.controls['stockLocation'].valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500)).subscribe(value => {
      if (!value || this.form.controls['stockLocation'].disabled) {
        return;
      }
      this.setCurrentProductStock(true);
    });

    this.form.controls['newQty'].valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500)).subscribe(value => {
      if (this.form.controls['newQty'].disabled) {
        return;
      }
      this.disabledSave = !value;
    });

    // this.readQueryParams();
    this.readInputParams();
  }

  readParams(params: { productId: string; StockPointCode: string; StockPointId: string; }) {
    if (params.productId) {
      this.productService.getProduct(params.productId).pipe(first()).subscribe(product => {
        this.selectProduct(product);
        this.form.get('location').setValue(params.StockPointCode);
        // const stockPoint = this.stockLocations.find(sl => sl.stockPointId === queryParams.StockPointId)
        // const stockPoints = this.metadataService.getMetadataTableList('StockPoints') as any[];
        // const stockPoint = stockPoints.find(s => s.code === queryParams.StockPointCode)?.stockLocations?.find(l => l.stockPointId === queryParams.StockPointId);
        this.form.get('stockLocation').setValue(params.StockPointId);
        this.selectDefaultStockLocation = true;
      });
    }
  }

  readQueryParams() {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams.productId) {
      this.readParams({
        productId: queryParams.productId,
        StockPointCode: queryParams.StockPointCode,
        StockPointId: queryParams.StockPointId
      });
  
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: {}
        }
      );
    }
  }

  readInputParams() {

    if (this.inputParams?.productId) {
      this.readParams({
        productId: this.inputParams.productId,
        StockPointCode: this.inputParams.StockPointCode,
        StockPointId: this.inputParams.StockPointId
      });
    }
  }

  selectProduct(product: Product) {
    this.product = product;
    this.form.get('itemNo').setValue(product.id);
  }

  getAvailability(productId: string, stockId: string, enableStockLocation?: boolean) {
    this.availabilityService
      .getAvailability(productId, stockId, true)
      .pipe(first())
      .subscribe(availability => {
        // Fortnox specific
        if (availability.extra.stockLocations) {
          // Temporary solution - Flatten nested array to single array
          var stockLocations = availability.extra.stockLocations;
          if (Array.isArray(availability.extra.stockLocations)) {
            stockLocations = availability.extra.stockLocations.reduce((acc, val) => acc.concat(val), []);
          }

          this.stockLocations = stockLocations;
          this.stockLocationList = stockLocations.map(sl => {
            return {
              id: sl.stockLocationId || '', // If it is blank in Fortnox the key does not exist
              name: sl.stockLocationName || 'UNSPECIFIED'
            };
          });

          if (enableStockLocation) {
            this.form.controls['stockLocation'].enable();
            this.form.controls['stockLocation'].updateValueAndValidity();
            if (this.selectDefaultStockLocation) {
              this.selectDefaultStockLocation = false;
              if (this.stockLocationList.length > 0) {
                this.form.controls['stockLocation'].setValue(this.stockLocationList[0].id);
              }
            }
          } else {
            this.setCurrentProductStock(false);
          }
        }
      });
  }

  setCurrentProductStock(enableNewQty?: boolean) {
    const formRaw = this.form.getRawValue();

    const stockLocationId = formRaw.stockLocation;

    let stockLocation = this.stockLocations.find(sl => sl.stockLocationId === stockLocationId || sl.stockPointId === stockLocationId);
    if (!stockLocationId) {
      stockLocation = this.stockLocations.find(sl => !sl.stockLocationId);
    }

    if (stockLocation) {
      this.productStock = { inventory: stockLocation.totalQuantity, available: stockLocation.availableQuantity };

      if (enableNewQty) {
        this.form.controls['newQty'].enable();
        this.form.controls['newQty'].updateValueAndValidity();
      }
    } else {
      this.gungNotificationService.notify('Stock Counting', 'Stock Location not found', 'error');
    }
  }

  save({ target }) {
    gungAddRemoveSpinner(target);
    const formRaw = this.form.getRawValue();
    this.saveAsObservable().subscribe(res => {
      this.disabledSave = true;
      this.productSearchDisabled = true;
      this.form.controls['location'].disable();
      this.form.controls['stockLocation'].disable();
      this.form.controls['newQty'].disable();
      this.form.controls['location'].updateValueAndValidity();
      this.form.controls['stockLocation'].updateValueAndValidity();
      this.form.controls['newQty'].updateValueAndValidity();
      // TODO - catch exceptions
      this.gungNotificationService.notify('Stock Counting', 'Stock Counting saved', 'success');

      this.getAvailability(formRaw.itemNo, formRaw.location, false);

      gungAddRemoveSpinner(target);
    });
  }

  saveAsObservable() {
    const formRaw = this.form.getRawValue();
    if (this.inputParams) {
      this.form.disable();
    }
    const row: StockTakingRow = {
      countedBy: this.currentUser.username,
      countedQuantity: formRaw.newQty,
      productId: formRaw.itemNo,
      stockPointId: this.resolveStockPointId(formRaw.location),
      stockLocationId: formRaw.stockLocation || undefined
    };
    const stockTaking: StockTaking = {
      date: new Date(),
      rows: [row],
      status: StockTakingStatus.STARTED,
      name: 'Gung Stock Counting',
      responsible: this.currentUser.username
    };
    return this.stockCountingService.createItem(stockTaking);
  }

  newCount(productEle) {
    productEle.model = undefined;
    this.product = undefined;
    this.productStock = undefined;
    this.stockLocationList = [];
    this.form.reset();
    this.form.patchValue({
      itemNo: '',
      newQty: '',
      stockLocation: null,
      location: null
    });
    this.form.controls['stockLocation'].disable();
    this.form.controls['stockLocation'].updateValueAndValidity();
    this.form.controls['newQty'].disable();
    this.form.controls['newQty'].updateValueAndValidity();
    this.form.controls['location'].disable();
    this.form.controls['location'].updateValueAndValidity();
    this.productSearchDisabled = false;
  }

  resolveStockPointId(stockCode: string): string {
    // Fortnox Specific
    return this.metadataService.getMetadataValue('StockPoints', 'id', stockCode);
  }
}
