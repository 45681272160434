import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SjoborgAppComponent } from './sjoborg-app.component';
import {
  GungStandardModule,
  createTranslateLocalLoader,
  TranslationLocalService,
  NavbarConfigService,
  ProductListConfigService,
  ProductPaginationListConfigService,
  CheckoutConfigService,
  BackendInterceptor,
  AvailabilityHelperService,
  AssortmentTreeNavigationConfigService,
  ConceptTreeListConfigService,
  ProductTreeListConfigService,
  OrderListConfigService,
  ProductListByIdsConfigService,
  ConceptDetailProductListConfigService,
  AssortmentTreeListConfigService,
  SalesCartConfigService,
  CartConfigService,
  FavouriteProductListConfigService,
  ActivityInternalListConfigService,
  OpenOrdersConfigService
} from 'gung-standard';
import { GungStandardJeevesModule, JeevesOpenOrdersConfigService } from 'gung-standard-jeeves';
import { environment } from '../environments/environment';
import { GungListModule } from 'gung-list';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SjoborgBackendInterceptorService } from './services/sjoborg-backend-interceptor.service';
import { SjoborgNavbarConfigService } from './services/sjoborg-navbar-config.service';
import { MatTreeModule } from '@angular/material/tree';
import { SjoborgProductPaginationListConfigService } from './services/sjoborg-product-pagnation-list-config.service';
import { ProductPaginationListTableComponent } from './components/product-pagination-list-table/product-pagination-list-table.component';
import { SjoborgConceptGridViewComponent } from './components/sjoborg-concept-grid-view/sjoborg-concept-grid-view.component';
import { SjoborgCustomPageDisplayComponent } from './components/sjoborg-custom-page-display/sjoborg-custom-page-display.component';
import { SjoborgProductDownloadPdfComponent } from './components/sjoborg-product-download-pdf/sjoborg-product-download-pdf.component';
import { SjoborgJeevesOrderDetailsComponent } from './components/sjoborg-jeeves-order-details/sjoborg-jeeves-order-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SjoborgConceptTableViewComponent } from './components/sjoborg-concept-table-view/sjoborg-concept-table-view.component';
import { SjoborgTranslationLocalService } from './services/sjoborg-translation-local.service';
import { SjoborgDeliveryLocationsJeevesComponent } from './components/checkout/sjoborg-delivery-locations-jeeves/sjoborg-delivery-locations-jeeves.component';
import { SjoborgCheckoutConfigService } from './services/sjoborg-checkout-config.service';
import { SjoborgButtonsComponent } from './components/checkout/sjoborg-buttons/sjoborg-buttons.component';
import { SjoborgSummarizeOrderJeevesComponent } from './components/checkout/sjoborg-summarize-order-jeeves/sjoborg-summarize-order-jeeves.component';
import { SjoborgCheckoutWrapperComponent } from './components/checkout/sjoborg-checkout-wrapper/sjoborg-checkout-wrapper.component';
import { SjoborgAssortmentTreeNavigationConfigService } from './services/sjoborg-assortment-tree-navigation-config.service';
import { SjoborgConceptTreeListConfigService } from './services/sjoborg-concept-tree-list-config.service';
import { SjoborgProductDetailsComponent } from './components/sjoborg-product-details/sjoborg-product-details.component';
import { SjoborgProductTreeListConfigService } from './services/sjoborg-product-tree-list-config.service';
import { SjoborgProductTreeListViewComponent } from './components/sjoborg-product-tree-list-view/sjoborg-product-tree-list-view.component';
import { SjoborgOrderListConfigService } from './services/sjoborg-order-list-config.service';
import { SjoborgAvailabilityHelperService } from './services/sjoborg-availability-helper.service';
import { SjoborgHomeAssortmentComponent } from './components/sjoborg-home-assortment/sjoborg-home-assortment.component';
import { SjoborgProductListByIdsConfigService } from './services/sjoborg-product-list-by-ids-config.service';
import { SjoborgConceptDetailProductListConfigService } from './services/sjoborg-concept-detail-product-list-config.service';
import { SjoborgConceptDetailProductListViewComponent } from './components/sjoborg-concept-detail-product-list-view/sjoborg-concept-detail-product-list-view.component';
import { SjoborgOrderTermsJeevesComponent } from './components/checkout/sjoborg-order-terms-jeeves/sjoborg-order-terms-jeeves.component';
import { SjoborgOrderCardListComponent } from './components/sjoborg-order-card-list/sjoborg-order-card-list.component';
import { SjoborgsDeliveryAddressStepComponent } from './components/sjoborgs-delivery-address-step/sjoborgs-delivery-address-step.component';
import { SjoborgRequestAccountComponent } from './components/sjoborg-request-account/sjoborg-request-account.component';
import { SjoborgLoginWrapperComponent } from './components/sjoborg-login-wrapper/sjoborg-login-wrapper.component';
import { SjoborgFashionLoginComponent } from './components/sjoborg-fashion-login/sjoborg-fashion-login.component';
import { SjoborgCustomerDetailsComponent } from './components/sjoborg-customer-details/sjoborg-customer-details.component';
import { SjoborgAssortmentTreeListConfigService } from './services/sjoborg-assortment-tree-list-config.service';
import { SjoborgAssortmentTreeListViewComponent } from './components/sjoborg-assortment-tree-list-view/sjoborg-assortment-tree-list-view.component';
import { SjoborgAssortmentTreeNavigationComponent } from './components/sjoborg-assortment-tree-navigation/sjoborg-assortment-tree-navigation.component';
import { SjoborgBuyBtnComponent } from './components/sjoborg-buy-btn/sjoborg-buy-btn.component';
import { SjoborgSalesCartListComponent } from './components/checkout/sjoborg-sales-cart-list/sjoborg-sales-cart-list.component';
import { SjoborgSalesCartConfigService } from './services/sjoborg-sales-cart-config.service';
import { SjoborgCartConfigService } from './services/sjoborg-cart-config.service';
import { SjoborgCartListComponent } from './components/checkout/sjoborg-cart-list/sjoborg-cart-list.component';
import { SjoborgAppWrapperComponent } from './components/sjoborg-app-wrapper/sjoborg-app-wrapper.component';
import { SjoborgNavbarComponent } from './components/sjoborg-navbar/sjoborg-navbar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SjoborgProductGridViewComponent } from './components/sjoborg-product-grid-view/sjoborg-product-grid-view.component';
import { SjoborgProductListConfigService } from './services/sjoborg-product-list-config.service';
import { SjoborgFavouriteProductComponent } from './components/sjoborg-favourite-product/sjoborg-favourite-product.component';
import { DashboardCardService } from 'gung-dashboard';
import { SjoborgDashboardCardService } from './services/dashboard/sjoborg-dashboard-card.service';
import { SjoborgSalespersonSelectComponent } from './components/dashboard/sjoborg-salesperson-select/sjoborg-salesperson-select.component';
import { SjoborgJeevesCustomerContactCardListComponent } from './components/sjoborg-jeeves-customer-contact-card-list/sjoborg-jeeves-customer-contact-card-list.component';
import { SjoborgJeevesCustomerContactListConfigService } from './services/sjoborg-jeeves-customer-contact-list-config.service';
import { CustomerContactListConfigService } from 'gung-standard';
import { GungCommonModule } from 'gung-common';
import { SjoborgFavouriteProductListConfigService } from './services/sjoborg-favourite-product-list-config.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SjoborgActivityInternalTableComponent } from './components/sjoborg-activity-internal-table/sjoborg-activity-internal-table.component';
import { SjoborgActivityInternalListConfigService } from './services/sjoborg-activity-internal-list-config.service';
import { SjoborgProductListByIdsSparePartsComponent } from './components/sjoborg-product-list-by-ids-spare-parts/sjoborg-product-list-by-ids-spare-parts.component';

@NgModule({ declarations: [
        SjoborgAppComponent,
        ProductPaginationListTableComponent,
        SjoborgConceptGridViewComponent,
        SjoborgCustomPageDisplayComponent,
        SjoborgProductDownloadPdfComponent,
        SjoborgJeevesOrderDetailsComponent,
        SjoborgConceptTableViewComponent,
        SjoborgDeliveryLocationsJeevesComponent,
        SjoborgButtonsComponent,
        SjoborgSummarizeOrderJeevesComponent,
        SjoborgCheckoutWrapperComponent,
        SjoborgProductDetailsComponent,
        SjoborgProductTreeListViewComponent,
        SjoborgHomeAssortmentComponent,
        SjoborgConceptDetailProductListViewComponent,
        SjoborgOrderTermsJeevesComponent,
        SjoborgOrderCardListComponent,
        SjoborgsDeliveryAddressStepComponent,
        SjoborgRequestAccountComponent,
        SjoborgLoginWrapperComponent,
        SjoborgFashionLoginComponent,
        SjoborgCustomerDetailsComponent,
        SjoborgAssortmentTreeListViewComponent,
        SjoborgAssortmentTreeNavigationComponent,
        SjoborgBuyBtnComponent,
        SjoborgSalesCartListComponent,
        SjoborgCartListComponent,
        SjoborgAppWrapperComponent,
        SjoborgNavbarComponent,
        SjoborgProductGridViewComponent,
        SjoborgFavouriteProductComponent,
        SjoborgSalespersonSelectComponent,
        SjoborgJeevesCustomerContactCardListComponent,
        SjoborgActivityInternalTableComponent,
        SjoborgProductListByIdsSparePartsComponent
    ],
    bootstrap: [SjoborgAppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        GungStandardModule,
        GungStandardJeevesModule,
        GungCommonModule,
        GungListModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        MatTreeModule,
        MatSidenavModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLocalLoader,
                deps: [TranslationLocalService]
            }
        })], providers: [
        { provide: 'environment', useValue: environment },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SjoborgBackendInterceptorService,
            multi: true
        },
        {
            provide: BackendInterceptor,
            useClass: SjoborgBackendInterceptorService
        },
        {
            provide: DashboardCardService,
            useClass: SjoborgDashboardCardService
        },
        {
            provide: TranslationLocalService,
            useClass: SjoborgTranslationLocalService
        },
        {
            provide: NavbarConfigService,
            useClass: SjoborgNavbarConfigService
        },
        {
            provide: ProductPaginationListConfigService,
            useClass: SjoborgProductPaginationListConfigService
        },
        {
            provide: CheckoutConfigService,
            useClass: SjoborgCheckoutConfigService
        },
        {
            provide: AssortmentTreeNavigationConfigService,
            useClass: SjoborgAssortmentTreeNavigationConfigService
        },
        {
            provide: ConceptTreeListConfigService,
            useClass: SjoborgConceptTreeListConfigService
        },
        {
            provide: ProductTreeListConfigService,
            useClass: SjoborgProductTreeListConfigService
        },
        {
            provide: OrderListConfigService,
            useClass: SjoborgOrderListConfigService
        },
        {
            provide: AvailabilityHelperService,
            useClass: SjoborgAvailabilityHelperService
        },
        {
            provide: ProductListByIdsConfigService,
            useClass: SjoborgProductListByIdsConfigService
        },
        {
            provide: ConceptDetailProductListConfigService,
            useClass: SjoborgConceptDetailProductListConfigService
        },
        {
            provide: AssortmentTreeListConfigService,
            useClass: SjoborgAssortmentTreeListConfigService
        },
        {
            provide: SalesCartConfigService,
            useClass: SjoborgSalesCartConfigService
        },
        {
            provide: CartConfigService,
            useClass: SjoborgCartConfigService
        },
        {
            provide: ProductListConfigService,
            useClass: SjoborgProductListConfigService
        },
        {
            provide: CustomerContactListConfigService,
            useClass: SjoborgJeevesCustomerContactListConfigService
        },
        {
            provide: FavouriteProductListConfigService,
            useClass: SjoborgFavouriteProductListConfigService
        },
        {
            provide: ActivityInternalListConfigService,
            useClass: SjoborgActivityInternalListConfigService
        },
        {
            provide: OpenOrdersConfigService,
            useClass: JeevesOpenOrdersConfigService
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
