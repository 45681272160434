<h3 translate class="page-title text-center">PIM_EXPORT_TEMPLATES</h3>
<button *ngIf="enableCreateTemplate" class="btn btn-primary" (click)="openCreatePIMTemplateModal()" translate>
  CREATE_PIM_EXPORT_TEMPLATE
</button>

<div *ngIf="templatesList != null; else loading">
  <div class="row mt-2 mb-2">
    <div class="col">
      <div
        *ngIf="alertMessage"
        class="alert mt-2 text-center"
        role="alert"
        [ngClass]="{ 'alert-success': alertMessage.type === 'success', 'alert-danger': alertMessage.type === 'danger' }"
        (click)="alertMessage = null"
      >
        <span>{{ alertMessage.message | translate }}</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <mat-toolbar color="primary">
        <mat-toolbar-row
          *ngFor="let option of templatesList; index as i"
          class="template-menu border border-primary pointer"
          (click)="setTemplate(option.id)"
          [class.active]="option.id === templateId"
          [class.btn-primary]="option.id === templateId"
        >
          <span >{{ (option.name || option.id) | translate }}</span>
          <span class="side-nav-spacer"></span>
          <i
            *ngIf="editedTemplatesId.includes(option.id)"
            class="fas fa-exclamation-triangle"
            title="{{ 'UNSAVED_CHANGES' | translate }}"
          ></i>
        </mat-toolbar-row>
      </mat-toolbar>
      <button
        *ngIf="templateId"
        type="button"
        class="btn btn-outline-secondary w-100 mt-3"
        (click)="openEditPIMTemplateModal()"
        [disabled]="loader ? true : null"
      >
        <span translate>EDIT_SELECTED_TEMPLATE</span>
      </button>
    </div>
    <div class="col-9">
      <div class="row mt-2 mb-2">
        <div class="col-9">
          <lib-filter-list-search (searchUpdated)="setSearch($event)"></lib-filter-list-search>
        </div>
        <div class="col-3">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary float-right"
              (click)="onSaveTemplate()"
              [disabled]="loader ? true : null"
            >
              <span translate>SAVE</span><i *ngIf="loader" class="fas fa-spinner fa-spin ml-2"></i>
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary float-right"
              (click)="addRow()"
              [disabled]="loader ? true : null"
            >
              <span translate>ADD_ROW</span>
            </button>
          </div>
        </div>
      </div>
      <div class="row no-border-table">
        <div class="col-12">
          <table *ngIf="filteredColumns" class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col" class="text-center" id="idRequired"><span translate>REQUIRED</span></th>
                <th scope="col" class="text-center" id="idName"><span translate>NAME</span></th>
                <th scope="col" class="text-center" id="idPath"><span translate>PATH</span></th>
                <th scope="col" class="text-center" id="idEdit"><span translate>TOOLS</span></th>
                <th scope="col" class="text-center" id="idMove"><span translate>MOVE</span></th>
              </tr>
            </thead>
            <tbody cdkDropList class="table-list" (cdkDropListDropped)="drop($event)">
              <tr class="row-list" *ngFor="let column of filteredColumns; index as i" cdkDrag>
                <td colspan="5" class="p-0" *cdkDragPlaceholder>
                  <div class="row-ist-placeholder"></div>
                </td>
                <td class="text-center" headers="idRequired" width="10%">
                  <input type="checkbox" [(ngModel)]="column.required" [disabled]="loader ? true : null" />
                </td>
                <td class="text-center" headers="idName" width="50%">
                  <lib-gung-text-input
                    class="w-100 text-center"
                    [gungTranslate]="'NAME'"
                    [(ngModel)]="column.columnName"
                    [renderStatic]="loader ? true : null"
                    [suppressLabel]="true"
                    (change)="unsaved(selectedTemplate.id)"
                  >
                  </lib-gung-text-input>
                </td>
                <td class="text-center" headers="idPath" width="50%">
                  <lib-gung-text-input
                    class="w-100 text-center"
                    [gungTranslate]="'PATH'"
                    [(ngModel)]="column.path"
                    [renderStatic]="loader ? true : null"
                    [suppressLabel]="true"
                    (change)="unsaved(selectedTemplate.id)"
                  >
                  </lib-gung-text-input>
                </td>
                <td class="text-center" headers="idEdit" width="20%">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-outline-primary"
                      name="Edit"
                      (click)="clickEdit(i)"
                      [disabled]="loader ? true : null"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-primary"
                      name="Clone"
                      (click)="clickClone(i)"
                      [disabled]="loader ? true : null"
                    >
                      <i class="fas fa-clone"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      name="Remove"
                      (click)="clickRemove(i)"
                      [disabled]="loader ? true : null"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </td>
                <td
                  class="text-center handle-list"
                  headers="idMove"
                  width="10%"
                  cdkDragHandle
                  [cdkDragHandleDisabled]="columns.length !== filteredColumns.length || loader"
                  [class.cdk-drag-handle-disabled]="columns.length !== filteredColumns.length || loader"
                >
                  <i class="fas fa-arrows-alt"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
