import {Component} from '@angular/core';
import {AbstractSelectionService} from 'gung-list';
import {EdiOrderListConfigService} from '../../../services/edi-order-list-config/edi-order-list-config.service';
import {ProductSelectionService} from '../../../services/product-selection-service/product-selection.service';
import {
  EdiPurchasedOrderListConfigService
} from "../../../services/edi-purchased-order-list-config/edi-purchased-order-list-config.service";
@Component({
  selector: 'lib-edi-list-purchased-orders',
  template: `<h3 class="d-block page-title text-center" translate>EDI - Purchased Orders</h3>
  <lib-filter-list [configService]="ediPurchasedOrderListConfigService"></lib-filter-list>`,
  styleUrls: ['./edi-list-purchased-orders.component.css'],
  providers: [
    {
      provide: AbstractSelectionService,
      useClass: ProductSelectionService
    }
  ]
})
export class EdiListPurchasedOrdersComponent {
  constructor(public ediPurchasedOrderListConfigService: EdiPurchasedOrderListConfigService) {
  }
}
