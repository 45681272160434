import { Component } from '@angular/core';
import { BulkPlanComponent } from '../bulk-plan/bulk-plan.component';
import { CartCalendarRow } from '../../../models/bulk-orders';
import { Cart } from '../../../models/cart';

@Component({
  selector: 'lib-select-saved-cart-modal',
  templateUrl: './select-saved-cart-modal.component.html',
  styleUrls: ['./select-saved-cart-modal.component.css']
})
export class SelectSavedCartModalComponent {
  cell: CartCalendarRow;
  savedCarts: Cart[];
  delegate: BulkPlanComponent;
  selectedCartId: string;

  constructor() { }

  close() {
    this.delegate.modalRef.get(SelectSavedCartModalComponent.name).close('Closed');
  }

  select(){
    if(!this.selectedCartId){
      return;
    }
    const cart = this.savedCarts.find(s=>s.id === this.selectedCartId);
    this.cell.carts.push(cart);
    this.delegate.modalRef.get(SelectSavedCartModalComponent.name).close('')
  }
}
