import { Injectable } from '@angular/core';
import { CartTotalsService, CartRow, CustomerProductPrice, TotalObject } from 'gung-standard';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JeevesCartTotalsService extends CartTotalsService {
  mapData(rows: CartRow[], prices: CustomerProductPrice[]): Observable<TotalObject> {
    const totalRows = rows.length;

    const anyPrice = prices.find(_ => true);
    const currency = anyPrice ? anyPrice.customerNetPrice.currencyCode : '';

    const rowMap = rows.map(r => {
      const price = prices.find(p => p.productId === r.productId);
      const basePrice = price.customerNetPrice.value;
      const discountPercent = (r.extra && r.extra.orp && r.extra.orp.rabatt1) || 0;
      const overridePrice = (r.extra && r.extra.orp && r.extra.orp.vb_pris) || undefined;

      const rowPPU = (overridePrice || basePrice) * (100 - discountPercent) * 0.01;
      const totalRowPrice = rowPPU * r.qty;
      return {
        qty: r.qty,
        unitPrice: rowPPU,
        totalPrice: totalRowPrice
      };
    });
    const toReturn = {
      ...rowMap.reduce(
        (acc, curr) => ({
          totalQuantity: acc.totalQuantity + curr.qty,
          totalValue: acc.totalValue + curr.totalPrice
        }),
        {
          totalQuantity: 0,
          totalValue: 0
        }
      ),
      totalRows,
      totalCurrency: currency
    };
    return of(toReturn);
  }
}
