import { Injectable } from '@angular/core';
import { DateUtilService } from 'gung-common';
import { FilterListDateUtilService } from 'gung-list';

@Injectable({
  providedIn: 'root'
})
export class GungStandardFilterListDateUtilService extends FilterListDateUtilService {
  constructor(protected dateUtilService: DateUtilService) {
    super();
  }
  
  /**
 * Returns day of week, depending on passed parameters on which day the week starts
 * @param date variable of type Date which will be tests for the week day
 * @param firstWeekDay variable of type String which decides which day will be the first day of the week
 * @returns One of these numbers: 0 | 1 | 2 | 3 | 4 | 5 | 6
 */
  public getGungWeekDay(date: Date, firstWeekDay: string): number {
    return this.dateUtilService.getGungWeekDay(date, firstWeekDay);
  }

  /**
 * Returns first day of week number, depending on environment variable "gungCalendarFirstWeekday"
 * @param useISO8601 variable of type Boolean which decides which type of return the function will give back
 * @returns One of these numbers: Default: [0 | 1 | 2 | 3 | 4 | 5 | 6] || ISO8601: [1 | 2 | 3 | 4 | 5 | 6 | 7]
 */
  public getFirstDayOfWeekRealNumber(useISO8601: boolean = false): number {
    return this.dateUtilService.getFirstDayOfWeekRealNumber(useISO8601);
  }
}
