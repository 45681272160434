import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService, GungBase } from 'gung-standard';

@Component({
  selector: 'gung-jeeves-jeeves-customer-delivery-location-modal',
  templateUrl: './jeeves-customer-delivery-location-modal.component.html',
  styleUrls: ['./jeeves-customer-delivery-location-modal.component.css']
})
export class JeevesCustomerDeliveryLocationModalComponent implements OnInit {
  @Input()
  public deliveryLocation: GungBase;

  title = 'DELIVERY_LOCATION_DETAILS';
  form: FormGroup;
  disabled = false;

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected customerService: CustomerService
  ) {}

  ngOnInit() {
    if (this.deliveryLocation && this.deliveryLocation.id) {
      this.title = 'EDIT_DELIVERY_LOCATION_DETAILS';
    } else {
      this.title = 'ADD_DELIVERY_LOCATION_DETAILS';
    }
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      ftgnr: this.formBuilder.control(this.deliveryLocation.extra.fr.ftgnr || ''),
      ftgnamn: this.formBuilder.control(this.deliveryLocation.extra.fr.ftgnamn || ''),
      ftgpostadr1: this.formBuilder.control(this.deliveryLocation.extra.fr.ftgpostadr1 || ''),
      ftgpostadr2: this.formBuilder.control(this.deliveryLocation.extra.fr.ftgpostadr2 || ''),
      ftgpostnr: this.formBuilder.control(this.deliveryLocation.extra.fr.ftgpostnr || ''),
      ftgpostadr3: this.formBuilder.control(this.deliveryLocation.extra.fr.ftgpostadr3 || ''),
      landskod: this.formBuilder.control(this.deliveryLocation.extra.fr.landskod || '')
    });
  }

  submitForm() {
    if (this.form.invalid) {
      return;
    }

    this.disabled = true;

    if (this.deliveryLocation && this.deliveryLocation.id) {
      this.editDeliveryLocation();
    } else {
      this.createDeliveryLocation();
    }

    // const updateContact = JSON.parse(JSON.stringify(this.customerContact));
    // updateContact.name = this.contactForm.get('name').value;

    // Object.keys(this.contactForm.value).forEach(key => {
    //   if (this.contactForm.value[key].comnr !== '') {
    //     updateContact.extra[key] = {
    //       ...updateContact.extra[key],
    //       ...this.contactForm.value[key]
    //     };
    //   }
    // });

    // this.customerContactService.addCustomerContact(this.customerContact.customerId, updateContact)
    //   .subscribe(() => {
    //     this.disabled = false;
    //     this.customerContactService.updateCustomerContactsSubject(this.customerContact.customerId);
    //     this.activeModal.close('Add Contact procedure completed.');
    //   });
  }

  editDeliveryLocation() {
    const customerId = this.deliveryLocation.extra.lp.ftgnr;

    const deliveryLocation = {
      id: this.deliveryLocation.id,
      extra: {
        fr: {
          ...this.deliveryLocation.extra.fr,
          ...this.form.getRawValue()
        }
      }
    };

    this.customerService.updateCustomerDeliveryLocations(customerId, deliveryLocation as any).subscribe(result => {
      this.activeModal.close(result);
    });
  }

  createDeliveryLocation() {
    const customerId = this.deliveryLocation.extra.lp.ftgnr;

    const deliveryLocation = {
      id: customerId + '_createNew',
      extra: {
        fr: {
          ...this.form.getRawValue(),
          ftgnr: undefined
        }
      }
    };
    if (deliveryLocation.extra.fr.hasOwnProperty('ftgnr')) {
      delete deliveryLocation.extra.fr.ftgnr;
    }

    this.customerService.updateCustomerDeliveryLocations(customerId, deliveryLocation as any).subscribe(result => {
      this.activeModal.close(result);
    });
  }
}
