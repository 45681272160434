<div *ngIf="isOnlyDisplayFlow; else buyButton">
  <div class="input-group">
    <button class="btn btn-block btn-sm btn-primary" tabindex="-1" [routerLink]="[goToDetailUrl()]" translate>
      DETAILS
    </button>
  </div>
</div>

<ng-template #buyButton>
  <div class="input-group" *ngIf="currentQty > 0; else btn">
    <div class="input-group-prepend">
      <button
        class="btn btn-primary btn-sm"
        tabindex="-1"
        (click)="sub(); $event.preventDefault(); $event.stopPropagation()"
      >
        <i class="fas fa-minus"></i>
      </button>
    </div>
    <input
      type="number"
      class="form-control form-control-sm text-center"
      [(ngModel)]="currentQty"
      (ngModelChange)="set($event)"
      (click)="$event.preventDefault(); $event.stopPropagation()"
      [ngModelOptions]="{ updateOn: 'blur' }"
      ngbTooltip="{{ 'QUANTITY_ADJUSTED_UP_CLOSET_PACKAGE_SIZE' | translate }}"
      #tooltip="ngbTooltip"
      triggers="manual"
    />
    <div class="input-group-append">
      <button
        class="btn btn-sm btn-primary"
        tabindex="-1"
        (click)="add(); $event.preventDefault(); $event.stopPropagation()"
      >
        <i class="fas fa-plus"></i>
      </button>
    </div>
  </div>

  <ng-template #btn>
    <div class="input-group">
      <button
        class="btn btn-block btn-sm btn-primary"
        tabindex="-1"
        (click)="add(); $event.preventDefault(); $event.stopPropagation()"
        translate
      >
        ADD_TO_CART
      </button>
    </div>
  </ng-template>
</ng-template>
