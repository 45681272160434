import { Component, OnInit } from '@angular/core';
import { SupplierListConfigService } from '../../../services/supplier-list-config/supplier-list-config.service';

@Component({
  selector: 'lib-supplier-wrapper',
  template: `
    <div class="container">
      <div class="row pb-4">
        <div class="col-12 d-flex justify-content-center">
          <h3 translate class="page-title text-center position-absolute">SUPPLIERS</h3>
          <!--
          <div class="ml-auto">
            <button class="btn btn-outline-primary" (click)="openCreateCustomer()" translate>CREATE_CUSTOMER</button>
          </div>
          -->
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <lib-filter-list [configService]="customerListConfigService"></lib-filter-list>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class SupplierWrapperComponent implements OnInit {

  constructor(
    public customerListConfigService: SupplierListConfigService
  ) {}

  ngOnInit(): void {
  }

}
