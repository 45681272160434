import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  ProductConfiguration,
  ProductConfigurationFeature,
  ProductConfigurationOption
} from '../models/product-configuration-types';

@Injectable({
  providedIn: 'root'
})
export class ProductConfigurationService {
  constructor(protected http: HttpClient) {}

  getOne(id: string): Observable<ProductConfiguration> {
    const url = `json/product-configurations/${id}`;
    return this.http.get<ProductConfiguration>(url);
  }

  getFeatures(ids: string[]): Observable<ProductConfigurationFeature[]> {
    const url = `json/product-configuration/features/by-ids?ids=${ids.join(',')}`;
    return this.http.get<ProductConfigurationFeature[]>(url);
  }

  getOptions(ids: string[]): Observable<ProductConfigurationOption[]> {
    const url = `json/product-configuration/options/by-ids?ids=${ids.join(',')}`;
    return this.http.get<ProductConfigurationOption[]>(url);
  }
}
