import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, CustomerDetailsComponent, CustomerService, NavbarConfigService, NavigationConfig } from 'gung-standard';
import { JeevesCustomerActionConfigService } from '../../services/jeeves-customer-action-config/jeeves-customer-action-config.service';

@Component({
  selector: 'gung-jeeves-jeeves-customer-details-wrapper',
  templateUrl: './jeeves-customer-details-wrapper.component.html',
  styleUrls: ['./jeeves-customer-details-wrapper.component.css']
})
export class JeevesCustomerDetailsWrapperComponent extends CustomerDetailsComponent implements OnInit {
  constructor(
    route: ActivatedRoute,
    customerService: CustomerService,
    protected authService: AuthService,
    protected navbarConfigService: NavbarConfigService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig,
    public configService: JeevesCustomerActionConfigService
  ) {
    super(route, customerService, authService, navbarConfigService, environment);
    super(route, customerService, authService, navbarConfigService, environment);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
