import { Component, OnInit } from '@angular/core';
import { CartRow, CartRowPrice, Order, OrderService, PriceService, AuthService, Product } from 'gung-standard';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { first } from 'rxjs';

import { User } from 'gung-standard/lib/state/auth/types';
import { JeevesOrderService } from '../../services/jeeves-order.service';

@Component({
  selector: 'gung-jeeves-jeeves-add-order-row-modal',
  templateUrl: './jeeves-add-order-row-modal.component.html',
  styleUrls: ['./jeeves-add-order-row-modal.component.css']
})
export class JeevesAddOrderRowModalComponent implements OnInit {
  editRows: CartRow[] = [];
  prices: CartRowPrice[] = [];
  user: User;
  submitingRowsWaiting = false;

  /**
   * added as modalref data from order details component
   */
  order: Order;

  constructor(
    public activeModal: NgbActiveModal,
    protected orderService: JeevesOrderService,
    protected priceService: PriceService,
    protected router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => (this.user = user));
  }

  selectProduct(product: Product, row: CartRow, rowIndex: number) {
    row.productId = product.id;
    if (product.extra.ar?.artfsgforp) {
      row.qty = product.extra.ar.artfsgforp;
    }
    const orderCustomer = this.order.deliveryCustomerId;

    this.priceService.getCustomerPrice(product.id, orderCustomer).subscribe(price => {
      this.prices[rowIndex] = this.priceService.getCartRowPrice(price, row);
    });
  }

  addNewRow() {
    this.editRows.push({
      productId: '',
      extra: { orp: {} },
      targetStockId: this.user.managedMultistockIds[0],
      timeAdded: new Date(),
      timeUpdated: new Date(),
      qty: 1
    });
  }

  removeRow(index: number) {
    this.editRows.splice(index, 1);
    this.prices.splice(index, 1);
  }

  submitRows() {
    const copiedOrder = JSON.parse(JSON.stringify(this.order)) as any;
    const submitRows = this.editRows
      .filter(row => !!row.productId)
      .map(row => {
        const submitRow = {
          id: row.productId,
          quantity: row.qty,
          extra: row.extra
        };
        if (row.targetStockId) {
          submitRow.extra.orp = submitRow.extra.orp || {};
          submitRow.extra.orp.lagstalle = row.targetStockId;
        }
        return submitRow;
      });

    copiedOrder.rows = submitRows;
    this.submitingRowsWaiting = true;
    this.orderService.postAppendToOrder(copiedOrder).subscribe(orders => {
      this.submitingRowsWaiting = false;
      this.activeModal.dismiss();
      this.router.navigate([`/orders/${orders[0].id}`]);
      window.location.reload();
    });
  }
}
