@if(curentGungFlow.productConditions){
  @for(productConditions of flowProductConditions; track productConditions; let index = $index){
    <div class="card border rounded mb-2">
      <div class="card-header">
        <span translate>CONDITION</span> {{ index + 1 }}
        <button
          type="button"
          class="btn btn-danger btn-sm float-right"
          title="{{ 'DELETE' | translate }}"
          (click)="delete(index)"
        >
          <i class="fa-solid fa-trash-can"></i>
        </button>
      </div>
      <div class="card-body">
        @for(setting of productConditions; track setting;let settingIndex = $index){
          <div class="row form-group">
            <div class="col-auto pt-3">
              <label class="d-flex align-items-center">
                <input
                  type="radio" name="{{setting.label}}_{{index}}_{{settingIndex}}_key" id="{{setting.label}}_{{index}}_{{settingIndex}}_$in" value="_$in"
                  [attr.checked]="setting.entry.key == '_$in' ? 'checked' : undefined"
                  (change)="keyChange($event.target.value, setting, index)"
                />
                <span translate>INCLUDE</span>
              </label>
              <label class="d-flex align-items-center">
                <input
                  type="radio" name="{{setting.label}}_{{index}}_{{settingIndex}}_key" id="{{setting.label}}_{{index}}_{{settingIndex}}_$nin" value="_$nin"
                  [attr.checked]="setting.entry.key == '_$nin' ? 'checked' : undefined"
                  (change)="keyChange($event.target.value, setting, index)"
                />
                <span translate>EXCLUDE</span>
              </label>
            </div>
            <div class="col flex-grow-1">
              <label for="{{ setting.label }}" class="control-label" translate>{{ setting.label }}</label>
              <input
                class="form-control"
                type="text"
                name="{{ setting.label }}"
                id="{{ setting.label }}"
                [value]="setting.entry.value"
                (blur)="setValue($event.target.value, setting, index)"
              />
            </div>
          </div>
          @if(settingIndex < (productConditions.length - 1)){
            <div class="separator text-center">
              <span class="separator-text pl-5 pr-5" translate>AND</span>
              <div class="line"></div>
            </div>
          }
          
        }
      </div>
    </div>
    @if(index < (flowProductConditions.length - 1)){
      <div class="separator text-center my-2">
        <span class="separator-text pl-5 pr-5" translate>OR</span>
        <div class="line"></div>
      </div>
    }
  }
  
}
<gung-button
	classes="btn-primary w-100"
	label="{{ 'ADD_CONDITION' | translate }}"
	[onClick]="add.bind(this)"
/>

<!-- <pre class="bg-light border mt-2 rounded p-2">{{ curentGungFlow.productConditions | json }}</pre> -->
