import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { ReplenishmentProductExpanded } from '../models/replenishment';
import { first, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { ReplenishmentProductService } from './replenishment-product.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { keyBy } from 'lodash-es';
import { ReplenishmentProductViewComponent } from '../components/replenishment-product-view/replenishment-product-view.component';
import { ReplenishmentProductStatusService } from './replenishment-product-status.service';
import { AvailabilityService } from '../../../services/availability/availability.service';
import { ProductService } from '../../../services/products/product.service';

export class IsActiveFilter extends SimpleConfigBaseFilter<ReplenishmentProductExpanded> {
  getName(): string {
    return 'ACTIVE';
  }

  getOptionIds(item: ReplenishmentProductExpanded): string[] {
    return [item.active ? 'YES' : 'NO'];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class StatusFilter extends SimpleConfigBaseFilter<ReplenishmentProductExpanded> {
  getName(): string {
    return 'STATUS';
  }

  getOptionIds(item: ReplenishmentProductExpanded): string[] {
    return [item.status];
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReplenishmentProductListConfigService implements ConfigService<ReplenishmentProductExpanded> {
  topFilter = true;

  constructor(
    protected replenishmentProductService: ReplenishmentProductService,
    protected replenishmentProductStatusService: ReplenishmentProductStatusService,
    protected availabilityService: AvailabilityService,
    protected productService: ProductService
  ) {}

  getFilters(): ConfigBaseFilter<ReplenishmentProductExpanded>[] {
    return [new IsActiveFilter(), new StatusFilter()];
  }

  getItems(route?: ActivatedRouteSnapshot): Observable<ReplenishmentProductExpanded[]> {
    const customerId = route.paramMap.get('customerId');
    return this.replenishmentProductService.getAllForCustomer(customerId).pipe(
      first(),
      switchMap(products =>
        forkJoin([
          of(products),
          this.availabilityService.getAvailabilities(products.map(p => p.productId)).pipe(first()),
          this.productService.getProductsByIdsUnfiltered(products.map(p => p.productId)).pipe(first())
        ])
      ),
      map(([products, availabilities, productData]) => {
        const keyedAvailability = keyBy(availabilities, 'productId');
        const keyedProducts = keyBy(productData, 'id');

        return products.map(p => {
          return {
            ...p,
            availability: keyedAvailability[p.productId],
            productData: keyedProducts[p.productId],
            status: this.replenishmentProductStatusService.getStatus(p, keyedAvailability[p.productId])
          };
        });
      })
    );
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: ReplenishmentProductExpanded): string {
    return item.productId;
  }

  getLayouts(): ListLayout<ReplenishmentProductExpanded>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ReplenishmentProductViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Replenishment product list'
      }
    ];
  }

  getSearchTerms(item: ReplenishmentProductExpanded): string[] {
    const result = [];
    result.push(item.productId);

    if (item.productData?.name) {
      result.push(item.productData.name);
    }

    return result;
  }

  getSortOptions(): ListSortOption<ReplenishmentProductExpanded>[] {
    return [];
  }
}
