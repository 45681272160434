<div class="row mb-5">
  <div class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end">
    <h2 translate>SCHEDULERS</h2>
  </div>
  <div class="col-12 col-md-6">
    <div class="d-block">
      <span translate>UPDATE_EVERY</span> <b> {{ schedulersService.intervaleSeconds }} </b> <span translate>SECONDS</span>
    </div>
    <div class="d-block">
      <span translate>LAST_UPDATED</span>:
      <b>{{ schedulersConfigService.lastUpdated | date: dateUtilService.dateFormat + '
                HH:mm:ss' }}</b>
    </div>
    <div class="d-block">
      <span class="btn btn-link p-0" (click)="clearAllError()" translate>CLEAR_ALL_ERROR_LOGS</span>
    </div>
  </div>
</div>
<lib-filter-list #productList [configService]="schedulersConfigService"></lib-filter-list>
