import { Component } from '@angular/core';
import { ReportCentralReportListConfigService } from '../../services/report-central-report-list-config.service';

@Component({
  selector: 'lib-report-central-report-list',
  templateUrl: './report-central-report-list.component.html',
  styleUrls: ['./report-central-report-list.component.css']
})
export class ReportCentralReportListComponent {
  constructor(public configService: ReportCentralReportListConfigService) {}
}
