import { Injectable } from '@angular/core';
import { ActionButton, ActionButtonConfigService, CommonModalService } from 'gung-common';
import { GeneratePdfPlanogram, Planogram } from '../models/planogram';
import { AuthService } from '../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PlanogramService } from './planogram.service';
import { Observable, first, forkJoin, map, of } from 'rxjs';
import { PlanogramEmailPdfExportComponent } from '../components/planogram-email-pdf-export/planogram-email-pdf-export.component';
import { PlanogramCompareModalComponent } from '../components/planogram-compare-modal/planogram-compare-modal.component';
import { SelectedCustomerService } from '../../../services/selected-customer/selected-customer.service';
import { ExportDataService } from '../../../services/export-data/export-data.service';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { HttpClient } from '@angular/common/http';
import { ProductService } from '../../../services/products/product.service';

@Injectable({
  providedIn: 'root'
})
export class PlanogramActionButtonConfigService implements ActionButtonConfigService<Planogram>{

  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();

  currentLang = this.translateService.currentLang || 'se';

  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected modalService: NgbModal,
    protected gungModalService: CommonModalService,
    protected translateService: TranslateService,
    protected planogramService: PlanogramService,
    protected selectedCustomerService: SelectedCustomerService,
    protected exportService: ExportDataService,
    protected gungFlowService: GungFlowService,
    protected http: HttpClient,
    protected productService: ProductService
  ) { }

  getButtons(item: Planogram): Observable<ActionButton<Planogram>[]> {
    return this.authService.getRoles().pipe(
      first(),
      map(roles => {
        const actions = [];
        actions.push(
          {
            title: 'LOAD_PLANOGRAM',
            displayAsButton: true,
            disable: item.readOnly,
            action: (planogram: Planogram) => {
              this.router.navigate(['planogram']).then(w => {
                this.router.navigate(['planogram', planogram.id]).then(w2 => {
                  this.modalService.dismissAll();
                });
              });
            }
          }
        );

        if (roles.includes('ADMIN') || roles.includes('SALES')) {
          actions.push(

            {
              title: 'COPY',
              displayAsButton: false,
              action: (planogram: Planogram) => {
                const payload: Planogram = {
                  ...planogram,
                  id: null,
                  name: planogram.name + ' Copy'
                }
                this.planogramService.savePlanogram(payload).subscribe(newCopy => {
                  this.router.navigate(['planogram']).then(w => {
                    this.router.navigate(['planogram', newCopy.id]).then(w2 => {
                      this.modalService.dismissAll();
                    });
                  });
                });
                
              }
            },            
            {
              title: 'PDF',
              displayAsButton: false,
              action: (planogram: Planogram) => {
                const payload: GeneratePdfPlanogram = {
                  recipient: '',
                  planogram: planogram,
                  showText: true
                }
                this.openEmailPDFExportModal('').then(res => {
                  payload.recipient = res.email;
                  payload.showText = res.showText;
                  this.planogramService.generatePdf(payload).subscribe();
                });
                return of();
              }
            },
            {
              title: 'COMPARE',
              displayAsButton: false,
              action: (planogram: Planogram) => {
               
                this.openCompareModal(planogram).then(res => {                 
                  this.planogramService.comparePlanogram(res).subscribe();
                });
                return of();
              }
            },
            {
              title: 'EXPORT',
              displayAsButton: false,
              action: (planogram: Planogram) => {
                this.gungModalService.openEmailConfirmExportModal('').then(res =>{
                  this.selectedCustomerService.getSelectedCustomer().pipe(first()).subscribe(customer => {
                    const payload = {
                      planogramId: planogram.id,
                      customerId: customer.id,
                      email: res.email
                    }
                    this.planogramService.exportPlanogram(payload).subscribe(_ => { })
                  });
  
                })
               
                return of();
              }
            },
            {
              title: 'EXPORT_WITH_PRICE',
              displayAsButton: false,
              action: (planogram: Planogram) => {
                this.gungModalService.openEmailConfirmExportModal('').then(res =>{
          
                  this.selectedCustomerService.getSelectedCustomer().pipe(first()).subscribe(customer => {
                    const payload = {
                      planogramId: planogram.id,
                      customerId: customer.id,
                      email: res.email
                    }
                    this.planogramService.exportPlanogramWithPrices(payload).subscribe(_ => { })
                  });
  
                })
                
                return of();
              }
            },
            {
              title: 'EXPORT_NEW_MODULES',
              displayAsButton: false,
              action: (planogram: Planogram) => {
                this.gungModalService.openEmailConfirmExportModal('').then(res =>{
          
                  this.selectedCustomerService.getSelectedCustomer().pipe(first()).subscribe(customer => {
                    const payload = {
                      planogramId: planogram.id,
                      customerId: customer.id,
                      email: res.email
                    }
                    this.planogramService.exportPlanogramNewModules(payload).subscribe(_ => { })
                  });
  
                })
                
                return of();
              }
            }
          );
        }
        if (roles.includes('ADMIN')) {
          actions.push({
            title: 'DELETE',
            displayAsButton: true,
            action: (planogram: Planogram) => {
              this.gungModalService
                .openConfirmYesNoModal(undefined, this.translateService.instant('DELETE_PLANOGRAM_CONFIRM'), { size: 'sm' })
                .then(
                  result => {
                    if (result) {
                      this.planogramService
                        .deletePlanogram(planogram.id)
                        .pipe(first())
                        .subscribe(_ => { });
                    }
                  },
                  reason => { }
                );
            }
          });
        }


        return actions;
      })
    );
  }


  openEmailPDFExportModal(email) {
    const id = PlanogramEmailPdfExportComponent.name;

    const ref = this.modalService.open(PlanogramEmailPdfExportComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: true
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.email = email;

    this.modalRef.set(id, ref);

    return ref.result;
  }

  openCompareModal(planogram: Planogram) {
    const id = PlanogramCompareModalComponent.name;

    const ref = this.modalService.open(PlanogramCompareModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: true
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.planogram = planogram;

    this.modalRef.set(id, ref);

    return ref.result;
  }
}
