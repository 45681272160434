import { AfterContentChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PlanogramService } from '../../services/planogram.service';
import { GridTemplate, Planogram, PlanogramGridItem } from '../../models/planogram';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../../../services/products/product.service';
import { first } from 'rxjs';


@Component({
  selector: 'lib-planogram-print-preview',
  templateUrl: './planogram-print-preview.component.html',
  styleUrls: ['./planogram-print-preview.component.scss']
})
export class PlanogramPrintPreviewComponent implements OnInit, AfterContentChecked {
  
  data: PlanogramGridItem[]=[];
  planogram: Planogram;
  gridWidth: number;
  shelfs: number;
  emptySpaces: any[] = [];
  columnWidth: number;
  layout: GridTemplate;
  fontSize = 4;
  boxHeight = 50;

  newModules: PlanogramGridItem[]=[];
  removeModules = [];

  addonsWidth = {};
  

  @ViewChild('gridContainer', { static: false }) gridContainer: ElementRef<HTMLDivElement>;
  
  constructor(
    protected planogramService: PlanogramService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected productService: ProductService
  ){
    
  }
  ngAfterContentChecked(): void {
    if (this.gridContainer) {
      this.gridContainer.nativeElement.style.setProperty('--num-columns', this.gridWidth.toString());
      this.gridContainer.nativeElement.style.setProperty('--colunm-width', this.columnWidth.toString());
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.data = [];
      if (params.id) {
        this.planogramService.getPlanogram(params.id).subscribe(planogram => {
          this.planogram = planogram;
          this.gridWidth = this.planogram.width / this.planogram.widthStep;
          this.columnWidth = this.gridWidth/100;
          this.shelfs = this.planogram.shelfs;
          this.getPlanogramData(this.planogram.items);
          this.emptySpaces = this.calculateEmptySpaces();   
          this.route.queryParams.subscribe(params => {
            if(params?.layout){
              this.layout =params.layout
            }           
          });                 
        });
      }
    })    
  }

  getPlanogramData(items: PlanogramGridItem[]){
    let itemsIds = items.map(i => i.itemId);
    if(this.planogram.expiredAndRemovedItems){
      itemsIds = [...itemsIds, ...this.planogram.expiredAndRemovedItems];
    }
    
    this.productService.getFullProductsByIds(itemsIds).pipe(first()).subscribe(modules =>{
      let allProductsIds = [];
      for (const m of modules) {
        allProductsIds = [...allProductsIds,...m.extra?.SpaceManagementModuleProducts?.map(p=> p.itemId)];
      }
      const productsIds = [...new Set(allProductsIds)];
      this.productService.getFullProductsByIds(productsIds).pipe(first()).subscribe(products =>{
        for (const item of items) {
          const module = modules.find(p => p.id === item.itemId);
          item.data = {
            articles: module?.extra?.SpaceManagementModuleProducts?.map(p=> p.itemId),
            ...module
          }
          const modulesProducts = products.filter(m => item.data.articles?.includes(m.id));
          this.setProductsData(item, modulesProducts);
          if(item.newModule){
            this.newModules.push(structuredClone(item));
          }
          this.data.push(item);
          
        }

        this.initAddons(products, modules);
      });
    });
  }


  initAddons(products, modules){
    if(this.planogram.expiredAndRemovedItems && this.planogram.expiredAndRemovedItems.length > 0){
      for (const removed of this.planogram.expiredAndRemovedItems) {
        const module = modules.find(p => p.id === removed);
        let cols = 1;
        if (module.extra?.pim?.spaceManagementWidth) {
          const width = Number(module.extra?.pim?.spaceManagementWidth);
          cols = Math.floor(width / this.planogram.widthStep);
        }
        const item: PlanogramGridItem = {
          cols,
          itemId: removed,
          type: '',
          x: 0,
          y: 0,
          rows: 0,
          data : {
            articles: module?.extra?.SpaceManagementModuleProducts?.map(p=> p.itemId),
            ...module
          }
        }
        
        const modulesProducts = products.filter(m => item.data.articles?.includes(m.id));
        this.setProductsData(item, modulesProducts);
        this.removeModules.push(structuredClone(item));
        const width = this.gridWidth ;
        this.addonsWidth[item.itemId] = `calc((100% / (${width})) * ${item.cols} )`
      }
    }
    
    for (const module of this.newModules) {
      const width = this.gridWidth ;
      this.addonsWidth[module.itemId] = `calc((100% / (${width})) * ${module.cols} )`
    }
  }


  setProductsData(gridItem: PlanogramGridItem, products){
    let artno = [];
    let floorOne = [];
    let floorTwo = [];
    let floorThree = [];
    let quantityInDisplay = null;
    for (const product of products) {
      if(product.extra.pim.erpProductType==='MERCH'){
        if(artno.includes(product.id)){
          continue;
        }
        artno.push(product.id);
      }
      const itemModule = gridItem.data.extra.SpaceManagementModuleProducts.find(pm=> pm.itemId === product.id);
      if(itemModule.properties.floorOne && itemModule.properties.floorOne > -1){
        floorOne.push({id: product.extra.pim.colorCode, sort: itemModule.properties.floorOne });
        if(itemModule.properties.quantityOfSequenceFloorOne && Number(itemModule.properties.quantityOfSequenceFloorOne)>1){
          for (let index = 1; index < Number(itemModule.properties.quantityOfSequenceFloorOne); index++) {
            floorOne.push({id: product.extra.pim.colorCode, sort: itemModule.properties.floorOne });
          }
        }
      }
      if(itemModule.properties.floorTwo && itemModule.properties.floorTwo > -1){
        floorTwo.push({id: product.extra.pim.colorCode, sort: itemModule.properties.floorTwo });
        if(itemModule.properties.quantityOfSequenceFloorTwo && Number(itemModule.properties.quantityOfSequenceFloorTwo)>1){
          for (let index = 1; index < Number(itemModule.properties.quantityOfSequenceFloorTwo); index++) {
            floorTwo.push({id: product.extra.pim.colorCode, sort: itemModule.properties.floorTwo });
          }
        }
      }
      if(itemModule.properties.floorThree && itemModule.properties.floorThree > -1){
        floorThree.push({id: product.extra.pim.colorCode, sort: itemModule.properties.floorThree });
        if(itemModule.properties.quantityOfSequenceFloorThree && Number(itemModule.properties.quantityOfSequenceFloorThree)>1){
          for (let index = 1; index < Number(itemModule.properties.quantityOfSequenceFloorThree); index++) {
            floorThree.push({id: product.extra.pim.colorCode, sort: itemModule.properties.floorThree });
          }
        }
      }
      if(gridItem.data?.extra?.pim?.imageFromProductId === product.id){
        quantityInDisplay = product.extra.pim.quantityInDisplay;
      }
    }
    gridItem.data.artNo = artno.join(', ');
    gridItem.data.floorOne = floorOne.sort((a, b) =>  a.sort - b.sort);
    gridItem.data.floorTwo = floorTwo.sort((a, b) =>  a.sort - b.sort);
    gridItem.data.floorThree = floorThree.sort((a, b) =>  a.sort - b.sort);
    gridItem.data.quantityInDisplay = quantityInDisplay;
  }


  calculateEmptySpaces() {
    const emptySpaces = [];
    for (let x = 0; x < this.gridWidth; x++) {
      for (let y = 0; y < this.shelfs; y++) {
        if (!this.isItemInPosition(x, y)) {
          emptySpaces.push({ x, y });
        }
      }
    }
    return emptySpaces;
  }

  isItemInPosition(x: number, y: number): boolean {
    return this.planogram.items.some(item => item.x === x && item.y === y);
  }

  print(){
    window.print();
  }

  back(){
    let queryParams = {...this.route.snapshot.queryParams};
    const params = {layout: queryParams?.layout};
    this.router.navigate(['planogram/', this.planogram.id], {queryParams: params})
  }

}
