import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductRequirements, RequirementsPlanningOrder } from '../models/product-requirements';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductionManagementMrpService {
  constructor(private http: HttpClient) {}

  runRequirementsPlanning(): Observable<ProductRequirements[]> {
    const body = this.getRequestBody();
    return this.http.post<ProductRequirements[]>('json/production/requirements-planning', body);
  }

  private getRequestBody(): any {
    // TODO This request body should be created based on the user's input, nothing else.

    return {
      assortmentIds: ['s1000'],
      stockId: 'LAGER',
      forceDataRefresh: true
    };
  }
}
