import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService } from 'gung-common';
import { ListItemRendererComponent } from 'gung-list';
import { first, forkJoin, switchMap } from 'rxjs';
import { Customer } from '../../../models';
import { CheckoutObject, CheckoutRow } from '../../../models/checkout';
import { AuthService } from '../../../services/auth/auth.service';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { OrderServicesService, OrderServicesTicket } from '../../../services/order-services/order-services.service';
import { OrderSubmitService } from '../../../services/order-submit.service';
import { SelectedCustomerService } from '../../../services/selected-customer/selected-customer.service';
import { UsersService } from '../../../services/users/users.service';
import { User } from '../../../state/auth/types';
import { TableRecord } from '../../../state/metadata/types';

@Component({
  selector: 'lib-return-warranty-table',
  templateUrl: './return-warranty-table.component.html',
  styleUrls: ['./return-warranty-table.component.css']
})
export class ReturnWarrantyTableComponent extends ListItemRendererComponent<OrderServicesTicket[]> implements AfterViewInit {
  isAdmin = false;
  isUser = false;
  currentUser: User;
  currentCustomer: Customer;

  statusColor: {[s: string]: string} = {
    '10': '#FFBF00', // Yellow
    '20': '#DEB887', // BurlyWood
    '3': '#9FE2BF', // Blue
    '4': '#FFA07A', // Salmon
    '5': '#E6E6FA	', // Purple
    '90': '#90EE90', // Green
    '99': '#FF7F50', // Maroon
  }

  claimStatusMetadata: [TableRecord];

  customerView: boolean;

  constructor(
    protected modalService: NgbModal,
    protected gungModalService: GungModalService,
    protected orderServicesService: OrderServicesService,
    protected authService: AuthService,
    protected translationService: TranslateService,
    public dateUtilService: DateUtilService,
    protected selectedCustomerService: SelectedCustomerService,
    protected flowService: GungFlowService,
    protected orderSubmitService: OrderSubmitService,
    protected metadataService: MetadataService,
    protected router: Router
  ) {
    super();
    forkJoin([
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.authService.getCurrentUser().pipe(first()),
    ]).subscribe(([customer, user]) => {
      this.currentCustomer = customer;
      this.currentUser = user;
      this.isAdmin = user.roles.indexOf('ADMIN') > -1;
      this.isUser = user.roles.indexOf('USER') > -1 &&  user.roles.indexOf('SALES') === -1 &&  user.roles.indexOf('ADMIN') === -1;
      this.claimStatusMetadata = this.metadataService.getMetadataTableList('CLAIMSTATUS');
    });
    this.customerView = this.router.url.includes('/customers/');
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  openViewModal(item: OrderServicesTicket) {
    this.gungModalService.openViewCreateReturnWarrantyModal(item).then(
      result => {},
      reason => {
        console.log(`Dismissed ${reason}`);
      }
    );
  }

  deleteOrderServices(item: OrderServicesTicket) {
    this.gungModalService
      .openConfirmYesNoModal(undefined, this.translationService.instant('REMOVE') + ` ${item.id}?`, { size: 'sm' })
      .then(result => {
        if (result) {
          this.orderServicesService.deleteOrderServices(item.id).subscribe();
        }
      });
  }

  public claimStatusColor(status: string) {
    return this.statusColor?.[this.claimStatusMetadata?.find(s => s.name === status)?.id];
  }

  createReturnOrder(data) {
    this.gungModalService.openConfirmYesNoModal(undefined, 'CREATE_RETURN_ORDER_IN_ERP').then(
      res => {
        if (res) {
          const toPost = this.mapWarrantyToReturnOrderInput(data);
          const url = 'json/order/create/GUNG_WEB_RETURN';
          this.flowService
            .getSelectedFlow()
            .pipe(
              first(),
              switchMap(flow => {
                toPost.extra._flow = flow;
                return this.orderSubmitService.postOrder(url, toPost).pipe(first());
              })
            ).subscribe({
              next: (res) => {
                // SUCCESS //
                data.orderId = res.erpOrderIds[0];
                data.status = this.claimStatusMetadata[this.claimStatusMetadata.length -2 ].name;
                data.approvedBy = this.currentUser.username;
                data.comments = [
                  ...data.comments,
                  {
                    user: this.currentUser.username,
                    date: new Date(),
                    attachments: [],
                    comment: this.translationService.instant('APPROVED_BY') + ' :' + data.approvedBy
                  }
                ];
                this.orderServicesService.postOrderServices(data).subscribe(data => { });
                this.gungModalService.openBasicModal(
                  this.translationService.instant('SUCCESS'),
                  this.translationService.instant('RETURN_WARRANTY_CREATED_SUCCESS')
                  + '. ' + this.translationService.instant('ORDER_ID')
                  + ': ' + res.erpOrderIds[0]
      
                  ).then(result=>{}, reason=>{});
              },
              error: (err) => {
                // ERROR //
                this.gungModalService.openBasicModal(
                  this.translationService.instant('ERROR'),
                  this.translationService.instant('RETURN_WARRANTY_CREATED_ERROR')
                  ).then(result=>{}, reason=>{});
              }});
        }
      }
    );
  }

  protected mapWarrantyToReturnOrderInput(input: OrderServicesTicket): CheckoutObject {
    const result = {} as CheckoutObject;
    result.deliveryCustomerId = input.customerId || this.currentCustomer.id;
    result.extra = {
      _orderServiceId: input.id
    };
    result.rows = input.rows.map(r => {
      return {
        id: r.id,
        quantity: r.quantity,
        extra: {
          gungSalesOrderLines: {
            ...r?.extra?.gungSalesOrderLines,
            returnReasonCode: r.reasonCode[0]
          }
        },
        targetStockId: this.currentUser.managedMultistockIds[0]
      } as CheckoutRow;
    });

    return result;
  }
}
