import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  BuyBtnComponent,
  CartService,
  GungFlowService,
  GungModalService,
  ProductInputQuantityConfigService
} from 'gung-standard';
import { of } from 'rxjs';
import { delay, first } from 'rxjs';

@Component({
  selector: 'sjoborg-buy-btn',
  templateUrl: './sjoborg-buy-btn.component.html',
  styleUrls: ['./sjoborg-buy-btn.component.scss']
})
export class SjoborgBuyBtnComponent extends BuyBtnComponent {
  @ViewChild('tooltip', { static: false }) tooltip;

  constructor(
    protected cartService: CartService,
    protected gungFlowService: GungFlowService,
    protected productInputQuantityConfigService: ProductInputQuantityConfigService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    protected authService: AuthService
  ) {
    super(cartService, gungFlowService, productInputQuantityConfigService, gungModalService, translationService, authService);
  }

  set(value: number) {
    if (value >= this.minimumOrderQuantity && (this.stepAmount === 1 || value % this.stepAmount === 0)) {
      this.cartService.setProductQuantity(this.id, value, this.targetStockId);
    } else if (value >= 0 && value % this.stepAmount !== 0) {
      value += this.stepAmount - (value % this.stepAmount);
      this.cartService.setProductQuantity(this.id, value, this.targetStockId);
      this.showTooltipAdjustedUpQtyToPackageSize();
    } else {
      if (this.checkoutCart) {
        this.gungModalService
          .openConfirmYesNoModal(undefined, this.translationService.instant('REMOVE_PRODUCT') + '?', { size: 'sm' })
          .then(result => {
            if (result) {
              this.cartService.setProductQuantity(this.id, 0, this.targetStockId);
            } else {
              this.cartService.setProductQuantity(this.id, this.previousQty, this.targetStockId);
            }
          });
      } else {
        this.cartService.setProductQuantity(this.id, 0, this.targetStockId);
      }
    }
  }

  showTooltipAdjustedUpQtyToPackageSize(): void {
    this.tooltip.open();
    of(true)
      .pipe(delay(1500), first())
      .subscribe(() => this.tooltip.close());
  }
}
