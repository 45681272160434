import {
  AfterViewInit,
  Component,
  ComponentRef,
  HostListener,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { filter, first, Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { GungNavbarService, GungShopMenu } from '../../../services/gung-navbar/gung-navbar.service';
import { NavbarCartModel, NavbarCartService } from '../../../services/navbar-cart/navbar-cart.service';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { GungFlow } from '../../../state/flow/types';
import { BaseViewConfigService } from '../../../services/base-view-config/base-view-config.service';
import { GungNavbarCartComponent } from '../gung-navbar-cart/gung-navbar-cart.component';

@Component({
  selector: 'lib-gung-navbar',
  templateUrl: './gung-navbar.component.html',
  styleUrls: ['./gung-navbar.component.scss']
})
export class GungNavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const clickedInsideMenu = (event.target as HTMLElement).closest('.main-menu, .btn-open-menu');
    if (this.activeMainMenu && !clickedInsideMenu) {
      this.activeMainMenu = false;
    }
  }

  @ViewChild('navbarCart', { read: ViewContainerRef }) viewChild;

  dynamicComponentRef: ComponentRef<GungNavbarCartComponent>;

  private unsubscribe: Subject<void> = new Subject<void>();

  public logoUrl; /* = this.gungNavbar.getLogoUrl(); */
  isSales = false;
  activeMainMenu = false;
  isClosingShowMenu = false;
  shopMenu;
  customerInfo: { id: string; description: string };
  isAnonymous = true;
  authenticated: boolean;
  isGlobalSearchOpen = false;
  cartContents: NavbarCartModel;
  showflowMenu = this.gungNavbar.showFlowMenu;
  flowsMenu: GungShopMenu[];
  selectedFlow: GungFlow;
  navbarHeight = this.gungNavbar.navbarHeight;

  constructor(
    protected gungNavbar: GungNavbarService,
    protected authService: AuthService,
    protected navbarCartService: NavbarCartService,
    protected gungFlowService: GungFlowService,
    protected baseViewConfigService: BaseViewConfigService
  ) {}

  ngOnInit(): void {
    this.authService
      .getIsAuthenticated()
      .pipe(
        filter(authenticated => !!authenticated),
        takeUntil(this.unsubscribe)
      )
      .subscribe(auth => (this.authenticated = auth));

    this.gungNavbar
      .getLogoUrlObservable()
      .pipe(first())
      .subscribe(logoUrl => {
        this.logoUrl = logoUrl;
      });

    this.authService
      .getRoles()
      .pipe(
        filter(roles => !!roles),
        first()
      )
      .subscribe(roles => {
        if (roles.indexOf('SALES') > -1 || roles.indexOf('ADMIN') > -1) {
          this.isSales = true;
        }
        if (roles.indexOf('ANONYMOUS') < 0) {
          this.isAnonymous = false;
        }
      });

    this.gungNavbar
      .getCustomerInfo()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(customerInfo => (this.customerInfo = customerInfo));

    this.gungNavbar
      .getShopMenu()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(shopMenu => {
        this.shopMenu = shopMenu;
      });

    this.navbarCartService.getContents().subscribe(contents => {
      this.cartContents = contents;
      this.updateNavCartComponent();
    });

    if (this.showflowMenu) {
      this.mountFlowMenu();
    }
  }

  ngAfterViewInit(): void {
    this.renderLayout(this.baseViewConfigService.getNavbarCartBox());
  }

  mountFlowMenu() {
    this.gungNavbar
      .getFlowMenu()
      .pipe(first())
      .subscribe(flows => {
        
        if (flows.length > 1) {
          this.flowsMenu = flows;
          
        } else {
          this.showflowMenu = false;
        }
      });
    this.gungFlowService
      .getSelectedFlow()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(flow => (this.selectedFlow = flow));
  }

  toggleMainMenu() {
    this.activeMainMenu = !this.activeMainMenu;
  }

  menuClickEvent(event) {
    this.activeMainMenu = !this.activeMainMenu;
  }

  closeExpandMenu(event) {
    this.isClosingShowMenu = event;
    setTimeout(() => {
      this.isClosingShowMenu = false;
    }, 100);
  }

  renderLayout(layoutComponent: Type<GungNavbarCartComponent>): void {
    this.dynamicComponentRef = this.viewChild.createComponent(layoutComponent);
    this.dynamicComponentRef.instance.cartContentsSubject.next(this.cartContents);
  }

  updateNavCartComponent(): void {
    if (this.dynamicComponentRef) {
      this.dynamicComponentRef.instance.cartContentsSubject.next(this.cartContents);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
