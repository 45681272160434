<div class="row">
  <div class="col-sm-12">
    <div class="text-center mb-3">
      <h3 translate>ORDER_PORTAL</h3>
    </div>

    <lib-filter-list #list [configService]="configService"></lib-filter-list>
  </div>
</div>

