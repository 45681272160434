<form [formGroup]="form">
  <div class="row" *ngIf="form && deliveryAddresses">
    <div class="col-12">
      <div class="form-group">
        <label for="deliveryLocation" class="control-label" translate>DELIVERY_LOCATION</label>
        <select
          name="deliveryLocation"
          id="deliveryLocation"
          class="form-control"
          (change)="onDeliveryAddressSelected($event.target.value)"
        >
          <option
            [value]="i"
            *ngFor="let option of deliveryAddresses; let i = index"
            [selected]="isSelectedAddress(option)"
          >
            {{ option.addressValues.ordlevadr1 }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group">
        <label for="ordlevadr1" class="control-label" translate>OH_ORDLEVADR1</label>
        <input class="form-control" type="text" name="ordlevadr1" id="ordlevadr1" [formControlName]="'ordlevadr1'" />
      </div>
      <div class="form-group">
        <label for="ordlevadr2" class="control-label" translate>OH_ORDLEVADR2</label>
        <input class="form-control" type="text" name="ordlevadr2" id="ordlevadr2" [formControlName]="'ordlevadr2'" />
      </div>
      <div class="form-group">
        <label for="ordlevadr3" class="control-label" translate>OH_ORDLEVADR3</label>
        <input class="form-control" type="text" name="ordlevadr3" id="ordlevadr3" [formControlName]="'ordlevadr3'" />
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group">
        <label for="ftgpostnr" class="control-label" translate>OH_FTGPOSTNR</label>
        <input class="form-control" type="text" name="ftgpostnr" id="ftgpostnr" [formControlName]="'ftgpostnr'" />
      </div>
      <div class="form-group">
        <label for="ordlevadr4" class="control-label" translate>OH_ORDLEVADR4</label>
        <input class="form-control" type="text" name="ordlevadr4" id="ordlevadr4" [formControlName]="'ordlevadr4'" />
      </div>
      <div>
        <lib-meta-select-input formControlName="ordlevadrlandskod" table="xr" metaId="landskod" metaDisplay="land">
        </lib-meta-select-input>
      </div>
    </div>
  </div>
</form>
