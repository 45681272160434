import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnInit,
  QueryList,
  Type,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomHostDirective } from 'gung-list';
import { LoginConfigService } from '../../../services/login-config/login-config.service';
import { BaseViewConfigService } from '../../../services/base-view-config/base-view-config.service';

@Component({
  selector: 'lib-reset-password-wrapper',
  templateUrl: './reset-password-wrapper.component.html',
  styleUrls: ['./reset-password-wrapper.component.css']
})
export class ResetPasswordWrapperComponent implements OnInit, AfterViewInit {
  @ViewChildren(CustomHostDirective)
  viewChild: QueryList<CustomHostDirective>;
  containerStyles: { [styleName: string]: any } = { ['margin-top']: '52px' };

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected changeDetectorRef: ChangeDetectorRef,
    protected baseViewConfigService: BaseViewConfigService
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    if (this.viewChild && this.viewChild.length !== 0) {
      // Clear views already rendered from previous navigation
      const containerRef = this.viewChild.last.viewContainerRef;
      containerRef.clear();
    }

    this.renderLayout(this.baseViewConfigService.getResetPasswordComponent('fashion'));
  }

  renderLayout(layoutComponent: Type<any>): void {
    if (!this.viewChild || this.viewChild.length === 0) {
      return;
    }
    this.changeDetectorRef.detectChanges();
    const factory = this.componentFactoryResolver.resolveComponentFactory(layoutComponent);
    const containerRef = this.viewChild.last.viewContainerRef;
    containerRef.clear();
    containerRef.createComponent(factory);
    this.changeDetectorRef.detectChanges();
  }
}
