<div class="container" *ngIf="form">
  <div class="row">
    <div class="col-12 text-center">
      <h2 class="mt-5" translate>TRANSFER_INVENTORY</h2>
    </div>
    <div class="col-auto position-absolute">
      <a type="button" class="btn btn-info" routerLink=".."
        ><i class="fa-solid fa-arrow-left-long mr-1"></i><span translate>GO_BACK</span></a
      >
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-3">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <lib-production-management-product-select-dropdown
                #productEle
                gungTranslate="ITEM_NO"
                [formatterFunction]="formatterFunction"
                [formatterInputFunction]="formatterInputFunction"
                (productSelected)="selectProduct($event)"
                [disabled]="productSearchDisabled"
              ></lib-production-management-product-select-dropdown>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="" translate>FROM_STOCK</label>
              <lib-production-management-meta-data-select-input
                suppressLabel="true"
                formControlName="fromStockPointId"
                label="FROM_STOCK"
                table="StockPoints"
                metaId="code"
                metaDisplay="name"
                hideEmptyOption="true"
              ></lib-production-management-meta-data-select-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="" translate>FROM_LOCATION</label>
              <lib-gung-select-option-input
                suppressLabel="true"
                formControlName="fromLocationId"
                [optionsList]="stockLocationList['from']"
              ></lib-gung-select-option-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="" translate>TO_STOCK</label>
              <lib-production-management-meta-data-select-input
                suppressLabel="true"
                formControlName="toStockPointId"
                label="TO_STOCK"
                table="StockPoints"
                metaId="code"
                metaDisplay="name"
                hideEmptyOption="true"
              ></lib-production-management-meta-data-select-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="" translate>TO_LOCATION</label>
              <lib-gung-select-option-input
                suppressLabel="true"
                formControlName="toLocationId"
                [optionsList]="stockLocationList['to']"
              ></lib-gung-select-option-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <lib-gung-number-input
                gungTranslate="QTY"
                formControlName="quantity"
                [outputAsString]="true"
                [minValue]="0"
                [maxValue]="productStock?.from?.inventory ?? 0"
              ></lib-gung-number-input>
            </div>
          </div>
        </div>

        <button type="button" class="btn btn-primary mb-2 w-100" translate [disabled]="disabledTransfer" (click)="transfer($event)">
          TRANSFER
        </button>
        <br />
        <button type="button" class="btn btn-secondary w-100" translate (click)="newTransfer(productEle)">
          NEW_TRANSFER
        </button>
      </form>
    </div>
    <div class="col-12 col-md-9 mt-4 mt-md-0">
      <div class="row mb-3">
        <div class="col-12">
          <lib-gung-text-input
            gungTranslate="NAME"
            renderStatic="true"
            [ngModel]="product?.name ?? ''"
          ></lib-gung-text-input>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-md-auto">
          <lib-gung-text-input
            gungTranslate="INVENTORY"
            renderStatic="true"
            [ngModel]="productStock?.from?.inventory ?? ''"
          ></lib-gung-text-input>
        </div>
        <div class="col-12 col-md-auto">
          <lib-gung-text-input
            gungTranslate="AVAILABLE"
            renderStatic="true"
            [ngModel]="productStock?.from?.available ?? ''"
          ></lib-gung-text-input>
        </div>
        <div class="col-12 col-md-auto">
          <lib-gung-text-input
            gungTranslate="NEW"
            renderStatic="true"
            [ngModel]="(productStock?.from?.inventory ?? 0) - (form?.value?.quantity ?? 0)"
          ></lib-gung-text-input>
        </div>
      </div>
      <div class="row mt-5 mt-md-0">
        <div class="col-12 col-md-auto">
          <lib-gung-text-input
            gungTranslate="INVENTORY"
            renderStatic="true"
            [ngModel]="productStock?.to?.inventory ?? ''"
          ></lib-gung-text-input>
        </div>
        <div class="col-12 col-md-auto">
          <lib-gung-text-input
            gungTranslate="AVAILABLE"
            renderStatic="true"
            [ngModel]="productStock?.to?.available ?? ''"
          ></lib-gung-text-input>
        </div>
        <div class="col-12 col-md-auto">
          <lib-gung-text-input
            gungTranslate="NEW"
            renderStatic="true"
            [ngModel]="add(form?.value?.quantity, productStock?.to?.inventory)"
          ></lib-gung-text-input>
        </div>
      </div>
    </div>
  </div>
</div>
