import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { CheckoutObject } from '../../models/checkout';
import { CheckoutStepComponent } from '../checkout/steps/base/checkout-step.component';
import { first } from 'rxjs';
import { StripeCheckoutService } from '../../services/stripe-checkout/stripe-checkout.service';
import { StripeService } from '../../services/stripe/stripe.service';
declare var Stripe: any;

@Component({
  selector: 'lib-stripe-payment-step',
  templateUrl: './stripe-payment-step.component.html',
  styleUrls: ['./stripe-payment-step.component.css']
})
export class StripePaymentStepComponent extends CheckoutStepComponent implements OnInit, OnDestroy {
  @Input()
  public checkout: CheckoutObject;

  private readonly STRIPE_PUBLIC_KEY = this.environment.stripePkLive;
  private stripe_checkout;

  constructor(
    protected stripeCheckoutService: StripeCheckoutService,
    protected stripeService: StripeService,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {
    super();
  }

  public isExternalPaymentStep(): boolean {
    return true;
  }

  ngOnInit() {
    super.ngOnInit();

    // When we don't pay with stripe , we don't need to do anything
    if (!this.stripeCheckoutService.isEnabled() || !this.stripeCheckoutService.shouldPayWithStripe(this.checkout)) {
      this.stepDone.emit(this.checkout);
      return;
    }

    this.stripeCheckoutService.decorateCheckout(this.checkout);

    this.stripeService
      .createSession(this.checkout)
      .pipe(first())
      .subscribe((res: any) => {
        const client_secret = res.client_secret;

        const stripe = Stripe(this.STRIPE_PUBLIC_KEY);

        stripe
          .initEmbeddedCheckout({
            clientSecret: client_secret
          })
          .then(result => {
            this.stripe_checkout = result;
            this.stripe_checkout.mount('#checkout');
          });
      });
  }

  ngOnDestroy(): void {
    if (this.stripe_checkout) {
      this.stripe_checkout.destroy();
    }
  }
}
