<div class="dashboard-card card" *ngIf="loaded">
  <div class="card-body dashboard-card-content">
    <div class="card-title">
      <div class="d-flex justify-content-between">
        <div>
          <span class="title" translate>NUMBER_SALES</span> <br />
          <span class="sub-title" translate>{{ monthString }}</span>
        </div>
        <span class="current-statistic text-primary">{{ salesThisMonth | number: '1.0-0' }}</span>
      </div>
    </div>

    <div class="progress-container">
      <ngb-progressbar [animated]="true" [value]="(salesThisMonth / salesLastYearThisMonth) * 100"> </ngb-progressbar>
      <div class="d-flex justify-content-between">
        <span class="sub-title">{{ ((salesThisMonth / salesLastYearThisMonth) * 100 || 0).toFixed() }}%</span>
        <span class="sub-title" translate>CHANGE_FROM_BEFORE</span>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-card card" *ngIf="!loaded">
  <div class="card-body">
    <div class="text-center dashboard-loading-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
