<div class="table-responsive">
  <table class="table table-borderless table-hover">
    <!-- table-striped -->
    <thead>
      <tr>
        <th class="p-0 pr-2"></th>
        <th style="min-width: 90px"></th>
        <th scope="col" translate>ORDER_ID</th>
        <!-- <th scope="col" translate>ORDER_STATUS</th> -->
        <th scope="col" translate>CUSTOMER_NAME</th>
        <th scope="col" translate>OUR_REFERENCE</th>
        <th scope="col" translate>ORDER_DATE</th>
        <th scope="col" translate>EXPECTED_DELIVERY_DATE</th>
        <th scope="col" translate>TOTAL</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let order of data"
        [routerLink]="[order.id]"
      >
        <td class="p-0 border-0" [ngClass]="{
          'not-invoiced': order.extra.Order.InvoiceReference === '0',
          invoiced: order.extra.Order.InvoiceReference !== '0',
          cancelled: order.extra.Order.Cancelled === true
        }"></td>
        <td style="width: 90px">
          <lib-item-selection-button [item]="order"></lib-item-selection-button>
        </td>
        <td>
          <a>{{ order.id }}</a>
        </td>
        <!-- <td>{{ getStatusTranslationKey(order) | translate }}</td> -->
        <td>{{ order.extra.Order.CustomerName || '-' }}</td>
        <td>{{ order.extra.Order.OurReference }}</td>
        <td>
          <span *ngIf="order.extra.Order.OrderDate" class="{{ getCssClass(order) }}">
            {{ order.extra.Order.OrderDate | date: dateUtilService.dateFormat }}
          </span>
        </td>
        <td>
          <span *ngIf="order.extra.Order.DeliveryDate" class="{{ getCssClass(order) }}">
            {{ order.extra.Order.DeliveryDate | date: dateUtilService.dateFormat }}
          </span>
        </td>
        <td>
          <!-- {{ order.extra.Order.Total | gungCurrency: order.extra.Order.Currency:'symbol-narrow':'1.2-2' }} -->
          {{ gungCurrencyPipe.transform(order.extra.Order.Total, order.extra.Order.Currency, 'symbol-narrow', '1.2-2') }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
