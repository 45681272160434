import { Component, OnInit } from '@angular/core';
import { InvoiceStatistic } from '../../types';
import { ListItemRendererComponent } from 'gung-list';

@Component({
  selector: 'lib-invoice-filtered-statistics',
  templateUrl: './invoice-filtered-statistics.component.html',
  styleUrls: ['./invoice-filtered-statistics.component.scss']
})
export class InvoiceFilteredStatisticsComponent
  extends ListItemRendererComponent<InvoiceStatistic[]>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit() {}
}
