<div class="expand-menu" [ngStyle]="{ 'top.px': top, 'left.px': left }">
  <div class="d-flex">
    @if (levels !== 3) {
      <div class="first-menu-list">
        @for (link of menu; track link.heading) {
          <div class="first-menu d-flex align-items-center justify-content-between">
            @if (link.links && link.links.length > 0) {
              <a
                [routerLink]="[link.link]"
                routerLinkActive="active-submenu"
                [routerLinkActiveOptions]="{ exact: false }"
                (click)="menuClick(link)"
                class="w-100 d-flex align-items-center justify-content-between first-menu-link"
                (mouseover)="showSecondMenu(link.links)"
              >
                <span class="mr-2">{{ link.heading }}</span>
                <i class="fa-solid fa-chevron-right"></i>
              </a>
            } @else {
              <a
                [routerLink]="[link.link]"
                routerLinkActive="active-submenu"
                [routerLinkActiveOptions]="{ exact: false }"
                class="w-100 d-flex align-items-center justify-content-between first-menu-link"
                (click)="menuClick(link)"
              >
                <span class="mr-2">{{ link.heading }}</span>
                <!-- <i class="fa-regular fa-arrow-right"></i> -->
              </a>
            }
          </div>
        }
      </div>
      @if (levels > 3 && secondMenuLinks) {
        <div class="second-menu">
          <div class="d-flex secondary-menu-list">
            @for (link of secondMenuLinks; track link.heading) {
              <div class="secondary-menu">
                <div class="secondary-menu-title">
                  <a class="d-block" [routerLink]="[link.link]" (click)="menuClick(link)">
                    {{ link.heading }}
                  </a>
                </div>
                @if (link.links && link.links.length > 0) {
                  @for (sublink of link.links; track sublink.heading) {
                    <div class="third-link">
                      <a [routerLink]="[sublink.link]" (click)="menuClick(sublink)">
                        {{ sublink.heading }}
                      </a>
                    </div>
                  }
                }
              </div>
            }
          </div>
        </div>
      }
    } @else {
      <div class="two-level-menu">
        <div class="second-menu">
          <div class="d-flex secondary-menu-list">
            @for (link of menu; track link.heading) {
              <div class="secondary-menu">
                <div class="secondary-menu-title">
                  <a class="d-block" [routerLink]="[link.link]" (click)="menuClick(link)">
                    {{ link.heading }}
                  </a>
                </div>
                @if (link.links && link.links.length > 0) {
                  @for (sublink of link.links; track sublink.heading) {
                    <div class="third-link">
                      <a [routerLink]="[sublink.link]" (click)="menuClick(sublink)">
                        {{ sublink.heading }}
                      </a>
                    </div>
                  }
                }
              </div>
            }
          </div>
        </div>
      </div>
      
    }
  </div>
</div>
