import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FreightProductHelperService {
  protected freightProducts: string[] = [];

  constructor() { }

  getFreightProducts(): string[] {
    return this.freightProducts;
  }

  getFreightProductsAsObservable(): Observable<string[]> {
    return of(this.freightProducts);
  }
}
