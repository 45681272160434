import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
  ConfigBaseFilter,
  ConfigFilterGroup,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  PimTemplateProperties,
  SearchField,
  SelectionAction,
  SimpleConfigBaseFilter
} from 'gung-list';
import { Observable } from 'rxjs';
import { EanNumbers, EanNumbersService } from '../ean-numbers/ean-numbers.service';
import { BaseViewConfigService } from '../base-view-config/base-view-config.service';

export class EanDuplicateFilter extends SimpleConfigBaseFilter<EanNumbers> {
  getName(): string {
    return 'DUPLICATE_EAN';
  }
  getOptionIds(item: EanNumbers): string[] {
    if (!!item.duplicateEANs) {
      return ['TRUE'];
    } else {
      return ['FALSE'];
    }
  }
  getOptionName(key: string): string {
    if (key === 'TRUE') {
      return 'DUPLICATES';
    } else {
      return 'NO_DUPLICATES';
    }
  }
}
@Injectable({
  providedIn: 'root'
})
export class EdiEanRepositoryListConfigService implements ConfigService<EanNumbers> {
  topFilter?: boolean;
  selectedFilterTop?: boolean;
  searchDisabled?: boolean;
  searchAboveFilters?: boolean;
  showHeaderBanner?: boolean;
  itemsPerRow?: number;

  constructor(protected eanNumbersService: EanNumbersService, protected baseViewConfigService: BaseViewConfigService) {}

  getSearchTerms(item: EanNumbers): string[] {
    const generalSearchTerms = [item.ean, item.sku].filter(s => !!s);
    return generalSearchTerms;
  }
  getItemId(item: EanNumbers): string {
    return item.id;
  }
  getItems(route?: ActivatedRouteSnapshot): Observable<EanNumbers[]> {
    return this.eanNumbersService.getEanNumbers();
  }
  getFilters(route?: ActivatedRouteSnapshot): ConfigBaseFilter<EanNumbers>[] {
    return [new EanDuplicateFilter()];
  }
  getSortOptions(): ListSortOption<EanNumbers>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }

  getLayouts(): ListLayout<EanNumbers>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => this.baseViewConfigService.getEanNumbersListComponent(),
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'CustomerUsers grid'
      }
    ];
  }
}
