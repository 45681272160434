import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, switchMap } from 'rxjs';
import { InvoiceStatisticsHelperService } from '../../../services/invoice-statistics-helper.service';
import { TranslateService } from '@ngx-translate/core';

class InactiveInfo {
  customerId: string;
  customerName: string;
  amountOfInvoices: number;
  latestInvoiceDate: string;
}

class Option {
  months: number;
  text: string;
}

@Component({
  selector: 'lib-inactive-customers-card',
  templateUrl: './inactive-customers-card.component.html',
  styleUrls: ['./inactive-customers-card.component.scss']
})
export class InactiveCustomersCardComponent implements OnInit {
  constructor(
    protected statisticsHelpers: InvoiceStatisticsHelperService,
    private translateService: TranslateService
  ) {}

  inactiveCustomerInfo: InactiveInfo[];
  options: Option[];
  inactiveMonths: number;
  activeWithinMonths: number;

  ngOnInit() {
    this.statisticsHelpers
      .getInactiveCustomersMonthOptions()
      .pipe(map(x => x as Option[]))
      .subscribe((data: Option[]) => {
        this.options = data;
      });

    this.loadData(3, 2);
  }

  loadData(activeWithinMonths: number, inactiveMonths: number) {
    this.inactiveMonths = inactiveMonths;
    this.activeWithinMonths = activeWithinMonths;

    this.statisticsHelpers
      .getInactiveCustomers(activeWithinMonths, inactiveMonths)
      .pipe(map(x => x as InactiveInfo[]))
      .subscribe((data: InactiveInfo[]) => {
        this.inactiveCustomerInfo = data;
      });
  }
}
