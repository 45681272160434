import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first, forkJoin, map, switchMap } from 'rxjs';
import { ReplenishmentCustomerService } from '../../services/replenishment-customer.service';
import { ReplenishmentCustomer, ReplenishmentProduct } from '../../models/replenishment';
import { CartService } from '../../../../services/cart/cart.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { ProductService } from '../../../../services/products/product.service';
import { SelectedCustomerService } from '../../../../services/selected-customer/selected-customer.service';
import { ReplenishmentProductExcelService } from '../../services/replenishment-product-excel.service';

@Component({
  selector: 'lib-replenishment-customer',
  templateUrl: './replenishment-customer.component.html',
  styleUrls: ['./replenishment-customer.component.scss']
})
export class ReplenishmentCustomerComponent implements OnInit {
  replenishmentCustomer: ReplenishmentCustomer;

  constructor(
    protected route: ActivatedRoute,
    protected replenishmentCustomerService: ReplenishmentCustomerService,
    protected cartService: CartService,
    protected authService: AuthService,
    protected productService: ProductService,
    protected router: Router,
    protected selectedCustomerService: SelectedCustomerService,
    protected replenishmentProductExcelService: ReplenishmentProductExcelService
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        map(params => {
          return params.customerId as string;
        }),
        switchMap(customerId =>
          forkJoin([this.replenishmentCustomerService.getReplenishmentCustomer(customerId).pipe(first())])
        )
      )
      .subscribe(([customer]) => {
        this.replenishmentCustomer = customer;
      });
  }

  createExcel(): void {
    this.replenishmentProductExcelService
      .exportActiveProductsToExcel(this.replenishmentCustomer.customerId)
      .subscribe(_ => undefined);
  }

  sortProducts = (a: ReplenishmentProduct, b: ReplenishmentProduct): number => {
    return -1;
    // return this.enumSortOrder.get(a.status) - this.enumSortOrder.get(b.status);
  };
  // private enumSortOrder = new Map<ReplenishmentProductStatus, number>([
  //   [ReplenishmentProductStatus.OK_FOR_ORDER, 1],
  //   [ReplenishmentProductStatus.SHORTAGE, 2],
  //   [ReplenishmentProductStatus.NO_PRESSURE, 3]
  // ]);
}
