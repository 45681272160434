import { Component, OnInit } from '@angular/core';
import { AssignOrderRowsComponent, CartService, CartRow } from 'gung-standard';

@Component({
  selector: 'gung-jeeves-assign-order-rows-jeeves',
  templateUrl: './assign-order-rows-jeeves.component.html',
  styleUrls: ['./assign-order-rows-jeeves.component.css']
})
export class AssignOrderRowsJeevesComponent extends AssignOrderRowsComponent implements OnInit {
  constructor(cartService: CartService) {
    super(cartService);
  }

  protected handleCart(rows: CartRow[]) {
    // Ensure lagstalle is set on orderrows
    const newRows: CartRow[] = rows.map(row => {
      return {
        ...row,
        extra: {
          ...row.extra,
          orp: {
            ...row.extra.orp,
            lagstalle: row.extra.orp && row.extra.orp.lagstalle ? row.extra.orp.lagstalle : row.targetStockId
          }
        }
      };
    });

    super.handleCart(newRows);
  }
}
