<div class="container fashion-login">
  <div class="login-container">
    <div class="login-logo">
      <img class="d-none" [src]="logoUrl || './assets/logo.png'" />
    </div>
    <lib-language-selection></lib-language-selection>
    <h5 *ngIf="!forgotPassword" class="text-primary"><strong translate>LOGIN</strong></h5>
    <form *ngIf="!forgotPassword" [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="mt-3">
      <div class="form-group">
        <label for="username" translate>USERNAME</label>
        <input
          type="text"
          formControlName="username"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
          [placeholder]="'USERNAME' | translate"
        />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required" translate>USERNAME_REQUIRED</div>
        </div>
      </div>
      <div class="form-group">
        <label for="password" translate>PASSWORD</label>
        <input
          type="password"
          formControlName="password"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
          [placeholder]="'PASSWORD' | translate"
        />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required" translate>PASSWORD_REQUIRED</div>
        </div>
      </div>
      <div
        *ngIf="error && (error.type === ActionTypes.BadLogin || error.type === ActionTypes.BackendDown)"
        class="alert alert-danger"
      >
        <span *ngIf="error.type === ActionTypes.BadLogin" translate>LOGIN_FAILED</span>
        <span *ngIf="error.type === ActionTypes.BackendDown" translate>BACKEND_DOWN</span>
      </div>

      <div class="form-group d-flex justify-content-between align-items-center">
        <a routerLink="/forgot-password" class="forgot-password" translate>FORGOT_PASSWORD</a>
        <button *ngIf="!loading" class="btn btn-primary" translate>LOGIN</button>
        <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg"></i>
      </div>

      <div class="form-group d-flex justify-content-between align-items-center">
        <a routerLink="/request-account" class="request-account" translate>REQUEST_ACCOUNT</a>
      </div>
    </form>
    <lib-forgot-password *ngIf="!!forgotPassword"></lib-forgot-password>
  </div>
</div>
