import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { th } from 'date-fns/locale';
import { Observable, forkJoin, first, of } from 'rxjs';
import { CustomPageConfigService } from '../../../services/custom-page/custom-page-config.service';
import { LanguageConfigService } from '../../../services/language-config/language-config.service';
import { TemplateElement } from '../custom-page-interface/template-component';
import { GungDownloadUrl, GungLanguageConfigService, GungNoImageUrl } from 'gung-common';

const defaultSlide: TemplateElement = {
  link: {
    enabled: true,
    internal: undefined,
    url: ''
  },
  image: {
    enabled: true,
    sourceUrl: GungNoImageUrl // 400x400
  },
  text: {
    enabled: true,
    text: ''
  }
}

@Component({
  selector: 'lib-custom-page-editor-modal',
  templateUrl: './custom-page-editor-modal.component.html',
  styleUrls: ['./custom-page-editor-modal.component.css']
})
export class CustomPageEditorModalComponent implements OnInit {
  @Input() data: TemplateElement[];
  @Input() isAssortment: boolean = false;
  @Input() isDataSlider: boolean = false;

  imageUpload: File[];
  isLoading = false;

  translateLangs: string[];

  constructor(
    public activeModal: NgbActiveModal,
    public customPagesService: CustomPageConfigService,
    protected languageConfigService: GungLanguageConfigService
  ) { }

  ngOnInit(): void {

    this.mapData();
  }

  mapData() {
    this.imageUpload = this.data.map(() => undefined);

    this.translateLangs = this.languageConfigService
      .getLanguages()
      .filter(l => l.enabled)
      .map(l => l.short);

    for (let data of this.data) {
      data.text = data.text || { enabled: true, text: '' };
      data.text.i18n = data.text.i18n || {};

      for (const lang of this.translateLangs) {
        if (!data.text.i18n[lang]) {
          data.text.i18n[lang] = { title: data?.text?.text || '' };
        }
      }

      if (data.text.text && this.translateLangs?.length > 0) {
        data.text.text = undefined;
      }
    }
  }

  onSave() {
    const observables: Observable<any>[] = [];
    const indexWithImages: number[] = [];
    for (let index = 0; index < this.data.length; index++) {
      if (this.data.length > 1 && !this.isAssortment) {
        this.data[index].text.text = this.data[index].text.i18n[this.languageConfigService.getDefaultLanguage()].title;
      }
      if (this.imageUpload && this.imageUpload[index]) {
        this.isLoading = true;
        const imageObject = {
          index: index,
          description: 'custompage image - ' + new Date().getTime(),
          category: 'custompage image',
          s3Uri: 'images/no-image.jpg'
        };
        const formData = new FormData();
        formData.append('file', this.imageUpload[index]);
        formData.append('productImage', JSON.stringify(imageObject));
        observables.push(this.customPagesService.uploadImageS3(formData));
        indexWithImages.push(index);
      }
    }

    if (observables.length > 0) {
      forkJoin([forkJoin(observables)]).pipe(first()).subscribe(([images]) => {
        for (const [index, value] of indexWithImages.entries()) {
          this.data[value].image.sourceUrl = GungDownloadUrl(images[index]);
        }

        this.isLoading = false;
        this.activeModal.close(JSON.stringify(this.data));
      });
    } else {
      this.activeModal.close(JSON.stringify(this.data));
    }
  }

  onFileChange(event, index?: number) {
    this.imageUpload[index] = event.target.files && event.target.files[0];
    this.data[index].image.sourceUrl = '';
  }

  addSlide() {
    this.data.push(JSON.parse(JSON.stringify(defaultSlide)));
    this.imageUpload.push(undefined);
    this.mapData();
  }

  removeSlide(index: number) {
    this.data.splice(index, 1);
    this.imageUpload.splice(index, 1);
    this.mapData();
  }
}


