import { Injectable, Type } from '@angular/core';
import { BaseViewConfigService, PriceService, BarcodeQuickCheckoutComponent } from 'gung-standard';
import { JeevesBarcodeQuickCheckoutComponent } from '../../components/jeeves-barcode-scanner/jeeves-barcode-quick-checkout/jeeves-barcode-quick-checkout.component';
import { JeevesProductDetailsComponent } from '../../components/jeeves-product-details/jeeves-product-details.component';

@Injectable({
  providedIn: 'root'
})
export class JeevesBaseViewConfigService extends BaseViewConfigService {
  defaultProductDetail = JeevesProductDetailsComponent;

  constructor(priceServiceInput: PriceService) {
    super(priceServiceInput);
  }

  getBarcodeQuickCheckoutComponent(): Type<BarcodeQuickCheckoutComponent> {
    return JeevesBarcodeQuickCheckoutComponent;
  }
}
