import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CpqBuyBtnComponent } from './components/cpq-buy-btn/cpq-buy-btn.component';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CpqConfiguratorComponent } from './components/cpq-configurator/cpq-configurator.component';
import { CpqCartRowSummaryComponent } from './components/cpq-cart-row-summary/cpq-cart-row-summary.component';
import { CpqConfigurationSummaryComponent } from './components/cpq-configuration-summary/cpq-configuration-summary.component';
import { AuthGuard } from '../../guards/authguard/auth-guard';
import { CpqFeatureHeaderComponent } from './components/cpq-feature-header/cpq-feature-header.component';

const routes: Routes = [
  {
    path: 'configurator/:id',
    component: CpqConfiguratorComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    CpqBuyBtnComponent,
    CpqConfiguratorComponent,
    CpqCartRowSummaryComponent,
    CpqConfigurationSummaryComponent,
    CpqFeatureHeaderComponent
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild({
      extend: true
    }),
    RouterModule.forChild(routes)
  ],
  exports: [CpqCartRowSummaryComponent]
})
export class CpqModule {}
