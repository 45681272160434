import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutSingleComponent, ListSortOption } from 'gung-list';
import { ProductionOrder } from '../../models/production-order';
import { Observable, of } from 'rxjs';
import { ProductionOrderService } from '../production-order/production-order.service';
import { OrderCardListComponent } from '../../components/order-card-list/order-card-list.component';

@Injectable({
  providedIn: 'root'
})
export class ProductionOrdersListConfigService implements ConfigService<ProductionOrder> {
  constructor(protected productionOrderService: ProductionOrderService) {}

  getItems(): Observable<ProductionOrder[]> {
    return this.productionOrderService.getProductionOrders();
  }

  getLayouts(): ListLayout<ProductionOrder>[] {
    // Here we change on each ERP.
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OrderCardListComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Production order list'
      }
    ];
  }

  getFilters(): ConfigBaseFilter<ProductionOrder>[] {
    return [];
  }

  getItemId(item: ProductionOrder): string {
    return item.id;
  }

  getSearchTerms(item: ProductionOrder): string[] {
    return [item.id];
  }

  getSortOptions(): ListSortOption<ProductionOrder>[] {
    return [];
  }

  getBatchSizes(): number[] | undefined {
    return [24];
  }
}
