import { Component, Input, OnInit } from '@angular/core';
import { first, forkJoin } from 'rxjs';
import { Customer } from '../../models/customer';
import { Pricelist } from '../../models/price';
import { CustomerService } from '../../services/customers/customer.service';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { PriceService } from '../../services/price/price.service';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { GungFlow } from '../../state/flow/types';
import { gungAddRemoveSpinner } from '../../utils/gung-utils';

@Component({
  selector: 'lib-customer-conditions',
  templateUrl: './customer-conditions.component.html',
  styleUrls: ['./customer-conditions.component.css']
})
export class CustomerConditionsComponent implements OnInit {

  @Input() customer: Customer; 
  pricelists: Pricelist[];
  flows: GungFlow[];
  mappedFlowPricelist = {};
  saved = false;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected customerService: CustomerService,
    protected selectedCustomerService: SelectedCustomerService
  ) { }

  ngOnInit(): void {
    forkJoin({
      flows: this.gungFlowService.getGungFlows().pipe(first()),
      pricelists: this.priceService.getPriceListOptions().pipe(first())
    }).subscribe(({flows, pricelists}) => {
      this.flows = flows;
      this.pricelists = pricelists;
      this.initMapPriceFlow();
    })

  }

  initMapPriceFlow(){
    for (const flow of this.flows) {
      this.mappedFlowPricelist[flow.id] = this.customer.flowToPricelistMapping && this.customer.flowToPricelistMapping[flow.id] ? this.customer.flowToPricelistMapping[flow.id] : undefined;
    }
  }

  save({target}){
    gungAddRemoveSpinner(target);
    
    let newCustomerData =  JSON.parse(JSON.stringify(this.customer));
    newCustomerData.flowToPricelistMapping = this.mappedFlowPricelist;
    this.customerService.updateCustomer(newCustomerData).subscribe(data =>{
      gungAddRemoveSpinner(target);
      this.saved = true;
      this.customer = data;
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    })
  }
}
