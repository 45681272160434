import { EventEmitter, Injectable } from '@angular/core';
import { ActionButton, ActionButtonConfigService, GungNotificationService } from 'gung-common';
import { OrderPortalEntry } from '../models/order-portal';
import { first, Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { BackendInterceptor } from '../../../services/backend-interceptor/backend-interceptor.service';
import { OrderContextService } from '../../../services/order-context.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { OrderPortalChannelService } from './order-portal-channel.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OrderPortalActionConfigService implements ActionButtonConfigService<OrderPortalEntry> {
  constructor(
    protected authService: AuthService,
    protected backendInterceptor: BackendInterceptor,
    protected orderContextService: OrderContextService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    protected gungNotificationService: GungNotificationService,
    protected orderPortalChannelService: OrderPortalChannelService,
    protected router: Router
  ) {}

  // getAllowedResendChannels finds all channels that are allowed to resend an order context for.
  // Some order channels should not be allowed to resend an order context, such as EDI flows that can cause issues.
  // Those should for example be handled in the EDI module.
  protected getAllowedResendChannels(): Set<string> {
    const result = new Set<string>();
    result.add('GUNG_WEB');
    result.add('GUNG_WEB_RETURN');
    result.add('GUNG_WEB_OFFER');
    return result;
  }

  getButtons(
    item: OrderPortalEntry,
    eventEmitter?: EventEmitter<OrderPortalEntry>
  ): Observable<ActionButton<OrderPortalEntry>[]> {
    return this.authService.getRoles().pipe(
      first(),
      switchMap(roles => {
        const baseActions: ActionButton<OrderPortalEntry>[] = [];

        const originalInputRoute = this.orderPortalChannelService.getOriginalInputRoute(item, item.originalInputId);
        if (!!originalInputRoute) {
          baseActions.push(this.routeToOriginalInputAction());
        }

        const isAllowedToResend = this.getAllowedResendChannels().has(item.orderChannel);
        if (isAllowedToResend && !item.finishedSuccessfully) {
          baseActions.push(this.getResendOrderContextAction());
        }

        const isActuator = roles.includes('ACTUATOR');
        if (isActuator) {
          baseActions.push(this.getOpenJsonAction());
        }

        return of(baseActions);
      })
    );
  }

  private getOpenJsonAction(): ActionButton<OrderPortalEntry> {
    return {
      title: 'OPEN_JSON',
      action: (item: OrderPortalEntry) => {
        const url = `${this.backendInterceptor.getBaseUrl()}json/order-contexts/${item.orderContextId}`;
        window.open(url, '_blank');
      }
    };
  }

  private routeToOriginalInputAction(): ActionButton<OrderPortalEntry> {
    return {
      title: 'GO_TO_EDI_MESSAGE',
      action: (item: OrderPortalEntry) => {
        this.router.navigateByUrl(this.orderPortalChannelService.getOriginalInputRoute(item, item.originalInputId));
      }
    };
  }

  private getResendOrderContextAction(): ActionButton<OrderPortalEntry> {
    return {
      title: 'RESEND_ORDER_CONTEXT',
      action: (item: OrderPortalEntry) => {
        this.gungModalService
          .openConfirmYesNoModal(
            this.translationService.instant('RESEND_ORDERCONTEXT_CONFIRM'),
            this.translationService.instant('RESEND_ORDERCONTEXT_CONFIRM_LONG'),
            {
              size: 'md'
            }
          )
          .then(
            result => {
              if (result) {
                this.orderContextService
                  .resendOrderContext(item.orderContextId)
                  .pipe(first())
                  .subscribe(context => {
                    const title = this.translationService.instant('RESEND_ORDERCONTEXT_SUCCESS');
                    const body = this.translationService.instant('RESEND_ORDERCONTEXT_SUCCESS_BODY', {
                      id: context.id
                    });
                    this.gungNotificationService.notify(title, body, 'success');
                  });
              }
            },
            reason => {}
          );
      }
    };
  }
}
