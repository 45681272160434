import { Injectable } from '@angular/core';
import { CartListCheckoutConfigService, CartListColumns } from 'gung-standard';

@Injectable({
  providedIn: 'root'
})
export class JeevesCartListCheckoutConfigService extends CartListCheckoutConfigService {
  enableGlobalDeliveryDate = true;

  public enableSalesEditPrice = true;
  changePriceField = 'orp.vb_pris';

  public enableSalesEditDiscount = true;
  changeDiscountField = 'orp.rabatt1';

  public userCanChangeDeliveryDates = true;
  deliveryDateField = 'orp.ordberlevdat';

  public userCanAddDescription = false;
  rowDescriptionField = '';

  unitField = "Article.Unit";

  public enableCopyRow = false;

  enableAddRow = false;

  /* with only work full screen desktop */
  public cartListColumns:CartListColumns = {
    image:{
      name: '',
      styles:{
        width:"10%"
      },
      show: true,
      showToUser: true
    },
    name:{
      name: 'name',
      styles:{
        width:"30%"
      },
      show:true,
      showToUser: true
    },
    qty:{
      name: 'QUANTITY',
      styles:{
        width:"13%",
        padding:"0 0.5% 0 0.5%"
      },
      show:true,
      showToUser: true
    },
    unit:{
      name: 'UNIT',
      styles:{
        width:"3%",
        padding:"0 0.5% 0 0.5%"
      },
      show:false,
      showToUser: false
    },
    unitPrice:{
      name: 'UNIT_PRICE',
      styles:{
        width:"10%",
        padding:"0 0.5% 0 0.5%"
      },
      show:true,
      showToUser: true
    },
    discount:{
      name: 'DISCOUNT',
      styles:{
        width:"7%",
        padding:"0 0.5% 0 0.5%"
      },
      show:true,
      showToUser: false 
    },
    total:{
      name: 'TOTAL_PRICE',
      styles:{
        width:"12%",
        padding:"0 0.5% 0 0.5%"
      },
      show:true,
      showToUser: true
    },
    deliveryDate:{
      name: 'DeliveryDate',
      styles:{
        width:"15%",
        padding:"0 0.5% 0 0.5%"
      },
      show:true,
      showToUser: true
    },
    actions:{
      name: '',
      styles:{
        width:"7%",
        padding:"0 0.5% 0 0.5%"
      },
      show:true,
      showToUser: true
    }
  }

  transformPriceToERP(price: any): number | string {
    return parseFloat(price).toFixed(2);
  }
}
