import { Component, OnInit } from '@angular/core';
import { ProductionOrderService } from '../../services/production-order/production-order.service';
import { ChangeDateEvent, DateUtilService, GungCalendarItem } from 'gung-common';
import { map, first } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-production-order-gung-calendar',
  templateUrl: './production-order-gung-calendar.component.html',
  styleUrls: ['./production-order-gung-calendar.component.css']
})
export class ProductionOrderGungCalendarComponent implements OnInit {
  calendarItemsList: GungCalendarItem[] = [];

  constructor(
    protected productionOrderService: ProductionOrderService,
    protected router: Router,
    protected dateUtilService: DateUtilService
  ) { }

  ngOnInit(): void {
    this.productionOrderService.getProductionOrders().pipe(
      map(result =>
        result.sort((a, b) => {
          if (new Date(a.extra?.ProductionOrder?.startDate) > new Date(b.extra?.ProductionOrder?.startDate)) {
            return 1;
          }
          if (new Date(a.extra?.ProductionOrder?.startDate) < new Date(b.extra?.ProductionOrder?.startDate)) {
            return -1;
          }
          return 0;
        })
      )
    ).subscribe(res => {
      this.calendarItemsList = [];
      for (const productionOrder of res) {
        this.calendarItemsList.push({
          id: productionOrder.id,
          date: new Date(productionOrder.extra?.ProductionOrder?.startDate),
          heading: `${productionOrder.extra?.ProductionOrder?.id} - ${productionOrder.extra?.ProductionOrder?.itemDescription}`,
          action: () => this.router.navigate(['/production-orders/' + productionOrder.id])
        });
      }
    });
  }

  createProductionOrder(selectedDate: Date) {
    
  }

  changeProductionOrderDate(event: ChangeDateEvent) {
    this.productionOrderService.getProductionOrder(event.id).pipe(first()).subscribe(productionOrder => {
      productionOrder.extra.ProductionOrder.startDate = this.dateUtilService.getFormattedDateString(event.date);
      this.productionOrderService.postProductionOrder(productionOrder).pipe(first()).subscribe(_ => {});
    });
  }
}