<div *ngIf="data && loaded; else loading" class="table-responsive gung-responsive">
  <table class="table">
    <thead>
    <tr>
      <th class="p-0 pr-2"></th>
      <th translate>REPORT</th>
      <th translate>STATUS</th>
      <th class="text-right" translate>LINKS</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let r of data">
      <td class="p-0 border-0" [ngClass]="{
          'failed': keyedReports[r.id].status === ReportCentralReportStatus.ERROR,
          'finished': keyedReports[r.id].status === ReportCentralReportStatus.FINISHED,
          'in-progress': keyedReports[r.id].status === ReportCentralReportStatus.PROCESSING
        }"></td>

      <td>
        <span class="small font-italic">{{keyedReports[r.id].userId}} - {{keyedReports[r.id].createdAt | date: 'yyyy-MM-dd HH:mm:ss'}}</span> <br/>
        <span class="description">{{keyedReports[r.id].description}}</span>
      </td>

      <td>
        <span translate>{{keyedReports[r.id].status}} <span class="small font-italic" *ngIf="keyedReports[r.id].finishedAt">({{keyedReports[r.id].finishedAt | date: 'yyyy-MM-dd HH:mm:ss'}})</span></span>
      </td>

      <td class="text-right toggle-switch-column">
        <ng-container *ngIf="keyedReports[r.id].status === ReportCentralReportStatus.PROCESSING">
          <i style="font-size: 22px;" class="fas fa-spinner fa-spin"></i>
        </ng-container>

        <ng-container *ngIf="keyedReports[r.id].status !== ReportCentralReportStatus.PROCESSING">
          <span *ngFor="let url of keyedReports[r.id].urls"><a [href]="url.url">{{url.name}}</a> <br/></span>
        </ng-container>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
  LOADING...
</ng-template>
