import { Component } from '@angular/core';
import { ProductListByIdsComponent } from '../product-list-by-ids/product-list-by-ids.component';
import { ProductListByIdsConfigService } from '../../services/product-list-by-ids-config/product-list-by-ids-config.service';

@Component({
  selector: 'lib-simple-product-list-by-ids',
  template: '<lib-simple-list #productList [configService]="configService"></lib-simple-list>',
  styles: []
})
export class SimpleProductListByIdsComponent extends ProductListByIdsComponent {}
