import { Injectable } from '@angular/core';
import { SearchRequest } from 'gung-list';
import { Observable, first, mergeMap, forkJoin, of, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { BaseViewConfigService } from '../base-view-config/base-view-config.service';
import { PriceService } from '../price/price.service';
import { ProductAssortmentParentService } from '../product-assortment-parent/product-assortment-parent.service';
import { ProductInlineSearchListConfigService } from '../product-inline-search-list-config/product-inline-search-list-config.service';
import { ProductService } from '../products/product.service';

@Injectable({
  providedIn: 'root'
})
export class ProductInlineAndCategoriesSearchListConfigService extends ProductInlineSearchListConfigService {
  skuLevel = true;

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService
  ) {
    super(productService, authService, priceService, baseViewConfigService);
  }

  getItems(terms: string[], skip: number, limit: number): Observable<any> {
    return this.authService.getCurrentUser().pipe(
      first(),
      mergeMap(user => {
        const searchRequestProducts: SearchRequest = {
          terms,
          skip,
          limit,
          assortment: user.assortment,
          skuLevel: true,
          requireAvailability: this.requireAvailability
        };

        const searchRequestConcepts: SearchRequest = {
          terms,
          skip,
          limit,
          assortment: user.assortment,
          skuLevel: false,
          requireAvailability: this.requireAvailability
        };

        return forkJoin([
          this.productService.getPagedProducts(searchRequestProducts).pipe(first()),
          this.productService.getPagedProducts(searchRequestConcepts).pipe(first()),
          // this.productAssortmentParentService.getSearchAssortments(terms[0]).pipe(first())
        ]);
      }),
      mergeMap(([products, concepts]) => {
        concepts.items = concepts.items.filter(c => c.productType !== 'singleDimension')
        return forkJoin([
          of(products),
          of(concepts),
          this.priceService
            .getCurrentCustomerPrices(products.items.filter(p => p.productType !== 'concept').map(p => p.id))
            .pipe(first())
        ]);
      }),
      switchMap(([products, concepts, prices]) => {
        products.items.map(p => (p.extra.price = prices.find(pr => pr.productId === p.id)));
        return of([products, concepts]);
      })
    );
  }
}
