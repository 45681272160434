<div *ngIf="data; else loading" class="table-responsive gung-responsive">
  <table class="table">
    <thead>
      <tr>
        <th><span translate>ID</span>/<span translate>STATUS</span></th>
        <th translate>ORDER_ID</th>
        <th translate>DATE_CREATED</th>
        <th translate>CUSTOMER</th>
        <th translate>ASSIGN_TO</th>
        <th style="min-width: 50px"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of data">
      <tr>
        <td [attr.data-label]="('ID' | translate) + '/' + ('STATUS' | translate)" (click)="openViewModal(item)" class="cursor-pointer border-bottom-0">
          <button type="button" class="btn btn-link d-block text-center m-0 p-0 w-100">{{ item.id }}</button>
          <span
            class="badge badge-pill badge badge-ligh w-100"
            [style.background-color]="claimStatusColor(item.status)"
            translate
            >{{ item.status }}</span
          >
        </td>
        <td class="border-bottom-0" [attr.data-label]="'ORDER_ID' | translate">
          {{ item.orderId }}
        </td>
        <td class="border-bottom-0" [attr.data-label]="'DATE_CREATED' | translate">
          {{ item.date | date: dateUtilService.dateFormat }}
        </td>
        <td class="border-bottom-0" [attr.data-label]="'CUSTOMER' | translate">
          <b>{{ item.customerId }}</b>
          <br>{{ item.customerName }}</td>
        <td [attr.data-label]="'ASSIGN_TO' | translate" class="border-bottom-0">
          {{ item.assignTo }}
        </td>
        <td [attr.data-label]="''" class="text-nowrap border-bottom-0">
          <button *ngIf="isAdmin && !customerView" type="button" class="btn btn-sm btn-outline-primary mr-2" (click)="openViewModal(item)">
            <i class="fas fa-edit"></i>
          </button>
          <button *ngIf="isAdmin && !customerView" type="button" class="btn btn-sm btn-danger mr-2" (click)="deleteOrderServices(item)">
            <i class="fas fa-trash"></i>
          </button>
          <button *ngIf="isAdmin && !customerView" type="button" [disabled]="!!item.orderId" class="btn btn-sm btn-outline-primary mr-2" title="{{'CREATE_RETURN_ORDER_IN_ERP' | translate}}" (click)="createReturnOrder(item)">
            <i class="fa-solid fa-file-invoice"></i>
          </button>
          <button *ngIf="(isUser && !isAdmin) || customerView" type="button" class="btn btn-sm btn-outline-primary mr-2" (click)="openViewModal(item)">
            <i class="fa-solid fa-eye"></i>
          </button>
        </td>
      </tr>
      <tr *ngIf="item.comments.length > 0 && item.comments[item.comments.length - 1].comment">
        <td></td>
        <td colspan="6" [attr.data-label]="'NOTE' | translate" class="text-truncate">
          <span translate class="font-weight-bold">NOTE</span>: {{ item.comments[item.comments.length - 1].comment }}
        </td>
      </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
  LOADING...
</ng-template>
