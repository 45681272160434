import { Injectable } from '@angular/core';
import { Observable, first, map, of } from 'rxjs';
import { ProductExportService } from './product-export/product-export.service';
import { AuthService } from './auth/auth.service';
import { ExportSelection, SelectionAction } from 'gung-list';
import { Product } from '../models';
import { SelectProductImageExportSizeModalComponent } from '../components/select-product-image-export-size-modal/select-product-image-export-size-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';



@Injectable({
  providedIn: 'root'
})
export class UploadItemsFromExcelConfigService {
  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();
  constructor(
    protected productExportService: ProductExportService,
    protected authService: AuthService,
    protected modalService: NgbModal
    ) { 
  }

  getSelectionActions(): Observable<SelectionAction<Product>[]> {
    return this.authService.getRoles().pipe(
      first(),
      map(roles => {
        let userActions: SelectionAction<Product>[] = [
          {
            label: 'PRODUCT_EXPORT_XLSX',
            performAction: (selection: ExportSelection<Product>) => {
              const itemList = Object.values(selection);
              this.productExportService.exportExcel(itemList).pipe(first()).subscribe();
              return of();
            }
          },
          {
            label: 'PRODUCT_EXPORT_IMG',
            performAction: (selection: ExportSelection<Product>) => {
              const itemList = Object.values(selection);
              this.productExportService.exportImg(itemList).pipe(first()).subscribe();
              return of();
            }
          }
        ];
        if (roles.includes('SALES') || roles.includes('ADMIN')) {
          userActions = [
            {
              label: 'PRODUCT_EXPORT_XLSX',
              performAction: (selection: ExportSelection<Product>) => {
                const itemList = Object.values(selection);
                this.productExportService.exportExcel(itemList).pipe(first()).subscribe();
                return of();
              }
            },
            {
              label: 'PRODUCT_EXPORT_CSV',
              performAction: (selection: ExportSelection<Product>) => {
                const itemList = Object.values(selection);
                this.productExportService.exportCsv(itemList).pipe(first()).subscribe();
                return of();
              }
            },
            {
              label: 'PRODUCT_EXPORT_LINESHEET',
              performAction: (selection: ExportSelection<Product>) => {
                const itemList = Object.values(selection);
                this.productExportService.exportLinesheet(itemList).pipe(first()).subscribe();
                return of();
              }
            },
            {
              label: 'PRODUCT_EXPORT_PRICAT',
              performAction: (selection: ExportSelection<Product>) => {
                const itemList = Object.values(selection);
                this.productExportService.exportPricat(itemList).pipe(first()).subscribe();
                return of();
              }
            },
            {
              label: 'PRODUCT_EXPORT_IMG',
              performAction: (selection: ExportSelection<Product>) => {
                const itemList = Object.values(selection);
                const id = SelectProductImageExportSizeModalComponent.name;
                const ref = this.modalService.open(SelectProductImageExportSizeModalComponent, {
                  size: 'md',
                  windowClass: 'modal-md-custom'
                });
                ref.componentInstance.items = itemList;
                ref.result.then(
                  result => {
                    if (result.success) {
                      this.productExportService
                        .exportImg(itemList, { imageTransformations: result.imageTransformations })
                        .pipe(first())
                        .subscribe();
                    }
                  },
                  reason => {
                    console.log(reason);
                  }
                );
                this.modalRef.set(id, ref);
                return of();
              }
            },
            {
              label: 'PRODUCT_EXPORT_PRODUCT_INFO',
              performAction: (selection: ExportSelection<Product>) => {
                const itemList = Object.values(selection);
                this.productExportService.exportProductInfo(itemList).pipe(first()).subscribe();
                return of();
              }
            },
            {
              label: 'PRODUCT_EXPORT_PRODUCT_STOCK',
              performAction: (selection: ExportSelection<Product>) => {
                const itemList = Object.values(selection);
                this.productExportService.exportProductStock(itemList).pipe(first()).subscribe();
                return of();
              }
            }
          ];
        }
        return userActions;
      })
    );
  }
}
  