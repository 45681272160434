import { Injectable } from '@angular/core';
import { DynamicPdfService, ExportPdfOrderProductListConfigService, PdfProductService } from 'gung-dynamic-pdf';
import { ListLayout } from 'gung-list';
import { ProductListConfigService } from '../product-list-config/product-list-config.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicPdfOrderProductListConfigService extends ExportPdfOrderProductListConfigService {

  constructor(
    protected productService: PdfProductService, 
    protected dynamicPdfService: DynamicPdfService,
    protected productListService: ProductListConfigService
  ) {

    super(productService, dynamicPdfService);
  }

  getLayouts(): ListLayout<any>[] {
    return this.productListService.getLayouts();
  }

  
}
