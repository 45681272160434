import { Injectable } from '@angular/core';
import { Invoice } from 'gung-standard';
import { Observable } from 'rxjs';
import { ActionButton, ActionButtonConfigService } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class JeevesInvoiceActionConfigService implements ActionButtonConfigService<Invoice> {
  base = [
    // { title: 'PDF', action: (item: Invoice) => this.backendRerouteService.reroute('download/order-pdf/' + item.id) }
  ];

  constructor() // protected backendRerouteService: BackendRerouteService,
  {}

  getButtons(item: Invoice): Observable<ActionButton<Invoice>[]> {
    return new Observable<ActionButton<Invoice>[]>(subscriber => {
      subscriber.next(this.base);
      subscriber.complete();
    });
  }
}
