import { Injectable } from '@angular/core';
import { InvoiceStatistic, InvoiceStatisticSubset } from '../types';
import { ConfigService, ConfigBaseFilter, ListLayout, ListSortOption, ListLayoutMultipleComponent } from 'gung-list';
import { Observable } from 'rxjs';
import { InvoiceFilteredStatisticsComponent } from '../components/invoice-filtered-statistics/invoice-filtered-statistics.component';
import { OurReferenceFilterService } from './filters/our-reference-filter.service';
import { InvoiceCustomerFilterService } from './filters/invoice-customer-filter.service';
import { InvoiceStatisticsService } from './invoice-statistics.service';
import { subYears } from 'date-fns';
import { map } from 'rxjs';
import { isBefore, parse } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class InvoiceFilteredStatisticsConfigService implements ConfigService<InvoiceStatisticSubset> {
  topFilter = true;

  constructor(private invoiceStatisticsService: InvoiceStatisticsService<InvoiceStatisticSubset>) {}

  getItems(): Observable<InvoiceStatisticSubset[]> {
    const afterThisDate = subYears(new Date(), 2);
    return this.invoiceStatisticsService.getInvoices().pipe(
      map(invoices => {
        return invoices.filter(invoice =>
          isBefore(afterThisDate, parse(invoice.invoiceCreationDateYearMonth, 'yyyy-MM', new Date()))
        );
      })
    );
  }

  getFilters(): ConfigBaseFilter<InvoiceStatisticSubset>[] {
    return [new OurReferenceFilterService(), new InvoiceCustomerFilterService()];
  }

  getSortOptions(): ListSortOption<InvoiceStatisticSubset>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [999999];
  }

  getLayouts(): ListLayout<InvoiceStatisticSubset>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => InvoiceFilteredStatisticsComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Invoice grid'
      }
    ];
  }

  getSearchGroupCss(): string {
    return 'hide-search-keep-current-filter';
  }

  getFilterGroupCss(): string {
    return 'top-filter-search-hidden';
  }

  getSearchTerms(item: InvoiceStatisticSubset): string[] {
    const result: string[] = [];
    if (Boolean(item.productId)) {
      result.push(item.productId);
    }
    if (Boolean(item.productName)) {
      result.push(item.productName);
    }
    if (Boolean(item.customerName)) {
      result.push(item.customerName);
    }
    if (Boolean(item.customerId)) {
      result.push(item.customerId);
    }
    if (Boolean(item.invoiceOurReference)) {
      result.push(item.invoiceOurReference);
    }
    return result;
  }

  getItemId(item: InvoiceStatistic) {
    return item.id;
  }

  hideZeroOptions(): boolean {
    return true;
  }
}
