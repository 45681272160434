import { Injectable } from '@angular/core';
import { ConfigService, ConfigBaseFilter, ListSortOption, ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { Observable, map } from 'rxjs';
import { EdiDespatchAdvice } from '../../models/edi';
import { BaseViewConfigService } from '../base-view-config/base-view-config.service';
import { EdiDispatchDateFilter } from '../edi-dispatch-advices-list-config/edi-dispatch-advices-list-config.service';
import {
  ValidationFilter,
  InvoiceStatusFilter,
  ConfigIdFilter
} from '../edi-order-list-config/edi-order-list-config.service';
import { EdiService } from '../edi/edi.service';

@Injectable({
  providedIn: 'root'
})
export class EdiIncomingDespatchAdvicesListConfigService implements ConfigService<EdiDespatchAdvice> {
  constructor(protected ediService: EdiService, protected baseViewConfigService: BaseViewConfigService) {}

  getItems(): Observable<EdiDespatchAdvice[]> {
    return this.ediService.getIncomingDespatchAdvices().pipe(
      map(data =>
        data.reverse().sort((a, b) => {
          const aSort =
            a?.timestamp && new Date(a.timestamp);
          const bSort =
            b?.timestamp && new Date(b.timestamp);
          if ((!bSort && aSort) || (aSort && bSort && aSort > bSort)) {
            return -1;
          } else if ((!aSort && bSort) || (aSort && bSort && aSort < bSort)) {
            return 1;
          } else {
            return 0;
          }
        })
      )
    );
  }

  getFilters(): ConfigBaseFilter<EdiDespatchAdvice>[] {
    return [new ValidationFilter(), new InvoiceStatusFilter(), new ConfigIdFilter(), new EdiDispatchDateFilter()];
  }

  getSortOptions(): ListSortOption<EdiDespatchAdvice>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<EdiDespatchAdvice>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => this.baseViewConfigService.getEdiIncomingDespatchCardListComponent(),
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Edi dispatch advices grid'
      }
    ];
  }

  getSearchTerms(item: EdiDespatchAdvice): string[] {
    const generalSearchTerms = [
      item.documentReferences?.supplierOrder?.id,
      item.documentReferences?.buyerOrder?.id,
      item.documentReferences?.invoice?.id,
      item.documentReferences?.invoiceOcr?.id,
      item.documentReferences?.despatchAdvice?.id,
      item.documentReferences?.parcel?.id
    ].filter(s => !!s);
    return [item.id, item.consigneeGln?.toString(), item.buyerGln?.toString()].concat(generalSearchTerms);
  }

  getItemId(item: EdiDespatchAdvice): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
