import { Component, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { GungBase } from 'gung-standard';
import { JeevesModalService } from '../../services/jeeves-modal-service/jeeves-modal.service';

@Component({
  selector: 'gung-jeeves-customer-delivery-location-cart-list',
  templateUrl: './jeeves-customer-delivery-location-cart-list.component.html',
  styleUrls: ['./jeeves-customer-delivery-location-cart-list.component.css']
})
export class JeevesCustomerDeliveryLocationCartListComponent extends ListItemRendererComponent<GungBase> implements AfterViewInit {
  constructor(protected jeevesModalService: JeevesModalService) {
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  openModalCreate(deliveryLocation: GungBase) {
    const newDeliveryLocation = {
      ...deliveryLocation,
      id: null,
      extra: {
        ...deliveryLocation.extra,
        fr: {}
      }
    };
    this.jeevesModalService.openCustomerDeliveryLocationModal(newDeliveryLocation);
  }

  openModalEdit(deliveryLocation: GungBase) {
    this.jeevesModalService.openCustomerDeliveryLocationModal(deliveryLocation);
  }
}
