import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardDataService {
  private messageSource = new BehaviorSubject(null);
  currentMessage = this.messageSource.asObservable();

  constructor() {
    this.messageSource.next(null);
  }

  public selectedSalesPerson: string;

  setFilter(message: any) {
    this.messageSource.next(message);
  }
}
