import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigFilterGroup,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption
} from 'gung-list';
import { ProductRequirements, RequirementsPlanningOrder } from '../models/product-requirements';
import { Observable, of } from 'rxjs';
import { ProductionManagementMrpComponent } from '../components/production-management-mrp/production-management-mrp.component';
import { ProductionManagementMrpService } from './production-management-mrp.service';

@Injectable({
  providedIn: 'root'
})
export class ProductionManagementMrpListConfigService implements ConfigService<ProductRequirements> {
  constructor(protected mrpService: ProductionManagementMrpService) {}

  topFilter = true;

  getItems(): Observable<ProductRequirements[]> {
    return this.mrpService.runRequirementsPlanning();
  }

  getLayouts(): ListLayout<ProductRequirements>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ProductionManagementMrpComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Table'
      }
    ];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getFilters(): ConfigBaseFilter<ProductRequirements>[] {
    return [];
  }

  getItemId(item: ProductRequirements): string {
    return item.productId;
  }

  getSearchTerms(item: ProductRequirements): string[] {
    return [item.productId];
  }

  getSortOptions(): ListSortOption<any>[] {
    return [];
  }
}
