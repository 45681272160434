import { Component } from '@angular/core';
import { ProductionManagementMrpListConfigService } from '../../services/production-management-mrp-list-config.service';

@Component({
  selector: 'lib-production-management-mrp-list',
  template: '<lib-filter-list #list [configService]="configService"></lib-filter-list>',
  styleUrls: ['./production-management-mrp-list.component.css']
})
export class ProductionManagementMrpListComponent {
  constructor(public configService: ProductionManagementMrpListConfigService) {}
}
