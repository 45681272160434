import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, first, map, switchMap } from 'rxjs';
import { ExportProductSelectionDataType, ProductExportService } from '../../../services/product-export/product-export.service';
import { Order } from '../../../models/order';
import { Product } from '../../../models/product';

@Injectable({
  providedIn: 'root'
})
export class ProductionManagementOrderExportService {
  // Jack in the product export service for now - incorrect and just temp to test - TODO refactor
  constructor(protected http: HttpClient, protected productExportService: ProductExportService) {}

  exportOrders(itemList: Order[], template?: string, additionalVariables?: {}, additionalParams?: {}): Observable<any> {
    const url = 'generate-order-pdf-from-order-ids'; 

    return this.productExportService
      .getExportRequestBodyByType(
        ExportProductSelectionDataType.ProductInfoV3,
        itemList as any as Product[],
        template,
        additionalVariables,
        additionalParams
      )
      .pipe(
        first(),
        map(data => {
          return { isValid: true, body: data };
        }),
        switchMap(data => {
          return this.http.post('download/' + url, data.body, { responseType: 'blob', observe: 'response' });
        })
      );
  }
}