import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CommonModalService } from 'gung-common';
import { Availability, GungModalService, Product, SupplierPurchaseOrder } from 'gung-standard';
import { JeevesSupplierPoSplitModalComponent } from '../../components/supplier/jeeves-supplier-po-split-modal/jeeves-supplier-po-split-modal.component';
import { JeevesAvailabilityModalComponent } from '../../components/jeeves-availability-modal/jeeves-availability-modal.component';

@Injectable({
  providedIn: 'root'
})
export class JeevesGungModalService extends GungModalService {

  constructor(protected modalService: NgbModal) {
    super(modalService);
  }

  openSupplierPurchaseOrderSplitModal(supplierPurchaseOrder: SupplierPurchaseOrder) {
    const id = JeevesSupplierPoSplitModalComponent.name;

    const ref = this.modalService.open(JeevesSupplierPoSplitModalComponent, {
      size: 'xl'
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.supplierPurchaseOrder = supplierPurchaseOrder;
    this.modalRef.set(id, ref);
    return ref.result;
  }

  openAvailabilityModal(availability: Availability, modalOption?: NgbModalOptions, product?: Product): Promise<any> {
    const option: NgbModalOptions = {
      backdrop: true,
      keyboard: true,
      centered: true,
      ...modalOption
    };

    const ref = this.ngbModal.open(JeevesAvailabilityModalComponent, option);
    const componentInstance = ref.componentInstance as JeevesAvailabilityModalComponent;
    componentInstance.availability = availability;
    componentInstance.product = product;

    return ref.result;
  }
}
