<div class="card mb-2">
  <div class="card-body">
    <div class="card-title row">
      <div class="col">
        <h5>
          {{ getDisplayStartDate(data.extra.gungData.start)
          }}<span *ngIf="!!data.extra.gungData.Status">
            - {{ getMetadataValue('TA54', data.extra.gungData.Status) }}</span
          >
        </h5>
      </div>
      <div class="col-auto">
        <button class="btn btn-sm btn-outline-primary m-1 action-btn" (click)="editActivity(data)" translate>
          EDIT
        </button>
        <button class="btn btn-sm btn-outline-secondary m-1 action-btn" (click)="removeActivity(data)" translate>
          REMOVE
        </button>
      </div>
    </div>
    <div class="card-text">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4">
          <lib-gung-text-input
            [gungTranslate]="'RESPONSIBLE'"
            [renderStatic]="true"
            [ngModel]="getMetadataValue('TA04', data.extra.gungData.Ansvarig) || data.extra.gungData.Ansvarig || ''"
          >
          </lib-gung-text-input>
        </div>

        <div class="col-12 col-sm-6 col-md-4">
          <lib-gung-text-input
            [gungTranslate]="'CUSTOMER_ACTIVITY_CONTACT'"
            [renderStatic]="true"
            [ngModel]="data.extra.gungData.Kontaktperson || ''"
          >
          </lib-gung-text-input>
        </div>

        <div class="col-12 col-sm-6 col-md-4">
          <lib-gung-text-input
            [gungTranslate]="'ACTIVITY_TYPE'"
            [renderStatic]="true"
            [ngModel]="getMetadataValue('TA51', data.extra.gungData.Aktivitet) || data.extra.gungData.Aktivitet || ''"
          >
          </lib-gung-text-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label for="_text" class="font-weight-bold" translate>CUSTOMER_ACTIVITY_TEXT</label>
          <div>
            {{ data.extra.gungData._text || '' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
