<div class="edi-invoice" *ngIf="orderResponseData">
  <div class="invoice-header">
    <div class="header-title">
      <h3>
        <span translate="ORDER_RESPONSE"></span>: {{ orderResponseData.id }}
        <span style="float: right"><lib-edi-send-status [message]="orderResponseData"></lib-edi-send-status></span>
      </h3>
    </div>
    <!-- TMP REMOVED UNTIL WE'VE IMPLEMENTED ACTIONS FOR INCOMING ORDER RESPONSES
    <div class="row">
      <div class="col">
          <lib-action-btn class="float-right mb-2" [(item)]="orderResponseData" [config]="actionConfig"></lib-action-btn>
        </div>
      </div>
    -->
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label" for="id" translate>SUPPLIER_ORDER_NUMBER</label>
              <input
                class="form-control"
                name="id"
                type="text"
                [(ngModel)]="orderResponseData?.documentReferences.supplierOrder.id"
                [disabled]="configService.disable.customerNumber"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label" for="buyer-order-number" translate>BUYER_ORDER_NUMBER</label>
              <input
                class="form-control"
                name="buyer-order-number"
                type="text"
                [(ngModel)]="orderResponseData?.documentReferences?.buyerOrder.id"
                [disabled]="configService.disable.supplierOrderNumber"
              />
              <a [routerLink]="['/admin/edi/purchased-orders', orderResponseData?.documentReferences?.buyerOrder.id]">
                <span translate>GO_TO_PURCHASE_ORDER_MESSAGE</span>
              </a>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label" for="order-date" translate>ORDER_DATE</label>
              <input
                class="form-control"
                name="order-date"
                type="text"
                [(ngModel)]="orderResponseData?.documentReferences.supplierOrder.date"
                [disabled]="configService.disable.orderDate"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label" for="order-type" translate>ORDER_TYPE</label>
              <input
                class="form-control"
                name="order-type"
                type="text"
                [(ngModel)]="orderResponseData.orderType"
                [disabled]="configService.disable.orderType"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label" for="delivery-date" translate>DELIVERY_DATE</label>
              <input
                class="form-control"
                name="delivery-date"
                type="text"
                [(ngModel)]="orderResponseData.deliveryDate"
                [disabled]="configService.disable.deliveryDate"
              />
            </div>
          </div>
          <div class="col-md-3" *ngIf="!!orderResponseData.timestamp">
            <div class="form-group">
              <label class="control-label" for="delivery-date" translate>TIMESTAMP</label>
              <input
                class="form-control"
                name="timestamp"
                type="text"
                [(ngModel)]="orderResponseData.timestamp"
                [disabled]="true"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label" for="order-status" translate>GUNG_EDI_MESSAGE_SEND_STATUS</label>
              <input
                class="form-control"
                name="order-status"
                type="text"
                [(ngModel)]="orderResponseData.gungEdiMessageSendStatus"
                [disabled]="configService.disable.orderStatus"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12" *ngIf="orderResponseData.supplierGln || orderResponseData.senderGln || orderResponseData.consigneeGln || orderResponseData.deliveryGln || orderResponseData.buyerGln || orderResponseData.recipientGln || orderResponseData.invoiceeGln">
        <lib-edi-gln-number-tabs [ediMessage]="orderResponseData"></lib-edi-gln-number-tabs>
      </div>
    </div>
  </div>
  <lib-edi-detail-error-display [ediMessage]="orderResponseData"></lib-edi-detail-error-display>
  <div class="invoice-itens">
    <h3 class="header-title" translate>ORDER_LINES</h3>
    <!--<lib-edi-row-table [data]="orderResponseData.items" [columns]="itemsColumns"></lib-edi-row-table>-->
    <div class="col-12 table-responsive gung-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th translate>PRODUCT</th>
            <th translate>QUANTITY</th>
            <th translate>PRICE</th>
            <th translate>DELIVERY_DATE</th>
          </tr>
        </thead>
        <tbody *ngFor="let item of orderResponseData.items; let idx = index">
          <tr [class.bg-cancelled]="item.changes.cancelled || item.changes.ignored">
            <td [attr.data-label]="'ITEM ID' | translate">
              <div *ngIf="item.name" class="row">
                <label class="col-4">NAME: </label><b>{{ item.name }}</b>
              </div>
              <div *ngIf="item.ids.gungId" class="row">
                <label class="col-4">ID: </label><b>{{ item.ids.gungId }}</b>
              </div>
              <div *ngIf="item.ids.ean13" class="row">
                <label class="col-4">EAN13: </label>{{ item.ids.ean13 }}
              </div>
              <div *ngIf="item.ids.upca" class="row">
                <label class="col-4">UPCA: </label>{{ item.ids.upca }}
              </div>
            </td>
            <td [attr.data-label]="'QUANTITY' | translate">
              <div *ngIf="item.quantities.expectedQuantity" class="row">
                <label class="col-4">EXPECTED:&nbsp;</label><b>{{ item.quantities.expectedQuantity }}</b>
              </div>
              <div *ngIf="item.quantities.actualQuantity" class="row">
                <label class="col-4">ACTUAL:&nbsp;</label><b>{{ item.quantities.actualQuantity }}</b>
              </div>
            </td>
            <td [attr.data-label]="'PRICE' | translate">
              <div *ngIf="item.prices.expectedUnitNetPrice" class="row">
                <label class="col-4">EXPECTED:&nbsp;</label><b>{{ item.prices.expectedUnitNetPrice.value }}</b>
              </div>
              <div *ngIf="item.prices.actualUnitNetPrice" class="row">
                <label class="col-4">ACTUAL:&nbsp;</label><b>{{ item.prices.actualUnitNetPrice.value }}</b>
              </div>
            </td>
            <td [attr.data-label]="'DELIVERY_DATE' | translate">
              <div *ngIf="item.deliveryDate" class="row">
                <label class="col-4">EXPECTED:&nbsp;</label><b>{{ item.deliveryDate }}</b>
                <span *ngIf="item.changes.cancelled || item.changes.ignored" class="d-block">
                        <i class="fa-solid fa-triangle-exclamation text-danger mr-1"></i>
                        <span translate>CANCELED</span>
                      </span>
              </div>
              <div *ngIf="item.extra.originalPurchaseRowItem.deliveryDate" class="row">
                <label class="col-4">ACTUAL:&nbsp;</label><b>{{ item.extra.originalPurchaseRowItem.deliveryDate }}</b>
              </div>
            </td>
          </tr>
          <tr *ngIf="(item.errors | keyvalue).length > 0">
            <td colspan="5" style="border-left: 10px solid red; padding-left: 0px">
              <table class="table">
                <thead>
                  <tr>
                    <th class="col-2">Type</th>
                    <th class="col-6">Message</th>
                    <th class="col-2">Source</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let error of item.errors">
                    <td>
                      {{ error.type }}
                    </td>
                    <td>
                      {{ error.error }}
                    </td>
                    <td>
                      {{ error.validator }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="order-response-errors" *ngIf="orderResponseData.errors.length > 0">
    <h3 class="header-title" translate>ERROR</h3>
    <lib-edi-error-messages [errors]="orderResponseData.errors"></lib-edi-error-messages>
  </div>
</div>
