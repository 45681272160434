import { Component, Inject, OnDestroy, OnInit, Optional, Type, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, delay, filter, first, takeUntil } from 'rxjs';
import { AppState } from '../../state/state.module';
import { AuthService } from '../../services/auth/auth.service';
import { ProductService } from '../../services/products/product.service';
import { MetadataService } from '../../services/metadata/metadata.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { NavGroup, NavbarConfigService, UserAndRole } from '../../services/navbar-config/navbar-config.service';
import { NavbarComponent } from './../navbar/navbar.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { RouterEventsService } from '../../services/router-events/router-events.service';
import { LoadCart } from '../../state/cart/actions';
import { GungListRouterEventsService } from 'gung-list';
import { TranslateService } from '@ngx-translate/core';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { NavigationConfig } from '../../models/navigation-config';
import { GungLanguageConfigService } from 'gung-common';
import { BaseViewConfigService } from '../../services/base-view-config/base-view-config.service';
import { FooterComponent } from '../footer/footer.component';

export enum LoadingState {
  LOADING,
  VALID,
  INVALID
}

/**
* @deprecated write further comments here
*/
@Component({
  selector: 'lib-app-wrapper',
  templateUrl: './app-wrapper.component.html',
  styleUrls: ['./app-wrapper.component.css']
})
export class AppWrapperComponent implements OnInit, OnDestroy {
  mainMenu: NavGroup;
  marginTop: number;
  sideMenuExpanded = false;
  topMenuExpanded = false;
  devMode = localStorage.getItem('dev') || false;
  public navbarConfig;
  actuator = false;
  unsubscribe: Subject<boolean> = new Subject<boolean>();
  @ViewChild('navbar') navbar: NavbarComponent;
  @ViewChild('sidenav') sidenav: MatSidenav;
  cookiesEnabled: boolean = this.environment.enableCookies;
  public userInfo: UserAndRole;
  public footerComponent: Type<FooterComponent>;

  constructor(
    protected store: Store<AppState>,
    protected authService: AuthService,
    protected productService: ProductService,
    protected metadataService: MetadataService,
    protected googleAnalyticsService: GoogleAnalyticsService,
    protected navbarConfigService: NavbarConfigService,
    protected router: Router,
    protected routerEventsService: RouterEventsService,
    protected gungListRouterEventsService: GungListRouterEventsService,
    protected route: ActivatedRoute,
    protected translate: TranslateService,
    protected selectedCustomerService: SelectedCustomerService,
    protected gungLanguageConfigService: GungLanguageConfigService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig,
    protected baseViewConfigService: BaseViewConfigService
  ) {
    this.routerEventsService.setRouter(this.router);
    this.gungListRouterEventsService.setRouter(this.router);
    this.navbarConfig = navbarConfigService;
    this.gungLanguageConfigService.selectLanguage();

    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (!!this.sidenav) {
        this.sidenav.close();
      }
    });

    this.route.queryParams.pipe(filter(params => !!params?.language), delay(250)).subscribe(value => {
      let language = value['language'];
      if (!!language) {
        translate.use(language);
      }
    });
    this.footerComponent = this.baseViewConfigService.getFooterComponent();
  }

  // by default nav bar is set to be hidden
  // however if the user is authenticated then it is set to be visible
  hideNavbar = this.navbarConfigService.hideNavbar;
  isLoading = true;
  errorOccured = false;
  protected states: { [identifier: string]: LoadingState } = {};
  loginRequired = true;
  containerStyles: { [styleName: string]: any } = {};

  ngOnInit() {
    this.sideMenuExpanded = this.navbarConfigService.sideMenuExpanded;
    this.authService.getIsAuthenticated().subscribe(authenticated => {
      this.loginRequired = !authenticated;

      this.setHideNavbar(authenticated);

      if (!!authenticated) {
        this.navbarConfigService
          .getUserAndRole()
          .pipe(first())
          .subscribe(data => (this.userInfo = data));
        this.subscribeToState();
        this.selectedCustomerService
          .getSelectedCustomer()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(customer => {
            this.navbarConfigService
              .getMainMenu()
              .pipe(first())
              .subscribe(menu => (this.mainMenu = menu));
          });
        window.addEventListener('storage', this.cartListener, false);
      }

      this.updateAppState();
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
    window.removeEventListener('storage', this.cartListener, false);
  }

  protected subscribeToState() {
    this.subscribeCustomer();
    this.subscribeMetadata();
    this.subscribeFlow();
  }

  protected subscribeMetadata() {
    this.states.metadataLoaded = LoadingState.LOADING;
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe({
        next: roles => {
          this.actuator = roles.includes('ACTUATOR');
          if (roles.indexOf('ANONYMOUS') >= 0) {
            this.states.metadataLoaded = LoadingState.VALID;
            this.updateAppState();
          } else {
            this.metadataService.getMetadataCompleted().subscribe(_ => {
              this.states.metadataLoaded = LoadingState.VALID;
              this.updateAppState();
            });
          }
        },
        error: _ => {
          this.states.metadataLoaded = LoadingState.INVALID;
          this.updateAppState();
        }
      });
  }

  protected subscribeCustomer() {
    this.states.selectedCustomer = LoadingState.LOADING;
    this.store
      .select(state => state.customer)
      .subscribe({
        next: customerState => {
          if (customerState.doneLoading) {
            if (!!customerState.selectedCustomer) {
              this.states.selectedCustomer = LoadingState.VALID;
            } else {
              this.states.selectedCustomer = LoadingState.INVALID;
            }
          }

          this.updateAppState();
        },
        error: _ => {
          this.states.selectedCustomer = LoadingState.INVALID;
          this.updateAppState();
        }
      });
  }

  protected subscribeFlow() {
    this.states.selectedFlow = LoadingState.LOADING;
    this.store
      .select(state => state.gungFlow)
      .subscribe({
        next: gungFlowState => {
          if (gungFlowState.doneLoading) {
            if (!!gungFlowState.selectedFlow) {
              this.states.selectedFlow = LoadingState.VALID;
            } else {
              this.states.selectedFlow = LoadingState.INVALID;
            }
          }

          this.updateAppState();
        },
        error: _ => {
          this.states.selectedFlow = LoadingState.INVALID;
          this.updateAppState();
        }
      });
  }

  updateAppState() {
    const keys = Object.keys(this.states);

    const filteredKeys = keys.filter(key => {
      return this.states[key] === LoadingState.LOADING;
    });

    this.isLoading = filteredKeys.length !== 0;

    const invalidKeys = keys.filter(key => {
      return this.states[key] === LoadingState.INVALID;
    });
    const error = invalidKeys.length > 0;
    this.errorOccured = this.devMode ? false : error;
    if (error) {
      console.error(`Error in the following keys: ${invalidKeys}`);
    }
  }

  logout() {
    this.authService.logout();
  }

  setContainerMarginTop(margin: number) {
    this.containerStyles['margin-top'] = margin + 'px';
    this.marginTop = margin;
  }

  collapseTopMenu(): void {
    if (!!this.navbar) {
      this.navbar.collapseTopMenu();
    }
  }

  sideMenuChange(expanded: boolean): void {
    this.sideMenuExpanded = expanded;
    this.setContainerOverlay();
  }

  topMenuChange(expanded: boolean): void {
    this.topMenuExpanded = expanded;
    this.setContainerOverlay();
  }

  private setContainerOverlay(): void {
    if (this.topMenuExpanded || this.sideMenuExpanded) {
      //document.getElementById('app-wrapper-content-container').classList.add('wrapper-content-container-overlay');
    } else {
      //document.getElementById('app-wrapper-content-container').classList.remove('wrapper-content-container-overlay');
    }
  }

  cartListener = (e: StorageEvent) => {
    if (e.key.startsWith('cart_') && e.oldValue !== e.newValue) {
      this.store.dispatch(new LoadCart({ cartKey: e.key }));
    }
  };

  setHideNavbar(authenticated: boolean) {
    this.hideNavbar = this.navbarConfigService.hideNavbar = !authenticated;
  }

  showFullScreen(): { [s: string]: any } {
    const showFullScreen = this.router.url.includes('/products') || this.router.url.includes('/articles');
    return {
      container: !showFullScreen,
      'container-fluid pt-2': showFullScreen
    };
  }

  enableDev() {
    localStorage.setItem('dev', '1');
    window.location.reload();
  }

  disableDev() {
    localStorage.removeItem('dev');
    window.location.reload();
  }
}
