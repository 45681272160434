import { Component, OnInit, Input } from '@angular/core';
import { GungFlow } from './../../../../state/flow/types';
import { GungFlowService } from './../../../../services/gung-flow/gung-flow.service';
import { gungComparatorHelper } from '../../../../utils/gung-utils';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';

interface Entry {
  key: '_$in' | '_$nin';
  value: any;
}

export interface Setting {
  index: number;
  key: string[];
  label: string;
  entry: Entry;
}

@Component({
  selector: 'lib-gung-flow-details-product-settings',
  templateUrl: './gung-flow-details-product-settings.component.html',
  styleUrls: ['./gung-flow-details-product-settings.component.css']
})
export class GungFlowDetailsProductSettingsComponent implements OnInit {
  @Input()
  curentGungFlow: GungFlow;
  settings: Setting[] = [];
  flowProductConditions = [];

  private readonly INCLUDE_KEY = '_$in';
  private readonly EXCLUDE_KEY = '_$nin';

  constructor(
    protected gungFlowService: GungFlowService,
    protected translateService: TranslateService
  ) {}

  ngOnInit() {
    this.settings = [];
    this.gungFlowService.getSystemGung().pipe(first()).subscribe(systemGung => {

      if (!!this.curentGungFlow.productConditions && Array.isArray(this.curentGungFlow.productConditions)) {
        for (const [index, entry] of this.curentGungFlow.productConditions.entries()) {
          for (const key of Object.keys(this.curentGungFlow.productConditions[index])) {
            this.processNestedObjectFromFlow(this.curentGungFlow.productConditions[index][key], index, [key]);
          }
        }
      }

      if (systemGung.extra.flowProductConditions) {
        for (const key of Object.keys(systemGung.extra.flowProductConditions)) {
          this.processNestedObjectFromSystemSettings(systemGung.extra.flowProductConditions[key], [key]);
        }
      }
      this.settings = this.settings.sort((a, b) => gungComparatorHelper(this.translateService.instant(a.label), this.translateService.instant(b.label), 1));

      if(!this.curentGungFlow.productConditions){
        this.curentGungFlow.productConditions = [];
      }else{
        for (const conditions of this.curentGungFlow.productConditions) {
          const settings = structuredClone(this.settings)
          const settingsValues = this.populateValues(settings, conditions);
          this.flowProductConditions.push(settingsValues)
        }
      }

      if(this.flowProductConditions.length === 0){
        this.flowProductConditions.push(this.settings);
      }
      this.doAfterInit();
    });
  }

  doAfterInit() {
    // override this method for custom logic
  }

  populateValues(settings, conditions) {
    return settings.map(item => {
        const [firstKey, ...remainingKeys] = item.key;
        let currentConditionLevel = conditions[firstKey];
        for (const key of remainingKeys) {
            if (currentConditionLevel && currentConditionLevel[key]) {
              currentConditionLevel = currentConditionLevel[key];
            } else {
                currentConditionLevel = null;
                break;
            }
        }
        if (currentConditionLevel) {
            const operator = Object.keys(currentConditionLevel)[0];
            if (operator) {
                const values = Object.values(currentConditionLevel[operator]);
                if (values.length > 0) {
                    item.entry.key = operator; 
                    item.entry.value = values.join(','); 
                }
            }
        }
        return item;
    });
}

processNestedObjectFromFlow(obj, index, key = []) {
  for (const nextMapKey of Object.keys(obj)) {
    const nextMap = obj[nextMapKey];
    const setting: Setting = {
      index,
      key: [...key, nextMapKey],
      label: ([...key, nextMapKey].join('_')).toUpperCase(),
      entry: { key: this.INCLUDE_KEY, value: null }
    };
    const existsSetting = this.settings.find(s => s.label === setting.label);
    if(existsSetting){
      continue;
    }
    if (nextMap.hasOwnProperty(this.INCLUDE_KEY) || nextMap.hasOwnProperty(this.EXCLUDE_KEY)) {
      this.settings.push(setting);
    } else if (typeof nextMap === 'object') {
      this.processNestedObjectFromFlow(nextMap, index, setting.key);
    } else {
      console.error('This should not happen');
    }
  }
}

protected getValue(value: any): Entry {
  return {
    key: Object.keys(value)[0] == this.EXCLUDE_KEY ? this.EXCLUDE_KEY : this.INCLUDE_KEY,
    value: Array.from(Object.values(value)[0] as any).join(',')
  };
}

  private processNestedObjectFromSystemSettings(obj, key = []) {
    for (const nextMapKey of Object.keys(obj)) {
      const nextMap = obj[nextMapKey];
      const setting: Setting = {
        index: 0,
        key: [...key, nextMapKey],
        label: ([...key, nextMapKey].join('_')).toUpperCase(),
        entry: { key: this.INCLUDE_KEY, value: null }
      };
      const pathNotAddedFromFlow = this.settings.findIndex(s => s.label === setting.label) === -1;
      if (pathNotAddedFromFlow && typeof nextMap === 'string') {
        this.settings.push(setting);
      } else if (typeof nextMap === 'object') {
        this.processNestedObjectFromSystemSettings(nextMap, setting.key);
      }
    }
  }

  public delete(index: number) {
    this.curentGungFlow.productConditions.splice(index, 1);
    this.flowProductConditions.splice(index, 1);
  }


  setValue(value: any, setting: Setting, index): void {
    setting.entry.value = value;
    this.assignValuesToFlow(this.flowProductConditions[index], index);
  }

  keyChange(key: any, setting: Setting, index) {
    const oldVal = setting.entry.value;
    setting.entry.key = key;
    this.setValue(oldVal, setting, index);
  }

  assignValuesToFlow(settingsConditions, index){
    const dateToSave = this.mapDataToSave(settingsConditions);
    this.curentGungFlow.productConditions[index] = dateToSave;
  }

  mapDataToSave(input): any {
    let result = {};
  
    input.forEach(item => {
      if (item.entry.value) {
        const valuesArray = item.entry.value.split(',').map(value => value.trim());
  
        let nestedObject: any = {};
        let currentLevel = nestedObject;
        item.key.forEach((key: string, index: number) => {
          if (index === item.key.length - 1) {
            currentLevel[key] = { [item.entry.key]: valuesArray };
          } else {
            currentLevel[key] = currentLevel[key] || {};
            currentLevel = currentLevel[key];
          }
        });
        result = this.mergeDeep(result, nestedObject);
      }
    });
  
    return result;
  }

  mergeDeep(target: any, source: any) {
    for (const key of Object.keys(source)) {
      if (Array.isArray(source[key])) {
        // Se o valor for um array, substitui diretamente o valor no target
        target[key] = source[key];
      } else if (source[key] instanceof Object) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        this.mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
    return target;
  }

  public add(){
    this.flowProductConditions.push(this.settings);
  }
}
