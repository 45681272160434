import { Component, OnInit } from '@angular/core';
import { ProductCardComponent, CustomerProductPrice, GungFlowService, Product, AuthService } from 'gung-standard';
import { PriceService } from 'gung-standard';
import { Availability } from 'gung-standard';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs';

@Component({
  selector: 'gung-jeeves-jeeves-product-card',
  templateUrl: './jeeves-product-card.component.html',
  styleUrls: ['./jeeves-product-card.component.css']
})
export class JeevesProductCardComponent extends ProductCardComponent implements OnInit {
  price: CustomerProductPrice;
  availability: Availability;

  isSales: boolean;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    forkJoin([
      this.priceService.getCurrentCustomerPrice(this.data.id).pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first()),
      this.authService.getRoles().pipe(first())
    ])
      .pipe(first())
      .subscribe(([price, flow, roles]) => {
        this.price = price;

        this.availability = this.getProductAvailability(this.data);
        if (!this.availability && (flow.useAvailabilities || flow.requireAvailability)) {
          throw new Error('No availability found for product ' + this.data.id);
        }

        this.isSales =
          roles.filter(role => role.toUpperCase() === 'ADMIN' || role.toUpperCase() === 'SALES').length > 0;
      });
  }

  private getProductAvailability(product: Product): Availability {
    if (product.extra.availabilities) {
      const availabilities = Object.keys(product.extra.availabilities).map(key => product.extra.availabilities[key]);
      if (availabilities.length > 0) {
        return availabilities[0];
      }
    }

    return null;
  }
}
