<div class="row">
  <div class="col-12 col-md-6">
    <h4 translate>DELIVERY_ADDRESS</h4>
    <div class="form-group">
      <label for="ordlevadr1" class="control-label" translate>OH_ORDLEVADR1</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr1"
        [value]="checkout.extra.procargs.ordlevadr1 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="ordlevadr2" class="control-label" translate>OH_ORDLEVADR2</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr2"
        [value]="checkout.extra.procargs.ordlevadr2 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="ordlevadr3" class="control-label" translate>OH_ORDLEVADR3</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr3"
        [value]="checkout.extra.procargs.ordlevadr3 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="ftgpostnr" class="control-label" translate>OH_FTGPOSTNR</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ftgpostnr"
        [value]="checkout.extra.procargs.ftgpostnr || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="ordlevadr4" class="control-label" translate>OH_ORDLEVADR4</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="ordlevadr4"
        [value]="checkout.extra.procargs.ordlevadr4 || ''"
        readonly
      />
    </div>
    <lib-meta-select-input
      [(ngModel)]="checkout.extra.procargs.ordlevadrlandskod"
      table="xr"
      metaId="landskod"
      metaDisplay="land"
      [disabled]="true"
      class="gung-select-input-sm"
    >
    </lib-meta-select-input>
  </div>
  <div class="col-12 col-md-6" *ngIf="customer">
    <h4 translate>INVOICE_ADDRESS</h4>
    <div class="form-group">
      <label for="billingFtgnr" class="control-label" translate>CUSTOMER_ID</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="billingFtgnr"
        [value]="customer.extra._billing.ftgnr || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="billingFtgnamn" class="control-label" translate>FTGNAMN</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="billingFtgnamn"
        [value]="customer.extra._billing.ftgnamn || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="billingFtgpostadr2" class="control-label" translate>FTGPOSTADR2</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="billingFtgpostadr2"
        [value]="customer.extra._billing.ftgpostadr2 || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="billingFtgpostnr" class="control-label" translate>FTGPOSTNR</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="billingFtgpostnr"
        [value]="customer.extra._billing.ftgpostnr || ''"
        readonly
      />
    </div>
    <div class="form-group">
      <label for="billingFtgpostadr3" class="control-label" translate>FTGPOSTADR3</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="billingFtgpostadr3"
        [value]="customer.extra._billing.ftgpostadr3 || ''"
        readonly
      />
    </div>
    <lib-meta-select-input
      [(ngModel)]="customer.extra._billing.landskod"
      table="xr"
      metaId="landskod"
      metaDisplay="land"
      [disabled]="true"
      class="gung-select-input-sm"
    >
    </lib-meta-select-input>
  </div>
</div>

<h4 translate>CONDITIONS</h4>
<div class="row">
  <div class="col-12 col-md-6">
    <lib-meta-select-input
      [(ngModel)]="checkout.extra.procargs.levsattkod"
      table="x2f"
      metaId="levsattkod"
      metaDisplay="levsattbeskr"
      [disabled]="true"
      class="gung-select-input-sm"
    >
    </lib-meta-select-input>
    <lib-meta-select-input
      [(ngModel)]="checkout.extra.procargs.levvillkkod"
      table="x2e"
      metaId="levvillkkod"
      metaDisplay="levvillkbeskr"
      [disabled]="true"
      class="gung-select-input-sm"
    >
    </lib-meta-select-input>
  </div>
  <div class="col-12 col-md-6">
    <lib-meta-select-input
      [(ngModel)]="checkout.extra.oh.betkod"
      table="x2"
      metaId="betkod"
      metaDisplay="betvillbeskr"
      [disabled]="true"
      class="gung-select-input-sm"
    >
    </lib-meta-select-input>
    <lib-meta-select-input
      [(ngModel)]="checkout.extra.procargs.saljare"
      table="salj"
      metaId="saljare"
      metaDisplay="saljarenamn"
      [disabled]="true"
      class="gung-select-input-sm"
    >
    </lib-meta-select-input>
  </div>
</div>

<div *ngIf="productsMap && pricesMap">
  <h4 translate>ROWS</h4>
  <div class="row">
    <div class="col-12 table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th translate>PRODUCT_ID</th>
            <th translate>NAME</th>
            <!-- <th translate>ESTIMATED_DELIVERY_DATE</th> -->
            <th translate>QUANTITY</th>
            <th translate>UNIT_PRICE</th>
            <th *ngIf="sales" class="d-table-cell" translate>DISCOUNT</th>
            <th translate>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of checkout.rows">
            <td>{{ row.id }}</td>
            <td>{{ productsMap[row.id].name }}</td>
            <!-- <td>
              <span
                *ngIf="(row.extra.orp && row.extra.orp.ordberlevdat) else orderHeadDate">{{row.extra.orp.ordberlevdat | date:'yyyy-MM-dd'}}</span>
              <ng-template #orderHeadDate>
                <span
                  *ngIf="checkout.extra.oh.ordberlevdat else defaultDate">{{checkout.extra.oh.ordberlevdat | date:'yyyy-MM-dd'}}</span>
              </ng-template>
              <ng-template #defaultDate>{{today | date:'yyyy-MM-dd'}}</ng-template>
            </td> -->
            <td>{{ row.quantity }}</td>
            <td>
              <span *ngIf="pricesMap">
                {{
                  pricesMap[row.id].cartRowUnitPrice.value
                    | gungCurrency: pricesMap[row.id].cartRowUnitPrice.currencyCode
                }}
              </span>
            </td>
            <td *ngIf="sales" class="d-table-cell">
              <span *ngIf="pricesMap && pricesMap[row.id].cartRowDiscountPercent"
                >{{ pricesMap[row.id].cartRowDiscountPercent | number: '1.0-2' }} %</span
              >
            </td>
            <td>
              <span *ngIf="pricesMap">
                {{
                  pricesMap[row.id].cartRowTotalPriceInclRowDiscount.value
                    | gungCurrency: pricesMap[row.id].cartRowTotalPriceInclRowDiscount.currencyCode
                }}</span
              >
            </td>
            <!-- <td *ngIf="pricesMap">{{pricesMap[row.id].customerNetPrice.value}} {{pricesMap[row.id].customerNetPrice.currencyCode}}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div
  *ngIf="
    checkout.extra.additionalCosts &&
    (checkout.extra.additionalCosts.adminFee || checkout.extra.additionalCosts.freight)
  "
>
  <h4 translate>ADDITIONAL_COSTS</h4>
  <div class="row">
    <div class="col-12 table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="4" translate>CATEGORY</th>
            <th colspan="6" translate>QUANTITY</th>
            <th translate>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="checkout.extra.additionalCosts.freight">
            <td colspan="4" translate>
              {{ checkout.extra.additionalCosts.freight.name }}
            </td>
            <td colspan="6">
              {{ checkout.extra.additionalCosts.freight.quantity }}
            </td>
            <td>
              {{
                checkout.extra.additionalCosts.freight.value
                  | gungCurrency: checkout.extra.additionalCosts.freight.currencyCode
              }}
            </td>
          </tr>
          <tr *ngIf="checkout.extra.additionalCosts.adminFee">
            <td colspan="4" translate>ADMIN_CHARGE</td>
            <td colspan="6">1</td>
            <td>
              {{
                checkout.extra.additionalCosts.adminFee.fee
                  | gungCurrency: checkout.extra.additionalCosts.adminFee.currency
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
